import Vue from 'vue'

import { cloneDeep, pick } from 'lodash'

import { generateServices, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import { tariffInputFilter, tariffOutputFilter } from '@/components/services/tariffs/utils'

import render from './render'

export default {
  name: `${services.tariffs}ButtonsDouble`,

  mixins: [
    generateServices({
      name: services.tariffs,

      inputFilter: tariffInputFilter,
      outputFilter: tariffOutputFilter,

      get: false,
      update: false,
      remove: false
    })
  ],

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      loading: false,

      divideByHundred: false,
      showWarningDialog: false,
      similarTariffs: []
    }
  },

  methods: {
    async createTariff(payload) {
      try {
        const response = await this.rest[services.tariffs].create(payload)
        if (response) {
          Vue.router.push({
            name: `${services.tariffs}.single`,
            params: { id: response.id }
          })
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.divideByHundred = false
      }
    },

    async cloneTariff(divideByHundred = false) {
      try {
        this.loading = true
        const payload = this.processPayload(divideByHundred)
        this.similarTariffs = await this.rest[services.tariffs].find({ query: { title: payload.title } })
        if (Array.isArray(this.similarTariffs) && this.similarTariffs.length) {
          const exactMatch = this.similarTariffs.find(tariff => tariff.title === payload.title)
          if (exactMatch) {
            this.showWarningDialog = true
          }
        } else {
          this.createTariff(payload)
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    },

    titleSuffix(payload) {
      const regex = /_(\d+)$/
      const match = payload.title.match(regex)
      if (match) {
        const index = parseInt(match[1])
        payload.title = payload.title.replace(regex, `_${index + 1}`)
      } else {
        payload.title = `${payload.title}_2`
      }

      return payload
    },

    dividePricesByHundred(payload) {
      for (const rule of payload.data.rules) {
        if (rule.price) {
          if (!Array.isArray(rule.price)) {
            rule.price = [ rule.price ]
          }

          rule.price = rule.price.map(price => {
            // изображаем деление на 100, без 0.9999999999999999 и округлений
            const [ integer, decimal ] = price.toString().split('.')
            const integers = integer.split('')
            const integersAfterDivide = integers.splice(-2)

            const result = [
              integers.length ? integers.join('') : '0',
              (integersAfterDivide.join('').padStart(2, '0') + decimal).slice(0, 4)
            ]

            // 0.0000
            return parseFloat(result.join('.'))
          })
        }
      }

      return payload
    },

    processPayload(divideByHundred = false) {
      const payload = pick(cloneDeep(this.value), [ 'title', 'source', 'data', 'isActive' ])

      this.titleSuffix(payload, divideByHundred)

      if (divideByHundred) {
        this.dividePricesByHundred(payload)
      }

      return payload
    }
  },

  render
}
