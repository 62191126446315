import VirtualList from 'vue-virtual-scroll-list'

import { buttonModes, colors, components, icons, inputModes, services, sizes, states } from '@/utils'

import button from '@/components/button'
import row from '@/components/misc/virtualScroll/row'
import importCSVButton from '@/components/trees/importCSVButton'

function renderSearchField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.searchValue,
        label: this.getTranslate(`${services.sendernames}.labels.searchAndAdd`),
        hint: this.getTranslate(`${services.sendernames}.hints.enterAndAdd`),
        beforeIcon: icons.search,
        afterIcon: icons.refresh,
        afterIconCallback: () => {
          this.get()
        },
        loading: this.restData[services.sendernames].find.state === states.loading,
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        clearable: true
      },
      on: {
        input: event => {
          this.search(event)
        },
        keypress: event => {
          if (event.keyCode === 13) {
            if (!~this.proxy.indexOf(this.searchValue)) {
              this.tab = 'checked'
              this.proxy.push(this.searchValue)
              this.searchValue = undefined
            }
          }
        }
      }
    }
  )
}
function renderCSVButton(h) {
  return h(
    importCSVButton,
    {
      props: {
        value: this.proxy,
        parser: result => {
          for (const array of result) {
            for (const sender of array) {
              const name = (sender + '').trim()
              if (!~this.proxy.indexOf(name)) {
                this.proxy.push(name)
              }
            }
          }
          this.tab = 'checked'
        }
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
function renderSearch(h) {
  return h(
    'div',
    {
      class: 'tree-header__search'
    },
    [
      renderSearchField.call(this, h),
      renderCSVButton.call(this, h)
    ]
  )
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'tree-header'
    },
    [ renderSearch.call(this, h) ]
  )
}

function renderPills(h) {
  return h(
    'div',
    {
      class: 'tree-body__pills'
    },
    [ 'all', 'checked' ].map(tab => {
      let total = this.proxy.length
      if (tab === 'all') {
        total = this.restData[services.sendernames].find.pagination.total
      }

      return h(
        button,
        {
          props: {
            mode: buttonModes.flat,
            size: sizes.small,
            color: this.tab === tab ? colors.primary : colors.grey,
            label: this.getTranslate(`${services.sendernames}.pills.${tab}`) + ` ${total}`
          },
          on: {
            click: () => {
              this.tab = tab
            }
          }
        }
      )
    })
  )
}

function renderItem(h, item) {
  if (item) {
    return h(
      'div',
      {
        class: 'tree__row',
        on: {
          click: () => {
            const index = this.proxy.indexOf(item)
            if (~index) {
              this.proxy.splice(index, 1)
            } else {
              this.proxy.push(item)
            }
          }
        }
      },
      [
        h(
          'div',
          {
            class: {
              ['tree__checker']: true,
              ['tree__checker--checked']: ~this.proxy.indexOf(item)
            }
          }
        ),

        h(
          'div',
          {
            class: 'tree__content'
          },
          [
            h(
              'div',
              {
                class: 'tree__title'
              },
              item
            )
          ]
        )
      ]
    )
  }
}

function renderChecked(h) {
  if (this.tab === 'checked') {
    if (Array.isArray(this.checked)) {
      if (this.checked.length) {
        return h(
          'div',
          {
            class: 'tree__level tree__level--special'
          },
          this.checked.sort((a, b) => a - b).map(item => renderItem.call(this, h, item))
        )
      } else {
        return h(
          'div',
          {
            class: 'tree__empty'
          },
          [ h('g-empty') ]
        )
      }
    }
  }
}
function renderItems(h) {
  if (this.tab === 'all') {
    if (Array.isArray(this.restData[services.sendernames].find.data)) {
      if (this.restData[services.sendernames].find.data.length) {
        return h(
          VirtualList,
          {
            class: 'tree__level tree__level--1',
            props: {
              dataKey: 'name',
              dataSources: this.restData[services.sendernames].find.data,
              dataComponent: row,
              itemScopedSlots: {
                item: source => renderItem.call(this, h, source.name)
              }
            },
            on: {
              tobottom: () => {
                const loadMore = this.restData[services.sendernames].find.pagination.limit + this.restData[services.sendernames].find.pagination.offset < this.restData[services.sendernames].find.pagination.total
                if (this.restData[services.sendernames].find.state === states.ready && loadMore) {
                  this.restData[services.sendernames].find.pagination.offset += this.restData[services.sendernames].find.pagination.limit
                }
              }
            }
          }
        )
      } else {
        return h(
          'div',
          {
            class: 'tree__empty'
          },
          [ h('g-empty') ]
        )
      }
    }
  }
}

function renderBody(h) {
  return h(
    'div',
    {
      class: 'tree-body'
    },
    [
      renderPills.call(this, h),
      renderChecked.call(this, h),
      renderItems.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'tree'
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h)
    ]
  )
}
