export default {
  hints: {
    tree: {
      country: 'país',
      operator: 'operador',
      group: 'grupo'
    }
  },

  snackbars: {
    created: 'Grupo creado',
    updated: 'Grupo actualizado',
    removed: 'Grupo eliminado'
  },

  contents: {
    confirm: {
      remove: '¿Está seguro de que desea eliminar el grupo {title}?'
    }
  },

  tabs: {
    all: 'Todos',
    checked: 'Seleccionados'
  },

  pills: {
    all: 'Disponibles',
    checked: 'Seleccionados'
  }
}
