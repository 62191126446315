import colors from './colors'
import sendings from './sendings'

export default {
  title: 'Configuraciones del sitio',

  titles: {
    settings: 'Configuraciones del sitio',
    reseller: 'Configuraciones del sitio del revendedor',
    resellers: 'Revendedores',
    auth: 'Autorización',
    frontend: 'Apariencia',
    notifications: 'Notificaciones',
    payments: 'Pagos',
    globalBlacklistManager: 'Lista negra global',
    apisender: 'API Sender',
    integrations: 'Integraciones',
    misc: 'Varios'
  },

  subtitles: {
    resellers: {
      domains: 'Dominios'
    },

    auth: {
      registration: {
        defaults: 'Valores predeterminados al registrarse'
      }
    },

    frontend: {
      colors: 'Colores del sitio',
      documents: 'Documentos públicos',
      jivosite: 'Configuraciones de Jivosite'
    },

    payments: {
      methods: 'Métodos de pago'
    },

    apisender: {
      settings: {
        readChatSettings: 'Leer configuraciones del chat',
        sendMessageSettings: 'Configuraciones de envío de mensajes',
        webhookSettings: 'Configuraciones del webhook'
      }
    },

    integrations: {
      yclients: {
        applicationCredentials: 'Datos de conexión a YClients'
      }
    }
  },

  labels: {
    reseller: 'Seleccionar revendedor',
    privacyPolicy: 'Política de privacidad',
    publicOffer: 'Oferta pública',

    resellers: {
      domains: {
        domain: 'Dominio',
        user: 'Usuario'
      }
    },

    auth: {
      registration: {
        defaults: {
          allowDuplicates: 'Permitir duplicados de mensajes',
          sendernamesCheck: 'Restringir nombres de remitentes para tipos',
          patternsCheck: 'Verificación obligatoria por patrón de moderación para tipos',
          balance: 'Balance predeterminado',
          tariffs: 'Tarifa predeterminada',
          routingGroups: 'Grupo de enrutamiento',
          messageRuleGroups: 'Grupo de reglas de mensajes',
          sendernames: 'Nombres de remitentes',
          patterns: 'Patrones',
          currency: 'Moneda'
        }
      }
    },

    frontend: {
      title: 'Título del sitio',
      colors: colors.labels,
      jivosite: 'ID de Jivosite',

      documents: {
        privacyPolicy: 'Política de privacidad',
        publicOffer: 'Oferta pública'
      }
    },

    notifications: {
      enabled: {
        types: sendings.types
      },

      extraRecipients: 'Destinatarios adicionales de notificaciones'
    },

    payments: {
      allowedGlobalMethods: 'Métodos de pago globales permitidos'
    },

    globalBlacklistManager: {
      mainListId: 'Lista principal',
      temporaryAvailableListId: 'Lista de números temporalmente permitidos'
    },

    apisender: {
      userApiKey: 'Clave API del usuario',

      settings: {
        readChatSettings: {
          eventOnIncomingMessage: 'Evento en mensaje entrante'
        },

        sendMessageSettings: {
          delayOnSendMessageInMs: 'Retraso en el envío de mensajes en ms'
        },

        webhookSettings: {
          webhookUrl: 'URL del webhook',
          webhookOnNewMessage: 'Webhook en nuevo mensaje',
          webhookOnStatusMessage: 'Webhook en mensaje de estado',
          webhookOnCheckPhoneState: 'Webhook en verificación del estado del teléfono',
          webhookOnChangeStateAccount: 'Webhook en cambio de estado de la cuenta',
          webhookOnIncomingCall: 'Webhook en llamada entrante',
          webhookOnIncomingVideoCall: 'Webhook en videollamada entrante',
          webhookOnFailedUserProxy: 'Webhook en proxy de usuario fallido'
        }
      }
    },

    integrations: {
      yclients: {
        applicationCredentials: {
          id: 'ID de la aplicación',
          token: 'Token de la aplicación'
        }
      }
    },

    misc: {
      rootId: 'ID raíz'
    }
  },

  contents: {
    payments: {
      methods: 'Configuraciones de métodos de pago'
    }
  },

  hints: {
    warning: 'Está editando las configuraciones del sitio del usuario seleccionado.',

    frontend: {
      colors: colors.hints,
      jivosite: 'Establezca un ID de Jivosite personal para activar el módulo de soporte en el sitio',

      documents: {
        privacyPolicy: 'Archivo que se ofrecerá a los usuarios para su revisión',
        publicOffer: 'Archivo que se ofrecerá a los usuarios para su revisión'
      }
    },

    notifications: {
      email: {
        templates: {
          balance: {
            limit: 'Mensaje que se enviará al usuario al alcanzar uno de los valores límite del balance'
          },

          auth: {
            registration: 'Mensaje que se enviará al usuario al registrarse',
            recover: 'Mensaje que se enviará al usuario al intentar recuperar la contraseña'
          },

          manager: {
            balance: {
              limit: 'Mensaje que se enviará al gerente o administrador al alcanzar uno de los valores límite del balance de los usuarios secundarios'
            },

            providers: {
              health: 'Mensaje que se enviará si el proveedor se desconecta por alguna razón'
            }
          }
        }
      },

      sms: {
        templates: {
          otp: {
            generate: {
              sendernames: {
                moderation: 'Mensaje que se enviará al confirmar el número del remitente por SMS'
              }
            }
          }
        }
      },

      events: {
        balance: {
          limit: {
            title: 'La notificación se activará al alcanzar el balance de {amount}',
            onLimit: 'Al alcanzar el balance',
            noLimit: '<límite no especificado>',
            notifyBy: 'notificar por',
            noNotifications: '<no notificar>',
            activator: 'La notificación se activará al alcanzar el balance de {limit}',
            overdraft: ' + sobregiro del usuario',
            invoice: 'Adjuntar factura por {amount}'
          }
        }
      },

      extraRecipients: 'Notificaciones sobre el estado del balance de todos los usuarios del sistema'
    },

    payments: {
      methods: 'Configure varios métodos de recarga de balance utilizando integraciones preestablecidas con sistemas de pago'
    }
  },

  misc: {
    globalSettings: 'Configuraciones globales'
  },

  snackbars: {
    updated: 'Configuración actualizada exitosamente.'
  },

  patterns: {
    username: 'Nombre de usuario',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    url: 'Url',
    domain: 'Dominio',
    title: 'Título',

    recipient: {
      username: 'Nombre de usuario',
      email: 'Correo electrónico',
      phone: 'Teléfono'
    }
  }
}
