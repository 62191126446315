import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import { components, inputModes, moderationColors, moderationIcons, services } from '@/utils'

import selectorOwner from '@/components/selector/owner'
import selectorSendingType from '@/components/selector/sendingType'
import tag from '@/components/tag'

function renderTypeSelect(h) {
  return h(
    selectorSendingType,
    {
      props: {
        value: this.proxy.type,
        label: this.getTranslate(`${services.patterns}.labels.type`)
      },
      on: {
        input: event => {
          this.proxy.type = event
        }
      }
    }
  )
}

function renderModerationSelect(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.moderation,
        label: this.getTranslate(`${services.patterns}.labels.moderation`),
        items: MODERATION_VALUES.map(moderation => {
          return {
            title: this.getTranslate(`misc.moderation.${moderation}`),
            value: moderation,
            icon: moderationIcons[moderation],
            iconColor: moderationColors[moderation]
          }
        }),
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            tag,
            {
              props: {
                label: this.getTranslate(`misc.moderation.${item.value}`),
                color: moderationColors[item.value],
                as: 'select-dense'
              }
            }
          )
        }
      },
      on: {
        input: event => {
          this.proxy.moderation = event
        }
      }
    }
  )
}

function renderSelectorOwner(h) {
  return h(
    selectorOwner,
    {
      props: {
        value: this.proxy.OwnerId,
        mode: inputModes.outline,
        dense: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderIsActiveSwitch(h) {
  return h(
    components.switch,
    {
      props: {
        value: this.proxy.isActive,
        tooltip: this.getTranslate(`${services.patterns}.tooltips.filter.isActive`)
      },
      on: {
        input: event => {
          this.proxy.isActive = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { gridTemplateColumns: this.gridTemplateColumns }
    },
    [
      renderTypeSelect.call(this, h),
      renderModerationSelect.call(this, h),
      renderSelectorOwner.call(this, h),
      renderIsActiveSwitch.call(this, h)
    ]
  )
}
