const baseFields = {
  requisite: 'Requisito',
  title: 'Título',
  type: 'Tipo',
  sum: 'Suma',
  vat: 'Cálculo del IVA',
  suggestions: {
    inn: 'INN de su empresa',
    bik: 'BIC o nombre del banco',
    fio: 'Nombre completo del firmante',
    address: 'Dirección legal de la empresa'
  },
  contact: {
    firstName: 'Nombre',
    middleName: 'Segundo nombre',
    lastName: 'Apellido',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    matches: 'Coincide con los datos indicados en el perfil de la cuenta personal'
  },
  signer: {
    position: {
      nominative: 'Posición',
      genitive: 'Posición'
    },
    basis: {
      nominative: 'Base',
      genitive: 'Base'
    },
    fullName: {
      nominative: 'Nombre completo del firmante',
      genitive: 'Nombre completo del firmante'
    }
  },
  data: {
    legal: {
      businessType: 'Tipo de empresa',
      name: 'Nombre de la empresa',
      opf: 'OPF',
      ogrn: 'OGRN',
      inn: 'INN',
      kpp: 'KPP',
      okpo: 'OKPO'
    },
    bank: {
      name: 'Nombre del banco',
      rs: 'Cuenta corriente',
      ks: 'Cuenta corresponsal',
      bik: 'BIC'
    },
    tax: {
      system: 'Sistema fiscal',
      vat: 'La empresa trabaja con IVA'
    },
    legalAddress: {
      title: 'Dirección legal',
      country: 'País',
      city: 'Ciudad',
      index: 'Índice',
      address: 'Dirección',
      addressMatches: 'Coincide con la dirección legal',
      phone: 'Teléfono',
      phoneAdd: 'Número adicional'
    },
    postalAddress: {
      title: 'Dirección postal',
      country: 'País',
      city: 'Ciudad',
      index: 'Índice',
      address: 'Dirección',
      addressMatches: 'Coincide con la dirección legal',
      phone: 'Teléfono',
      phoneAdd: 'Número adicional'
    },
    personal: {
      firstName: 'Nombre',
      middleName: 'Segundo nombre',
      lastName: 'Apellido',
      document: {
        series: 'Serie del documento',
        number: 'Número del documento',
        issuer: 'Emitido por',
        issuerCode: 'Código de la subdivisión',
        issueDate: 'Fecha de emisión',
        registrationAddress: 'Dirección de registro',
        registrationDate: 'Fecha de registro',
        citizenship: 'Ciudadanía'
      }
    }
  },
  moderation: 'Moderación',
  documents: {
    templates: {
      shared: 'Plantillas disponibles',
      assigned: 'Plantilla asignada',
      purpose: 'Propósito',
      purposes: {
        bill: 'Factura',
        contract: 'Contrato principal',
        contractAnnex1: 'Anexo №1 al contrato',
        addAgreementCreditPayment: 'Acuerdo adicional sobre el método de pago a crédito',
        invoice: 'Factura',
        UTD: 'Acto UTD',
        UTDplus: 'UTD',
        Letter1: 'Carta 1',
        Letter2: 'Carta 2',
        Letter3: 'Carta 3'
      }
    }
  },
  country: 'País',
  city: 'Ciudad',
  index: 'Índice',
  address: 'Dirección',
  addressMatches: 'Coincide con la dirección legal',
  phone: 'Teléfono',
  phoneAdd: 'Número adicional'
}

export default {
  title: 'Requisitos',

  titles: {
    create: 'Crear nuevos requisitos',
    autoFilling: 'Autocompletar requisitos',
    document: {
      templates: 'Plantillas'
    },
    empty: {
      title: 'No se ha creado ningún requisito',
      sub: 'Cree nuevos requisitos usando el botón de abajo'
    },
    generate: {
      document: 'Descargar documento'
    },
    requisites: 'Requisitos',
    notRequired: 'Parte no obligatoria'
  },

  dialogs: {
    titles: {
      create: 'Crear requisitos',
      update: 'Actualizar requisitos',
      remove: 'Eliminar requisitos'
    }
  },

  headers: {
    ...baseFields,

    businessInfo: 'INN y KPP',
    contactInfo: 'Contactos'
  },

  labels: {
    ...baseFields
  },

  subheaders: {
    title: 'Título',
    contact: 'Responsable de la firma del contrato',
    signer: 'Firmante',
    general: 'General',
    legal: 'Parte legal',
    bank: 'Banco',
    tax: 'Impuestos',
    personal: 'Personal',
    document: 'Documento',
    legalAddress: 'Dirección legal',
    postalAddress: 'Dirección postal',
    documents: {
      templates: {
        shared: 'Plantillas disponibles',
        assigned: 'Plantillas asignadas'
      }
    }
  },

  contents: {
    documents: {
      templates: {
        shared: 'Plantillas de documentos que el revendedor podrá adjuntar a sus usuarios',
        assigned: 'Plantillas de documentos que se utilizarán para generar documentos del usuario'
      }
    },
    confirm: {
      remove: 'Confirme la eliminación de los requisitos.'
    }
  },

  types: {
    business: 'Persona jurídica',
    personal: 'Persona física'
  },

  errors: {
    ogrn: 'Debe tener entre 13 y 15 dígitos',
    inn: 'Debe tener entre 10 y 12 dígitos',
    kpp: 'Debe tener 9 dígitos',
    okpo: 'Debe tener 8 dígitos',
    rs: 'Debe tener 20 dígitos',
    ks: 'Debe tener 20 dígitos',
    bik: 'Debe tener 9 dígitos',
    index: 'Debe tener 6 dígitos',
    document: {
      number: 'Debe tener 6 dígitos'
    },
    documentsNoRequisitesFound: 'No se encontraron requisitos',
    documentsNoDocumentTemplateFound: 'No se encontraron plantillas de documentos.',
    contact: {
      phone: 'El teléfono debe ser válido',
      email: 'Ingrese un correo electrónico válido'
    }
  },

  hints: {
    title: 'Nombre del requisito para una búsqueda rápida en la plataforma al emitir una factura.',
    required: 'Los campos marcados con * son obligatorios',
    owner: 'Seleccione el usuario para el que desea crear los requisitos',
    suggestion: 'Esta sección le ayudará a ahorrar tiempo al completar los requisitos. Simplemente ingrese los datos necesarios en cada campo y elija la opción adecuada de la lista.',
    suggestions: {
      inn: 'Ingrese INN para buscar requisitos',
      bik: 'Ingrese BIC o las primeras letras del nombre del banco',
      fio: 'Ingrese el nombre completo del director general o gerente',
      address: 'Ingrese la dirección para buscar'
    },
    data: {
      legal: {
        name: 'Sin comillas ni forma legal, por ejemplo, su empresa LLC "Lirios", solo ingrese Lirios',
        opf: 'Forma organizativa y legal (puede escribir cualquier, no solo de la lista)',
        ogrn: 'Número de registro estatal principal',
        inn: 'Número de identificación del contribuyente',
        kpp: 'Código de motivo de registro',
        okpo: 'Clasificador nacional de empresas y organizaciones'
      },
      bank: {
        name: 'Nombre del banco o sucursal sin comillas',
        rs: 'Cuenta bancaria principal de la organización o empresario individual',
        ks: 'Cuenta corresponsal de la organización bancaria',
        bik: 'Identificador único del banco'
      }
    },
    nominative: 'Caso nominativo',
    genitive: 'Caso genitivo',
    sum: 'Suma mínima de 100 rublos',
    address: 'Calle, casa, edificio, oficina / apartamento',
    validation: {
      state: {
        true: 'Completado',
        false: 'Errores',
        undefined: 'No completado'
      }
    }
  },

  vat: {
    true: 'Con IVA',
    false: 'Sin IVA'
  },

  buttons: {
    add: 'Agregar requisitos',
    Edit: 'Editar requisitos',
    document: {
      templates: 'Plantillas de documentos de usuarios'
    }
  },

  snackbars: {
    created: 'Requisitos creados con éxito.',
    updated: 'Requisitos actualizados con éxito.',
    removed: 'Requisitos eliminados con éxito.'
  },

  tooltips: {
    isActive: 'Requisito activo o inactivo. Si el requisito está inactivo, no podrá usarlo, por ejemplo, para emitir facturas.',
    favorites: 'Marcar el requisito como favorito',
    filter: {
      isActive: 'Mostrar requisitos desactivados.'
    },
    icons: {
      isActive: 'Requisito activo',
      notActive: 'Requisito inactivo'
    }
  }
}
