import { checkHostnames } from '@sigma-frontend-libs/commons'

import { hostnames, imlinkHostnames, services } from '@/utils'

import render from './render'

export default {
  name: 'Warning',

  props: {
    check: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    text() {
      if (checkHostnames(imlinkHostnames)) {
        return this.getTranslate(`${services.sendings}.warnings.imlink`)
      } else if (checkHostnames(hostnames)) {
        return this.getTranslate(`${services.sendings}.warnings.own`)
      }
    }
  },

  render
}
