import { BILLING_CURRENCY } from '@sigma-legacy-libs/essentials/lib/constants'
import proxy from '@sigma-legacy-libs/g-proxy'

import { currency, currentTab, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'UsersForm',

  mixins: [
    proxy({ type: 'object' }),
    currentTab('general'),
    currency
  ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    isNew: Boolean
  },

  data() {
    return {
      showDocumentTemplates: false,

      editable: false,
      passwordVisibility: true,

      currentType: 'sms',
      currentItem: undefined
    }
  },

  computed: {
    tabs() {
      const result = [ 'general' ]

      if (this.checkPermissions(`advanced.${services.settingsUser}.get`, permissionPresets.resellerUp) && !this.isNew) {
        result.push(services.settingsUser)
      }

      if (this.checkPermissions(`advanced.${services.users}.setTariffs`, permissionPresets.resellerUp)) {
        result.push(services.tariffs)
      }

      if (this.checkPermissions(`advanced.${services.users}.setRoutingGroups`, permissionPresets.resellerUp)) {
        result.push(services.routingGroups)
      }

      if (this.checkPermissions(`advanced.${services.users}.update`, permissionPresets.resellerUp) && !this.isNew) {
        result.push('processMessages')
      }

      if (this.checkPermissions(`advanced.${services.tokens}.get`, permissionPresets.meUp) && !this.isNew) {
        result.push(services.tokens)
      }

      if (this.checkPermissions(`advanced.${services.users}.setPermissions`, permissionPresets.resellerUp) && !this.isNew) {
        result.push(services.permissions)
      }

      if (this.checkPermissions(`advanced.${services.users}.setOtpChannels`, permissionPresets.resellerUp) && !this.isNew) {
        result.push('userChannels')
      }

      if (!this.isNew && this.currency === BILLING_CURRENCY.RUB) {
        if (this.checkPermissions(`advanced.${services.requisites}.get`, permissionPresets.meUp)) {
          result.push(services.requisites)
        }
        if (this.checkPermissions(`advanced.${services.recurrentPayments}.get`, permissionPresets.meUp)) {
          result.push(services.recurrentPayments)
        }
        result.push('referral')
      }

      return result
    }
  },

  mounted() {
    this.getCurrency(this.proxy?.id)
  },

  render
}
