import { buttonModes, colors, components, icons, services } from '@/utils'

import button from '@/components/button'
import Chart from '@/components/misc/Chart'
import preloader from '@/components/preloader'

function renderPreloader(h) {
  if (this.loading) {
    return h(
      'div',
      {
        class: 'preloader__holder preloader__holder--absolute'
      },
      [ h(preloader) ]
    )
  }
}

function renderFilterMinutes(h) {
  return h(
    'div',
    {
      class: `${services.providers}-grafana__filter-minutes`
    },
    [ 5, 15, 30, 60 ].map(minute => {
      return h(
        button,
        {
          props: {
            label: minute,
            mode: buttonModes.flat,
            color: this.range === minute ? colors.primary : undefined,
            disabled: this.loading,
            tooltip: this.getTranslate('misc.measure.time.minutes')
          },
          on: {
            click: () => {
              this.range = minute
              this.get()
            }
          }
        }
      )
    })
  )
}
function renderFilterActions(h) {
  return h(
    'div',
    {
      class: `${services.providers}-grafana__filter-actions`
    },
    [
      h(
        components.switch,
        {
          props: {
            value: this.online,
            tooltip: this.getTranslate('grafana.tooltips.metrics.online'),
            outline: true
          },
          on: {
            input: event => {
              this.online = event
            }
          }
        }
      ),

      h(
        button,
        {
          props: {
            icon: icons.refresh,
            mode: buttonModes.flat,
            loading: this.loading,
            disabled: this.loading
          },
          on: {
            click: () => {
              this.get()
            }
          }
        }
      )
    ]
  )
}
function renderFilter(h) {
  if (this.hasFilter) {
    return h(
      'div',
      {
        class: `${services.providers}-grafana__filter`
      },
      [
        renderFilterMinutes.call(this, h),
        renderFilterActions.call(this, h)
      ]
    )
  }
}

function renderChart(h) {
  return h(
    Chart,
    {
      props: {
        data: {
          labels: this.labels,
          datasets: this.datasets
        },
        options: {
          layout: {
            padding: {
              left: 8,
              right: 8
            }
          },
          interaction: {
            intersect: false,
            mode: 'index'
          },
          scales: {
            x: { display: false },
            queue: {
              axis: 'y',
              min: 0,
              display: false
            },
            speed: {
              axis: 'y',
              min: 0,
              display: false
            },
            count: {
              axis: 'y',
              min: 0,
              display: true
            },
            delivery_rate: {
              axis: 'y',
              min: 0,
              max: 100,
              display: false
            }
          },
          animation: { duration: 10 },
          plugins: {
            legend: {
              labels: {
                usePointStyle: true,
                font: {
                  size: 12,
                  lineHeight: '12px'
                }
              }
            },
            tooltip: {
              caretPadding: 8,
              bodySpacing: 4,
              padding: 8,
              cornerRadius: 16,
              usePointStyle: true
            }
          }
        }
      }
    }
  )
}
function renderContent(h) {
  if (this.datasets.length) {
    return h(
      'div',
      {
        style: { position: 'relative' }
      },
      [
        renderChart.call(this, h),
        renderPreloader.call(this, h)
      ]
    )
  }

  return h(components.empty)
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.providers}-grafana`
    },
    [
      renderFilter.call(this, h),
      renderContent.call(this, h)
    ]
  )
}
