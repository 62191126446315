import { labels, types } from './sendings'

export default {
  title: 'Переотправления',

  types,
  labels,

  errors: {
    onTimeout: {
      timeout: 'Не может быть меньше 10'
    }
  }
}
