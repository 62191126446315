import { routerQueryReplace, settingsSiteUser } from '@/utils'

import render from './render'

export default function(options) {
  options.loading = {
    find: false,
    update: false
  }

  return {
    mixins: [
      settingsSiteUser({
        serviceName: options.serviceName,
        UserId: options.UserId
      })
    ],

    data() {
      return {
        currentKey: undefined
      }
    },

    watch: {
      settingKey(value) {
        if (value) {
          this.setCurrentKey(value)
        }
      },

      loading: {
        handler() {
          options.loading.find = this.loading.find
          options.loading.update = this.loading.update
        },
        deep: true
      }
    },

    mounted() {
      this.setCurrentKey(this.settingKey)
    },

    methods: {
      setCurrentKey(key) {
        const keys = options.renderSchema.map(item => item.key)
        if (keys.includes(key)) {
          this.currentKey = key
        } else if (Array.isArray(keys) && keys.length > 0) {
          this.currentKey = keys[0]
        }
        routerQueryReplace({ settingKey: this.currentKey })
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
