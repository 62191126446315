import { SENDINGS_FALLBACKS_OPTIONS_CLEAN } from '@sigma-legacy-libs/essentials/lib/constants'
import { upgradeArray } from '@sigma-legacy-libs/essentials/lib/utils'
import Actions from '@sigma-legacy-libs/essentials/lib/utils/Actions'
import Conditions from '@sigma-legacy-libs/essentials/lib/utils/Conditions'

import { icons } from '@/utils'

export const className = 'rules'

export const ruleTypes = {
  conditions: 'conditions',
  actions: 'actions'
}

export const ruleClasses = {
  [ruleTypes.conditions]: Conditions,
  [ruleTypes.actions]: Actions
}

export const ruleTags = upgradeArray(
  Array.from(
    new Set(
      [
        ...ruleClasses[ruleTypes.conditions].tagsOnly,
        ...ruleClasses[ruleTypes.actions].tagsOnly
      ]
    )
  )
)

export const ruleIcons = {
  [ruleTags.text]: icons.text_fields,
  [ruleTags.sender]: icons.person_outline,
  [ruleTags.recipient]: icons.person,
  [ruleTags.group]: icons.group,
  [ruleTags.mccmnc]: icons.public,
  [ruleTags.contactList]: icons.perm_contact_cal,
  [ruleTags.type]: icons.messenger,
  [ruleTags.keywords]: icons.device_hub,
  [ruleTags.moderation]: icons.add_moderator,
  [ruleTags.fallbacks]: icons.wrap_text,
  [ruleTags.buttons]: icons.library_add_check
}

export const defaultFallback = {
  type: undefined,
  payload: {
    sender: undefined,
    text: undefined
  },
  $options: SENDINGS_FALLBACKS_OPTIONS_CLEAN
}

