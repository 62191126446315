import { TASK_FREQUENCY } from '@sigma-legacy-libs/essentials/lib/constants'
import proxy from '@sigma-legacy-libs/g-proxy'

import { billingsSources, getLocaleDateString, getYesterday, isUUID } from '@/utils'

import render from './render'

export default {
  name: 'TasksForm',

  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    $date() {
      const result = {
        title: this.proxy.startsAt ? getLocaleDateString(this.proxy.startsAt, this.locale) : this.getTranslate('filter.date.empty'),
        label: this.getTranslate('filter.date.titles.startsAt'),
        value: this.proxy.startsAt,
        min: getYesterday()
      }

      if (this.proxy.data.frequency !== TASK_FREQUENCY.once) {
        result.value = [ this.proxy.startsAt, this.proxy.data.endsAt ]
        result.label = this.getTranslate('filter.date.titles.range')
        result.min = this.proxy.startsAt || getYesterday()

        if (this.proxy.data.endsAt) {
          result.title = [
            getLocaleDateString(this.proxy.startsAt, this.locale),
            getLocaleDateString(this.proxy.data.endsAt, this.locale)
          ].join(' - ')
        }
      }

      return result
    },

    validation() {
      if (!this.proxy.title) {
        return false
      }

      if (!isUUID(this.proxy.OwnerId)) {
        return false
      }

      if (!TASK_FREQUENCY.includes(this.proxy.data.frequency)) {
        return false
      }

      return true
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    },

    proxy: {
      handler(value) {
        if (value.data.frequency === TASK_FREQUENCY.once) {
          value.data.endsAt = undefined
        }
      },
      deep: true
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
    this.proxy.data.amount = undefined
    this.proxy.data.source = billingsSources.subtract
  },

  render
}
