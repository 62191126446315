import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { fillDependence, permissionPresets, serviceTemplate, services } from '@/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services.eventsHistory,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: services.users,
            permissions: permissionPresets.true,
            pathToId: 'userId',
            pathToObject: 'User',
            outputPath: 'User',
            picks: PUBLIC_FIELDS_USER
          })
        }

        return result
      },

      find: {
        defaultFilter: {
          entityId: undefined,
          userId: undefined,
          service: undefined,
          type: undefined
        }
      },

      create: false,
      update: false,
      remove: false,

      has: {
        clone: false,
        search: false
      },

      formClass: '',
      width: 440
    })
  ]
}
