import Vue from 'vue'

import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'ProviderGroupsForm',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    type: String,
    disabled: Boolean
  },

  computed: {
    validation() {
      if (!this.proxy.type) {
        return false
      }

      if (!this.proxy.title) {
        return false
      }

      if (Array.isArray(this.proxy.Providers)) {
        if (this.proxy.Providers.length === 0) {
          return false
        }
        if (this.proxy.Providers.some(({ id }) => id === undefined)) {
          return false
        }
      }

      return true
    }
  },

  watch: {
    'proxy.type'() {
      Vue.set(this.proxy, 'Providers', [])
    },
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)

    if (this.type) {
      this.proxy.type = this.type
    }
  },

  render
}
