import { audioStatuses } from '@/utils'

export default {
  namespaced: true,

  state: {
    src: undefined,
    currentTime: undefined,
    duration: undefined,
    status: undefined,
    playerId: undefined
  },

  mutations: {
    setSrc(state, src) {
      state.src = src
    },
    setCurrentTime(state, currentTime) {
      state.currentTime = currentTime
    },
    setDuration(state, duration) {
      state.duration = duration
    },
    setStatus(state, status) {
      state.status = status
    },
    setPlayerId(state, playerId) {
      state.playerId = playerId
    }
  },

  actions: {
    setSrc({ commit, state }, src) {
      if (state.src === src) {
        switch (state.status) {
          case audioStatuses.play:
            commit('setStatus', audioStatuses.pause)
            break
          case audioStatuses.pause:
            commit('setStatus', audioStatuses.play)
            break
        }
      } else {
        commit('setSrc', src)
      }
    },
    setCurrentTime({ commit }, currentTime) {
      commit('setCurrentTime', currentTime)
    },
    setDuration({ commit }, duration) {
      commit('setDuration', duration)
    },
    setStatus({ commit }, status) {
      commit('setStatus', status)
    },
    setPlayerId({ commit }, playerId) {
      commit('setPlayerId', playerId)
    }
  },

  getters: {
    src: state => state.src,
    currentTime: state => state.currentTime,
    duration: state => state.duration,
    status: state => state.status,
    playerId: state => state.playerId
  }
}
