export default {
  title: 'Plantillas de documentos',

  titles: {
    generate: 'Descargar documento',
    contract: 'Plantilla de contrato',
    invoice: 'Plantilla de factura',
    empty: {
      contract: 'No hay plantilla de contrato',
      invoice: 'No hay plantilla de factura'
    }
  },

  labels: {
    requisite: 'Tus requisitos',
    company: 'Contratista',
    type: 'Tipo de documento',
    sum: 'Monto del pago'
  },

  singular: {
    requisite: 'Requisitos',
    company: 'Contratista',
    type: 'Tipo'
  },

  contents: {
    confirm: {
      remove: 'Confirme la eliminación de la plantilla del documento.'
    }
  },

  buttons: {
    download: 'Descargar documento',
    add: {
      template: 'Agregar'
    },
    create: {
      requisite: 'Crear requisitos'
    }
  },

  types: {
    contract: 'Contrato',
    invoice: 'Factura'
  },

  hints: {
    searching: {
      requisites: 'Buscando tus requisitos...',
      companies: 'Buscando tus empresas...'
    }
  },

  warnings: {
    notFound: {
      requisites: 'Desafortunadamente, no podemos encontrar tus requisitos.<br><a href="/#/account#requisites" class="link link--active link--underline">Créelos</a> e inténtalo de nuevo.',
      requisitesForAdmin: 'Desafortunadamente, no podemos encontrar requisitos para este usuario.<br>Puedes <a href="/#/admin/users/{OwnerId}#requisites" class="link link--active link--underline">crearlos</a> tú mismo.',
      companies: 'Desafortunadamente, no podemos encontrar ningún contratista para los requisitos seleccionados.<br>Contacta con soporte para resolver este problema.',
      types: 'Desafortunadamente, no podemos generar documentos para este contratista.<br>Contacta con soporte para resolver este problema.'
    }
  },

  snackbars: {
    created: 'Documento creado con éxito.',
    updated: 'Documento actualizado con éxito.',
    removed: 'Documento eliminado con éxito.'
  }
}
