import { colors, services, sizes } from '@/utils'

import entityInfo from '@/components/entity/info'
import FormTitle from '@/components/misc/FormTitle'
import selectorSendingType from '@/components/selector/sendingType'
import TariffsDoubleButtons from '@/components/services/tariffs/buttons/double'
import TariffsThirdCol from '@/components/services/tariffs/cols/third'
import tag from '@/components/tag'
import textOverflow from '@/components/text/overflow'

function renderTitle(h, item) {
  return h(
    textOverflow,
    {
      props: {
        value: item.title
      }
    }
  )
}
function renderType(h, item) {
  const [ source, type ] = item.source && item.source.split('.') || []
  const sourceOrType = type || source

  return h(
    tag,
    {
      props: {
        label: this.getTranslate(`${services.tariffs}.sources.${sourceOrType}`),
        size: sizes.small,
        color: colors[sourceOrType]
      }
    }
  )
}
function renderSearchGeneratorItem(h, item) {
  return h(
    'router-link',
    {
      class: {
        'search-generator__item': true,
        'search-generator__item--active': item.id === this.$route.params.id
      },
      props: {
        to: {
          name: `${services.tariffs}.single`,
          params: { id: item.id }
        }
      },
      key: item.id
    },
    [
      h(
        'div',
        {
          class: 'search-generator__item-content'
        },
        [
          renderTitle.call(this, h, item),
          renderType.call(this, h, item)
        ]
      )
    ]
  )
}
function renderSearchGenerator(h) {
  return h(
    'search-generator',
    {
      scopedSlots: {
        filter: ({ filter }) => {
          return h(
            selectorSendingType,
            {
              props: {
                value: filter.source,
                label: this.getTranslate(`${services.tariffs}.labels.source`),
                items: this.sourceItems,
                format: value => value.replace(/sendings\.|subscription\./g, '')
              },
              on: {
                input: event => {
                  filter.source = event
                }
              }
            }
          )
        },
        default: ({ item }) => renderSearchGeneratorItem.call(this, h, item)
      }
    }
  )
}

function renderTemplate(h) {
  return h(
    'get-create-template',
    {
      scopedSlots: {
        title: ({ data }) => {
          return h(
            'div',
            {
              class: `${services.tariffs}-title`
            },
            [
              h(
                FormTitle,
                {
                  props: {
                    value: data
                  },
                  on: {
                    input: event => {
                      data = event
                    }
                  }
                }
              ),

              h(TariffsDoubleButtons, { props: { value: data } })
            ]
          )
        },
        'after-form': ({ data }) => h(entityInfo, { props: { value: data } }),
        right: () => h(TariffsThirdCol)
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: services.tariffs
    },
    [
      renderSearchGenerator.call(this, h),
      renderTemplate.call(this, h)
    ]
  )
}
