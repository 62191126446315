import { get } from 'lodash'

import { colors, components, icons, leadZero, permissionPresets, services } from '@/utils'

import sticker from '@/components/misc/sticker'
import actions from '@/components/templates/service/item/actions'
import date from '@/components/templates/service/item/date'

function renderStickerByPath(h, path) {
  const value = get(this.value, path)
  if (value) {
    return h(
      sticker,
      {
        props: {
          value,
          label: this.getTranslate(`${services.contacts}.labels.${path}`),
          icon: icons[path],
          color: colors.grey,
          width: '140px'
        }
      }
    )
  }
}
function renderName(h) {
  if (this.viewport.size.width > 1440) {
    const value = [ this.value.lastName, this.value.firstName ].filter(Boolean).join(' ')
    if (value) {
      return h(
        sticker,
        {
          props: {
            value,
            label: this.getTranslate(`${services.contacts}.labels.name`),
            width: '140px'
          }
        }
      )
    }
  }
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: `${services.contacts}-item__info`
    },
    [
      renderStickerByPath.call(this, h, 'phone'),
      this.viewport.size.width > 500 && renderStickerByPath.call(this, h, 'email'),
      renderName.call(this, h)
    ]
  )
}

function renderCustomField(h, index) {
  if (this.viewport.size.width > 768) {
    const path = `custom${leadZero(index)}`
    const value = get(this.value, path)
    if (value) {
      return h(
        sticker,
        {
          props: {
            value,
            label: this.getTranslate(`${services.contacts}.labels.${path}`),
            width: '140px'
          }
        }
      )
    }
  }
}
function renderCustomFields(h) {
  return h(
    'div',
    {
      class: `${services.contacts}-item__custom`
    },
    [
      renderCustomField.call(this, h, 1),
      renderCustomField.call(this, h, 2),
      renderCustomField.call(this, h, 3),
      renderCustomField.call(this, h, 19)
    ]
  )
}

function renderListItemEdit(h) {
  return h(
    components['list-item'],
    {
      props: {
        label: this.getTranslate('misc.buttons.edit'),
        icon: icons.edit,
        dense: true
      },
      on: {
        click: () => {
          this.$emit(`${services.contacts}.update`, this.value.id)
        }
      }
    }
  )
}
function renderActions(h) {
  return h(
    actions,
    {
      props: {
        value: this.value,
        actions: this.isPermanent ? [] : [ 'remove' ],
        service: services.contacts,
        accept: permissionPresets.meUp
      }
    },
    [ renderListItemEdit.call(this, h) ]
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-item': true,
        [`${services.contacts}-item`]: true,
        [`${services.contacts}-item--permanent`]: this.isPermanent,
        'hover-parent hover-parent--opacity': this.viewport.breakpoint.mdUp
      }
    },
    [
      renderInfo.call(this, h),
      renderCustomFields.call(this, h),
      renderActions.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
