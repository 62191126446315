import { permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'Item',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    hasCustomer() {
      return !!(this.value.customer || this.value.customerId)
    },
    hasOwner() {
      return !!(this.value.owner || this.value.ownerId)
    },
    isAdminAndMdUp() {
      return this.viewport.size.width > 1500 && this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)
    }
  },

  render
}
