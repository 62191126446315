export default {
  title: 'Obtener informe',

  titles: {
    encoding: 'Codificación del archivo',
    settings: 'Configuración avanzada de codificación'
  },

  labels: {
    encoding: 'Codificación',
    delimiter: 'Delimitador',
    includeRefunded: 'Incluir reembolsos'
  },

  warnings: {
    beforeRequest: '<b>La generación del informe tarda de 10 a 60 minutos.</b>',
    repeatRequest: 'Solicitar un informe idéntico aumenta el tiempo de generación.',
    afterRequest: 'Después de presionar el botón "Solicitar informe", la generación del informe continuará en nuestro servidor. El informe estará disponible en la sección "Archivos" una vez generado.'
  },

  snackbars: {
    requested: 'Informe solicitado con éxito',
    already: {
      requested: 'El informe ya ha sido solicitado. Debe esperar a que se genere y esté disponible en la sección "Archivos".'
    }
  }
}
