import { DICTIONARIES, DICTIONARIES_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, getLocaleDateString, getLocaleTimeString, icons, permissionPresets, services, sizes, states } from '@/utils'

import button from '@/components/button'
import buttonConfirmRemove from '@/components/button/confirm/remove'
import fileManager from '@/components/file/manager'
import arrow from '@/components/misc/arrow'
import sticker from '@/components/misc/sticker'
import preloader from '@/components/preloader'
import filter from '@/components/services/dictionaries/filter'
import HLR from '@/components/services/dictionaries/hlr'
import infoButton from '@/components/services/dictionaries/infoButton'
import localSearch from '@/components/services/dictionaries/localSearch'
import { dictionariesStatuses } from '@/components/services/dictionaries/utils'
import operatorGroups from '@/components/services/operatorGroups/template'
import tag from '@/components/tag'

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'preloader__holder pa-5'
    },
    [ h(preloader) ]
  )
}
function renderAbsolutePreloader(h, dictionary) {
  if ([ DICTIONARIES_STATUSES.compiling, DICTIONARIES_STATUSES.importing ].includes(dictionary.status)) {
    return h(
      'div',
      {
        class: 'preloader__holder preloader__holder--absolute'
      },
      [ h(preloader) ]
    )
  }
}

function renderHLR(h) {
  return h(HLR)
}
function renderFilter(h) {
  if (this.checkPermissions(`advanced.${services.dictionaries}.get`, permissionPresets.resellerUp)) {
    return h(
      filter,
      {
        props: {
          value: this.restData[services.dictionaries].find.filter
        },
        on: {
          input: event => {
            this.restData[services.dictionaries].find.filter = event
          }
        }
      }
    )
  }
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.dictionaries}-header`
    },
    [
      renderHLR.call(this, h),
      renderFilter.call(this, h)
    ]
  )
}

function renderInfoButton(h, dictionaryType) {
  return h(infoButton, { props: { dictionaryType } })
}
function renderImportButton(h, dictionaryType) {
  return h(
    button,
    {
      props: {
        icon: icons.get_app,
        mode: buttonModes.flat,
        color: colors.secondary,
        disabled: dictionaryType === DICTIONARIES['phone:mnp'] ? this.importIsDelayed : this.disableUploadButton(dictionaryType),
        tooltip: this.getTranslate(`${services.dictionaries}.tooltips.upload.intis`)
      },
      on: {
        click: () => {
          this.processDictionaryDataRequest({
            operation: 'import',
            dictionaryType,
            mix: dictionaryType === DICTIONARIES['phone:ranges'] ? true : undefined
          })
        }
      }
    }
  )
}
function renderCompileButton(h, dictionaryType) {
  return h(
    button,
    {
      props: {
        icon: icons.file_upload,
        mode: buttonModes.flat,
        color: colors.primary,
        disabled: this.disableUploadButton(dictionaryType),
        tooltip: this.getTranslate(`${services.dictionaries}.tooltips.upload.filemanager`)
      },
      on: {
        click: () => {
          this.currentDictionaryType = dictionaryType
          switch (dictionaryType) {
            case DICTIONARIES['phone:ranges']:
              this.showCompileDialog = true
              break
            default:
              this.showFileManager = true
              break
          }
        }
      }
    }
  )
}
function renderActions(h, dictionaryType) {
  if (
    this.checkPermissions(`advanced.${services.dictionaries}.create`, permissionPresets.resellerUp) &&
    this.restData[services.dictionaries].find.filterIsEqualToDefault
  ) {
    return h(
      'div',
      {
        class: `${services.dictionaries}-item__title__actions`
      },
      [
        renderInfoButton.call(this, h, dictionaryType),
        renderImportButton.call(this, h, dictionaryType),
        renderCompileButton.call(this, h, dictionaryType)
      ]
    )
  }
}
function renderTitle(h, dictionaryType) {
  return h(
    'div',
    {
      class: `${services.dictionaries}-item__title`
    },
    [
      h(
        tag,
        {
          props: {
            label: this.getTranslate(`${services.dictionaries}.titles.${dictionaryType}`),
            size: sizes.huge
          }
        }
      ),

      renderActions.call(this, h, dictionaryType)
    ]
  )
}

function renderID(h, dictionary) {
  const isOpGroups = dictionary.type === DICTIONARIES['phone:opgroups']
  const label = [
    this.getTranslate(`${services.dictionaries}.captions.createdAt`),
    getLocaleDateString(dictionary.createdAt),
    this.getTranslate('misc.in'),
    getLocaleTimeString(dictionary.createdAt)
  ].join(' ')

  let onClick
  if (isOpGroups) {
    onClick = () => {
      this.currentOperatorGroupsDictionary = dictionary
    }
  }

  return h(
    sticker,
    {
      props: {
        value: { dictionaryId: dictionary.id },
        label,
        pathToTitle: 'dictionaryId',
        copy: true,
        icon: dictionariesStatuses[dictionary.status].icon,
        iconTooltip: this.getTranslate(`${services.dictionaries}.statuses.${dictionary.status}`),
        color: dictionariesStatuses[dictionary.status].color,
        minWidth: 340,
        onClick
      }
    }
  )
}
function renderTotal(h, dictionary) {
  if (dictionary.type !== DICTIONARIES['phone:opgroups']) {
    return h(
      sticker,
      {
        props: {
          value: this.currentCompiledDictionaryTotal[dictionary.id] || dictionary.total,
          label: this.getTranslate(`${services.dictionaries}.labels.total`)
        }
      }
    )
  }
}

function renderStatusChangeButtons(h, dictionary) {
  if (this.checkPermissions(`advanced.${services.dictionaries}.update`, permissionPresets.resellerUp)) {
    let color
    let operation
    switch (dictionary.status) {
      case DICTIONARIES_STATUSES.ready: {
        color = colors.primary
        operation = 'compile'
        break
      }
      case DICTIONARIES_STATUSES.compiled: {
        color = colors.success
        operation = 'set'
        break
      }
      case DICTIONARIES_STATUSES.activated: {
        if (this.checkPermissions(`advanced.${services.dictionaries}.update`) && dictionary.OwnerId) {
          color = colors.error
          operation = 'unset'
          break
        }
      }
    }

    if (operation) {
      return h(
        button,
        {
          class: 'hover-child',
          props: {
            label: this.getTranslate(`${services.dictionaries}.buttons.${operation}`),
            mode: buttonModes.flat,
            color
          },
          on: {
            click: event => {
              event.stopPropagation()
              this.processDictionaryDataRequest({
                operation: operation,
                dictionaryType: dictionary.type,
                id: dictionary.id,
                OwnerId: dictionary.OwnerId
              })
            }
          }
        }
      )
    }
  }
}
function renderDesktopButtons(h, dictionary) {
  if (this.viewport.breakpoint.mdUp) {
    return renderStatusChangeButtons.call(this, h, dictionary)
  }
}
function renderMobileButtons(h, dictionary) {
  if (this.viewport.breakpoint.smDown) {
    return renderStatusChangeButtons.call(this, h, dictionary)
  }
}

function renderRemoveButton(h, dictionary) {
  if (this.checkPermissions(`advanced.${services.dictionaries}.remove`, permissionPresets.resellerUp)) {
    return h(
      buttonConfirmRemove,
      {
        class: 'hover-child',
        props: {
          disabled: dictionary.status === DICTIONARIES_STATUSES.activated,
          callback: () => {
            this.currentRemoveDictionary = dictionary
            this.processDictionaryDataRequest({
              operation: 'remove',
              dictionaryType: this.currentRemoveDictionary.type,
              id: this.currentRemoveDictionary.id,
              OwnerId: this.currentRemoveDictionary.OwnerId
            })
          }
        }
      }
    )
  }
}

function renderItemHeader(h, dictionary, expanded) {
  return h(
    'div',
    {
      class: {
        [`${services.dictionaries}-item__header`]: true,
        'hover-parent--opacity': this.viewport.breakpoint.mdUp
      }
    },
    [
      renderID.call(this, h, dictionary),
      renderTotal.call(this, h, dictionary),
      h('div', { class: 'ff' }),
      renderDesktopButtons.call(this, h, dictionary),
      h(arrow, { props: { expanded } }),
      renderAbsolutePreloader.call(this, h, dictionary)
    ]
  )
}
function renderItemBodyByType(h, dictionary) {
  switch (dictionary.type) {
    case DICTIONARIES['phone:mnp']:
    case DICTIONARIES['phone:ranges']: return h(localSearch, { props: { dictionary } })
    case DICTIONARIES['phone:opgroups']: {
      return h(
        operatorGroups,
        {
          props: {
            dictionary,
            removeCallback: () => {
              this.removeOperatorGroupsDictionary(dictionary)
            }
          }
        }
      )
    }
  }
}
function renderItemBodyActions(h, dictionary) {
  switch (dictionary.type) {
    case DICTIONARIES['phone:mnp']:
    case DICTIONARIES['phone:ranges']: {
      return h(
        'div',
        {
          class: `${services.dictionaries}-item__body-actions`
        },
        [
          renderMobileButtons.call(this, h, dictionary),
          renderRemoveButton.call(this, h, dictionary)
        ]
      )
    }
  }
}
function renderItemBody(h, dictionary) {
  return h(
    'div',
    {
      class: `${services.dictionaries}-item__body`
    },
    [
      renderItemBodyByType.call(this, h, dictionary),
      renderItemBodyActions.call(this, h, dictionary)
    ]
  )
}
function renderItem(h, dictionaryType) {
  switch (this.restData[services.dictionaries].find.state) {
    case states.loading: return renderPreloader.call(this, h)
    case states.ready:
    default: {
      if (Array.isArray(this.restData[services.dictionaries].find.data) && this.restData[services.dictionaries].find.data.length) {
        return this.restData[services.dictionaries].find.data.reduce((result, dictionary) => {
          if (dictionary.type === dictionaryType) {
            result.push(
              h(
                components['expansion-panel'],
                {
                  key: dictionary.id,
                  scopedSlots: {
                    header: ({ expanded }) => renderItemHeader.call(this, h, dictionary, expanded),
                    default: () => renderItemBody.call(this, h, dictionary)
                  }
                }
              )
            )
          }

          return result
        }, [])
      }

      return h(components.empty)
    }
  }
}
function renderItems(h, dictionaryType) {
  return h(
    components['expansion-panels'],
    {
      props: {
        outline: true,
        rounded: true
      },
      key: dictionaryType
    },
    [ renderItem.call(this, h, dictionaryType) ]
  )
}

function renderBody(h) {
  return h(
    'div',
    {
      class: `${services.dictionaries}-body`
    },
    [
      DICTIONARIES.map(dictionaryType => {
        return h(
          'div',
          {
            class: `${services.dictionaries}-body__item`
          },
          [
            renderTitle.call(this, h, dictionaryType),
            renderItems.call(this, h, dictionaryType)
          ]
        )
      })
    ]
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: `${services.dictionaries}-content`
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h)
    ]
  )
}

function renderCompileDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showCompileDialog,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showCompileDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'faic pa-3'
        },
        this.getTranslate(`${services.dictionaries}.titles.mix`)
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe pt-0',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.no'),
                flat: true,
                rounded: true,
                disabled: !this.checkPermissions(`advanced.${services.dictionaries}.update`, permissionPresets.resellerUp)
              },
              on: {
                click: () => {
                  this.mix = false
                  this.showFileManager = true
                  this.showCompileDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.yes'),
                rounded: true,
                depressed: true,
                disabled: !this.checkPermissions(`advanced.${services.dictionaries}.update`, permissionPresets.resellerUp),
                color: 'primary'
              },
              on: {
                click: () => {
                  this.mix = true
                  this.showFileManager = true
                  this.showCompileDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderFileManager(h) {
  return h(
    fileManager,
    {
      props: {
        show: this.showFileManager,
        flat: true,
        readonly: true,
        embedded: true,
        name: false,
        maxFileSize: 524288000, // 500 mb in binary
        types: [ 'csv', 'xls', 'xlsx' ]
      },
      on: {
        show: event => {
          this.showFileManager = event
        },
        input: event => {
          this.uploadHandler(event.id)
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: services.dictionaries
    },
    [
      renderContent.call(this, h),
      renderCompileDialog.call(this, h),
      renderFileManager.call(this, h)
    ]
  )
}
