import { NOTIFICATIONS_AVAILABLE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { inputModes, notificationsTypes, services } from '@/utils'

import filterDate from '@/components/filter/date'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'
import selectorOwner from '@/components/selector/owner'

function renderCreatedAt(h) {
  return h(
    filterDate,
    {
      props: {
        value: this.proxy.createdAt,
        preset: this.proxy.createdAtPreset,
        presets: true,
        backendGeneration: 'nest'
      },
      on: {
        input: event => {
          this.proxy.createdAt = event
        },
        preset: event => {
          this.proxy.createdAtPreset = event
        }
      }
    }
  )
}

function renderChannelSelect(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.channel,
        label: this.getTranslate(`${services.notifications}.labels.channel`),
        mode: inputModes.outline,
        items: NOTIFICATIONS_AVAILABLE_TYPES.map(type => {
          return {
            title: this.getTranslate(`sendings.types.${type}`),
            value: type
          }
        }),
        rounded: true,
        dense: true,
        details: false,
        clearable: true
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.value,
                title: item.title
              }
            }
          )
        },
        item: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.value,
                title: item.title,
                as: 'text'
              }
            }
          )
        }
      },
      on: {
        input: event => {
          this.proxy.channel = event
        }
      }
    }
  )
}

function renderSelectorOwner(h) {
  return h(
    selectorOwner,
    {
      props: {
        value: this.proxy.targetUserId,
        label: this.getTranslate(`${services.notifications}.labels.owner`),
        mode: inputModes.outline,
        dense: true
      },
      on: {
        input: event => {
          this.proxy.targetUserId = event
        }
      }
    }
  )
}

function renderTypeSelect(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.type,
        label: this.getTranslate(`${services.notifications}.labels.type`),
        mode: 'outline',
        items: notificationsTypes.map(type => {
          return {
            title: this.getTranslate(`${services.notifications}.events.${type}`),
            value: type
          }
        }),
        rounded: true,
        dense: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.type = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'notifications-filter'
    },
    [
      renderCreatedAt.call(this, h),
      renderChannelSelect.call(this, h),
      renderSelectorOwner.call(this, h),
      renderTypeSelect.call(this, h)
    ]
  )
}
