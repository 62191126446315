import { colors, components, permissionPresets, services, states } from '@/utils'

import button from '@/components/button'
import sticker from '@/components/misc/sticker'
import preloader from '@/components/preloader'
import accountForm from '@/components/services/account/form'
import documentTemplatesGenerateButton from '@/components/services/documentTemplates/generate/button'

function renderPreLoader(h) {
  return h(
    'div',
    {
      class: 'preloader__holder pa-5'
    },
    [ h(preloader) ]
  )
}

function renderUsername(h) {
  return h(
    sticker,
    {
      props: {
        value: this.username,
        label: this.name,
        iconSVG: this.verified ? 'user-verified' : 'user',
        color: this.verified ? colors.info : colors.grey,
        maxWidth: 300
      }
    }
  )
}
function renderDocumentTemplatesGenerateButton(h) {
  return h(
    documentTemplatesGenerateButton,
    {
      props: {
        OwnerId: this.account.id
      }
    }
  )
}
function renderHeader(h) {
  if (this.restData[services.users].get.state === 'ready') {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        style: {
          gridTemplateColumns: 'repeat(2, auto)',
          justifyContent: 'space-between'
        }
      },
      [
        renderUsername.call(this, h),
        renderDocumentTemplatesGenerateButton.call(this, h)
      ]
    )
  }
}

function renderForm(h) {
  return h(
    accountForm,
    {
      props: {
        value: this.restData[services.users].get.data,
        errors: this.restData[services.users].errors
      },
      on: {
        input: event => {
          this.restData[services.users].get.data = event
        }
      }
    }
  )
}
function renderCardContent(h) {
  if (this.restData[services.users].get.state === states.loading) {
    return renderPreLoader.call(this, h)
  } else if (this.restData[services.users].get.state === states.ready) {
    return renderForm.call(this, h)
  }

  return h(components.empty)
}
function renderBody(h) {
  return h(
    components.card,
    {
      props: {
        rounded: true,
        outline: true
      }
    },
    [ renderCardContent.call(this, h) ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.update'),
            color: colors.primary,
            loading: this.restData[services.users].update.state === states.loading,
            disabled: this.restData[services.users].update.state === states.loading
          },
          on: {
            click: () => {
              this.rest[services.users].update(this.restData[services.users].get.data.id, this.restData[services.users].get.data)
            }
          }
        }
      )
    ]
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}

export default function(h) {
  if (this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.meUp)) {
    return h(
      'div',
      {
        class: 'grid faifs',
        style: { 'grid-template-columns': this.viewport.breakpoint.lgUp ? '800px 1fr' : undefined }
      },
      [ renderContent.call(this, h) ]
    )
  }
}
