import { moderation } from './misc'
import { types } from './sendings'

export default {
  title: 'Rules',

  titles: {
    conditions: 'Conditions',
    actions: 'Actions'
  },

  labels: {
    title: 'Rule title',
    type: 'Type',
    text: 'Text',
    sender: 'Sender',
    routingRule: 'Rule',
    condition: 'Condition',
    providerGroup: 'Providers group',
    percentage: 'Percentage',
    search: 'Search',
    textToCheck: 'Text to check',
    group: {
      pattern: 'Pattern for search group'
    },
    add: {
      condition: 'Add condition'
    },
    phone: 'Phone',
    keywords: 'Keywords lists',
    contactList: 'Contact list',
    fallbacks: 'Fallbacks',
    buttons: 'Buttons',

    ...types,
    ...moderation
  },

  tags: {
    text: 'Text',
    sender: 'Sender',
    recipient: 'Recipient',
    code: 'Operator code',
    group: 'Operator group',
    mcc: 'MCC',
    mnc: 'MNC',
    mccmnc: 'MCC MNC',
    contactList: 'Contact list',
    type: 'Type',
    moderation: 'Moderation',
    keywords: 'Keywords',
    fallbacks: 'Fallbacks',
    buttons: 'Buttons'
  },

  hints: {
    noRule: 'Condition not selected yet. Click on this line to select.'
  },

  buttons: {
    add: {
      rule: 'Add rule',
      group: 'Add group',
      condition: 'Add condition'
    },
    remove: {
      rule: 'Remove rule',
      group: 'Remove group'
    }
  }
}
