import { SENDING_TYPES, TTS_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { allowedSendingTypesForImage, audioStatuses, buttonModes, colors, components, icons, permissionPresets, popperPlacements, services } from '@/utils'

import button from '@/components/button'
import fileItemCard from '@/components/file/item/card'
import fileManager from '@/components/file/manager'
import sticker from '@/components/misc/sticker'

function renderCleanAttachButton(h) {
  return h(
    button,
    {
      props: {
        icon: icons.clear,
        mode: buttonModes.flat
      },
      on: {
        click: () => {
          this.cleanAttach()
        }
      }
    }
  )
}
function renderFileItemCard(h, value) {
  return h(
    fileItemCard,
    {
      props: {
        value,
        maxWidth: 300,
        displayMenu: false
      }
    },
    [ renderCleanAttachButton.call(this, h) ]
  )
}
function renderAttachFileInfo(h) {
  if (
    ~this.attachFileTypes.indexOf(this.attachFileType) &&
    this.proxy.payload[this.attachFileType]
  ) {
    return renderFileItemCard.call(this, h, this.proxy.payload[this.attachFileType])
  }
}
function renderAttachFileButton(h) {
  if (this.checkPermissions(`advanced.${services.storage}.upload`, permissionPresets.meUp)) {
    if (this.showAttachButton) {
      return h(
        components.menu,
        {
          props: {
            maxWidth: 200,
            rounded: true,
            offsetDistance: 8,
            placement: popperPlacements['bottom-start']
          }
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('sendingsCreate.attachMenu.attach'),
                mode: buttonModes.flat,
                icon: icons.attach_file
              },
              slot: 'activator'
            }
          ),

          h(
            components.list,
            {
              props: {
                dense: true,
                rounded: true
              }
            },
            this.attachFileTypes.map(item => {
              return h(
                components['list-item'],
                {
                  props: {
                    label: this.getTranslate(`sendingsCreate.attachMenu.items.${item}`),
                    dense: true
                  },
                  on: {
                    click: () => {
                      this.attachFileType = item
                      this.showFileManager = true
                    }
                  }
                }
              )
            })
          )
        ]
      )
    }
  }
}
function renderAttachFile(h) {
  if (~allowedSendingTypesForImage.indexOf(this.proxy.type)) {
    return h(
      'div',
      {
        class: 'grid'
      },
      [
        renderAttachFileButton.call(this, h),
        renderAttachFileInfo.call(this, h)
      ]
    )
  }
}

function renderTTSMenu(h) {
  if (!this.proxy.payload.tts) {
    this.proxy.payload.tts = TTS_TYPES[0]
  }

  return h(
    components.menu,
    {
      props: {
        minWidth: 150,
        maxHeight: 200,
        overflowY: 'auto',
        offsetDistance: 8,
        placement: popperPlacements['bottom-end'],
        disabled: this.timerCount > 0
      }
    },
    [
      h(
        sticker,
        {
          props: {
            value: this.getTranslate(`sendingsCreate.voice.names.${this.proxy.payload.tts}`),
            label: this.getTranslate(`sendingsCreate.voice.company.${this.proxy.payload.tts.split(':')[0]}`),
            icon: icons.record_voice_over,
            color: colors.grey,
            width: 150,
            disabled: this.timerCount > 0,
            to: { service: undefined }
          },
          slot: 'activator'
        }
      ),

      h(
        components.list,
        {
          props: {
            dense: true
          }
        },
        TTS_TYPES.map((type, index) => {
          return h(
            components['list-item'],
            {
              props: { dense: true },
              on: {
                click: () => {
                  this.generatedAudioFile = undefined
                  this.proxy.payload.tts = type
                  this.generateAudioFile()
                }
              },
              key: index
            },
            [
              h(
                'div',
                {
                  class: 'fc'
                },
                [
                  this.getTranslate(`sendingsCreate.voice.names.${type}`),

                  h(
                    'div',
                    {
                      class: 'small text--grey'
                    },
                    this.getTranslate(`sendingsCreate.voice.company.${type.split(':')[0]}`)
                  )
                ]
              )
            ]
          )
        })
      )
    ]
  )
}

function renderUploadButton(h) {
  if (this.proxy.payload.audio) {
    return renderAttachFileInfo.call(this, h)
  }

  return h(
    button,
    {
      props: {
        label: this.getTranslate('sendingsCreate.voice.upload'),
        color: colors.primary,
        mode: buttonModes.flat,
        icon: icons.file_upload
      },
      on: {
        click: () => {
          this.showFileManager = true
        }
      }
    }
  )
}

function renderGenerateButtonOrFileItemPlayer(h) {
  if (this.generatedAudioFile) {
    return renderFileItemCard.call(this, h, this.generatedAudioFile.data)
  }

  return h(
    button,
    {
      props: {
        label: this.timerCount,
        icon: icons.play_arrow,
        mode: buttonModes.flat,
        color: colors.primary,
        loading: this.isGenerate,
        disabled: !this.proxy.payload.text || this.timerCount > 0
      },
      on: {
        click: () => {
          this.generateAudioFile()
        }
      }
    }
  )
}

function renderVoiceActions(h) {
  if (this.isGeneratedFile) {
    return h(
      'div',
      {
        class: 'facfs grid-gap--8'
      },
      [
        renderTTSMenu.call(this, h),
        renderGenerateButtonOrFileItemPlayer.call(this, h)
      ]
    )
  }

  return renderUploadButton.call(this, h)
}
function renderVoiceInfo(h) {
  return h(
    'div',
    {
      class: 'text--grey caption mt-2'
    },
    this.isGeneratedFile ? this.getTranslate('sendingsCreate.voice.misc.generatedFile') : this.getTranslate('sendingsCreate.voice.misc.uploadedFile')
  )
}
function renderVoiceInfoSwitch(h) {
  if (this.hasPerSecondTariff) {
    return h(
      'div',
      {
        class: 'text--grey caption'
      },
      [
        this.getTranslate('sendingsCreate.voice.misc.can'),
        h(
          'span',
          {
            class: 'ml-1 link link--dashed text--primary',
            on: {
              click: () => {
                this.setStatus(audioStatuses.pause)
                this.isGeneratedFile = !this.isGeneratedFile
                if (this.proxy.payload.audio) {
                  delete this.proxy.payload.audio
                }
              }
            }
          },
          this.isGeneratedFile ? this.getTranslate('sendingsCreate.voice.misc.ownFile') : this.getTranslate('sendingsCreate.voice.misc.textFile')
        )
      ]
    )
  }
}
function renderChoiceVoiceSendings(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderVoiceActions.call(this, h),
      renderVoiceInfo.call(this, h),
      renderVoiceInfoSwitch.call(this, h)
    ]
  )
}

function renderContentByType(h) {
  if (this.proxy.type === SENDING_TYPES.voice) {
    return renderChoiceVoiceSendings.call(this, h)
  } else {
    return h(
      'div',
      {
        class: {
          'grid grid-gap--8': true,
          'grid-cols--2': this.viewport.breakpoint.mdUp
        }
      },
      [
        renderAttachFile.call(this, h),
        this.$slots.default
      ]
    )
  }
}

function renderFileManager(h) {
  return h(
    fileManager,
    {
      props: {
        currentChosenFileId: this.proxy.payload.audio || this.proxy.payload.file || this.proxy.payload.image,
        flat: true,
        readonly: true,
        disabled: this.disabled || this.readonly,
        embedded: true,
        show: this.showFileManager,
        types: this.computedFileType,
        isPublic: true
      },
      on: {
        show: event => {
          this.showFileManager = event
        },
        input: event => {
          this.setPayload(event)
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderContentByType.call(this, h),
      renderFileManager.call(this, h)
    ]
  )
}
