export default {
  title: 'Plantillas',

  labels: {
    id: 'ID',
    title: 'Título',
    type: 'Tipo',
    owner: 'Propietario',
    text: 'Texto',
    isActive: 'Activo'
  },

  buttons: {
    create: 'Crear plantilla',
    apply: 'Aplicar'
  },
  links: { edit: 'Editar plantilla' },
  dialogs: { create: 'Nombre su plantilla' },

  contents: {
    confirm: {
      remove: '¿Está seguro de que desea eliminar la plantilla {title}?'
    }
  },

  tooltips: {
    isActive: 'Activar o desactivar la plantilla.'
  },

  snackbars: {
    created: 'Plantilla creada con éxito.',
    updated: 'Plantilla actualizada con éxito.',
    removed: 'Plantilla eliminada con éxito.'
  }
}
