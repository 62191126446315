import { buttonModes, colors, components, inputModes } from '@/utils'

import button from '@/components/button'
import additionalComponent from '@/components/misc/rules/commons/dialog/additional'
import mainComponent from '@/components/misc/rules/commons/dialog/main'
import { className, ruleClasses, ruleTypes } from '@/components/misc/rules/utils'

function renderTagSelect(h) {
  if (this.tagEdit && !this.readonly) {
    return h(
      components.select,
      {
        props: {
          value: this.tag,
          label: this.getTranslate(`${className}.tags.${this.tag}`),
          items: ruleClasses[this.ruleType].tagsOnly.map(tag => {
            return {
              title: this.getTranslate(`${className}.tags.${tag}`),
              value: tag
            }
          }),
          itemsDisabled: this.tagDisabled,
          mode: inputModes.outline,
          dense: true,
          rounded: true,
          details: false,
          required: true
        },
        on: {
          input: event => {
            this.clickHandler('reset')
            this.$emit('tag', event)
          }
        }
      }
    )
  }
}
function renderMainComponent(h) {
  if (this.tag) {
    return h(
      mainComponent,
      {
        class: {
          [`${className}-dialog__content-section`]: true
        },
        props: {
          value: this.proxy,
          tag: this.tag,
          ruleType: this.ruleType,
          readonly: this.readonly
        },
        on: {
          input: event => {
            this.proxy = event
          }
        },
        ref: 'mainComponent'
      }
    )
  }
}
function renderAdditionalComponent(h) {
  if (this.ruleType === ruleTypes.conditions) {
    if (!this.readonly) {
      return h(
        additionalComponent,
        {
          class: `${className}-dialog__content-section`,
          props: {
            value: this.proxy,
            tag: this.tag,
            readonly: this.readonly
          },
          ref: 'additionalComponent'
        }
      )
    }
  }
}
function renderContent(h) {
  if (this.tag) {
    return h(
      'div',
      {
        class: `${className}-dialog__content`
      },
      [
        renderTagSelect.call(this, h),
        renderMainComponent.call(this, h),
        renderAdditionalComponent.call(this, h)
      ]
    )
  }
}

function renderCloseButton(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        label: this.getTranslate('misc.buttons.close')
      },
      on: {
        click: () => {
          this.clickHandler('close')
        }
      }
    }
  )
}
function renderResetButton(h) {
  if (!this.readonly) {
    return h(
      button,
      {
        props: {
          label: this.getTranslate('misc.buttons.clear'),
          mode: buttonModes.flat
        },
        on: {
          click: () => {
            this.clickHandler('reset')
          }
        }
      }
    )
  }
}
function renderSaveButton(h) {
  if (!this.readonly) {
    return h(
      button,
      {
        props: {
          label: this.getTranslate('misc.buttons.save'),
          disabled: this.readonly,
          color: colors.primary
        },
        on: {
          click: () => {
            this.clickHandler('save')
          }
        }
      }
    )
  }
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: `${className}-dialog__footer`,
      slot: 'footer'
    },
    [
      renderCloseButton.call(this, h),
      h('div', { class: 'ff' }),
      renderResetButton.call(this, h),
      renderSaveButton.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    components.dialog,
    {
      class: `${className}-dialog`,
      props: {
        title: this.title,
        value: this.show,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 500 : undefined
      },
      on: {
        input: event => {
          this.$emit('show', event)
        }
      }
    },
    [
      renderContent.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
