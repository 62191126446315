import { components, inputModes, services } from '@/utils'

import { className } from '@/components/misc/rules/utils'

function renderText(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.text,
        label: this.getTranslate(`${services.sendings}.labels.button.text`),
        mode: inputModes['line-label'],
        rounded: true,
        details: false,
        dense: true,
        disabled: this.disabled
      },
      on: {
        input: event => {
          this.proxy.text = event
        }
      }
    }
  )
}

function renderAction(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.action,
        label: this.getTranslate(`${services.sendings}.labels.button.action`),
        mode: inputModes['line-label'],
        rounded: true,
        details: false,
        dense: true,
        disabled: this.disabled
      },
      on: {
        input: event => {
          this.proxy.action = event
        }
      }
    }
  )
}

function renderButtonFields(h) {
  return h(
    'div',
    {
      class: `${className}-card__row`
    },
    [
      renderText.call(this, h),
      renderAction.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    components.card,
    {
      class: `${className}-card`,
      props: {
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [ renderButtonFields.call(this, h) ]
  )
}
