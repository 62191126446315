import { components } from '@/utils'

import copyright from '@/components/misc/copyright'
import localeSwitch from '@/components/misc/locale'

function renderLinks(h) {
  const routeNames = []
  switch (this.$route.name) {
    case 'login': {
      routeNames.push('registration')
      break
    }

    default: {
      routeNames.push('login')
      break
    }
  }
  routeNames.push('documentation')

  return routeNames.map(name => {
    return h(
      'router-link',
      {
        class: 'link caption',
        props: { to: { name } }
      },
      [ this.getTranslate(`${name}.title`) ]
    )
  })
}

export default function(h) {
  return h(
    components.app,
    {},
    [
      h(
        components.content,
        {
          style: { placeContent: 'center' }
        },
        [ this.$slots.default ]
      ),

      h(
        components.footer,
        {
          class: {
            'flex grid-gap--8 px-2': true,
            'fjcc facc': this.viewport.breakpoint.mdUp
          },
          props: {
            fixed: true,
            height: 38
          }
        },
        [
          h(copyright),
          h(localeSwitch),

          renderLinks.call(this, h)
        ]
      )
    ]
  )
}
