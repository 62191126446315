import sticker from '@/components/misc/sticker'
import { serviceName } from '@/components/services/servers/utils'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value.processor,
        icon: 'settings',
        color: 'grey',
        label: this.getTranslate(`${serviceName}.labels.processor`),
        borderStyle: 'dashed'
      }
    }
  )
}
