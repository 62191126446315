import Vue from 'vue'

import { buttonModes, colors, generateUniqueKey, icons, services, sizes } from '@/utils'

import button from '@/components/button'
import squircle from '@/components/icon/squircle'
import { className } from '@/components/misc/rules/utils'
import ruleActions from '@/components/services/tariffs/rules/rule/actions'
import ruleConditions from '@/components/services/tariffs/rules/rule/conditions'
import tag from '@/components/tag'

function renderHandle(h) {
  return h(
    squircle,
    {
      attrs: { id: 'drag_handle' },
      class: 'drag_handle',
      props: {
        icon: icons.drag_handle,
        size: sizes.medium,
        color: colors.grey
      }
    }
  )
}
function renderTitle(h) {
  return h(
    tag,
    {
      props: {
        label: this.getTranslate(`${services.tariffs}.rule`, { rule: this.index + 1 }),
        size: sizes.huge,
        color: colors.grey
      }
    }
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.tariffs}-rule__header`
    },
    [
      renderHandle.call(this, h),
      renderTitle.call(this, h)
    ]
  )
}

function renderActions(h) {
  return h(
    ruleActions,
    {
      props: {
        value: this.proxy,
        source: this.source
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderRule(h, rule, index) {
  return h(
    ruleConditions,
    {
      props: {
        value: rule.conditions,
        type: this.source
      },
      key: generateUniqueKey(),
      on: {
        input: event => {
          rule.conditions = event
        },
        remove: () => {
          this.proxy.rules.splice(index, 1)
        }
      }
    }
  )
}
function renderRules(h) {
  if (this.hasRules) {
    return h(
      'div',
      {
        class: `${services.tariffs}-rule__rules`
      },
      [
        this.proxy.rules.map((rule, index) => {
          return renderRule.call(this, h, rule, index)
        })
      ]
    )
  }
}

function renderBody(h) {
  return h(
    'div',
    {
      class: `${services.tariffs}-rule__body`
    },
    [
      renderActions.call(this, h),
      renderRules.call(this, h)
    ]
  )
}

function renderRemoveRuleButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate(`${className}.buttons.remove.rule`),
        size: sizes.small,
        mode: buttonModes.flat,
        color: colors.error
      },
      on: {
        click: () => {
          Vue.delete(this.target, this.index)
        }
      }
    }
  )
}
function renderAddGroupButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate(`${className}.buttons.add.group`),
        size: sizes.small,
        mode: buttonModes.flat,
        color: colors.primary
      },
      on: {
        click: () => {
          this.addConditions()
        }
      }
    }
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: `${services.tariffs}-rule__footer`
    },
    [
      renderRemoveRuleButton.call(this, h),
      renderAddGroupButton.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.tariffs}-rule`
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
