import { PROVIDER_STATES } from '@sigma-legacy-libs/essentials/lib/constants'

import sticker from '@/components/misc/sticker'
import fixButton from '@/components/services/inactiveProviders/buttons/fix'
import { serviceName } from '@/components/services/providerGroups/utils'

const colors = {
  active: 'success',
  inactive: 'grey',
  connecting: 'primary',
  disconnecting: 'error',
  stopped: 'warning',
  'half-open': 'info',
  'error-code': 'error',
  'error-connection': 'error'
}
const icons = {
  active: 'check_circle_outline',
  inactive: 'pause_circle_outline',
  connecting: 'sync',
  disconnecting: 'highlight_off',
  stopped: 'do_not_disturb_on',
  'half-open': 'access_time',
  'error-code': 'error_outline',
  'error-connection': 'error_outline'
}

function renderSearch(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.search,
        label: 'Search',
        suffix: this.computedProviderGroups.length ? this.computedProviderGroups.length : undefined,
        mode: 'outline',
        afterIcon: 'search',
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: value => {
          this.search = value
        }
      }
    }
  )
}

function renderUpdateDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showUpdateDialog,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showUpdateDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'headline pa-3',
          slot: 'header'
        },
        this.getTranslate('inactiveProviders.titles.confirm.update')
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--16 pb-3 px-3'
        },
        [
          h(
            'div',
            {
              domProps: {
                innerHTML: this.getTranslate('inactiveProviders.contents.confirm.update')
              }
            }
          ),

          h(
            'div',
            {
              class: 'grid grid-gap--0'
            },
            [
              h(
                'div',
                {
                  class: 'grid grid-gap--0 faic',
                  style: { gridTemplateColumns: 'auto 1fr' }
                },
                [
                  h(
                    'g-checkbox',
                    {
                      props: {
                        value: this.check.update
                      },
                      on: {
                        input: event => {
                          this.check.update = event
                        }
                      }
                    }
                  ),

                  h(
                    'div',
                    {},
                    this.getTranslate('inactiveProviders.labels.update.check')
                  )
                ]
              ),

              h(
                'div',
                {
                  class: 'grid grid-gap--0 faic',
                  style: { gridTemplateColumns: 'auto 1fr' }
                },
                [
                  h(
                    'g-checkbox',
                    {
                      props: {
                        value: this.check.showUpdateDialog
                      },
                      on: {
                        input: event => {
                          this.check.showUpdateDialog = event
                          const prefix = `${this.account.id}:inactiveProviders:showUpdateDialog`
                          if (event === true) {
                            window.localStorage.setItem(prefix, true)
                          } else {
                            window.localStorage.removeItem(prefix)
                          }
                        }
                      }
                    }
                  ),

                  h(
                    'div',
                    {},
                    this.getTranslate('inactiveProviders.labels.update.showUpdateDialog')
                  )
                ]
              )
            ]
          )
        ]
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.cancel')
              },
              on: {
                click: () => {
                  this.showUpdateDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                rounded: true,
                depressed: true,
                label: this.getTranslate('misc.buttons.update'),
                color: 'error',
                disabled: !this.check.update
              },
              on: {
                click: () => {
                  this.getProviderGroups()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderUpdateButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        icon: 'flash_on',
        label: this.getTranslate('misc.buttons.update'),
        flat: true,
        rounded: true,
        color: 'error',
        loading: this.providerGroupsLoading,
        disabled: this.providerGroupsLoading
      },
      on: {
        click: () => {
          const check = window.localStorage.getItem(`${this.account.id}:inactiveProviders:showUpdateDialog`)
          if (check === 'true') {
            this.getProviderGroups()
          } else {
            this.showUpdateDialog = true
          }
        }
      }
    }
  )
}

function renderFilter(h) {
  return h(
    'div',
    {
      class: `${serviceName}-inactive__filter`
    },
    [
      renderSearch.call(this, h),
      renderUpdateButton.call(this, h)
    ]
  )
}

function renderProvider(h, provider) {
  return h(
    'div',
    {
      class: `${serviceName}-inactive__provider`
    },
    [
      h(
        sticker,
        {
          props: {
            value: provider.id,
            service: 'providers',
            label: this.getTranslate('providers.labels.title'),
            icon: icons[provider.state],
            color: colors[provider.state],
            target: '_blank'
          }
        }
      ),

      h(
        fixButton,
        {
          props: {
            provider,
            providerGroup: this.providerGroup,
            loading: this.providersLoading || this.providerGroupsLoading,
            disabled: this.providersLoading || this.providerGroupsLoading
          },
          on: {
            fix: async () => {
              this.getProviders()
            }
          }
        }
      )
    ]
  )
}

function renderProviders(h) {
  return h(
    'div',
    {
      class: `${serviceName}-inactive__providers`
    },
    this.computedProviders.map(provider => renderProvider.call(this, h, provider))
  )
}

function renderProvidersDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showProvidersDialog,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showProvidersDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'headline pa-3',
          slot: 'header'
        },
        this.getTranslate('inactiveProviders.titles.fix')
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--16 pb-3 px-3'
        },
        [ renderProviders.call(this, h, this.providers) ]
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                icon: 'refresh',
                flat: true,
                rounded: true,
                loading: this.providersLoading,
                disabled: this.providersLoading
              },
              on: {
                click: () => {
                  this.getProviders()
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.close')
              },
              on: {
                click: () => {
                  this.showProvidersDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderProviderGroup(h, providerGroup) {
  return h(
    'div',
    {
      class: {
        [`${serviceName}-inactive__item`]: true,
        'hover-parent': true,
        'hover-parent--opacity': true
      }
    },
    [
      h(
        'div',
        {
          class: `${serviceName}-inactive__item-row ${serviceName}-inactive__item-row--first`
        },
        [
          h(
            sticker,
            {
              props: {
                value: providerGroup.title,
                label: this.getTranslate(`${serviceName}.labels.title`),
                to: {
                  name: `${serviceName}.single`,
                  params: { id: providerGroup.id }
                },
                target: '_blank'
              }
            }
          ),

          h(
            sticker,
            {
              props: {
                value: providerGroup.Providers.filter(item => item.state === PROVIDER_STATES.inactive).length.toString(),
                label: this.getTranslate(`providers.states.${PROVIDER_STATES.inactive}`),
                icon: icons[PROVIDER_STATES.inactive],
                color: colors[PROVIDER_STATES.inactive]
              }
            }
          ),

          h(
            'g-button',
            {
              class: 'hover-child ma-0',
              props: {
                icon: 'build',
                flat: true,
                color: 'primary',
                loading: this.providersLoading,
                disabled: this.providersLoading
              },
              on: {
                click: () => {
                  this.getProviders(providerGroup)
                }
              }
            }
          )
        ]
      )

      // h(
      //   'div',
      //   {
      //     class: `${serviceName}-inactive__item-row ${serviceName}-inactive__item-row--second`
      //   },
      //   PROVIDER_STATES.map(state => {
      //     return h(
      //       sticker,
      //       {
      //         props: {
      //           value: item.Providers.filter(item => item.state === state).length.toString(),
      //           label: this.getTranslate(`providers.states.${state}`),
      //           icon: icons[state],
      //           color: colors[state]
      //         }
      //       }
      //     )
      //   })
      // )
    ]
  )
}

function renderProviderGroups(h) {
  return h(
    'div',
    {
      class: `${serviceName}-inactive__items`
    },
    this.computedProviderGroups.map(item => renderProviderGroup.call(this, h, item))
  )
}

function renderWarning(h) {
  return h(
    'div',
    {
      class: `${serviceName}-inactive__warning`
    },
    [
      h(
        'div',
        {
          class: `${serviceName}-inactive__warning-text`
        },
        [
          h(
            'g-icon',
            {
              props: {
                size: 48,
                color: 'grey'
              }
            },
            'warning'
          ),

          h(
            'div',
            {
              domProps: {
                innerHTML: this.getTranslate('inactiveProviders.warnings.general')
              }
            }
          )
        ]
      )
    ]
  )
}

function renderContent(h) {
  if (this.computedProviderGroups.length) {
    return renderProviderGroups.call(this, h)
  }

  return h('g-empty')
}

function renderHolder(h) {
  return h(
    'div',
    {
      class: `${serviceName}-inactive__holder`
    },
    [ renderContent.call(this, h) ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${serviceName}-inactive`
    },
    [
      renderFilter.call(this, h),
      renderWarning.call(this, h),
      renderHolder.call(this, h),
      renderUpdateDialog.call(this, h),
      renderProvidersDialog.call(this, h)
    ]
  )
}
