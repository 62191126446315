export default {
  title: 'Moderación',

  labels: {
    task: 'Tarea de moderación',
    pattern: 'Texto del mensaje y plantilla',
    defaultRoute: 'Ruta predeterminada',
    sender: 'Nombre del remitente',
    text: 'Texto',
    moderation: 'Moderación',
    status: 'Estado',
    id: 'Id',
    providerId: 'Proveedor',
    OwnerId: 'Propietario',
    recipientsCount: 'Destinatarios / segmentos',
    updatedAt: 'Actualizado',
    createdAt: 'Creado',
    operator: 'Operador',
    tasks: {
      status: {
        init: 'Iniciado',
        processing: 'En proceso',
        success: 'Completado',
        error: 'Error'
      },
      moderation: {
        rejected: 'Rechazado',
        approved: 'Aprobado'
      }
    },
    sendings: {
      sendingId: 'Id'
    },
    sendingsList: 'Lista de envíos'
  },

  tabs: {
    sendings: 'Mensajes',
    tasks: 'Tareas'
  },

  actions: {
    approved: 'Aprobar',
    rejected: 'Rechazar',
    rejectedRefound: 'Rechazar y devolver dinero',
    massive: {
      approved: 'Aprobar todo',
      rejected: 'Rechazar todo',
      rejectedRefound: 'Rechazar todo y devolver dinero'
    }
  },

  tooltips: {
    info: 'En el campo de ruta predeterminada, puede seleccionar un proveedor específico al que enviar el mensaje. Si deja el campo vacío, la ruta del mensaje se seleccionará en el servidor como de costumbre, utilizando la configuración del usuario específico.',
    buttons: {
      openInNew: 'Abrir en una nueva pestaña',
      counter: 'Contador de mensajes en moderación',
      refresh: 'Actualizar'
    },
    recipientsCount: 'Número de destinatarios / segmentos',
    tasks: {
      status: {
        init: 'Iniciado',
        processing: 'En proceso',
        success: 'Completado',
        error: 'Error'
      },
      moderation: {
        rejected: 'Rechazado',
        approved: 'Aprobado'
      }
    },
    createdAt: {
      min: 'Hora del primer mensaje',
      max: 'Hora del último mensaje'
    }
  },

  hints: {
    sendings: {
      info: 'La lista de mensajes a continuación puede estar vacía por un tiempo.'
    },
    provider: 'Por ruta'
  }
}
