import { permissionPresets, services } from '@/utils'

import generatorSearch from '@/components/generator/search'
import { tariffInputFilter, tariffOutputFilter } from '@/components/services/tariffs/utils'
import templateLegacy from '@/components/templates/legacy'

import render from './render'

export default {
  name: services.tariffs,

  components: {
    'search-generator': generatorSearch({
      name: services.tariffs,

      inputFilterForCreateButton: tariffInputFilter,
      outputFilterForCreateButton: tariffOutputFilter,

      find: {
        defaultFilter: {
          isActive: true,
          source: undefined
        }
      }
    }),

    'get-create-template': templateLegacy({
      name: services.tariffs,

      inputFilter: tariffInputFilter,
      outputFilter: tariffOutputFilter,

      find: false,
      get: {
        params: {
          query: {
            $scope: [ 'Owner' ]
          }
        }
      },
      update: {
        params: {
          query: {
            $scope: [ 'Owner' ]
          }
        }
      },
      remove: {
        params: {
          query: {
            $scope: [ 'Owner' ]
          }
        },
        redirect: true
      }
    })
  },

  computed: {
    sourceItems() {
      const result = []
      const permission = `advanced.${services.tariffs}.find`
      if (this.checkPermissions(permission, permissionPresets.resellerUp)) {
        result.push(...this.getSendingTypesByPermission(permission).map(type => `sendings.${type}`))
        result.push('hlr')
      }

      return result
    }
  },

  render
}
