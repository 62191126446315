export const components = {
  app: 'g-app',
  button: 'g-button',
  card: 'g-card',
  'card-actions': 'g-card-actions',
  checkbox: 'g-checkbox',
  chip: 'g-chip',
  content: 'g-content',
  'date-picker': 'g-date-picker',
  dialog: 'g-dialog',
  divider: 'g-divider',
  empty: 'g-empty',
  'expansion-panel': 'g-expansion-panel',
  'expansion-panels': 'g-expansion-panels',
  footer: 'g-footer',
  form: 'g-form',
  'group-button': 'g-group-button',
  icon: 'g-icon',
  input: 'g-input',
  list: 'g-list',
  'list-group': 'g-list-group',
  'list-item': 'g-list-item',
  menu: 'g-menu',
  overlay: 'g-overlay',
  progress: 'g-progress',
  select: 'g-select',
  sidebar: 'g-sidebar',
  snackbar: 'g-snackbar',
  subheader: 'g-subheader',
  switch: 'g-switch',
  table: 'g-table',
  tabs: 'g-tabs',
  'tabs-header': 'g-tabs-header',
  tag: 'g-tag',
  'text-field': 'g-text-field',
  textarea: 'g-textarea',
  toolbar: 'g-toolbar',
  tooltip: 'g-tooltip'
}
