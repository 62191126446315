import { buttonModes, colors, components, icons, popperPlacements, sizes } from '@/utils'

import button from '@/components/button'
import messagePayloadGeneratorButton from '@/components/services/message-payload-generator/button'
import utmGenerator from '@/components/services/sendingsCreate/utm'
import tag from '@/components/tag'

function renderButton(h, trigger) {
  if (this.readonly) {
    return
  }

  if (trigger) {
    return h(
      button,
      {
        attrs: { id: `button-${this._uid}` },
        props: {
          icon: icons.settings_ethernet,
          mode: buttonModes.flat,
          color: colors.primary,
          disabled: this.disabled,
          tooltip: this.getTranslate('sendingsCreate.tooltips.insertVariables')
        },
        ref: `button-${this._uid}`,
        key: `button-${this._uid}`,
        slot: 'activator',
        on: {
          click: () => {
            this.blurInput()
            if (this.viewport.breakpoint.smDown) {
              this.showDialog = true
            }
          }
        }
      }
    )
  }
}
function renderListItem(h, item, index) {
  return h(
    components['list-item'],
    {
      props: {
        label: this.getTranslate(item.title),
        active: index === this.currentOption,
        dense: true
      },
      on: {
        click: () => {
          this.insertText(item.value)
          if (this.viewport.breakpoint.smDown) {
            this.showDialog = false
          }
        }
      },
      key: `${item.value}-${index}`
    }
  )
}
function renderList(h, items) {
  return h(
    components.list,
    {
      props: {
        dense: true
      }
    },
    items.map((item, index) => {
      return renderListItem.call(this, h, item, index)
    })
  )
}
function renderFooter(h) {
  if (this.viewport.breakpoint.smDown) {
    return h(
      'div',
      {
        class: 'fjcfe pa-2',
        slot: 'footer'
      },
      [
        h(
          button,
          {
            props: {
              label: this.getTranslate('misc.buttons.close'),
              mode: buttonModes.flat
            },
            on: {
              click: () => {
                this.showDialog = false
                this.focusInput()
              }
            }
          }
        )
      ]
    )
  }
}
function renderTagMenu(h) {
  if (this.readonly) {
    return
  }

  return h(
    this.viewport.breakpoint.smDown ? components.dialog : components.menu,
    {
      attrs: { id: `menu-button-${this._uid}` },
      props: {
        value: this.viewport.breakpoint.smDown ? this.showDialog : undefined,
        openOnHover: !this.viewport.breakpoint.smDown,
        disabled: this.disabled,
        fullWidth: true,
        offsetOverflow: true,
        closeOnClick: true,
        closeOnContentClick: true,
        closeOnEsc: true,
        maxHeight: 300,
        minWidth: 200,
        overflowY: 'auto',
        zIndex: 900,
        offsetDistance: 8,
        placement: popperPlacements['bottom-end']
      },
      ref: `menu-button-${this._uid}`,
      key: `menu-button-${this._uid}`
    },
    [
      renderButton.call(this, h, this.viewport.breakpoint.mdUp),
      renderList.call(this, h, this.customMenu),
      renderFooter.call(this, h)
    ]
  )
}
function renderTextGenerator(h) {
  if (this.messagePayloadGenerator) {
    return h(
      messagePayloadGeneratorButton,
      {
        props: {
          sendingType: this.sendingType
        },
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}
function renderUTMButton(h) {
  if (this.utm) {
    return h(utmGenerator)
  }
}
function renderActions(h) {
  return h(
    'div',
    {
      class: 'fc grid-gap--8'
    },
    [
      renderButton.call(this, h, this.viewport.breakpoint.smDown),
      renderTagMenu.call(this, h),
      renderTextGenerator.call(this, h),
      renderUTMButton.call(this, h)
    ]
  )
}

function renderComponent(h) {
  return h(
    this.component,
    {
      props: {
        id: `input-${this._uid}`,
        value: this.proxy,
        type: this.type,
        label: this.label,
        hint: this.hint,
        mode: this.mode,
        rounded: this.rounded,
        dense: this.dense,
        disabled: this.disabled,
        readonly: this.readonly,
        required: this.required,
        maxlength: this.maxLength,
        error: this.error,
        details: !!this.hint,
        rows: 6,
        grow: true
      },
      ref: `input-${this._uid}`,
      key: `input-${this._uid}`,
      on: {
        input: event => {
          this.proxy = event
        },
        focus: () => {
          this.setFocus()
        },
        mouseup: () => {
          this.getTagsFromText()
        },
        keyup: () => {
          this.getTagsFromText()
        },
        keydown: event => {
          if (this.section) {
            if (event.keyCode === 40) {
              event.stopPropagation()
              event.preventDefault()
              this.setOption(this.currentOption + 1)
            } else if (event.keyCode === 38) {
              event.stopPropagation()
              event.preventDefault()
              this.setOption(this.currentOption - 1)
            } else if (event.keyCode === 13) {
              event.stopPropagation()
              event.preventDefault()
              this.insertText(this.computedMenu[this.currentOption].value)
            }
          }
        }
      }
    }
  )
}

function renderStatistic(h) {
  if (this.stats) {
    if (Array.isArray(this.$stats) && this.$stats.length) {
      return h(
        'div',
        {
          class: 'flex grid-gap--8 pt-2'
        },
        this.$stats.map(label => {
          return h(
            tag,
            {
              props: {
                label,
                size: sizes.small,
                color: colors.grey
              }
            }
          )
        })
      )
    }
  }
}
function renderPopUpMenu(h) {
  if (this.readonly) {
    return
  }

  if (!this.disabled && !this.readonly && this.viewport.breakpoint.mdUp) {
    return h(
      components.menu,
      {
        attrs: { id: `menu-${this._uid}` },
        props: {
          value: this.showMenu,
          closeOnClick: true,
          closeOnContentClick: true,
          maxHeight: 300,
          minWidth: 200,
          zIndex: 900,
          activator: this.catchInput,
          offsetDistance: this.catchInput ? -this.catchInput.offsetHeight + this.computedPosition.top : 0,
          offsetSkidding: this.catchInput ? this.computedPosition.left : 0,
          overflowY: 'auto',
          attach: this.$refs[`holder-${this._uid}`]
        },
        ref: `menu-${this._uid}`,
        key: `menu-${this._uid}`
      },
      [ renderList.call(this, h, this.computedMenu) ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--0',
      style: { position: 'relative' },
      attrs: { id: `holder-${this._uid}` },
      ref: `holder-${this._uid}`,
      key: `holder-${this._uid}`
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: { gridTemplateColumns: '1fr auto' }
        },
        [
          renderComponent.call(this, h),
          renderActions.call(this, h)
        ]
      ),
      renderStatistic.call(this, h),
      renderPopUpMenu.call(this, h)
    ]
  )
}
