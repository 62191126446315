import { buttonModes, colors, components, inputModes, sizes } from '@/utils'

import button from '@/components/button'
import buttonCopy from '@/components/button/copy'
import { name, sources, utmMedium, utmParams } from '@/components/services/sendingsCreate/utm/utils'
import tag from '@/components/tag'

function renderURL(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { gridTemplateColumns: this.viewport.breakpoint.mdUp ? '130px 1fr' : '1fr' }
    },
    [
      h(
        components.select,
        {
          props: {
            value: this.protocol,
            label: this.getTranslate(`${name}.labels.protocol`),
            items: [ 'https://', 'http://' ],
            mode: inputModes['line-label'],
            dense: true,
            rounded: true,
            required: true,
            details: false
          },
          on: {
            input: event => {
              this.protocol = event
            }
          }
        }
      ),
      h(
        components['text-field'],
        {
          props: {
            value: this.url,
            label: this.getTranslate(`${name}.labels.url`),
            mode: inputModes['line-label'],
            error: this.url && !this.urlIsValid ? 'false' : undefined,
            dense: true,
            rounded: true,
            details: false,
            clearable: true,
            required: true
          },
          on: {
            input: event => {
              this.url = event
            }
          }
        }
      )
    ]
  )
}

function renderSource(h, source) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate(`${name}.labels.${source}`),
        size: sizes.small,
        mode: this.source === source ? buttonModes.filled : buttonModes.flat,
        color: this.source === source ? colors.primary : undefined
      },
      on: {
        click: () => {
          this.source = source
        }
      }
    }
  )
}
function renderSources(h) {
  return h(
    'div',
    {
      class: 'faic fw grid-gap--8'
    },
    [ sources.map(source => renderSource.call(this, h, source)) ]
  )
}

function renderParam(h, param) {
  const component = param === utmParams.utm_medium ? components.select : components['text-field']
  const required = [ utmParams.utm_source, utmParams.utm_medium, utmParams.utm_campaign ].includes(param)

  return h(
    component,
    {
      props: {
        value: this.params[param],
        label: this.getTranslate(`${name}.labels.${param}`, { param }),
        hint: this.getTranslate(`${name}.hints.${this.source}.${param}`),
        items: Object.values(utmMedium),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        combobox: true,
        clearable: component === components['text-field'],
        required,
        mask: /^[^а-яё]+$/iu
      },
      key: param,
      on: {
        input: event => {
          this.params[param] = event
        }
      }
    }
  )
}
function renderReferenceDialog(h, param) {
  return h(
    components.dialog,
    {
      props: {
        value: this.showReference[param],
        title: this.getTranslate(`${name}.titles.${param}`, { param }),
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 600 : undefined
      },
      on: {
        input: event => {
          this.showReference[param] = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'px-3',
          domProps: {
            innerHTML: this.getTranslate(`${name}.references.${param}`)
          }
        }
      ),
      h(
        'div',
        {
          class: 'pa-2 faic fjcfe grid-gap--8',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.showReference[param] = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}
function renderReferenceButton(h, param) {
  return h(
    button,
    {
      props: {
        iconSVG: 'question-mark',
        mode: buttonModes.flat,
        color: colors.secondary,
        tooltip: this.getTranslate(`${name}.tooltips.reference`, { param })
      },
      on: {
        click: () => {
          this.showReference[param] = true
        }
      },
      scopedSlots: {
        dialog: () => {
          return renderReferenceDialog.call(this, h, param)
        }
      }
    }
  )
}
function renderParams(h) {
  const params = {
    required: [ utmParams.utm_source, utmParams.utm_medium, utmParams.utm_campaign ],
    optional: [ utmParams.utm_content, utmParams.utm_term ]
  }

  return h(
    'div',
    {
      class: 'grid',
      style: { gridTemplateColumns: this.viewport.breakpoint.mdUp ? 'repeat(2, 1fr)' : '1fr' }
    },
    [
      Object.keys(params).map(key => {
        return h(
          components.card,
          {
            class: 'grid grid-gap--12 pt-3 pb-2 px-2',
            props: {
              title: this.getTranslate(`${name}.titles.params.${key}`),
              dashed: key === 'optional',
              rounded: true,
              outline: true
            }
          },
          [
            Object.keys(params[key]).map(param => {
              return h(
                'div',
                {
                  class: 'grid grid-gap--8',
                  style: { gridTemplateColumns: '1fr auto' }
                },
                [
                  renderParam.call(this, h, params[key][param]),
                  renderReferenceButton.call(this, h, params[key][param])
                ]
              )
            })
          ]

        )
      })
    ]
  )
}

function renderLinks(h) {
  return [ 'full', 'short' ].map(type => {
    return h(
      'div',
      {
        class: 'faife grid-gap--8'
      },
      [
        h(
          'div',
          {
            class: `${name}-link`
          },
          [
            h(
              tag,
              {
                props: {
                  label: this.getTranslate(`${name}.labels.links.${type}`),
                  size: sizes.tiny,
                  mode: buttonModes.flat,
                  color: colors.grey
                }
              }
            ),

            h(
              'pre',
              {
                class: `${name}-link__url`
              },
              this[`${type}Link`]
            )
          ]
        ),

        h(
          buttonCopy,
          {
            props: {
              value: this[`${type}Link`],
              disabled: type === 'full' ? !this.fullLink : this.loading || !this.shortLink,
              loading: type === 'full' ? false : this.loading,
              tooltip: this.getTranslate('misc.buttons.copy'),
              snackbar: {
                text: this.getTranslate('misc.copied'),
                type: colors.success
              }
            }
          }
        )
      ]
    )
  })
}

function renderDialogBody(h) {
  return h(
    'div',
    {
      class: 'grid pa-2'
    },
    [
      renderURL.call(this, h),
      renderSources.call(this, h),
      renderParams.call(this, h),
      renderLinks.call(this, h)
    ]
  )
}
function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'pa-2 faic fjcfe grid-gap--8',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.reset'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.setDefaultsBySource()
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.showDialog = false
            }
          }
        }
      )
    ]
  )
}
function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        value: this.showDialog,
        title: this.getTranslate(`${name}.title`),
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 900 : undefined
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      renderDialogBody.call(this, h),
      renderDialogFooter.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    button,
    {
      props: {
        iconSVG: 'utm',
        mode: buttonModes.flat,
        color: colors.utm,
        tooltip: this.getTranslate(`${name}.tooltips.generate`)
      },
      on: {
        click: () => {
          this.showDialog = !this.showDialog
        }
      },
      scopedSlots: {
        dialog: () => {
          return renderDialog.call(this, h)
        }
      }
    }
  )
}
