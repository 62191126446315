import { isEqual } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'GeneratorText',

  mixins: [ proxy({ type: 'array' }) ],

  methods: {
    _inputFilter(data) {
      return data.map(item => ({ text: item }))
    },

    _outputFilter(data) {
      return data.map(item => item.text).filter(item => item !== undefined)
    },

    transmitValue() {
      const proxy = this._outputFilter(this.proxy)
      if (!isEqual(proxy, this.value)) {
        this.$emit('input', proxy)
      }
    }
  },

  render
}
