import { EVENTSHISTORY_ASSOCIATED_SERVICES } from '@sigma-legacy-libs/essentials/lib/constants'
import proxy from '@sigma-legacy-libs/g-proxy'

import { services } from '@/utils'

import render from './render'

export default {
  name: 'Filters',

  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    selectItems() {
      const result = []
      for (const association of EVENTSHISTORY_ASSOCIATED_SERVICES) {
        switch (association) {
          case EVENTSHISTORY_ASSOCIATED_SERVICES.billingsUsers: {
            result.push({
              service: services.billings,
              value: association
            })
            break
          }
          case EVENTSHISTORY_ASSOCIATED_SERVICES.contactListsContacts: {
            result.push({
              service: services.contacts,
              value: association
            })
            break
          }
          default: {
            result.push({
              service: association,
              value: association
            })
          }
        }
      }

      return result.map(({ service, value }) => {
        return {
          title: this.getTranslate(`${services.eventsHistory}.services.${service}`),
          value
        }
      })
    }
  },

  render
}
