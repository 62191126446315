export default {
  title: 'Support',

  labels: {
    vk: 'VKontakte',
    t: 'Telegram',
    rbc: 'RBC',
    vc: 'VC.ru',
    linkedin: 'LinkedIn',
    x: 'X.com'
  }
}
