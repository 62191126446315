import Vue from 'vue'

import { cloneDeep, merge, pick } from 'lodash'

import { colors, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import { commonProperties } from '@/components/services/inactiveProviders/utils'

import render from './render'

export default {
  name: 'IsActiveButton',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      loading: false,
      isActive: this.value.isActive
    }
  },

  methods: {
    async update() {
      try {
        this.loading = true
        const params = merge(pick(cloneDeep(this.value), commonProperties), { isActive: !this.isActive })
        const { data } = await Vue.$GRequest.update(services.providers, this.value.id, params)
        if (data) {
          this.isActive = data.isActive
          this.addSnackbar({
            text: this.getTranslate(`${services.providers}.snackbars.updated`),
            type: colors.info
          })
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
