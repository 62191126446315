import Vue from 'vue'

import proxy from '@sigma-legacy-libs/g-proxy'

import { generateServices, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import { otpSettingsChannelTypes } from '@/components/services/otp-settings/utils'

import render from './render'

const serviceNameViaPoint = services['otp-settings/channels'].split('/').join('.')

export default {
  name: 'SecondCol',

  mixins: [
    proxy({ type: 'object' }),

    generateServices({
      name: services['otp-settings/templates'],

      backendGeneration: 'nest',

      get: false,
      create: false,
      update: false,
      remove: false
    })
  ],

  data() {
    return {
      loading: false
    }
  },

  computed: {
    filteredTemplates() {
      return this.proxy.otpChannelTemplateSettings.filter(({ otpTemplateSettingsId, priority }) => {
        if (otpTemplateSettingsId && typeof priority === 'number') {
          return {
            otpTemplateSettingsId,
            priority
          }
        }
      })
    },

    excludeTypes() {
      return [
        otpSettingsChannelTypes.mobileid_mts_push,
        otpSettingsChannelTypes.mobileid_mts_push_sms
      ]
    },

    $disabled() {
      return this.filteredTemplates.length === 0
    }
  },

  mounted() {
    if (!this.excludeTypes.includes(this.proxy.channelType)) {
      this.rest[services['otp-settings/templates']].find()
    }
  },

  methods: {
    async attachTemplates() {
      try {
        this.loading = true
        const { data } = await Vue.$GRequest.update(
          `/n/${services['otp-settings/channels']}`,
          `${this.$route.params.id}/template/bulk`,
          { otpChannelTemplateSettings: this.filteredTemplates }
        )
        if (data) {
          this.addSnackbar({
            text: this.getTranslate(`${serviceNameViaPoint}.snackbars.templates.attached`),
            type: 'success'
          })
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
