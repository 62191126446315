import { escape } from 'lodash'

import { buttonModes, colors, components, displays, icons, sizes } from '@/utils'

import button from '@/components/button'

const defaultButtonProps = {
  mode: buttonModes.flat,
  color: colors.white,
  size: sizes.small
}

function renderText(h, { text }) {
  if (text) {
    return h(
      'div',
      {
        class: `${components.snackbar}__text`,
        domProps: { innerHTML: escape(text) }
      }
    )
  }
}

function renderButton(h, item) {
  if (item.button && item.button.on) {
    return h(
      button,
      {
        class: item.button.class,
        props: Object.assign({}, defaultButtonProps, item.button.props || {}),
        on: {
          click: item.button.on.click
        }
      }
    )
  }
}

function renderCancelable(h, { cancelable, remove }) {
  if (cancelable) {
    return h(
      button,
      {
        props: {
          ...defaultButtonProps,
          icon: icons.clear
        },
        on: {
          click: () => {
            remove()
          }
        }
      }
    )
  }
}

function renderItem(h, item) {
  return h(
    'div',
    {
      class: `${components.snackbar}__item ${components.snackbar}__item--${item.type}`,
      key: item.id
    },
    [
      renderText.call(this, h, item),
      renderButton.call(this, h, item),
      renderCancelable.call(this, h, item)
    ]
  )
}
function renderItems(h) {
  return this.items.map(item => {
    return renderItem.call(this, h, item)
  })
}

export default function(h) {
  return h(
    'transition-group',
    {
      class: this.$_class,
      props: {
        tag: 'div',
        name: `${components.snackbar}-fade${this.display === displays.mobile ? '--mobile' : ''}`,
        css: true,
        appear: true
      }
    },
    [ renderItems.call(this, h) ]
  )
}
