export default {
  title: 'Permisos',

  subtitles: {
    presets: 'Preajustes'
  },

  dialogs: {
    titles: {
      info: 'Información sobre niveles de permisos'
    }
  },

  labels: {
    create: 'Crear',
    check: 'Verificar número',
    finalize: 'Finalizar',
    find: 'Encontrar',
    generate: 'Generar',
    set: 'Establecer',
    get: 'Obtener',
    use: 'Usar',
    getSendingsForTask: 'Obtener envíos para la tarea',
    remove: 'Eliminar',
    update: 'Actualizar',
    modify: 'Modificar',
    seek: 'Buscar',
    download: 'Descargar',
    upload: 'Subir',
    setRead: 'Marcar como leído',
    setPermissions: 'Establecer permisos',
    setRoutingGroups: 'Establecer grupos de enrutamiento',
    setMessageRuleGroups: 'Establecer grupos de reglas de mensajes',
    setSettings: 'Establecer configuraciones',
    setSendernames: 'Establecer nombres de remitentes',
    setPatterns: 'Establecer patrones',
    setTariffs: 'Establecer tarifas',
    showSecrets: 'Mostrar permisos secretos',
    trigger: 'Activar',
    health: 'Rendimiento',
    loginAs: 'Iniciar sesión como',
    request: 'Solicitar',
    controlDocuments: 'Control de documentos',
    edit: 'Editar',
    viewChangelogs: 'Ver lista de cambios',
    contracts: 'Contratos',
    companies: 'Empresas',
    documentTemplates: 'Plantillas de documentos',
    refund: 'Reembolso de pago',
    presetUsersBalance: 'Posibilidad de descargar la lista de usuarios',
    matchKeywords: 'Verificar palabras clave',
    setOtpChannels: 'Establecer canales OTP'
  },

  hints: {
    false: 'Prohíbe cualquier acción que requiera el permiso correspondiente.',
    me: 'Permite acciones con objetos que el usuario creó por sí mismo, o que su revendedor o administrador creó para él.',
    manager: 'Permite acciones con objetos que pertenecen al propio usuario o a los usuarios vinculados al gerente.',
    reseller: 'Permite acciones con objetos que pertenecen al propio usuario o a los usuarios subordinados del revendedor.',
    true: 'Permite acciones con cualquier objeto en el sistema.',
    warning: 'Cambiar permisos no requiere actualizar el usuario.'
  },

  tooltips: {
    info: 'Información sobre niveles de permisos'
  },

  buttons: {
    false: 'Falso',
    me: 'Usuario',
    reseller: 'Revendedor'
  },

  services: {
    apisender: 'API sender',
    billings: 'Cuentas de facturación',
    changelogs: 'Lista de cambios',
    companies: 'Empresas',
    components: 'Componentes',
    contacts: 'Contactos',
    contracts: 'Contratos',
    credentials: 'Credenciales',
    dadata: 'Dadata',
    dictionaries: 'Diccionarios',
    documentTemplates: 'Plantillas de documentos',
    eventsHistory: 'Historial de eventos',
    hlr: 'HLR',
    invoices: 'Facturas',
    keywords: 'Palabras clave',
    messageProcessors: 'Procesadores de mensajes',
    messageRuleGroups: 'Grupos de reglas de mensajes',
    messageRules: 'Reglas de mensajes',
    notifications: 'Notificaciones',
    operatorGroups: 'Grupos de operadores',
    patterns: 'Plantillas para moderación',
    payment: 'Pagos',
    paymentLogs: 'Registro de pagos',
    payments: 'Pagos',
    platform: 'Plataforma',
    providerGroups: 'Grupos de proveedores',
    providers: 'Proveedores',
    recurrentPayments: 'Pagos recurrentes',
    reports: 'Informes',
    requisites: 'Requisitos',
    routingGroups: 'Grupos de enrutamiento',
    routingRules: 'Reglas de enrutamiento',
    secret: 'Secretos',
    sendernames: 'Nombres de remitentes',
    sendings: 'Envíos',
    sendingsIncoming: 'Mensajes entrantes',
    sendingsModeration: 'Moderación de mensajes',
    servers: 'Servidores',
    settings: 'Configuraciones del sitio',
    settingsSite: 'Configuraciones del sitio',
    settingsUser: 'Configuraciones de usuarios',
    storage: 'Almacenamiento',
    tariffs: 'Tarifas',
    tasks: 'Tareas',
    telemetry: 'Telemetría',
    templates: 'Plantillas de mensajes',
    tokens: 'Tokens',
    userComments: 'Comentarios de usuarios',
    users: 'Usuarios',
    yclients: 'YClients',
    'otp-handler': 'Manejador OTP',

    'otp-settings': {
      widgets: 'Widgets OTP',
      channels: 'Canales OTP',
      templates: 'Plantillas OTP'
    }
  },

  snackbars: {
    updated: 'Permiso actualizado con éxito'
  },

  tooltips: {
    presets: 'Al hacer clic en este botón, todos los permisos del usuario serán reemplazados por el preajuste seleccionado, si existe tal preajuste para el permiso específico. Los cambios son irreversibles y se aplicarán de inmediato.'
  }
}
