import Vue from 'vue'

import { mergeWith, toPlainObject } from 'lodash'

import { EMPTY_USERS, SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'
import { CSendingTypesGenerallyAvailable } from '@sigmasms/commons'

import { isArrayNotEmpty } from '@/utils'

export default {
  namespaced: true,

  state: {
    account: EMPTY_USERS,
    sendingTypes: SENDING_TYPES.reduce((result, type) => {
      if (CSendingTypesGenerallyAvailable.includes(type.value)) {
        result.push(type)
      }

      return result
    }, []),
    isPlatinum: false
  },

  mutations: {
    fillAccount(state, account) {
      account = toPlainObject(account)
      state.account = mergeWith({}, EMPTY_USERS, account, (objValue, srcValue) => {
        return Array.isArray(srcValue) ? srcValue : undefined
      })
    },

    setSendingTypes(state, types) {
      state.sendingTypes = types
    },
    setPlatinumStatus(state, status) {
      state.isPlatinum = status
    }
  },

  actions: {
    async getAccount({ commit, dispatch }, userId) {
      const { data } = await Vue.$GRequest.get('users', userId, { query: { $scope: [ 'full' ] } })
      if (data) {
        const types = []
        for (const type of CSendingTypesGenerallyAvailable) {
          if (
            isArrayNotEmpty(data.Tariffs) &&
            data.Tariffs.find(tariff => tariff.source === `sendings.${type}`) &&
            isArrayNotEmpty(data.RoutingGroups) &&
            data.RoutingGroups.find(routingGroup => routingGroup.type === type)
          ) {
            types.push(type)
          }
        }

        commit('setSendingTypes', types)

        const { permissions } = await dispatch('settings/getGlobalSettings', null, { root: true })
        data.permissions = permissions

        commit('fillAccount', data)
      }
    },

    setAccount({ commit }, account) {
      commit('fillAccount', account)
    },
    setPlatinumStatus({ commit }, status) {
      commit('setPlatinumStatus', status)
    }
  },

  getters: {
    account: state => state.account,
    sendingTypes: state => state.sendingTypes,
    isPlatinum: state => state.isPlatinum
  }
}
