import { cloneDeep } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import { leadZero, projectName, timeToArrayOfNumbers } from '@/utils'

import render from './render'

const mergeArrayTime = time => [ time.hours, time.minutes, time.seconds ].join(':')

export default {
  name: 'Time',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    isSingleDate: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      time: {
        gt: {
          hours: 0,
          minutes: 0,
          seconds: 0
        },
        lt: {
          hours: 23,
          minutes: 59,
          seconds: 59
        }
      }
    }
  },

  computed: {
    items() {
      return {
        gt: {
          hours: Array.from({ length: 24 }, (key, index) => {
            return {
              title: leadZero(index),
              value: index
            }
          }),
          minutes: Array.from({ length: 60 }, (key, index) => {
            return {
              title: leadZero(index),
              value: index
            }
          }),
          seconds: Array.from({ length: 60 }, (key, index) => {
            return {
              title: leadZero(index),
              value: index
            }
          })
        },
        lt: {
          hours: Array.from({ length: 24 }, (key, index) => {
            return {
              title: leadZero(index),
              value: index,
              disabled: this.isSingleDate && index < this.time.gt.hours
            }
          }),
          minutes: Array.from({ length: 60 }, (key, index) => {
            return {
              title: leadZero(index),
              value: index,
              disabled: this.isSingleDate && this.time.gt.hours === this.time.lt.hours && index < this.time.gt.minutes
            }
          }),
          seconds: Array.from({ length: 60 }, (key, index) => {
            return {
              title: leadZero(index),
              value: index,
              disabled: this.isSingleDate && this.time.gt.hours === this.time.lt.hours && this.time.gt.minutes === this.time.lt.minutes && index < this.time.gt.seconds
            }
          })
        }
      }
    },

    prefixKey() {
      return `${projectName}:date-time-filter:time`
    }
  },

  watch: {
    time: {
      handler() {
        this.proxy.time.$gt = mergeArrayTime(this.time.gt)
        this.proxy.time.$lt = mergeArrayTime(this.time.lt)
      },
      deep: true
    },

    'proxy.time': {
      handler() {
        this.setRenderTime()
      },
      deep: true
    }
  },

  mounted() {
    this.setRenderTime()
  },

  methods: {
    setRenderTime() {
      const { $gt, $lt } = cloneDeep(this.proxy.time)
      if ($gt) {
        const [ hours, minutes, seconds ] = timeToArrayOfNumbers($gt)
        this.time.gt = {
          hours,
          minutes,
          seconds
        }
      }
      if ($lt) {
        const [ hours, minutes, seconds ] = timeToArrayOfNumbers($lt)
        this.time.lt = {
          hours,
          minutes,
          seconds
        }
      }
    },

    getMinMaxTime(key, type) {
      let min = 0
      if (this.isSingleDate && key === 'lt') {
        switch (type) {
          case 'hours': {
            min = this.time.gt.hours

            break
          }
          case 'minutes': {
            if (this.time.gt.hours === this.time.lt.hours) {
              min = this.time.gt.minutes
            }

            break
          }
          case 'seconds': {
            if (this.time.gt.hours === this.time.lt.hours && this.time.gt.minutes === this.time.lt.minutes) {
              min = this.time.gt.seconds
            }

            break
          }
        }
      }

      return {
        min,
        max: type === 'hours' ? 23 : 59
      }
    }
  },

  render
}
