export default function(h) {
  return h(
    this.$component,
    {
      props: this.$props,
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
