import { buttonModes, colors, components, icons } from '@/utils'

import button from '@/components/button'
import draggable from '@/components/misc/draggable'

const className = 'row-generator'

function renderDragHandle(h) {
  if (this.sortable) {
    return h(
      components.icon,
      {
        class: `${className}__drag_handle drag_handle`,
        props: {
          value: icons.drag_handle,
          color: colors.grey,
          size: 24
        }
      }
    )
  }
}

function renderRemoveButton(h, index) {
  return h(
    button,
    {
      props: {
        icon: icons.remove,
        color: colors.error,
        mode: buttonModes.flat,
        disabled: this.disabled
      },
      on: {
        click: () => {
          this.removeField(index)
        }
      }
    }
  )
}

function renderRow(h, item, index) {
  return h(
    'div',
    {
      class: {
        [`${className}__content-item`]: true,
        [`${className}__content-item--sortable`]: this.sortable
      }
    },
    [
      renderDragHandle.call(this, h),

      this.$scopedSlots.row ? this.$scopedSlots.row({
        item,
        index
      }) : this.$slots.row,

      renderRemoveButton.call(this, h, index)
    ]
  )
}

function renderFields(h) {
  const callback = (item, index) => renderRow.call(this, h, item, index)
  if (this.sortable) {
    return h(
      draggable,
      {
        class: `${className}__content`,
        props: {
          value: this.proxy,
          disabled: this.disabled,
          handle: 'drag_handle'
        },
        on: {
          input: event => {
            this.proxy = event
          }
        },
        scopedSlots: { item: callback }
      }
    )
  } else {
    return h(
      'div',
      {
        class: `${className}__content`
      },
      [ this.proxy.map(callback) ]
    )
  }
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: `${className}__footer`
    },
    [
      h(
        button,
        {
          props: {
            icon: icons.add,
            color: colors.primary,
            mode: buttonModes.flat,
            disabled: this.$addDisabled
          },
          on: {
            click: () => {
              this.addField()
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${className}`
    },
    [
      renderFields.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
