export default {
  title: 'Empresas',

  titles: {
    create: 'Crear empresa',
    vat: 'IVA'
  },

  labels: {
    title: 'Título',
    vat: 'Procedimiento de cálculo',
    owner: 'Propietario'
  },

  vat: {
    true: 'Con IVA',
    false: 'Sin IVA'
  },

  hints: {
    notFound: 'Empresas no encontradas'
  },

  snackbars: {
    created: 'Empresa creada con éxito.',
    updated: 'Empresa actualizada con éxito.',
    removed: 'Empresa eliminada con éxito.'
  }
}
