import Vue from 'vue'

import { isEmpty } from 'lodash'

import { Macro } from '@sigma-legacy-libs/cache'

import { globalErrorHandler, globalErrorProcessor, isValidPhone, services } from '@/utils'

import render from './render'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

export default {
  name: services.hlr,

  data() {
    return {
      value: undefined,
      result: undefined,

      loading: false
    }
  },

  computed: {
    validation() {
      return isValidPhone(this.value)
    }
  },

  methods: {
    cachedRequest: Cache.wrapWithCache(async (key, value) => {
      return await Vue.$GRequest.get(services.hlr, value)
    }),

    async get() {
      if (this.validation) {
        try {
          this.loading = true
          this.result = undefined
          const { data } = await this.cachedRequest(`${services.hlr}:${this.value}`, this.value)
          if (!isEmpty(data)) {
            this.result = data
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
        }
      }
    },

    cleanData() {
      this.value = undefined
      this.result = undefined
    }
  },

  render
}
