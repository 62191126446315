import { buttonModes, colors, components, icons, permissionPresets, services, states } from '@/utils'

import button from '@/components/button'
import buttonRefresh from '@/components/button/refresh'
import info from '@/components/info'
import pagination from '@/components/pagination'
import preloader from '@/components/preloader'
import paymentsList from '@/components/services/payments/list'
import recurrentPaymentsFilter from '@/components/services/recurrentPayments/filter'
import recurrentPaymentsForm from '@/components/services/recurrentPayments/form'
import recurrentPaymentsItem from '@/components/services/recurrentPayments/item'

function renderCreateDialogBody(h, options) {
  if (this.paymentsMethods.length) {
    return h(
      recurrentPaymentsForm,
      {
        props: {
          value: this.restData[services.recurrentPayments].create.data,
          errors: this.restData[services.recurrentPayments].create.errors,
          isNew: true,
          OwnerId: options.OwnerId
        },
        on: {
          input: event => {
            this.restData[services.recurrentPayments].create.data = event
          },
          submit: () => {
            this.createRecurrentPayment()
          },
          validation: event => {
            this.restData[services.recurrentPayments].create.isValid = event
          }
        }
      }
    )
  }

  return h(
    'div',
    {
      class: 'px-2'
    },
    [
      h(
        info,
        {
          props: {
            value: this.getTranslate(`${services.payments}.infos.method`)
          }
        }
      )
    ]
  )
}
function renderCreateDialogFooter(h) {
  if (this.paymentsMethods.length) {
    const loading = this.restData[services.recurrentPayments].create.state === states.loading

    return h(
      'div',
      {
        class: 'fjcfe grid-gap--8 pa-2',
        slot: 'footer'
      },
      [
        h(
          button,
          {
            props: {
              label: this.getTranslate('misc.buttons.cancel'),
              mode: buttonModes.flat
            },
            on: {
              click: () => {
                this.showCreateDialog = false
              }
            }
          }
        ),
        h(
          button,
          {
            props: {
              label: this.getTranslate('misc.buttons.create'),
              color: colors.primary,
              loading,
              disabled: loading || !this.restData[services.recurrentPayments].create.isValid
            },
            on: {
              click: this.createRecurrentPayment
            }
          }
        )
      ]
    )
  }

  return h(
    'div',
    {
      class: 'faic fjcfe pa-2'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.showCreateDialog = false
            }
          }
        }
      )
    ]
  )
}
function renderCreateDialog(h, options) {
  if (this.checkPermissions(`advanced.${services.recurrentPayments}.create`, permissionPresets.meUp)) {
    return h(
      components.dialog,
      {
        props: {
          title: this.getTranslate(`${services.recurrentPayments}.titles.create`),
          value: this.showCreateDialog,
          rounded: this.viewport.breakpoint.mdUp,
          maxWidth: this.viewport.breakpoint.mdUp ? 450 : undefined
        },
        on: {
          input: event => {
            this.showCreateDialog = event
            this.setShowCreateRecurrentPaymentDialog(event)
          }
        }
      },
      [
        renderCreateDialogBody.call(this, h, options),
        renderCreateDialogFooter.call(this, h)
      ]
    )
  }
}
function renderCreateButton(h, options) {
  if (!options.isPage) {
    if (this.checkPermissions(`advanced.${services.recurrentPayments}.create`, permissionPresets.meUp)) {
      return h(
        'div',
        {
          class: { fjcfe: !options.isPage && !this.viewport.breakpoint.xs }
        },
        [
          h(
            button,
            {
              props: {
                icon: icons.add,
                mode: buttonModes.flat,
                color: colors.secondary
              },
              on: {
                click: () => {
                  this.showCreateDialog = true
                }
              },
              scopedSlots: { dialog: () => renderCreateDialog.call(this, h, options) }
            }
          )
        ]
      )
    }
  }
}

function renderRefreshButton(h) {
  return h(
    buttonRefresh,
    {
      props: {
        state: this.restData[services.recurrentPayments].find.state,
        shortClickMethod: () => this.rest[services.recurrentPayments].find(),
        longClickMethod: () => this.rest[services.recurrentPayments].find({}, { noCache: true })
      }
    }
  )
}
function renderPagination(h) {
  return h(
    'div',
    {
      class: 'faic fjcfe grid-gap--8'
    },
    [
      h(
        pagination,
        {
          props: {
            value: this.restData[services.recurrentPayments].find.pagination,
            service: services.recurrentPayments,
            selectRows: !this.viewport.breakpoint.xs,
            chunk: this.viewport.breakpoint.xs ? 1 : 3
          },
          on: {
            input: event => {
              this.restData[services.recurrentPayments].find.pagination = event
            }
          }
        }
      ),

      renderRefreshButton.call(this, h)
    ]
  )
}
function renderFilter(h, options) {
  return h(
    recurrentPaymentsFilter,
    {
      props: {
        value: this.restData[services.recurrentPayments].find.filter,
        isPage: !!options.isPage,
        OwnerId: options.OwnerId
      },
      on: {
        input: event => {
          this.restData[services.recurrentPayments].find.filter = event
        }
      },
      scopedSlots: {
        buttons: () => {
          return [ renderCreateButton.call(this, h, options) ]
        }
      }
    }
  )
}
function renderHeader(h, options) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      renderFilter.call(this, h, options),
      renderPagination.call(this, h, true)
    ]
  )
}

function renderDivider(h) {
  if (this.restData[services.recurrentPayments].find.pagination.total > 0) {
    return h(components.divider)
  }
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'preloader__holder preloader__holder--absolute pa-5'
    },
    [ h(preloader) ]
  )
}
function renderAbsolutePreloader(h) {
  if (this.restData[services.recurrentPayments].find.state === states.loading) {
    return h(
      'div',
      {
        class: 'preloader__holder preloader__holder--absolute'
      },
      [ h(preloader) ]
    )
  }
}
function renderItem(h, options, item) {
  return h(
    recurrentPaymentsItem,
    {
      props: {
        value: item,
        isPage: !!options.isPage
      },
      key: item.id,
      on: {
        payments: id => {
          this.RecurrentPaymentId = id
          this.showPaymentsDialog = true
        },
        unlockRecurrentPayment: id => {
          this.RecurrentPaymentId = id
          this.showUnlockConfirmDialog = true
        }
      }
    }
  )
}
function renderItems(h, options) {
  return h(
    'div',
    {
      style: { position: 'relative' }
    },
    [
      this.restData[services.recurrentPayments].find.data.map(item => {
        return renderItem.call(this, h, options, item)
      }),

      renderAbsolutePreloader.call(this, h)
    ]
  )
}
function renderBody(h, options) {
  if (this.restData[services.recurrentPayments].find.pagination.total > 0) {
    return renderItems.call(this, h, options)
  } else {
    switch (this.restData[services.recurrentPayments].find.state) {
      case states.loading: return renderPreloader.call(this, h)
      case states.ready:
      default: return h(components.empty)
    }
  }
}

function renderPaymentsDialog(h) {
  if (this.checkPermissions(`advanced.${services.payments}.get`, permissionPresets.meUp)) {
    return h(
      components.dialog,
      {
        props: {
          title: this.getTranslate(`${services.recurrentPayments}.titles.payments`),
          value: this.showPaymentsDialog,
          rounded: this.viewport.breakpoint.mdUp,
          maxWidth: this.viewport.breakpoint.mdUp ? 800 : undefined
        },
        on: {
          input: event => {
            this.showPaymentsDialog = event
          }
        }
      },
      [
        h(paymentsList({ RecurrentPaymentId: this.RecurrentPaymentId })),

        h(
          'div',
          {
            class: 'fjcfe grid-gap--8 pa-2',
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.close'),
                  mode: buttonModes.flat
                },
                on: {
                  click: () => {
                    this.showPaymentsDialog = false
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}
function renderUnlockConfirmDialog(h) {
  if (this.checkPermissions(`advanced.${services.recurrentPayments}.remove`, permissionPresets.true)) {
    return h(
      components.dialog,
      {
        props: {
          title: this.getTranslate(`${services.recurrentPayments}.titles.unlock`),
          value: this.showUnlockConfirmDialog,
          rounded: this.viewport.breakpoint.mdUp,
          maxWidth: this.viewport.breakpoint.mdUp ? 450 : undefined
        },
        on: {
          input: event => {
            this.showUnlockConfirmDialog = event
          }
        }
      },
      [
        h('div', { class: 'pa-3' }, this.getTranslate(`${services.recurrentPayments}.contents.unlock`)),

        h(
          'div',
          {
            class: 'fjcfe grid-gap--8 pa-2',
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  mode: buttonModes.flat,
                  disabled: this.unlockLoading
                },
                on: {
                  click: () => {
                    this.showUnlockConfirmDialog = false
                  }
                }
              }
            ),
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.unlock'),
                  icon: icons.done,
                  color: colors.success,
                  mode: buttonModes.filled,
                  loading: this.unlockLoading,
                  disabled: this.unlockLoading
                },
                on: {
                  click: () => {
                    this.unlockRecurrentPayment()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

export default function(h, options) {
  if (this.checkPermissions(`advanced.${services.recurrentPayments}.get`, permissionPresets.meUp)) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        renderHeader.call(this, h, options),
        renderDivider.call(this, h),
        renderBody.call(this, h, options),
        renderPaymentsDialog.call(this, h),
        renderUnlockConfirmDialog.call(this, h)
      ]
    )
  }
}
