import { get } from 'lodash'

import { colors, icons, permissionPresets, services } from '@/utils'

import sticker from '@/components/misc/sticker'
import actions from '@/components/templates/service/item/actions'
import date from '@/components/templates/service/item/date'
import owner from '@/components/templates/service/item/owner'

function renderTitle(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value.title,
        label: this.viewport.size.width > 660 ? this.getTranslate(`${services.requisites}.labels.data.legal.name`) : this.value.data.legal.inn,
        icon: this.value.isActive ? icons.check_circle_outline : icons.error_outline,
        color: this.value.isActive ? colors.success : colors.error,
        iconTooltip: this.getTranslate(`${services.requisites}.tooltips.icons.${this.value.isActive ? 'isActive' : 'notActive'}`),
        copy: true,
        to: this.to
      }
    }
  )
}
function renderOPF(h) {
  if (this.$route.name === services.requisites) {
    if (this.viewport.size.width > 1170) {
      const value = get(this.value, 'data.legal.opf')

      return h(
        sticker,
        {
          props: {
            value,
            label: this.getTranslate(`${services.requisites}.labels.data.legal.opf`),
            icon: icons.document_scanner,
            color: colors.grey
          }
        }
      )
    }
  }
}
function renderInn(h) {
  if (this.viewport.size.width > 660) {
    const value = get(this.value, 'data.legal.inn')

    return h(
      sticker,
      {
        props: {
          value,
          label: this.getTranslate(`${services.requisites}.labels.data.legal.inn`),
          copy: true,
          icon: icons.numbers,
          color: colors.grey
        }
      }
    )
  }
}
function renderVAT(h) {
  if (this.viewport.size.width > 500) {
    const value = get(this.value, 'data.tax.vat')

    return h(
      sticker,
      {
        props: {
          value: this.getTranslate(`${services.requisites}.vat.${value}`),
          label: this.getTranslate(`${services.requisites}.labels.vat`)
        }
      }
    )
  }
}
function renderOwner(h) {
  if (this.$route.name === services.requisites) {
    if (this.viewport.size.width > 1340) {
      return h(
        owner,
        {
          props: {
            value: this.value
          }
        }
      )
    }
  }
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: {
        [`${services.requisites}-item__info`]: true,
        [`${services.requisites}-item__info--users`]: this.$route.name !== services.requisites,
        [`${services.requisites}-item__info--admin`]: this.$route.name === services.requisites && this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)
      }
    },
    [
      renderTitle.call(this, h),
      renderOPF.call(this, h),
      renderInn.call(this, h),
      renderVAT.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderAction(h) {
  if (this.$scopedSlots.actions) {
    return this.$scopedSlots.actions()
  }

  return h(
    actions,
    {
      props: {
        value: this.value,
        service: services.requisites
      }
    }
  )
}
function renderActions(h) {
  return h(
    'div',
    {
      class: `${services.requisites}-item__actions`
    },
    [ renderAction.call(this, h) ]
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        [`service-template-item ${services.requisites}-item`]: true,
        ['hover-parent hover-parent--opacity']: this.viewport.breakpoint.mdUp
      }
    },
    [
      renderInfo.call(this, h),
      renderActions.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
