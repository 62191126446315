export default {
  title: 'Recuperación de contraseña',

  labels: {
    login: 'Nombre de usuario o correo electrónico',
    password: 'Contraseña'
  },

  hints: {
    recover: '¿Olvidaste tu contraseña?',
    password: 'Ingrese una nueva contraseña y presione el botón de restablecer'
  },

  buttons: {
    reset: 'Restablecer'
  },

  contents: {
    validation: {
      request:
        'Se ha enviado un correo electrónico con más instrucciones para recuperar su contraseña a la dirección que proporcionó durante el registro.'
    }
  }
}
