import { services } from '@/utils'

import squircle from '@/components/icon/squircle'
import svgIcon from '@/components/icon/svg'
import sticker from '@/components/misc/sticker'
import actions from '@/components/templates/service/item/actions'
import date from '@/components/templates/service/item/date'

const serviceNameViaPoint = services['otp-settings/channels'].split('/').join('.')

function renderName(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        pathToTitle: 'name',
        label: this.getTranslate(`${serviceNameViaPoint}.labels.name`),
        service: serviceNameViaPoint
      }
    },
    [
      h(
        squircle,
        {
          props: {
            color: this.value.channelType,
            tooltip: this.getTranslate(`sendings.types.${this.value.channelType}`)
          },
          slot: 'icon'
        },
        [
          h(
            svgIcon,
            {
              props: {
                value: this.value.channelType,
                color: this.value.channelType,
                size: 18
              }
            }
          )
        ]
      )
    ]
  )
}
function renderLifetime(h) {
  if (this.viewport.size.width > 690) {
    return h(
      sticker,
      {
        props: {
          value: this.value.additionalSettings.lifetime,
          label: this.getTranslate(`${serviceNameViaPoint}.labels.additionalSettings.lifetime`)
        }
      }
    )
  }
}
function renderNumberOfTries(h) {
  if (this.viewport.size.width > 690) {
    return h(
      sticker,
      {
        props: {
          value: this.value.additionalSettings.numberOfTries,
          label: this.getTranslate(`${serviceNameViaPoint}.labels.additionalSettings.numberOfTries`)
        }
      }
    )
  }
}

function renderInfo(h) {
  return h(
    'div',
    {
      class: `${serviceNameViaPoint.replace('.', '-')}-panel__info`
    },
    [
      renderName.call(this, h),
      renderLifetime.call(this, h),
      renderNumberOfTries.call(this, h)
    ]
  )
}

function renderActions(h) {
  return h(
    actions,
    {
      props: {
        value: this.value,
        service: services['otp-settings/channels']
      }
    }
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-item hover-parent hover-parent--opacity': true,
        [`${serviceNameViaPoint.replace('.', '-')}-panel`]: true
      }
    },
    [
      renderInfo.call(this, h),
      renderActions.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
