export default {
  title: 'Configuración de OTP',

  widgets: {
    title: 'Widgets',

    titles: {
      create: 'Crear widget',
      settings: 'Configuraciones',
      channels: 'Canales'
    },

    labels: {
      name: 'Título',
      isActive: 'Activo',
      isBlocked: 'Bloqueado',
      captchaIsRequired: 'Captcha requerido',
      owner: 'Propietario',
      channels: 'Canales'
    },

    hints: {
      notFound: 'No se encontraron widgets',
      channels: 'Para vincular con éxito los canales al widget, seleccione los canales necesarios y presione el botón "Vincular".'
    },

    tooltips: {
      isActive: {
        true: 'El widget está activo',
        false: 'El widget no está activo'
      },

      isBlocked: {
        true: 'El widget está bloqueado por el administrador'
      }
    },

    infos: {
      channels: 'Para que el widget funcione correctamente, es necesario vincular los canales a él.<br>Para ello, seleccione los canales necesarios y haga clic en el botón "Guardar".',

      user: {
        channels: 'Para que el widget de usuario funcione correctamente, es necesario permitirle usar canales específicos.<br>Para ello, seleccione los canales necesarios y haga clic en el botón "Guardar".'
      }
    },

    warnings: {
      channels: 'No hay canales activos disponibles. Por favor, contacte con soporte técnico.'
    },

    snackbars: {
      created: 'Widget creado con éxito',
      updated: 'Widget actualizado con éxito',
      removed: 'Widget eliminado con éxito',

      channels: {
        attached: 'Canales vinculados con éxito'
      }
    }
  },

  channels: {
    title: 'Canales',

    titles: {
      create: 'Crear canal',
      templates: 'Plantillas',

      additionalSettings: {
        title: 'Configuraciones adicionales',
        code: 'Configuraciones de código'
      }
    },

    labels: {
      name: 'Título',
      channelType: 'Tipo de canal',
      templates: 'Plantillas',

      additionalSettings: {
        code: {
          length: 'Longitud del código',
          beautify: 'Código bonito'
        },
        lifetime: 'Tiempo de vida del código, seg',
        numberOfTries: 'Número de intentos de ingreso del código',
        numberOfRestart: 'Número máximo de reinicios del canal'
      }
    },

    hints: {
      notFound: 'No se encontraron canales',
      templates: 'Para vincular con éxito las plantillas al canal, seleccione las plantillas necesarias y presione el botón "Vincular".',
      additionalSettings: 'El tiempo de vida y el número de intentos deben ser mayores a 0'
    },

    infos: {
      templates: 'Para que las plantillas se vinculen correctamente al canal, es necesario seleccionar las plantillas necesarias y hacer clic en el botón "Guardar".'
    },

    warnings: {
      templates: 'No hay plantillas disponibles.'
    },

    snackbars: {
      created: 'Canal creado con éxito',
      updated: 'Canal actualizado con éxito',
      removed: 'Canal eliminado con éxito',

      templates: {
        attached: 'Plantillas vinculadas con éxito'
      }
    }
  },

  templates: {
    title: 'Plantillas',

    titles: {
      create: 'Crear plantilla',
      conditions: 'Condiciones'
    },

    labels: {
      name: 'Título',
      channelType: 'Tipo de canal',

      additionalSettings: {
        senderId: 'Remitente',
        template: 'Plantilla'
      }
    },

    hints: {
      notFound: 'No se encontraron plantillas',
      conditions: 'Debe completarse al menos una condición.',

      additionalSettings: {
        template: 'Para insertar el código, use {{ code }}'
      }
    },

    snackbars: {
      created: 'Plantilla creada con éxito',
      updated: 'Plantilla actualizada con éxito',
      removed: 'Plantilla eliminada con éxito'
    }
  }
}
