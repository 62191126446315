import { defaultsDeep } from 'lodash'

import fileNames from '../fileNames'

export default fileNames.reduce((result, name) => {
  const currentFile = require(`./${name}`).default
  const commonsFile = require('./commons').default
  result[name] = defaultsDeep(currentFile, commonsFile)

  return result
}, {})
