import Vue from 'vue'
import { mapGetters } from 'vuex'

import { colors, components } from '@/utils'

const typeColorsMap = {
  created: colors.success,
  updated: colors.info,
  removed: colors.warning
}

export default {
  name: 'GlobalSnackbar',

  computed: {
    ...mapGetters({ snackbars: 'notifications/snackbars' })
  },

  mounted() {
    Vue.$bus.on('rest.**', this.restListener)
  },

  beforeDestroy() {
    Vue.$bus.off('rest.**', this.restListener)
  },

  methods: {
    restListener(serviceName, method) {
      this.addSnackbar({
        text: this.getTranslate(`${serviceName}.snackbars.${method}`),
        type: typeColorsMap[method]
      })
    }
  },

  render(h) {
    return h(components.snackbar, { props: { items: this.snackbars } })
  }
}
