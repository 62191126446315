import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'
import proxy from '@sigma-legacy-libs/g-proxy'

import { toFloatOrUndefined } from '@/utils'

import { invoicesContracts } from '@/components/services/documentTemplates/generate/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [
    proxy({ type: 'object' }),
    invoicesContracts(DOCUMENT_TEMPLATES_TYPES.invoice)
  ],

  props: {
    isNew: Boolean,
    loading: Boolean,
    paddless: Boolean
  },

  computed: {
    validation() {
      const props = [ 'customerId', 'requisiteId', 'companyId', 'amount' ]

      return props.every(prop => Boolean(this.proxy[prop]))
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  async mounted() {
    if (this.isNew) {
      if (this.proxy.customerId === undefined) {
        this.proxy.customerId = this.OwnerId || this.account.id
      }
      await this.getByCustomerId()
      this.setAmount()
    }
  },

  beforeDestroy() {
    if (this.isNew) {
      this.proxy.customerId = undefined
      this.proxy.requisiteId = undefined
      this.proxy.companyId = undefined
      this.proxy.amount = undefined
    }
  },

  methods: {
    _outputFilter(data) {
      data.amount = toFloatOrUndefined(data.amount)

      return data
    },

    setAmount() {
      if (!this.proxy.amount) {
        this.proxy.amount = 1000
      }
    }
  },

  render
}

