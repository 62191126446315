import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import { cloneProvider } from '@/components/services/inactiveProviders/utils'

import render from './render'

export default {
  name: 'CloneButton',

  props: {
    provider: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },

  data() {
    return {
      loading: false,
      showConfirmDialog: false
    }
  },

  methods: {
    async cloneProvider() {
      try {
        this.loading = true
        const { data } = await cloneProvider(this.provider)
        if (data) {
          this.$emit('clone', data.id)
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
