import Vue from 'vue'

import { getCurrencyFromStorage } from '@sigma-frontend-libs/commons'

import { globalErrorHandler, globalErrorProcessor, permissionPresets, services } from '@/utils'

export const currency = {
  data() {
    return {
      currency: getCurrencyFromStorage()
    }
  },

  methods: {
    async getCurrency(ownerId) {
      if (ownerId) {
        if (this.checkPermissions(`advanced.${services.settingsUser}.get`, permissionPresets.resellerUp)) {
          try {
            const { data } = await Vue.$GRequest.get('settingsUser', `${ownerId}/billings.currency`)
            if (data && data.result) {
              this.currency = data.result
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    }
  }
}
