import { cloneDeep } from 'lodash'

import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { PAYMENTS_FILTER, fillDependence, generateServices, permissionPresets, services } from '@/utils'

import render from './render'

export default function(options = {}) {
  return {
    name: 'List',

    mixins: [
      generateServices({
        name: services.payments,

        async inputFilter(result) {
          if (!result.Owner) {
            await fillDependence.call(this, result, {
              service: services.users,
              permissions: permissionPresets.resellerUp,
              pathToId: 'OwnerId',
              pathToObject: 'Owner',
              outputPath: 'Owner',
              picks: PUBLIC_FIELDS_USER
            })
          }

          result.OwnerId = result.OwnerId || result.Owner && result.Owner.id

          return result
        },

        find: {
          defaultFilter: cloneDeep(PAYMENTS_FILTER),
          defaultPagination: { limit: 10 },

          alwaysCreateFromWebSocket: true,
          alwaysUpdateFromWebSocket: true,
          alwaysRemoveFromWebSocket: true
        },

        get: false,
        create: false,
        update: false,
        remove: false
      })
    ],

    data() {
      return {
        showPaymentLogsDialog: false,
        showBillingsDialog: false,

        paymentsBillings: [],

        paymentId: undefined
      }
    },

    mounted() {
      if (options.isPage) {
        this.rest[services.payments].find()
      } else {
        this.restData[services.payments].find.filter.RecurrentPaymentId = options.RecurrentPaymentId
      }
    },

    render
  }
}
