export default {
  title: 'Moderation templates',
  pattern: 'Pattern',

  titles: {
    new: 'New moderation template',
    create: 'Create new moderation template',
    users: 'Users'
  },

  headers: {
    moderation: 'Moderation',
    title: 'Title',
    comment: 'Comment'
  },

  labels: {
    title: 'Title',
    name: 'Sender name',
    text: 'Text',
    type: 'Type',
    moderation: 'Moderation',
    owner: 'Owner',
    search: 'Search',
    id: 'ID',
    isActive: 'Activate or deactivate pattern',
    checkPattern: 'Check pattern',
    sendername: 'Sender name',

    meta: {
      comment: 'Comment',
      adminComment: 'Administrator comment',
      adminCommentPrivate: 'Private administrator comment',
      resellerComment: 'Reseller comment',
      resellerCommentPrivate: 'Private reseller comment'
    }
  },

  statuses: {
    disabled: 'Not requested',
    requested: 'Requested',
    moderation: 'Moderation',
    approved: 'Approved',
    rejected: 'Rejected'
  },

  contents: {
    confirm: {
      remove: 'Delete pattern?'
    }
  },

  errors: {
    isSms: 'Only latin characters or digits allowed, 15 symbols maximum',
    pattern: 'Pattern not passed validation'
  },

  buttons: {
    users: 'Users {count}'
  },

  hints: {
    emptyState: 'No one pattern found',
    notFound: 'Patterns not found',
    checkPattern: 'Enter the text to check the pattern'
  },

  tooltips: {
    favorites: 'Make the pattern a favorite',
    filter: {
      isActive: 'Show deactivated patterns'
    },
    isActive: {
      true: 'Activated',
      false: 'Deactivated'
    }
  },

  snackbars: {
    created: 'Moderation template successfully created.',
    updated: 'Moderation template successfully updated.',
    removed: 'Moderation template successfully removed.'
  }
}
