import { generateServices, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'Remove',

  mixins: [
    generateServices({
      name: services.providers,

      find: false,
      get: false,
      create: false,
      update: false,

      remove: {
        manipulateData: false,
        redirect: services.providers
      }
    })
  ],

  props: {
    id: String
  },

  data() {
    return {
      showDialog: false
    }
  },

  methods: {
    async remove() {
      if (this.checkPermissions(`advanced.${services.providers}.remove`)) {
        try {
          const result = await this.rest[services.providers].remove(this.id)
          if (result) {
            this.showDialog = false
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    }
  },

  render
}
