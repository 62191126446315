import { camelCase } from 'lodash'

import { buttonModes, colors, permissionPresets, states } from '@/utils'

import button from '@/components/button'
import FormTitle from '@/components/misc/FormTitle'

function renderDialog(h, options) {
  let slotTitleCreate = h(
    FormTitle,
    {
      class: 'pt-2 px-2',
      props: {
        value: this.restData[options.name].create.data,
        errors: this.restData[options.name].create.errors
      },
      on: {
        input: event => {
          this.restData[options.name].create.data = event
        },
        submit: () => {
          this.createData()
        },
        validation: event => {
          this.titleIsValid = event
        }
      }
    }
  )
  if (this.$scopedSlots.titleCreate) {
    slotTitleCreate = this.$scopedSlots.titleCreate({ data: this.restData[options.name].create.data })
  } else if (this.$slots.titleCreate) {
    slotTitleCreate = this.$slots.titleCreate
  }

  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        rounded: true,
        maxWidth: this.viewport.breakpoint.mdUp ? this.maxWidth : undefined
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h('div', { slot: 'header' }, [ slotTitleCreate ]),

      h(
        `${camelCase(`${options.name}-form`)}`,
        {
          props: {
            value: this.restData[options.name].create.data,
            errors: this.restData[options.name].create.errors,
            isNew: true
          },
          on: {
            input: event => {
              this.restData[options.name].create.data = event
            },
            submit: () => {
              this.createData()
            },
            validation: event => {
              this.restData[options.name].create.isValid = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'fjcfe facfe grid-gap--8 pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                mode: buttonModes.flat,
                label: this.getTranslate('misc.buttons.cancel'),
                disabled: this.restData[options.name].create.state === states.loading
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.create'),
                loading: this.restData[options.name].create.state === states.loading,
                disabled: !this.createDataIsValid,
                color: colors.primary
              },
              on: {
                click: () => {
                  this.createData()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h, options) {
  if (!options.excludeCreate) {
    if (this.checkPermissions(`advanced.${options.name}.create`, permissionPresets.meUp)) {
      switch (this.type) {
        case 'button': {
          return h(
            'g-button',
            {
              class: 'ma-0',
              props: {
                icon: 'add',
                rounded: true,
                depressed: true,
                color: colors.secondary
              },
              on: {
                click: () => {
                  this.showDialog = true
                }
              },
              scopedSlots: {
                dialog: () => renderDialog.call(this, h, options)
              }
            }
          )
        }

        case 'list-item':
        default: {
          return h(
            'g-list-item',
            {
              props: {
                label: this.getTranslate('misc.buttons.create'),
                color: colors.secondary,
                dense: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.showDialog = true
                }
              },
              scopedSlots: {
                dialog: () => renderDialog.call(this, h, options)
              }
            }
          )
        }
      }
    }
  }
}
