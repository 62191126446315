import { SENDING_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, components, icons, inputModes, services } from '@/utils'

import info from '@/components/info'
import arrow from '@/components/misc/arrow'

function renderInfo(h, icon, value, trigger = true) {
  if (trigger) {
    return h(
      info,
      {
        props: {
          icon,
          value,
          color: colors.grey
        }
      }
    )
  }
}

function renderStatusSelect(h) {
  if (this.hasOnStatus) {
    return h(
      components.select,
      {
        props: {
          value: this.proxy.onStatus,
          label: this.getTranslate(`${services.sendings}.labels.status`),
          hint: this.getTranslate(`${services.sendings}.labels.options.status`),
          disabled: this.disabled,
          readonly: this.readonly,
          items: SENDING_STATUS.filter(item => item !== SENDING_STATUS.paused).map(item => ({
            title: this.getTranslate(`${services.sendings}.statuses.${item}`),
            value: item
          })),
          mode: inputModes.outline,
          rounded: true,
          multiple: true
        },
        on: {
          input: event => {
            this.proxy.onStatus = event
          }
        }
      }
    )
  }
}

function renderTimeout(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.onTimeout.timeout,
        label: this.getTranslate(`${services.sendings}.labels.time`),
        hint: this.getTranslate(`${services.sendings}.labels.options.timeout`),
        error: this.timeoutError,
        suffix: this.getTranslate('misc.measure.time.sec').toLowerCase(),
        disabled: this.disabled,
        readonly: this.readonly,
        mode: inputModes.outline,
        rounded: true,
        type: 'number',
        min: 10,
        step: 'any'
      },
      on: {
        input: event => {
          this.proxy.onTimeout.timeout = event
        }
      }
    }
  )
}
function renderExcept(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.onTimeout.except,
        label: this.getTranslate(`${services.sendings}.labels.status`),
        hint: this.getTranslate(`${services.sendings}.labels.options.except`),
        items: SENDING_STATUS.filter(item => item !== SENDING_STATUS.paused).map(item => ({
          title: this.getTranslate(`${services.sendings}.statuses.${item}`),
          value: item
        })),
        disabled: this.disabled,
        readonly: this.readonly,
        mode: inputModes.outline,
        rounded: true,
        multiple: true
      },
      on: {
        input: event => {
          this.proxy.onTimeout.except = event
        }
      }
    }
  )
}
function renderOnTimeout(h) {
  if (this.hasOnTimeout) {
    return h(
      'div',
      {
        class: `${services.sendingsFallbacks}-options__section`
      },
      [
        renderInfo.call(
          this,
          h,
          icons.arrow_drop_down,
          this.getTranslate('misc.or'),
          this.hasOnStatus
        ),
        h(
          'div',
          {
            class: `${services.sendingsFallbacks}-options__section-timeout`
          },
          [
            renderTimeout.call(this, h),
            renderExcept.call(this, h)
          ]
        )
      ]
    )
  }
}

function renderText(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.onIncomingPayload.text,
        label: this.getTranslate(`${services.sendings}.labels.text`),
        hint: this.getTranslate(`${services.sendings}.labels.options.onIncomingPayload.text`),
        mode: inputModes.outline,
        rounded: true,
        disabled: this.disabled,
        readonly: this.readonly
      },
      on: {
        input: event => {
          this.proxy.onIncomingPayload.text = event
        }
      }
    }
  )
}
function renderOnIncomingPayload(h) {
  if (this.proxy.onIncomingPayload) {
    return h(
      'div',
      {
        class: `${services.sendingsFallbacks}-options__section`
      },
      [
        renderInfo.call(
          this,
          h,
          icons.arrow_drop_down,
          this.getTranslate('misc.or'),
          this.hasOnStatus && this.hasOnTimeout
        ),
        renderText.call(this, h)
      ]
    )
  }
}

function renderOptions(h) {
  return h(
    'div',
    {
      class: `${services.sendingsFallbacks}-options__panel-body`
    },
    [
      renderInfo.call(this, h, icons.done, this.getTranslate(`${services.sendings}.subheaders.resend`)),
      renderStatusSelect.call(this, h),
      renderOnTimeout.call(this, h),
      renderOnIncomingPayload.call(this, h)
    ]
  )
}
function renderPanel(h) {
  return h(
    components['expansion-panel'],
    {
      class: `${services.sendingsFallbacks}-options__panel`,
      scopedSlots: {
        header: ({ expanded }) => {
          return h(
            'div',
            {
              class: 'default-expansion-panel-header'
            },
            [
              this.getTranslate(`${services.sendings}.subheaders.options`),
              h(arrow, { props: { expanded } })
            ]
          )
        }
      }
    },
    [ renderOptions.call(this, h) ]
  )
}

export default function(h) {
  return h(
    components['expansion-panels'],
    {
      class: `${services.sendingsFallbacks}-options`,
      props: {
        outline: true,
        rounded: true
      }
    },
    [ renderPanel.call(this, h) ]
  )
}
