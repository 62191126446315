import { get } from 'lodash'

import { REQUISITE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices, permissionPresets, services } from '@/utils'

export function invoicesContracts(type) {
  return {
    mixins: [
      generateServices([
        {
          name: services.requisites,

          find: {
            useCache: true,
            defaultFilter: {
              isActive: true,
              type: REQUISITE_TYPES.business,
              OwnerId: undefined
            }
          },
          get: false,
          update: false,
          create: false,
          remove: false,

          disableWatchers: true
        },
        {
          name: services.companies,

          inputFilter(data) {
            if (type) {
              const { DocumentTemplatesTypes } = data
              if (Array.isArray(DocumentTemplatesTypes) && !DocumentTemplatesTypes.includes(type)) {
                return undefined
              }
            }

            return data
          },
          filterDataByBoolean: true,

          find: {
            useCache: true,
            defaultFilter: {
              vat: undefined,
              $scope: [ 'documentTypes' ],
              OwnerId: undefined
            }
          },
          get: false,
          update: false,
          create: false,
          remove: false,

          disableWatchers: true
        }
      ])
    ],

    props: {
      OwnerId: String,
      ResellerId: String
    },

    data() {
      return {
        vat: false
      }
    },

    computed: {
      customerId() {
        return this.proxy.customerId || this.OwnerId || this.account.id
      }
    },

    watch: {
      'proxy.requisiteId'(value) {
        this.proxy.companyId = undefined
        if (value) {
          const requisite = this.restData[services.requisites].find.data.find(item => item.id === value)
          if (requisite) {
            this.vat = get(requisite, 'data.tax.vat')
            this.getCompanies()
          }
        }
      },

      customerId(value) {
        if (value) {
          this.getByCustomerId()
        }
      }
    },

    mounted() {
      this.getByCustomerId()
    },

    beforeDestroy() {
      this.reset()
    },

    methods: {
      async getRequisites() {
        this.restData[services.requisites].find.filter.OwnerId = this.customerId

        const requisites = await this.rest.requisites.find()
        if (Array.isArray(requisites) && requisites.length) {
          this.proxy.requisiteId = requisites[0].id
          this.vat = requisites[0].data.tax.vat
        } else {
          this.proxy.requisiteId = undefined
          this.vat = undefined
        }
      },

      async getCompanies() {
        this.restData[services.companies].find.filter.vat = this.vat
        this.restData[services.companies].find.filter.OwnerId = this.customerId

        if (this.checkPermissions(`advanced.${services.companies}.get`, permissionPresets.resellerUp)) {
          this.restData[services.companies].find.filter.OwnerId = this.ResellerId || '$global'
        }

        const companies = await this.rest.companies.find()
        if (Array.isArray(companies) && companies.length) {
          this.proxy.companyId = companies[0].id
        } else {
          this.proxy.companyId = undefined
        }
      },

      reset() {
        this.proxy.requisiteId = undefined
        this.proxy.companyId = undefined
      },

      async getByCustomerId() {
        this.reset()
        await this.getRequisites()
        await this.getCompanies()
      }
    }
  }
}
