import { cloneDeep } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import { projectName } from '@/utils'

import render from './render'

export default {
  name: 'Pagination',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    arrows: {
      type: Boolean,
      default: true
    },

    edgeArrows: {
      type: Boolean,
      default: true
    },

    selectRows: {
      type: Boolean,
      default: true
    },

    show: {
      type: Boolean,
      default: true
    },

    chunk: {
      type: Number,
      default: 3
    },

    service: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      limit: 25,
      showMenu: false
    }
  },

  computed: {
    rowsPerPage() {
      const limit = 25

      return [ limit, limit * 2, limit * 4 ]
    },

    currentPage() {
      let page = Math.ceil(this.proxy.offset / this.proxy.limit + 1)
      if (page < 1) {
        page = 1
      }

      return page
    },

    $chunk() {
      if (this.viewport.breakpoint.smUp) {
        return this.chunk
      }

      return 1
    },

    pages() {
      const pages = []

      const chunk = this.$chunk - 1

      let leftAmount = Math.floor(chunk / 2)
      let rightAmount = chunk - leftAmount

      for (let index = 1; index <= leftAmount; index++) {
        const amount = this.currentPage - index
        if (amount > 0) {
          pages.unshift(amount)
        }
      }

      leftAmount -= pages.length
      rightAmount += leftAmount

      pages.push(this.currentPage)

      for (let index = 1; index <= rightAmount; index++) {
        const amount = this.currentPage + index
        if (amount <= this.totalPages) {
          pages.push(amount)
        }
      }

      leftAmount = this.$chunk - pages.length

      const b = pages[0]

      for (let index = 1; index <= leftAmount; index++) {
        const a = b - index
        if (a > 0) {
          pages.unshift(a)
        }
      }

      return pages
    },

    totalPages() {
      if (this.proxy.total > 0) {
        return Math.ceil(this.proxy.total / this.proxy.limit)
      } else {
        return 1
      }
    },

    buttonColumnsStyle() {
      const result = []
      const buttonWidth = 'auto'

      let arrowColumns = this.edgeArrows ? 2 : 1
      if (this.viewport.breakpoint.smDown) {
        arrowColumns = 1
      }

      result.push(`repeat(${arrowColumns}, ${buttonWidth})`)
      result.push(`repeat(${this.pages.length || 0}, ${buttonWidth})`)
      result.push(`repeat(${arrowColumns}, ${buttonWidth})`)

      if (this.selectRows && this.viewport.size.width > 320) {
        result.push('auto')
      }

      result.push('auto')

      return result.join(' ')
    },

    isAdmin() {
      return this.checkPermissions([ `advanced.${this.service}.get`, `advanced.${this.service}.find` ])
    }
  },

  mounted() {
    this.limit = cloneDeep(this.proxy.limit)
  },

  methods: {
    setPage(page, limit) {
      limit = limit || this.proxy.limit

      window.localStorage.setItem(`${projectName}:pagination:limit:${this.service || 'all'}`, JSON.stringify(limit))

      Object.assign(this.proxy, {
        limit,
        offset: limit * (page - 1)
      })

      this.showMenu = false
    }
  },

  render
}
