export default {
  total: 'Total',

  no: {
    data: {
      text: 'No hay datos disponibles'
    }
  },

  contents: {
    lastMonthSum: 'Acumulaciones de referencia para',
    referralCode: 'Código de referencia: '
  },

  headers: {
    amount: 'Cantidad',
    referral: 'Referido',
    date: 'Fecha'
  }
}
