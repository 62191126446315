import { get } from 'lodash'

import { checkHostnames } from '@sigma-frontend-libs/commons'

import { imlinkHostnames } from '@/utils'

export default function(h) {
  if (process.env.NODE_ENV !== 'production') {
    return
  }

  if (checkHostnames(imlinkHostnames)) {
    return
  }

  const jivositeId = get(this.globalSettings, 'frontend.jivosite')
  if (jivositeId) {
    const toggleVisibility = show => {
      const container = document.querySelector('jdiv')
      if (container) {
        if (!show) {
          container.classList.add('jivo-override')
        } else {
          container.classList.remove('jivo-override')
        }
      }
    }

    return h(
      'script',
      {
        attrs: {
          type: 'text/javascript',
          async: true,
          src: '//code.jivosite.com/widget.js',
          'data-jv-id': jivositeId
        },
        on: {
          load: async () => {
            while (!window.jivo_api) {
              await new Promise(resolve => setTimeout(resolve, 100))
            }

            toggleVisibility(false)
            const closeButton = document.getElementById('jivo_close_button')
            if (closeButton) {
              closeButton.addEventListener('click', () => {
                toggleVisibility(false)
              })
            }

            window.jivo_onOpen = () => {
              window.jivo_api.setContactInfo({
                name: this.account.data.firstName,
                email: this.account.data.email,
                phone: this.account.data.phone
              })

              toggleVisibility(true)
            }

            window.jivo_onLoadCallback = function() {
              toggleVisibility(false)
            }
          }
        }
      }
    )
  }
}
