import { get } from 'lodash'

import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, icons, inputModes, services, sizes } from '@/utils'

import button from '@/components/button'
import info from '@/components/info'
import { payloadKeys } from '@/components/services/message-payload-generator/utils'
import tag from '@/components/tag'

function renderTextField(h, key, hint = false, required = false) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.payload[key],
        label: this.getTranslate(`${services['message-payload-generator']}.labels.${key}`),
        hint: hint ? this.getTranslate(`${services['message-payload-generator']}.hints.${key}`) : undefined,
        error: this.errorsByField[key],
        mode: inputModes['line-label'],
        rounded: true,
        dense: true,
        details: hint,
        required,
        disabled: this.loading,
        clearable: true,
        mask: ~[ payloadKeys.smsSegments, payloadKeys.otherChars ].indexOf(key) ? /^[0-9]\d*$/ : undefined
      },
      on: {
        input: event => {
          this.payload[key] = event
        }
      }
    }
  )
}
function renderTextArea(h, key, hint = false, required = false) {
  return h(
    components.textarea,
    {
      props: {
        value: this.payload[key],
        label: this.getTranslate(`${services['message-payload-generator']}.labels.${key}`),
        mode: inputModes['line-label'],
        rounded: true,
        dense: true,
        hint: hint ? this.getTranslate(`${services['message-payload-generator']}.hints.${key}`) : undefined,
        required,
        disabled: this.loading,
        clearable: true
      },
      on: {
        input: event => {
          this.payload[key] = event
        }
      }
    }
  )
}
function renderSelect(h, key) {
  return h(
    components.select,
    {
      props: {
        value: this.payload[key],
        label: this.getTranslate(`${services['message-payload-generator']}.labels.${key}`),
        items: this.selectItems[key],
        mode: inputModes['line-label'],
        rounded: true,
        dense: true,
        details: false,
        clearable: key !== payloadKeys.language,
        disabled: this.loading
      },
      on: {
        input: event => {
          this.payload[key] = event
        }
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            tag,
            {
              props: {
                label: item.title,
                size: sizes.small,
                as: 'select-dense'
              }
            }
          )
        }
      }
    }
  )
}
function renderSwitch(h) {
  return h(
    components.switch,
    {
      props: {
        value: this.payload[payloadKeys.addEmojis],
        label: this.getTranslate(`${services['message-payload-generator']}.labels.${payloadKeys.addEmojis}`),
        outline: true,
        disabled: this.loading
      },
      on: {
        input: event => {
          this.payload[payloadKeys.addEmojis] = event
        }
      }
    }
  )
}

function renderBaseFields(h) {
  return h(
    'div',
    {
      class: `${services['message-payload-generator']}-button__fields`
    },
    [
      h(
        'div',
        {
          class: `${services['message-payload-generator']}-button__fields-row`
        },
        [
          renderTextField.call(this, h, payloadKeys.messageTheme, true, true),
          renderTextField.call(this, h, this.channel === SENDING_TYPES.sms ? payloadKeys.smsSegments : payloadKeys.otherChars, true, true)
        ]
      ),

      renderTextArea.call(this, h, payloadKeys.occasion, true, true)
    ]
  )
}
function renderAdditionalInfo(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${services['message-payload-generator']}.infos.additional`)
      }
    }
  )
}
function renderAdditionalFields(h) {
  return h(
    'div',
    {
      class: `${services['message-payload-generator']}-button__fields--additional`
    },
    [
      renderTextArea.call(this, h, payloadKeys.additionalNotes1, true, false),
      renderTextArea.call(this, h, payloadKeys.rephraseText, true, false),
      renderTextField.call(this, h, payloadKeys.endQuestion),
      renderSelect.call(this, h, payloadKeys.emotionalTone),
      renderSelect.call(this, h, payloadKeys.language),
      renderSwitch.call(this, h)
    ]
  )
}
function renderAdditionalDialogFooter(h) {
  return h(
    'div',
    {
      class: 'faic fjcfe grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat,
            disabled: this.loading
          },
          on: {
            click: () => {
              this.showAdditionalDialog = !this.showAdditionalDialog
            }
          }
        }
      )
    ]
  )
}
function renderAdditionalDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`${services['message-payload-generator']}.titles.additional`),
        value: this.showAdditionalDialog,
        rounded: true,
        maxWidth: 800
      },
      on: {
        input: event => {
          this.showAdditionalDialog = event
        }
      }
    },
    [
      renderAdditionalFields.call(this, h),
      renderAdditionalDialogFooter.call(this, h)
    ]
  )
}
function renderAdditionalButtons(h) {
  return h(
    'div',
    {
      class: `${services['message-payload-generator']}-button__additional-buttons`
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate(`${services['message-payload-generator']}.buttons.additional`),
            mode: buttonModes.flat,
            icon: icons.settings,
            fullWidth: true,
            color: colors.info
          },
          on: {
            click: () => {
              this.showAdditionalDialog = !this.showAdditionalDialog
            }
          },
          scopedSlots: {
            dialog: () => {
              return renderAdditionalDialog.call(this, h)
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.clear'),
            mode: buttonModes.flat,
            icon: icons.clear,
            disabled: this.loading
          },
          on: {
            click: () => {
              this.clearPayload()
            }
          }
        }
      )
    ]
  )
}
function renderAdditional(h) {
  return h(
    'div',
    {
      class: `${services['message-payload-generator']}-button__additional`
    },
    [
      renderAdditionalInfo.call(this, h),
      renderAdditionalButtons.call(this, h)
    ]
  )
}
function renderFields(h) {
  return h(
    'div',
    {
      class: `${services['message-payload-generator']}-button__form-fields`
    },
    [
      renderBaseFields.call(this, h),
      renderAdditional.call(this, h)
    ]
  )
}

function renderTextInfo(h) {
  if (!this.text) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate(`${services['message-payload-generator']}.infos.text`),
          color: colors.grey
        }
      }
    )
  }
}
function renderTextWarning(h) {
  if (this.interval) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate(`${services['message-payload-generator']}.warnings.limit`),
          color: colors.warning
        }
      }
    )
  }
}
function renderGeneratedText(h) {
  if (this.displayedText) {
    return h(
      'div',
      {
        class: `${services['message-payload-generator']}-button__text-generated`,
        domProps: { innerHTML: this.displayedText }
      }
    )
  }
}
function renderTextStat(h, key) {
  const value = get(this.stats, key)
  if (value) {
    return h(
      tag,
      {
        props: {
          label: this.getTranslate(`${services['message-payload-generator']}.stats.${key}`, { value }),
          size: sizes.small,
          color: colors.primary
        }
      }
    )
  }
}
function renderTextStats(h) {
  if (this.text) {
    return h(
      'div',
      {
        class: `${services['message-payload-generator']}-button__text-stats`
      },
      [
        renderTextStat.call(this, h, 'characters'),
        renderTextStat.call(this, h, 'segments')
      ]
    )
  }
}
function renderTextContent(h) {
  return h(
    components.card,
    {
      class: `${services['message-payload-generator']}-button__text-content`,
      props: {
        title: this.getTranslate(`${services['message-payload-generator']}.titles.text`),
        outline: true,
        dashed: true,
        rounded: true
      }
    },
    [
      renderTextInfo.call(this, h),
      renderTextWarning.call(this, h),
      renderGeneratedText.call(this, h),
      renderTextStats.call(this, h)
    ]
  )
}
function renderGenerateButton(h) {
  return h(
    'div',
    {
      class: `${services['message-payload-generator']}-button__text-button`
    },
    [
      h(
        button,
        {
          style: { minWidth: '180px' },
          props: {
            iconSVG: 'ai',
            mode: buttonModes.flat,
            label: this.buttonLabel,
            color: colors.epic,
            loading: this.loading,
            disabled: this.loading || this.disabled
          },
          on: {
            click: () => {
              this.generate()
            }
          }
        }
      )
    ]
  )
}
function renderText(h) {
  return h(
    'div',
    {
      class: `${services['message-payload-generator']}-button__text`
    },
    [
      renderTextContent.call(this, h),
      renderGenerateButton.call(this, h)
    ]
  )
}

function renderDialogBody(h) {
  return h(
    'div',
    {
      class: `${services['message-payload-generator']}-button__form`
    },
    [
      renderFields.call(this, h),
      renderText.call(this, h)
    ]
  )
}
function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'faic fjcfe grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat,
            disabled: this.loading
          },
          on: {
            click: () => {
              this.showDialog = !this.showDialog
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.apply'),
            mode: buttonModes.filled,
            color: colors.primary,
            disabled: this.loading || !this.text
          },
          on: {
            click: () => {
              this.$emit('input', this.text)
              this.showDialog = !this.showDialog
            }
          }
        }
      )
    ]
  )
}
function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`${services['message-payload-generator']}.titles.generate`),
        value: this.showDialog,
        rounded: true,
        maxWidth: 1000
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      renderDialogBody.call(this, h),
      renderDialogFooter.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    button,
    {
      props: {
        iconSVG: 'ai',
        mode: buttonModes.flat,
        color: colors.epic,
        tooltip: this.getTranslate(`${services['message-payload-generator']}.tooltips.generate`)
      },
      scopedSlots: {
        dialog: () => {
          return renderDialog.call(this, h)
        }
      },
      on: {
        click: () => {
          this.showDialog = !this.showDialog
        }
      }
    }
  )
}
