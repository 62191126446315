import { types } from './sendings'

export const notificationTypes = {
  tariff: {
    update: 'Tarifa actualizada'
  },

  balance: {
    limit: 'Se alcanzó el límite de saldo',
    accrual: 'Acreditación en el saldo',
    daily: 'Notificación diaria de saldo'
  },

  payments: {
    failed: 'El pago no fue exitoso'
  },

  auth: {
    registration: 'Registro',
    recover: 'Recuperación de contraseña'
  },

  pattern: {
    approved: 'Plantilla aprobada',
    rejected: 'Plantilla rechazada'
  },

  sendername: {
    approved: 'Nombre del remitente aprobado',
    rejected: 'Nombre del remitente rechazado'
  },

  subscription: {
    enabled: 'Suscripción activada',
    renew: {
      failed: 'Suscripción no renovada'
    }
  },

  sendings: {
    bulk: {
      created: 'Envío masivo creado',
      paused: 'Envío masivo pausado',
      resumed: 'Envío masivo reanudado'
    }
  },

  system: {
    platform: {
      issues: 'Error del sistema de la plataforma'
    },

    route: {
      issues: 'Error del sistema de enrutamiento'
    }
  }
}

const templates = { ...notificationTypes }

export default {
  title: 'Notificaciones',

  titles: {
    variables: 'Variables'
  },

  subtitles: {
    settingsSite: {
      smtp: 'Conexión SMTP',
      sms: 'Notificaciones SMS',
      templates: 'Plantillas de notificación'
    }
  },

  labels: {
    notification: 'Notificación',
    subject: 'Asunto',
    body: 'Cuerpo de la plantilla',

    channel: 'Canal',
    owner: 'Destinatario',
    targetUser: 'Destinatario',
    type: 'Tipo',
    text: 'Texto',
    sentAt: 'Enviado a las',
    isRead: 'Estado',

    widget: {
      header: 'Últimas notificaciones',
      hint: 'Las últimas 10 notificaciones recibidas a través del canal Web',
      empty: 'Aquí se mostrarán tus notificaciones'
    },

    settingsSite: {
      smtp: {
        host: 'Host',
        port: 'Puerto',
        from: 'Desde',
        login: 'Nombre de usuario',
        password: 'Contraseña'
      },

      sms: {
        senderUserId: 'Remitente',
        sendername: 'Nombre del remitente'
      },

      templates: {
        users: 'Plantillas para usuarios',
        managers: 'Plantillas para gerentes'
      }
    },

    settingsUser: {
      templates: {
        my: 'Mis eventos',
        users: 'Eventos de usuarios secundarios'
      },

      balance: {
        title: 'Notificación de saldo',

        include: {
          overdraft: 'Incluyendo sobregiro',
          invoice: 'Enviar factura'
        },

        notInclude: {
          overdraft: 'Sin incluir sobregiro',
          invoice: ''
        },

        limit: 'Límite',

        onLimit: 'Con saldo',
        noLimit: '<límite no especificado>',
        notifyBy: 'notificar por',
        noNotifications: '<no notificar>',
        activator: 'La notificación se activará con un saldo de {limit}',
        overdraft: ' + sobregiro del usuario',
        extraRecipients: 'Notificaciones sobre el saldo de todos los usuarios del sistema',
        invoice: 'Adjuntar factura por un monto de {amount}',

        buttons: {
          add: 'Agregar límite',
          invoice: {
            attach: 'Adjuntar factura',
            detach: 'Desvincular factura',
            attached: 'Se emitirá una factura por un monto de {amount}'
          }
        }
      }
    }
  },

  hints: {
    variables: 'Consulte la documentación para obtener más información sobre cómo completar correctamente las plantillas y utilizar variables.',

    widget: {
      header: 'Aquí se muestran las últimas notificaciones de los temas para los que se han habilitado las notificaciones a través del canal web.'
    },

    notifications: {
      events: {
        balance: {
          limit: {
            empty: 'No se especificó ningún límite de saldo.<br>Puedes agregar fácilmente un nuevo límite haciendo clic en el botón "Agregar" a continuación.'
          }
        }
      }
    }
  },

  channels: types,
  types: notificationTypes,
  events: notificationTypes,
  childrenUsersEvents: notificationTypes,

  email: {
    smtp: {
      host: 'Host',
      port: 'Puerto',
      from: 'Desde',
      login: 'Nombre de usuario',
      password: 'Contraseña'
    },

    templates
  },

  sms: {
    sendername: 'Nombre del remitente',
    senderUserId: 'Remitente',
    templates
  },

  web: { templates },
  push: { templates },
  tgBot: { templates },

  snackbars: {
    updated: 'Configuración actualizada con éxito.'
  },

  isRead: {
    true: 'Leído',
    false: 'No leído',
    sent: 'Enviado: {date}',
    delivered: 'Entregado: {date}'
  },

  tooltips: {
    channel: {
      on: 'Activado',
      off: 'Desactivado'
    }
  }
}
