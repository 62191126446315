export default {
  title: 'Cuenta',

  current: {
    balance: 'Saldo actual',
    overdraft: 'Sobregiro actual'
  },

  tooltips: {
    warning: 'Actualmente está utilizando sobregiro.',
    error: 'Has alcanzado el límite de sobregiro.'
  }
}
