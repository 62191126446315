import { components, isEmpty } from '@/utils'

import { core } from './mixins'
import render from './render'

export default {
  name: components.checkbox,

  mixins: [ core ],

  props: {
    label: [ String, Number ],
    tooltip: [ String, Number ],

    loading: Boolean,
    outline: Boolean
  },

  computed: {
    $_attrs() {
      return {
        role: 'checkbox',
        'aria-checked': typeof this.proxy === 'boolean' ? `${this.proxy}` : 'mixed'
      }
    },

    $_class() {
      return {
        [`${components.checkbox}`]: true,
        [`${components.checkbox}--checked`]: this.checked,
        [`${components.checkbox}--disabled`]: this.disabled,
        [`${components.checkbox}--unknown`]: isEmpty(this.proxy),
        [`${components.checkbox}--labeled`]: !!this.label,
        [`${components.checkbox}--outline`]: this.outline
      }
    },

    $_directives() {
      const result = []
      if (this.tooltip) {
        result.push({
          name: components.tooltip,
          options: { value: this.tooltip || this.title }
        })
      }

      return result
    },

    $_on() {
      return {
        click: () => {
          this.toggle()
        }
      }
    }
  },

  render
}
