import { buttonModes, colors, components, inputModes, services, sizes } from '@/utils'

import button from '@/components/button'
import preloader from '@/components/preloader'
import eventsHistoryTab from '@/components/services/eventsHistory/tab'
import textHighlight from '@/components/text/highlight'

function renderTextField(h) {
  return h(
    components.textarea,
    {
      props: {
        value: this.text,
        label: this.getTranslate(`${services.keywords}.labels.text`),
        mode: inputModes['line-label'],
        rounded: true,
        grow: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.text = event
        }
      }
    }
  )
}

function renderTextHighlight(h) {
  return h(
    textHighlight,
    {
      props: {
        value: this.text
      }
    }
  )
}

function renderMatchWord(h, word) {
  return h(
    button,
    {
      props: {
        label: word,
        size: sizes.small,
        color: colors.primary,
        mode: buttonModes.flat
      },
      on: {
        click: () => this.setKeyword(word)
      }
    }
  )
}
function renderWordsMatchesContent(h) {
  if (this.loading) {
    return h(
      'div',
      {
        class: 'preloader__holder pa-5'
      },
      [ h(preloader) ]
    )
  } else if (Array.isArray(this.words) && this.words.length) {
    return h(
      'div',
      {
        class: `${services.keywords}-matches__words`
      },
      [ this.words.map(word => renderMatchWord.call(this, h, word)) ]
    )
  }

  return h(components.empty)
}
function renderWordsMatches(h) {
  return h(
    components.card,
    {
      props: {
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [ renderWordsMatchesContent.call(this, h) ]
  )
}

function renderBody(h) {
  return h(
    'div',
    {
      class: `${services.keywords}-matches__body`
    },
    [
      renderTextField.call(this, h),
      renderTextHighlight.call(this, h),
      renderWordsMatches.call(this, h)
    ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.match'),
            color: colors.primary,
            mode: buttonModes.flat,
            loading: this.loading,
            disabled: this.loading || !this.text
          },
          on: {
            click: () => this.matchText()
          }
        }
      )
    ]
  )
}

function renderTabContent(h) {
  switch (this.tab) {
    case 'matches': {
      return h(
        'div',
        {
          class: `${services.keywords}-matches`
        },
        [
          renderBody.call(this, h),
          renderFooter.call(this, h)
        ]
      )
    }
    case services.eventsHistory: {
      return h(
        eventsHistoryTab,
        {
          props: {
            entityId: this.$route.params.id
          }
        }
      )
    }
  }
}
function renderTab(h, key) {
  return h(
    components.card,
    {
      props: {
        rounded: true,
        outline: true,
        width: '100%'
      },
      key
    },
    [ renderTabContent.call(this, h) ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        components['tabs-header'],
        {
          props: {
            value: this.tab,
            tabs: this.tabs
          },
          on: {
            input: event => {
              this.tab = event
            }
          }
        }
      ),
      h(
        components.tabs,
        {
          props: { value: this.tab },
          scopedSlots: {
            body: () => this.tabs.map(tab => {
              return renderTab.call(this, h, tab.key)
            })
          }
        }
      )
    ]
  )
}
