export const methods = {
  yooMoney: 'YooMoney',
  tinkoff: 'Tinkoff',
  mandarin: 'Mandarin',
  stripe: 'Stripe',
  cards: 'Pago con tarjeta',
  recurrent: 'Pago recurrente',
  invoice: 'Factura'
}

export default {
  title: 'Pagos',

  titles: {
    individual: 'Personas físicas',
    legal: 'Personas jurídicas',
    threshold: 'Por umbral',
    frequency: 'Por tiempo',
    billings: 'Operaciones financieras',
    paymentLogs: 'Registro de solicitudes',

    step: {
      first: 'Método de pago',
      individual: 'Método de pago',
      legal: 'Emisión de factura',
      cards: 'Pago con tarjeta',
      yooMoney: 'YooMoney',
      recurrent: 'Pago recurrente'
    }
  },

  labels: {
    amount: 'Cantidad',
    method: 'Método',
    frequency: '',
    status: 'Estado',
    paymentType: 'Tipo de pago',
    sum: 'Cantidad',
    owner: 'Propietario',
    acknowledged: 'He leído la información, entiendo que sin enviar el correo mi pago no será acreditado, y me comprometo a adjuntar el recibo al correo.',

    methods: {
      tinkoff: {
        TerminalKey: 'Clave del terminal',
        Password: 'Contraseña',
        ekamToken: 'Token EKAM'
      },

      mandarin: {
        merchantId: 'ID del comerciante',
        secret: 'Clave secreta',
        ekamToken: 'Token EKAM',
        includeFiscalInformation: 'Incluir información fiscal'
      },

      stripe: {
        apikey: 'Clave API',
        usd_price_id: 'ID de precio para USD',
        eur_price_id: 'ID de precio para EUR'
      }
    }
  },

  recurrentTypes: {
    once: 'Una vez',
    daily: 'Diariamente',
    weekly: 'Semanalmente',
    monthly: 'Mensualmente'
  },

  contents: {
    legal: 'Para emitir una factura, por favor escriba a <a href="mailto:support@sigmasms.ru">support@sigmasms.ru</a>.',

    payment: {
      result: {
        success: 'Pago realizado con éxito.<br>Los fondos serán acreditados en su cuenta pronto.',
        fail: 'No se pudo completar el pago.<br>Por favor, contacte con el gerente para resolver este problema.'
      }
    }
  },

  hints: {
    delay: 'El saldo se acredita en 5 minutos',
    commission: 'Comisión del banco:',
    commissions: 'comisiones',
    yooMoney: 'También puede pagar con el sistema de pago YooMoney, con una comisión menor.',
    noOneFound: 'No se encontró ningún pago',
    nextPaymentAt: 'Próximo pago',
    addPayment: 'Agregar pago',
    offer: 'Acepto los términos del contrato de oferta.',
    goToPayment: 'Ir al pago',
    notFound: 'No se encontró ningún pago',
    individual: 'Para recargar el saldo de su cuenta personal con tarjeta de crédito, contáctenos de cualquier manera conveniente.',
    owner: 'Propietario',
    due: 'Comisión: {due}%',
    sum: 'Comisión: 3%',
    recurrent: 'Pago automático recurrente'
  },

  types: {
    individual: 'Persona física',
    legal: 'Persona jurídica'
  },

  methods,

  buttons: {
    add: {
      funds: 'Recargar saldo',
      payment: 'Configurar pago automático'
    },
    request: {
      payment: 'Solicitar pago'
    },
    billings: 'Historial de operaciones financieras',
    paymentLogs: 'Registro de solicitudes',
    refund: 'Reembolso de pago'
  },

  links: {
    attachInvoice: 'Adjuntar factura',
    attachInvoiceTo: 'Adjuntar factura a {title}'
  },

  statuses: {
    init: 'Iniciado',
    completed: 'Completado',
    refundRequested: 'Reembolso solicitado',
    refunded: 'Reembolsado',
    partialRefunded: 'Reembolsado parcialmente',
    error: 'Error'
  },

  tabs: {
    payments: 'Pagos',
    recurrentPayments: 'Pagos recurrentes'
  },

  infos: {
    method: 'Actualmente no hay métodos de pago configurados. Por favor, contacte con el soporte.'
  },

  warnings: {
    yooMoney: `
<center>⚠ <strong>¡INFORMACIÓN IMPORTANTE!</strong> ⚠</center>
<br>
<center><strong>¡SI NO NOS INFORMA SOBRE EL PAGO, EL DINERO NO SERÁ ACREDITADO!</strong></center>
<br>
Para que podamos acreditar su pago, debe <strong>escribirnos un correo</strong>:
<div><a href="mailto:a1@sigmasms.ru?subject=Pago%20a%20través%20de%20YooMoney%20–%20identificación&body=Hola.%0A%0AUsuario: {username}%0ACantidad: {sum}%0A%0AAdjunto el recibo en el archivo adjunto.">a1@sigmasms.ru</a></div>
<div><strong>Simplemente haga clic en el enlace: el correo ya está completado, solo necesita enviarlo. No olvide adjuntar el recibo de pago.</strong></div>
<br>
Si completa el correo usted mismo, indique en el cuerpo del correo:
<ul>
<li><strong>Su usuario</strong> (de lo contrario, no sabremos a quién acreditar el dinero)</li>
<li><strong>La cantidad del pago</strong> (para encontrarlo en el sistema)</li>
<li><strong>Adjunte el recibo de pago en el archivo adjunto</strong> (para confirmar la transacción)</li>
</ul>
<p><strong>Sin estos datos, no podremos acreditar el pago, y el dinero quedará sin identificar.</strong></p>
<strong>Comisión aproximada del servicio: 3%.</strong>
<div>&#x2197; <a href="https://yoomoney.ru/page?id=536332" target="_blank">Más información sobre las comisiones de YooMoney</a></div>
`,

    no: {
      methods: 'Actualmente no hay métodos de pago configurados. Por favor, contacte con el soporte.'
    }
  }
}
