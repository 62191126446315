export default {
  RUB: {
    title: 'rublo',
    short: 'rub.',
    full: '₽ rublo',
    symbol: {
      html: '&#8381;',
      unicode: '₽'
    }
  },

  USD: {
    title: 'dólar',
    short: 'USD',
    full: '$ dólar',
    symbol: {
      html: '&#36;',
      unicode: '$'
    }
  },

  EUR: {
    title: 'euro',
    short: 'EUR',
    full: '€ euro',
    symbol: {
      html: '&#8364;',
      unicode: '€'
    }
  },

  MXN: {
    title: 'peso mexicano',
    short: 'MXN',
    full: 'MX$ peso mexicano',
    symbol: {
      html: 'MX&#36;',
      unicode: 'MX$'
    }
  }
}
