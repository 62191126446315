import { cloneDeep, isEqual } from 'lodash'

import { REGEX_IPV4_WITH_MASK_OR_PATTERN } from '@sigma-legacy-libs/essentials/lib/constants/regexp'
import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default function(options) {
  return {
    name: 'SMPPIPWhitelist',

    mixins: [ proxy({ type: 'array' }) ],

    props: {
      loading: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        validIps: [],
        invalidIps: [],

        showDialog: false
      }
    },

    computed: {
      disabled() {
        if (this.loading.update) {
          return true
        }

        return isEqual(this.proxy, this._inputFilter(this.value))
      }
    },

    methods: {
      _inputFilter(value) {
        return value.join('\n')
      },

      watchProxyHandler() {
        return
      },

      checkIpList() {
        this.validIps = []
        this.invalidIps = []
        const proxy = cloneDeep(this.proxy)
        if (proxy.length && typeof proxy === 'string') {
          const list = proxy.split(/[\n,]+/).map(item => item.trim())
          for (const ip of list) {
            if (REGEX_IPV4_WITH_MASK_OR_PATTERN.test(ip)) {
              this.validIps.push(ip)
            } else {
              this.invalidIps.push(ip)
            }
          }

          this.proxy = this.validIps.join('\n')

          if (this.invalidIps.length) {
            this.showDialog = true
          } else {
            this.eventHandler(this.validIps)
          }
        }
      },

      eventHandler(value) {
        if (!this.disabled) {
          this.$emit('input', value)
        }
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
