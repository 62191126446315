import csvToJSON from 'csvtojson'

import proxy from '@sigma-legacy-libs/g-proxy'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

export default {
  name: 'ImportCSVButton',

  mixins: [ proxy() ],

  props: {
    parser: {
      type: Function,
      default: () => undefined
    },

    tooltip: String
  },

  methods: {
    import(file) {
      try {
        if (!file) {
          file = document.getElementById('csvFile').files
        }

        const reader = new FileReader()
        reader.onload = event => {
          csvToJSON({
            output: 'json',
            trim: true,
            delimiter: ';'
          })
            .fromString(event.target.result)
            .then(result => this.parser(result))
        }
        reader.readAsText(file[0])
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    }
  },

  render
}
