import { cloneDeep } from 'lodash'

import { buttonModes, colors, icons, services, sizes } from '@/utils'

import button from '@/components/button'
import rulesConditionsDialog from '@/components/misc/rules/commons/dialog'
import rulesConditionsRow from '@/components/misc/rules/commons/item'
import { className, ruleTags, ruleTypes } from '@/components/misc/rules/utils'

function renderDialog(h) {
  if (this.proxy[this.index]) {
    return h(
      rulesConditionsDialog,
      {
        props: {
          show: this.showDialog,
          value: cloneDeep(this.proxy[this.index].value),
          ruleType: ruleTypes.conditions,
          tag: this.tag,
          tagEdit: true,
          tagDisabled: [ ruleTags.type, ruleTags.group ].concat(this.proxy.map(({ tag }) => tag)),
          readonly: this.readonly
        },
        on: {
          show: event => {
            this.showDialog = event
          },
          input: event => {
            this.proxy[this.index].value = event
          },
          tag: event => {
            this.proxy[this.index].tag = event
            this.tag = event
          }
        }
      }
    )
  }
}

function renderRemoveConditionButton(h, index) {
  if (!this.readonly) {
    return h(
      button,
      {
        props: {
          icon: icons.remove,
          mode: buttonModes.flat,
          color: colors.error
        },
        on: {
          click: () => {
            this.remove(index)
          }
        }
      }
    )
  }
}
function renderCondition(h, { tag, value }, index) {
  return h(
    rulesConditionsRow,
    {
      props: {
        tag,
        value,
        ruleType: ruleTypes.conditions,
        type: this.type,
        readonly: this.readonly,
        clearable: false
      },
      on: {
        dialog: event => {
          this.tag = tag
          this.index = index
          this.showDialog = event
        }
      }
    }
  )
}
function renderItem(h, item, index) {
  return h(
    'div',
    {
      class: {
        [`${services.tariffs}-rule__conditions-item`]: true,
        [`${services.tariffs}-rule__conditions-item--readonly`]: this.readonly
      }
    },
    [
      renderRemoveConditionButton.call(this, h, index),
      renderCondition.call(this, h, item, index)
    ]
  )
}
function renderItems(h) {
  return h(
    'div',
    {
      class: `${services.tariffs}-rule__conditions-items`
    },
    [
      this.proxy.map((item, index) => {
        return renderItem.call(this, h, item, index)
      })
    ]
  )
}

function renderRemoveGroupButton(h) {
  if (!this.readonly) {
    return h(
      button,
      {
        props: {
          label: this.getTranslate(`${className}.buttons.remove.group`),
          size: sizes.small,
          mode: buttonModes.flat,
          color: colors.error
        },
        on: {
          click: () => {
            this.$emit('remove', true)
          }
        }
      }
    )
  }
}
function renderAddRuleButton(h) {
  if (!this.readonly) {
    return h(
      button,
      {
        props: {
          label: this.getTranslate(`${className}.buttons.add.condition`),
          size: sizes.small,
          mode: buttonModes.flat,
          color: colors.success
        },
        on: {
          click: () => {
            this.add()
          }
        }
      }
    )
  }
}
function renderFooter(h) {
  if (!this.readonly) {
    return h(
      'div',
      {
        class: `${services.tariffs}-rule__conditions-footer`
      },
      [
        renderRemoveGroupButton.call(this, h),
        renderAddRuleButton.call(this, h)
      ]
    )
  }
}

export default function(h) {
  if (Array.isArray(this.proxy) && this.proxy.length) {
    return h(
      'div',
      {
        class: `${services.tariffs}-rule__conditions`
      },
      [
        renderItems.call(this, h),
        renderFooter.call(this, h),
        renderDialog.call(this, h)
      ]
    )
  }
}
