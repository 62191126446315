const fields = {
  title: 'Título',
  owner: 'Propietario',
  requisite: 'Requisito',
  company: 'Compañía',
  createdAt: 'Creado el',
  updatedAt: 'Actualizado el'
}

export default {
  title: 'Contratos',

  titles: {
    create: 'Crear contrato',
    upload: 'Subir contrato existente',
    confirm: {
      remove: 'Confirmar eliminación'
    }
  },

  headers: {
    ...fields
  },

  labels: {
    ...fields
  },

  buttons: {
    attach: 'Adjuntar archivo'
  },

  actions: {
    edit: 'Editar',
    download: 'Descargar PDF',
    remove: 'Eliminar'
  },

  contents: {
    confirm: {
      remove: 'Confirmar eliminación del contrato'
    }
  },

  tooltips: {
    edit: 'Editar archivo'
  },

  snackbars: {
    created: 'Contrato creado con éxito.',
    updated: 'Contrato actualizado con éxito.',
    removed: 'Contrato eliminado con éxito.'
  }
}
