import { buttonModes, colors, components, icons, services } from '@/utils'

import button from '@/components/button'
import form from '@/components/services/documentTemplates/generate/form'

function renderForm(h) {
  return h(
    form,
    {
      props: {
        value: this.payload,
        loading: this.loading,
        OwnerId: this.OwnerId,
        ResellerId: this.ResellerId
      },
      on: {
        input: event => {
          this.payload = event
        },
        type: event => {
          this.type = event
        },
        validation: event => {
          this.formValidation = event
        }
      }
    }
  )
}
function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat,
            disabled: this.loading
          },
          on: {
            click: () => {
              this.showDialog = false
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.download'),
            color: colors.primary,
            loading: this.loading,
            disabled: this.loading || !this.formValidation
          },
          on: {
            click: () => {
              this.generate()
            }
          }
        }
      )
    ]
  )
}
function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        value: this.showDialog,
        title: this.getTranslate(`${services.documentTemplates}.titles.generate`),
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 400 : undefined
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      renderForm.call(this, h),
      renderDialogFooter.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    button,
    {
      props: {
        icon: icons.description,
        mode: buttonModes.flat,
        tooltip: this.getTranslate(`${services.documentTemplates}.buttons.download`)
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      },
      scopedSlots: {
        dialog: () => renderDialog.call(this, h)
      }
    }
  )
}
