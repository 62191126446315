import { cloneDeep, get, isEqual } from 'lodash'

import { checkHostnames } from '@sigma-frontend-libs/commons'
import { EMPTY_SENDINGS, SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'
import proxy from '@sigma-legacy-libs/g-proxy'

import { generateServices, hostnames, services } from '@/utils'

import render from './render'

const testPayload = {
  [SENDING_TYPES.sms]: {
    sender: 'B-Media',
    text: 'Это тестовое сообщение.\nИзменённый текст будет отправлен на модерацию.'
  },
  [SENDING_TYPES.vk]: {
    sender: 'sigmamessaging',
    text: 'Тестовое сервисное сообщение ВКонтакте от компании SigmaSMS'
  },
  [SENDING_TYPES.viber]: {
    sender: 'MediaGorod',
    text: 'Это тестовое сообщение.\nИзменённый текст будет отправлен на модерацию.'
  },
  [SENDING_TYPES.voice]: {
    sender: '74956665610',
    text: '5744',
    tts: 'yandex:filipp'
  },
  [SENDING_TYPES.whatsapp]: {
    sender: '123456',
    text: 'Добрый день, ваш запрос принят. В ближайшее время свяжемся в вами.'
  }
}

export default {
  name: 'SendingsCreateForm',

  mixins: [
    proxy({ type: 'object' }),
    generateServices({
      name: services.sendernames,

      find: false,
      get: false,
      remove: false,
      update: false,
      create: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      }
    })
  ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    types: {
      type: Array,
      default: () => []
    },
    tags: {
      type: Array,
      default: () => []
    },
    customMenu: {
      type: Array,
      default: () => []
    },

    disabled: Boolean,
    readonly: Boolean,

    isPattern: Boolean,
    isTemplate: Boolean,
    isFallback: Boolean,
    isNewMessage: Boolean
  },

  data() {
    return {
      showCreateNewSenderName: false,

      isGeneratedFile: true
    }
  },

  computed: {
    checkTestRoute() {
      let result = false
      if (this.isNewMessage && !this.isPattern && !this.isTemplate) {
        switch (this.proxy.type) {
          case SENDING_TYPES.sms: {
            if (this.checkSomeId('RoutingGroups', '9ba7f6e8-7b0b-462d-ba1d-033455c383b0')) {
              result = true
            }
            break
          }
          case SENDING_TYPES.vk: {
            if (this.checkSomeId('Patterns', 'b34498d6-79e5-4c7e-913a-4658390dd5e9')) {
              result = true
            }
            break
          }
          case SENDING_TYPES.viber: {
            if (this.checkSomeId('Patterns', '95d61562-0b92-45ad-a92c-6e9da7dc69a1')) {
              result = true
            }
            break
          }
          case SENDING_TYPES.voice: {
            if (this.checkSomeId('Patterns', '6e49597a-fe4a-4a63-8c85-43cbe9bd10b4')) {
              result = true
            }
            break
          }
          case SENDING_TYPES.whatsapp: {
            if (this.checkSomeId('Patterns', '8efc388b-beaf-429e-8c37-300031ba1c63')) {
              result = true
            }
            break
          }
        }
      }

      return result
    },

    validationButtonUrl() {
      if (this.proxy.payload.button.url && this.proxy.payload.button.url.length > 2048) {
        return this.getTranslate(`${services.sendings}.errors.button.url`)
      }
    },
    validationButtonText() {
      if (this.proxy.payload.button.text && this.proxy.payload.button.text.length > 30) {
        return this.getTranslate(`${services.sendings}.errors.button.text`)
      }
    },

    hasTypes() {
      return Array.isArray(this.types) && this.types.length > 0
    },

    maxButtons() {
      switch (this.proxy.type) {
        case SENDING_TYPES.vk: return 10 // https://gitlab-y7r4iuqy1m.sigmasms.ru/sigma/issues/-/issues/1545
        case SENDING_TYPES.whatsapp: return 3 // Изначально правило установленное больше 3х лет назад на текущий момент
      }
    }
  },

  watch: {
    proxy: {
      handler() {
        this.setType()
      },
      deep: true
    },
    'proxy.type'() {
      if (!this.isTemplate) {
        this.proxy.payload = cloneDeep(EMPTY_SENDINGS.payload)
      }
      const testSender = get(testPayload, `${this.proxy.type}.sender`)
      const testText = get(testPayload, `${this.proxy.type}.text`)
      const testTTS = get(testPayload, `${this.proxy.type}.tts`)
      if (this.checkTestRoute) {
        if (checkHostnames(hostnames)) {
          this.proxy.payload.sender = testSender
          this.proxy.payload.text = testText

          if (testTTS) {
            this.proxy.payload.tts = testTTS
          }
        }
      } else {
        if (isEqual(this.proxy.payload.text, testText)) {
          this.proxy.payload.text = undefined
        }
        if (isEqual(this.proxy.payload.tts, testTTS)) {
          this.proxy.payload.tts = undefined
        }
      }
    }
  },

  async mounted() {
    this.setType()
  },

  methods: {
    checkSomeId(association, value) {
      return Array.isArray(this.account[association]) && this.account[association].length > 0 && this.account[association].some(({ id }) => id === value)
    },

    setType() {
      if (!this.proxy.type && this.hasTypes) {
        this.proxy.type = this.types[0]
      }
    }
  },

  render
}
