import { getPrice } from '@sigma-frontend-libs/commons'

import { colors, icons, services } from '@/utils'

import sticker from '@/components/misc/sticker'
import { amountColors, amountIcons } from '@/components/services/billings/utils'

export default function(h) {
  if (this.$value.amount) {
    return h(
      sticker,
      {
        props: {
          value: getPrice(this.$value.amount),
          icon: this.$value.source ? amountIcons[this.$value.source] : icons.wallet,
          color: this.$value.source ? amountColors[this.$value.source] : colors.primary,
          label: this.getTranslate(`${services.payments}.labels.amount`)
        }
      }
    )
  }
}
