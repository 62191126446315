export default {
  RUB: {
    title: 'ruble',
    short: 'rub.',
    full: '₽ ruble',
    symbol: {
      html: '&#8381;',
      unicode: '₽'
    }
  },

  USD: {
    title: 'dollar',
    short: 'USD',
    full: '$ dollar',
    symbol: {
      html: '&#36;',
      unicode: '$'
    }
  },

  EUR: {
    title: 'euro',
    short: 'EUR',
    full: '€ euro',
    symbol: {
      html: '&#8364;',
      unicode: '€'
    }
  },

  MXN: {
    title: 'mexican peso',
    short: 'MXN',
    full: 'MX$ mexican peso',
    symbol: {
      html: 'MX&#36;',
      unicode: 'MX$'
    }
  }
}
