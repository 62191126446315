import { labels, types } from './sendings'

export default {
  title: 'Reenvíos',

  types,
  labels,

  errors: {
    onTimeout: {
      timeout: 'No puede ser menor que 10'
    }
  }
}
