import { components, services } from '@/utils'

import { countsColors, countsTypes } from '@/components/services/tasks/utils'

function renderCount(h, type) {
  return h(
    'div',
    {
      class: [
        `${services.sendings}-${services.tasks}__counts-item`,
        `${services.sendings}-${services.tasks}__counts-item--${countsColors[type]}`
      ],
      directives: [
        {
          name: components.tooltip,
          options: { value: this.$value.tooltips[type] }
        }
      ]
    },
    [ this.$value.count[type] ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.sendings}-${services.tasks}__counts`
    },
    [ countsTypes.map(type => renderCount.call(this, h, type)) ]
  )
}
