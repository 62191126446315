import Vue from 'vue'

import { cloneDeep } from 'lodash'

import { components } from '@/utils'

import dialog from '@/components/misc/rules/commons/dialog'
import item from '@/components/misc/rules/commons/item'
import { className, ruleClasses } from '@/components/misc/rules/utils'

function renderDialog(h) {
  return h(
    dialog,
    {
      props: {
        value: cloneDeep(this.proxy[this.tag]),
        show: this.showDialog,
        tag: this.tag,
        ruleType: this.ruleType
      },
      on: {
        show: event => {
          this.showDialog = event
        },
        input: event => {
          Vue.set(this.proxy, this.tag, event)
        },
        tag: event => {
          this.tag = event
        }
      }
    }
  )
}

function renderItem(h, tag) {
  return h(
    item,
    {
      props: {
        tag,
        value: this.proxy[tag],
        ruleType: this.ruleType
      },
      on: {
        dialog: event => {
          this.tag = tag
          this.showDialog = event
        },
        clear: () => {
          Vue.delete(this.proxy, tag)
        }
      }
    }
  )
}

export default function(h) {
  return h(
    components.card,
    {
      class: `${className}-card`,
      props: {
        title: this.getTranslate(`${className}.titles.${this.ruleType}`),
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [
      ruleClasses[this.ruleType].tagsOnly.filter(this._tagFilter).map(tag => renderItem.call(this, h, tag)),
      renderDialog.call(this, h)
    ]
  )
}
