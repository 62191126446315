import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'CreateDialog',

  mixins: [ proxy() ],

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    OwnerId: String,

    loading: Boolean
  },

  data() {
    return {
      formValidation: false
    }
  },

  render
}
