import { components, inputModes, services } from '@/utils'

import { indexRegExp } from '@/components/services/requisites/utils'

function renderFieldMatches(h) {
  if (this.matches) {
    return h(
      components.switch,
      {
        props: {
          value: this.addressMatches,
          label: this.getTranslate(`${services.requisites}.labels.addressMatches`),
          outline: true
        },
        on: {
          input: event => {
            this.addressMatches = event
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.requisites}-form__column`
    },
    [
      renderFieldMatches.call(this, h),

      h(
        'div',
        {
          class: `${services.requisites}-form__row`
        },
        [
          h(
            'div',
            {
              class: `${services.requisites}-form__column`
            },
            [
              h(
                components['text-field'],
                {
                  props: {
                    value: this.proxy.index,
                    label: this.getTranslate(`${services.requisites}.labels.index`),
                    error: this.proxy.index && !indexRegExp.test(this.proxy.index) ? this.getTranslate(`${services.requisites}.errors.index`) : undefined,
                    mode: inputModes['line-label'],
                    trim: true,
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    required: true,
                    disabled: this.addressMatches,
                    validateOnBlur: true,
                    mask: /^\d{1,6}$/
                  },
                  on: {
                    input: event => {
                      this.proxy.index = event
                    }
                  }
                }
              ),
              h(
                components['text-field'],
                {
                  props: {
                    value: this.proxy.country,
                    label: this.getTranslate(`${services.requisites}.labels.country`),
                    disabled: this.addressMatches,
                    mode: inputModes['line-label'],
                    trim: true,
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    required: true
                  },
                  on: {
                    input: event => {
                      this.proxy.country = event
                    }
                  }
                }
              ),
              h(
                components['text-field'],
                {
                  props: {
                    value: this.proxy.city,
                    label: this.getTranslate(`${services.requisites}.labels.city`),
                    disabled: this.addressMatches,
                    mode: inputModes['line-label'],
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    required: true
                  },
                  on: {
                    input: event => {
                      this.proxy.city = event
                    }
                  }
                }
              )
            ]
          ),

          h(
            'div',
            {
              class: `${services.requisites}-form__column`
            },
            [
              h(
                components['text-field'],
                {
                  props: {
                    value: this.proxy.address,
                    label: this.getTranslate(`${services.requisites}.labels.address`),
                    disabled: this.addressMatches,
                    mode: inputModes['line-label'],
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false
                  },
                  on: {
                    input: event => {
                      this.proxy.address = event
                    }
                  }
                }
              ),
              h(
                components['text-field'],
                {
                  props: {
                    value: this.proxy.phone,
                    label: this.getTranslate(`${services.requisites}.labels.phone`),
                    disabled: this.addressMatches,
                    type: 'tel',
                    mode: inputModes['line-label'],
                    validateOnBlur: true,
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    mask: /^[\d\s()+-]+$/
                  },
                  on: {
                    input: event => {
                      this.proxy.phone = event
                    }
                  }
                }
              ),
              h(
                components['text-field'],
                {
                  props: {
                    value: this.proxy.phoneAdd,
                    label: this.getTranslate(`${services.requisites}.labels.phoneAdd`),
                    disabled: this.addressMatches,
                    mode: inputModes['line-label'],
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    mask: /^[\d\s()+-]+$/
                  },
                  on: {
                    input: event => {
                      this.proxy.phoneAdd = event
                    }
                  }
                }
              )
            ]
          )
        ]
      )
    ]
  )
}
