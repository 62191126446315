import { isEqual } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Buttons',

  mixins: [ proxy({ type: 'array' }) ],

  data() {
    return {
      defaultButton: {
        text: undefined,
        action: undefined
      }
    }
  },

  methods: {
    _outputFilter(data) {
      return data.filter(item => item.text && item.action)
    },

    transmitValue() {
      const proxy = this._outputFilter(this.proxy)
      if (!isEqual(proxy, this.value)) {
        this.$emit('input', proxy)
      }
    }
  },

  render
}
