export default {
  apisender: 'WAPI',
  billings: 'Operaciones financieras',
  companies: 'Compañías',
  contacts: 'Contactos',
  contactLists: 'Listas de contactos',
  contracts: 'Contratos',
  credentials: 'Credenciales',
  dadata: 'DaData',
  dictionaries: 'Diccionarios',
  documentTemplates: 'Plantillas de documentos',
  eventsHistory: 'Historial de eventos',
  invoices: 'Facturas',
  keywords: 'Palabras clave',
  keywordLists: 'Listas de palabras clave',
  login: 'Autorización',
  messageRules: 'Reglas de mensajes',
  messageRuleGroups: 'Grupos de reglas de mensajes',
  notifications: 'Notificaciones',
  payments: 'Pagos',
  patterns: 'Plantillas para moderación',
  providers: 'Proveedores',
  providerGroups: 'Grupos de proveedores',
  providersPrices: 'Precios de proveedores',
  recurrentPayments: 'Pagos recurrentes',
  registration: 'Registro',
  reports: 'Informes',
  requisites: 'Requisitos',
  sendernames: 'Nombres de remitentes',
  routingGroups: 'Grupos de reglas de enrutamiento',
  routingRules: 'Reglas de enrutamiento',
  sendings: 'Mensajes salientes',
  sendingsIncoming: 'Mensajes entrantes',
  servers: 'Servidores',
  settingsUser: 'Configuración de usuario',
  storage: 'Archivos',
  tariffs: 'Tarifas',
  tasks: 'Tareas',
  telemetry: 'Telemetría',
  templates: 'Plantillas',
  tokens: 'Tokens',
  users: 'Usuarios'
}
