const presets = {
  labels: {
    queue: 'Cola',
    speed: 'Velocidad',
    count: 'Total en 5 minutos',
    deliveryRate: 'Tasa de entrega'
  },
  hints: {
    queue: 'Número de mensajes en la cola del proveedor',
    speed: 'Velocidad a la que los mensajes se envían al proveedor en el último minuto',
    count: 'Número de mensajes enviados al proveedor en los últimos 5 minutos',
    deliveryRate: 'Calculado en base a los datos del número de mensajes enviados y entregados en los últimos 5 minutos'
  },
  measure: {
    queue: 'mensaje',
    speed: 'mensaje/seg',
    count: 'mensaje',
    deliveryRate: '%'
  }
}

export default {
  title: 'Proveedores',

  provider: 'Proveedor',
  providers: 'Proveedores',
  one: 'Proveedor',
  many: 'Proveedores',

  titles: {
    create: 'Crear proveedor',
    update: 'Actualizar proveedor',
    remove: 'Eliminar proveedor',
    properties: {
      add: 'Agregar propiedad',
      edit: 'Editar propiedad',
      notFound: 'Propiedades no especificadas'
    },
    replacements: {
      add: 'Agregar reemplazo de contenido',
      edit: 'Editar reemplazo de contenido',
      notFound: 'Reemplazo de contenido no especificado'
    }
  },

  labels: {
    title: 'Título',
    type: 'Tipo',
    ownProcessor: 'Procesador propio',

    settings: {
      protocol: 'Protocolo',
      processor: 'Procesador',
      credentials: {
        username: 'Nombre de usuario',
        password: 'Contraseña'
      },
      server: {
        host: 'Host',
        port: 'Puerto'
      },
      limits: {
        bulk: 'Número de mensajes por solicitud',
        concurrency: 'Número de solicitudes concurrentes',
        rpm: 'Número de mensajes por minuto'
      }
    },

    search: 'Buscar',
    server: 'Servidor',
    credentials: 'Credenciales',
    comment: 'Comentario',
    version: 'Versión',
    text: 'Texto',
    sender: 'Nombre',
    sendername: 'Nombre del remitente',
    isActive: 'Activo',

    d360: {
      owner: 'Propietario',
      token: 'Token'
    }
  },

  contents: {
    confirm: {
      remove: 'Confirme la eliminación del proveedor.'
    }
  },

  dialogs: {
    new: 'Nuevo proveedor'
  },

  tabs: {
    send: 'Envíos',
    check: 'Verificación',
    ping: 'Ping'
  },

  subheaders: {
    general: 'General',
    settings: 'Configuraciones',
    credentials: 'Credenciales',
    server: 'Servidor',
    options: 'Opciones',
    limits: 'Límites'
  },

  chart: { labels: presets.labels },

  states: {
    inactive: 'Inactivo',
    connecting: 'Conectando',
    authorizing: 'Autorizando',
    disconnecting: 'Desconectando',
    active: 'Activo',
    stopped: 'Detenido',
    'half-open': 'Temporalmente activo',
    'error-code': 'Error de código',
    'error-connection': 'Error de conexión'
  },

  buttons: {
    edit: 'Editar',
    remove: 'Eliminar',
    add: {
      property: 'Propiedad',
      replacement: 'Reemplazo'
    }
  },

  tooltips: {
    liveRefresh: 'Activar o desactivar la actualización en vivo de la analítica.',
    isActive: 'Activar o desactivar el proveedor.',
    incoming: 'Entrantes',
    outgoing: 'Salientes',
    filter: {
      isActive: 'Mostrar solo proveedores activos o inactivos'
    },
    logs: {
      online: 'Solicitar registros del servidor cada 15 segundos'
    },
    presets: {
      queue: `${presets.labels.queue}: {count} ${presets.measure.queue}\n\n${presets.hints.queue}`,
      speed: `${presets.labels.speed}: {count} ${presets.measure.speed}\n\n${presets.hints.speed}`,
      count: `${presets.labels.count}: {count} ${presets.measure.count}\n\n${presets.hints.count}`,
      deliveryRate: `${presets.labels.deliveryRate}: {count} ${presets.measure.deliveryRate}\n\n${presets.hints.deliveryRate}`
    },
    buttons: {
      isActive: 'Cambiar la actividad del proveedor'
    },
    favorites: 'Hacer al proveedor favorito'
  },

  measure: {
    presets: presets.measure
  },

  hints: {
    notFound: 'No se encontraron proveedores.',
    other: 'Otros valores',
    rules: 'Condiciones',
    replacement: 'Datos para el reemplazo de contenido',

    replacements: {
      hint: 'Reemplazo de contenido',

      notFound: 'Agregue un reemplazo de contenido presionando el botón de abajo.',

      rules: 'Condiciones',
      replacement: 'Reemplazo'
    },

    properties: {
      hint: 'Propiedades',

      notFound: 'Agregue propiedades presionando el botón de abajo.',

      defaultWebhookId: 'ID para webhook por defecto',
      customWebhookId: 'ID personalizado para webhook',
      messagePartIdPrefix: 'Prefijo personalizado para ID de parte del mensaje',

      keepAlive: 'Mantener la conexión abierta',

      noSeenStatus: '[FakeHTTP] Usar estado visto en el proveedor',
      failurePercent: '[FakeHTTP] Porcentaje de estados fallidos',

      chatPultIntegration: '[ChatPult] Se pasa en el cuerpo de la solicitud al crear un mensaje',

      tts: '[AWSAST | Telerobot] Tipo de sintetizador para transformar texto en voz',
      trunk: '[AWSAST] 531544, quantek, trunk_84992774787',
      prefix: '[AWSAST] quantek - 392500',
      trycount: '[AWSAST] Valor por defecto 0, se hace un intento, si se especifica 1, la API lo tomará como un intento adicional además del estándar. Es decir, en total hay 2 intentos de llamada.',
      trydelay: '[AWSAST] Retraso entre intentos de llamada en minutos',
      length: '[AWSAST] Número de caracteres aleatorios desde el final',
      record_timeout: '[AWSAST] Duración de la grabación de la respuesta, 0 si se necesita desactivar la grabación. Undefined - valor por defecto de asterisk (45s)',
      wait_timeout: '[AWSAST] Retraso antes de reproducir el archivo',
      dtmf_timeout: '[AWSAST] Cuánto tiempo esperar la respuesta del cliente en segundos',

      menu: '[Telerobot] 0 / 1 - Activa la grabación de las pulsaciones de botones por parte del abonado',
      asr: '[Telerobot] 0 / 1 - Reconocimiento de la respuesta de voz del usuario en texto',

      resendSms: 'Reenviar como SMS en caso de no entrega',
      priority: '[ShkolainfoPush] Prioridad',
      validityPeriodSec: 'Tiempo de vida del mensaje',

      rus: '[SmartDelivery] Por defecto 1',
      traffic_type: '[SmartDelivery] Tipo de tráfico, por defecto \'promotion\'',

      proxy: 'Configuraciones de proxy',

      accountId: '[Tyntec] ID de cuenta en Tyntec',

      routes: '[VKMailru] vk,ok / vk / ok',
      delivery_policy: '[VKMailru] Configuraciones de entrega. mobile_device_required o nada',
      ttl: '[VKMailru] Tiempo de vida del mensaje',

      dlr: '[Messaggio] 0 / 1 - Devolver estado del mensaje (por defecto 1)',
      dlr_timeout: '[Messaggio]',
      expiry_txt: '[Messaggio]',

      system_type: '[SMPP] Se pasa en el comando bind_',
      preferredDataCodings: '[SMPP] Establece codificaciones preferidas (\'1:3,8\')',
      registeredDelivery: '[SMPP] Establece el método de recepción del estado del mensaje (query_sm/deliver_sm)',
      interface_version: '[SMPP] Establece el parámetro interface_version al conectarse por SMPP (3,5)',
      tls: '[SMPP] Bandera para conectarse a SMPP a través de TLS',
      enquireLinkTimeout: '[SMPP] Tiempo de espera para ejecutar el comando enquireLink',

      source_addr_ton: '[XSMPP] Se pasa en el comando submit_sm',
      source_addr_npi: '[XSMPP] Se pasa en el comando submit_sm',
      dest_addr_ton: '[XSMPP] Se pasa en el comando submit_sm',
      dest_addr_npi: '[XSMPP] Se pasa en el comando submit_sm',

      senderName: 'Nombre del remitente para el reemplazo',

      _testType: 'Tipo de envío para la prueba',
      _testSendername: 'Nombre del remitente para la prueba',
      _testRecipient: 'Destinatario para la prueba',
      _testPayloadText: 'Texto para la prueba',

      rateLimit_concurrency: 'Número de solicitudes concurrentes',
      rateLimit_messagesPerSecond: 'Número de mensajes por segundo'
    },

    presets: presets.hints
  },

  snackbars: {
    created: 'Proveedor creado con éxito.',
    updated: 'Proveedor actualizado con éxito.',
    removed: 'Proveedor eliminado con éxito.'
  },

  warnings: {
    server: 'Para seleccionar las propiedades del procesador, primero seleccione el servidor.'
  }
}
