import { getPrice } from '@sigma-frontend-libs/commons'

import { colors, icons } from '@/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: getPrice(this.balance, { currency: this.$currency }),
        label: getPrice(this.balance + this.overdraft, { currency: this.$currency }),
        icon: icons.wallet,
        color: colors.grey,
        minWidth: 120,
        button: {
          icon: icons.refresh,
          color: colors.grey,
          loading: this.balanceLoading,
          disabled: this.balanceLoading,
          callback: () => this.getBalance()
        }
      }
    }
  )
}
