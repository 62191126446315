export default {
  title: 'Inicio de sesión',

  labels: {
    username: 'Nombre de usuario',
    password: 'Contraseña'
  },

  buttons: {
    logout: 'Cerrar sesión',
    signin: 'Iniciar sesión',
    signup: 'Registro'
  },

  errors: {
    tooManyRequests: 'Se ha excedido el límite de intentos ({limit}) para ingresar la combinación correcta de nombre de usuario/contraseña.'
  },

  warnings: {
    remainingAttempts: 'Le quedan {remaining} intentos de {limit} para ingresar la combinación correcta de nombre de usuario/contraseña.'
  }
}
