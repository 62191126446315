import services from './services'
import settingsUser from './settingsUser'

const settingsUserLabels = Object.assign(
  settingsUser.labels,
  {
    sendings: {
      ...settingsUser.labels.sendings,
      sendernamesCheck: 'Procesamiento de mensajes: Nombres de remitentes',
      patternsCheck: 'Procesamiento de mensajes: Patrones'
    }
  }
)

export default {
  title: 'Historial de eventos',

  labels: {
    eventsHistory: 'Historial de cambios',
    type: 'Tipo',
    userId: 'Usuario',
    entityId: 'ID de la entidad',
    service: 'Servicio',
    oldValues: 'Antes',
    newValues: 'Después',
    author: 'Autor',
    changesType: 'Tipo',
    created: 'Creado',
    compareId: 'ID de la entidad',
    permissionsUsers: 'Permiso del usuario'
  },

  updatedValues: {
    ...services,
    ...settingsUserLabels,

    value: 'Valor',

    data: 'Datos',
    isActive: 'Activar/Desactivar',
    state: 'Estado',
    startsAt: 'Inicio',
    updatedAt: 'Actualizado en',
    title: 'Título',
    serverId: 'Servidor',
    credentialsId: 'Credenciales',
    comment: 'Comentario',
    properties: 'Propiedades',
    replacements: 'Sustitución de contenido',
    createdAt: 'Creado en',
    deletedAt: 'Eliminado en',

    type: 'Tipo',
    provider: 'Proveedor',
    name: 'Nombre',

    rules: 'Reglas',

    description: 'Descripción',

    source: 'Fuente',
    ownerId: 'Propietario',

    resellerId: 'Revendedor',
    password: 'Contraseña',
    referralCode: 'Código de referencia',
    sudo: 'Sudo',
    username: 'Nombre de usuario',

    amount: 'Cantidad',
    status: 'Estado',
    TariffId: 'Tarifa',

    listId: 'ListId',
    date: 'Fecha',
    email: 'Correo electrónico',
    firstName: 'Nombre',
    gender: 'Género',
    id: 'Id',
    lastName: 'Apellido',
    middleName: 'Segundo nombre',
    phone: 'Teléfono',

    fallbacks: 'Reenvíos',
    payload: 'Información',
    recipient: 'Destinatario',

    meta: 'Meta',
    moderation: 'Moderación',

    conditions: 'Condiciones',
    trafficType: 'Tipo de tráfico',

    records: 'Credenciales',

    signer: 'Firmante',
    contact: 'Contacto',
    data: 'Datos',
    legal: 'Persona jurídica',
    bank: 'Banco',
    tax: 'Impuestos',
    address: 'Dirección',
    legalAddress: 'Dirección legal'
  },

  types: {
    created: 'Creado',
    updated: 'Actualizado',
    removed: 'Eliminado'
  },

  services: {
    ...services,

    billingsUsers: 'Operación financiera',
    settingsUserUsers: 'Configuraciones del usuario',
    contactListsContacts: 'Contacto',
    keywordListsKeywords: 'Palabra clave',
    permissionsUsers: 'Permisos del usuario',
    tokensUsers: 'Token'
  },

  tooltips: {
    historyButton: 'Ver historial de cambios'
  }
}
