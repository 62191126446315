import Vue from 'vue'

import { Manager } from 'socket.io-client'

import { projectName } from '@/utils'

export function socketsRenew() {
  Vue.$socket && Vue.$socket.disconnect()

  const hostname = window.location.hostname
  const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'
  const url = `${protocol}://${hostname}`
  const manager = new Manager(url, {
    protocols: [ 'websocket' ],
    transports: [ 'websocket' ],
    reconnection: false,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 5,
    query: { token: window.localStorage.getItem(`${projectName}:token`) },
    autoConnect: false
  })

  Vue.$socket = manager.socket('/')
  Vue.$socket && Vue.$socket.connect()
}

export const socketDisconnect = () => {
  Vue.$socket && Vue.$socket.disconnect()
}
