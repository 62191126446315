import { getString } from '@sigma-frontend-libs/commons'

import { colors } from '@/utils'

import render from './render'

export default {
  name: 'Copy',

  props: {
    value: [ String, Number, Boolean ]
  },

  computed: {
    $_value() {
      return getString(this.value)
    }
  },

  methods: {
    clickHandler() {
      navigator.clipboard.writeText(this.$_value)
      this.addSnackbar({
        text: this.getTranslate('misc.copied'),
        type: colors.success
      })
    }
  },

  render
}
