import VirtualList from 'vue-virtual-scroll-list'

import { buttonModes, colors, inputModes, services, sizes, states } from '@/utils'

import button from '@/components/button'
import HeadTitle from '@/components/misc/HeadTitle'
import row from '@/components/misc/virtualScroll/row'

function renderSearch(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.restData[services.keywords].find.filter.$search,
        label: this.getTranslate('commons.labels.search'),
        loading: this.restData[services.keywords].find.state === states.loading,
        mode: inputModes['line-label'],
        details: false,
        dense: true,
        rounded: true,
        clearable: true
      },
      on: {
        input: event => {
          if (!event) {
            this.$route.query.keyword = undefined
          }
          this.restData[services.keywords].find.filter.$search = event
        }
      }
    }
  )
}
function renderCreateButton(h) {
  return h(
    button,
    {
      props: {
        icon: 'add',
        color: colors.secondary,
        mode: buttonModes.flat
      },
      on: {
        click: () => {
          this.keywordPayload.ListId = this.$route.params.id
          this.dialogType = 'create'
          this.showDialog = true
        }
      }
    }
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.keywords}-list__header`
    },
    [
      renderSearch.call(this, h),
      renderCreateButton.call(this, h)
    ]
  )
}

function renderItemKeyword(h, item) {
  return h(
    'div',
    {
      class: `${services.keywords}-list__item-title`,
      on: {
        click: () => {
          this.keywordPayload = item
          this.dialogType = 'update'
          this.showDialog = true
        }
      }
    },
    [ item.regexp ]
  )
}
function renderItemActions(h, item) {
  return h(
    'div',
    {
      class: 'fjcfe facfe grid-gap--4 hover-child'
    },
    [
      h(
        button,
        {
          props: {
            icon: 'remove',
            size: sizes.small,
            color: colors.error,
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.keywordPayload = item
              this.dialogType = 'remove'
              this.showDialog = true
            }
          }
        }
      )
    ]
  )
}
function renderItem(h, item) {
  return h(
    'div',
    {
      class: `${services.keywords}-list__item hover-parent hover-parent--opacity`
    },
    [
      renderItemKeyword.call(this, h, item),
      renderItemActions.call(this, h, item)
    ]
  )
}
function renderItems(h) {
  return h(
    VirtualList,
    {
      class: `${services.keywords}-list`,
      props: {
        dataKey: 'id',
        dataSources: this.restData[services.keywords].find.data,
        dataComponent: row,
        itemScopedSlots: {
          item: source => renderItem.call(this, h, source)
        }
      },
      on: {
        tobottom: () => {
          const loadMore = this.restData[services.keywords].find.pagination.limit
            + this.restData[services.keywords].find.pagination.offset
            < this.restData[services.keywords].find.pagination.total
          if (this.restData[services.keywords].find.state === states.ready && loadMore) {
            this.restData[services.keywords].find.pagination.offset += this.restData[services.keywords].find.pagination.limit
          }
        }
      }
    }
  )
}
function renderContent(h) {
  if (this.restData[services.keywords].find.pagination.total) {
    return renderItems.call(this, h)
  } else {
    return h('g-empty')
  }
}

function renderDialogHeader(h) {
  return h(
    HeadTitle,
    {
      props: {
        value: this.getTranslate(`${services.keywords}.labels.${this.dialogType}`)
      },
      slot: 'header'
    }
  )
}
function renderDialogBodyByType(h) {
  switch (this.dialogType) {
    case 'update':
    case 'create': {
      return h(
        'g-text-field',
        {
          props: {
            value: this.keywordPayload.regexp,
            label: this.getTranslate(`${services.keywords}.labels.value`),
            mode: inputModes['line-label'],
            details: false,
            dense: true,
            rounded: true,
            autofocus: true,
            clearable: true
          },
          on: {
            input: event => {
              this.keywordPayload.regexp = event
            }
          }
        }
      )
    }
    case 'remove': {
      return this.getTranslate('commons.contents.confirm.remove')
    }
  }
}
function renderDialogBody(h) {
  return h(
    'div',
    {
      class: 'pa-3'
    },
    [ renderDialogBodyByType.call(this, h) ]
  )
}
function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'pa-2 faic fjcfe grid-gap--8',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.cancel'),
            disabled: this.loading,
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.showDialog = false
            }
          }
        }
      ),

      h(
        button,
        {
          props: {
            color: this.dialogType === 'remove' ? colors.error : colors.primary,
            label: this.getTranslate(`misc.buttons.${this.dialogType}`),
            loading: this.loading,
            disabled: this.loading
          },
          on: {
            click: () => {
              this.setKeyword()
              this.showDialog = false
            }
          }
        }
      )
    ]
  )
}
function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      renderDialogHeader.call(this, h),
      renderDialogBody.call(this, h),
      renderDialogFooter.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: services.keywords
    },
    [
      renderHeader.call(this, h),
      renderContent.call(this, h),
      renderDialog.call(this, h)
    ]
  )
}
