import Vue from 'vue'

import { cloneDeep, merge } from 'lodash'

import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'
import { calculateSmsStats, calculateVoiceStats } from '@sigma-legacy-libs/essentials/lib/utils/smpp'

import { getUnixSeconds, globalErrorHandler, globalErrorProcessor, locales, projectName, services } from '@/utils'

import { defaultPayload, emotionalTones, payloadKeys } from '@/components/services/message-payload-generator/utils'

import render from './render'

const timeoutKey = `${projectName}:${services['message-payload-generator']}:timeout`
const fieldParams = [
  {
    field: payloadKeys.smsSegments,
    min: 1,
    max: 10
  },
  {
    field: payloadKeys.otherChars,
    min: 1,
    max: 1000
  }
]

export default {
  name: 'PayloadGenerator',

  props: {
    sendingType: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      payload: {
        messageTheme: '',
        occasion: '',
        smsSegments: undefined,
        otherChars: undefined,
        additionalNotes1: '',
        rephraseText: '',
        addEmojis: true,
        endQuestion: '',
        emotionalTone: '',
        language: this.locale
      },

      channel: this.sendingType,

      text: undefined,
      displayedText: undefined,

      loading: false,
      showDialog: false,
      showAdditionalDialog: false,

      interval: undefined,
      timeout: undefined,
      animationFrameId: undefined
    }
  },

  watch: {
    locale(value) {
      this.payload.language = value
    },

    sendingType(value) {
      this.channelProcess(value)
    },

    timeout(value) {
      if (!value) {
        this.clearInterval()
      }
    }
  },

  computed: {
    selectItems() {
      return {
        [payloadKeys.emotionalTone]: Object.keys(emotionalTones).map(tone => {
          return {
            title: this.getTranslate(`${services['message-payload-generator']}.emotionalTones.${tone}`),
            value: this.getTranslate(`${services['message-payload-generator']}.emotionalTones.${tone}`)
          }
        }),

        [payloadKeys.language]: locales.map(value => {
          return {
            title: this.getTranslate(`misc.locales.${value}`),
            value
          }
        })
      }
    },

    errorsByField() {
      const result = {}
      for (const { field, min, max } of fieldParams) {
        if (this.payload[field] && (parseInt(this.payload[field]) < min || parseInt(this.payload[field]) > max)) {
          result[field] = this.getTranslate(`${services['message-payload-generator']}.hints.${field}`)
        }
      }

      return result
    },
    disabled() {
      if (!this.payload[payloadKeys.messageTheme]) {
        return true
      }

      if (!this.payload[payloadKeys.occasion]) {
        return true
      }

      for (const { field } of fieldParams) {
        if (this.errorsByField[field]) {
          return true
        }
      }

      if (this.timeout) {
        return true
      }

      if (this.loading) {
        return true
      }

      return false
    },

    buttonLabel() {
      if (this.timeout) {
        return this.timeout
      } else {
        return this.getTranslate('misc.buttons.generate')
      }
    },

    stats() {
      switch (this.sendingType) {
        case SENDING_TYPES.voice: return calculateVoiceStats(this.text)
        case SENDING_TYPES.sms:
        default: return calculateSmsStats(this.text)
      }
    }
  },

  async mounted() {
    this.channelProcess(this.sendingType)
    this.payload.language = this.locale

    this.getTimeout()
  },

  beforeDestroy() {
    this.clearInterval()
  },

  methods: {
    getTimeout() {
      try {
        const timeout = JSON.parse(window.localStorage.getItem(timeoutKey))
        this.timeoutProcess(parseInt(timeout))
      } catch (error) {
      }
    },
    setTimeout(timeout) {
      if (typeof timeout === 'string' || typeof timeout === 'number') {
        timeout = parseInt(timeout)
        const now = getUnixSeconds()
        this.timeout = timeout - now
        window.localStorage.setItem(timeoutKey, JSON.stringify(timeout))
      }
    },
    clearTimeout() {
      if (this.timeout !== undefined) {
        this.timeout = undefined
        window.localStorage.removeItem(timeoutKey)
      }
    },
    timeoutProcess(timeout) {
      if (timeout) {
        const now = getUnixSeconds()
        if (timeout > now) {
          this.setTimeout(timeout)
          this.intervalProcess()
        } else {
          this.clearInterval()
          this.clearTimeout()
        }
      }
    },

    errorProcess(error) {
      const { payload } = error
      if (payload) {
        const { expires_at } = payload
        if (expires_at) {
          const expires = expires_at / 1000
          this.timeoutProcess(expires)
        }
      }
    },

    intervalProcess() {
      this.interval = setInterval(() => {
        if (this.timeout) {
          this.timeout--
          if (this.timeout < 0) {
            this.clearInterval()
            this.clearTimeout()
          }
        }
      }, 1000)
    },

    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = undefined
      }
    },

    channelProcess(channel) {
      this.channel = channel
      if (channel === SENDING_TYPES.sms) {
        this.payload.smsSegments = '1'
        this.payload.otherChars = undefined
      } else {
        this.payload.smsSegments = undefined
        this.payload.otherChars = '100'
      }
    },

    async generate() {
      if (!this.disabled) {
        try {
          this.loading = true
          if (this.animationFrameId !== null) {
            cancelAnimationFrame(this.animationFrameId)
            this.animationFrameId = 0
          }
          const { data } = await Vue.$GRequest.create(`/n/${services['message-payload-generator']}/text`, {
            formValues: this.payload,
            channel: this.channel
          })
          if (data?.result) {
            this.text = data.result
          }
          if (this.text) {
            this.displayTextGradually(this.text)
          }
        } catch (error) {
          this.errorProcess(error)
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
        }
      }
    },

    displayTextGradually(text, speed = 1) {
      this.displayedText = ''
      const words = text.split(' ')
      let index = 0
      let frameCount = 0

      const addNextWord = () => {
        if (index < words.length) {
          if (frameCount % speed === 0) {
            this.displayedText += (index > 0 ? ' ' : '') + words[index]
            index++
          }
          frameCount++
          this.animationFrameId = requestAnimationFrame(addNextWord)
        } else {
          this.animationFrameId = 0
        }
      }

      this.animationFrameId = requestAnimationFrame(addNextWord)
    },

    clearPayload() {
      merge(this.payload, cloneDeep(defaultPayload), { language: this.locale })
      this.channelProcess(this.sendingType)
    }
  },

  render
}
