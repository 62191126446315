import { getUrlToStorage } from '@/utils'

import render from './render'

export default {
  name: 'ButtonsDownload',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    download() {
      window.location.href = getUrlToStorage([ this.value.documentId ])
    }
  },

  render
}
