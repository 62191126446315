import Vue from 'vue'

import { isEqual } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import { generateServices, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'SendersTree',

  mixins: [
    proxy({ type: 'array' }),
    generateServices([
      {
        name: services.sendernames,

        find: {
          defaultFilter: {
            type: undefined,
            $search: undefined
          },
          appendMode: true
        },

        get: false,
        update: false,
        create: false,
        remove: false
      }
    ])
  ],

  props: {
    type: String,
    readonly: Boolean
  },

  data() {
    return {
      searchValue: undefined,

      tab: 'all'
    }
  },

  computed: {
    checked() {
      return this.proxy.filter(item => {
        if (this.searchValue) {
          return ~(item + '').toLowerCase().indexOf((this.searchValue + '').toLowerCase())
        }

        return true
      })
    }
  },

  watch: {
    tab() {
      this.search(this.searchValue)
    },
    type() {
      if (this.type) {
        this.restData[services.sendernames].find.filter.type = this.type
      }
    }
  },

  mounted() {
    this.get()
    if (this.type) {
      this.restData[services.sendernames].find.filter.type = this.type
    }
  },

  methods: {
    _inputFilter(data) {
      if (!Array.isArray(data)) {
        data = []
      }

      return data
    },

    watchProxyHandler() {
      if (!isEqual(this.proxy, this.value)) {
        this.transmitValue()
      }
    },

    search(search) {
      this.searchValue = search
      switch (this.tab) {
        case 'all': {
          if (search) {
            Vue.set(this.restData[services.sendernames].find.filter, '$search', search)
          } else {
            Vue.delete(this.restData[services.sendernames].find.filter, '$search')
            this.rest[services.sendernames].find()
          }
          break
        }
      }
    },

    async get() {
      try {
        this.search()
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    }
  },

  render
}
