import { mapActions, mapGetters } from 'vuex'

import { cloneDeep, get } from 'lodash'

import { SENDINGS_OUTGOING_FILTER, appendFormattedDates, downloadable, fillDependence, generateServices, permissionPresets, services, tableHeaderParser } from '@/utils'

import render from './render'

export default {
  name: 'SendingsDetails',

  mixins: [
    generateServices({
      name: services.sendings,

      async inputFilter(result) {
        if (result && result.payload && (result.payload.file || result.payload.image || result.payload.audio)) {
          const [ id1, id2 ] = (result.payload.file || result.payload.image || result.payload.audio).split('/')

          result.payload.fileId = id2 || id1

          await fillDependence.call(this, result, {
            service: services.storage,
            permissions: permissionPresets.meUp,
            pathToId: 'payload.fileId',
            pathToObject: 'File',
            outputPath: 'File',
            requestParams: {
              query: {
                return: 'meta'
              }
            }
          })
        }

        appendFormattedDates.call(this, result)

        return result
      },

      find: {
        bucketEnabled: true,
        defaultFilter: cloneDeep(SENDINGS_OUTGOING_FILTER),
        disableWatcherFilter: true
      },
      get: false,
      update: false,
      create: false,
      remove: false
    }),

    downloadable({ permissionAccept: `advanced.${services.sendings}.find` })
  ],

  data() {
    return {
      showDialog: false,
      source: undefined
    }
  },

  props: {
    filter: {
      type: Object,
      default: () => cloneDeep(SENDINGS_OUTGOING_FILTER),
      required: true
    }
  },

  computed: {
    ...mapGetters({
      findSendingFromRouteParams: 'global/findSendingFromRouteParams'
    }),

    headers() {
      return tableHeaderParser.call(this, [
        {
          value: 'type',
          class: 'px-0'
        },
        {
          value: 'status',
          class: 'px-0'
        },
        {
          value: 'owner',
          align: 'left',
          class: 'px-0',
          show: this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)
        },
        {
          title: `${services.sendings}.headers.recipient`,
          value: 'payload.recipient',
          align: 'left',
          class: 'px-2',
          sortable: true
        },
        {
          title: `${services.sendings}.headers.sender`,
          value: 'payload.sender',
          align: 'left',
          class: 'px-2',
          sortable: true
        },
        {
          title: `${services.sendings}.headers.text`,
          value: 'payload.text',
          align: 'left',
          class: 'px-2',
          sortable: true
        },
        {
          title: '',
          value: 'custom.field.1',
          class: 'px-2'
        },
        {
          value: 'segments',
          class: 'px-2'
        },
        {
          value: 'billSec',
          class: 'px-2'
        },
        {
          title: `${services.sendings}.headers.operator`,
          value: 'operator',
          align: 'left',
          class: 'px-2'
        },
        {
          value: 'provider',
          align: 'left',
          class: 'px-2',
          show: this.checkPermissions(`advanced.${services.providers}.get`, permissionPresets.resellerUp)
        },
        {
          title: `${services.sendings}.headers.amount`,
          value: 'meta.billing.amount',
          align: 'left',
          class: 'px-2'
        },
        {
          title: 'misc.createdAt',
          value: 'createdAt',
          align: 'right',
          class: 'px-2',
          sortable: true
        },
        {
          title: 'misc.updatedAt',
          value: 'updatedAt',
          align: 'right',
          class: 'px-2',
          sortable: true
        }
      ])
    }
  },

  watch: {
    filter: {
      handler() {
        this.restData[services.sendings].find.filter = cloneDeep(this.filter)
        if (this.restData[services.sendings].find.pagination.offset !== 0) {
          this.restData[services.sendings].find.pagination.offset = 0
        }
      },
      deep: true
    },
    $route: {
      handler() {
        this.getFilterFromRouter()
      },
      deep: true
    },

    findSendingFromRouteParams() {
      this.getFilterFromRouter()
    }
  },

  mounted() {
    this.getFilterFromRouter()
  },

  methods: {
    ...mapActions({
      setFindSendingFromRouteParams: 'global/setFindSendingFromRouteParams'
    }),

    async getFilterFromRouter() {
      const id = get(this.$route, 'query.id', get(this.$route, 'params.id'))
      if (id) {
        this.showDialog = false
        this.restData[services.sendings].find.filter.id = id
      }
      const providerId = get(this.$route, 'query.providerId')
      if (providerId) {
        this.restData[services.sendings].find.filter.meta._routing.provider = providerId
      }
      const createdAt = get(this.$route, 'params.createdAt')
      if (createdAt) {
        this.restData[services.sendings].find.filter.createdAt = createdAt
      }
      const ownerId = get(this.$route, 'query.ownerId')
      if (ownerId) {
        this.restData[services.sendings].find.filter.OwnerId = ownerId
      }
      if (id || providerId || createdAt || ownerId) {
        this.$emit('filter', this.restData[services.sendings].find.filter)
        await this.rest[services.sendings].find()
        this.setFindSendingFromRouteParams(false)
      }
    }
  },

  render
}
