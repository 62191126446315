import { generateServices, services } from '@/utils'

import render from './render'

export default {
  name: services.paymentLogs,

  mixins: [
    generateServices({
      name: services.paymentLogs,

      find: {
        defaultFilter: {
          PaymentId: undefined,
          $scope: [ 'full' ]
        }
      },

      get: false,
      create: false,
      update: false,
      remove: false
    })
  ],

  props: {
    PaymentId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      showDialog: false,

      request: undefined,
      response: undefined,

      currentTab: undefined
    }
  },

  mounted() {
    if (this.checkPermissions(`advanced.${services.paymentLogs}.get`)) {
      this.rest[services.paymentLogs].find({ query: { PaymentId: this.PaymentId } })
    }
  },

  render
}
