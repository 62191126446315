import { components, inputModes, services } from '@/utils'

function renderUsernameField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.username,
        label: this.getTranslate(`${services.login}.labels.username`),
        name: 'username',
        autocomplete: 'username',
        mode: inputModes['line-label'],
        rounded: true,
        details: false,
        autofocus: true,
        required: true
      },
      on: {
        input: event => {
          this.proxy.username = event
        }
      }
    }
  )
}
function renderPasswordField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.password,
        label: this.getTranslate(`${services.login}.labels.password`),
        name: 'password',
        autocomplete: 'current-password',
        type: this.passwordVisibility ? 'password' : 'text',
        afterIcon: this.passwordVisibility ? 'visibility' : 'visibility_off',
        afterIconCallback: () => this.passwordVisibility = !this.passwordVisibility,
        mode: inputModes['line-label'],
        rounded: true,
        details: false,
        clearable: true,
        required: true
      },
      on: {
        input: event => {
          this.proxy.password = event
        }
      }
    }
  )
}

function renderRecoveryLink(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        'router-link',
        {
          class: 'link caption',
          props: { to: { name: 'recover' } }
        },
        [ this.getTranslate('recover.hints.recover') ]
      )
    ]
  )
}

export default function(h) {
  return h(
    components.form,
    {
      class: 'grid'
    },
    [
      renderUsernameField.call(this, h),
      renderPasswordField.call(this, h),
      renderRecoveryLink.call(this, h)
    ]
  )
}
