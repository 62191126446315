export const methods = {
  yooMoney: 'YooMoney',
  tinkoff: 'Tinkoff',
  mandarin: 'Mandarin',
  stripe: 'Stripe',
  cards: 'Pay by cards',
  recurrent: 'Recurrent payment',
  invoice: 'Invoice'
}

export default {
  title: 'Payments',

  titles: {
    individual: 'Individual',
    legal: 'Legal',
    threshold: 'By threshold',
    frequency: 'By frequency',
    billings: 'Billings',
    paymentLogs: 'Payment logs',

    step: {
      first: 'Payment method',
      individual: 'Payment method',
      legal: 'Making an invoice',
      cards: 'Pay with card',
      yooMoney: 'YooMoney',
      recurrent: 'Recurrent payment'
    }
  },

  labels: {
    amount: 'Amount',
    method: 'Method',
    frequency: '',
    status: 'Status',
    paymentType: 'Payment type',
    sum: 'Sum',
    owner: 'Owner',
    acknowledged: 'I have read the information, understand that my payment will not be credited without sending the email, and agree to attach the payment receipt.',

    methods: {
      tinkoff: {
        TerminalKey: 'Terminal key',
        Password: 'Password',
        ekamToken: 'EKAM token'
      },

      mandarin: {
        merchantId: 'Merchant ID',
        secret: 'Secret',
        ekamToken: 'EKAM token',
        includeFiscalInformation: 'Include fiscal information'
      },

      stripe: {
        apikey: 'API key',
        usd_price_id: 'USD price ID',
        eur_price_id: 'RUB price ID'
      }
    }
  },

  recurrentTypes: {
    once: 'Once',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly'
  },

  contents: {
    legal: 'To invoice, please write to <a href="mailto:support@sigmasms.ru">support@sigmasms.ru</a>.',

    payment: {
      result: {
        success: 'Payment completed successfully.<br>Your money will be credited to your account soon.',
        fail: 'Could not complete payment.<br>Please contact your manager to resolve this issue.'
      }
    }
  },

  hints: {
    delay: 'Balance credited within 5 minutes',
    commission: 'Bank fee:',
    commissions: 'fee',
    yooMoney: 'You can also pay with YooMoney, with a lower fee.',
    noOneFound: 'No one payment found',
    nextPaymentAt: 'Next payment at',
    addPayment: 'Add payment',
    offer: 'I agree to the offer for automatic payment service.',
    goToPayment: 'Go to payment',
    notFound: 'No payments found',
    individual: 'To replenish the balance of your personal account by credit card, contact us in any convenient way.',
    owner: 'Owner',
    due: 'Fee: {due}%',
    sum: 'Commission: 3%',
    recurrent: 'Recurrent automatic payment'
  },

  types: {
    individual: 'Individual',
    legal: 'Company'
  },

  methods,

  buttons: {
    add: {
      funds: 'Add funds',
      payment: 'Add recurrent payment'
    },
    request: {
      payment: 'Request payment'
    },
    billings: 'Billings history',
    paymentLogs: 'Payment logs',
    refund: 'Refund payment'
  },

  links: {
    attachInvoice: 'Attach invoice',
    attachInvoiceTo: 'Attach an invoice for {title}'
  },

  statuses: {
    init: 'Initialized',
    completed: 'Completed',
    refundRequested: 'Refund requested',
    refunded: 'Refunded',
    partialRefunded: 'Partial refunded',
    error: 'Error'
  },

  tabs: {
    payments: 'Payments',
    recurrentPayments: 'Recurrent payments'
  },

  infos: {
    method: 'There are currently no payment methods configured. Please contact support to resolve this issue.'
  },

  warnings: {
    yooMoney: `
<center>⚠ <strong>IMPORTANT INFORMATION!</strong> ⚠</center>
<center><strong>IF YOU DO NOT INFORM US ABOUT YOUR PAYMENT, THE MONEY WILL NOT BE CREDITED!</strong></center>
To process your payment, you must <strong>send us an email</strong>:
<div><a href="mailto:a1@sigmasms.ru?subject=Payment%20via%20YooMoney%20–%20Identification&body=Hello.%0A%0ALogin: {username}%0AAmount: {sum}%0A%0AI'm attaching the payment receipt as an attachment.">a1@sigmasms.ru</a></div>
<div><strong>Just click the link — the email is pre-filled, you only need to send it! Don’t forget to attach the payment receipt.</strong></div>
If you choose to write the email manually, please include the following information:
<ul>
<li><strong>Your login</strong> (otherwise, we don’t know whose account to credit)</li>
<li><strong>Payment amount</strong> (so we can find it in the system)</li>
<li><strong>Attach the payment receipt</strong> (to confirm the transaction)</li>
</ul>
<p><strong>Without this information, we cannot process your payment, and the funds will remain unallocated!</strong></p>
<strong>The indicated service commission is approximately 3%.</strong>
<div>&#x2197; <a href="https://yoomoney.ru/page?id=536332" target="_blank">More about YooMoney commissions</a></div>
`,
    no: {
      methods: 'Payment methods are not currently configured. Please contact support.'
    }
  }
}
