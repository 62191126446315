import IMask from 'imask'
import { merge } from 'lodash'

export const iMaskProps = {
  mask: [ String, RegExp ],

  maskOptions: {
    type: Object,
    default: () => ({})
  }
}

export const iMask = {
  data() {
    return {
      masked: undefined
    }
  },

  computed: {
    options() {
      return merge(
        {
          mask: this.mask,
          lazy: true,
          autofix: true,
          overwrite: true
        },
        this.maskOptions
      )
    }
  },

  watch: {
    mask(value) {
      if (value !== undefined) {
        this._updateOptions()
      } else {
        this._destroyMasked()
      }
    },

    maskOptions: {
      handler() {
        this._updateOptions()
      },
      deep: true
    }
  },

  beforeDestroy() {
    this._destroyMasked()
  },

  methods: {
    _initMasked() {
      if (this.options.mask && !this.masked) {
        this.masked = new IMask(this.$el, this.options)
      }
    },
    _destroyMasked() {
      if (this.masked) {
        this.masked.destroy()
        this.masked = undefined
      }
    },
    _updateValue(value) {
      if (this.masked) {
        if (typeof value === 'string') {
          this.masked.value = value
        } else {
          this.masked.value = ''
        }
      }
    },
    _updateOptions() {
      if (this.masked) {
        this.masked.updateOptions(this.options)
      }
    }
  }
}
