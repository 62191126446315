import { methods } from './payments'

export default {
  title: 'Pagos recurrentes',

  titles: {
    create: 'Crear pago recurrente',
    payments: 'Historial de pagos',
    empty: {
      title: 'No se ha creado ningún pago recurrente',
      sub: 'Cree un nuevo pago recurrente usando el botón de abajo'
    },
    remove: 'Confirmación de eliminación',
    unlock: 'Confirmación de desbloqueo'
  },

  methods,

  types: {
    threshold: 'Por umbral',
    frequency: 'Periódicamente',
    description: {
      threshold: 'Pago al alcanzar el umbral',
      frequency: 'Pago una vez por período'
    }
  },

  statuses: {
    init: 'Inicializado',
    ready: 'Activo',
    error: 'Error',
    pause: 'Pausado',
    block: 'Bloqueado'
  },

  labels: {
    method: 'Método de pago',
    amount: 'Cantidad',
    type: 'Tipo de pago',
    status: 'Estado del pago',
    threshold: 'Umbral de recarga',
    frequency: 'Frecuencia de repetición',
    owner: 'Propietario'
  },

  contents: {
    threshold: 'Al alcanzar el umbral de {threshold}',
    frequency: '{frequency}',
    remove: 'Confirme la eliminación del pago recurrente',
    unlock: 'Confirme el desbloqueo del pago recurrente',

    confirm: {
      pause: 'Está a punto de pausar el pago recurrente.<br>Esto desactivará el pago recurrente y evitará la deducción automática de fondos según los umbrales o el horario establecidos.<br><strong class="mt-2">¿Está seguro de que desea continuar?<strong>',
      resume: 'Está a punto de reanudar el pago recurrente.<br>Esto permitirá al sistema deducir automáticamente los fondos según los umbrales o el horario establecidos.<br><strong class="mt-2">¿Está seguro de que desea continuar?<strong>'
    }
  },

  hints: {
    method: 'Seleccione el método de pago que se utilizará para futuros pagos',
    amount: 'Ingrese una cantidad en rublos, mayor que cero',
    type: 'Seleccione el método de repetición del pago',
    threshold: 'Al alcanzar el cual se intentará deducir los fondos',
    frequency: 'Con qué frecuencia deducir los fondos',
    redirect: 'Después de hacer clic en el botón "Crear", será redirigido a la página de pago.',
    statuses: {
      init: 'El pago recurrente necesita ser confirmado',
      ready: 'El pago recurrente está activo',
      pause: 'El pago recurrente está en pausa',
      error: 'El pago recurrente ha terminado con un error',
      block: 'El pago recurrente está bloqueado'
    },
    notFound: 'No se encontró ningún pago recurrente',
    owner: 'Propietario del pago',
    types: {
      threshold: 'Pago por umbral',
      frequency: 'Pago por tiempo'
    },
    description: {
      amount: 'La cantidad del pago por defecto es igual al umbral.'
    }
  },

  tooltips: {
    debit: 'Se deducirán {amount}.',
    payments: 'Historial de pagos',
    pause: 'Pausar',
    continue: 'Reanudar',
    approve: 'Confirmar pago',
    repeat: 'Repetir confirmación del pago'
  },

  buttons: {
    add: 'Agregar pago recurrente',
    repeat: 'Repetir pago',
    resume: 'Reanudar',
    pause: 'Pausar',
    payments: 'Historial de pagos',
    confirm: 'Confirmar'
  },

  links: {
    confirm: 'Confirmar'
  },

  snackbars: {
    created: 'Pago recurrente creado',
    updated: 'Pago recurrente actualizado',
    removed: 'Pago recurrente eliminado'
  },

  errors: {
    amount: {
      required: 'La cantidad es obligatoria',
      min: 'La cantidad no puede ser igual o menor que cero',
      threshold: 'La cantidad no puede ser menor que el umbral'
    },
    threshold: {
      required: 'El umbral es obligatorio',
      min: 'El umbral no puede ser igual o menor que cero'
    }
  }
}
