import { EVENTSHISTORY_ASSOCIATED_SERVICES } from '@sigma-legacy-libs/essentials/lib/constants'
import proxy from '@sigma-legacy-libs/g-proxy'

import { services } from '@/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    rows() {
      const rows = []
      const excludeKeys = [ 'createdAt', 'updatedAt', 'User', 'data' ]
      for (const key in this.value) {
        if (excludeKeys.includes(key)) {
          continue
        }

        const result = {
          title: this.getTranslate(`${services.eventsHistory}.labels.${key}`),
          copy: true,
          value: this.value[key]
        }
        switch (key) {
          case 'userId': {
            result.link = true
            result.to = {
              name: `${services.users}.single`,
              params: { id: this.value[key] }
            }
            break
          }
          case 'entityId': {
            result.link = true
            result.to = {
              name: `${this.value.service}.single`,
              params: { id: this.value[key] }
            }

            switch (this.value.service) {
              case EVENTSHISTORY_ASSOCIATED_SERVICES.tasks: {
                result.to = {
                  name: services.billings,
                  params: {
                    id: this.value.data.id,
                    createdAt: this.getCreatedAt()
                  },
                  query: { currentTab: services.tasks }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.templates: {
                result.to = {
                  name: 'create',
                  params: { id: this.value.data.id }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.contactLists: {
                result.to = {
                  name: `${services.contacts}.single`,
                  params: { id: this.value.data.id }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.billingsUsers: {
                result.to = {
                  name: services.billings,
                  params: {
                    id: this.value.data.id,
                    createdAt: this.getCreatedAt()
                  },
                  query: { currentTab: services.billings }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.contactListsContacts: {
                result.to = {
                  name: `${services.contacts}.single`,
                  params: { id: this.value.data.ListId }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.keywordListsKeywords: {
                result.to = {
                  name: `${services.keywordLists}.single`,
                  params: { id: this.value.data.ListId }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.permissionsUsers: {
                result.to = {
                  name: `${services.users}.single`,
                  params: { id: this.value.entityId },
                  query: { currentTab: services.permissions }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.settingsUserUsers: {
                const [ settingKey ] = this.value?.data?.new?.field?.split('.') || []
                result.to = {
                  name: `${services.users}.single`,
                  params: { id: this.value.entityId },
                  query: {
                    currentTab: services.settingsUser,
                    settingKey
                  }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.tokensUsers: {
                result.to = {
                  name: `${services.users}.single`,
                  params: { id: this.value.entityId },
                  query: { currentTab: services.tokens }
                }
                break
              }
            }

            break
          }
          case 'service': {
            let title = `${this.value.service}.title`
            switch (this.value.service) {
              case EVENTSHISTORY_ASSOCIATED_SERVICES.billingsUsers: {
                title = `${services.eventsHistory}.services.billingsUsers`
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.contactListsContacts: {
                title = `${services.eventsHistory}.services.${services.contacts}`
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.keywordListsKeywords: {
                title = `${services.eventsHistory}.services.${services.keywords}`
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.permissionsUsers: {
                title = `${services.eventsHistory}.services.${services.permissions}`
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.settingsUserUsers: {
                title = `${services.eventsHistory}.services.${services.settingsUser}`
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.tokensUsers: {
                title = `${services.eventsHistory}.services.${services.tokens}`
                break
              }
            }

            result.value = this.getTranslate(title)
            result.link = true
            result.to = { name: this.value[key] }

            switch (this.value.service) {
              case EVENTSHISTORY_ASSOCIATED_SERVICES.tasks: {
                result.to = {
                  name: services.billings,
                  query: { currentTab: services.tasks }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.templates: {
                result.to = {
                  name: 'create'
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.contactLists: {
                result.to = {
                  name: `${services.contacts}.single`,
                  params: { id: this.value.data.id }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.billingsUsers: {
                result.to = {
                  name: services.billings,
                  query: { currentTab: services.billings }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.contactListsContacts: {
                result.to = {
                  name: services.contacts,
                  params: { id: this.value.data.ListId }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.keywordListsKeywords: {
                result.to = {
                  name: `${services.keywordLists}.single`,
                  params: { id: this.value.data.ListId }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.permissionsUsers: {
                result.to = {
                  name: `${services.users}.single`,
                  params: { id: this.value.entityId },
                  query: { currentTab: services.permissions }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.settingsUserUsers: {
                const [ settingKey ] = this.value?.data?.new?.field?.split('.') || []
                result.to = {
                  name: `${services.users}.single`,
                  params: { id: this.value.entityId },
                  query: {
                    currentTab: services.settingsUser,
                    settingKey
                  }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.tokensUsers: {
                result.to = {
                  name: `${services.users}.single`,
                  params: { id: this.value.entityId },
                  query: { currentTab: services.tokens }
                }
                break
              }
            }

            break
          }
          case 'type': {
            result.value = this.getTranslate(`${services.eventsHistory}.types.${this.value[key]}`)
            break
          }
        }
        rows.push(result)
      }

      return rows
    }
  },

  methods: {
    getCreatedAt() {
      const gt = this.value.data.createdAt
      const lt = new Date(gt).setSeconds(new Date(gt).getSeconds() + 1)

      return {
        $gt: gt,
        $lt: new Date(lt).toISOString()
      }
    }
  },

  render
}
