import { merge } from 'lodash'

import { RENDER_SETTINGS_SITE_SCHEMA, RENDER_SETTINGS_SITE_SCHEMA_RESELLER, services } from '@/utils'

import template from '@/components/services/settings/template'

export default function(options) {
  return {
    computed: {
      renderSchema() {
        if (options.UserId && this.checkPermissions(`advanced.${services.settingsSite}.set`) || this.checkPermissions(`advanced.${services.settingsSite}.set`, 'reseller')) {
          return RENDER_SETTINGS_SITE_SCHEMA_RESELLER
        } else if (this.checkPermissions(`advanced.${services.settingsSite}.set`)) {
          return RENDER_SETTINGS_SITE_SCHEMA
        }
      }
    },

    render(h) {
      const optionsDefaults = {
        renderSchema: this.renderSchema,
        serviceName: services.settingsSite
      }

      return h(template(merge(optionsDefaults, options)))
    }
  }
}
