import { buttonModes, colors, components, services } from '@/utils'

import button from '@/components/button'
import squircle from '@/components/icon/squircle'
import svgIcon from '@/components/icon/svg'
import tag from '@/components/tag'
import textOverflow from '@/components/text/overflow'

function renderButton(h, method) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate(`${services.payments}.methods.${method}`),
        iconSVG: method,
        color: this.proxy === method ? colors[method] : colors.grey,
        mode: this.proxy === method ? buttonModes.filled : buttonModes.flat,
        disabled: this.$disabled
      },
      on: {
        click: () => {
          this.proxy = method
        }
      }
    }
  )
}
function renderButtons(h) {
  return h(
    'div',
    {
      class: 'faic fw grid-gap--8'
    },
    [ this.$items.map(item => renderButton.call(this, h, item.value)) ]
  )
}

function renderSelectionItem(h, item) {
  return h(
    tag,
    {
      props: {
        label: item.title,
        color: colors[item.value],
        as: 'select'
      }
    }
  )
}
function renderItem(h, item) {
  return h(
    'div',
    {
      class: 'faic grid-gap--8'
    },
    [
      h(
        squircle,
        {
          props: {
            color: item.value,
            tooltip: this.getTranslate(`${services.payments}.methods.${item.value}`)
          }
        },
        [
          h(
            svgIcon,
            {
              props: {
                value: item.value,
                color: item.value
              }
            }
          )
        ]
      ),
      h(
        textOverflow,
        {
          props: {
            value: item.title
          }
        }
      )
    ]
  )
}
function renderSelect(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${services.payments}.labels.method`),
        items: this.$items,
        mode: this.mode,
        disabled: this.$disabled,
        clearable: this.clearable,
        required: this.required,
        dense: this.dense,
        details: false,
        rounded: true
      },
      on: {
        input: event => {
          this.proxy = event
        }
      },
      scopedSlots: {
        selection: ({ item }) => {
          return renderSelectionItem.call(this, h, item)
        },
        item: ({ item }) => {
          return renderItem.call(this, h, item)
        }
      }
    }
  )
}

export default function(h) {
  switch (this.as) {
    case 'buttons': return renderButtons.call(this, h)
    case 'select':
    default: return renderSelect.call(this, h)
  }
}
