import { services } from '@/utils'

import download from '@/components/services/invoices/item/buttons/download'
import moderation from '@/components/services/invoices/item/buttons/moderation'
import { invoiceStatues } from '@/components/services/invoices/utils'
import serviceActions from '@/components/templates/service/item/actions'

function renderDownload(h) {
  return h(
    download,
    {
      props: {
        value: this.value,
        view: this.view
      }
    }
  )
}
function renderModeration(h, status) {
  return h(
    moderation,
    {
      props: {
        status,
        value: this.value,
        view: this.view
      }
    }
  )
}
function renderContentByPermission(h) {
  if (this.hasPermission) {
    return [
      renderModeration.call(this, h, invoiceStatues.paid),
      renderModeration.call(this, h, invoiceStatues.rejected)
    ]
  }
}
function renderServiceActions(h, trigger = false) {
  if (trigger && this.hasPermission || !trigger && (this.canEdit || this.canRemove)) {
    return h(
      serviceActions,
      {
        props: {
          value: this.value,
          service: services.invoices
        },
        scopedSlots: {
          default: () => {
            if (trigger) {
              return renderContentByPermission.call(this, h)
            }
          }
        }
      }
    )
  }
}
function renderButtons(h) {
  if (this.hasPermission || this.canEdit || this.canRemove) {
    return h(
      'div',
      {
        class: 'flex grid-gap--8'
      },
      [
        renderContentByPermission.call(this, h),
        renderServiceActions.call(this, h)
      ]
    )
  }
}

function renderContentByViewport(h) {
  if (this.viewport.breakpoint.lgUp) {
    return renderButtons.call(this, h)
  } else {
    return renderServiceActions.call(this, h, true)
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        [`${services.invoices}-item__buttons`]: true,
        'hover-child': this.viewport.breakpoint.lgUp
      }
    },
    [
      renderDownload.call(this, h),
      renderContentByViewport.call(this, h)
    ]
  )
}
