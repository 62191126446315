import { cloneDeep } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

const defaults = {
  count: 15,
  interval: null,
  sensitivity: true,
  disabled: true
}

export default {
  name: 'AfterCreateDialog',

  mixins: [ proxy() ],

  props: {
    data: {
      type: Object,
      default: undefined
    }
  },

  data() {
    return cloneDeep(defaults)
  },

  computed: {
    $_token() {
      if (this.data?.token) {
        if (this.sensitivity) {
          return new Array(this.data.token.length).fill('•').join('\u200B')
        }

        return this.data?.token
      }
    }
  },

  watch: {
    value(value) {
      if (value) {
        this.interval = setInterval(() => {
          this.count--
          if (this.count <= 0) {
            this.reset(false)
            this.disabled = false
          }
        }, 1000)
      } else {
        this.reset()
      }
    }
  },

  mounted() {
    this.reset()
  },

  beforeDestroy() {
    this.reset()
  },

  methods: {
    reset(hard = true) {
      clearInterval(this.interval)
      if (hard) {
        for (const key in defaults) {
          this[key] = defaults[key]
        }
      }
    }
  },

  render
}
