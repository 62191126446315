import { get } from 'lodash'

import { buttonModes, components, getUrlToStorage, icons, inputModes, safeNameRegex, services, usernameRegexSoftForRegistration } from '@/utils'

import button from '@/components/button'

function renderUsernameField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.username,
        label: this.getTranslate(`${services.registration}.labels.username`),
        hint: this.getTranslate(`${services.registration}.hints.username`),
        error: this.errors.username || this.usernameValidation,
        name: 'username',
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        autofocus: true,
        required: true,
        tabindex: 1,
        mask: usernameRegexSoftForRegistration
      },
      on: {
        input: event => {
          this.proxy.username = event
        }
      }
    }
  )
}
function renderPasswordField(h) {
  return h(
    'div',
    {
      class: 'faifs grid-gap--8'
    },
    [
      h(
        components['text-field'],
        {
          props: {
            value: this.proxy.password,
            label: this.getTranslate(`${services.registration}.labels.password`),
            error: this.errors.password || this.passwordValidation,
            hint: this.getTranslate(`${services.registration}.hints.password`),
            mode: inputModes['line-label'],
            dense: true,
            rounded: true,
            details: true,
            name: 'password',
            autocomplete: 'new-password',
            type: this.passwordVisibility ? 'password' : 'text',
            afterIcon: this.passwordVisibility ? 'visibility' : 'visibility_off',
            afterIconCallback: () => {
              this.passwordVisibility = !this.passwordVisibility
            },
            clearable: true,
            required: true,
            tabindex: 2
          },
          on: {
            input: event => {
              this.proxy.password = event
            }
          }
        }
      ),

      h(
        button,
        {
          props: {
            icon: icons.settings_backup_restore,
            mode: buttonModes.flat,
            tooltip: this.getTranslate(`${services.registration}.tooltips.password.generate`),
            tabindex: -1
          },
          on: {
            click: () => {
              this.passwordGenerator()
            }
          }
        }
      )
    ]
  )
}

function renderFirstNameField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.data.firstName,
        label: this.getTranslate(`${services.registration}.labels.firstName`),
        name: 'firstName',
        error: get(this.errors, 'data.firstName'),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false,
        required: true,
        tabindex: 3,
        mask: safeNameRegex
      },
      on: {
        input: event => {
          this.proxy.data.firstName = event
        }
      }
    }
  )
}
function renderLastNameField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.data.lastName,
        label: this.getTranslate(`${services.registration}.labels.lastName`),
        name: 'lastName',
        error: get(this.errors, 'data.lastName'),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false,
        tabindex: 3,
        mask: safeNameRegex
      },
      on: {
        input: event => {
          this.proxy.data.lastName = event
        }
      }
    }
  )
}
function renderEmailField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.data.email,
        label: this.getTranslate(`${services.registration}.labels.email`),
        hint: this.getTranslate(`${services.registration}.hints.email`),
        name: 'email',
        type: 'email',
        error: get(this.errors, 'data.email') || this.emailValidation,
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        required: true,
        tabindex: 4
      },
      on: {
        input: event => {
          this.proxy.data.email = event
        }
      }
    }
  )
}
function renderPhoneField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.data.phone,
        label: this.getTranslate(`${services.registration}.labels.phone`),
        hint: this.getTranslate(`${services.registration}.hints.phone`),
        name: 'phone',
        type: 'tel',
        error: get(this.errors, 'data.phone') || this.phoneValidation,
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false,
        required: true,
        tabindex: 5
      },
      on: {
        input: event => {
          this.proxy.data.phone = event
        }
      }
    }
  )
}

function renderUsedReferralCode(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.data.meta.usedReferralCode,
        label: this.getTranslate(`${services.registration}.labels.usedReferralCode`),
        name: 'usedReferralCode',
        error: get(this.errors, 'data.meta.usedReferralCode'),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false,
        tabindex: 6
      },
      on: {
        input: event => {
          this.proxy.data.meta.usedReferralCode = event
        }
      }
    }
  )
}
function renderPrivacyPolicy(h) {
  const privacyPolicy = get(this.globalSettings, 'frontend.documents.privacyPolicy')
  if (privacyPolicy) {
    return h(
      'div',
      {
        class: 'faic caption'
      },
      [
        h(
          'a',
          {
            attrs: { href: getUrlToStorage([ privacyPolicy ]) },
            class: 'link link--dashed'
          },
          [ this.getTranslate('settingsSite.titles.privacyPolicy') ]
        )
      ]
    )
  }
}
function renderPublicOffer(h) {
  const publicOffer = get(this.globalSettings, 'frontend.documents.publicOffer')
  if (publicOffer) {
    return h(
      'div',
      {
        class: 'faic caption pt-1'
      },
      [
        h(
          'a',
          {
            attrs: { href: getUrlToStorage([ publicOffer ]) },
            class: 'link link--dashed'
          },
          [ this.getTranslate('settingsSite.titles.publicOffer') ]
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    components.form,
    {
      class: 'grid',
      props: { autocomplete: 'off' }
    },
    [
      renderUsernameField.call(this, h),
      renderPasswordField.call(this, h),

      h('div'),

      renderFirstNameField.call(this, h),
      renderLastNameField.call(this, h),
      renderEmailField.call(this, h),
      renderPhoneField.call(this, h),

      h('div'),

      renderUsedReferralCode.call(this, h),
      renderPrivacyPolicy.call(this, h),
      renderPublicOffer.call(this, h)
    ]
  )
}
