import { buttonModes, colors } from '@/utils'

import button from '@/components/button'
import info from '@/components/info'

export default function(h) {
  if (this.check) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        style: 'grid-template-columns: 1fr auto'
      },
      [
        h(
          info,
          {
            props: {
              value: this.text,
              color: colors.warning
            }
          }
        ),
        h(
          button,
          {
            props: {
              iconSVG: 'question',
              tooltip: this.getTranslate('misc.buttons.support'),
              mode: buttonModes.flat,
              color: colors.primary
            },
            on: {
              click: () => {
                this.$router.push({ name: 'support' })
              }
            }
          }
        )
      ]
    )
  }
}
