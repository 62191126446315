import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import { cloneDeep, get } from 'lodash'

import { checkHostnames } from '@sigma-frontend-libs/commons'
import { BILLING_CURRENCY, PAYMENTS_METHODS, RECURRENT_PAYMENTS_METHODS } from '@sigma-legacy-libs/essentials/lib/constants'

import { DEFAULT_INVOICE_PAYLOAD, DEFAULT_PAYMENT_PAYLOAD, backendServerIP, getUrlToStorage, globalErrorHandler, globalErrorProcessor, hostnames, services } from '@/utils'

import render from './render'

export default {
  name: 'GlobalPaymentDialog',

  data() {
    return {
      step: 'first',

      show: false,
      loading: false,

      payload: {
        legal: cloneDeep(DEFAULT_INVOICE_PAYLOAD),
        individual: cloneDeep(DEFAULT_PAYMENT_PAYLOAD),
        individualValidation: false
      }
    }
  },

  computed: {
    ...mapGetters({
      paymentsMethods: 'payments/methods',
      showPaymentDialog: 'payments/showPaymentDialog'
    }),

    validation() {
      const amount = get(this.payload, 'legal.amount', 0)

      return !!(this.payload.legal.requisiteId && this.payload.legal.companyId && amount > 0)
    },

    paymentTypes() {
      const result = [
        {
          name: 'legal',
          priority: 2
        }
      ]
      if (Array.isArray(this.$paymentsMethods) && this.$paymentsMethods.length) {
        result.push({
          name: 'individual',
          priority: 1
        })
      }

      return result.sort((a, b) => a.priority - b.priority).map(({ name }) => name)
    },

    $paymentsMethods() {
      const result = []
      if (this.paymentsMethods.some(method => ~PAYMENTS_METHODS.indexOf(method))) {
        result.push({
          name: 'cards',
          priority: 1
        })
      }
      if (this.currencyIsRUB && this.hostnameIsOwn) {
        result.push({
          name: 'yooMoney',
          priority: 3
        })
      }
      if (this.paymentsMethods.some(method => ~RECURRENT_PAYMENTS_METHODS.indexOf(method))) {
        result.push({
          name: 'recurrent',
          priority: 9
        })
      }

      return result.sort((a, b) => a.priority - b.priority).map(({ name }) => name)
    },

    currencyIsRUB() {
      return this.globalSettings.billings.currency === BILLING_CURRENCY.RUB
    },
    hostnameIsOwn() {
      return checkHostnames(hostnames)
    }
  },

  methods: {
    ...mapActions({
      setCurrentTab: 'currentTab/setCurrentTab',
      setShowPaymentDialog: 'payments/setShowPaymentDialog',
      setShowCreateRecurrentPaymentDialog: 'payments/setShowCreateRecurrentPaymentDialog'
    }),

    async requestPayment() {
      if (!this.payload.individualValidation) {
        return
      }

      const method = this.payload.individual.method
      const amount = this.payload.individual.amount
      if (!amount || amount <= 0) {
        return
      }

      try {
        this.loading = true
        const body = {
          amount,
          method,
          urlSuccess: `${backendServerIP}/api/${services.payments}/webhook/${method}/success/`,
          urlFail: `${backendServerIP}/api/${services.payments}/webhook/${method}/fail/`
        }
        const { data } = await Vue.$GRequest.create(services.payments, body)
        if (data && data.url) {
          window.location.href = data.url
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    },

    async requestDocument() {
      try {
        this.loading = true
        delete this.payload.type
        const { data } = await Vue.$GRequest.create(
          `/n/${services.invoices}`,
          this.payload.legal,
          { query: { scopes: [ 'awaitDocument' ] } }
        )
        if (data) {
          window.location.href = getUrlToStorage([ get(data, 'Owner.id', data.OwnerId), data.documentId ])
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    },

    async paymentMethodClickHandler(method) {
      switch (method) {
        case 'recurrent': {
          const { name, query } = Vue.router.currentRoute
          if (name !== 'account' && query.currentTab !== services.recurrentPayments) {
            Vue.router.push({
              name: 'account',
              hash: 'recurrentPayments'
            })
          } else {
            this.setCurrentTab({ name: services.recurrentPayments })
          }
          this.setShowPaymentDialog(false)
          this.setShowCreateRecurrentPaymentDialog(true)
          break
        }
        case 'cards':
        default: {
          this.step = method
          break
        }
      }
    }
  },

  render
}
