export const names = {
  'aws:maxim': 'Máximo',
  'aws:tatyana': 'Tatiana',
  'yandex:oksana': 'Oksana',
  'yandex:jane': 'Jane',
  'yandex:omazh': 'Omazh',
  'yandex:zahar': 'Zajar',
  'yandex:ermil': 'Ermil',
  'yandex:madirus': 'Madirus',
  'yandex:lea': 'Lea',
  'yandex:john': 'John',
  'yandex:amira': 'Amira',
  'yandex:madi': 'Madi',
  'yandex:nigora': 'Nigora',
  'yandex:alena': 'Aliona',
  'yandex:filipp': 'Felipe',
  'yandex:silaerkan': 'Silaerkan',
  'yandex:erkanyavas': 'Erkanavas',
  'yandex:alyss': 'Ailish',
  'yandex:nick': 'Nick'
}

export default { names }
