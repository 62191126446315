import { get } from 'lodash'

import { TASK_FREQUENCY, TASK_STATES, TASK_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, services } from '@/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  const frequency = get(this.value, 'data.frequency')
  if (frequency) {
    return h(
      'g-menu',
      {
        props: {
          rounded: true,
          placement: 'bottom-start',
          disabled: this.value.state === TASK_STATES.completed || TASK_TYPES['payments:recurrent'] === this.value.type,
          offsetDistance: 8,
          sameWidth: true
        }
      },
      [
        h(
          sticker,
          {
            props: {
              value: this.getTranslate(`${services.tasks}.frequencies.${frequency}`),
              label: this.getTranslate(`${services.tasks}.labels.data.frequency`),
              icon: 'repeat',
              color: colors.grey,
              disabled: this.value.state === TASK_STATES.completed || TASK_TYPES['payments:recurrent'] === this.value.type,
              width: '100%',
              to: { service: undefined }
            },
            slot: 'activator'
          }
        ),

        h(
          'g-list',
          {
            props: {
              dense: true,
              rounded: true
            }
          },
          TASK_FREQUENCY.reduce((result, frequency) => {
            if (frequency !== TASK_FREQUENCY.once) {
              result.push(
                h(
                  'g-list-item',
                  {
                    props: {
                      label: this.getTranslate(`${services.tasks}.frequencies.${frequency}`),
                      dense: true
                    },
                    on: {
                      click: () => {
                        this.changeFrequency(this.value.id, frequency)
                      }
                    }
                  }
                )
              )
            }

            return result
          }, [])
        )
      ]
    )
  }
}
