import Vue from 'vue'

import Conditions from '@sigma-legacy-libs/essentials/lib/utils/Conditions'

export default class ConditionsFrontend extends Conditions {
  static async checkInContactLists(recipient, ListIds) {
    const { data } = await Vue.$GRequest.find('contacts', {
      query: {
        phone: recipient,
        ListId: ListIds
      }
    })

    return data.total > 0
  }
}
