export default {
  title: 'Depurar',

  titles: {
    browser: 'Navegador',
    ws: 'Websocket',
    account: 'Cuenta',
    sendings: 'Último envío',

    site: {
      address: 'Dirección del sitio'
    }
  },

  labels: {
    version: 'Versión de la plataforma',
    date: 'Fecha y hora de apertura de la página',
    viewport: 'Tamaño de la ventana',
    host: 'Host',
    protocol: 'Protocolo',

    ws: {
      connection: 'Conexión',
      error: 'Error'
    },

    frontend: {
      title: 'Nombre de la plataforma'
    },

    browser: {
      name: 'Nombre',
      version: 'Versión'
    },

    account: {
      username: 'Nombre de usuario',
      balance: 'Balance',
      id: 'ID',
      sendingTypes: 'Tipos de envío permitidos'
    },

    sending: {
      id: 'ID',
      recipient: 'Destinatario',
      type: 'Tipo de envío'
    }
  },

  ws: {
    connected: 'Conectado',
    disconnected: 'Desconectado'
  },

  buttons: {
    reset: 'Reiniciar',

    download: {
      storage: 'Descargar almacenamiento'
    },

    clean: {
      storage: 'Limpiar almacenamiento'
    }
  }
}
