import Vue from 'vue'
import { mapActions } from 'vuex'

import { proxy } from '@/utils'

import render from './render'

export default {
  name: 'Company',

  mixins: [ proxy() ],

  props: {
    items: {
      type: Array,
      default: () => []
    },

    loading: Boolean,
    disabled: Boolean
  },

  methods: {
    ...mapActions({ setShowPaymentDialog: 'payments/setShowPaymentDialog' }),

    redirect() {
      Vue.router.push({ name: 'support' })
      this.setShowPaymentDialog(false)
    }
  },

  render
}
