export default {
  title: 'Comentarios',

  labels: {
    text: 'Comentario'
  },

  snackbars: {
    created: 'Comentario creado con éxito.',
    updated: 'Comentario actualizado con éxito.',
    removed: 'Comentario eliminado con éxito.'
  }
}
