import numeral from 'numeral'
import Prism from 'prismjs'

import 'prismjs/components/prism-json.js'

import { buttonModes, colors, components, getLocaleDateString, getLocaleTimeString, icons, inputModes, services, sizes, states } from '@/utils'

import button from '@/components/button'
import { dialogTypes, fileInfoIcons } from '@/components/file/utils'
import squircle from '@/components/icon/squircle'
import sticker from '@/components/misc/sticker'
import tag from '@/components/tag'
import textOverflow from '@/components/text/overflow'

function renderExpiredAt(h) {
  if (this.checkPermissions(`advanced.${services.storage}.update`)) {
    return h(
      components.menu,
      {
        props: {
          value: this.showEditExpireMenu,
          closeOnContentClick: false
        },
        on: {
          input: event => {
            this.showEditExpireMenu = event
          }
        }
      },
      [
        h(
          tag,
          {
            class: 'cursor--pointer',
            props: {
              label: getLocaleDateString(this.proxy.expiredAt),
              size: sizes.small,
              color: colors.primary
            },
            slot: 'activator'
          }
        ),
        h(
          components['date-picker'],
          {
            props: {
              value: this.proxy.expiredAt,
              localeTag: this.locale,
              min: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
              max: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
              range: false
            },
            on: {
              input: async event => {
                this.proxy.expiredAt = event
                this.showEditExpireMenu = false
              }
            }
          }
        )
      ]
    )
  } else {
    return h(
      'div',
      {
        class: 'faic fjcfe'
      },
      getLocaleDateString(this.proxy.expiredAt)
    )
  }
}

function renderTitleField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.title,
        label: this.getTranslate(`${services.storage}.labels.title`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false,
        clearable: true,
        mask: /^.{0,255}$/
      },
      on: {
        input: event => {
          this.proxy.title = event
        },
        keypress: event => {
          if (event.keyCode === 13) {
            this.updateFile()
          }
        }
      }
    }
  )
}
function renderFileName(h) {
  return h(
    sticker,
    {
      props: {
        value: this.proxy.filename,
        label: this.getTranslate(`${services.storage}.prefixes.filename`)
      }
    }
  )
}

function renderInfoIcon(h, field) {
  return h(
    squircle,
    {
      props: {
        icon: fileInfoIcons[field],
        color: colors.grey,
        iconSize: 17
      }
    }
  )
}
function renderInfoLabel(h, field) {
  return h(
    textOverflow,
    {
      class: `${services.storage}-dialog__info-label`,
      props: {
        value: this.getTranslate(`${services.storage}.prefixes.${field}`)
      }
    }
  )
}
function renderTag(h, content) {
  return h(
    tag,
    {
      props: {
        label: content,
        size: sizes.small
      }
    }
  )
}
function renderInfoValueByType(h, field) {
  switch (field) {
    case 'size': return renderTag.call(this, h, numeral(this.proxy[field]).format('0.0 b'))
    case 'createdAt':
    case 'updatedAt': return renderTag.call(this, h, `${getLocaleDateString(this.proxy[field])} ${getLocaleTimeString(this.proxy[field])}`)
    case 'owner': return renderTag.call(this, h, this.proxy.Owner?.username)
    case 'willBeDeleted': return renderExpiredAt.call(this, h)
    default: return renderTag.call(this, h, this.proxy[field])
  }
}
function renderInfoValue(h, field) {
  return h(
    'div',
    {
      class: `${services.storage}-dialog__info-value`
    },
    [ renderInfoValueByType.call(this, h, field) ]
  )
}
function renderInfoRow(h, field) {
  return h(
    'div',
    {
      class: `${services.storage}-dialog__info-row`
    },
    [
      renderInfoIcon.call(this, h, field),
      renderInfoLabel.call(this, h, field),
      renderInfoValue.call(this, h, field)
    ]
  )
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: `${services.storage}-dialog__info`
    },
    [ this.rows.map(field => renderInfoRow.call(this, h, field)) ]
  )
}

function renderLocks(h) {
  if (this.proxy.locks.length) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        h(
          'div',
          {
            class: 'fjcc'
          },
          [
            h(
              tag,
              {
                props: {
                  label: this.getTranslate(`${services.storage}.prefixes.locks`),
                  size: sizes.small,
                  color: colors.grey
                }
              }
            )
          ]
        ),
        h(
          'div',
          {
            class: 'grid grid-gap--8'
          },
          this.proxy.locks.map(lock => {
            const [ service, id ] = lock.split(':')
            const index = this.proxy.locks.findIndex(item => item === lock)

            return h(
              sticker,
              {
                props: {
                  value: { title: id },
                  label: service === 'googleDriveApi' ? 'Google Drive API' : this.getTranslate(`${service}.title`),
                  button: {
                    icon: icons.lock_open,
                    color: colors.secondary,
                    loading: this.restData[services.storage].update.state === states.loading,
                    disabled: this.restData[services.storage].update.state === states.loading,
                    callback: () => {
                      this.proxy.locks.splice(index, 1)
                    }
                  }
                },
                key: id
              }
            )
          })
        )
      ]
    )
  }
}

function renderBody(h) {
  switch (this.type) {
    case 'editMeta': {
      return h(
        'div',
        {
          class: `${services.storage}-dialog__body`
        },
        [
          renderTitleField.call(this, h),
          renderFileName.call(this, h),
          renderInfo.call(this, h),
          renderLocks.call(this, h)
        ]
      )
    }
    case 'export': {
      return h(
        'div',
        {
          class: 'fc pt-5 pb-5'
        },
        [
          h(
            'div',
            {
              class: 'fjcc facc ff'
            },
            [
              h('g-progress', {
                props: {
                  indeterminate: true,
                  color: 'grey'
                }
              })
            ]
          ),

          h(
            'div',
            {
              class: 'text-center mt-4'
            },
            this.getTranslate(`${services.storage}.hints.export`)
          )
        ]
      )
    }
    case 'remove': {
      return h(
        'div',
        {
          class: 'pa-3'
        },
        this.getTranslate('commons.contents.confirm.remove')
      )
    }
    case 'edit': {
      return h(
        'iframe',
        {
          attrs: {
            src: this.googleFileURL,
            frameborder: 0
          },
          class: 'position-absolute h--100 w--100'
        }
      )
    }
    case 'source': {
      return h(
        'pre',
        {
          class: 'language-json ma-0 pa-2 w--100',
          style: { 'border-radius': 0 },
          domProps: { innerHTML: Prism.highlight(JSON.stringify(this.proxy, null, 2), Prism.languages.json, 'json') }
        }
      )
    }
  }
}

function renderCloseButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.close'),
        mode: buttonModes.flat,
        disabled: this.restData[services.storage].update.state === states.loading
      },
      on: {
        click: () => {
          this.hideDialog()
        }
      }
    }
  )
}
function renderActionButton(h) {
  if (this.type !== dialogTypes.source) {
    return h(
      button,
      {
        props: {
          label: this.getTranslate(`misc.buttons.${this.type === dialogTypes.remove ? this.type : this.type === dialogTypes.edit ? 'save' : 'update'}`),
          loading: this.restData[services.storage].update.state === states.loading,
          disabled: this.restData[services.storage].update.state === states.loading,
          color: this.type === 'remove' ? colors.error : colors.primary
        },
        on: {
          click: () => {
            this[`${this.type}File`]()
          }
        }
      }
    )
  }
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'dialog__footer',
      slot: 'footer'
    },
    [
      renderCloseButton.call(this, h),
      renderActionButton.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    components.dialog,
    {
      props: this.$_props,
      on: this.$_on
    },
    [
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
