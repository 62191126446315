import { BILLING_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, components, icons, popperPlacements, services } from '@/utils'

import button from '@/components/button'
import { billingsStatuses } from '@/components/services/billings/utils'

export default function(h) {
  if (this.checkPermissions(`advanced.${services.billings}.update`)) {
    return h(
      components.menu,
      {
        props: {
          rounded: true,
          offsetDistance: 8,
          placement: popperPlacements['bottom-end'],
          disabled: this.$disabled
        },
        key: this.id
      },
      [
        h(
          button,
          {
            props: {
              icon: icons.more_vert,
              mode: buttonModes.flat,
              loading: this.loading,
              disabled: this.$disabled
            },
            slot: 'activator'
          }
        ),

        h(
          components.list,
          {
            props: {
              dense: true,
              rounded: true
            }
          },
          [
            BILLING_STATUS.map(status => {
              return h(
                components['list-item'],
                {
                  props: {
                    label: this.getTranslate(`${services.billings}.statuses.${status}`),
                    icon: billingsStatuses[status].icon,
                    color: billingsStatuses[status].color,
                    dense: true,
                    disabled: this.status === BILLING_STATUS[status]
                  },
                  on: {
                    click: () => {
                      this.update(status)
                    }
                  }
                }
              )
            })
          ]
        )
      ]
    )
  }
}
