import { getPrice } from '@sigma-frontend-libs/commons'

import { colors } from '@/utils'

import preloader from '@/components/preloader'
import tag from '@/components/tag'

export default function(h) {
  if (this.billingAmount !== undefined) {
    return h(
      tag,
      {
        props: {
          label: getPrice(this.billingAmount),
          color: this.color,
          tooltip: this.tooltip,
          strikethrough: this.refunded
        }
      }
    )
  }

  return h(
    preloader,
    {
      props: {
        color: colors.grey,
        size: 12
      }
    }
  )
}
