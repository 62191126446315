export default function(h) {
  return h(
    'div',
    {
      class: 'text-overflow',
      directives: this.directives,
      on: this.$eventHandlers,
      domProps: { innerHTML: this.$_value }
    }
  )
}
