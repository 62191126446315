export const orderDirections = {
  ASC: 'ASC',
  DESC: 'DESC'
}

export const alphabets = {
  latin: 'latin',
  cyrillic: 'cyrillic'
}

export const dateFields = {
  sentAt: 'sentAt',
  deliveredAt: 'deliveredAt',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  expiredAt: 'expiredAt',
  deletedAt: 'deletedAt',
  startsAt: 'startsAt'
}

export const supportTypes = {
  phones: 'phones',
  emails: 'emails',
  websites: 'websites',
  socials: 'socials'
}

export const displays = {
  desktop: 'desktop',
  mobile: 'mobile'
}
