export default {
  unknown: {
    types: 'Error desconocido con tipo {type}',
    fields: 'Error desconocido con el campo {field}'
  },

  types: {
    required: '¡El campo {field} es obligatorio!',
    string: '¡El campo {field} debe ser una cadena de texto!',
    stringEmpty: '¡El campo {field} no debe estar vacío!',
    stringMin: '¡El campo {field} debe tener al menos {expected} caracteres!',
    stringMax: '¡El campo {field} debe tener como máximo {expected} caracteres!',
    stringLength: '¡El campo {field} debe tener exactamente {expected} caracteres!',
    stringPattern: '¡El campo {field} no coincide con el patrón requerido!',
    stringContains: '¡El campo {field} debe contener el texto: {expected}!',
    stringEnum: '¡El campo {field} no coincide con ninguno de los valores permitidos!',
    number: '¡El campo {field} debe ser un número!',
    numberMin: '¡El campo {field} debe ser mayor o igual a {expected}!',
    numberMax: '¡El campo {field} debe ser menor o igual a {expected}!',
    numberEqual: '¡El campo {field} debe ser igual a {expected}!',
    numberNotEqual: '¡El campo {field} no puede ser igual a {expected}!',
    numberInteger: '¡El campo {field} debe ser un número entero!',
    numberPositive: '¡El campo {field} debe ser un número positivo!',
    numberNegative: '¡El campo {field} debe ser un número negativo!',
    array: '¡El campo {field} debe ser un arreglo!',
    arrayEmpty: '¡El campo {field} no debe ser un arreglo vacío!',
    arrayMin: '¡El campo {field} debe contener al menos {expected} elementos!',
    arrayMax: '¡El campo {field} debe contener como máximo {expected} elementos!',
    arrayLength: '¡El campo {field} debe contener {expected} elementos!',
    arrayContains: '¡El campo {field} debe contener el elemento {expected}!',
    arrayEnum: '¡El valor {expected} del campo {field} no coincide con ninguno de los valores permitidos!',
    boolean: '¡El campo {field} debe ser un valor booleano!',
    function: '¡El campo {field} debe ser una función!',
    date: '¡El campo {field} debe ser una fecha!',
    dateMin: '¡El campo {field} debe ser mayor o igual a {expected}!',
    dateMax: '¡El campo {field} debe ser menor o igual a {expected}!',
    forbidden: '¡El campo {field} está prohibido!',
    email: '¡El campo {field} debe ser un correo electrónico válido!',
    phoneNumber: '¡El campo {field} debe ser un número de teléfono válido en cualquiera de los formatos populares!',
    uniqPhoneNumber: 'El contacto con este número de teléfono ya existe.',
    invalid: '¡El campo {field} está lleno incorrectamente!',
    uniqueViolation: '¡El campo {field} ya existe!',
    fileMaxSize: '¡El tamaño máximo del archivo se ha excedido! [{expected}]',
    duplicateSending: 'Está intentando enviar un duplicado de envío.',
    overdraftLimitExceeded: 'Se ha excedido el límite de sobregiro.',
    userNotFound: 'Usuario con este nombre de usuario o correo electrónico no encontrado.',
    pattern: 'El campo debe contener solo números',
    serverNotAvailable: 'Servidor no disponible',
    invalidType: 'Formato de archivo no soportado',
    wrongLoginOrPassword: 'Usuario o contraseña incorrectos',
    rateLimitExceeded: 'Se ha excedido el número de solicitudes. Inténtelo de nuevo en {count} segundos.',
    INVALID_JWT: 'Token no válido',
    clone: 'No se pudo clonar la entidad.',
    uuid: 'El campo {field} debe ser un UUID válido'
  },

  fields: {
    login: 'inicio de sesión',
    name: 'nombre',
    username: 'nombre de usuario',
    password: 'contraseña',
    owner: 'propietario',
    OwnerId: 'propietario',
    action: 'acción',
    source: 'fuente',
    amount: 'cantidad',
    multiplier: 'multiplicador',
    comment: 'comentario',
    moderation: 'moderación',
    contact: {
      phone: 'teléfono'
    },
    payload: {
      sender: 'remitente',
      text: 'texto'
    },
    data: {
      comment: 'comentario',
      sendingType: 'tipo de envío',
      sender: 'remitente',
      firstName: 'nombre',
      lastName: 'apellido',
      email: 'correo electrónico',
      phone: 'teléfono',
      sum: 'cantidad',
      multiplier: 'multiplicador',
      button: {
        url: 'url del botón',
        text: 'texto del botón'
      }
    },
    settings: {
      check: {
        protocol: 'protocolo',
        processor: 'procesador',
        credentials: {
          username: 'nombre de usuario',
          password: 'contraseña'
        },
        server: {
          host: 'host',
          port: 'puerto'
        },
        limits: {
          bulk: 'bulk',
          concurrency: 'concurrencia',
          rpm: 'rpm'
        }
      },
      send: {
        protocol: 'protocolo',
        processor: 'procesador',
        credentials: {
          username: 'nombre de usuario',
          password: 'contraseña'
        },
        server: {
          host: 'host',
          port: 'puerto'
        },
        limits: {
          bulk: 'bulk',
          concurrency: 'concurrencia',
          rpm: 'rpm'
        }
      },
      ping: {
        protocol: 'protocolo',
        processor: 'procesador',
        credentials: {
          username: 'nombre de usuario',
          password: 'contraseña'
        },
        server: {
          host: 'host',
          port: 'puerto'
        },
        limits: {
          bulk: 'bulk',
          concurrency: 'concurrencia',
          rpm: 'rpm'
        }
      },
      sendings: {
        anySendername: 'cualquier nombre del remitente'
      },
      billing: {
        overdraft: 'sobregiro'
      },
      websocket: {
        channels: 'canales'
      }
    },
    phone: 'teléfono',
    email: 'correo electrónico',
    firstName: 'nombre',
    lastName: 'apellido',
    middleName: 'segundo nombre',
    gender: 'género',
    title: 'título',
    type: 'tipo',
    key: 'clave',
    opcode: 'código del operador',
    group: 'grupo',
    groupName: 'nombre del grupo',
    operatorName: 'nombre del operador',
    button: {
      url: 'URL',
      text: 'Texto'
    },
    DictionaryId: 'id del diccionario',
    mcc: 'mcc',
    mnc: 'mnc'
  }
}
