import Vue from 'vue'
import { mapGetters } from 'vuex'

import { Macro } from '@sigma-legacy-libs/cache'
import { DICTIONARIES } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices, globalErrorHandler, globalErrorProcessor, permissionPresets, services } from '@/utils'

import { dictionaryToService } from '@/components/services/dictionaries/utils'

import render from './render'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

function clearTimer(timer) {
  if (timer) {
    clearInterval(timer)
  }
}

export default {
  name: 'Template',

  mixins: [
    generateServices({
      name: services.dictionaries,

      find: {
        defaultFilter: {
          type: [],
          status: [],
          OwnerId: undefined
        },
        alwaysCreateFromWebSocket: true,
        alwaysUpdateFromWebSocket: true,
        alwaysRemoveFromWebSocket: true
      },
      create: false,
      remove: false
    })
  ],

  data() {
    return {
      showFileManager: false,
      showCompileDialog: false,

      importIsDelayed: false,
      importDelayTimer: undefined,

      currentDictionaryType: undefined,
      currentCompiledDictionary: undefined,
      currentCompiledDictionaryTotal: {},
      currentOperatorGroupsDictionary: undefined,
      currentRemoveDictionary: {},

      mix: false
    }
  },

  computed: {
    ...mapGetters({
      file: 'file/file',
      error: 'file/error'
    })
  },

  watch: {
    file: {
      handler({ data }) {
        this.uploadHandler(data.id)
      },
      deep: true
    },

    error() {
      this.showFileManager = false
    }
  },

  mounted() {
    Vue.$socket.on('progress.mnp', data => this.compileHandler(data))
    Vue.$socket.on('progress.prefixes', data => this.compileHandler(data))
    Vue.$socket.on('progress.operatorGroups', data => this.compileHandler(data))
    Vue.$socket.on('error.mnp', error => globalErrorHandler.call(this, globalErrorProcessor.call(this, error)))
    Vue.$socket.on('error.prefixes', error => globalErrorHandler.call(this, globalErrorProcessor.call(this, error)))
    Vue.$socket.on('error.operatorGroups', error => globalErrorHandler.call(this, globalErrorProcessor.call(this, error)))

    if (this.checkPermissions(`advanced.${services.dictionaries}.get`, permissionPresets.resellerUp)) {
      this.rest[services.dictionaries].find()
    }
  },

  beforeDestroy() {
    clearTimer(this.importDelayTimer)
  },

  methods: {
    cachedRequest: Cache.wrapWithCache(async (key, params) => {
      return await Vue.$GRequest.create(`/${params.service}/${params.operation}`, {
        id: params.id,
        OwnerId: params.OwnerId,
        mappings: params.mappings,
        mix: params.mix,
        value: params.value
      })
    }),

    async processDictionaryDataRequest(params = {}) {
      if (this.account.id !== '3e194b3e-a2ee-40ce-a986-17fdc918541e') {
        params.OwnerId = this.account.id
      }

      if (params.operation && params.dictionaryType) {
        const key = [ params.dictionaryType, params.operation, params.id, params.OwnerId, params.value ].filter(v => !!v).join(':')
        try {
          const result = await this.cachedRequest(key, Object.assign(params, { service: dictionaryToService(params.dictionaryType) }))

          if (result) {
            switch (params.operation) {
              case 'remove': {
                this.addSnackbar({
                  text: this.getTranslate(`${services.dictionaries}.snackbars.dictionary.removed`),
                  type: 'warning'
                })
                break
              }
              case 'set': {
                this.addSnackbar({
                  text: this.getTranslate(`${services.dictionaries}.snackbars.dictionary.updated`),
                  type: 'success'
                })
                break
              }
              case 'import': {
                this.importIsDelayed = true
                this.importDelayTimer = setInterval(() => {
                  this.importIsDelayed = false
                  clearTimer(this.importDelayTimer)
                }, 6 * 1000)
                this.addSnackbar({
                  text: this.getTranslate(`${services.dictionaries}.snackbars.dictionary.created`),
                  type: 'success'
                })
                this.mix = false
                break
              }
            }

            return result
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    },

    async removeOperatorGroupsDictionary(dictionary) {
      await this.processDictionaryDataRequest({
        operation: 'remove',
        dictionaryType: DICTIONARIES['phone:opgroups'],
        id: dictionary.id,
        OwnerId: dictionary.OwnerId
      })
      this.currentOperatorGroupsDictionary = undefined
    },

    async uploadHandler(id) {
      await this.processDictionaryDataRequest({
        operation: 'import',
        dictionaryType: this.currentDictionaryType,
        id,
        mix: this.mix
      })
      this.currentDictionaryType = undefined
      this.showFileManager = false
    },

    compileHandler(result) {
      Vue.set(this.currentCompiledDictionaryTotal, result.id, result.progress)
      if (result.finished) {
        Vue.delete(this.currentCompiledDictionaryTotal, result.id)
        this.addSnackbar({
          text: this.getTranslate(`${services.dictionaries}.snackbars.dictionary.compile`),
          type: 'success'
        })
      }
    },

    disableUploadButton(dictionaryType) {
      return this.restData[services.dictionaries].find.data.filter(({ type, OwnerId }) => {
        if (this.checkPermissions(`advanced.${services.dictionaries}.get`)) {
          return type === dictionaryType && OwnerId === null
        } else {
          return type === dictionaryType
        }
      }).length >= 3
    }
  },

  render
}
