export default {
  title: 'Reemplazo',

  labels: {
    fallback: 'Reenvío'
  },

  hints: {
    replacement: {
      original: 'Original',
      replace: 'Reemplazo',
      final: 'Resultado final'
    },
    fallbacks: 'No se especificaron reenvíos'
  },

  types: {
    type: 'Tipo',
    state: {
      moderation: 'Moderación'
    },
    payload: {
      sender: 'Remitente',
      text: 'Texto'
    },
    fallbacks: 'Reenvíos'
  }
}
