import { buttonModes, icons, services, states } from '@/utils'

import button from '@/components/button'
import fileItemCard from '@/components/file/item/card'
import fileManager from '@/components/file/manager'
import preloader from '@/components/preloader'
import settingsRow from '@/components/services/settings/row'

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'preloader__holder pa-5'
    },
    [ h(preloader) ]
  )
}
function renderDefaultState(h) {
  return h(
    settingsRow,
    {
      props: {
        title: this.title,
        label: this.hint,
        icon: this.icon
      },
      on: {
        click: () => {
          this.showFileManager = true
        }
      }
    }
  )
}
function renderFile(h) {
  if (this.restData[services.storage].get.data) {
    return h(
      'div',
      {
        class: 'grid grid-gap--4'
      },
      [
        h(
          'div',
          { class: 'caption text--grey pl-2' },
          [ this.title ]
        ),
        h(
          fileItemCard,
          {
            props: {
              value: this.restData[services.storage].get.data,
              readonly: true
            }
          },
          [
            h(
              button,
              {
                props: {
                  icon: icons.clear,
                  mode: buttonModes.flat
                },
                on: {
                  click: () => {
                    this.proxy = undefined
                  }
                }
              }
            )
          ]
        )
      ]
    )
  } else {
    return renderDefaultState.call(this, h)
  }
}
function renderBody(h) {
  switch (this.restData[services.storage].get.state) {
    case states.loading: return renderPreloader.call(this, h)
    case states.ready:
    default: return renderFile.call(this, h)
  }
}

function renderFileManager(h) {
  return h(
    fileManager,
    {
      props: {
        currentChosenFileId: this.proxy,
        flat: true,
        readonly: true,
        embedded: true,
        isPublic: true,
        show: this.showFileManager,
        types: [ 'doc', 'docx', 'pdf' ]
      },
      on: {
        show: event => {
          this.showFileManager = event
        },
        input: event => {
          this.proxy = event.id
          this.showFileManager = false
        },
        finish: event => {
          this.proxy = event.id
          this.showFileManager = false
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid'
    },
    [
      renderBody.call(this, h),
      renderFileManager.call(this, h)
    ]
  )
}
