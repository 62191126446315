import { components, inputModes, services } from '@/utils'

import providersProperties from '@/components/services/providers/properties'
import providersReplacements from '@/components/services/providers/replacements'
import routesFormLink from '@/components/services/routes/form/link'
import routesFormTitle from '@/components/services/routes/form/title'

function renderTitleField(h) {
  return h(
    routesFormTitle,
    {
      props: {
        value: this.proxy,
        serviceName: services.providers
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderServerField(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': '1fr auto' }
    },
    [
      h(
        components.select,
        {
          props: {
            value: this.proxy.ServerId,
            label: this.getTranslate(`${services.providers}.labels.server`),
            service: services.servers,
            picks: [ 'id', 'title', 'host', 'port' ],
            mode: inputModes['line-label'],
            dense: true,
            rounded: true,
            autocomplete: true,
            clearable: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.ServerId = event
            }
          },
          scopedSlots: {
            item: ({ item }) => {
              return h(
                'div',
                {
                  class: 'fc'
                },
                [
                  h('div', { class: 'medium' }, item.title),
                  h('div', { class: 'small text-overflow text--grey' }, [ item.host, item.port ].join(':'))
                ]
              )
            }
          }
        }
      ),

      h(
        routesFormLink,
        {
          props: {
            value: { id: this.proxy.ServerId },
            service: services.servers
          }
        }
      )
    ]
  )
}

function renderCredentialsField(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': '1fr auto' }
    },
    [
      h(
        components.select,
        {
          props: {
            value: this.proxy.CredentialsId,
            label: this.getTranslate(`${services.providers}.labels.credentials`),
            service: services.credentials,
            mode: inputModes['line-label'],
            dense: true,
            rounded: true,
            autocomplete: true,
            clearable: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.CredentialsId = event
            }
          }
        }
      ),

      h(
        routesFormLink,
        {
          props: {
            value: { id: this.proxy.CredentialsId },
            service: services.credentials
          }
        }
      )
    ]
  )
}

function renderOtherFields(h) {
  return h(
    'div',
    {
      class: 'grid'
    },
    [
      h(
        components.textarea,
        {
          props: {
            value: this.proxy.comment,
            label: this.getTranslate(`${services.providers}.labels.comment`),
            mode: inputModes['line-label'],
            grow: true,
            rounded: true,
            details: false,
            mask: /^[\s\S]{0,255}$/
          },
          on: {
            input: event => {
              this.proxy.comment = event
            }
          }
        }
      ),

      h(
        providersProperties,
        {
          props: {
            value: this.proxy.properties,
            processor: this.proxy.Server && this.proxy.Server.processor,
            ServerId: this.proxy.ServerId
          },
          on: {
            input: event => {
              this.proxy.properties = event
            }
          }
        }
      ),

      h(
        providersReplacements,
        {
          props: { value: this.proxy.replacements },
          on: {
            input: event => {
              this.proxy.replacements = event
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    components.form,
    {
      class: 'grid grid-gap--12'
    },
    [
      renderTitleField.call(this, h),
      renderServerField.call(this, h),
      renderCredentialsField.call(this, h),
      renderOtherFields.call(this, h)
    ]
  )
}
