import { colors, sizes } from '@/utils'

import { className } from '@/components/misc/rules/utils'
import tag from '@/components/tag'

function renderItem(h, { country, operator, fullCountry }) {
  if (country) {
    const label = [ country ]
    if (!fullCountry) {
      label.push(operator)
    }

    return h(
      tag,
      {
        props: {
          label: label.join(' | '),
          color: colors.primary,
          size: sizes.small
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${className}-item__holder-value`
    },
    this.values.reduce((result, item, index) => {
      if (index < this.count) {
        result.push(renderItem.call(this, h, item))
      }
      if (index === this.count && this.values.length > this.count) {
        result.push(
          h(
            tag,
            {
              props: {
                label: `+${this.values.length - this.count}`,
                color: colors.black,
                size: sizes.small
              }
            }
          )
        )
      }

      return result
    }, [])
  )
}
