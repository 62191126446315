import { components, inputModes, prioritizeAccountInList, services } from '@/utils'

import filterDate from '@/components/filter/date'
import filterDatePreset from '@/components/filter/date/preset'
import selectorOwner from '@/components/selector/owner'
import selectorSendingType from '@/components/selector/sendingType'

function renderSelectorOwner(h) {
  return h(
    selectorOwner,
    {
      props: {
        value: this.proxy.OwnerId,
        clearItems: prioritizeAccountInList,
        mode: inputModes.outline,
        dense: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}
function renderSelectorSendingType(h) {
  return h(
    selectorSendingType,
    {
      props: {
        value: this.proxy.type,
        label: this.getTranslate(`${services.sendings}.filter.type`),
        permission: `advanced.${services.sendingsIncoming}.get`,
        multiple: true
      },
      on: {
        input: event => {
          this.proxy.type = event
        }
      }
    }
  )
}
function renderFromField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.from,
        label: this.getTranslate(`${services.sendings}.filter.from`),
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.from = event
        }
      }
    }
  )
}
function renderFilterDatePreset(h) {
  return h(
    filterDatePreset,
    {
      props: {
        value: this.proxy.createdAtPreset,
        mode: inputModes.outline
      },
      on: {
        input: event => {
          if (event) {
            this.proxy.createdAt = {
              $gt: undefined,
              $lt: undefined
            }
          }

          this.proxy.createdAtPreset = event
        }
      }
    }
  )
}
function renderFilterDate(h) {
  return h(
    filterDate,
    {
      props: {
        value: this.proxy.createdAt
      },
      on: {
        input: event => {
          if (event) {
            this.proxy.createdAtPreset = undefined
          }

          this.proxy.createdAt = event
        }
      }
    }
  )
}

function renderColumn(h, content) {
  return h(
    'div',
    {
      class: 'column'
    },
    [ content ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        column: true,
        'grid-cols--3': this.viewport.breakpoint.lgUp,
        'grid-cols--2': this.viewport.breakpoint.md
      }
    },
    [
      renderColumn(h, renderSelectorOwner.call(this, h)),
      renderColumn(h, [ renderSelectorSendingType.call(this, h), renderFromField.call(this, h) ]),
      renderColumn(h, [ renderFilterDatePreset.call(this, h), renderFilterDate.call(this, h) ])
    ]
  )
}
