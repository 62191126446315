import { cloneDeep, get } from 'lodash'

import { getPrice } from '@sigma-frontend-libs/commons'
import { BILLING_CURRENCY } from '@sigma-legacy-libs/essentials/lib/constants'

import { DEFAULT_DOCUMENT_TEMPLATES_INVOICE_PAYLOAD, buttonModes, colors, components, icons, inputModes, popperPlacements, services, sizes } from '@/utils'

import button from '@/components/button'
import info from '@/components/info'
import InvoiceDialog from '@/components/services/notifications/dialog/balance/limit/InvoiceDialog'
import tag from '@/components/tag'

const path = 'notifications.events.balance.limit'

function renderEmptyState(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${services.notifications}.hints.${path}.empty`)
      }
    }
  )
}

function renderLimitTag(h, { balance, includeOverdraft }) {
  return h(
    tag,
    {
      props: {
        label: this.getLabel(balance, includeOverdraft, path)
      }
    }
  )
}
function renderLimitField(h, limit) {
  let suffix
  if (path === this.path) {
    suffix = this.getTranslate(`currency.${this.currency}.symbol.unicode`)
  }

  return h(
    components['text-field'],
    {
      props: {
        value: limit.balance,
        label: this.getTranslate(`${services.settingsUser}.labels.${path}.limit`),
        defaultValue: 0,
        clearable: true,
        autofocus: true,
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        suffix,
        type: 'number',
        step: 'any'
      },
      on: {
        input: event => {
          limit.balance = event
        }
      }
    }
  )
}
function renderLimitButton(h, limit, index) {
  return h(
    components.menu,
    {
      props: {
        closeOnContentClick: false,
        transparent: true,
        maxWidth: 150,
        offsetDistance: 8,
        placement: popperPlacements.right
      },
      key: index
    },
    [
      h(
        button,
        {
          props: {
            icon: icons.edit,
            mode: buttonModes.flat,
            size: sizes.small
          },
          slot: 'activator'
        }
      ),

      renderLimitField.call(this, h, limit)
    ]
  )
}
function renderPanelHeader(h, limit, index) {
  return h(
    'div',
    {
      class: `${services.notifications}-events-balance-limit__panel-header`
    },
    [
      renderLimitTag.call(this, h, limit),
      renderLimitButton.call(this, h, limit, index)
    ]
  )
}
function renderOverdraftButton(h, limit) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate(`${services.settingsUser}.labels.${path}.${limit.includeOverdraft ? 'include' : 'notInclude'}.overdraft`),
        mode: buttonModes.flat,
        size: sizes.tiny,
        color: limit.includeOverdraft ? colors.success : colors.error
      },
      on: {
        click: () => {
          limit.includeOverdraft = !limit.includeOverdraft
        }
      }
    }
  )
}
// NOTE: Так как счета мы пока не умеем выставлять
// ни в какой другой валюте кроме RUB
// то и валюта суммы счета всегда RUB
function renderInvoiceButton(h, limit, index) {
  if (this.currency === BILLING_CURRENCY.RUB) {
    let label = ''
    if (limit.invoice) {
      const amount = get(limit, 'invoice.data.SERVICE_PRICE')
      if (amount) {
        label = this.getTranslate(`${services.settingsUser}.labels.${path}.include.invoice`, { amount: getPrice(amount, { currency: BILLING_CURRENCY.RUB }) })
      }
    } else {
      label = this.getTranslate(`${services.settingsUser}.buttons.${path}.invoice.attach`)
    }

    return h(
      button,
      {
        props: {
          label,
          mode: buttonModes.flat,
          size: sizes.tiny,
          color: limit.invoice ? colors.warning : colors.primary
        },
        on: {
          click: () => {
            if (!limit.invoice) {
              limit.invoice = cloneDeep(DEFAULT_DOCUMENT_TEMPLATES_INVOICE_PAYLOAD)
              this.index = index
              this.showInvoiceDialog = true
            } else {
              limit.invoice = false
              this.index = index
            }
          }
        }
      }
    )
  }
}
function renderPanelFooterButtons(h, limit, index) {
  return h(
    'div',
    {
      class: `${services.notifications}-events-balance-limit__panel-footer-buttons`
    },
    [
      renderOverdraftButton.call(this, h, limit),
      renderInvoiceButton.call(this, h, limit, index)
    ]
  )
}
function renderPanelRemoveButton(h, index) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.remove'),
            mode: buttonModes.flat,
            size: sizes.tiny,
            color: colors.error
          },
          on: {
            click: () => {
              this.remove(index)
            }
          }
        }
      )
    ]
  )
}
function renderPanelFooter(h, limit, index) {
  return h(
    'div',
    {
      class: `${services.notifications}-events-balance-limit__panel-footer`
    },
    [
      renderPanelFooterButtons.call(this, h, limit, index),
      renderPanelRemoveButton.call(this, h, index)
    ]
  )
}
function renderPanel(h, limit, index) {
  return h(
    'div',
    {
      class: `${services.notifications}-events-balance-limit__panel`,
      key: index
    },
    [
      renderPanelHeader.call(this, h, limit, index),
      renderPanelFooter.call(this, h, limit, index)
    ]
  )
}
function renderPanels(h) {
  if (Array.isArray(this.proxy)) {
    if (this.proxy.length) {
      return h(
        'div',
        {
          class: `${services.notifications}-events-balance-limit__panels`
        },
        [
          this.proxy.map((limit, index) => {
            return renderPanel.call(this, h, limit, index)
          })
        ]
      )
    } else {
      return renderEmptyState.call(this, h)
    }
  }
}

function renderAddLimitButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.add'),
        mode: buttonModes.flat,
        size: sizes.small
      },
      on: {
        click: () => {
          this.add()
        }
      }
    }
  )
}
function renderSaveLimitButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.save'),
        mode: buttonModes.flat,
        size: sizes.small,
        color: colors.primary
      },
      on: {
        click: () => {
          this.$emit('input', this.proxy)
        }
      }
    }
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8'
    },
    [
      renderAddLimitButton.call(this, h),
      renderSaveLimitButton.call(this, h)
    ]
  )
}

function renderInvoiceDialog(h) {
  if (Array.isArray(this.proxy) && this.proxy.length) {
    if (this.proxy[this.index].invoice) {
      return h(
        InvoiceDialog,
        {
          props: {
            value: this.proxy[this.index].invoice,
            show: this.showInvoiceDialog,
            OwnerId: this.UserId,
            ResellerId: this.ResellerId
          },
          key: this.index,
          on: {
            input: event => {
              this.proxy[this.index].invoice = event
            },
            show: ({ event, validation }) => {
              this.showInvoiceDialog = event
              if (!event && !validation) {
                this.proxy[this.index].invoice = false
              }
            }
          }
        }
      )
    }
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderPanels.call(this, h),
      renderFooter.call(this, h),
      renderInvoiceDialog.call(this, h)
    ]
  )
}
