export default {
  title: 'Grupos de reglas',

  one: 'Grupo',
  many: 'Grupos',

  titles: {
    create: 'Crear grupo de reglas de procesamiento de mensajes'
  },

  labels: {
    title: 'Título',
    search: 'Buscar',
    rule: 'Regla',
    group: 'Grupo'
  },

  dialogs: {
    new: 'Nuevo grupo de reglas de procesamiento de mensajes',
    types: {
      full: 'Completo',
      direct: 'Directo'
    },
    title: {
      full: 'Grupo completo',
      direct: 'Grupo directo'
    }
  },

  hints: {
    notFound: 'No se encontraron grupos de reglas de procesamiento de mensajes'
  },

  contents: {
    confirm: {
      remove: '¿Confirmar eliminación de {title}?'
    }
  },

  tooltips: {
    isActive: 'Activar o desactivar el grupo de reglas de procesamiento de mensajes.',
    favorites: 'Agregar el grupo de reglas de procesamiento de mensajes a favoritos.',
    active: 'Activo',
    notActive: 'Inactivo'
  },

  subtitles: {
    rules: 'Reglas',
    groups: 'Reglas de procesamiento de mensajes'
  },

  buttons: {
    add: 'Agregar grupo de reglas',
    messageRuleCreate: 'Crear regla'
  },

  snackbars: {
    created: 'Grupo de reglas de procesamiento de mensajes creado con éxito.',
    updated: 'Grupo de reglas de procesamiento de mensajes actualizado con éxito.',
    removed: 'Grupo de reglas de procesamiento de mensajes eliminado con éxito.'
  }
}
