import { colors, icons, supportTypes } from '@/utils'

export const supportsColors = {
  [supportTypes.phones]: colors.success,
  [supportTypes.emails]: colors.primary,
  [supportTypes.websites]: colors.epic,
  [supportTypes.socials]: colors.legendary
}

export const supportsIcons = {
  [supportTypes.phones]: icons.call,
  [supportTypes.emails]: icons.email,
  [supportTypes.websites]: icons.home,
  [supportTypes.socials]: icons.groups
}
