import Vue from 'vue'
import { mapGetters } from 'vuex'

import { get } from 'lodash'

import { DOCUMENT_TEMPLATES_TYPES, REST_ACTIONS } from '@sigma-legacy-libs/essentials/lib/constants'

import { fillDependence, generateServices, getUrlToStorage, globalErrorHandler, globalErrorProcessor, permissionPresets, services, states } from '@/utils'

import render from './render'

export default {
  name: 'Selector',

  mixins: [
    generateServices({
      name: services.documentTemplates,

      async inputFilter(result) {
        if (!result.File) {
          await fillDependence.call(this, result, {
            service: services.storage,
            permissions: permissionPresets.meUp,
            pathToId: 'FileId',
            pathToObject: 'File',
            outputPath: 'File',
            requestParams: {
              query: {
                return: 'meta'
              }
            }
          })
        }

        return result
      },

      find: {
        defaultFilter: {
          CompanyId: undefined
        },
        alwaysCreateFromWebSocket: true,
        alwaysUpdateFromWebSocket: true,
        alwaysRemoveFromWebSocket: true
      },
      update: false,
      remove: {
        redirect: false
      }
    })
  ],

  props: {
    CompanyId: String,

    type: {
      type: String,
      default: DOCUMENT_TEMPLATES_TYPES.contract
    }
  },

  data() {
    return {
      showFileManager: false,
      showEditFileDialog: false,

      editFileProgress: false,
      uploadFileProgress: false,
      createDocumentProgress: false,

      document: undefined,

      googleFileURL: undefined,

      currentType: undefined
    }
  },

  computed: {
    ...mapGetters({ file: 'file/file' }),

    loading() {
      for (const action of REST_ACTIONS) {
        if (this.restData[services.documentTemplates][action]?.state === states.loading) {
          return true
        }
      }

      return false
    },

    title() {
      if (this.document) {
        return this.getTranslate(`${services.documentTemplates}.titles.${this.type}`)
      }

      return this.getTranslate(`${services.documentTemplates}.titles.empty.${this.type}`)
    },

    fileId() {
      return get(this.document, 'File.id')
    },
    fileTitle() {
      return get(this.document, 'File.title')
    }
  },

  watch: {
    file: {
      async handler({ data }) {
        if (this.currentType === this.type && !this.createDocumentProgress) {
          await this.createDocumentTemplate(data.id)
          this.showFileManager = false
        }
      },
      deep: true
    },

    CompanyId() {
      this.setDocument()
    }
  },

  mounted() {
    this.setDocument()
  },

  methods: {
    async setDocument() {
      const data = await this.rest[services.documentTemplates].find({ query: { CompanyId: this.CompanyId } })
      if (Array.isArray(data) && data.length) {
        this.document = data.find(({ type }) => type === this.type)
      }
    },

    async createDocumentTemplate(id) {
      if (id && !this.document) {
        try {
          this.createDocumentProgress = true
          const response = await this.rest[services.documentTemplates].create({
            FileId: id,
            CompanyId: this.CompanyId,
            type: this.type
          })

          if (response) {
            this.setDocument()
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.createDocumentProgress = false
        }
      }
    },

    async editFile() {
      try {
        this.editFileProgress = true
        const query = {}
        const { File } = this.document || {}
        if (this.checkPermissions(`advanced.${services.storage}.update`)) {
          query.OwnerId = get(File, 'Owner.id', File.OwnerId)
        }
        const { data } = await Vue.$GRequest.get(`/${services.storage}/edit`, File.id, { query })
        if (data) {
          this.googleFileURL = data.result
          this.showEditFileDialog = true
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.editFileProgress = false
      }
    },

    async saveFile() {
      try {
        this.uploadFileProgress = true
        const { data } = await Vue.$GRequest.remove(`/${services.storage}/edit/`, this.document.File.id)
        if (data.result) {
          this.googleFileURL = undefined
          this.showEditFileDialog = false
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.uploadFileProgress = false
      }
    },

    downloadFile(File) {
      const OwnerId = get(File, 'Owner.id', File.OwnerId)
      window.location.href = getUrlToStorage([ OwnerId, File.id ])
    },

    async removeFile() {
      try {
        const { id } = await this.rest[services.documentTemplates].remove(this.document.id)
        if (id) {
          this.document = undefined
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    }
  },

  render
}
