const sources = {
  utm_source: 'Fuente de la campaña ({param})',
  utm_medium: 'Tipo ({param})',
  utm_campaign: 'Nombre de la campaña ({param})',
  utm_content: 'Identificador del anuncio ({param})',
  utm_term: 'Palabra clave ({param})'
}

export default {
  title: 'Generador de etiquetas UTM',

  titles: {
    generate: 'Generar etiqueta UTM',

    params: {
      required: 'Parámetros obligatorios',
      optional: 'Parámetros opcionales'
    },

    ...sources
  },

  labels: {
    protocol: 'Protocolo',
    url: 'Dirección de su página',

    ...sources,

    custom: 'Valores personalizados',
    google: 'Google Ads',
    yandex: 'Yandex.Direct',
    vk: 'Vkontakte',

    links: {
      full: 'Enlace completo',
      short: 'Enlace corto'
    }
  },

  hints: {
    custom: {
      utm_source: 'Plataforma publicitaria',
      utm_medium: 'Tipo de campaña o publicidad',
      utm_campaign: 'Permite distinguir campañas en estadísticas',
      utm_content: 'Parámetro adicional para seguimiento',
      utm_term: 'Palabra con la que comenzó la visualización del anuncio'
    },
    google: {
      utm_source: 'Plataforma publicitaria',
      utm_medium: 'Tipo de campaña o publicidad',
      utm_campaign: 'En lugar de {network}, Google Ads insertará "g" (búsqueda), "s" (socio de búsqueda) o "d" (GDN)',
      utm_content: 'En lugar de {creative}, Google Ads insertará el ID del anuncio',
      utm_term: 'En lugar de {keyword}, Google Ads insertará la palabra clave'
    },
    yandex: {
      utm_source: 'Plataforma publicitaria',
      utm_medium: 'Tipo de campaña o publicidad',
      utm_campaign: 'En lugar de {campaign_id}, Yandex.Direct insertará el ID de la campaña',
      utm_content: 'En lugar de {ad_id}, Yandex.Direct insertará el ID del anuncio',
      utm_term: 'En lugar de {keyword}, Yandex.Direct insertará la palabra clave'
    },
    vk: {
      utm_source: 'Plataforma publicitaria',
      utm_medium: 'Tipo de campaña o publicidad',
      utm_campaign: 'En lugar de {campaign_id}, Vkontakte insertará el ID del anuncio',
      utm_content: 'En lugar de {ad_id}, Vkontakte insertará el ID del anuncio',
      utm_term: 'Palabra con la que comenzó la visualización del anuncio'
    }
  },

  references: {
    utm_source: '<strong>utm_source</strong> — nombre de la plataforma publicitaria.<br/><br/>\
                <strong>¿Para qué sirve?</strong><br/>Para indicar el nombre de la fuente de tráfico.<br/><br/>\
                <strong>Ejemplos:</strong>\
                <ul class="ma-0">\
                  <li>utm_source=google – publicidad contextual en Google Ads.</li>\
                  <li>utm_source=yandex — publicidad contextual en Yandex.Direct.</li>\
                  <li>utm_source=vk — publicidad contextual en Vkontakte.</li><ul/>',

    utm_medium: '<strong>utm_medium</strong> — tipo de publicidad.<br/><br/>\
                En este parámetro se recomienda usar valores establecidos, por ejemplo:\
                cpc (cost per click) — publicidad contextual, display — publicidad gráfica con pago por impresiones,\
                social_cpc — publicidad en redes sociales con pago por clic.<br/><br/>\
                <strong>¿Para qué sirve?</strong><br/>Para determinar el tipo de campaña o publicidad.<br/><br/>\
                <strong>Ejemplos:</strong>\
                <ul class="ma-0">\
                  <li>utm_medium=organic – transición gratuita.</li>\
                  <li>utm_medium=cpc – publicidad contextual (cost per click, pago por clic).</li>\
                  <li>utm_medium=email — boletín.</li>\
                  <li>utm_medium=social — redes sociales.</li>\
                  <li>utm_medium=banner — publicidad gráfica.</li>\
                  <li>utm_medium=cpa — otra publicidad (cost per action, pago por acción).</li><ul/>',

    utm_campaign: '<strong>utm_campaign</strong> — nombre de la campaña.<br/><br/>\
                  Este parámetro obligatorio se puede establecer de forma arbitraria.<br/><br/>\
                  <strong>¿Para qué sirve?</strong><br/>Le permitirá distinguir una campaña publicitaria de otra en las estadísticas.<br/><br/>\
                  <strong>Ejemplos:</strong>\
                  <ul class="ma-0">\
                  <li>utm_campaign=muebles_para_el_hogar – campaña publicitaria de muebles para el hogar.</li><ul/>',

    utm_content: '<strong>utm_content</strong> — información adicional que se puede rastrear si coinciden otros parámetros.<br/><br/>\
                  <strong>¿Para qué sirve?</strong></br>A menudo se usa como una nota para el anuncio dentro de la campaña publicitaria.\
                  El nombre se puede establecer de forma arbitraria, es más conveniente usar características importantes del anuncio —\
                  subcategoría del producto o servicio, tipo de anuncio, etc..<br/><br/>\
                  <strong>Ejemplos:</strong>\
                  <ul class="ma-0">\
                  <li>utm_content=leadmagnet_webinar0404 — publicidad de un producto en un lead magnet que se ofreció a los usuarios en un webinar el 4 de abril.</li><ul/>',

    utm_term: '<strong>utm_term</strong> — palabra clave con la que comenzó la visualización del anuncio.<br/><br/>\
              Este parámetro obligatorio se puede establecer de forma arbitraria.<br/><br/>\
              <strong>¿Para qué sirve?</strong></br>Le permitirá distinguir una campaña publicitaria de otra en las estadísticas.<br/><br/>\
              <strong>Ejemplos:</strong>\
              <ul class="ma-0">\
              <li>utm_term=comprar_zapatillas — anunciando zapatillas al usuario.</li><ul/>'
  },

  tooltips: {
    generate: 'Generar etiqueta UTM',
    reference: 'Referencia para {param}'
  },

  snackbars: {
    error: 'No se pudo crear el enlace corto'
  }
}
