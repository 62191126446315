import { get } from 'lodash'

import { services } from '@/utils'

import entityTable from '@/components/entity/table'
import arrow from '@/components/misc/arrow'

function renderItem(h, item) {
  return h(
    entityTable,
    {
      props: {
        rows: [
          {
            title: this.getTranslate(`${services.requisites}.labels.data.personal.firstName`),
            value: [
              get(item, 'contact.firstName'),
              get(item, 'contact.middleName'),
              get(item, 'contact.lastName')
            ].filter(v => !!v).join(' ')
          },
          {
            title: this.getTranslate(`${services.requisites}.labels.data.legal.name`),
            copy: true,
            value: get(item, 'data.legal.name')
          },
          {
            title: this.getTranslate(`${services.requisites}.labels.data.legal.inn`),
            copy: true,
            value: get(item, 'data.legal.inn')
          }
        ]
      }
    }
  )
}

function renderItems(h) {
  if (this.items.length) {
    return h(
      'div',
      {
        class: 'py-2'
      },
      [ this.items.map(item => renderItem.call(this, h, item)) ]
    )
  } else {
    return h('g-empty')
  }
}

export default function(h) {
  return h(
    'g-expansion-panels',
    {
      props: {
        outline: true,
        rounded: true
      }
    },
    [
      h(
        'g-expansion-panel',
        {
          scopedSlots: {
            header: ({ expanded }) => {
              return h(
                'div',
                {
                  class: 'default-expansion-panel-header'
                },
                [
                  this.getTranslate(`${services.requisites}.title`),
                  h(arrow, { props: { expanded } })
                ]
              )
            },
            default: () => renderItems.call(this, h)
          }
        }
      )
    ]
  )
}
