import { colors, components, services, sizes } from '@/utils'

import ruleConditions from '@/components/services/tariffs/rules/rule/conditions'
import rulePrices from '@/components/services/tariffs/rules/rule/prices'
import tag from '@/components/tag'

function renderTitle(h, index, isDefault) {
  let labelPath = 'rule'
  if (this.hasRules) {
    if (isDefault) {
      labelPath = 'otherCases'
    }
  } else {
    if (isDefault) {
      labelPath = 'always'
    }
  }

  return h(
    tag,
    {
      props: {
        label: this.getTranslate(`${services.tariffs}.${labelPath}`, { rule: index + 1 }),
        size: sizes.huge,
        color: colors.grey
      }
    }
  )
}
function renderAction(h, { action }) {
  return h(
    tag,
    {
      props: {
        label: this.getTranslate(`${services.tariffs}.actions.${action}`),
        size: sizes.huge,
        color: action === 'decrease' ? colors.error : colors.success
      }
    }
  )
}
function renderPrices(h, { price }) {
  return h(
    rulePrices,
    {
      props: {
        value: price,
        source: this.source,
        readonly: true
      }
    }
  )
}
function renderRuleHeader(h, rule, index, isDefault = false) {
  return h(
    'div',
    {
      class: `${services.tariffs}-rule__header`
    },
    [
      renderTitle.call(this, h, index, isDefault),
      renderAction.call(this, h, rule),
      renderPrices.call(this, h, rule)
    ]
  )
}
function renderRuleConditions(h, conditions) {
  return h(
    ruleConditions,
    {
      props: {
        value: conditions,
        type: this.source,
        readonly: true
      }
    }
  )
}
function renderRuleBody(h, { rules }) {
  if (Array.isArray(rules) && rules.length) {
    return h(
      'div',
      {
        class: `${services.tariffs}-rule__body`
      },
      [
        rules.map(({ conditions }) => {
          return renderRuleConditions.call(this, h, conditions)
        })
      ]
    )
  }
}
function renderRule(h, rule, index, isDefault = false) {
  if (rule.action && rule.price) {
    return h(
      'div',
      {
        class: `${services.tariffs}-rule`
      },
      [
        renderRuleHeader.call(this, h, rule, index, isDefault),
        renderRuleBody.call(this, h, rule)
      ]
    )
  }
}
function renderRules(h) {
  if (this.hasRules) {
    return this.rules.map((rule, index) => {
      return renderRule.call(this, h, rule, index)
    })
  }
}
function renderDefaultRule(h) {
  if (this.defaultRule) {
    return renderRule.call(this, h, this.defaultRule, 0, true)
  }
}
function renderBody(h) {
  return h(
    'div',
    {
      class: `${services.tariffs}-rules__body`
    },
    [
      renderRules.call(this, h),
      renderDefaultRule.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    components['expansion-panels'],
    {
      props: {
        rounded: true,
        outline: true
      }
    },
    [
      h(
        components['expansion-panel'],
        {
          scopedSlots: {
            header: () => {
              return h(
                'div',
                {
                  class: `${services.tariffs}-rules__panel`
                },
                [ this.getTranslate(`${services.tariffs}.titles.rules`) ]
              )
            },
            default: () => renderBody.call(this, h)
          }
        }
      )
    ]
  )
}
