import { getPrice } from '@sigma-frontend-libs/commons'
import { BILLING_CURRENCY } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, icons, permissionPresets, services } from '@/utils'

import sticker from '@/components/misc/sticker'
import balance from '@/components/services/invoices/item/balance'
import buttons from '@/components/services/invoices/item/buttons'
import { statusColors, statusIcons } from '@/components/services/invoices/utils'
import date from '@/components/templates/service/item/date'
import owner from '@/components/templates/service/item/owner'

function renderDocNumber(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        pathToTitle: 'docNumber',
        label: this.getTranslate(`${services.invoices}.labels.docNumber`),
        icon: statusIcons[this.value.status],
        color: statusColors[this.value.status],
        service: services.invoices
      }
    }
  )
}

// NOTE: Так как счета мы пока не умеем выставлять
// ни в какой другой валюте кроме RUB
// то и валюта суммы счета всегда RUB
function renderAmount(h) {
  return h(
    sticker,
    {
      props: {
        value: getPrice(this.value.amount, { currency: BILLING_CURRENCY.RUB }),
        label: this.getTranslate(`${services.invoices}.labels.amount`),
        color: colors.grey,
        iconSVG: BILLING_CURRENCY.RUB
      }
    }
  )
}
function renderRequisite(h) {
  if (this.viewport.size.width > 600) {
    return h(
      sticker,
      {
        props: {
          value: this.value.requisite || this.value.requisiteId,
          label: this.getTranslate(`${services.invoices}.labels.${services.requisites}`),
          icon: icons.feed,
          color: colors.grey,
          service: services.requisites,
          readonly: !this.checkPermissions(`advanced.${services.requisites}.get`, permissionPresets.managerUp)
        }
      }
    )
  }
}
function renderCompany(h) {
  if (this.viewport.size.width > 600) {
    return h(
      sticker,
      {
        props: {
          value: this.value.company || this.value.companyId,
          label: this.getTranslate(`${services.invoices}.labels.company`),
          icon: icons.work_outline,
          color: colors.grey,
          service: services.companies,
          readonly: !this.checkPermissions(`advanced.${services.companies}.get`, permissionPresets.managerUp)
        }
      }
    )
  }
}

function renderCustomer(h) {
  if (this.hasCustomer) {
    return h(
      owner,
      {
        props: {
          value: this.value,
          pathToData: 'customer',
          label: this.getTranslate(`${services.invoices}.labels.customer`),
          copy: true
        }
      }
    )
  }
}
function renderOwner(h) {
  if (this.hasOwner) {
    return h(
      owner,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

// NOTE: Так как счета мы пока не умеем выставлять
// ни в какой другой валюте кроме RUB
// то и проверка баланса пользователя всегда в RUB
function renderBalance(h) {
  if (this.value.customerId) {
    return h(
      balance,
      {
        props: {
          UserId: this.value.customerId,
          currency: BILLING_CURRENCY.RUB
        }
      }
    )
  }
}
function renderAdminInfo(h) {
  if (this.isAdminAndMdUp) {
    return h(
      'div',
      {
        class: {
          [`${services.invoices}-item__info-admin`]: true,
          [`${services.invoices}-item__info-admin--owner`]: this.hasOwner
        }
      },
      [
        renderCustomer.call(this, h),
        renderOwner.call(this, h),
        renderBalance.call(this, h)
      ]
    )
  }
}

function renderInfo(h) {
  return h(
    'div',
    {
      class: {
        [`${services.invoices}-item__info`]: true,
        [`${services.invoices}-item__info--admin`]: this.isAdminAndMdUp
      }
    },
    [
      renderDocNumber.call(this, h),
      renderAmount.call(this, h),
      renderRequisite.call(this, h),
      renderCompany.call(this, h),
      renderAdminInfo.call(this, h)
    ]
  )
}

function renderButtons(h) {
  return h(
    buttons,
    {
      props: {
        value: this.value,
        view: 'item'
      }
    }
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        ['service-template-item']: true,
        ['hover-parent hover-parent--opacity']: this.viewport.breakpoint.mdUp,
        [`${services.invoices}-item`]: true
      }
    },
    [
      renderInfo.call(this, h),
      renderButtons.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
