import squircle from '@/components/icon/squircle'
import svgIcon from '@/components/icon/svg'

function renderIconContent(h) {
  if (this.iconSVG) {
    return h(
      squircle,
      {
        props: {
          color: this.color
        }
      },
      [
        h(
          svgIcon,
          {
            props: {
              value: this.iconSVG,
              color: this.color,
              size: 20
            }
          }
        )
      ]
    )
  } else {
    return h(
      squircle,
      {
        props: {
          icon: this.icon,
          color: this.color
        }
      }
    )
  }
}
function renderIcon(h) {
  return h(
    'div',
    {
      class: 'info__icon'
    },
    [ renderIconContent.call(this, h) ]
  )
}

function renderContent(h) {
  if (this.$slots.default) {
    return this.$slots.default
  }

  return h(
    'div',
    {
      class: 'info__text',
      domProps: { innerHTML: this.value }
    }
  )
}

export default function(h) {
  if (this.hasContent) {
    return h(
      'div',
      {
        class: this.class
      },
      [
        renderIcon.call(this, h),
        renderContent.call(this, h)
      ]
    )
  }
}
