import { cloneDeep, isEmpty, merge } from 'lodash'

import { dateFilter } from '@/utils'

import { tasksFilter } from '@/components/services/tasks/utils'

import render from './render'

export default {
  name: 'TasksFilter',

  mixins: [ dateFilter ],

  props: {
    type: {
      type: [ Array, String ],
      default: () => []
    }
  },

  mounted() {
    if (!isEmpty(this.$route.params)) {
      this.proxy.createdAt = this.$route.params.createdAt
    }
  },

  methods: {
    dropDateFilter(filter) {
      this.proxy[filter] = {
        $gt: undefined,
        $lt: undefined
      }
    },

    getDefaultFilter() {
      const replacedFilter = {}
      if (~this.type.indexOf('sendings:bulk')) {
        replacedFilter.ListId = undefined
        replacedFilter.data = {
          sending: {
            type: undefined
          }
        }
      }

      return merge({}, cloneDeep(tasksFilter), { type: this.type }, replacedFilter)
    }
  },

  render
}
