import Vue from 'vue'
import { mapActions } from 'vuex'

import { backendServerIP, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

export default {
  name: 'RegistrationTemplate',

  data() {
    return {
      payload: {
        username: undefined,
        password: undefined,
        data: {
          firstName: undefined,
          lastName: undefined,
          email: undefined,
          phone: undefined,
          meta: {
            source: window.location.hostname,
            salon_id: undefined,
            usedReferralCode: undefined
          }
        }
      },

      loading: false,
      formValidation: false,

      errors: {}
    }
  },

  watch: {
    $route: {
      handler: 'setMeta',
      deep: true
    }
  },

  mounted() {
    this.setMeta()
  },

  beforeDestroy() {
    window.history.replaceState(null, null, window.location.pathname)
  },

  methods: {
    ...mapActions({ login: 'login/login' }),

    async registration() {
      if (this.formValidation) {
        try {
          this.loading = true

          const response = await fetch(`${backendServerIP}/api/registration`, {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(this.payload)
          })

          if (!response.ok) {
            try {
              const error = await response.json()
              switch (error.code || error.error) {
                case 422: {
                  if (Array.isArray(error.data) && error.data.length) {
                    for (const item of error.data) {
                      const translate = this.getTranslate(`registration.errors.${item.field}`)
                      Vue.set(this.errors, item.field, translate || item.message)
                      this.addSnackbar({
                        text: translate || item.message,
                        type: 'error'
                      })
                    }
                  }
                  break
                }
                default: {
                  globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
                }
              }

              return
            } catch (error) {
              throw new Error(response.status)
            }
          }

          const { id } = await response.json()
          if (id) {
            this.login({
              username: this.payload.username,
              password: this.payload.password
            })
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
        }
      }
    },

    setMeta() {
      const search = window.location.search.replace('?', '').split('&')
      for (const s of search) {
        const [ key, value ] = s.split('=')
        switch (key) {
          case 'ref': {
            this.payload.data.meta.usedReferralCode = value
            break
          }
          case 'source':
          case 'salon_id': {
            this.payload.data.meta[key] = value
            break
          }
        }
      }
    }
  },

  render
}
