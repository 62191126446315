import { permissionPresets, services } from '@/utils'

import Account from '@/pages/Account'
import Admin from '@/pages/Admin'
import Billings from '@/pages/Billings'
import Companies from '@/pages/Companies'
import Contacts from '@/pages/Contacts'
import Contracts from '@/pages/Contracts'
import Credentials from '@/pages/Credentials'
import Dashboard from '@/pages/Dashboard'
import Debug from '@/pages/Debug'
import Dictionaries from '@/pages/Dictionaries'
import Documentation from '@/pages/Documentation'
import Documents from '@/pages/Documents'
import EventsHistory from '@/pages/EventsHistory'
import Files from '@/pages/Files'
import Invoices from '@/pages/Invoices'
import KeywordLists from '@/pages/KeywordLists'
import Login from '@/pages/Login'
import MessageProcessing from '@/pages/MessageProcessing'
import MessageRuleGroups from '@/pages/MessageRuleGroups'
import MessageRuleTemplates from '@/pages/MessageRuleTemplates'
import Notifications from '@/pages/Notifications'
import Other from '@/pages/Other'
import OTP from '@/pages/OTP'
import OTPChannels from '@/pages/OTP/channels'
import OTPTemplates from '@/pages/OTP/templates'
import OTPWidgets from '@/pages/OTP/widgets'
import Patterns from '@/pages/Patterns'
import Pay from '@/pages/Pay'
import Payments from '@/pages/Payments'
import Playground from '@/pages/Playground'
import ProviderGroups from '@/pages/ProviderGroups'
import Providers from '@/pages/Providers'
import Recover from '@/pages/Recover'
import Registration from '@/pages/Registration'
import Requisites from '@/pages/Requisites'
import Routes from '@/pages/Routes'
import RoutingGroups from '@/pages/RoutingGroups'
import RoutingRules from '@/pages/RoutingRules'
import SenderNames from '@/pages/SenderNames'
import Sendings from '@/pages/Sendings'
import SendingsCreate from '@/pages/SendingsCreate'
import SendingsModeration from '@/pages/SendingsModeration'
import Servers from '@/pages/Servers'
import Settings from '@/pages/Settings'
import Support from '@/pages/Support'
import Tariffs from '@/pages/Tariffs'
import Tokens from '@/pages/Tokens'
import Users from '@/pages/Users'
import YClients from '@/pages/YClients'

const otpSettingsWidgetsViaPoint = services['otp-settings/widgets'].split('/').join('.')
const otpSettingsChannelsViaPoint = services['otp-settings/channels'].split('/').join('.')
const otpSettingsTemplatesViaPoint = services['otp-settings/templates'].split('/').join('.')

let otpSettingsPermissions = [
  `advanced.${otpSettingsWidgetsViaPoint}.get`,
  `advanced.${otpSettingsChannelsViaPoint}.create`,
  `advanced.${otpSettingsTemplatesViaPoint}.create`
]
const otpSettingsPermissionsAccept = permissionPresets.meUp
if ([ 'stage-online.sigmasms.ru' ].includes(window.location.hostname)) { // Временное решение для тестов на stage
  otpSettingsPermissions = [
    `advanced.${otpSettingsWidgetsViaPoint}.get`,
    `advanced.${otpSettingsChannelsViaPoint}.get`,
    `advanced.${otpSettingsTemplatesViaPoint}.get`
  ]
}

export default [
  {
    path: '/',
    name: 'general',
    redirect: '/create',

    meta: {
      nav: true,
      navType: 'subheader'
    }
  },

  {
    path: '/create',
    name: 'create',
    component: SendingsCreate,

    meta: {
      auth: true,
      nav: true,
      navIcon: 'add_circle_outline',
      permission: 'advanced.sendings.generate',
      permissionAccept: true
    }
  },

  {
    path: '/sendings',
    name: 'sendings',
    component: Sendings,

    meta: {
      auth: true,
      nav: true,
      navIcon: 'swap_horiz',
      permission: 'advanced.sendings.get',
      permissionAccept: permissionPresets.meUp
    }
  },

  {
    path: '/billings',
    name: 'billings',
    component: Billings,

    meta: {
      auth: true,
      nav: true,
      navIcon: 'attach_money',
      permission: 'advanced.billings.find', // Исключение, так как нет права ME на GET
      permissionAccept: permissionPresets.meUp
    }
  },

  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,

    meta: {
      auth: true,
      nav: true,
      navIcon: 'contact_phone',
      permission: 'advanced.contacts.get',
      permissionAccept: permissionPresets.meUp
    },

    children: [
      {
        path: ':id',
        name: 'contacts.single',

        meta: {
          auth: true,
          permission: 'advanced.contacts.get',
          permissionAccept: permissionPresets.meUp
        }
      }
    ]
  },

  {
    path: '/sendernames',
    name: 'sendernames',
    component: SenderNames,

    meta: {
      auth: true,
      nav: true,
      navIcon: 'article',
      permission: 'advanced.sendernames.get',
      permissionAccept: permissionPresets.meUp
    },

    children: [
      {
        path: ':id',
        name: 'sendernames.single',

        meta: {
          auth: true,
          permission: 'advanced.sendernames.get',
          permissionAccept: permissionPresets.meUp
        }
      }
    ]
  },

  {
    path: '/patterns',
    name: 'patterns',
    component: Patterns,

    meta: {
      auth: true,
      nav: true,
      navIcon: 'developer_board',
      permission: 'advanced.patterns.get',
      permissionAccept: permissionPresets.resellerUp
    },

    children: [
      {
        path: ':id',
        name: 'patterns.single',

        meta: {
          auth: true,
          permission: 'advanced.patterns.get',
          permissionAccept: permissionPresets.resellerUp
        }
      }
    ]
  },

  {
    path: '/invoices',
    name: 'invoices',
    component: Invoices,

    meta: {
      auth: true,
      nav: true,
      navIcon: 'document_scanner',
      permission: 'advanced.invoices.get',
      permissionAccept: permissionPresets.meUp
    },

    children: [
      {
        path: ':id',
        name: 'invoices.single',

        meta: {
          auth: true,
          permission: 'advanced.invoices.get',
          permissionAccept: permissionPresets.meUp
        }
      }
    ]
  },

  {
    path: `/${services['otp-settings']}`,
    name: services['otp-settings'],
    component: OTP,

    meta: {
      auth: true,
      nav: true,
      navType: 'group',
      navIcon: '123',
      permission: otpSettingsPermissions,
      permissionAccept: otpSettingsPermissionsAccept
    },

    children: [
      {
        path: 'widgets',
        name: otpSettingsWidgetsViaPoint,
        component: OTPWidgets,

        meta: {
          auth: true,
          nav: true,
          pathToTitle: `${otpSettingsWidgetsViaPoint}`,
          permission: `advanced.${otpSettingsWidgetsViaPoint}.get`,
          permissionAccept: permissionPresets.meUp
        },

        children: [
          {
            path: ':id',
            name: `${otpSettingsWidgetsViaPoint}.single`,

            meta: {
              auth: true,
              permission: `advanced.${otpSettingsWidgetsViaPoint}.get`,
              permissionAccept: permissionPresets.meUp
            }
          }
        ]
      },
      {
        path: 'channels',
        name: otpSettingsChannelsViaPoint,
        component: OTPChannels,

        meta: {
          auth: true,
          nav: true,
          pathToTitle: `${otpSettingsChannelsViaPoint}`,
          permission: `advanced.${otpSettingsChannelsViaPoint}.get`,
          permissionAccept: permissionPresets.true
        },

        children: [
          {
            path: ':id',
            name: `${otpSettingsChannelsViaPoint}.single`,

            meta: {
              auth: true,
              permission: `advanced.${otpSettingsChannelsViaPoint}.get`,
              permissionAccept: permissionPresets.true
            }
          }
        ]
      },
      {
        path: 'templates',
        name: otpSettingsTemplatesViaPoint,
        component: OTPTemplates,

        meta: {
          auth: true,
          nav: true,
          pathToTitle: `${otpSettingsTemplatesViaPoint}`,
          permission: `advanced.${otpSettingsTemplatesViaPoint}.get`,
          permissionAccept: permissionPresets.true
        },

        children: [
          {
            path: ':id',
            name: `${otpSettingsTemplatesViaPoint}.single`,

            meta: {
              auth: true,
              permission: `advanced.${otpSettingsTemplatesViaPoint}.get`,
              permissionAccept: permissionPresets.true
            }
          }
        ]
      }
    ]
  },

  {
    path: '/admin',
    name: 'admin',
    component: Admin,

    meta: {
      auth: true,
      nav: true,
      navType: 'subheader',
      permission: [
        'advanced.providers.get',
        'advanced.providerGroups.get',
        'advanced.routingRules.get',
        'advanced.routingGroups.get',
        'advanced.sendingsModeration.get',
        'advanced.dictionaries.get',
        'advanced.users.get',
        'advanced.tariffs.get',
        'advanced.requisites.get',
        'advanced.settingsSite.get'
      ],
      permissionAccept: permissionPresets.resellerUp
    },

    children: [
      {
        path: 'users',
        name: 'users',
        component: Users,

        meta: {
          auth: true,
          nav: true,
          navIcon: 'account_circle',
          permission: 'advanced.users.get',
          permissionAccept: permissionPresets.resellerUp
        },

        children: [
          {
            path: ':id',
            name: 'users.single',

            meta: {
              auth: true,
              permission: 'advanced.users.get',
              permissionAccept: permissionPresets.resellerUp
            }
          }
        ]
      },

      {
        path: 'tariffs',
        name: 'tariffs',
        component: Tariffs,

        meta: {
          auth: true,
          nav: true,
          navIcon: 'donut_large',
          permission: 'advanced.tariffs.get',
          permissionAccept: permissionPresets.resellerUp
        },

        children: [
          {
            path: ':id',
            name: 'tariffs.single',

            meta: {
              auth: true,
              permission: 'advanced.tariffs.get',
              permissionAccept: permissionPresets.resellerUp
            }
          }
        ]
      },

      {
        path: 'payments',
        name: 'payments',
        component: Payments,

        meta: {
          auth: true,
          nav: true,
          navIcon: 'attach_money',
          permission: [ 'advanced.payments.get', 'advanced.recurrentPayments.get' ],
          permissionAccept: permissionPresets.resellerUp
        }
      },

      {
        path: 'sendingsModeration',
        name: 'sendingsModeration',
        component: SendingsModeration,

        meta: {
          auth: true,
          nav: true,
          navIcon: 'verified_user',
          permission: [ 'advanced.sendingsModeration.get' ],
          permissionAccept: permissionPresets.resellerUp,
          suffix: 'counter'
        }
      },

      {
        path: 'routes',
        name: 'routes',
        component: Routes,

        meta: {
          auth: true,
          nav: true,
          navType: 'group',
          navIcon: 'merge_type',
          permission: [
            'advanced.providers.get',
            'advanced.providerGroups.get',
            'advanced.routingRules.get',
            'advanced.routingGroups.get',
            'advanced.servers.get',
            'advanced.credentials.get'
          ],
          permissionAccept: permissionPresets.true
        },

        children: [
          {
            path: 'providers',
            name: 'providers',
            component: Providers,

            meta: {
              auth: true,
              nav: true,
              permission: 'advanced.providers.get',
              permissionAccept: permissionPresets.true
            },

            children: [
              {
                path: ':id',
                name: 'providers.single',

                meta: {
                  auth: true,
                  permission: 'advanced.providers.get',
                  permissionAccept: permissionPresets.true
                }
              }
            ]
          },

          {
            path: 'providerGroups',
            name: 'providerGroups',
            component: ProviderGroups,

            meta: {
              auth: true,
              nav: true,
              permission: 'advanced.providerGroups.get',
              permissionAccept: permissionPresets.true
            },

            children: [
              {
                path: ':id',
                name: 'providerGroups.single',

                meta: {
                  auth: true,
                  permission: 'advanced.providerGroups.get',
                  permissionAccept: permissionPresets.true
                }
              }
            ]
          },

          {
            path: 'routingRules',
            name: 'routingRules',
            component: RoutingRules,

            meta: {
              auth: true,
              nav: true,
              permission: 'advanced.routingRules.get',
              permissionAccept: permissionPresets.true
            },

            children: [
              {
                path: ':id',
                name: 'routingRules.single',

                meta: {
                  auth: true,
                  permission: 'advanced.routingRules.get',
                  permissionAccept: permissionPresets.true
                }
              }
            ]
          },

          {
            path: 'routingGroups',
            name: 'routingGroups',
            component: RoutingGroups,

            meta: {
              auth: true,
              nav: true,
              permission: 'advanced.routingGroups.get',
              permissionAccept: permissionPresets.true
            },

            children: [
              {
                path: ':id',
                name: 'routingGroups.single',

                meta: {
                  auth: true,
                  permission: 'advanced.routingGroups.get',
                  permissionAccept: permissionPresets.true
                }
              }
            ]
          },

          {
            path: 'servers',
            name: 'servers',
            component: Servers,

            meta: {
              auth: true,
              nav: true,
              permission: 'advanced.servers.get',
              permissionAccept: permissionPresets.true
            },

            children: [
              {
                path: ':id',
                name: 'servers.single',

                meta: {
                  auth: true,
                  permission: 'advanced.servers.get',
                  permissionAccept: permissionPresets.true
                }
              }
            ]
          },

          {
            path: 'credentials',
            name: 'credentials',
            component: Credentials,

            meta: {
              auth: true,
              nav: true,
              permission: 'advanced.credentials.get',
              permissionAccept: permissionPresets.true
            },

            children: [
              {
                path: ':id',
                name: 'credentials.single',

                meta: {
                  auth: true,
                  permission: 'advanced.credentials.get',
                  permissionAccept: permissionPresets.true
                }
              }
            ]
          }
        ]
      },

      {
        path: 'messageProcessing',
        name: 'messageProcessing',
        component: MessageProcessing,

        meta: {
          auth: true,
          nav: true,
          navType: 'group',
          navIcon: 'message',
          permission: [
            'advanced.messageRules.get',
            'advanced.messageRuleGroups.get',
            'advanced.keywords.get'
          ],
          permissionAccept: true
        },

        children: [
          {
            path: 'messageRules',
            name: 'messageRules',
            component: MessageRuleTemplates,

            meta: {
              auth: true,
              nav: true,
              permission: 'advanced.messageRules.get',
              permissionAccept: true
            },

            children: [
              {
                path: ':id',
                name: 'messageRules.single',

                meta: {
                  auth: true,
                  permission: 'advanced.messageRules.get',
                  permissionAccept: true
                }
              }
            ]
          },

          {
            path: 'messageRuleGroups',
            name: 'messageRuleGroups',
            component: MessageRuleGroups,

            meta: {
              auth: true,
              nav: true,
              permission: 'advanced.messageRuleGroups.get',
              permissionAccept: true
            },

            children: [
              {
                path: ':id',
                name: 'messageRuleGroups.single',

                meta: {
                  auth: true,
                  permission: 'advanced.messageRuleGroups.get',
                  permissionAccept: true
                }
              }
            ]
          },

          {
            path: 'keywordLists',
            name: 'keywordLists',
            component: KeywordLists,

            meta: {
              auth: true,
              nav: true,
              permission: 'advanced.keywords.get',
              permissionAccept: true
            },

            children: [
              {
                path: ':id',
                name: 'keywordLists.single',

                meta: {
                  auth: true,
                  permission: 'advanced.keywords.get',
                  permissionAccept: true
                }
              }
            ]
          }
        ]
      },

      {
        path: 'documents',
        name: 'documents',
        component: Documents,

        meta: {
          auth: true,
          nav: true,
          navType: 'group',
          navIcon: 'class',
          permission: [
            'advanced.requisites.get',
            'advanced.companies.get',
            'advanced.contracts.get'
          ],
          permissionAccept: permissionPresets.resellerUp
        },

        children: [
          {
            path: 'requisites',
            name: 'requisites',
            component: Requisites,

            meta: {
              auth: true,
              nav: true,
              permission: 'advanced.requisites.get',
              permissionAccept: permissionPresets.resellerUp
            },

            children: [
              {
                path: ':id',
                name: 'requisites.single',

                meta: {
                  auth: true,
                  permission: 'advanced.requisites.get',
                  permissionAccept: permissionPresets.resellerUp
                }
              }
            ]
          },

          {
            path: 'companies',
            name: 'companies',
            component: Companies,

            meta: {
              auth: true,
              nav: true,
              permission: 'advanced.companies.get',
              permissionAccept: permissionPresets.resellerUp
            },

            children: [
              {
                path: ':id',
                name: 'companies.single',

                meta: {
                  auth: true,
                  permission: 'advanced.companies.get',
                  permissionAccept: permissionPresets.resellerUp
                }
              }
            ]
          },

          {
            path: 'contracts',
            name: 'contracts',
            component: Contracts,

            meta: {
              auth: true,
              nav: true,
              permission: 'advanced.contracts.get',
              permissionAccept: permissionPresets.resellerUp
            },

            children: [
              {
                path: ':id',
                name: 'contracts.single',

                meta: {
                  auth: true,
                  permission: 'advanced.contracts.get',
                  permissionAccept: permissionPresets.resellerUp
                }
              }
            ]
          }
        ]
      },

      {
        path: 'settings',
        name: 'settings',
        component: Settings,

        meta: {
          auth: true,
          nav: true,
          navIcon: 'settings',
          permission: 'advanced.settingsSite.get',
          permissionAccept: permissionPresets.resellerUp
        },

        children: [
          {
            path: ':UserId',
            name: 'settings.single',

            meta: {
              auth: true,
              permission: 'advanced.settingsSite.get',
              permissionAccept: permissionPresets.resellerUp
            }
          }
        ]
      },

      {
        path: 'notifications',
        name: 'notifications',
        component: Notifications,

        meta: {
          auth: true,
          nav: true,
          navIcon: 'notifications',
          permission: 'advanced.notifications.get',
          permissionAccept: permissionPresets.admin
        }
      },

      {
        path: '/other',
        name: 'other',
        component: Other,

        meta: {
          auth: true,
          nav: true,
          navType: 'group',
          navIcon: 'more_horiz',
          permission: [
            'advanced.dictionaries.get',
            'advanced.tokens.get',
            'advanced.yclients.get',
            'advanced.eventsHistory.get'
          ],
          permissionAccept: permissionPresets.true
        },

        children: [
          {
            path: 'yclients',
            name: 'yclients',
            component: YClients,

            meta: {
              auth: true,
              nav: true,
              permission: 'advanced.yclients.get',
              permissionAccept: permissionPresets.true
            },

            children: [
              {
                path: ':id',
                name: 'yclients.single',

                meta: {
                  auth: true,
                  permission: 'advanced.yclients.get',
                  permissionAccept: permissionPresets.true
                }
              }
            ]
          },

          {
            path: 'tokens',
            name: 'tokens',
            component: Tokens,

            meta: {
              auth: true,
              nav: true,
              permission: 'advanced.tokens.get',
              permissionAccept: permissionPresets.true
            }
          },

          {
            path: 'dictionaries',
            name: 'dictionaries',
            component: Dictionaries,

            meta: {
              auth: true,
              nav: true,
              permission: 'advanced.dictionaries.get',
              permissionAccept: permissionPresets.true
            }
          },

          {
            path: 'eventsHistory',
            name: 'eventsHistory',
            component: EventsHistory,

            meta: {
              auth: true,
              nav: true,
              permission: 'advanced.eventsHistory.get',
              permissionAccept: permissionPresets.true
            },

            children: [
              {
                path: ':id',
                name: 'eventsHistory.single',

                meta: {
                  auth: true,
                  permission: 'advanced.eventsHistory.get',
                  permissionAccept: permissionPresets.true
                }
              }
            ]
          }
        ]
      }
    ]
  },

  {
    path: '/files',
    name: 'files',
    component: Files,

    meta: {
      auth: true,
      nav: true,
      navIcon: 'folder',
      permission: 'advanced.storage.get',
      permissionAccept: permissionPresets.meUp
    }
  },

  {
    path: '/documentation',
    name: 'documentation',
    component: Documentation,

    meta: {
      nav: true,
      navIcon: 'help',
      params: {
        category: 'platform',
        file: 'instruction'
      }
    },

    children: [
      {
        path: ':category',
        name: 'documentation.category',

        children: [
          {
            path: ':file',
            name: 'documentation.file'
          }
        ]
      }
    ]
  },

  {
    path: '/login',
    name: 'login',
    component: Login,

    meta: {
      guest: true
    }
  },

  {
    path: '/registration',
    name: 'registration',
    component: Registration,

    meta: {
      guest: true
    }
  },

  {
    path: '/recover',
    name: 'recover',
    component: Recover,

    meta: {
      guest: true
    },

    children: [
      {
        path: ':key',
        name: 'recover.key',

        meta: {
          guest: true
        }
      }
    ]
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,

    meta: {
      auth: true
    }
  },

  {
    path: '/account',
    name: 'account',
    component: Account,

    meta: {
      auth: true
    }
  },

  {
    path: '/support',
    name: 'support',
    component: Support,

    meta: {
      auth: true,
      handler({ store, from, next }) {
        if (!store.getters['settings/global'].contacts) {
          next(from && from.name ? false : { name: 'home' })

          return true
        }
      }
    }
  },

  {
    path: '/debug',
    name: 'debug',
    component: Debug,

    meta: {
      auth: true
    }
  },

  {
    path: '/playground',
    name: 'playground',
    component: Playground,

    meta: {
      auth: true
    }
  },

  {
    path: '/pay',
    name: 'pay',
    component: Pay,

    children: [
      {
        path: ':owner',
        name: 'pay.owner'
      }
    ]
  },

  {
    path: '/components',
    redirect: '/sendernames'
  },

  // Эти два роута должны быть в конце,
  // иначе роуты без авторизации или статуса гостя
  // будут перехвачены редиректом на /create
  {
    path: '/',
    name: 'home',
    redirect: '/create'
  },

  {
    path: '/*',
    redirect: '/create'
  }
]
