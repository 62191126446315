import Vue from 'vue'

import { debounce } from 'lodash'

import schemas from '@sigma-legacy-libs/essentials/lib/schemas'
import { processSchemaKey } from '@sigma-legacy-libs/essentials/lib/utils/prism'

import { globalErrorHandler, globalErrorProcessor, permissionPresets, services } from '@/utils'

export const notificationsSettings = () => {
  return {
    props: {
      options: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        loading: {
          find: false,
          update: false
        },
        loadings: {},
        settings: {},
        newSettings: {}
      }
    },

    computed: {
      $schema() {
        switch (this.options.serviceName) {
          case services.settingsSite: {
            if (this.checkPermissions(`advanced.${this.options.serviceName}.set`)) {
              return schemas.site.SETTINGS_SITE_SCHEMA
            } else if (this.checkPermissions(`advanced.${this.options.serviceName}.set`, permissionPresets.reseller)) {
              return schemas.site.SETTINGS_SITE_SCHEMA_RESELLER
            }
            break
          }
          case services.settingsUser: {
            if (this.checkPermissions(`advanced.${this.options.serviceName}.set`)) {
              return schemas.user.SETTINGS_USER_SCHEMA
            } else if (this.checkPermissions(`advanced.${this.options.serviceName}.set`, permissionPresets.reseller)) {
              if (this.account.id !== this.options.UserId) {
                return schemas.user.SETTINGS_USER_SCHEMA_RESELLER_CHILD
              }

              return schemas.user.SETTINGS_USER_SCHEMA_RESELLER
            } else if (this.checkPermissions(`advanced.${this.options.serviceName}.set`, permissionPresets.me)) {
              return schemas.user.SETTINGS_USER_SCHEMA_ME
            }
            break
          }
        }
      }
    },

    watch: {
      newSettings: {
        handler() {
          this.update()
        },
        deep: true
      }
    },

    mounted() {
      this.getSettings()
    },

    methods: {
      async getSettings() {
        try {
          this.loading.find = true
          const { data } = await Vue.$GRequest.find([ this.options.serviceName, this.options.UserId ].filter(Boolean).join('/'))
          if (data) {
            this.settings = data
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading.find = false
        }
      },

      setNewSetting(path, value) {
        Vue.set(this.newSettings, path, value)
      },

      async updateSettings(path, value) {
        value = processSchemaKey(value, path, this.$schema, true)
        const { data } = await Vue.$GRequest.update(this.options.serviceName, [ this.options.UserId, path ].filter(Boolean).join('/'), { value })
        if (data) {
          Vue.set(this.settings, path, data.value)
          Vue.delete(this.loadings, path)
        }
      },

      update: debounce(
        async function() {
          this.loading.update = true
          const results = await Promise.allSettled(Object.keys(this.newSettings).map(path => this.updateSettings(path, this.newSettings[path])))
          if (results && results.length) {
            for (const { status, reason } of results) {
              switch (status) {
                case 'rejected': {
                  this.addSnackbar({
                    type: 'error',
                    text: reason
                  })
                  break
                }
              }
            }
            this.addSnackbar({
              type: 'success',
              text: this.getTranslate(`${services.notifications}.snackbars.updated`)
            })
          }
          this.loading.update = false
        },
        500,
        {
          leading: false,
          trailing: true
        }
      ),

      setLoading(path, channel) {
        if (!this.loadings[path]) {
          Vue.set(this.loadings, path, [])
        }
        this.loadings[path].push(channel)
      }
    }
  }
}
