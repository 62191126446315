import { get } from 'lodash'

import { buttonModes, colors, components, icons, inputModes, services } from '@/utils'

import button from '@/components/button'
import fileDialog from '@/components/file/dialog'
import fileCard from '@/components/file/item/card'
import fileManager from '@/components/file/manager'
import selectorOwner from '@/components/selector/owner'
import { opfListShort } from '@/components/services/requisites/utils'
import tag from '@/components/tag'
import textOverflow from '@/components/text/overflow'

function renderFileDialog(h) {
  if (this.showFileDialog) {
    return h(
      fileDialog,
      {
        props: {
          value: this.dialogData.currentEditFile,
          googleFileURL: this.dialogData.googleFileURL,
          type: this.dialogData.editDialogType,
          show: this.showFileDialog
        },
        on: {
          show: event => {
            this.showFileDialog = event
          }
        }
      }
    )
  }
}

function renderTitleField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.title,
        label: this.getTranslate(`${services.contracts}.labels.title`),
        mode: inputModes['line-label'],
        rounded: true,
        details: false,
        required: true,
        clearable: true,
        autofocus: true
      },
      on: {
        input: event => {
          this.proxy.title = event
        }
      }
    }
  )
}

function renderSelectorOwner(h) {
  return h(
    selectorOwner,
    {
      props: {
        value: this.proxy.OwnerId,
        disabled: !this.isNew && !!this.value.OwnerId
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderRequisiteSelect(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.RequisiteId,
        label: this.getTranslate(`${services.contracts}.labels.requisite`),
        service: services.requisites,
        query: { OwnerId: this.proxy.OwnerId },
        picks: [ 'id', 'title', 'data' ],
        mode: inputModes['line-label'],
        rounded: true,
        details: false,
        clearable: true,
        disabled: !this.proxy.OwnerId || !this.isNew && !!this.value.Requisite
      },
      scopedSlots: {
        selection: ({ item }) => {
          const opf = get(item, 'data.legal.opf')
          const name = get(item, 'data.legal.name')
          const vat = get(item, 'data.tax.vat')

          return h(
            'div',
            {
              class: 'w--100 grid grid-gap--8 faic fjcsb',
              style: { gridTemplateColumns: 'repeat(2, auto)' }
            },
            [
              h(
                tag,
                {
                  props: {
                    label: [ opfListShort[opf], name ].filter(Boolean).join(' '),
                    as: 'select'
                  }
                }
              ),
              h(
                tag,
                {
                  props: {
                    label: this.getTranslate(`${services.requisites}.vat.${vat}`),
                    color: colors.primary,
                    as: 'select'
                  }
                }
              )
            ]
          )
        },
        item: ({ item }) => {
          const opf = get(item, 'data.legal.opf')
          const name = get(item, 'data.legal.name')
          const vat = get(item, 'data.tax.vat')

          return h(
            'div',
            {
              class: 'w--100 grid grid-gap--8 faic fjcsb',
              style: { gridTemplateColumns: '1fr auto' }
            },
            [
              h(
                'div',
                {
                  class: 'grid grid-gap--0'
                },
                [
                  h(
                    textOverflow,
                    {
                      props: {
                        value: name
                      }
                    }
                  ),
                  h(
                    textOverflow,
                    {
                      class: 'caption text--grey',
                      props: {
                        value: opf
                      }
                    }
                  )
                ]
              ),
              h(
                tag,
                {
                  props: {
                    label: this.getTranslate(`${services.requisites}.vat.${vat}`),
                    color: colors.primary,
                    as: 'select'
                  }
                }
              )
            ]
          )
        }
      },
      on: {
        input: event => {
          this.proxy.RequisiteId = event
        }
      }
    }
  )
}

function renderCompanySelect(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.CompanyId,
        label: this.getTranslate(`${services.contracts}.labels.company`),
        service: services.companies,
        query: {
          OwnerId: '$global',
          vat: this.vat
        },
        picks: [ 'id', 'title', 'vat' ],
        mode: inputModes['line-label'],
        rounded: true,
        details: false,
        autocomplete: true,
        clearable: true,
        disabled: !this.proxy.OwnerId || !this.isNew && !!this.value.Company
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            'div',
            {
              class: 'w--100 grid grid-gap--8 faic fjcsb',
              style: { gridTemplateColumns: 'repeat(2, auto)' }
            },
            [
              h(
                tag,
                {
                  props: {
                    label: item.title,
                    as: 'select'
                  }
                }
              ),
              h(
                tag,
                {
                  props: {
                    label: this.getTranslate(`${services.companies}.vat.${item.vat}`),
                    color: colors.primary,
                    as: 'select'
                  }
                }
              )
            ]
          )
        },
        item: ({ item }) => {
          return h(
            'div',
            {
              class: 'w--100 grid grid-gap--8 faic fjcsb',
              style: { gridTemplateColumns: '1fr auto' }
            },
            [
              h(
                textOverflow,
                {
                  props: {
                    value: item.title
                  }
                }
              ),
              h(
                tag,
                {
                  props: {
                    label: this.getTranslate(`${services.requisites}.vat.${item.vat}`),
                    color: colors.primary,
                    as: 'select'
                  }
                }
              )
            ]
          )
        }
      },
      on: {
        input: event => {
          this.proxy.CompanyId = event
        }
      }
    }
  )
}

function renderFileManager(h) {
  return h(
    fileManager,
    {
      props: {
        show: this.showFileManager,
        currentChosenFileId: this.proxy.FileId,
        flat: true,
        embedded: true,
        readonly: true,
        types: [ 'doc', 'docx' ]
      },
      on: {
        show: event => {
          this.showFileManager = event
        },
        input: event => {
          this.showFileManager = false
          this.proxy.FileId = event.id
        }
      }
    }
  )
}

function renderClearFileIdButton(h) {
  if (this.isNew) {
    return h(
      button,
      {
        props: {
          icon: icons.clear,
          mode: buttonModes.flat
        },
        on: {
          click: () => {
            this.proxy.FileId = undefined
          }
        }
      }
    )
  }
}

function renderFileCard(h) {
  if (this.proxy.FileId) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        style: { 'grid-template-columns': this.isNew ? '1fr auto' : '1fr' }
      },
      [
        h(
          fileCard,
          {
            props: {
              value: this.proxy.FileId,
              outline: true,
              displayMenu: !this.isNew,
              flat: true,
              maxWidth: '100%',
              small: true,
              include: [ 'edit', 'download', 'export' ]
            },
            on: {
              dialog: event => {
                this.dialogData = event
                this.showFileDialog = !event.close
              }
            }
          }
        ),

        renderClearFileIdButton.call(this, h)
      ]
    )
  }

  return h(
    'div',
    {
      class: 'fjcfs'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate(`${services.contracts}.buttons.attach`),
            icon: 'attach_file',
            mode: buttonModes.flat,
            color: 'primary'
          },
          on: {
            click: () => {
              this.showFileManager = true
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: {
        'grid grid-cols--1': true,
        'pa-3': !this.isNew,
        'pa-2': this.isNew
      }
    },
    [
      renderTitleField.call(this, h),
      renderSelectorOwner.call(this, h),
      renderRequisiteSelect.call(this, h),
      renderCompanySelect.call(this, h),
      renderFileCard.call(this, h),
      renderFileManager.call(this, h),
      renderFileDialog.call(this, h)
    ]
  )
}
