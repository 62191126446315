import { get } from 'lodash'

import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, icons, inputModes, permissionPresets, services, states } from '@/utils'

import button from '@/components/button'
import generatorRow from '@/components/generator/row'
import info from '@/components/info'
import selectorSendername from '@/components/selector/sendername'
import selectorSendingType from '@/components/selector/sendingType'
import sendernamesForm from '@/components/services/sendernames/form'
import catchSymbolsInput from '@/components/services/sendings/catchSymbolsInput'
import sendingsCreateAttach from '@/components/services/sendingsCreate/form/attach'
import fallbackOptions from '@/components/services/sendingsCreate/form/fallback/options'
import sendingsCreateWarning from '@/components/services/sendingsCreate/form/warning'

function renderTypes(h) {
  return h(
    selectorSendingType,
    {
      props: {
        value: this.proxy.type,
        items: this.types,
        disabled: this.disabled,
        readonly: this.readonly,
        view: 'buttons'
      },
      on: {
        input: event => {
          this.proxy.type = event
        }
      }
    }
  )
}

function renderCreateSenderNameDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`${services.sendernames}.titles.new`),
        value: this.showCreateNewSenderName,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 400 : undefined
      },
      on: {
        input: event => {
          this.showCreateNewSenderName = event
        }
      }
    },
    [
      h(
        sendernamesForm,
        {
          props: {
            value: this.restData[services.sendernames].create.data,
            errors: this.restData[services.sendernames].create.errors,
            isNew: true
          },
          on: {
            input: event => {
              this.restData[services.sendernames].create.data = event
            },
            submit: async () => {
              if (this.restData[services.sendernames].create.isValid) {
                const result = await this.rest[services.sendernames].create(this.restData[services.sendernames].create.data)
                if (result) {
                  this.showCreateNewSenderName = false
                }
              }
            },
            validation: event => {
              this.restData[services.sendernames].create.isValid = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'fjcfe grid-gap--8 pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.showCreateNewSenderName = false
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.create'),
                color: colors.primary,
                loading: this.restData[services.sendernames].create.state === states.loading,
                disabled: this.restData[services.sendernames].create.state === states.loading || !this.restData[services.sendernames].create.isValid
              },
              on: {
                click: async () => {
                  const result = await this.rest[services.sendernames].create(this.restData[services.sendernames].create.data)
                  if (result) {
                    this.showCreateNewSenderName = false
                  }
                }
              }
            }
          )
        ]
      )
    ]
  )
}
function renderCreateSenderNameButton(h) {
  if (this.readonly) {
    return
  }

  if (this.checkPermissions(`advanced.${services.sendernames}.create`, permissionPresets.meUp)) {
    return h(
      button,
      {
        props: {
          icon: icons.add,
          mode: buttonModes.flat,
          color: colors.secondary,
          tooltip: this.getTranslate('sendingsCreate.tooltips.createNewUser')
        },
        on: {
          click: () => {
            this.showCreateNewSenderName = true
          }
        },
        scopedSlots: {
          dialog: () => renderCreateSenderNameDialog.call(this, h)
        }
      }
    )
  }
}

function renderSenderNameField(h) {
  return h(
    'div',
    {
      class: 'faifs grid grid-gap--8',
      style: { gridTemplateColumns: '1fr auto' }
    },
    [
      h(
        selectorSendername,
        {
          props: {
            value: this.proxy.payload.sender,
            type: this.proxy.type,
            errors: this.errors,
            disabled: this.disabled,
            readonly: this.readonly,
            forceAnySendername: this.isPattern
          },
          on: {
            input: event => {
              this.proxy.payload.sender = event
            }
          }
        }
      ),

      renderCreateSenderNameButton.call(this, h)
    ]
  )
}

function renderWarning(h) {
  if (this.readonly) {
    return
  }

  return h(
    sendingsCreateWarning,
    {
      props: {
        check: this.checkTestRoute
      }
    }
  )
}

function renderTextField(h) {
  return h(
    catchSymbolsInput,
    {
      props: {
        value: this.proxy.payload.text,
        sendingType: this.proxy.type,
        label: this.getTranslate(`${services.sendings}.labels.text`),
        disabled: this.disabled || !this.isGeneratedFile,
        readonly: this.readonly,
        component: components.textarea,
        mode: inputModes.outline,
        rounded: true,
        error: this.errors.text,
        tags: this.tags,
        customMenu: this.customMenu,
        maxLength: this.proxy.type === SENDING_TYPES.voice ? 3000 : null,
        messagePayloadGenerator: true,
        utm: true,
        stats: true
      },
      on: {
        input: event => {
          this.generatedAudioFile = undefined
          this.proxy.payload.text = event
        }
      }
    }
  )
}

function renderButtonFields(h) {
  if (!this.disabled || !this.readonly) {
    if ([ SENDING_TYPES.viber ].includes(this.proxy.type)) {
      if (this.proxy.payload.button) {
        return h(
          'div',
          {
            class: 'grid grid-gap--8'
          },
          [
            h(
              catchSymbolsInput,
              {
                props: {
                  value: this.proxy.payload.button.url,
                  label: this.getTranslate(`${services.sendings}.labels.button.url`),
                  hint: this.getTranslate(`${services.sendings}.hints.button.url`),
                  type: 'url',
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  disabled: this.disabled,
                  readonly: this.readonly,
                  error: get(this.errors, 'button.url') || this.validationButtonUrl,
                  tags: this.tags,
                  customMenu: this.customMenu
                },
                on: {
                  input: event => {
                    this.proxy.payload.button.url = event
                  }
                }
              }
            ),
            h(
              catchSymbolsInput,
              {
                props: {
                  value: this.proxy.payload.button.text,
                  label: this.getTranslate(`${services.sendings}.labels.button.text`),
                  hint: this.getTranslate(`${services.sendings}.hints.button.text`),
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  disabled: this.disabled,
                  readonly: this.readonly,
                  error: get(this.errors, 'button.text') || this.validationButtonText,
                  tags: this.tags,
                  customMenu: this.customMenu
                },
                on: {
                  input: event => {
                    this.proxy.payload.button.text = event
                  }
                }
              }
            )
          ]
        )
      }
    }
  }
}
function renderWhatsAppButtons(h) {
  if ([ SENDING_TYPES.whatsapp, SENDING_TYPES.vk ].includes(this.proxy.type)) {
    if ((this.disabled || this.readonly) && (!this.proxy.payload.buttons || !this.proxy.payload.buttons.length)) {
      return
    }

    return h(
      generatorRow,
      {
        props: {
          value: this.proxy.payload.buttons,
          disabled: this.disabled || this.readonly,
          defaultValue: {
            text: undefined,
            action: undefined
          },
          max: this.maxButtons
        },
        scopedSlots: {
          row: ({ item }) => {
            return h(
              'div',
              {
                class: 'grid grid-cols--2 grid-gap--8'
              },
              [
                h(
                  components['text-field'],
                  {
                    props: {
                      value: item.text,
                      label: this.getTranslate(`${services.sendings}.labels.button.text`),
                      mode: inputModes['line-label'],
                      dense: true,
                      rounded: true,
                      details: false,
                      disabled: this.disabled,
                      readonly: this.readonly
                    },
                    on: {
                      input: event => {
                        item.text = event
                      }
                    }
                  }
                ),

                h(
                  components['text-field'],
                  {
                    props: {
                      value: item.action,
                      label: this.getTranslate(`${services.sendings}.labels.button.action`),
                      mode: inputModes['line-label'],
                      dense: true,
                      rounded: true,
                      details: false,
                      disabled: this.disabled,
                      readonly: this.readonly
                    },
                    on: {
                      input: event => {
                        item.action = event
                      }
                    }
                  }
                )
              ]
            )
          }
        },
        on: {
          input: event => {
            this.proxy.payload.buttons = event
          }
        }
      }
    )
  }
}
function renderAttach(h) {
  if (!this.disabled || !this.readonly) {
    return h(
      sendingsCreateAttach,
      {
        props: { value: this.proxy },
        on: {
          input: event => {
            this.proxy = event
          },
          isGeneratedFile: event => {
            this.isGeneratedFile = event
          }
        }
      }
    )
  }
}
function renderViberInfo(h) {
  if (this.readonly) {
    return
  }

  if (this.proxy.type === SENDING_TYPES.viber) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate('sendingsCreate.hints.viberTemplates')
        }
      }
    )
  }
}

function renderFallbackOptions(h) {
  if (this.isFallback) {
    return h(
      fallbackOptions,
      {
        props: {
          value: this.proxy.$options,
          readonly: this.readonly
        },
        on: {
          input: event => {
            this.proxy.$options = event
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderTypes.call(this, h),
      renderSenderNameField.call(this, h),
      renderWarning.call(this, h),
      renderTextField.call(this, h),
      renderButtonFields.call(this, h),
      renderWhatsAppButtons.call(this, h),
      renderAttach.call(this, h),
      renderViberInfo.call(this, h),
      renderFallbackOptions.call(this, h)
    ]
  )
}
