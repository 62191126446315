export default {
  title: 'Grupos de proveedores',

  titles: {
    create: 'Crear grupo de proveedores'
  },

  providerGroup: 'Grupo de proveedor',
  providerGroups: 'Grupo de proveedores',
  one: 'Grupo de proveedor',
  many: 'Grupo de proveedores',

  labels: {
    title: 'Nombre del grupo',
    type: 'Tipo',
    provider: 'Proveedor',
    search: 'Buscar',
    isActive: 'Activo'
  },

  tabs: {
    eventsHistory: 'Historial de cambios'
  },

  dialogs: {
    new: 'Nuevo grupo de proveedor'
  },

  subheaders: {
    providers: 'Proveedores'
  },

  buttons: {
    add: {
      provider: 'Agregar proveedor'
    }
  },

  hints: {
    notFound: 'No se encontraron grupos de proveedores'
  },

  tooltips: {
    isActive: 'Activar o desactivar grupo de proveedores.',
    favorites: 'Marcar grupo de proveedores como favorito',
    providers: 'Lista de proveedores',

    filter: {
      isActive: 'Mostrar solo grupos de proveedores activos o inactivos'
    }
  },

  subtitles: {
    providers: 'Proveedores'
  },

  snackbars: {
    created: 'Grupo de proveedor creado con éxito.',
    updated: 'Grupo de proveedor actualizado con éxito.',
    removed: 'Grupo de proveedor eliminado con éxito.'
  }
}
