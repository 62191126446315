export default {
  title: 'Cartas credenciales',

  titles: {
    create: 'Crear credenciales',
    update: 'Actualizar credenciales',
    remove: 'Eliminar credenciales'
  },

  labels: {
    title: 'Título',
    records: {
      username: 'Nombre de usuario',
      password: 'Contraseña',
      token: 'Token',
      apikey: 'Clave API'
    },
    search: 'Buscar'
  },

  contents: {
    confirm: {
      remove: 'Confirme la eliminación de las credenciales.'
    }
  },

  hints: {
    notFound: 'Credenciales no encontradas.',
    data: 'Datos',
    info: 'Complete al menos uno de los campos a continuación para crear credenciales.'
  },

  tooltips: {
    favorites: 'Marcar las credenciales como favoritas'
  },

  snackbars: {
    created: 'Credenciales creadas con éxito.',
    updated: 'Credenciales actualizadas con éxito.',
    removed: 'Credenciales eliminadas con éxito.'
  }
}
