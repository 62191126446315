import { buttonModes, colors, components, icons } from '@/utils'

import button from '@/components/button'

function renderConfirmDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate('misc.titles.confirm.clone'),
        value: this.showDialog,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 400 : undefined
      },
      on: {
        input: value => {
          this.showDialog = value
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'pa-3'
        },
        [ this.getTranslate('misc.contents.confirm.clone') ]
      ),

      h(
        'div',
        {
          class: 'dialog__footer',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat,
                rounded: true
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.clone'),
                color: colors.primary

              },
              on: {
                click: () => {
                  this._clone()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    button,
    {
      props: {
        icon: icons.copy_all,
        mode: buttonModes.flat,
        color: colors.secondary,
        loading: this.loading,
        disabled: this.loading,
        tooltip: this.getTranslate('misc.tooltips.clone')
      },
      scopedSlots: {
        dialog: () => renderConfirmDialog.call(this, h)
      },
      key: this.value.id,
      on: {
        click: () => {
          this.showDialog = true
        }
      }
    }
  )
}
