export default {
  title: 'Contactos',

  copyTo: 'Copiar',

  titles: {
    custom: {
      fields: 'Campos personalizados'
    }
  },

  labels: {
    list: 'Lista',
    phone: 'Teléfono',
    email: 'Correo electrónico',
    firstName: 'Nombre',
    lastName: 'Apellido',
    middleName: 'Segundo nombre',
    date: 'Fecha de nacimiento',
    gender: 'Género',
    contactList: 'Lista de contactos',
    actions: '',
    custom: 'Campo personalizado',
    custom01: 'Personalizado 01',
    custom02: 'Personalizado 02',
    custom03: 'Personalizado 03',
    custom04: 'Personalizado 04',
    custom05: 'Personalizado 05',
    custom06: 'Personalizado 06',
    custom07: 'Personalizado 07',
    custom08: 'Personalizado 08',
    custom09: 'Personalizado 09',
    custom10: 'Personalizado 10',
    custom11: 'Personalizado 11',
    custom12: 'Personalizado 12',
    custom13: 'Personalizado 13',
    custom14: 'Personalizado 14',
    custom15: 'Personalizado 15',
    custom16: 'Personalizado 16',
    custom17: 'Personalizado 17',
    custom18: 'Personalizado 18',
    custom19: 'Personalizado 19',
    custom20: 'Personalizado 20',
    clear: 'Limpiar filtro',
    name: 'Nombre'
  },

  dialogs: {
    titles: {
      create: 'Crear contacto',
      update: 'Actualizar contacto',
      remove: 'Eliminar contacto',
      import: 'Importar contactos'
    }
  },

  subheaders: {
    custom: 'Campos personalizados',
    date: 'Seleccionar fecha de nacimiento'
  },

  emptyState: {
    emptyAddressBook: 'No se encuentra la libreta de direcciones',
    title: 'No hay contactos para mostrar',
    description: '{0} Un archivo CSV/XLS/XLSX o {1} Un solo contacto'
  },

  contents: {
    confirm: {
      remove: '¿Estás seguro de que quieres eliminar el contacto?'
    },
    import: {
      loading: 'El diálogo se cerrará automáticamente al completar la importación.',
      success: 'Importación exitosa: {count}.',
      error: 'Desafortunadamente, no pudimos importar ni un solo contacto del archivo proporcionado.<br><br><ul><li>Verifique si sus columnas están nombradas correctamente. Intente renombrar su columna de número de teléfono a "phone"</li><li>Si tiene problemas para subir un archivo CSV, pruebe con XLSX o XLS. Tenga en cuenta que, en ese caso, sus registros deben estar en la primera hoja/libro.</li><li>¡Contacte a nuestro soporte! Puede que necesitemos su archivo para resolver el problema.</li></ul>'
    }
  },

  buttons: {
    add: 'Agregar',
    remove: 'Eliminar'
  },

  tooltips: {
    add: 'Agregar un contacto a la lista de contactos',
    remove: 'Eliminar el contacto de la lista de contactos',
    loading: 'Cargando...',
    total: '&#xb7 &#xb7 &#xb7 \ntotal {total}',
    noData: 'Sin contactos',
    permanent: 'Inserte la palabra "permanente" en este campo'
  },

  errors: {
    phone: 'Número de teléfono no válido',
    email: 'Correo electrónico no válido',
    loading: 'Cargando...',
    total: '&#xb7 &#xb7 &#xb7 \ntotal {total}',
    noData: 'Sin contactos'
  },

  snackbars: {
    created: 'Contacto creado con éxito.',
    updated: 'Contacto actualizado con éxito.',
    removed: 'Contacto eliminado con éxito.'
  }
}
