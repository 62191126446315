import { services } from '@/utils'

import generatorRow from '@/components/generator/row'
import routesFormLink from '@/components/services/routes/form/link'
import routesFormTitle from '@/components/services/routes/form/title'
import routesFormType from '@/components/services/routes/form/type'

function renderTypeField(h) {
  if (!this.type) {
    return h(
      routesFormType,
      {
        props: {
          value: this.proxy,
          serviceName: services.providerGroups
        },
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}

function renderTitleField(h) {
  return h(
    routesFormTitle,
    {
      props: {
        value: this.proxy,
        serviceName: services.providerGroups
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderProvidersFields(h) {
  if (Array.isArray(this.proxy.Providers)) {
    return h(
      'g-card',
      {
        class: 'grid grid-gap--8 pt-3 px-2 pb-2',
        props: {
          title: this.getTranslate(`${services.providerGroups}.subtitles.providers`),
          dashed: true,
          outline: true,
          rounded: true
        }
      },
      [
        h(
          generatorRow,
          {
            props: {
              value: this.proxy.Providers,
              disabled: this.disabled,
              defaultValue: {
                id: undefined,
                priority: 0,
                $attach: true
              },
              sortable: true,
              sortableProperty: 'priority'
            },
            scopedSlots: {
              row: ({ item }) => {
                return h(
                  'div',
                  {
                    class: 'grid grid-gap--8',
                    style: { 'grid-template-columns': '1fr auto' }
                  },
                  [
                    h(
                      'g-select',
                      {
                        props: {
                          value: item.id,
                          label: this.getTranslate(`${services.providerGroups}.labels.provider`),
                          disabled: this.disabled,
                          itemsDisabled: this.proxy.Providers.map(({ id }) => id),
                          service: 'providers',
                          query: { isActive: true },
                          clearable: true,
                          hideSelections: true,
                          mode: 'outline',
                          rounded: true,
                          dense: true,
                          details: false,
                          autocomplete: true
                        },
                        on: {
                          input: event => {
                            item.id = event
                          }
                        }
                      }
                    ),

                    h(
                      routesFormLink,
                      {
                        props: {
                          value: item,
                          service: services.providers
                        }
                      }
                    )
                  ]
                )
              }
            },
            on: {
              input: event => {
                this.proxy.Providers = event
              }
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid',
      style: { 'grid-template-columns': '100%' }
    },
    [
      renderTypeField.call(this, h),
      renderTitleField.call(this, h),
      renderProvidersFields.call(this, h)
    ]
  )
}
