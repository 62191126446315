import Vue from 'vue'

import { cloneDeep } from 'lodash'

import { keyValue, properties, services } from '@/utils'

import render from './render'

const defaultPayload = {
  key: undefined,
  value: undefined
}
const defaultD360Payload = { '': '' }

export const propertiesSelect = (options = {}) => {
  return {
    name: `${options.serviceName}Properties`,

    mixins: [ keyValue, properties(options) ],

    props: {
      processor: String,
      ServerId: String
    },

    data() {
      return {
        editMode: false,
        showDialog: false,

        index: undefined,

        payload: cloneDeep(defaultPayload),

        processorFromServer: undefined
      }
    },

    computed: {
      $processor() {
        return this.processor || this.processorFromServer
      },

      $hint() {
        let result = ''
        if (this.payload.key) {
          const property = this._getProperty()
          if (Array.isArray(property.processor)) {
            result += this.getTranslate(`${services.servers}.labels.processor`) + ': '
            result += property.processor.join(' | ')
            result += '<br>'
          }
          result += property.description
        }

        return result
      },

      $type() {
        if (this.payload.key) {
          const { type } = this._getProperty()

          return type
        }
      },

      disabled() {
        if (!this.$processor) {
          return true
        }

        return this.payload.key === undefined || this.payload.value === undefined
      }
    },

    watch: {
      'payload.key'(value) {
        if (value) {
          const { type, property } = this._getProperty()
          switch (type) {
            case 'string':
            case 'number': {
              if (typeof this.payload.value !== type) {
                this.payload.value = undefined
              }
              break
            }
            case 'boolean': {
              if (typeof this.payload.value !== 'boolean') {
                this.payload.value = false
              }
              break
            }
          }
          switch (property) {
            case 'telegramGateway_apiTokens':
            case 'd360ApiKeys': {
              if (typeof this.payload.value !== 'object' || !this.payload.value || Object.keys(this.payload.value).length === 0) {
                this.payload.value = cloneDeep(defaultD360Payload)
              }
              break
            }
            case 'forceMessageStatus': {
              if (
                typeof this.payload.value !== 'object' ||
                !Array.isArray(this.payload.value.conditions) ||
                this.payload.value.status !== undefined &&
                typeof this.payload.value.status !== 'string'
              ) {
                this.payload.value = {
                  conditions: [],
                  status: undefined
                }
              }
              break
            }
            case 'megafonTarget_campaignTimeWindowHrs': {
              if (!Array.isArray(this.payload.value)) {
                this.payload.value = []
              }
              break
            }
          }
        }
      },
      showDialog() {
        if (this.showDialog === false) {
          this.cancel()
        }
      },

      ServerId() {
        this.getProcessor()
      }
    },

    mounted() {
      this.getProcessor()
    },

    methods: {
      add() {
        this.proxy.push(this.payload)
        this.cancel()
      },
      cancel() {
        this.payload = cloneDeep(defaultPayload)
        if (this.editMode) {
          this.index = undefined
          this.editMode = false
        }
        this.showDialog = false
      },
      edit(index) {
        this.index = index
        this.payload = this.proxy[index]
        this.editMode = true
        this.showDialog = true
      },
      update() {
        this.proxy.splice(this.index, 1, this.payload)
        this.cancel()
      },

      async getProcessor() {
        if (this.ServerId) {
          const { data } = await Vue.$GRequest.get(services.servers, this.ServerId) || {}
          this.processorFromServer = data.processor
        } else {
          this.processorFromServer = undefined
          this.proxy.splice(0, this.proxy.length)
        }
      },

      valueProcessor(property, value) {
        switch (property) {
          case 'megafonTarget_campaignTimeWindowHrs': return value.map(Number)
          default: return value
        }
      },

      propertiesFilter({ whiteList, blackList }) {
        if (!this.$processor) {
          return true
        }
        if (Array.isArray(blackList) && blackList.length) {
          if (~blackList.indexOf(this.$processor)) {
            return false
          }
        }
        if (!Array.isArray(whiteList) || !whiteList.length) {
          return true
        }
        if (~whiteList.indexOf(this.$processor)) {
          return true
        }

        return false
      },
      searchValidator(searchValue, { description, group }) {
        searchValue = searchValue.toLocaleLowerCase()
        description = description.toLocaleLowerCase()
        const tag = group.tag.toLocaleLowerCase()
        const text = group.text.toLocaleLowerCase()

        return ~description.indexOf(searchValue) || ~tag.indexOf(searchValue) || ~text.indexOf(searchValue)
      },

      _inputFilter(data) {
        return Object.keys(data).reduce((result, key) => {
          result.splice(result.length, 0, {
            key,
            value: data[key]
          })

          return result
        }, [])
      },
      _outputFilter(data) {
        return data.reduce((result, item) => Object.assign(result, { [item.key]: this.valueProcessor(item.key, item.value) }), {})
      },

      _getProperty() {
        const key = this.payload.key
        if (key) {
          return this.properties.find(item => item.property === key)
        }

        return {}
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
