import Vue from 'vue'

import { get } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import { generateServices, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: `${services.messageRuleGroups}Tree`,

  mixins: [
    proxy({ type: 'array' }),

    generateServices([
      {
        name: services.messageRuleGroups,
        find: false,
        create: false,
        update: false,
        remove: false
      },
      {
        name: services.contactLists,
        find: false,
        create: false,
        update: false,
        remove: false
      }
    ])
  ],

  data() {
    return {
      showDialog: {
        full: false,
        direct: false,
        mccmnc: false,
        texts: false
      },
      showMenu: false,
      showConfirmRemoveMenu: {},

      groupSelector: undefined,
      rowState: {},

      MessageRules: {},

      mccmnc: undefined,
      texts: [],

      expandLoading: false,
      expanded: false
    }
  },

  watch: {
    proxy: {
      handler() {
        this.fillRowsRender()
      },
      deep: true
    }
  },

  methods: {
    async fillRowsRender() {
      if (!this.proxy.length) {
        return []
      }

      await Promise.all(this.proxy.map(async item => {
        const lastState = get(this.rowState, `${services.messageRuleGroups}.${item.id}`, 'closed')
        Vue.set(this.rowState, `${services.messageRuleGroups}.${item.id}`, 'loading')
        if (!item.title) {
          try {
            Object.assign(item, await this.rest[services.messageRuleGroups].get(item.id))
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
        Vue.set(this.rowState, `${services.messageRuleGroups}.${item.id}`, lastState)

        return item
      }))
    },

    messageRuleGroupsEventProcessing(id) {
      if (id) {
        if (!~this.proxy.findIndex(item => item.id === id)) {
          this.proxy.push({ id })
        }
      }
    },
    removeMessageRuleGroup(id) {
      if (id) {
        const index = this.proxy.findIndex(item => item.id === id)
        if (index > -1) {
          this.proxy.splice(index, 1)
          Vue.set(this.showConfirmRemoveMenu, id, false)
          Vue.delete(this.rowState, `${services.messageRuleGroups}.${id}`)
        }
      }
    },

    getRowState(service, id, defaults = 'closed') {
      return get(this.rowState, `${service}.${id}`, defaults)
    },

    closeRow(service, id, force = false) {
      if (force || this.getRowState(service, id) != 'closed') {
        Vue.set(this.rowState, `${service}.${id}`, 'closed')

        return this.getRowState(service, id)
      }
    },
    openRow(service, id, force = false) {
      if (force || this.getRowState(service, id) != 'opened') {
        Vue.set(this.rowState, `${service}.${id}`, 'opened')

        return this.getRowState(service, id)
      }
    },

    clickRow(event, options) {
      let pendingClick = 0

      if (pendingClick) {
        clearTimeout(pendingClick)
        pendingClick = 0
      }

      switch (event.detail) {
        case 1: {
          pendingClick = setTimeout(() => {
            if (typeof options.click === 'function') {
              options.click()
            }
          }, 300)
          break
        }
        case 2: {
          if (typeof options.dblclick === 'function') {
            options.dblclick()
          }
          break
        }
      }
    },

    async expandAll() {
      this.expandLoading = true

      return await Promise.all(this.proxy.map(async ({ id }) => await this.getAllEntities(services.messageRuleGroups, 'MessageRules', id))).then(() => {
        this.expandLoading = false
        this.expanded = !this.expanded
      })
    },

    async getEntities(service, association, id) {
      if (service && association && id) {
        switch (this.getRowState(service, id)) {
          case 'closed': {
            try {
              Vue.set(this.rowState, `${service}.${id}`, 'loading')

              const response = await this.rest[service].get(id, { query: { $scope: association } })
              let data = response[association]

              if (!this[association][id]) {
                this[association][id] = []
              }
              if (association === 'MessageRules') {
                data = await Promise.all(data.map(async item => {
                  item.conditions = await Promise.all(item.conditions.map(async condition => await this.conditionProcessor(condition)))
                  for (const tag in item.actions) {
                    item.actions[tag] = this.actionProcessor(tag, item.actions[tag])
                  }

                  return item
                }))
              }

              this[association][id].splice(0, data.length, ...data)
              this[association][id].sort((a, b) => a.priority - b.priority || a.percentage - b.percentage)

              return data
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            } finally {
              this.openRow(service, id)
            }
            break
          }
          case 'opened': {
            this.closeRow(service, id)
            break
          }
        }
      }
    },

    async getAllEntities(service, association, id) {
      if (service && id) {
        try {
          if (service === services.messageRuleGroups) {
            const entities = await this.getEntities(service, association, id)
            if (entities) {
              await Promise.all(entities.map(entity => {
                this.closeRow(services.messageRules, entity.id, true)
              }))
            }
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    },

    async conditionProcessor(condition) {
      switch (condition.tag) {
        case 'contactList': {
          condition.value = await Promise.all(condition.value.map(async id => {
            const response = await this.rest.contactLists.get(id)

            if (response) {
              return {
                id,
                title: response.title
              }
            }
          }))
          break
        }
        case 'type': {
          condition.value = condition.value.map(type => this.getTranslate(`${services.sendings}.types.${type}`))
          break
        }
      }

      return condition
    },

    actionProcessor(tag, action) {
      switch (tag) {
        case 'moderation': {
          return this.getTranslate(`misc.moderation.${action}`)
        }
      }

      return action
    }
  },

  render
}
