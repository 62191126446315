import { buttonModes, colors, components, services } from '@/utils'

import button from '@/components/button'

function renderCheckbox(h) {
  return h(
    components.checkbox,
    {
      props: { value: this.proxy },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
function renderLink(h) {
  return h(
    'div',
    {
      class: 'link link--dashed',
      on: {
        click: () => {
          this.showDialog = true
        }
      }
    },
    [ this.getTranslate(`${services.payments}.hints.offer`) ]
  )
}
function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        value: this.showDialog,
        rounded: true,
        maxWidth: 1000
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'pa-2',
          domProps: { innerHTML: this.html }
        }
      ),
      h(
        'div',
        {
          class: 'dialog__footer',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.accept'),
                color: colors.primary
              },
              on: {
                click: () => {
                  this.proxy = true
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  if (typeof this.html === 'string') {
    return h(
      'div',
      {
        class: `${services.payments}-offer`
      },
      [
        renderCheckbox.call(this, h),
        renderLink.call(this, h),
        renderDialog.call(this, h)
      ]
    )
  }
}
