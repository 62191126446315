import Vue from 'vue'

import { debounce } from 'lodash'

import { colors } from '@/utils'

import { name, paramsBySource, sources, utmParams } from '@/components/services/sendingsCreate/utm/utils'

import render from './render'

export default {
  name: 'UTM',

  data() {
    const showReference = {}
    for (const key in utmParams) {
      showReference[key] = false
    }
    const params = {}
    for (const key in paramsBySource[sources.custom]) {
      params[key] = paramsBySource[sources.custom][key]
    }

    return {
      showReference,

      protocol: undefined,
      url: undefined,
      shortLink: undefined,

      source: sources.custom,
      params,

      showDialog: false,
      loading: false
    }
  },

  watch: {
    url(value) {
      if (value) {
        this.url = value.replace(/https?:\/\//, '')
      }
    },

    source(value) {
      this.setDefaultsBySource(value)
    },

    fullLink(value) {
      if (value) {
        this.getShortLink()
      } else {
        this.shortLink = undefined
      }
    }
  },

  computed: {
    utmParams() {
      const result = []
      if (this.urlIsValid) {
        for (const key of Object.keys(this.params)) {
          if (this.params[key]) {
            result.push(`${key}=${this.params[key]}`)
          }
        }
      }

      return result.join('&')
    },

    cleanUrl() {
      if (this.url) {
        const result = []
        const url = this.url.replace(/https?:\/\//, '')
          .replace(/^\/+/g, '')
          .replace(/\/{2,}/g, '/')
          .replace(/&{2,}/g, '&')
          .replace(/\s/g, '')
          .replace(/\/*$/, '')
        const start = url.indexOf('?')
        if (start !== -1) {
          const path = url.slice(0, start)
          let params = url.slice(start, url.length)
          params = params.replace(/\?/g, '')
          result.push(path, '?', params)
        } else {
          result.push(url, '?')
        }

        return result.join('')
      }
    },
    urlIsValid() {
      if (!this.cleanUrl) {
        return false
      }

      const validUrlRegExp = /^([\da-zа-яё.-]+)\.([a-zа-яё.]{2,6})/i

      return validUrlRegExp.test(this.cleanUrl)
    },

    fullLink() {
      const result = []
      if (this.protocol && this.cleanUrl && this.urlIsValid) {
        const separator = this.cleanUrl.at(-1) === '?' ? '' : '&'
        const url = [ this.cleanUrl, this.utmParams ].join(separator)
        result.push(this.protocol, url)
      }

      return result.join('').toLowerCase()
    }
  },

  methods: {
    getShortLink: debounce(
      async function() {
        if (this.fullLink && this.urlIsValid) {
          this.loading = true
          try {
            const url = new URL('', 'https://clck.ru/--')
            const search = new URLSearchParams()
            search.set('url', this.fullLink)
            url.search = search.toString()
            const response = await fetch(url.toString())
            if (response.ok) {
              this.shortLink = await response.text()
            }
          } catch {
            this.addSnackbar({
              text: this.getTranslate(`${name}.snackbars.error`),
              type: colors.error
            })
          } finally {
            this.loading = false
          }
        }
      },
      500,
      {
        leading: false,
        trailing: true
      }
    ),

    setDefaultsBySource(source) {
      source = source || this.source
      for (const key in this.params) {
        Vue.set(this.params, key, paramsBySource[source][key])
      }
    }
  },

  render
}
