import { cloneDeep, isEqual } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

export const keyValue = {
  mixins: [ proxy({ type: 'object' }) ],

  props: {
    defaultValue: {
      type: Object,
      default() {
        return {
          key: '',
          value: ''
        }
      }
    }
  },

  methods: {
    addField() {
      this.proxy.push(cloneDeep(this.defaultValue))
    },
    removeField(index) {
      this.proxy.splice(index, 1)
    },

    _inputFilter(data) {
      const result = Object.keys(data).reduce((result, key) => {
        result.splice(result.length, 0, {
          key,
          value: data[key]
        })

        return result
      }, [])

      if (result.length === 0) {
        result.splice(0, 0, cloneDeep(this.defaultValue))
      }

      return result
    },
    _outputFilter(data) {
      const result = data.reduce((result, item) => Object.assign(result, { [item.key]: item.value }), {})

      if (Object.keys(result).length === 0) {
        this.proxy.push(cloneDeep(this.defaultValue))
      }

      return result
    },

    receiveValue() {
      const value = this._inputFilter(this.value)
      if (!isEqual(this.proxy, value)) {
        this.proxy = value
      }
    },

    transmitValue() {
      const proxy = this._outputFilter(this.proxy)
      if (!isEqual(proxy, this.value)) {
        this.$emit('input', proxy)
      }
    }
  }
}

export default { keyValue }
