import Vue from 'vue'

import { debounce } from 'lodash'

import { Macro } from '@sigma-legacy-libs/cache'

import { globalErrorHandler, globalErrorProcessor, isUUID, orderDirections, permissionPresets, services } from '@/utils'

import render from './render'

const Cache = new Macro({ ttl: 5 * 60 * 1000 })

const cachedFind = Cache.wrapWithCache(async (key, query) => {
  return await Vue.$GRequest.find(services.contacts, { query })
})

export default {
  name: 'ContactsTag',

  props: {
    label: {
      type: String,
      default: undefined
    },

    ListId: {
      type: String,
      default: undefined
    },

    cancel: {
      type: Function,
      default: undefined
    }
  },

  data() {
    return {
      loading: false,
      items: [],
      total: 0
    }
  },

  computed: {
    tooltip() {
      if (isUUID(this.ListId)) {
        if (this.loading) {
          return this.getTranslate(`${services.contacts}.tooltips.loading`)
        } else if (this.items.length) {
          if (this.total > 10) {
            this.items.push(this.getTranslate(`${services.contacts}.tooltips.total`, { total: this.total }))
          }

          return this.items.join('\n')
        }

        return this.getTranslate(`${services.contacts}.tooltips.noData`)
      }
    },

    $props() {
      return {
        as: 'select',
        label: this.label,
        tooltip: this.tooltip,
        cancelable: true,
        cancel: () => this.cancel()
      }
    }
  },

  methods: {
    async _getContacts() {
      try {
        if (this.checkPermissions(`advanced.${services.contacts}.get`, permissionPresets.meUp)) {
          if (isUUID(this.ListId)) {
            this.loading = true
            this.items = []
            const { data } = await cachedFind(`${services.contacts}:get:${this.ListId}`, {
              ListId: this.ListId,
              $offset: 0,
              $limit: 10,
              $order: [ [ 'createdAt', orderDirections.DESC ] ],
              isActive: true
            })
            if (data?.data && data?.data?.length) {
              for (const { phone } of data.data) {
                if (phone) {
                  this.items.push(phone)
                }
              }
              this.total = data?.total
            }
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    },

    getContacts: debounce(
      async function() {
        await this._getContacts()
      },
      100,
      {
        leading: true,
        trailing: true
      }
    )
  },

  render
}
