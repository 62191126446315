import Vue from 'vue'
import { mapActions } from 'vuex'

import { cloneDeep, get, isPlainObject, merge, pick, set } from 'lodash'

import { Macro } from '@sigma-legacy-libs/cache'
import { EMPTY_SENDINGS, SENDING_PAYLOADS, SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import {
  currentTab,
  generateServices,
  getEmptyFallback,
  getHammerTime,
  getLocaleDateString,
  getLocaleTimeString,
  globalErrorHandler,
  globalErrorProcessor,
  isArrayNotEmpty,
  isStringNotEmpty,
  isUUID,
  sendingsOutputFilter,
  services
} from '@/utils'

import render from './render'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

const defaultDate = {
  until: 0,
  delay: {
    date: undefined,
    time: getHammerTime()
  }
}

const sendingValidator = data => {
  const { type, payload, $options } = data

  if (!type || !~SENDING_TYPES.indexOf(type)) {
    return false
  }

  if ($options && $options.onTimeout) {
    if ($options.onTimeout.timeout && $options.onTimeout.timeout < 10) {
      return false
    }
  }

  if (payload && !payload.sender) {
    return false
  }

  switch (type) {
    case SENDING_TYPES.voice: {
      return !!payload.text && !!payload.tts || !!payload.audio
    }
    case SENDING_TYPES.viber: {
      const buttonText = get(payload, 'button.text')
      const buttonUrl = get(payload, 'button.url')
      const hasValidButton = buttonText && buttonText.length <= 30 && buttonUrl && buttonUrl.length <= 2048

      if (payload.text) {
        if (buttonText || buttonUrl) {
          return !!hasValidButton
        } else {
          return !!payload.text
        }
      }

      return false
    }
    case SENDING_TYPES.whatsapp: {
      return !!payload.image || !!payload.file || !!payload.text
    }

    default: {
      return !!payload.text
    }
  }
}

export default {
  name: 'SendingsCreateTemplate',

  mixins: [
    generateServices([
      {
        name: services.sendings,

        outputFilter: sendingsOutputFilter,

        find: false,
        get: false,
        update: false,
        remove: false,
        create: {
          async method(data, params = {}) {
            try {
              const { data: result } = await Vue.$GRequest.create(services.sendings, data, params)
              if (result.status === 'failed') {
                throw new Error(result.error)
              }

              return result
            } catch (error) {
              throw error
            }
          },
          params: {
            query: {
              $scope: [ 'full' ]
            }
          }
        }
      },
      {
        name: services.templates,

        outputFilter: sendingsOutputFilter,

        find: false,
        get: false,
        update: false,
        remove: false,

        create: {
          params: {
            query: {
              $scope: [ 'full' ]
            }
          }
        }
      }
    ]),

    currentTab('create')
  ],

  data() {
    return {
      showConfirmCreateSendings: false,
      showCreateTemplates: false,
      showEditTemplate: false,
      showScheduled: false,
      showDelayMenu: false,
      showUntilMenu: false,
      showMessagePreview: false,

      timezoneOffset: this.$store.getters['global/date'].getTimezoneOffset(),
      changeableDates: cloneDeep(defaultDate),

      recipientsError: undefined,

      template: undefined,
      isTemplate: false
    }
  },

  computed: {
    title() {
      const include = this.restData[services.sendings].create.data.recipient.include

      return [
        this.getTranslate(`${services.sendings}.confirm.send.title`),
        this.getTranslateTemplate(`${services.sendings}.confirm.send.count`, { count: include ? include.length : 1 })
      ].join('')
    },

    recipientValidation() {
      const { recipient } = this.restData[services.sendings].create.data

      return recipient && Array.isArray(recipient.include) && recipient.include.length > 0
    },

    payloadValidation() {
      return sendingValidator(this.restData[services.sendings].create.data)
    },

    fallbacksValidation() {
      const { fallbacks } = this.restData[services.sendings].create.data

      if (Array.isArray(fallbacks) && fallbacks.length) {
        return fallbacks.every(fallback => sendingValidator(fallback))
      } else {
        return true
      }
    },

    createDisabled() {
      return !(this.recipientValidation && this.payloadValidation && this.fallbacksValidation)
    },

    computedDate() {
      let delay = 0
      if (this.changeableDates.delay.date) {
        const hours = ('' + Math.floor(Math.abs(this.timezoneOffset / 60))).padStart(2, '0')
        const minutes = ('' + Math.abs(this.timezoneOffset % 60)).padStart(2, '0')
        const timezone = (this.timezoneOffset > 0 ? '-' : '+') + hours + ':' + minutes
        this.dateString = this.changeableDates.delay.date + 'T' + this.changeableDates.delay.time + ':00' + timezone
        delay = new Date(this.changeableDates.delay.date + 'T' + this.changeableDates.delay.time + ':00' + timezone)
      }

      let until
      if (this.changeableDates.until > 0) {
        until = new Date(delay)
        until.setHours(until.getHours() + this.changeableDates.until)
      }

      return {
        delay,
        until
      }
    },

    computedTypeRecipient() {
      const data = this.restData[services.sendings].create.data
      if (
        data.schedule && data.schedule.delay ||
        data.recipient.include.length > 100 ||
        data.recipient.include.find(isUUID) ||
        data.recipient.exclude && data.recipient.exclude.length
      ) {
        return 'bulk'
      }
      if (isArrayNotEmpty(data.recipient)) {
        return 'butch'
      }
      if (isStringNotEmpty(data.recipient)) {
        return 'single'
      }
    },

    fallbacks() {
      return this.restData[services.sendings].create.data.fallbacks.length > 0
    },

    dateScheduleDelay() {
      if (this.computedDate && this.computedDate.delay) {
        return `${getLocaleDateString(this.computedDate.delay)} ${getLocaleTimeString(this.computedDate.delay)}`
      }

      return undefined
    }
  },

  watch: {
    computedDate: {
      handler() {
        this.restData[services.sendings].create.data.schedule = this.computedDate
      },
      deep: true
    },

    template(value) {
      if (value) {
        this.setTemplate(value)
      }
    },

    [`restData.${services.sendings}.create.data.type`]() {
      if (!this.template) {
        this.isTemplate = false
      } else {
        this.template = undefined
      }
    }
  },

  methods: {
    cachedGet: Cache.wrapWithCache(async (key, id) => {
      return await Vue.$GRequest.get(`${services.templates}`, id)
    }),

    async setTemplate(id) {
      try {
        const { data } = await this.cachedGet(`${services.templates}:${id}`, id)
        if (data) {
          const template = pick(data, [ 'type', 'payload', 'recipient', 'fallbacks' ])

          for (const key in template) {
            const element = template[key]
            switch (key) {
              case 'type': {
                if (typeof element === 'string' && element) {
                  this.restData[services.sendings].create.data[key] = element
                }
                break
              }
              case 'payload': {
                for (const path of SENDING_PAYLOADS[template.type]) {
                  if (path !== 'recipient') {
                    const value = get(element, path, undefined)
                    set(this.restData[services.sendings].create.data.payload, path, value)
                  }
                }
                break
              }
              case 'recipient': {
                if (isPlainObject(element)) {
                  for (const prop in element) {
                    this.restData[services.sendings].create.data.recipient[prop] = []
                    for (const value of element[prop]) {
                      this.restData[services.sendings].create.data.recipient[prop].push(value)
                    }
                  }
                }
                break
              }
              case 'fallbacks': {
                if (Array.isArray(element) && element.length > 0) {
                  this.restData[services.sendings].create.data[key] = element
                }
                break
              }
            }
          }

          this.setCurrentTab({
            name: 'create',
            to: { name: 'create' }
          })
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.isTemplate = true
      }
    },

    templateRecipient(recipient) {
      if (
        Array.isArray(this.restData[services.sendings].create.data.recipient.include) &&
        !this.restData[services.sendings].create.data.recipient.include.length
      ) {
        Vue.set(this.restData[services.sendings].create.data.recipient, 'include', recipient.include)
      }
      if (
        Array.isArray(this.restData[services.sendings].create.data.recipient.exclude) &&
        !this.restData[services.sendings].create.data.recipient.exclude.length
      ) {
        Vue.set(this.restData[services.sendings].create.data.recipient, 'exclude', recipient.exclude)
      }
    },

    saveTemplate() {
      this.restData.templates.create.data = merge(
        {},
        { OwnerId: this.account.id },
        pick(cloneDeep(this.restData[services.sendings].create.data), [
          'type',
          'payload',
          'recipient',
          'schedule',
          'fallbacks'
        ])
      )
      this.showCreateTemplates = true
    },

    setFallbacks() {
      this.restData[services.sendings].create.data.fallbacks.splice(
        this.restData[services.sendings].create.data.fallbacks.length,
        0,
        getEmptyFallback()
      )
    },

    async createSending() {
      try {
        const result = await this.rest[services.sendings].create(this.restData[services.sendings].create.data)
        if (result) {
          this.restData[services.sendings].create.data = cloneDeep(EMPTY_SENDINGS)
          this.changeableDates = cloneDeep(defaultDate)
          this.showScheduled = false
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.showConfirmCreateSendings = false
      }
    },
    async createTemplate() {
      try {
        const { id } = await this.rest.templates.create(this.restData.templates.create.data)
        if (id) {
          this.showCreateTemplates = false
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    },

    ...mapActions({ setCurrentTab: 'currentTab/setCurrentTab' })
  },

  render
}
