import { mapActions, mapGetters } from 'vuex'

import render from './render'

export default {
  name: 'Locale',

  computed: {
    ...mapGetters({
      locale: 'locale/locale',
      locales: 'locale/locales'
    })
  },

  methods: {
    ...mapActions({ setLocale: 'locale/setLocale' })
  },

  render
}
