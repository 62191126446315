import { mapActions, mapGetters } from 'vuex'

import { audioStatuses } from '@/utils'

import render from './render'

export default {
  name: 'AudioPlayer',

  data() {
    return {
      audio: undefined
    }
  },

  mounted() {
    if (!this.audio) {
      this.audio = document.getElementById('audio')
    }

    this.audio.addEventListener('loadstart', this.loadStartHandler)
    this.audio.addEventListener('loadedmetadata', this.loadedHandler)
    this.audio.addEventListener('timeupdate', this.timeUpdateHandler)
    this.audio.addEventListener('durationchange', this.durationChangeHandler)
    this.audio.addEventListener('play', this.playHandler)
    this.audio.addEventListener('pause', this.pauseHandler)
    this.audio.addEventListener('ended', this.endedHandler)
  },

  beforeDestroy() {
    this.audio.pause()

    this.audio.removeEventListener('loadstart', this.loadstartHandler)
    this.audio.removeEventListener('loadedmetadata', this.loadedHandler)
    this.audio.removeEventListener('timeupdate', this.timeUpdateHandler)
    this.audio.removeEventListener('durationchange', this.durationChangeHandler)
    this.audio.removeEventListener('play', this.playHandler)
    this.audio.removeEventListener('pause', this.pauseHandler)
    this.audio.removeEventListener('ended', this.endedHandler)
  },

  computed: {
    ...mapGetters({
      src: 'audio/src',
      status: 'audio/status'
    })
  },

  methods: {
    loadStartHandler() {
      this.setDuration()
      this.setCurrentTime()
      this.setStatus(audioStatuses.loading)
    },
    loadedHandler() {
      this.setStatus(audioStatuses.play)
    },
    playHandler() {
      this.setDuration(this.audio.duration)
      this.setStatus(audioStatuses.play)
    },
    pauseHandler() {
      this.setStatus(audioStatuses.pause)
    },
    timeUpdateHandler() {
      this.setCurrentTime(this.audio.currentTime)
    },
    durationChangeHandler() {
      this.setDuration(this.audio.duration)
    },
    endedHandler() {
      setTimeout(() => {
        this.setDuration()
        this.setCurrentTime()
      }, 1000)
    },

    ...mapActions({
      setCurrentTime: 'audio/setCurrentTime',
      setDuration: 'audio/setDuration',
      setStatus: 'audio/setStatus'
    })
  },

  watch: {
    src(value) {
      if (value) {
        this.audio.src = value
      }
    },

    status(value) {
      switch (value) {
        case audioStatuses.play:
          this.audio.play()
          break
        case audioStatuses.loading:
        case audioStatuses.pause:
          this.audio.pause()
          break
      }
    }
  },

  render
}
