import Vue from 'vue'

import { isEqual } from 'lodash'

import Conditions from '@sigma-legacy-libs/essentials/lib/utils/Conditions'

import { ruleTags, ruleTypes } from '@/components/misc/rules/utils'
import { ruleProcessor } from '@/components/services/tariffs/utils'

import render from './render'

const rulesMethodsByType = {
  [ruleTypes.conditions]: {
    _inputFilter(data) {
      const result = {}
      for (const key in Conditions.tagsMeta) {
        if (this.whitelist.length) {
          if (!~this.whitelist.indexOf(key)) {
            continue
          }
        }
        if (this.blacklist.length) {
          if (~this.blacklist.indexOf(key)) {
            continue
          }
        }
        const tag = Conditions.tagsMeta[key].tag
        if (!result[tag]) {
          result[tag] = ruleProcessor(undefined, tag)
        }
      }
      if (Array.isArray(data)) {
        data.reduce((accumulator, { path, type, tag, value }) => {
          const tagMeta = Conditions.tagsMeta[path || type || tag]
          if (tagMeta) {
            accumulator[tagMeta.tag] = ruleProcessor(value, tagMeta.tag)
          }

          return accumulator
        }, result)
      }

      return result
    },

    _outputFilter(data) {
      const result = []
      for (const tag in data) {
        const value = data[tag]
        if (Conditions.validateValue(value, tag)) {
          result.push({
            tag,
            value
          })
        }
      }

      return result
    }
  },
  [ruleTypes.actions]: {
    _inputFilter(data) {
      const result = data
      if (!result[ruleTags.sender]) {
        Vue.set(result, ruleTags.sender, [])
      }

      return result
    },

    _outputFilter(data) {
      if (Array.isArray(data[ruleTags.sender] && !data[ruleTags.sender].length)) {
        delete data[ruleTags.sender]
      }

      return data
    }
  }
}

export default {
  name: 'RulesTemplate',

  props: {
    value: null,

    whitelist: {
      type: Array,
      default: () => []
    },
    blacklist: {
      type: Array,
      default: () => []
    },

    ruleType: {
      type: String,
      default: ruleTypes.conditions,
      validator: value => {
        return ~Object.values(ruleTypes).indexOf(value)
      }
    }
  },

  data() {
    return {
      proxy: this.value,

      tag: undefined,
      showDialog: false
    }
  },

  watch: {
    value: {
      handler() {
        this.receiveValue()
      },
      deep: true
    },
    proxy: {
      handler() {
        this.transmitValue()
      },
      deep: true
    }
  },

  mounted() {
    this.receiveValue()
  },

  methods: {
    _inputFilter(data) {
      return rulesMethodsByType[this.ruleType]._inputFilter.call(this, data)
    },

    _outputFilter(data) {
      return rulesMethodsByType[this.ruleType]._outputFilter.call(this, data)
    },

    _tagFilter(tag) {
      if (this.whitelist.length) {
        if (!~this.whitelist.indexOf(tag)) {
          return false
        }
      }
      if (this.blacklist.length) {
        if (~this.blacklist.indexOf(tag)) {
          return false
        }
      }

      return true
    },

    receiveValue() {
      const value = this._inputFilter(this.value)
      if (!isEqual(this.proxy, value)) {
        this.proxy = value
      }
    },
    transmitValue() {
      const proxy = this._outputFilter(this.proxy)
      if (!isEqual(proxy, this.value)) {
        this.$emit('input', proxy)
      }
    }
  },

  render
}
