export default {
  title: 'Almacenamiento',

  titles: {
    locks: 'Bloqueos',
    properties: 'Propiedades del archivo',
    editLocks: 'Editar bloqueos del archivo'
  },

  headers: {
    title: 'Título',
    owner: 'Propietario',
    size: 'Tamaño',
    createdAt: 'Fecha de creación',
    expiredAt: 'Fecha de expiración'
  },

  labels: {
    title: 'Título del archivo'
  },

  hints: {
    emptyState: 'No hay archivos para mostrar.',
    expire: 'Este es un almacenamiento temporal. Todos los archivos tienen una fecha de expiración y serán eliminados al alcanzarla.',
    export: 'Por favor espere, su archivo PDF se está generando',
    locks:
      'Este archivo se usa en uno o más servicios y no será eliminado automáticamente al alcanzar la fecha de expiración.',
    editData: 'Editar contenido del archivo',
    editMeta: 'Editar información del archivo'
  },

  prefixes: {
    id: 'ID',
    filename: 'Nombre del archivo',
    size: 'Tamaño',
    owner: 'Propietario',
    locked: 'Bloqueado',
    locks: 'Bloqueado en los siguientes servicios',
    hash: 'Hash',
    duration: 'Duración',
    channels: 'Canales',
    sampleRate: 'Frecuencia de muestreo',
    bitsPerSample: 'Bits por muestra',
    willBeDeleted: 'El archivo será eliminado',
    createdAt: 'Fecha de creación',
    updatedAt: 'Fecha de actualización',
    expired: 'Fecha de expiración',
    type: 'Tipo de archivo'
  },

  buttons: {
    setExpiredAt: 'Establecer fecha de expiración',
    editLocks: 'Editar bloqueos',
    properties: 'Propiedades del archivo'
  },

  snackbars: {
    created: 'Archivo creado exitosamente.',
    updated: 'Archivo actualizado exitosamente.',
    removed: 'Archivo eliminado exitosamente.'
  }
}
