import { buttonModes, colors, icons, sizes } from '@/utils'

import button from '@/components/button'
import squircle from '@/components/icon/squircle'
import rulesConditionsRowMCCMNC from '@/components/misc/rules/conditions/mccmnc'
import { className, ruleIcons, ruleTags } from '@/components/misc/rules/utils'
import tag from '@/components/tag'

function renderLabel(h) {
  return h(
    'div',
    {
      class: `${className}-item__holder-label`
    },
    [ this.getTranslate(`${className}.tags.${this.tag}`) ]
  )
}

function renderTag(h, item, trigger = true) {
  if (trigger) {
    return h(
      tag,
      {
        props: {
          label: item.label || item,
          color: item.color || colors.primary,
          size: sizes.small
        }
      }
    )
  }
}

function renderTagsArray(h) {
  return [
    ...this.$value.map(item => {
      return renderTag.call(this, h, item)
    }),
    renderTag.call(this, h, {
      label: `+${this.value.length - this.maxTags}`,
      color: colors.black
    }, this.value.length - this.maxTags > 0)
  ]
}

function renderValueByTag(h) {
  switch (this.tag) {
    case ruleTags.mccmnc: {
      return h(
        rulesConditionsRowMCCMNC,
        {
          props: {
            value: this.value,
            count: this.maxTags
          }
        }
      )
    }
    default: {
      if (Array.isArray(this.$value) && this.$value.length) {
        return renderTagsArray.call(this, h)
      }

      return renderTag.call(this, h, this.$value)
    }
  }
}

function renderValue(h) {
  if (this.hasValue) {
    return h(
      'div',
      {
        class: `${className}-item__holder-value`
      },
      [ renderValueByTag.call(this, h) ]
    )
  }

  return renderLabel.call(this, h)
}

function renderIcon(h) {
  return h(
    squircle,
    {
      props: {
        icon: ruleIcons[this.tag],
        color: colors.grey,
        size: sizes.medium,
        tooltip: this.getTranslate(`${className}.tags.${this.tag}`)
      }
    }
  )
}

function renderItemHolder(h) {
  if (this.tag) {
    return h(
      'div',
      {
        class: {
          [`${className}-item__holder`]: true,
          [`${className}-item__holder--empty`]: !this.hasValue
        },
        on: {
          click: () => {
            this.$emit('dialog', true)
          }
        }
      },
      [ renderValue.call(this, h) ]
    )
  }
}

function renderClearButton(h) {
  if (this.clearable) {
    return h(
      button,
      {
        class: { 'hover-child': this.viewport.breakpoint.mdUp },
        props: {
          icon: icons.clear,
          mode: buttonModes.flat,
          disabled: !this.hasValue || this.readonly
        },
        on: {
          click: () => {
            this.$emit('clear', true)
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        [`${className}-item hover-parent hover-parent--opacity`]: true,
        [`${className}-item--clearable`]: this.clearable
      }
    },
    [
      renderIcon.call(this, h),
      renderItemHolder.call(this, h),
      renderClearButton.call(this, h)
    ]
  )
}
