import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  data() {
    return {
      passwordVisibility: true
    }
  },

  computed: {
    validation() {
      return !!this.proxy.username && !!this.proxy.password
    }
  },

  watch: {
    validation(value) {
      this.$emit('validation', value)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  render
}
