export default {
  RUB: {
    title: 'рубль',
    short: 'руб.',
    full: '₽ рубль',
    symbol: {
      html: '&#8381;',
      unicode: '₽'
    }
  },

  USD: {
    title: 'доллар',
    short: 'USD',
    full: '$ доллар',
    symbol: {
      html: '&#36;',
      unicode: '$'
    }
  },

  EUR: {
    title: 'евро',
    short: 'EUR',
    full: '€ евро',
    symbol: {
      html: '&#8364;',
      unicode: '€'
    }
  },

  MXN: {
    title: 'мексиканское песо',
    short: 'MXN',
    full: 'MX$ мексиканское песо',
    symbol: {
      html: 'MX&#36;',
      unicode: 'MX$'
    }
  }
}
