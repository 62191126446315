import { get } from 'lodash'

import { buttonModes, colors, components, inputModes, services } from '@/utils'

import button from '@/components/button'
import info from '@/components/info'
import preloader from '@/components/preloader'
import { opfListShort } from '@/components/services/requisites/utils'
import tag from '@/components/tag'
import textOverflow from '@/components/text/overflow'

function renderPreloader(h) {
  if (this.loading) {
    return h(
      'div',
      {
        class: 'preloader__holder',
        style: { minHeight: '50px' }
      },
      [ h(preloader) ]
    )
  }
}

function renderSelect(h) {
  if (this.items.length) {
    return h(
      components.select,
      {
        props: {
          value: this.proxy,
          label: this.getTranslate(`${services.invoices}.labels.requisite`),
          items: this.items,
          itemValue: 'id',
          picks: [ 'id', 'title', 'data' ],
          mode: inputModes['line-label'],
          loading: this.loading,
          disabled: this.loading || this.disabled,
          rounded: true,
          required: true,
          details: false
        },
        scopedSlots: {
          selection: ({ item }) => {
            const opf = get(item, 'data.legal.opf')
            const name = get(item, 'data.legal.name')
            const vat = get(item, 'data.tax.vat')

            return h(
              'div',
              {
                class: 'w--100 grid grid-gap--8 faic fjcsb',
                style: { gridTemplateColumns: 'repeat(2, auto)' }
              },
              [
                h(
                  tag,
                  {
                    props: {
                      label: [ opfListShort[opf], name ].filter(Boolean).join(' '),
                      as: 'select'
                    }
                  }
                ),
                h(
                  tag,
                  {
                    props: {
                      label: this.getTranslate(`${services.requisites}.vat.${vat}`),
                      color: colors.primary,
                      as: 'select'
                    }
                  }
                )
              ]
            )
          },
          item: ({ item }) => {
            const opf = get(item, 'data.legal.opf')
            const name = get(item, 'data.legal.name')
            const vat = get(item, 'data.tax.vat')

            return h(
              'div',
              {
                class: 'w--100 grid grid-gap--8 faic fjcsb',
                style: { gridTemplateColumns: '1fr auto' }
              },
              [
                h(
                  'div',
                  {
                    class: 'grid grid-gap--0'
                  },
                  [
                    h(
                      textOverflow,
                      {
                        props: {
                          value: name
                        }
                      }
                    ),
                    h(
                      textOverflow,
                      {
                        class: 'caption text--grey',
                        props: {
                          value: opf
                        }
                      }
                    )
                  ]
                ),
                h(
                  tag,
                  {
                    props: {
                      label: this.getTranslate(`${services.requisites}.vat.${vat}`),
                      color: colors.primary,
                      as: 'select'
                    }
                  }
                )
              ]
            )
          }
        },
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}

function renderWarning(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        info,
        {
          props: {
            value: this.customerId === this.account.id
              ? this.getTranslate(`${services.invoices}.warnings.notFound.requisites`)
              : this.getTranslate(`${services.invoices}.warnings.notFound.requisitesForAdmin`),
            color: colors.error
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate(`${services.invoices}.labels.requisites`),
            mode: buttonModes.flat,
            color: colors.primary
          },
          on: {
            click: () => {
              this.redirect()
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return renderPreloader.call(this, h) || renderSelect.call(this, h) || renderWarning.call(this, h)
}
