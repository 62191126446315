import Vue from 'vue'

import { get } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import { getRUDate, globalErrorHandler, globalErrorProcessor, isValidEmail, isValidPhone, leadZero, services } from '@/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    ListId: String,
    isFilter: Boolean
  },

  data() {
    return {
      show: false,
      permanent: false
    }
  },

  computed: {
    computedDate() {
      if (this.proxy.date) {
        return getRUDate(this.proxy.date)
      }

      return this.getTranslate(`${services.contacts}.subheaders.date`)
    },

    customFields() {
      const result = []
      for (let index = 1; index <= 20; index++) {
        result.push(`custom${leadZero(index)}`)
      }

      return result
    },

    errors() {
      const result = {
        phone: undefined,
        email: undefined
      }

      if (!this.isFilter) {
        if (this.proxy.phone && !isValidPhone(this.proxy.phone)) {
          result.phone = this.getTranslate(`${services.contacts}.errors.phone`)
        }

        if (this.proxy.email && !isValidEmail(this.proxy.email)) {
          result.email = this.getTranslate(`${services.contacts}.errors.email`)
        }
      }

      return result
    },

    required() {
      const result = {
        phone: false,
        email: false
      }

      if (!this.isFilter) {
        if (!this.proxy.email || !!this.emailValidation) {
          result.phone = true
        }
        if (!this.proxy.phone || !!this.phoneValidation) {
          result.email = true
        }
      }

      return result
    },

    validation() {
      return isValidPhone(this.proxy.phone) || isValidEmail(this.proxy.email)
    }
  },

  watch: {
    ListId(value) {
      this.proxy.ListId = value
      this.checkPermanent()
    },

    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.proxy.ListId = this.ListId
    this.$emit('validation', this.validation)
    this.checkPermanent()
  },

  methods: {
    async checkPermanent() {
      if (!this.isFilter) {
        if (this.checkPermissions(`advanced.${services.contacts}.update`)) {
          try {
            const { data } = await Vue.$GRequest.find(services.settingsSite)
            if (data) {
              const mainListId = get(data, 'globalBlacklistManager.mainListId')
              if (mainListId) {
                this.permanent = mainListId === this.proxy.ListId
              }
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    }
  },

  render
}
