export default {
  title: 'Listas de palabras clave',

  one: 'Lista de palabras clave',
  many: 'Listas de palabras clave',

  titles: {
    create: 'Crear lista de palabras clave'
  },

  labels: {
    title: 'Título',
    search: 'Buscar'
  },

  dialogs: {
    new: 'Nueva lista de palabras clave'
  },

  hints: {
    notFound: 'Lista de palabras clave no encontrada'
  },

  contents: {
    confirm: {
      remove: '¿Confirmar eliminación de {title}?'
    }
  },

  tooltips: {
    isActive: 'Activar o desactivar la lista de palabras clave.',
    favorites: 'Agregar lista de palabras clave a favoritos',
    active: 'Activo',
    notActive: 'Inactivo'
  },

  snackbars: {
    created: 'Lista de palabras clave creada con éxito.',
    updated: 'Lista de palabras clave actualizada con éxito.',
    removed: 'Lista de palabras clave eliminada con éxito.'
  }
}
