import { components, services } from '@/utils'

import eventsHistoryTab from '@/components/services/eventsHistory/tab'
import providersPrices from '@/components/services/providers/prices'

function renderTabContent(h) {
  const ProviderId = this.$route.params.id
  switch (this.currentTab) {
    case services.providersPrices: {
      return h(
        providersPrices,
        {
          props: {
            ProviderId
          }
        }
      )
    }
    case services.eventsHistory: {
      return h(
        eventsHistoryTab,
        {
          props: {
            entityId: ProviderId
          }
        }
      )
    }
  }
}

function renderTab(h, key) {
  return h(
    components.card,
    {
      props: {
        rounded: true,
        outline: true,
        width: '100%'
      },
      key
    },
    [ renderTabContent.call(this, h) ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        components['tabs-header'],
        {
          props: {
            value: this.currentTab,
            tabs: this.tabs.map(key => {
              return {
                key,
                title: this.getTranslate(`commons.tabs.${key}`)
              }
            })
          },
          on: {
            input: event => {
              this.currentTab = event
            }
          }
        }
      ),
      h(
        components.tabs,
        {
          props: { value: this.currentTab },
          scopedSlots: {
            body: () => this.tabs.map(tab => renderTab.call(this, h, tab))
          }
        }
      )
    ]
  )
}
