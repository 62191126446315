export default {
  title: 'Nombres de remitentes',
  sendername: 'Nombre del remitente',

  titles: {
    new: 'Nuevo nombre del remitente',
    create: 'Crear nuevo nombre del remitente',
    users: 'Usuarios',
    comments: 'Comentarios'
  },

  headers: {
    moderation: 'Moderación',
    sendername: 'Nombre del remitente',
    comment: 'Comentario'
  },

  labels: {
    name: 'Nombre del remitente',
    type: 'Tipo de envío',
    moderation: 'Moderación',
    owner: 'Propietario',
    isActive: 'Activar o desactivar el nombre del remitente',
    meta: {
      comment: 'Organización y NIF',
      adminComment: 'Comentario del administrador',
      adminCommentPrivate: 'Comentario privado del administrador',
      resellerComment: 'Comentario del revendedor',
      resellerCommentPrivate: 'Comentario privado del revendedor'
    },
    confirmationCode: 'Introduzca el código',
    searchAndAdd: 'Buscar y añadir'
  },

  statuses: {
    disabled: 'No solicitado',
    requested: 'Solicitado',
    moderation: 'En moderación',
    approved: 'Aprobado',
    rejected: 'Rechazado',
    pending: 'Pendiente'
  },

  contents: {
    confirm: {
      remove: '¿Eliminar el nombre del remitente?'
    }
  },

  hints: {
    meta: {
      comment: 'Mínimo 10, máximo 150 caracteres'
    },
    emptyState: 'No se ha encontrado ningún nombre de remitente',
    confirmationCode: {
      again: 'Solicitar el código de nuevo',
      timer: 'Se puede solicitar el código de nuevo en: {count} seg'
    },
    enterAndAdd: 'Introduzca el nombre del remitente y presione ENTER para añadirlo',
    notFound: 'No se han encontrado nombres de remitentes'
  },

  warnings: {
    meta: {
      comment: 'Para pasar la moderación con éxito, debe introducir el nombre de su organización y el NIF en el campo anterior.'
    },

    name: {
      sms: 'El nombre debe cumplir con las siguientes condiciones:<br>- longitud de 4 a 11 caracteres,<br>- letras minúsculas o mayúsculas del alfabeto latino [a-z, A-Z],<br>- números [0-9],<br>- caracteres especiales [!, ?, -, _, .],<br>- paréntesis [( )],<br>- no debe contener espacios.',
      voice: 'El nombre debe cumplir con las siguientes condiciones:<br>- longitud de 6 a 15 caracteres,<br>- solo números.'
    },

    no: {
      names: 'No se ha encontrado ningún nombre de remitente aprobado.<br>Puede crear uno nuevo haciendo clic en el botón "+" a la derecha.'
    }
  },

  examples: {
    meta: {
      comment: 'Ejemplo de relleno: S.L. "Empresa" NIF 1234567890'
    }
  },

  tooltips: {
    create: 'Crear nuevo nombre del remitente',
    favorites: 'Hacer el nombre del remitente favorito',
    edit: 'Editar nombre del remitente',
    filter: {
      isActive: 'Mostrar nombres de remitentes desactivados'
    },
    copy: 'Copiar nombre al portapapeles',
    approve: 'Aprobar',
    rejectForReason: 'Rechazar por razón...',
    reject: 'Rechazar'
  },

  errors: {
    sms: 'Solo letras latinas o números, no más de 11 caracteres',
    voice: 'Solo números, de 6 a 15 caracteres'
  },

  buttons: {
    users: 'Usuarios {count}',
    confirmationCode: 'Obtener código de confirmación',
    approve: 'Aprobar',
    rejectForReason: 'Rechazar por razón',
    reject: 'Rechazar',
    copy: 'Copiar nombre',
    favorites: {
      add: 'Añadir a favoritos',
      remove: 'Eliminar de favoritos'
    },
    edit: 'Editar'
  },

  variants: {
    contact: 'Contáctenos',
    comment: 'Comentario incorrecto',
    length: 'Longitud incorrecta',
    common: 'Nombre común',
    international: 'Nombre internacional'
  },

  pills: {
    all: 'Disponibles',
    checked: 'Seleccionados'
  },

  snackbars: {
    created: 'Nombre del remitente creado con éxito.',
    updated: 'Nombre del remitente actualizado con éxito.',
    removed: 'Nombre del remitente eliminado con éxito.',
    confirmationCode: {
      rateLimit: 'El código solo se puede solicitar una vez por minuto',
      invalidCode: 'Código incorrecto',
      success: 'Verificación exitosa'
    }
  },

  tabs: {
    eventsHistory: 'Historial de eventos'
  }
}
