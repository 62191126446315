import { stringParser } from '@sigma-legacy-libs/essentials/lib/utils/regexp'
import proxy from '@sigma-legacy-libs/g-proxy'

import { sendingPayloadValidator } from '@/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      advanced: false,
      checkPattern: undefined
    }
  },

  computed: {
    validation() {
      return this.proxy.title && sendingPayloadValidator(this.proxy.type, this.proxy.payload)
    },
    patternValidation() {
      if (this.proxy.payload.text && this.checkPattern) {
        const patternRegExp = stringParser.makeRegExp(this.proxy.payload.text)

        return patternRegExp.test(this.checkPattern)
      }
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  render
}
