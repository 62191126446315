import { cloneDeep, isEqual } from 'lodash'

import { buttonModes, colors, components, icons, permissionPresets, services, states } from '@/utils'

import button from '@/components/button'
import buttonRefresh from '@/components/button/refresh'
import pagination from '@/components/pagination'
import tasksFilter from '@/components/services/tasks/filter'
import tasksForm from '@/components/services/tasks/form'

function renderPresets(h) {
  if (Array.isArray(this.presets) && this.presets.length > 0) {
    return h(
      'div',
      {
        class: `${services.tasks}-header__presets`
      },
      [
        this.presets.map(preset => {
          return h(
            button,
            {
              props: {
                label: this.getTranslate(`${services.tasks}.buttons.${preset.title}`),
                mode: buttonModes.flat,
                color: isEqual(this.filter, preset.filter) ? colors.primary : colors.grey
              },
              on: {
                click: () => {
                  this.filter = cloneDeep(preset.filter)
                }
              }
            }
          )
        })
      ]
    )
  }
}

function renderButtonCreate(h) {
  if (this.$route.name === services.billings) {
    if (this.checkPermissions(`advanced.${services.tasks}.create`, permissionPresets.resellerUp)) {
      return h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.create'),
            icon: icons.add,
            mode: buttonModes.flat,
            color: colors.secondary
          },
          on: {
            click: () => {
              this.showDialog = true
            }
          },
          scopedSlots: {
            dialog: () => renderDialog.call(this, h)
          }
        }
      )
    }
  }
}
function renderButtonRefresh(h) {
  return h(
    buttonRefresh,
    {
      props: {
        label: this.getTranslate(`${services.tasks}.buttons.refresh`),
        tooltip: this.getTranslate(`${services.tasks}.tooltips.refresh`),
        state: this.restData[services.tasks].find.state,
        shortClickMethod: () => this.rest[services.tasks].find(),
        longClickMethod: () => this.rest[services.tasks].find({}, { noCache: true })
      }
    }
  )
}
function renderActions(h) {
  return h(
    'div',
    {
      class: `${services.tasks}-header__actions`
    },
    [
      renderButtonCreate.call(this, h),
      renderButtonRefresh.call(this, h)
    ]
  )
}
function renderHeaderContent(h) {
  return h(
    'div',
    {
      class: `${services.tasks}-header__content`
    },
    [
      renderPresets.call(this, h),
      renderActions.call(this, h)
    ]
  )
}
function renderFilter(h) {
  return h(
    tasksFilter,
    {
      props: {
        value: this.filter,
        type: this.restData[services.tasks].find.filter.type
      },
      on: {
        input: event => {
          this.filter = event
        }
      }
    }
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.tasks}-header`
    },
    [
      renderHeaderContent.call(this, h),
      renderFilter.call(this, h)
    ]
  )
}

function renderList(h) {
  if (this.restData[services.tasks].find.pagination.total) {
    const component = item => this.$scopedSlots.item ? this.$scopedSlots.item({ item }) : this.$slots.item
    if (this.viewport.breakpoint.lgUp) {
      return this.restData[services.tasks].find.data.map(component)
    } else {
      return h(
        'g-expansion-panels',
        {
          props: { flat: true }
        },
        [ this.restData[services.tasks].find.data.map(component) ]
      )
    }
  } else {
    return h(components.empty)
  }
}

function renderDialogBody(h) {
  return h(
    tasksForm,
    {
      props: {
        value: this.restData[services.tasks].create.data,
        errors: this.restData[services.tasks].create.errors
      },
      on: {
        input: event => {
          this.restData[services.tasks].create.data = event
        },
        validation: event => {
          this.restData[services.tasks].create.isValid = event
        }
      }
    }
  )
}
function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat,
            disabled: this.restData[services.tasks].create.state === states.loading
          },
          on: {
            click: () => {
              this.showDialog = false
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.create'),
            loading: this.restData[services.tasks].create.state === 'loading',
            disabled: !this.createDataIsValid,
            color: colors.primary
          },
          on: {
            click: async () => {
              await this.createTask()
            }
          }
        }
      )
    ]
  )
}
function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`${services.tasks}.dialogs.create`),
        value: this.showDialog,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 400 : undefined
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      renderDialogBody.call(this, h),
      renderDialogFooter.call(this, h)
    ]
  )
}

function renderDivider(h) {
  if (this.restData[services.tasks].find.pagination.total > 0) {
    return h(components.divider)
  }
}

function renderPagination(h) {
  if (this.restData[services.tasks].find.pagination.total > 0) {
    return h(
      'div',
      {
        class: `${services.tasks}-pagination`
      },
      [
        h(
          pagination,
          {
            props: {
              value: this.restData[services.tasks].find.pagination,
              service: services.tasks
            },
            on: {
              input: event => {
                this.restData[services.tasks].find.pagination = event
              }
            }
          }
        )
      ]
    )
  }

  return h('div')
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.tasks}-holder`
    },
    [
      renderHeader.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h),
      renderDivider.call(this, h),
      renderList.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h)
    ]
  )
}
