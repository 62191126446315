import { NOTIFICATIONS_SETTINGS_CHILD, NOTIFICATIONS_SETTINGS_MY } from '@sigma-legacy-libs/essentials/lib/schemas/notifications'

import { permissionPresets, services } from '@/utils'

import render from './render'

import { notificationsSettings } from '@/utils/mixins/notifications'

export default {
  name: 'NotificationsSettingsUser',

  mixins: [ notificationsSettings() ],

  computed: {
    schema() {
      const childrenForReseller = {
        my: Object.keys(NOTIFICATIONS_SETTINGS_MY),
        users: Object.keys(NOTIFICATIONS_SETTINGS_CHILD)
      }
      const childrenForMy = Object.keys(NOTIFICATIONS_SETTINGS_MY)
      const permissionPath = `advanced.${services.settingsUser}.set`

      if (this.checkPermissions(permissionPath)) {
        if (this.options.UserId === this.account.id || this.options.payload.permissions[permissionPath] !== permissionPresets.me) {
          return childrenForReseller
        } else {
          return childrenForMy
        }
      } else if (this.checkPermissions(permissionPath, permissionPresets.reseller)) {
        if (this.options.UserId === this.account.id) {
          return childrenForReseller
        } else {
          return childrenForMy
        }
      } else {
        return childrenForMy
      }
    }
  },

  render
}
