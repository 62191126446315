export default {
  title: 'Поддержка',

  labels: {
    vk: 'ВКонтакте',
    t: 'Telegram',
    rbc: 'РБК',
    vc: 'VC.ru',
    linkedin: 'LinkedIn',
    x: 'X.com'
  }
}
