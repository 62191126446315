import Vue from 'vue'

import { Macro } from '@sigma-legacy-libs/cache'

import { isUUID, proxy, services } from '@/utils'

import { userTariffsInputFilter } from '@/components/services/tariffs/utils'

import render from './render'

const Cache = new Macro({
  ttl: 60 * 1000,
  ttlInterval: 1000
})

const cachedGet = Cache.wrapWithCache(async (key, id) => {
  return await Vue.$GRequest.get(services.tariffs, id)
})

export default {
  name: 'Generator',

  mixins: [ proxy({ type: 'array' }) ],

  props: {
    readonly: Boolean
  },

  watch: {
    proxy: {
      handler() {
        this.fillTariff()
      },
      deep: true
    }
  },

  methods: {
    remove(index) {
      this.proxy.splice(index, 1)
    },

    _outputFilter(data) {
      return data.map((item, index) => {
        if (!isUUID(item)) {
          item.priority = index
        }

        return item
      })
    },

    async fillTariff() {
      const index = this.proxy.findIndex(isUUID)
      const id = this.proxy[index]
      if (id) {
        const { data } = await cachedGet(`${services.tariffs}:get:${id}`, id)
        this.proxy.splice(index, 1, userTariffsInputFilter(data))
      }
    }
  },

  render
}
