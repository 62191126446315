import { mapActions, mapGetters } from 'vuex'

import { metaInfo, serviceWorker } from '@/utils'

import render from './render'

import DefaultLayout from '@/layouts/default'
import SimpleLayout from '@/layouts/simple'

export default {
  name: 'App',

  mixins: [ metaInfo, serviceWorker ],

  computed: {
    ...mapGetters({ token: 'authentication/token' }),

    layout() {
      switch (this.$route.name) {
        case 'documentation':
        case 'documentation.file': {
          if (this.token) {
            return DefaultLayout
          }

          return SimpleLayout
        }
        case 'pay':
        case 'pay.owner':
        case 'pay.webhook':
        case 'login':
        case 'registration':
        case 'recover':
        case 'recover.key': return SimpleLayout
        default: return DefaultLayout
      }
    }
  },

  methods: {
    ...mapActions({ setLocale: 'locale/setLocale' })
  },

  watch: {
    $route(to) {
      const locale = to.params.locale || to.query.locale
      if (locale) {
        this.setLocale(locale)
      }
    }
  },

  render
}
