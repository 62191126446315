import { colors, components, getLocaleDateString, getLocaleTimeString, icons, services, sizes, states } from '@/utils'

import squircle from '@/components/icon/squircle'
import preloader from '@/components/preloader'
import { getColor } from '@/components/services/notifications/utils'
import tag from '@/components/tag'

function renderArrow(h, direction) {
  return h(
    'div',
    {
      class: {
        [`${services.notifications}-widget__arrow`]: true,
        [`${services.notifications}-widget__arrow--${direction}`]: this.arrow[direction]
      },
      on: {
        click: () => {
          this.scroll(direction)
        }
      }
    },
    [
      h(
        components.icon,
        {
          props: {
            value: direction === 'top' ? icons.keyboard_arrow_up : icons.keyboard_arrow_down,
            color: colors.grey,
            size: 16
          }
        }
      )
    ]
  )
}

function renderHeaderContent(h) {
  return h(
    'div',
    {
      class: `${services.notifications}-widget__header-content`
    },
    [
      h(
        'div',
        {
          class: `${services.notifications}-widget__header-title`
        },
        [ this.getTranslate(`${services.notifications}.labels.widget.header`) ]
      ),
      h(
        'div',
        {
          class: `${services.notifications}-widget__header-text`
        },
        [ this.getTranslate(`${services.notifications}.hints.widget.header`) ]
      )
    ]
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.notifications}-widget__header`
    },
    [
      h(
        squircle,
        {
          props: {
            iconSVG: services.notifications,
            iconSize: 28,
            size: sizes.medium,
            color: colors.white
          }
        }
      ),

      renderHeaderContent.call(this, h)
    ]
  )
}

function renderContent(h, item) {
  const { title, text } = item

  return h(
    'div',
    {
      class: `${services.notifications}-widget__notification-content`
    },
    [
      h(
        'div',
        {
          class: `${services.notifications}-widget__notification-content__title`
        },
        [ title ]
      ),

      h(
        'div',
        {
          class: `${services.notifications}-widget__notification-content__text`,
          domProps: {
            innerHTML: text.replace(/\n/g, '<br />')
          }
        }
      )
    ]
  )
}
function renderType(h, type) {
  return h(
    tag,
    {
      props: {
        label: this.getTranslate(`${services.notifications}.events.${type}`),
        color: getColor(type),
        size: sizes.small
      }
    }
  )
}
function renderDate(h, isRead, createdAt, id) {
  return h(
    'div',
    {
      class: `${services.notifications}-widget__notification-date`
    },
    [
      h(
        tag,
        {
          props: {
            label: [ getLocaleDateString(createdAt), getLocaleTimeString(createdAt) ].join(' '),
            size: sizes.small
          }
        }
      ),

      h(
        'div',
        {
          class: {
            [`${services.notifications}-widget__notification-date-icon`]: true,
            [`${services.notifications}-widget__notification-date-icon--${isRead ? colors.success : colors.grey}`]: true
          }
        },
        [
          h(
            components.icon,
            {
              props: {
                value: this.loading[id] ? icons.more_horiz : isRead ? icons.done_all : icons.done,
                color: this.loading[id] ? colors.primary : isRead ? colors.success : colors.grey,
                size: 14
              }
            }
          )
        ]
      )
    ]
  )
}
function renderInfo(h, item) {
  const { id, type, isRead, createdAt } = item

  return h(
    'div',
    {
      class: `${services.notifications}-widget__notification-info`
    },
    [
      renderType.call(this, h, type),
      renderDate.call(this, h, isRead, createdAt, id)
    ]
  )
}
function renderItem(h, item) {
  const { id, type } = item

  return h(
    'div',
    {
      attrs: { id },
      class: {
        [`${services.notifications}-widget__notification`]: true,
        [`${services.notifications}-widget__notification--${getColor(type)}`]: true
      },
      on: {
        mouseenter: () => {
          this.wheelHandler()
        }
      }
    },
    [
      renderContent.call(this, h, item),
      renderInfo.call(this, h, item)
    ]
  )
}
function renderItems(h) {
  return this.restData[services.notifications].find.data.map(item => {
    return renderItem.call(this, h, item)
  })
}
function renderList(h) {
  return h(
    'div',
    {
      class: `${services.notifications}-widget__list`,
      ref: 'list'
    },
    [ renderItems.call(this, h) ]
  )
}
function renderViewport(h) {
  return h(
    'div',
    {
      class: `${services.notifications}-widget__viewport`,
      ref: 'viewport',
      on: {
        wheel: () => this.wheelHandler(),
        scroll: () => this.scrollHandler()
      }
    },
    [ renderList.call(this, h) ]
  )
}
function renderBody(h) {
  const { data, state } = this.restData[services.notifications].find
  switch (state) {
    case states.ready: {
      if (Array.isArray(data) && data.length) {
        return h(
          'div',
          {
            class: `${services.notifications}-widget__body`
          },
          [
            renderArrow.call(this, h, 'top'),
            renderViewport.call(this, h),
            renderArrow.call(this, h, 'bottom')
          ]
        )
      } else {
        return h(components.empty)
      }
    }
    case states.loading: return h(preloader)
    default: return h(components.empty)
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.notifications}-widget`
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h)
    ]
  )
}
