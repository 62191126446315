import { cloneDeep, defaultsDeep, isEqual } from 'lodash'

import Fallbacks from '@sigma-legacy-libs/essentials/lib/utils/Fallbacks'
import proxy from '@sigma-legacy-libs/g-proxy'

import { defaultFallback } from '@/components/misc/rules/utils'

import render from './render'

export default {
  name: 'GeneratorFallbacks',

  mixins: [ proxy({ type: 'array' }) ],

  methods: {
    _inputFilter(data) {
      data = cloneDeep(data)

      return data.map(item => defaultsDeep(item, defaultFallback))
    },

    _outputFilter(data) {
      return Fallbacks.clean(data)
    },

    transmitValue() {
      const proxy = this._outputFilter(this.proxy)
      if (!isEqual(proxy, this.value)) {
        this.$emit('input', proxy)
      }
    }
  },

  render
}
