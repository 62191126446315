import { FILE_TYPES, buttonModes, colors, components, icons, services } from '@/utils'

import button from '@/components/button'
import fileManager from '@/components/file/manager'
import info from '@/components/info'
import preloader from '@/components/preloader'

function renderFileManager(h) {
  return h(
    fileManager,
    {
      props: {
        flat: true,
        readonly: true,
        embedded: true,
        show: this.showFileManager,
        types: FILE_TYPES.csv.extensions,
        isPublic: true
      },
      on: {
        show: event => {
          this.showFileManager = event
        },
        input: event => {
          this.import(event.id)
        }
      }
    }
  )
}
function renderResultDialogBody(h) {
  if (this.loading) {
    return h(preloader)
  }
  if (this.result) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate(`${services.providersPrices}.contents.import.result`, { result: this.result })
        }
      }
    )
  }

  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${services.providersPrices}.contents.import.error`),
        color: colors.warning
      }
    }
  )
}
function renderResultDialogFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe pa-2'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.showResultDialog = false
            }
          }
        }
      )
    ]
  )
}
function renderResultDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`${services.providersPrices}.titles.import.result`),
        value: this.showResultDialog,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 400 : undefined
      },
      on: {
        input: event => {
          this.showResultDialog = event
        }
      }
    },
    [
      renderResultDialogBody.call(this, h),
      renderResultDialogFooter.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        icon: icons.file_upload,
        color: colors.primary,
        loading: this.loading,
        disabled: this.loading || this.disabled,
        tooltip: this.getTranslate('misc.buttons.upload')
      },
      on: {
        click: () => {
          this.showFileManager = true
        }
      },
      scopedSlots: {
        dialog: () => [
          renderFileManager.call(this, h),
          renderResultDialog.call(this, h)
        ]
      }
    }
  )
}
