export default {
  title: 'Токены',

  titles: {
    create: 'Создать токен',
    update: 'Изменить название токена',
    remove: 'Удалить токен',
    copy: 'Скопировать токен',
    expiredAt: 'Выберите дату истечения'
  },

  labels: {
    token: 'Токен',
    description: 'Название',
    search: 'Поиск',
    owner: 'Владелец',
    noData: {
      description: 'Нет названия',
      expiredAt: 'Нет даты',
      createAt: 'Нет даты'
    },
    expiredAt: 'Дата истечения'
  },

  hints: {
    notFound: 'Токены не найдены',
    description: 'Максимум 250 символов'
  },

  tooltips: {
    favorites: 'Сделать токен избранным',
    copy: 'Скопировать токен',
    sensitivity: 'Показать токен'
  },

  buttons: {
    create: 'Создать новый токен',
    add: 'Добавить токен'
  },

  warnings: {
    token: `
<strong>Внимание!</strong><br>
Это единственный момент, когда вы сможете увидеть и скопировать ваш токен.<br>
После закрытия этого окна он станет недоступен для просмотра.<br>
Пожалуйста, сохраните токен в надежном месте, так как впоследствии вы сможете лишь удалить его и создать новый.<br>
<br>
<span class="text--grey">Чтобы предотвратить случайное закрытие, окно заблокировано на 15 секунд (таймер отображается рядом с кнопкой закрытия).</span>`
  },

  snackbars: {
    created: 'Токен успешно создан.',
    removed: 'Токен успешно удалён.',
    copy: 'Токен скопирован в буфер обмена.'
  }
}
