import { get } from 'lodash'

import { getPrice } from '@sigma-frontend-libs/commons'

import { colors, services } from '@/utils'

import formatTable from '@/components/format/table'
import info from '@/components/info'
import { billingsStatuses } from '@/components/services/billings/utils'
import tag from '@/components/tag'

function renderTags(h) {
  const [ source, type ] = this.value.source && this.value.source.split('.') || []
  const status = get(this.value, 'status')

  return h(
    'div',
    {
      class: 'faic grid-gap--8 px-2 text-upper'
    },
    [
      h(
        tag,
        {
          props: {
            label: getPrice(this.value.amount)
          }
        }
      ),
      h(
        tag,
        {
          props: {
            label: type || source,
            color: type || source
          }
        }
      ),
      h(
        tag,
        {
          props: {
            icon: billingsStatuses[status].icon,
            color: billingsStatuses[status].color,
            label: this.getTranslate(`${services.billings}.statuses.${status}`)
          }
        }
      )
    ]
  )
}
function renderComment(h) {
  const value = get(this.value, 'data.hidden.comment')
  if (value) {
    return h(
      'div',
      {
        class: 'px-2'
      },
      [
        h(
          info,
          {
            props: {
              value,
              color: colors.grey
            }
          }
        )
      ]
    )
  }
}
function renderFormatTable(h) {
  return h(
    formatTable,
    {
      props: { value: this.value }
    }
  )
}

export default function(h) {
  if (this.value) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        renderTags.call(this, h),
        renderComment.call(this, h),
        renderFormatTable.call(this, h)
      ]
    )
  }
}
