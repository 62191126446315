import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import { isUUID, services, touch } from '@/utils'

import render from './render'

export default {
  name: 'DefaultLayout',

  mixins: [ touch() ],

  data() {
    return {
      showSidebar: false
    }
  },

  computed: {
    ...mapGetters({ isLoggedAs: 'login/isLoggedAs' }),

    buttonsStyle() {
      let cols = 2
      if (this.viewport.breakpoint.mdUp) {
        cols = 5
      }

      return `grid-template-columns: repeat(${cols}, auto)`
    },

    pathToTitle() {
      const paths = this.$route.path.split('/')
      const excludePaths = [ 'admin', 'routes', 'other', 'documents', 'messageProcessing' ]

      return paths
        .filter(path => Boolean(path) && !isUUID(path) && !excludePaths.includes(path))
        .join('.')
    }
  },

  watch: {
    $route: {
      handler() {
        if (this.viewport.breakpoint.smDown) {
          this.showSidebar = false
        }
      },
      deep: true
    },

    viewport: {
      handler() {
        if (this.viewport.breakpoint.smDown) {
          this.showSidebar = false
        } else {
          this.showSidebar = true
        }
      },
      deep: true
    }
  },

  mounted() {
    Vue.$socket.on(`${services.users}.updated`, data => {
      if (this.account.id === data.id) {
        this.getAccount(this.account.id)
      }
    })
    Vue.$socket.on(`${services.settingsUser}.updated`, () => this.getGlobalSettings())
    Vue.$socket.on(`${services.settingsSite}.updated`, () => this.getGlobalSettings())

    this.showSidebar = this.viewport.breakpoint.mdUp
  },

  methods: {
    ...mapActions({
      loginAs: 'login/loginAs',
      logout: 'login/logout',
      getAccount: 'account/getAccount',
      getGlobalSettings: 'settings/getGlobalSettings',
      setShowPaymentDialog: 'payments/setShowPaymentDialog'
    }),

    leftSwipeCallback() {
      this.showSidebar = false
    },
    rightSwipeCallback() {
      this.showSidebar = true
    }
  },

  render
}
