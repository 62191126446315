export default {
  title: 'Tokens',

  titles: {
    create: 'Crear token',
    update: 'Actualizar nombre del token',
    remove: 'Eliminar token',
    copy: 'Copiar token',
    expiredAt: 'Seleccionar fecha de expiración'
  },

  labels: {
    token: 'Token',
    description: 'Nombre',
    search: 'Buscar',
    owner: 'Propietario',
    noData: {
      description: 'Sin nombre',
      expiredAt: 'Sin fecha',
      createAt: 'Sin fecha'
    },
    expiredAt: 'Fecha de expiración'
  },

  hints: {
    notFound: 'Tokens no encontrados',
    description: 'Máximo 250 caracteres'
  },

  tooltips: {
    favorites: 'Marcar token como favorito',
    copy: 'Copiar token',
    sensitivity: 'Mostrar token'
  },

  buttons: {
    create: 'Crear nuevo token',
    add: 'Agregar token'
  },

  warnings: {
    token: `
<strong>¡Atención!</strong><br>
Este es el único momento en el que podrá ver y copiar su token.<br>
Una vez que se cierre esta ventana, ya no estará accesible.<br>
Por favor, guarde su token en un lugar seguro, ya que posteriormente solo podrá eliminarlo y generar uno nuevo.<br>
<br>
<span class="text--grey">Para evitar cierres accidentales, la ventana estará bloqueada durante 15 segundos (se muestra un temporizador junto al botón de cierre).</span>`
  },

  snackbars: {
    created: 'Token creado exitosamente.',
    removed: 'Token eliminado exitosamente.',
    copy: 'Token copiado al portapapeles.'
  }
}
