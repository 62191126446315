import { components } from '@/utils'

import svgIcon from '@/components/icon/svg'
import preloader from '@/components/preloader'

const className = 'btn'

function renderIconContent(h, icon) {
  if (icon) {
    return h(
      this.iconSVG ? svgIcon : components.icon,
      {
        props: {
          value: icon,
          color: this.$iconColor,
          size: this.$iconSize
        }
      }
    )
  }

  return this.$slots.icon
}

function renderIconHolder(h, icon) {
  return h(
    'div',
    {
      class: {
        [`${className}__icon`]: true,
        [`${className}__icon--${this.iconColor}`]: !!this.iconColor
      }
    },
    [ renderIconContent.call(this, h, icon) ]
  )
}
function renderIcon(h, icon) {
  if (icon) {
    return renderIconHolder.call(this, h, icon)
  }
}
function renderLabel(h) {
  if (this.$label) {
    return h(
      'div',
      {
        class: `${className}__label`
      },
      [ this.$label ]
    )
  }
}
function renderPreLoaderContent(h) {
  return h(
    preloader,
    {
      props: {
        size: this.$iconSize / 2,
        color: this.preloaderColor,
        count: this.preloaderCount
      }
    }
  )
}
function renderPreLoader(h) {
  if (this.loading) {
    return h(
      'div',
      {
        class: `${className}__loading`
      },
      [ renderPreLoaderContent.call(this, h) ]
    )
  }
}
function renderHolder(h) {
  return h(
    'div',
    {
      class: `${className}__holder`
    },
    [
      renderIcon.call(this, h, this.$icon),
      renderLabel.call(this, h),
      renderIcon.call(this, h, this.suffix),
      renderPreLoader.call(this, h)
    ]
  )
}

function renderDialogSlot() {
  if (typeof this.$scopedSlots.dialog === 'function') {
    return this.$scopedSlots.dialog()
  }

  return this.$slots.dialog
}

export default function(h) {
  return h(
    'button',
    {
      attrs: this.$_attrs,
      class: this.$_class,
      directives: this.$_directives,
      on: this.$eventHandlers
    },
    [
      renderHolder.call(this, h),
      renderDialogSlot.call(this, h)
    ]
  )
}
