import Vue from 'vue'

import { get } from 'lodash'

import { getPrice } from '@sigma-frontend-libs/commons'
import { BILLING_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, isEmpty, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'Price',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      timeout: {},
      billings: []
    }
  },

  computed: {
    providerAmount() {
      return get(this.value, 'meta.billing.providerAmount') || get(this.value, `meta.${services.billings}.provider.amount`)
    },
    hasProviderAmount() {
      return !isEmpty(this.providerAmount) && this.checkPermissions(`advanced.${services.sendings}.get`, permissionPresets.managerUp)
    },

    tooltip() {
      const result = []
      if (this.hasProviderAmount && !isNaN(this.providerAmount)) {
        result.push(`${this.getTranslate(`${services.sendings}.hints.purchase`)}: ${getPrice(this.providerAmount)}`)
      }
      if (this.refunded) {
        result.push(this.getTranslate(`${services.billings}.statuses.${BILLING_STATUS.refunded}`))
      }

      return result.join('\n')
    },

    billingAmount() {
      return this.billings.reduce((sum, { amount }) => {
        if (sum === undefined) {
          sum = 0
        }

        sum += parseFloat(amount)

        if (isNaN(sum)) {
          sum = undefined
        }

        return sum
      }, undefined)
    },

    refunded() {
      return get(this.value, 'meta.billing.refunded') || this.billings.length && this.billings.every(({ status }) => status === BILLING_STATUS.refunded)
    },

    color() {
      if (this.refunded) {
        return colors.grey
      }

      if (this.hasProviderAmount) {
        switch (true) {
          case this.providerAmount < this.billingAmount: return colors.error
          case this.providerAmount > this.billingAmount: return colors.success
          case this.providerAmount == this.billingAmount:
          default: return colors.primary
        }
      }

      return colors.black
    },

    ids() {
      return get(this.value, `meta.${services.billings}.ids`, [ get(this.value, 'meta.billing.id') ])
    }
  },

  mounted() {
    Vue.$socket.on(`${services.billings}.created`, this.updateBilling)
    Vue.$socket.on(`${services.billings}.updated`, this.updateBilling)
    Vue.$socket.on(`${services.billings}.removed2`, this.removeBilling)

    this.get()
  },

  beforeDestroy() {
    for (const id in this.timeout) {
      if (this.timeout[id]) {
        clearTimeout(this.timeout[id])
      }
    }
  },

  methods: {
    async get() {
      if (this.ids.length) {
        for (const id of this.ids) {
          try {
            const { data } = await Vue.$GRequest.get(services.billings, id)
            this.billings.push(data)
            if (this.timeout[id]) {
              clearTimeout(this.timeout[id])
            }
          } catch (error) {
            this.timeout[id] = setTimeout(() => this.get(), 5 * 1000)
          }
        }
      }
    },
    updateBilling(billing) {
      if (billing && billing.id) {
        if (Array.isArray(this.billings) && this.billings.length) {
          const index = this.billings.findIndex(({ id }) => id === billing.id)
          if (~index) {
            this.billings.splice(index, 1, billing)
          }
        }
      }
    },
    removeBilling({ id }) {
      if (id) {
        if (Array.isArray(this.billings) && this.billings.length) {
          const index = this.billings.findIndex(item => item.id === id)
          if (~index) {
            this.billings.splice(index, 1)
          }
        }
      }
    }
  },

  render
}
