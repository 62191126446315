import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default function(options) {
  return {
    name: 'PaymentsMethods',

    mixins: [ proxy({ type: 'object' }) ],

    data() {
      return {
        showDialog: false,

        visibility: {
          tinkoff: {
            Password: false,
            ekamToken: false
          },
          mandarin: {
            secret: false,
            ekamToken: false
          },
          stripe: {
            apikey: false
          }
        }
      }
    },

    methods: {
      watchValueHandler() {
        return
      },
      watchProxyHandler() {
        return
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
