import { BILLING_CURRENCY } from '@sigma-legacy-libs/essentials/lib/constants'
import proxy from '@sigma-legacy-libs/g-proxy'

import { currentTab, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [
    proxy({ type: 'object' }),
    currentTab('general')
  ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      editable: false,
      passwordVisibility: true
    }
  },

  computed: {
    accountCurrency() {
      return this.account?.settings?.['billings.currency']
    },

    tabs() {
      const result = [ 'general', services.settingsUser, services.tariffs ]
      const generalServices = [ services.tokens ]
      for (const service of generalServices) {
        if (this.checkPermissions(`advanced.${service}.get`, permissionPresets.meUp)) {
          result.push(service)
        }
      }
      if (this.accountCurrency === BILLING_CURRENCY.RUB) {
        const servicesForRUBCurrency = [
          services.requisites,
          services.recurrentPayments
        ]
        for (const service of servicesForRUBCurrency) {
          if (this.checkPermissions(`advanced.${service}.get`, permissionPresets.meUp)) {
            result.push(service)
          }
        }
        if (this.checkPermissions(`advanced.${services.billings}.find`, permissionPresets.meUp)) {
          result.push('referral')
        }
      }

      return result
    }
  },

  render
}
