export default {
  title: 'Tokens',

  titles: {
    create: 'Create token',
    update: 'Edit title token',
    remove: 'Remove token',
    copy: 'Copy token',
    expiredAt: 'Select expiration date'
  },

  labels: {
    token: 'Token',
    description: 'Title',
    search: 'Search',
    owner: 'Owner',
    noData: {
      description: 'No title',
      expiredAt: 'No date',
      createAt: 'No date'
    },
    expiredAt: 'Expiration date'
  },

  hints: {
    notFound: 'Tokens not found',
    description: 'Maximum 250 characters'
  },

  tooltips: {
    favorites: 'Make the token a favorite',
    copy: 'Copy token',
    sensitivity: 'Show token'
  },

  buttons: {
    create: 'Create new token',
    add: 'Add token'
  },

  warnings: {
    token: `
<strong>Attention!</strong><br>
This is the only moment when you can view and copy your token.<br>
Once this window is closed, it will no longer be accessible.<br>
Please save your token in a secure place, as you will only be able to delete it and create a new one later.<br>
<br>
<span class="text--grey">To prevent accidental closure, the window is locked for 15 seconds (a timer is displayed next to the close button).</span>`
  },

  snackbars: {
    created: 'Token successfully created.',
    removed: 'Token successfully removed.',
    copy: 'Token copied to clipboard.'
  }
}
