import { components } from '@/utils'

import ColorPicker from '@/components/misc/ColorPicker'

export default function(h, { path, serviceName }) {
  return h(
    components.card,
    {
      class: 'grid grid-gap--8 pa-2',
      props: {
        title: this.getTranslate(`${serviceName}.subtitles.${path}`),
        outline: true,
        rounded: true
      }
    },
    [
      Object.keys(this.proxy).map(color => {
        return h(
          ColorPicker,
          {
            props: {
              color,
              value: this.proxy[color],
              label: this.getTranslate(`${serviceName}.labels.${path}.${color}`),
              hint: this.getTranslate(`${serviceName}.hints.${path}.${color}`)
            },
            on: {
              input: event => {
                this.proxy[color] = event
              }
            }
          }
        )
      })
    ]
  )
}
