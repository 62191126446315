import Vue from 'vue'

import { get } from 'lodash'

import { SENDING_STATES, buttonModes, colors, components, services, sizes } from '@/utils'

import button from '@/components/button'
import formatTable from '@/components/format/table'
import detailsDialog from '@/components/services/sendings/outgoing/details/dialog'
import tag from '@/components/tag'

function renderType(h, type) {
  return h(
    tag,
    {
      props: {
        color: colors[type],
        label: type
      }
    }
  )
}
function renderStatus(h, status) {
  if (status) {
    const status_color = get(SENDING_STATES, `${status}.color`, '')
    const status_name = get(SENDING_STATES, `${status}.name`, '')
    const status_icon = get(SENDING_STATES, `${status}.icon`, '')

    return h(
      tag,
      {
        props: {
          icon: status_icon,
          color: status_color,
          label: this.getTranslate(`${services.sendings}.statuses.${status_name}`)
        }
      }
    )
  }
}
function renderDialog(h, sending) {
  if (sending.id) {
    return h(
      detailsDialog,
      {
        props: {
          value: sending,
          show: this.dialogs[sending.id]
        },
        on: {
          close: () => {
            Vue.delete(this.dialogs, sending.id)
          }
        }
      }
    )
  }
}
function renderMessageButton(h, sending) {
  if (sending.id) {
    return h(
      button,
      {
        props: {
          label: this.getTranslate(`${services.sendings}.labels.message`),
          mode: buttonModes.flat,
          size: sizes.small,
          color: colors.primary
        },
        on: {
          click: () => {
            Vue.set(this.dialogs, sending.id, true)
          }
        },
        scopedSlots: {
          dialog: () => renderDialog.call(this, h, sending)
        }
      }
    )
  }
}
function renderTags(h, sending) {
  return h(
    'div',
    {
      class: 'faic grid-gap--8 text-upper'
    },
    [
      renderType.call(this, h, sending.type),
      renderStatus.call(this, h, sending.status),
      renderMessageButton.call(this, h, sending)
    ]
  )
}

function renderBody(h, sending) {
  return h(
    formatTable,
    {
      props: {
        value: sending,
        service: services.sendingsFallbacks
      }
    }
  )
}

function renderItem(h, sending) {
  return h(
    components.card,
    {
      class: 'grid grid-gap--8 pa-2',
      props: {
        title: sending.id,
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [
      renderTags.call(this, h, sending),
      renderBody.call(this, h, sending)
    ]
  )
}

export default function(h) {
  if (Array.isArray(this.items) && this.items.length) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 pt-2 px-2'
      },
      [ this.items.map(item => renderItem.call(this, h, item)) ]
    )
  }
}
