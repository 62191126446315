import { components, displays, generateUniqueKey, isStringNotEmpty } from '@/utils'

const options = {
  [displays.mobile]: {
    length: 2,
    duration: 3,
    method: 'unshift'
  },
  [displays.desktop]: {
    length: 6,
    duration: 8,
    method: 'push'
  }
}

export default {
  namespaced: true,

  state: {
    snackbars: []
  },

  mutations: {
    addSnackbar(state, { snackbar, display }) {
      if (state.snackbars.length >= options[display].length) {
        const index = display === displays.mobile ? -1 : 0
        state.snackbars.splice(index, 1)
      }
      state.snackbars[options[display].method](snackbar)
    },

    removeSnackbar(state, id) {
      if (isStringNotEmpty(id)) {
        const index = state.snackbars.findIndex(item => item.id === id)
        if (~index) {
          state.snackbars.splice(index, 1)
        }
      }
    },

    clearSnackbars(state) {
      state.snackbars = []
    }
  },

  actions: {
    addSnackbar({ dispatch, commit, rootGetters }, payload) {
      const display = rootGetters['viewport/display']

      if (display === displays.mobile) {
        const blockElements = document.querySelectorAll([
          `.${components.dialog}`,
          `.${components.menu}__content--active`
        ].join(', '))

        if (blockElements.length) {
          return
        }
      }

      const id = generateUniqueKey()
      const duration = payload.duration || options[display].duration

      if (payload.button && payload.button.on && typeof payload.button.on.click === 'function') {
        const callback = payload.button.on.click
        payload.button.on.click = () => {
          dispatch('removeSnackbar', id)
          callback()
        }
      }

      const snackbar = {
        id,
        text: payload.text,
        type: payload.type,
        button: payload.button,
        classes: payload.classes,
        cancelable: payload.cancelable ?? true,
        remove: () => dispatch('removeSnackbar', id),
        interval: payload.interval || setInterval(() => dispatch('removeSnackbar', id), duration * 1000),
        duration
      }

      commit('addSnackbar', {
        snackbar,
        display
      })
    },

    removeSnackbar({ state, commit }, id) {
      const index = state.snackbars.findIndex(item => item.id === id)
      if (~index) {
        if (state.snackbars[index].interval) {
          clearInterval(state.snackbars[index].interval)
        }
        setTimeout(() => commit('removeSnackbar', id), 150)
      }
    },

    clearSnackbars({ commit }) {
      commit('clearSnackbars')
    }
  },

  getters: {
    snackbars: state => state.snackbars
  }
}
