import { components } from '@/utils'

import textOverflow from '@/components/text/overflow'

function renderCancelable(h) {
  if (this.cancelable) {
    return h(
      'div',
      {
        class: `${components.tag}__cancel`,
        on: {
          click: event => {
            event.preventDefault()
            event.stopPropagation()
            if (typeof this.cancel === 'function') {
              this.cancel()
            }
          }
        }
      }
    )
  }
}

function renderLabel(h) {
  return h(
    textOverflow,
    {
      class: `${components.tag}__label`,
      props: {
        value: this.$_label
      }
    }
  )
}

export default function(h) {
  if (this.$_label) {
    return h(
      'div',
      {
        class: this.$_class,
        directives: this.$_directives,
        on: this.$eventHandlers
      },
      [
        renderLabel.call(this, h),
        renderCancelable.call(this, h)
      ]
    )
  }
}
