import { get } from 'lodash'

import { buttonModes, colors, icons, services } from '@/utils'

import button from '@/components/button'
import sticker from '@/components/misc/sticker'
import { sendingsStatuses } from '@/components/services/sendings/utils'
import date from '@/components/templates/service/item/date'

function renderStatus(h) {
  const status = this.messageOnModeration ? 'moderation' : get(this.value, 'state.status')

  return h(
    sticker,
    {
      props: {
        icon: sendingsStatuses[status].icon,
        color: sendingsStatuses[status].color
      }
    }
  )
}
function renderRecipient(h) {
  return h(
    sticker,
    {
      props: {
        value: get(this.value, 'payload.recipient'),
        label: this.getTranslate(`${services.sendings}.labels.recipient`),
        iconSVG: this.value.type,
        iconTooltip: this.getTranslate(`${services.sendings}.types.${this.value.type}`),
        color: this.value.type
      }
    }
  )
}
function renderId(h) {
  const ids = get(this.value, 'state.detailed.ids')
  if (ids && ids.length) {
    const [ id1, id2 ] = ids[0].split(':')

    return h(
      sticker,
      {
        props: {
          value: { externalId: id2 || id1 },
          label: this.getTranslate(`${services.sendings}.labels.external.id`),
          pathToTitle: 'externalId',
          copy: true,
          width: 'fit-content'
        }
      }
    )
  }

  return h('div')
}

function renderSourceButton(h) {
  return h(
    button,
    {
      props: {
        icon: icons.code,
        mode: buttonModes.flat,
        color: colors.primary
      },
      on: {
        click: () => {
          this.$emit('source', this.value)
        }
      }
    }
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `service-template-item ${services.providers}-${services.sendings}__item hover-parent hover-parent--opacity`
    },
    [
      renderStatus.call(this, h),
      renderRecipient.call(this, h),
      renderId.call(this, h),
      renderSourceButton.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
