import { colors, generateUniqueKey, icons, services } from '@/utils'

import squircle from '@/components/icon/squircle'
import { providersStates } from '@/components/services/providers/utils'

export default function(h) {
  return h(
    squircle,
    {
      props: {
        icon: providersStates[this.state].icon || icons.help_outline,
        color: providersStates[this.state].color || colors.grey,
        size: this.size,
        loading: this.loading,
        tooltip: this.getTranslate(`${services.providers}.states.${this.state}`)
      },
      key: generateUniqueKey()
    }
  )
}
