export default {
  title: 'Facturas',

  titles: {
    create: 'Creación de una nueva factura',
    confirm: {
      rejected: 'Rechazo de la factura',
      paid: 'Confirmación de la factura'
    }
  },

  labels: {
    docNumber: 'Número',
    invoice: 'Factura',
    contract: 'Contrato',
    type: 'Tipo',
    status: 'Estado',
    amount: 'Cantidad',
    requisite: 'Requisitos',
    company: 'Empresa',
    customer: 'Cliente',
    owner: 'Creador',
    balance: 'Saldo',
    requisites: 'Requisitos',
    create: 'Crear factura',
    edit: 'Ver',
    paid: 'Confirmar',
    rejected: 'Rechazar',
    download: 'Descargar factura',
    delete: 'Eliminar',

    statuses: {
      paid: 'Pagado',
      rejected: 'Rechazado',
      pending: 'Pendiente'
    }
  },

  contents: {
    confirm: {
      rejected: '¿Está seguro de que desea rechazar esta factura?',
      paid: '¿Está seguro de que desea confirmar esta factura?'
    }
  },

  warnings: {
    notFound: {
      requisites: 'Desafortunadamente, no podemos encontrar sus requisitos.<br>Créelos e inténtelo de nuevo.',
      requisitesForAdmin: 'Desafortunadamente, no podemos encontrar requisitos para este usuario.<br>Puedes crearlos para él tú mismo.',
      companies: 'Desafortunadamente, para los requisitos seleccionados existe uno de los siguientes problemas:<strong>- no hay contratista con la forma fiscal especificada</strong><strong>- los contratistas existentes no tienen una plantilla de contrato vinculada</strong><br><div class="mt-2">Contacte con soporte para resolver este problema.</div>',
      template: 'Desafortunadamente, no podemos encontrar ninguna plantilla de factura para la empresa seleccionada.<br>Contacte con soporte para resolver este problema.'
    }
  },

  tooltips: {
    download: 'Descargar factura',
    paid: 'Confirmar',
    rejected: 'Rechazar',
    delete: 'Eliminar',
    edit: 'Ver',
    balance: 'Actualizar saldo'
  },

  snackbars: {
    created: 'Factura creada con éxito.',
    updated: 'Factura actualizada con éxito.',
    deleted: 'Factura eliminada con éxito.',
    paid: 'Factura confirmada con éxito.',
    rejected: 'Factura rechazada con éxito.'
  }
}
