export default {
  unknown: {
    types: 'Неизвестная ошибка с типом {type}',
    fields: 'Неизвестная ошибка с полем {field}'
  },

  types: {
    required: 'Поле {field} должно быть заполнено!',
    string: 'Поле {field} должно быть строкой!',
    stringEmpty: 'Поле {field} не должно быть пустым!',
    stringMin: 'Количество символов в поле {field} должно быть больше или равно {expected}!',
    stringMax: 'Количество символов в поле {field} должно быть меньше или равно {expected}!',
    stringLength: 'Количество символов в поле {field} должно быть равно {expected}!',
    stringPattern: 'Поле {field} не соответствует требуемому шаблону!',
    stringContains: 'Поле {field} должно содержать следующий текст: {expected}!',
    stringEnum: 'Поле {field} не соответствует ни одному из допустимых значений!',
    number: 'Поле {field} должно быть числом!',
    numberMin: 'Поле {field} должно быть больше или равно {expected}!',
    numberMax: 'Поле {field} должно быть меньше или равно {expected}!',
    numberEqual: 'Поле {field} должно быть равно {expected}!',
    numberNotEqual: 'Поле {field} не может быть равно {expected}!',
    numberInteger: 'Поле {field} должно быть целым числом!',
    numberPositive: 'Поле {field} должно быть положительным числом!',
    numberNegative: 'Поле {field} должно быть отрицательным числом!',
    array: 'Поле {field} должно быть массивом!',
    arrayEmpty: 'Поле {field} не должно быть пустым массивом!',
    arrayMin: 'Поле {field} должно содержать не менее {expected} элементов!',
    arrayMax: 'Поле {field} должно содержать меньше {expected} или столько же элементов!',
    arrayLength: 'Поле {field} должно содержать {expected} элементов!',
    arrayContains: 'Поле {field} должно содержать {expected} элемент!',
    arrayEnum: 'Значение {expected} поля {field} не соответствует ни одному из допустимых значений!',
    boolean: 'Поле {field} должно быть логическим!',
    function: 'Поле {field} должно быть функцией!',
    date: 'Поле {field} должно быть датой!',
    dateMin: 'Поле {field} должно быть больше или равно {expected}!',
    dateMax: 'Поле {field} должно быть меньше или равно {expected}!',
    forbidden: 'Поле {field} запрещено!',
    email: 'Поле {field} должно быть действительным e-mail!',
    phoneNumber: 'Поле {field} должно быть валидным номером телефона в любом из популярных форматов!',
    uniqPhoneNumber: 'Контакт с таким номером телефона уже существует.',
    invalid: 'Поле {field} заполнено не верно!',
    uniqueViolation: 'Поле {field} уже существует!',
    fileMaxSize: 'Максимальный размер файла превышен! [{expected}]',
    duplicateSending: 'Вы пытаетесь отправить дубликат рассылки.',
    overdraftLimitExceeded: 'Превышен лимит овердрафта.',
    userNotFound: 'Пользователь с таким логином или e-mail не найден',
    pattern: 'Поле должно содержать только числа',
    serverNotAvailable: 'Сервер недоступен',
    invalidType: 'Формат файла не поддерживается',
    wrongLoginOrPassword: 'Неверный логин или пароль',
    rateLimitExceeded: 'Превышено количество запросов. Повторите запрос через {count} секунд.',
    INVALID_JWT: 'Неверный токен',
    clone: 'Не удалось клонировать сущность.',
    uuid: 'Поле {field} должно быть валидным UUID'
  },

  fields: {
    login: 'логин',
    name: 'имя',
    username: 'логин',
    password: 'пароль',
    owner: 'владелец',
    OwnerId: 'владелец',
    action: 'действие',
    source: 'источник',
    amount: 'сумма',
    multiplier: 'множитель',
    comment: 'комментарий',
    moderation: 'модерация',
    contact: {
      phone: 'телефон'
    },
    payload: {
      sender: 'отправитель',
      text: 'текст'
    },
    data: {
      comment: 'комментарий',
      sendingType: 'тип отправления',
      sender: 'отправитель',
      firstName: 'имя',
      lastName: 'фамилия',
      email: 'e-mail',
      phone: 'телефон',
      sum: 'сумма',
      multiplier: 'множитель',
      button: {
        url: 'url кнопки',
        text: 'текст кнопки'
      }
    },
    settings: {
      check: {
        protocol: 'протокол',
        processor: 'процессор',
        credentials: {
          username: 'имя пользователя',
          password: 'пароль'
        },
        server: {
          host: 'хост',
          port: 'порт'
        },
        limits: {
          bulk: 'bulk',
          concurrency: 'concurrency',
          rpm: 'rpm'
        }
      },
      send: {
        protocol: 'протокол',
        processor: 'процессор',
        credentials: {
          username: 'имя пользователя',
          password: 'пароль'
        },
        server: {
          host: 'хост',
          port: 'порт'
        },
        limits: {
          bulk: 'bulk',
          concurrency: 'concurrency',
          rpm: 'rpm'
        }
      },
      ping: {
        protocol: 'протокол',
        processor: 'процессор',
        credentials: {
          username: 'имя пользователя',
          password: 'пароль'
        },
        server: {
          host: 'хост',
          port: 'порт'
        },
        limits: {
          bulk: 'bulk',
          concurrency: 'concurrency',
          rpm: 'rpm'
        }
      },
      sendings: {
        anySendername: 'любое имя отправителя'
      },
      billing: {
        overdraft: 'перерасход'
      },
      websocket: {
        channels: 'каналы'
      }
    },
    phone: 'телефон',
    email: 'e-mail',
    firstName: 'имя',
    lastName: 'фамилия',
    middleName: 'отчество',
    gender: 'пол',
    title: 'заголовок',
    type: 'тип',
    key: 'ключ',
    opcode: 'код оператора',
    group: 'группа',
    groupName: 'имя группы',
    operatorName: 'имя оператора',
    button: {
      url: 'URL',
      text: 'Текст'
    },
    DictionaryId: 'id словаря',
    mcc: 'mcc',
    mnc: 'mnc'
  }
}
