import { colors, components } from '@/utils'

import textOverflow from '@/components/text/overflow'

function renderPreLoader(h) {
  if (this.loading) {
    return h(
      components.progress,
      {
        props: {
          indeterminate: true,
          color: colors.primary,
          size: 10,
          width: 1
        }
      }
    )
  }
}
function renderThumb(h) {
  return h(
    'div',
    { class: `${components.switch}__thumb` },
    [ renderPreLoader.call(this, h) ]
  )
}
function renderRail(h) {
  return h(
    'div',
    { class: `${components.switch}__rail` },
    [ renderThumb.call(this, h) ]
  )
}

function renderLabel(h) {
  if (this.label) {
    return h(
      textOverflow,
      {
        class: `${components.switch}__label`,
        props: { value: this.label }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      attrs: this.$_attrs,
      class: this.$_class,
      directives: this.$_directives,
      on: this.$_on
    },
    [
      renderRail.call(this, h),
      renderLabel.call(this, h)
    ]
  )
}
