import { colors, components, inputModes, services } from '@/utils'

import info from '@/components/info'
import paymentsMethod from '@/components/services/payments/method'
import paymentsOffer from '@/components/services/payments/offer'

function renderWarning(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${services.payments}.warnings.no.methods`),
        color: colors.error
      }
    }
  )
}

function renderMethodField(h) {
  return h(
    paymentsMethod,
    {
      props: {
        OwnerId: this.OwnerId,
        value: this.proxy.method,
        items: this.methods,
        disabled: this.loading,
        as: 'buttons',
        required: true
      },
      on: {
        input: event => {
          this.proxy.method = event
        },
        methods: event => {
          if (event.length === 0) {
            this.showMethodsWarning = true
          }
        }
      }
    }
  )
}
function renderAmountField(h) {
  if (this.proxy.method) {
    return h(
      components['text-field'],
      {
        props: {
          value: this.proxy.amount,
          label: this.getTranslate(`${services.payments}.labels.amount`),
          hint: this.commissionHint,
          suffix: this.commissionSuffix,
          disabled: this.loading,
          mode: inputModes['outline-label'],
          type: 'number',
          name: 'amount',
          min: 1,
          step: 1,
          rounded: true,
          autofocus: true,
          clearable: true,
          required: true
        },
        on: {
          input: event => {
            this.proxy.amount = event
          }
        }
      }
    )
  }
}
function renderFields(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--12'
    },
    [
      renderMethodField.call(this, h),
      renderAmountField.call(this, h)
    ]
  )
}

function renderOffer(h) {
  if (this.proxy.method) {
    return h(
      paymentsOffer,
      {
        props: {
          value: this.acceptOffer,
          method: this.proxy.method
        },
        on: {
          input: event => {
            this.acceptOffer = event
          }
        }
      }
    )
  }
}

function renderForm(h) {
  return h(
    components.form,
    {
      class: 'grid grid-gap--8'
    },
    [
      renderFields.call(this, h),
      renderOffer.call(this, h)
    ]
  )
}

export default function(h) {
  if (this.showMethodsWarning) {
    return renderWarning.call(this, h)
  }

  return renderForm.call(this, h)
}
