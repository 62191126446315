import { CSendingTypesGenerallyAvailable } from '@sigmasms/commons'

import { inputModes } from '@/utils'

import selectorSendingType from '@/components/selector/sendingType'

export default function(h, { path, serviceName }) {
  return h(
    selectorSendingType,
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${serviceName}.labels.${path}`),
        items: CSendingTypesGenerallyAvailable,
        mode: inputModes['line-label'],
        required: true
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
