import { cloneDeep } from 'lodash'

import { PATTERNS_FILTER, sendingsOutputFilter, serviceTemplate, services } from '@/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services.patterns,

      async inputFilter(result) {
        result.OwnerId = result?.Owner?.id || result?.OwnerId

        return result
      },

      outputFilter: sendingsOutputFilter,

      find: { defaultFilter: cloneDeep(PATTERNS_FILTER) },

      width: 600
    })
  ]
}
