export default function(h) {
  return h(
    'div',
    {
      class: `fjcc facc faic cursor--pointer square--${this.size}`,
      style: {
        transition: 'transform 150ms',
        transform: this.expanded ? 'rotate(180deg)' : 'rotate(0deg)'
      },
      on: {
        click: () => {
          this.toggle()
        }
      }
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: 'keyboard_arrow_down',
            color: 'grey'
          }
        }
      )
    ]
  )
}
