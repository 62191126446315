import { buttonModes, colors, components, icons } from '@/utils'

import button from '@/components/button'
import info from '@/components/info'
import { serviceName } from '@/components/services/sendings/utils'

function renderAsOwnerField(h) {
  return h(
    components.switch,
    {
      props: {
        value: this.asOwner,
        label: this.getTranslate(`${serviceName}.labels.asOwner`),
        outline: true
      },
      on: {
        input: event => {
          this.asOwner = event
        }
      }
    }
  )
}
function renderInfo(h) {
  if (!this.asOwner) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate(`${serviceName}.hints.resend`),
          color: colors.info
        }
      }
    )
  }
}
function renderDialogBody(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 px-2'
    },
    [
      renderAsOwnerField.call(this, h),
      renderInfo.call(this, h)
    ]
  )
}
function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'dialog__footer',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat,
            disabled: this.loading
          },
          on: {
            click: () => {
              this.showDialog = false
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.send'),
            color: colors.primary,
            loading: this.loading,
            disabled: this.loading
          },
          on: {
            click: () => {
              this.resend()
            }
          }
        }
      )
    ]
  )
}

function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`${serviceName}.titles.resend`),
        value: this.showDialog,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 400 : undefined
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      renderDialogBody.call(this, h),
      renderDialogFooter.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    button,
    {
      props: {
        icon: icons.send,
        mode: buttonModes.flat,
        color: colors.primary,
        loading: this.loading
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      },
      scopedSlots: {
        dialog: () => renderDialog.call(this, h)
      }
    }
  )
}
