import Actions from '@sigma-legacy-libs/essentials/lib/utils/Actions'
import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    validation() {
      if (!this.proxy.title) {
        return false
      }

      return Object.keys(this.proxy.actions).some(key => Actions.validateValue(this.proxy.actions[key], key))
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  render
}
