import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { catchSymbolsMenus, components, inputModes, services } from '@/utils'

import rulesActionsDialogGeneratorsButtons from '@/components/misc/rules/actions/generators/buttons'
import { className } from '@/components/misc/rules/utils'
import selectorSendingType from '@/components/selector/sendingType'
import catchSymbolsInput from '@/components/services/sendings/catchSymbolsInput'
import fallbackOptions from '@/components/services/sendingsCreate/form/fallback/options'

function renderBasicFields(h) {
  return h(
    'div',
    {
      class: `${className}-card__row`
    },
    [
      h(
        selectorSendingType,
        {
          props: {
            value: this.proxy.type,
            mode: inputModes['line-label'],
            label: this.getTranslate(`${className}.labels.type`),
            permission: `advanced.${services.sendings}.create`,
            readonly: this.readonly
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          }
        }
      ),

      h(
        components.select,
        {
          props: {
            value: this.proxy.payload.sender,
            label: this.getTranslate(`${className}.labels.sender`),
            service: services.sendernames,
            query: { type: this.proxy.type },
            itemTitle: 'name',
            itemValue: 'name',
            mode: inputModes['line-label'],
            details: false,
            dense: true,
            rounded: true,
            clearable: true,
            combobox: true,
            autocomplete: true,
            readonly: this.readonly
          },
          on: {
            input: event => {
              this.proxy.payload.sender = event
            }
          }
        }
      )
    ]
  )
}

function renderTextField(h) {
  return h(
    components.textarea,
    {
      props: {
        value: this.proxy.payload.text,
        label: this.getTranslate(`${className}.labels.text`),
        mode: inputModes.outline,
        details: false,
        dense: true,
        rounded: true,
        clearable: true,
        readonly: this.readonly
      },
      on: {
        input: event => {
          this.proxy.payload.text = event
        }
      }
    }
  )
}

function renderViberButtons(h) {
  if (this.proxy.payload.button) {
    return h(
      components.card,
      {
        class: `${className}-card`,
        props: {
          dashed: true,
          outline: true,
          rounded: true
        }
      },
      [
        h(
          catchSymbolsInput,
          {
            props: {
              value: this.proxy.payload.button.url,
              label: this.getTranslate(`${services.sendings}.labels.button.url`),
              hint: this.getTranslate(`${services.sendings}.hints.button.url`),
              type: 'url',
              dense: true,
              mode: inputModes['line-label'],
              rounded: true,
              tags: this.tags,
              customMenu: catchSymbolsMenus.custom.$
            },
            on: {
              input: event => {
                this.proxy.payload.button.url = event
              }
            }
          }
        ),

        h(
          catchSymbolsInput,
          {
            props: {
              value: this.proxy.payload.button.text,
              label: this.getTranslate(`${services.sendings}.labels.button.text`),
              hint: this.getTranslate(`${services.sendings}.hints.button.text`),
              mode: inputModes['line-label'],
              dense: true,
              rounded: true,
              tags: this.tags,
              customMenu: catchSymbolsMenus.custom.$
            },
            on: {
              input: event => {
                this.proxy.payload.button.text = event
              }
            }
          }
        )
      ]
    )
  }
}
function renderWhatsAppButtons(h) {
  if (this.proxy.payload.buttons) {
    return h(
      rulesActionsDialogGeneratorsButtons,
      {
        props: {
          value: this.proxy.payload.buttons
        },
        on: {
          input: event => {
            this.proxy.payload.buttons = event
          }
        }
      }
    )
  }
}
function renderButtons(h) {
  switch (this.proxy.type) {
    case SENDING_TYPES.viber: return renderViberButtons.call(this, h)
    case SENDING_TYPES.vk:
    case SENDING_TYPES.whatsapp: return renderWhatsAppButtons.call(this, h)
  }
}

function renderFallbackOptions(h) {
  return h(
    fallbackOptions,
    {
      props: {
        value: this.proxy.$options,
        readonly: this.readonly
      },
      on: {
        input: event => {
          this.proxy.$options = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    components.card,
    {
      class: `${className}-card`,
      props: {
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [
      renderBasicFields.call(this, h),
      renderTextField.call(this, h),
      renderButtons.call(this, h),
      renderFallbackOptions.call(this, h)
    ]
  )
}
