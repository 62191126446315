import { colors, icons } from '@/utils'

export const providersStates = {
  active: {
    color: colors.success,
    icon: icons.check_circle_outline
  },
  inactive: {
    color: colors.grey,
    icon: icons.pause_circle_outline
  },
  connecting: {
    color: colors.primary,
    icon: icons.sync
  },
  disconnecting: {
    color: colors.error,
    icon: icons.highlight_off
  },
  stopped: {
    color: colors.warning,
    icon: icons.do_not_disturb_on
  },
  'half-open': {
    color: colors.info,
    icon: icons.access_time
  },
  'error-code': {
    color: colors.error,
    icon: icons.error_outline
  },
  'error-connection': {
    color: colors.error,
    icon: icons.error_outline
  }
}
