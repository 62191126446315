import sticker from '@/components/misc/sticker'
import { serviceName } from '@/components/services/servers/utils'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value.host,
        icon: 'vpn_lock',
        color: 'grey',
        label: this.getTranslate(`${serviceName}.labels.host`),
        borderStyle: 'dashed'
      }
    }
  )
}
