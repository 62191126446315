import { labels, types } from './sendings'

export default {
  title: 'Fallbacks',

  types,
  labels,

  errors: {
    onTimeout: {
      timeout: 'Cannot be less than 10'
    }
  }
}
