import { get } from 'lodash'

import { buttonModes, catchSymbolsMenus, colors, icons, services } from '@/utils'

import button from '@/components/button'
import ExpandArrow from '@/components/misc/ExpandArrow'
import sticker from '@/components/misc/sticker'
import sendingsCreateForm from '@/components/services/sendingsCreate/form'

function renderTextSticker(h, data) {
  if (this.viewport.breakpoint.smUp) {
    const value = get(data, 'payload.text')

    if (value) {
      return h(
        sticker,
        {
          props: {
            value,
            label: this.getTranslate(`${services.sendings}.labels.text`),
            width: 'fit-content'
          }
        }
      )
    }

    return h('div', { class: 'ff' })
  }
}
function renderHeader(h, data, expanded) {
  if (data) {
    const type = get(data, 'type')
    const sender = get(data, 'payload.sender')

    return h(
      'div',
      {
        class: 'grid grid-gap--8 faic pl-2 pr-0',
        style: {
          gridTemplateColumns: this.viewport.breakpoint.smUp ? '150px 1fr auto' : '1fr auto'
        }
      },
      [
        h(
          sticker,
          {
            props: {
              value: sender,
              label: this.getTranslate(`${services.sendings}.labels.sender`),
              color: type,
              iconSVG: type,
              width: this.viewport.breakpoint.smUp ? 150 : 'auto'
            }
          }
        ),
        renderTextSticker.call(this, h, data),
        h(ExpandArrow, { props: { expanded } })
      ]
    )
  }
}
function renderSendingsCreateForm(h) {
  return h(
    sendingsCreateForm,
    {
      class: 'pa-2',
      props: {
        value: this.proxy,
        types: this.sendingTypes,
        tags: catchSymbolsMenus.$,
        customMenu: catchSymbolsMenus.custom.$,
        disabled: this.disabled,
        readonly: this.readonly
      }
    }
  )
}
function renderRemoveButton(h, index) {
  if (!this.disabled || !this.readonly) {
    return h(
      'div',
      {
        class: 'fjcfe facfe'
      },
      [
        h(
          button,
          {
            props: {
              label: this.getTranslate('misc.buttons.remove'),
              mode: buttonModes.flat,
              color: colors.error,
              disabled: this.disabled
            },
            on: {
              click: () => {
                this.removeFallback(index)
              }
            }
          }
        )
      ]
    )
  }
}
function renderPanels(h) {
  return h(
    'g-expansion-panels',
    {
      props: {
        flat: this.flat,
        outline: true,
        rounded: true
      }
    },
    [
      h(
        'g-expansion-panel',
        {
          scopedSlots: {
            header: ({ expanded }) => renderHeader.call(this, h, this.proxy, expanded)
          }
        },
        [ renderSendingsCreateForm.call(this, h) ]
      ),

      ...this.proxy.fallbacks.map((item, index) => {
        return h(
          'g-expansion-panel',
          {
            attrs: { id: `fallback-${index}` },
            on: {
              mounted: () => this.scrollToPanel(index)
            },
            scopedSlots: {
              header: ({ expanded }) => renderHeader.call(this, h, item, expanded)
            },
            ref: `fallback-${index}`,
            key: `fallback-${index}`
          },
          [
            h(
              'div',
              {
                class: 'pa-2'
              },
              [
                h(
                  'div',
                  {
                    class: 'grid grid-gap--8'
                  },
                  [
                    this.$slots.default || this.$scopedSlots.default && this.$scopedSlots.default({ value: item }),

                    renderRemoveButton.call(this, h, index)
                  ]
                )
              ]
            )
          ]
        )
      })
    ]
  )
}

function renderFooter(h) {
  if (!this.disabled || !this.readonly) {
    return h(
      'div',
      {
        class: 'fjcfe facfe'
      },
      [
        h(
          button,
          {
            props: {
              label: this.getTranslate('misc.buttons.fallback'),
              icon: icons.add,
              mode: buttonModes.outline,
              color: colors.primary,
              disabled: this.disabled
            },
            on: {
              click: () => {
                this.add()
              }
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderPanels.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
