import { buttonModes, colors, components, inputModes, services, states } from '@/utils'

import button from '@/components/button'
import info from '@/components/info'

function renderMessageRuleIdField(h) {
  return h(
    components.select,
    {
      props: {
        value: this.messageRuleId,
        label: this.getTranslate(`${services.messageRuleGroups}.labels.rule`),
        service: services.messageRules,
        query: { isActive: true },
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        autocomplete: true,
        details: false
      },
      on: {
        input: event => {
          this.messageRuleId = event
        }
      }
    }
  )
}
function renderRows(h) {
  return this.restData[services.messageRuleGroups].find.data.map(item => {
    return h(
      components['list-item'],
      {
        props: {
          dense: true,
          rounded: true,
          label: item.title
        },
        on: {
          click: () => {
            this.$emit('id', item.id)
            this.clear()
            this.proxy = false
          }
        }
      }
    )
  })
}
function renderGroups(h) {
  if (this.restData[services.messageRuleGroups].find.state === states.ready) {
    if (this.restData[services.messageRuleGroups].find.pagination.total) {
      return h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          h(
            info,
            {
              props: {
                value: this.getTranslate('routes.hints.direct.choose'),
                color: colors.warning
              }
            }
          ),

          renderRows.call(this, h)
        ]
      )
    }
  }
}
function renderBody(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pt-2 px-2'
    },
    [
      renderMessageRuleIdField.call(this, h),
      renderGroups.call(this, h)
    ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat,
            disabled: this.loading
          },
          on: {
            click: () => {
              this.clear()
              this.proxy = false
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.create'),
            color: colors.primary,
            loading: this.loading,
            disabled: this.loading || this.disabled
          },
          on: {
            click: () => {
              this.create()
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`${services.messageRuleGroups}.dialogs.title.direct`),
        value: this.proxy,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    },
    [
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
