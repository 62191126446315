import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'
import { CSendingTypesGenerallyAvailable } from '@sigmasms/commons'

import { serviceName } from '@/components/services/routingRules/utils'

const defaultRules = [ 'payload.recipient', 'payload.text', 'paylaod.sender' ]
const metaRules = [ 'meta._recipientData.code', 'meta._recipientData.group', 'mccmnc' ]
const specialTypes = {
  [SENDING_TYPES.sms]: [ ...metaRules ],
  [SENDING_TYPES.voice]: [ ...metaRules ]
}
const rules = CSendingTypesGenerallyAvailable.reduce((result, type) => {
  result[type] = [ ...defaultRules ]
  if (specialTypes[type]) {
    result[type].push(...specialTypes[type])
  }

  return result
}, {})

function renderFieldType(h) {
  if (this.type) {
    if (this.proxy.tag) {
      return h(
        'div',
        {
          class: 'fjcc text--grey caption'
        },
        this.getTranslate(`${serviceName}.rules.${this.proxy.tag}`)
      )
    } else {
      return h(
        'div',
        {
          class: 'fjcc ff'
        },
        [
          h(
            'g-menu',
            {
              props: {
                rounded: true
              }
            },
            [
              h(
                'g-button',
                {
                  class: 'ma-0',
                  props: {
                    flat: true,
                    rounded: true,
                    color: 'primary',
                    label: this.getTranslate(`${serviceName}.buttons.routingRule`)
                  },
                  slot: 'activator'
                }
              ),

              h(
                'g-list',
                {
                  props: {
                    dense: true,
                    rounded: true
                  }
                },
                rules[this.type].map(rule => {
                  return h(
                    'g-list-item',
                    {
                      props: {
                        label: this.getTranslate(`${serviceName}.rules.${rule}`),
                        dense: true
                      },
                      on: {
                        click: () => {
                          this.tag = rule
                          this.proxy.value = undefined
                        }
                      }
                    }
                  )
                })
              )
            ]
          )
        ]
      )
    }
  }
}

function renderFieldValue(h) {
  if (this.proxy.tag) {
    let component = 'g-select'
    let props

    const defaultProps = {
      value: this.proxy.value,
      label: this.getTranslate(`${serviceName}.labels.condition`),
      mode: 'outline',
      disabled: this.disabled,
      details: false,
      rounded: true,
      dense: true,
      clearable: true
    }

    switch (this.proxy.tag) {
      case 'meta._recipientData.group': {
        props = Object.assign({}, defaultProps, {
          service: 'operatorGroups',
          itemTitle: 'group',
          itemValue: 'group',
          query: {
            $scope: [ 'GroupByGroup', 'available' ],
            $order: [ [ 'group', 'asc' ] ]
          },
          setOnBlur: true,
          multiple: true,
          combobox: true,
          autocomplete: true,
          menuProps: {
            fullWidth: true,
            minWidth: 300,
            maxWidth: 300
          }
        })
        break
      }
      case 'payload.recipient': {
        props = Object.assign({}, defaultProps, {
          autocomplete: true,
          multiple: true,
          combobox: true,
          setOnBlur: true
        })
        break
      }
      case 'payload.sender': {
        props = Object.assign({}, defaultProps, {
          service: 'sendernames',
          itemTitle: 'name',
          itemValue: 'name',
          autocomplete: true,
          multiple: true,
          combobox: true
        })
        break
      }

      default: {
        component = 'g-text-field'
        props = Object.assign({}, defaultProps)
        break
      }
    }

    return h(
      component,
      {
        props,
        on: {
          input: event => {
            this.proxy.value = event
          }
        }
      }
    )
  }
}

function renderContent(h) {
  if (this.type) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        renderFieldValue.call(this, h),
        renderFieldType.call(this, h)
      ]
    )
  } else {
    return h(
      'div',
      {
        class: 'faic'
      },
      [
        h(
          'g-icon',
          {
            class: 'mr-3',
            props: {
              value: 'info_outline',
              color: 'warning'
            }
          }
        ),

        h(
          'div',
          {
            class: 'text--warning body-1'
          },
          this.getTranslate(`${serviceName}.hints.wrongType`)
        )
      ]
    )
  }
}

export default function(h) {
  return renderContent.call(this, h)
}
