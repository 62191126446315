import { buttonModes, colors, components, icons, services, sizes } from '@/utils'

import button from '@/components/button'
import buttonCopy from '@/components/button/copy'
import info from '@/components/info'
import tag from '@/components/tag'

function renderWarning(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${services.tokens}.warnings.token`),
        color: colors.error
      }
    }
  )
}
function renderToken(h) {
  return h(
    'div',
    {
      class: `${services.tokens}-dialog__token`
    },
    [ this.$_token ]
  )
}
function renderSensitivityButton(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        icon: this.sensitivity ? icons.visibility : icons.visibility_off,
        color: this.sensitivity ? colors.success : colors.grey,
        tooltip: this.getTranslate(`${services.tokens}.tooltips.sensitivity`)
      },
      on: {
        click: () => {
          this.sensitivity = !this.sensitivity
        }
      }
    }
  )
}
function renderCopyButton(h) {
  return h(
    buttonCopy,
    {
      props: {
        value: this.data?.token,
        mode: buttonModes.flat,
        tooltip: this.getTranslate(`${services.tokens}.tooltips.copy`),
        snackbar: {
          text: this.getTranslate(`${services.tokens}.snackbars.copy`),
          type: colors.success
        }
      }
    }
  )
}
function renderContent(h) {
  if (this.$_token) {
    return h(
      'div',
      {
        class: `${services.tokens}-dialog__content`
      },
      [
        renderToken.call(this, h),
        renderSensitivityButton.call(this, h),
        renderCopyButton.call(this, h)
      ]
    )
  }
}
function renderBody(h) {
  return h(
    'div',
    {
      class: `${services.tokens}-dialog`
    },
    [
      renderWarning.call(this, h),
      renderContent.call(this, h)
    ]
  )
}

function renderTimer(h) {
  if (this.disabled) {
    return h(
      tag,
      {
        props: {
          label: this.count,
          size: sizes.huge,
          color: colors.grey,
          monospace: true,
          as: 'button'
        }
      }
    )
  }
}
function renderCloseButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.close'),
        mode: buttonModes.flat,
        disabled: this.disabled
      },
      on: {
        click: () => {
          this.proxy = false
        }
      }
    }
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      renderTimer.call(this, h),
      renderCloseButton.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`${services.tokens}.titles.copy`),
        value: this.proxy,
        closeOnEsc: false,
        closeOnClick: false,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 'max-content' : undefined
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    },
    [
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
