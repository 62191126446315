import { getPrice } from '@sigma-frontend-libs/commons'
import { RECURRENT_PAYMENTS_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, icons, permissionPresets, services } from '@/utils'

import button from '@/components/button'
import info from '@/components/info'
import sticker from '@/components/misc/sticker'
import eventsHistoryTab from '@/components/services/eventsHistory/tab'
import { recurrentPaymentIcons, recurrentPaymentsStatusColors } from '@/components/services/recurrentPayments/utils'
import actions from '@/components/templates/service/item/actions'
import date from '@/components/templates/service/item/date'
import owner from '@/components/templates/service/item/owner'

function renderStatus(h) {
  return h(
    sticker,
    {
      props: {
        value: this.viewport.size.width > 500 || !this.isPage ? this.getTranslate(`${services.recurrentPayments}.statuses.${this.value.status}`) : undefined,
        label: this.viewport.size.width > 500 || !this.isPage ? this.getTranslate(`${services.recurrentPayments}.labels.status`) : undefined,
        icon: recurrentPaymentIcons.status[this.value.status],
        iconTooltip: this.getTranslate(`${services.recurrentPayments}.hints.statuses.${this.value.status}`),
        color: recurrentPaymentsStatusColors[this.value.status]
      }
    }
  )
}
function renderAmount(h) {
  return h(
    sticker,
    {
      props: {
        value: getPrice(this.value.amount, { currency: this.currency }),
        label: this.amountLabel,
        iconSVG: this.currency,
        color: colors.grey
      }
    }
  )
}
function renderMethod(h) {
  if (this.viewport.size.width > 610) {
    return h(
      sticker,
      {
        props: {
          value: this.getTranslate(`${services.payments}.methods.${this.value.method}`),
          label: this.getTranslate(`${services.recurrentPayments}.labels.method`),
          iconSVG: this.value.method,
          iconTooltip: this.getTranslate(`${services.payments}.methods.${this.value.method}`),
          color: colors[this.value.method]
        }
      }
    )
  }
}
function renderPeriod(h) {
  if (this.viewport.size.width > 820) {
    return h(
      sticker,
      {
        props: {
          value: this.period,
          label: this.getTranslate(`${services.recurrentPayments}.labels.type`)
        }
      }
    )
  }
}
function renderOwner(h) {
  if (this.viewport.size.width > 1280 && this.isPage) {
    return h(
      owner,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: {
        [`${services.recurrentPayments}-item__info`]: true,
        [`${services.recurrentPayments}-item__info--admin`]: this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp),
        [`${services.recurrentPayments}-item__info--page`]: this.isPage
      }
    },
    [
      renderStatus.call(this, h),
      renderAmount.call(this, h),
      renderMethod.call(this, h),
      renderPeriod.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderEventHistoryDialogBody(h) {
  return h(
    eventsHistoryTab,
    {
      props: {
        entityId: this.value.id
      }
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat
          },
          slot: 'footer',
          on: {
            click: () => {
              this.showEventsHistoryDialog = false
            }
          }
        }
      )
    ]
  )
}
function renderEventHistoryDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`services.${services.eventsHistory}`),
        value: this.showEventsHistoryDialog,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 600 : undefined
      },
      on: {
        input: event => {
          this.showEventsHistoryDialog = event
        }
      }
    },
    [ renderEventHistoryDialogBody.call(this, h) ]
  )
}
function renderDialogBody(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${services.recurrentPayments}.contents.confirm.${this.isPaused ? 'resume' : 'pause'}`)
      }
    }
  )
}
function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'dialog__footer',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.showDialog = false
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate(`${services.recurrentPayments}.buttons.${this.isPaused ? 'resume' : 'pause'}`),
            icon: icons[this.isPaused ? 'play_arrow' : 'pause'],
            color: this.isPaused ? colors.success : colors.primary
          },
          on: {
            click: async () => {
              await this.activateRecurrentPayment()
              this.showDialog = false
            }
          }
        }
      )
    ]
  )
}
function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate('commons.titles.confirm.title'),
        value: this.showDialog,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 400 : undefined
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      renderDialogBody.call(this, h),
      renderDialogFooter.call(this, h)
    ]
  )
}
function renderListItem(h, { show = true, permission, accept = permissionPresets.meUp, validation, callback, icon, iconColor, pathToTranslate }) {
  if (this.checkPermissions(permission, accept)) {
    if (show) {
      if (validation) {
        return h(
          components['list-item'],
          {
            props: {
              icon,
              iconColor,
              label: this.getTranslate(pathToTranslate),
              dense: true
            },
            on: {
              click: callback
            }
          }
        )
      }
    }
  }
}
function renderActions(h) {
  if (this.checkPermissions(`advanced.${services.recurrentPayments}.update`, permissionPresets.meUp)) {
    return h(
      actions,
      {
        props: {
          value: this.value,
          actions: [ 'remove' ],
          service: services.recurrentPayments,
          accept: permissionPresets.meUp
        }
      },
      [
        renderListItem.call(this, h, {
          permission: `advanced.${services.recurrentPayments}.get`,
          accept: permissionPresets.managerUp,
          validation: true,
          icon: icons.history,
          callback: () => this.showEventsHistoryDialog = true,
          pathToTranslate: `services.${services.eventsHistory}`
        }),
        renderListItem.call(this, h, {
          show: this.value.OwnerId === this.account.id,
          permission: `advanced.${services.recurrentPayments}.update`,
          validation: [ RECURRENT_PAYMENTS_STATUSES.init, RECURRENT_PAYMENTS_STATUSES.error ].includes(this.value.status),
          callback: this.repeatRecurrentPayment,
          icon: icons.verified_user,
          iconColor: colors.success,
          pathToTranslate: `${services.recurrentPayments}.buttons.confirm`
        }),
        renderListItem.call(this, h, {
          permission: `advanced.${services.payments}.get`,
          validation: true,
          callback: () => this.$emit(services.payments, this.value.id),
          icon: icons.history,
          pathToTranslate: `${services.recurrentPayments}.buttons.${services.payments}`
        }),
        renderListItem.call(this, h, {
          permission: `advanced.${services.recurrentPayments}.update`,
          validation: ![ RECURRENT_PAYMENTS_STATUSES.init, RECURRENT_PAYMENTS_STATUSES.error ].includes(this.value.status),
          callback: () => this.showDialog = true,
          icon: icons[this.isPaused ? 'play_arrow' : 'pause'],
          iconColor: colors.primary,
          pathToTranslate: `${services.recurrentPayments}.buttons.${this.isPaused ? 'resume' : 'pause'}`
        }),
        renderListItem.call(this, h, {
          permission: `advanced.${services.recurrentPayments}.update`,
          validation: this.value.status === RECURRENT_PAYMENTS_STATUSES.block,
          callback: () => this.$emit('unlockRecurrentPayment', this.value.id),
          icon: icons.done,
          iconColor: colors.success,
          pathToTranslate: 'misc.buttons.unlock'
        })
      ]
    )
  }
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        ['service-template-item']: true,
        [`${services.recurrentPayments}-item`]: true,
        ['hover-parent hover-parent--opacity']: this.viewport.breakpoint.mdUp
      },
      key: this.value.id
    },
    [
      renderInfo.call(this, h),
      renderActions.call(this, h),
      renderCreatedAt.call(this, h),
      renderDialog.call(this, h),
      renderEventHistoryDialog.call(this, h)
    ]
  )
}
