import Vue from 'vue'
import { mapGetters } from 'vuex'

import { get, omit } from 'lodash'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import { serviceName } from '@/components/services/sendings/utils'

import render from './render'

export default {
  name: 'Resend',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      loading: false,
      showDialog: false,
      asOwner: false
    }
  },

  computed: {
    ...mapGetters({ token: 'authentication/token' })
  },

  methods: {
    async resend() {
      if (this.value) {
        try {
          this.loading = true

          let token = this.token
          if (this.asOwner) {
            const { data } = await Vue.$GRequest.get('login/as', get(this.value, 'Owner.id'))
            token = data.token
          }

          await Vue.$GRequest._request({
            method: 'POST',
            url: `/${serviceName}`,
            headers: { Authorization: token },
            data: {
              type: get(this.value, 'type'),
              recipient: get(this.value, 'payload.recipient'),
              payload: omit(get(this.value, 'payload'), 'recipient')
            }
          })

          this.showDialog = false
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
        }
      }
    }
  },

  render
}
