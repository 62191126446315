import { colors } from '../constants/enums'

export const eventsHistoryColors = {
  created: colors.success,
  updated: colors.info,
  removed: colors.error
}

export const eventsHistoryIcons = {
  created: 'add_circle_outline',
  updated: 'autorenew',
  removed: 'highlight_remove'
}
