import Vue from 'vue'

import { get, isEqual } from 'lodash'

import { Macro } from '@sigma-legacy-libs/cache'

import { services } from '../constants'
import { globalErrorHandler, globalErrorProcessor } from '../generators'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

export const balance = (options = {}) => {
  return {
    props: {
      UserId: String,
      currency: String
    },

    data() {
      return {
        balance: 0,
        balanceLoading: false,

        overdraft: 0
      }
    },

    computed: {
      $UserId() {
        return this.UserId || options.UserId
      }
    },

    watch: {
      $route: {
        handler(to, from) {
          if (!isEqual(to.params, from.params) || to.name !== from.name) {
            this.getBalance()
            this.getOverdraft()
          }
        },
        deep: true
      }
    },

    mounted() {
      if (!this.$UserId || this.$UserId === this.account.id) {
        Vue.$socket.on(`${services.billings}.created`, ({ OwnerId }) => {
          if (this.account.id === OwnerId) {
            this.getBalance()
          }
        })
      }

      this.getBalance()
      this.getOverdraft()
    },

    methods: {
      cachedGetBalance: Cache.wrapWithCache(async (key, UserId) => {
        return await Vue.$GRequest.get(`${services.billings}/balance`, UserId)
      }),
      cachedGetOverdraft: Cache.wrapWithCache(async (key, UserId) => {
        return await Vue.$GRequest.get(services.settingsUser, `${UserId}/${services.billings}.overdraft`)
      }),

      async getBalance() {
        try {
          this.balanceLoading = true

          const UserId = this.$UserId || this.account.id
          const { data } = await this.cachedGetBalance(`balance:${UserId}`, UserId)
          if (data) {
            this.balance = data.result
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.balanceLoading = false
        }
      },

      async getOverdraft() {
        if (this.$UserId) {
          // https://sigmasms.slack.com/archives/DDQ6HS91D/p1732627109571009
          if (this.checkPermissions(`advanced.${services.settingsUser}.get`)) {
            try {
              const { data } = await this.cachedGetOverdraft(`overdraft:${this.$UserId}`, this.$UserId)
              if (data) {
                let overdraft = parseFloat(data.result)
                if (isNaN(overdraft)) {
                  overdraft = 0
                }

                this.overdraft = overdraft
              }
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            }
          }
        } else {
          this.overdraft = get(this.account.settings, `${services.billings}.overdraft`, 0)
        }
      }
    }
  }
}
