import { cloneDeep } from 'lodash'

import { components, inputModes, permissionPresets, routerQueryReplace, services } from '@/utils'

import buttonUpDown from '@/components/button/upDown'
import permissions from '@/components/services/permissions/template'
import recurrentPaymentsList from '@/components/services/recurrentPayments/list'
import referrals from '@/components/services/referrals/template'
import settingsUser from '@/components/services/settingsUser/template'
import tariffsGenerator from '@/components/services/tariffs/generator'
import associationsByType from '@/components/services/users/associationsByType'
import usersBasicForm from '@/components/services/users/form/basic'
import messageProcessing from '@/components/services/users/messageProcessing'
import usersOTPSettingsChannels from '@/components/services/users/otp-settings/channels'
import usersRequisites from '@/components/services/users/requisites/template'
import usersTokens from '@/components/services/users/tokens/template'
import owner from '@/components/templates/service/item/owner'
import routesTree from '@/components/trees/routes'

function renderResellerField(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.ResellerId,
        label: this.getTranslate(`${services.users}.labels.reseller`),
        service: services.users,
        query: { isActive: true },
        itemTitle: 'username',
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        clearable: true,
        autocomplete: true,
        details: false,
        tabindex: 9
      },
      on: {
        input: event => {
          this.proxy.ResellerId = event
        }
      }
    }
  )
}
function renderManagerField(h) {
  if (this.checkPermissions([ `advanced.${services.users}.create`, `advanced.${services.users}.update` ])) {
    return h(
      components.select,
      {
        props: {
          value: this.proxy.data.partnership.ManagerId,
          label: this.getTranslate(`${services.users}.labels.manager`),
          service: services.users,
          query: { isActive: true },
          itemTitle: 'username',
          mode: inputModes['line-label'],
          dense: true,
          rounded: true,
          clearable: true,
          autocomplete: true,
          details: false,
          tabindex: 10
        },
        on: {
          input: event => {
            this.proxy.data.partnership.ManagerId = event
          }
        }
      }
    )
  } else if (this.checkPermissions([ `advanced.${services.users}.create`, `advanced.${services.users}.update` ], permissionPresets.manager)) {
    if (!this.isNew) {
      const value = this.proxy.data.partnership.ManagerId
      if (value) {
        return h(
          owner,
          {
            props: {
              value,
              pathToData: 'data.partnership.Manager'
            }
          }
        )
      }
    }
  }
}
function renderResellerAndManagerFields(h) {
  if (this.checkPermissions(`advanced.${services.users}.update`, permissionPresets.managerUp)) {
    return h(
      'div',
      {
        class: {
          'grid grid-gap--8': true,
          'grid-cols--2': this.viewport.breakpoint.mdUp
        }
      },
      [
        renderResellerField.call(this, h),
        renderManagerField.call(this, h)
      ]
    )
  }
}

function renderTabItemContent(h, tab) {
  switch (tab) {
    case 'general': {
      return h(
        usersBasicForm,
        {
          props: {
            value: this.proxy,
            errors: this.errors,
            isNew: this.isNew
          },
          on: {
            input: event => {
              this.proxy = event
            },
            submit: () => {
              this.$emit('submit', this.proxy)
            },
            validation: event => {
              this.$emit('validation', event)
            }
          }
        },
        [ renderResellerAndManagerFields.call(this, h) ]
      )
    }

    case services.settingsUser: {
      const props = {
        rounded: true,
        outline: true
      }

      return h(
        settingsUser(
          {
            payload: cloneDeep(this.proxy),
            UserId: this.proxy.id,
            ResellerId: this.proxy.ResellerId || this.proxy.Reseller && this.proxy.Reseller.id,
            card: { props },
            panels: { props }
          }
        ),
        {
          class: 'pa-2'
        }
      )
    }

    case services.tokens: {
      return h(
        usersTokens,
        {
          class: 'pb-2',
          props: {
            OwnerId: this.proxy.id
          }
        }
      )
    }

    case services.permissions: {
      return h(
        permissions(
          {
            UserId: this.proxy.id,
            ResellerId: this.proxy.ResellerId || this.proxy.Reseller && this.proxy.Reseller.id
          }
        )
      )
    }

    case 'userChannels': {
      return h(
        usersOTPSettingsChannels,
        {
          props: {
            UserId: this.proxy.id
          }
        }
      )
    }

    case services.tariffs: {
      return h(
        tariffsGenerator,
        {
          props: {
            value: this.proxy.Tariffs
          },
          on: {
            input: event => {
              this.proxy.Tariffs = event
            }
          }
        }
      )
    }

    case services.routingGroups: {
      return h(
        routesTree,
        {
          props: {
            value: this.proxy.RoutingGroups
          },
          on: {
            input: event => {
              this.proxy.RoutingGroups = event
            }
          }
        }
      )
    }

    case 'processMessages': {
      return h(
        'div',
        {
          class: {
            'grid grid-gap--8 faifs pa-2': true,
            'grid-cols--2': this.viewport.breakpoint.mdUp
          }
        },
        [
          h(
            associationsByType,
            {
              props: { value: this.proxy },
              on: {
                input: event => {
                  this.proxy = event
                }
              }
            }
          ),

          h(
            messageProcessing,
            {
              props: { value: this.proxy.MessageRuleGroups },
              on: {
                input: event => {
                  this.proxy.MessageRuleGroups = event
                }
              }
            }
          )
        ]
      )
    }

    case services.requisites: {
      return h(
        usersRequisites,
        {
          props: {
            OwnerId: this.proxy.id,
            User: cloneDeep(this.proxy)
          }
        }
      )
    }

    case services.recurrentPayments: {
      return h(
        recurrentPaymentsList({ OwnerId: this.proxy.id }),
        {
          class: 'mb-2',
          style: { 'border-bottom': '1px solid var(--border)' }
        }
      )
    }

    case 'referral': {
      return h(
        referrals,
        {
          class: 'pt-3 pb-3',
          props: { OwnerId: this.proxy.id }
        }
      )
    }
  }
}
function renderTabItem(h, key) {
  return h(
    'div',
    { key },
    [ renderTabItemContent.call(this, h, key) ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.users}-form`
    },
    [
      h(
        components['tabs-header'],
        {
          class: 'pt-2 px-2',
          props: {
            value: this.currentTab,
            hotkeys: true,
            tabs: this.tabs.map(key => {
              return {
                key,
                title: this.getTranslate(`${services.users}.tabs.${key}`)
              }
            })
          },
          on: {
            input: event => {
              if (this.isNew) {
                this.currentTab = event
              } else {
                if (event !== services.settingsUser) {
                  routerQueryReplace({
                    currentTab: event,
                    settingKey: undefined
                  })
                } else {
                  routerQueryReplace({ currentTab: event })
                }
              }
            }
          }
        }
      ),

      h(
        components.tabs,
        {
          props: { value: this.currentTab },
          scopedSlots: {
            body: () => this.tabs.map(tab => renderTabItem.call(this, h, tab))
          }
        }
      ),

      h(buttonUpDown)
    ]
  )
}
