import { buttonModes, components, sizes } from '@/utils'

import button from '@/components/button'
import squircle from '@/components/icon/squircle'
import { supportsColors, supportsIcons } from '@/components/services/support/utils'

const className = 'support'

function renderIcon(h, type) {
  return h(
    squircle,
    {
      props:
      {
        icon: supportsIcons[type],
        color: supportsColors[type]
      }
    }
  )
}
function renderItem(h, type, value) {
  return h(
    button,
    {
      props: {
        label: this.getLabel(type, value),
        mode: buttonModes.flat,
        size: sizes.small
      },
      on: {
        click: () => {
          this.clickHandler(type, value)
        }
      }
    }
  )
}
function renderItems(h, type) {
  return h(
    'div',
    {
      class: `${className}__type-items`
    },
    [
      this.globalSettings.contacts[type].map(item => {
        return renderItem.call(this, h, type, item)
      })
    ]
  )
}
function renderType(h, type) {
  return h(
    'div',
    {
      class: `${className}__type`
    },
    [
      renderIcon.call(this, h, type),
      renderItems.call(this, h, type)
    ]
  )
}
function renderContent(h) {
  return h(
    'div',
    {
      class: `${className}__content`
    },
    [
      Object.keys(this.globalSettings.contacts).map(type => {
        return renderType.call(this, h, type)
      })
    ]
  )
}

export default function(h) {
  return h(
    components.card,
    {
      class: className,
      props: {
        rounded: true,
        flat: true
      }
    },
    [ renderContent.call(this, h) ]
  )
}
