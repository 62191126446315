import { isPhoneNumber, supportTypes } from '@/utils'

import render from './render'

export default {
  name: 'SupportTemplate',

  methods: {
    getLabel(type, value) {
      switch (type) {
        case supportTypes.phones: return isPhoneNumber(value).formatted
        case supportTypes.socials: {
          const contact = value.replace(/https?:\/\/(?:www\.)?(?:[^\/.]+\.)?([^\/.]+)\.[^\/.]+.*/, '$1')

          return this.getTranslate(`support.labels.${contact}`)
        }
        default: return value
      }
    },

    clickHandler(type, value) {
      switch (type) {
        case supportTypes.phones: {
          window.location.href = `tel:${value}`
          break
        }
        case supportTypes.emails: {
          window.location.href = `mailto:${value}`
          break
        }
        case supportTypes.websites: {
          window.location.href = value.replace(/^(?:(?:https?:)?\/\/)?(.*?)$/, 'https://$1')
          break
        }
        case supportTypes.socials: {
          window.open(value, '_blank')
          break
        }
      }
    }
  },

  render
}
