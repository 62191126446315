export default {
  title: 'WebSockets',

  labels: {
    connected: 'Подключено по WebSockets',
    disconnected: 'Отключено от WebSockets'
  },

  tooltips: {
    connection: `Ваше соединение с платформой <strong>активно через WebSockets</strong>. Вы получаете обновления статусов и баланса <strong>в реальном времени</strong>.
<br>Если статус <strong>отключён</strong>, попробуйте:
<ul class="ma-0 pl-3"><li>Перезагрузить страницу.</li><li>Проверить интернет-соединение.</li></ul>
Если проблема сохраняется, возможно, это технические неполадки. Попробуйте ещё раз позже.`
  }
}
