import { cloneDeep } from 'lodash'

import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'
import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

const defaultPrice = 0.1
const defaultValue = {
  price: [ defaultPrice ],
  action: 'decrease',
  rules: []
}

export default {
  name: 'Rules',

  mixins: [ proxy({ type: 'array' }) ],

  props: {
    source: String,
    hasDefault: Boolean,
    isNew: Boolean
  },

  watch: {
    hasDefault(value) {
      if (!value && !this.proxy.length) {
        this.add()
      }
    }
  },

  methods: {
    watchProxyHandler() {
      if (!this.proxy.length && !this.hasDefault) {
        this.add()
      }

      this.transmitValue()
    },

    add() {
      const data = cloneDeep(defaultValue)
      if (this.source === SENDING_TYPES.voice) {
        if (data.price.length < 2) {
          data.price.push(defaultPrice)
        }
      }
      this.proxy.push(data)
    }
  },

  render
}
