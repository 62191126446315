export default {
  title: 'Informe financiero',

  labels: {
    id: 'ID',
    owner: 'Propietario',
    action: 'Acción',
    source: 'Fuente',
    amount: 'Cantidad',
    multiplier: 'Multiplicador',
    comment: 'Comentario',
    preset: 'Programar',
    status: 'Estado',
    tariff: 'Tarifa',
    noRef: 'No referible',
    price: 'Precio',
    OwnerId: 'Propietario',
    TariffId: 'Tarifa',
    total: 'Total',

    count: {
      pending: 'Pendiente {count}',
      refunded: 'Reembolsado {count}',
      complete: 'Completo {count}',
      invalid: 'Inválido {count}',
      total: 'Total {count}'
    },

    ref: {
      true: 'Referible',
      false: 'No referible'
    },

    data: {
      price: 'Precio',
      multiplier: 'Multiplicador'
    }
  },

  headers: {
    status: 'Estado',
    owner: 'Propietario',
    creator: 'Creador',
    source: 'Fuente',
    tariff: 'Tarifa',
    comment: 'Comentario',
    amount: 'Cantidad',
    total: 'Total',
    title: 'Título',
    frequency: 'Frecuencia',
    count_pending: 'Pendiente',
    sum_pending: 'Pendiente (suma)',
    count_refunded: 'Reembolsado',
    sum_refunded: 'Reembolsado (suma)',
    count_complete: 'Completo',
    sum_complete: 'Completo (suma)',
    count_invalid: 'Inválido',
    sum_invalid: 'Inválido (suma)',
    count_total: 'Total',
    sum_total: 'Total (suma)',
    referrer: 'Referente'
  },

  dialogs: {
    create: 'Crear una nueva facturación'
  },

  actions: {
    addFunds: 'Agregar fondos',
    charge: 'Cargar'
  },

  filter: {
    date: {
      from: 'Desde',
      by: 'Hasta'
    },
    time: {
      from: 'Hora desde',
      by: 'Hora hasta'
    }
  },

  hints: {
    amount: 'Por defecto, el multiplicador es 1.<br>Puedes cambiarlo en la sección adicional.',
    date: {
      from: 'AAAA-MM-DD',
      by: 'AAAA-MM-DD'
    },
    time: {
      from: 'HH:MM',
      by: 'HH:MM'
    },
    empty: 'Para obtener datos, utilice el filtro.'
  },

  presets: {
    billings: {
      details: 'Detalles de la facturación',
      group001: 'Agrupar por fuente'
    }
  },

  report: {
    download: 'Descargar',
    get: 'Obtener informe'
  },

  statuses: {
    pending: 'Pendiente',
    refunded: 'Reembolsado',
    complete: 'Completo',
    invalid: 'Inválido'
  },

  buttons: {
    clear: 'Limpiar filtro',
    refresh: 'Actualizar'
  },

  tooltips: {
    refresh: 'Actualiza los datos de la tabla usando el filtro seleccionado.',
    actions: 'Acumulación o resta de fondos'
  },

  tabs: {
    billings: 'Facturación',
    tasks: 'Tareas'
  },

  sources: {
    subtract: 'Sustraer',
    accrual: 'Acumulación',
    sms: 'SMS',
    voice: 'Voz',
    email: 'Correo electrónico',
    viber: 'Viber',
    vk: 'VK',
    whatsapp: 'Whatsapp',
    push: 'Notificación push'
  },

  snackbars: {
    created: 'Facturación creada con éxito.',
    updated: 'Facturación actualizada con éxito.',
    removed: 'Facturación eliminada con éxito.',
    funds: {
      success: 'Fondos agregados con éxito.',
      failed: 'Falló al agregar fondos.'
    }
  },

  currency: {
    RUB: '₽ rublos rusos',
    USD: '$ dólares estadounidenses',
    EUR: '€ euros',
    MXN: 'MX$ pesos mexicanos'
  }
}
