import { buttonModes, colors, components, icons, inputModes, popperPlacements, sizes } from '@/utils'

import button from '@/components/button'
import tag from '@/components/tag'

function renderEdgeArrow(h, options) {
  if (this.arrows && this.edgeArrows && this.viewport.breakpoint.mdUp) {
    return h(
      button,
      {
        props: {
          icon: options.icon,
          mode: buttonModes.flat,
          size: sizes.medium,
          disabled: options.disabled
        },
        on: {
          click: () => {
            this.setPage(options.page)
          }
        }
      }
    )
  }
}

function renderArrow(h, options) {
  if (this.arrows) {
    return h(
      button,
      {
        props: {
          icon: options.icon,
          mode: buttonModes.flat,
          size: sizes.medium,
          disabled: options.disabled
        },
        on: {
          click: () => {
            this.setPage(options.page)
          }
        }
      }
    )
  }
}

function renderPages(h) {
  return this.pages.map(page => {
    return h(
      button,
      {
        props: {
          label: page,
          size: sizes.medium,
          mode: this.currentPage !== page ? buttonModes.flat : buttonModes.filled,
          color: this.currentPage === page ? colors.primary : undefined
        },
        on: {
          click: () => {
            this.setPage(page)
          }
        }
      }
    )
  })
}

function renderLimitField(h) {
  if (this.isAdmin) {
    return h(
      'div',
      {
        class: 'flex grid-gap--8 pa-2'
      },
      [
        h(
          components['text-field'],
          {
            props: {
              value: this.limit,
              label: this.getTranslate('commons.labels.limit'),
              mode: inputModes.outline,
              dense: true,
              rounded: true,
              details: false,
              type: 'number',
              mask: /^\d{0,3}$/
            },
            on: {
              input: value => {
                this.limit = value
              }
            }
          }
        ),

        h(
          button,
          {
            props: {
              icon: icons.done,
              mode: buttonModes.flat,
              color: colors.primary,
              disabled: !this.limit
            },
            on: {
              click: () => {
                this.proxy.limit = this.limit
              }
            }
          }
        )
      ]
    )
  }
}
function renderSelectRowsList(h) {
  return h(
    components.list,
    {
      props: {
        dense: true,
        rounded: true
      }
    },
    [
      this.rowsPerPage.map(rows => {
        return h(
          components['list-item'],
          {
            props: {
              active: this.proxy.limit === rows,
              dense: true
            },
            on: {
              click: () => {
                this.setPage(1, rows)
              }
            }
          },
          [ rows ]
        )
      })
    ]
  )
}
function renderSelectRowsContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--0'
    },
    [
      renderSelectRowsList.call(this, h),
      renderLimitField.call(this, h)
    ]
  )
}
function renderSelectRows(h) {
  if (this.proxy.total) {
    if (this.selectRows) {
      if (this.viewport.size.width > 320) {
        return h(
          components.menu,
          {
            props: {
              value: this.showMenu,
              rounded: true,
              placement: popperPlacements['bottom-end'],
              offsetDistance: 8,
              closeOnContentClick: false,
              width: this.isAdmin ? '120px' : '80px'
            },
            on: {
              input: event => {
                this.showMenu = event
              }
            }
          },
          [
            h(
              button,
              {
                props: {
                  label: this.proxy.limit,
                  icon: icons.list,
                  size: sizes.medium,
                  color: colors.primary
                },
                slot: 'activator'
              }
            ),
            renderSelectRowsContent.call(this, h)
          ]
        )
      }
    }
  }
}

function renderTotal(h) {
  if (this.proxy.total) {
    return h(
      tag,
      {
        props: {
          as: 'button',
          label: this.proxy.total,
          size: sizes.huge,
          color: colors.primary,
          monospace: true
        }
      }
    )
  }
}

export default function(h) {
  if (this.show) {
    if (this.proxy.total > this.proxy.limit) {
      return h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: { 'grid-template-columns': this.buttonColumnsStyle }
        },
        [
          renderEdgeArrow.call(
            this,
            h,
            {
              icon: icons.first_page,
              page: 1,
              disabled: this.proxy.offset === 0
            }
          ),

          renderArrow.call(
            this,
            h,
            {
              icon: icons.keyboard_arrow_left,
              page: this.currentPage - 1,
              disabled: this.proxy.offset === 0
            }
          ),

          renderPages.call(this, h),

          renderArrow.call(
            this,
            h,
            {
              icon: icons.keyboard_arrow_right,
              page: this.currentPage + 1,
              disabled: this.proxy.offset + this.proxy.limit >= this.proxy.total
            }
          ),

          renderEdgeArrow.call(
            this,
            h,
            {
              icon: icons.last_page,
              page: this.totalPages,
              disabled: this.proxy.offset + this.proxy.limit >= this.proxy.total
            }
          ),

          renderSelectRows.call(this, h),

          renderTotal.call(this, h)
        ]
      )
    } else {
      return h(
        'div',
        {
          class: 'flex grid-gap--8'
        },
        [
          renderSelectRows.call(this, h),
          renderTotal.call(this, h)
        ]
      )
    }
  }
}
