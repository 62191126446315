export default {
  title: 'Archivos',

  states: {
    error: 'Error de carga',
    success: 'Archivo cargado',
    loading: 'Cargando archivo'
  },

  drop: 'Arrastra el archivo aquí para cargarlo'
}
