import { defaultWebsocketFindCreatedEvent, generateServices, globalErrorHandler, globalErrorProcessor, services, states } from '@/utils'

import render from './render'

export default function(UserId) {
  return {
    name: services.userComments,

    mixins: [
      generateServices({
        name: services.userComments,

        find: {
          defaultFilter: {
            UserId,
            $scope: [ 'Owner' ]
          },
          defaultPagination: { limit: 10 },

          async websocketFindCreatedEvent(instance) {
            if (UserId !== instance.UserId) {
              return
            }

            defaultWebsocketFindCreatedEvent.call(this, instance)
          }
        },

        get: false,
        update: false,
        remove: false
      })
    ],

    data() {
      return {
        text: undefined
      }
    },

    computed: {
      loading() {
        return this.restData[services.userComments].create.state === states.loading
      },
      disabled() {
        return this.loading || !this.text || !this.checkPermissions(`advanced.${services.userComments}.create`)
      }
    },

    mounted() {
      this.rest[services.userComments].find()
    },

    methods: {
      async create() {
        try {
          const response = await this.rest[services.userComments].create({
            text: this.text,
            UserId
          })
          if (response) {
            this.text = undefined
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    },

    render
  }
}
