import numeral from 'numeral'

import { colors, getLocaleDateString, getLocaleTimeString } from '@/utils'

import { getFileMiscInfo, getFileSizeColor } from '@/components/file/utils'

import render from './render'

export default {
  name: 'FileInfo',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    tags() {
      const { type, color } = getFileMiscInfo(this.value)

      const size = this.value.size
      const sizeColor = getFileSizeColor(size)
      const createdAt = this.value.createdAt
      const createdAtDate = new Date(createdAt)
      const createdAtYear = createdAtDate.getFullYear()
      const createdAtMonth = createdAtDate.getMonth()
      const createdAtDay = createdAtDate.getDate()
      const date = new Date()
      const currentYear = date.getFullYear()
      const currentMonth = date.getMonth()
      const currentDay = date.getDate()
      date.setDate(currentDay - 1)
      const yesterday = date.getDate()
      const dateOptions = {
        month: 'numeric',
        day: 'numeric'
      }
      if (createdAtYear !== currentYear) {
        dateOptions.year = 'numeric'
      }
      const timeOptions = {
        locale: this.locale,
        hour: '2-digit',
        minute: '2-digit',
        second: false
      }
      const dateLabel = []
      if (createdAtDay === currentDay && createdAtMonth === currentMonth && createdAtYear === currentYear) {
        dateLabel.push(this.getTranslate('misc.measure.date.today'))
      } else if (createdAtDay === yesterday && createdAtMonth === currentMonth && createdAtYear === currentYear) {
        dateLabel.push(this.getTranslate('misc.measure.date.yesterday'))
      } else {
        dateLabel.push(getLocaleDateString(createdAt, this.locale, dateOptions))
      }
      dateLabel.push(getLocaleTimeString(createdAt, timeOptions))
      const result = [
        {
          label: dateLabel.join(' '),
          tooltip: [
            getLocaleDateString(createdAt, this.locale, {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }),
            getLocaleTimeString(createdAt, { milliseconds: true })
          ].join(' '),
          color: colors.grey
        },
        {
          label: type,
          tooltip: this.value.type,
          color
        },
        {
          label: numeral(size).format('0b'),
          tooltip: numeral(size).format('0.000b'),
          color: sizeColor
        }
      ]

      return result
    }
  },

  render
}
