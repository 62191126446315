import Vue from 'vue'
import { mapGetters } from 'vuex'

import proxy from '@sigma-legacy-libs/g-proxy'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    isNew: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showFileManager: false,
      query: this.$route.query
    }
  },

  computed: {
    ...mapGetters({
      file: 'file/file',
      token: 'authentication/token'
    }),

    validation() {
      if (!this.proxy.title) {
        return false
      }

      return true
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    },
    file: {
      handler({ data }) {
        this.showFileManager = false
        this.import(data.id)
      },
      deep: true
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  methods: {
    async import(key) {
      try {
        await Vue.$GRequest._request({
          method: 'POST',
          url: '/keywords/import',
          headers: { Authorization: this.token },
          data: {
            ListId: this.$route.params.id,
            FileId: key
          }
        })
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    }
  },

  render
}
