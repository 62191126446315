import { buttonModes, colors, icons, services } from '@/utils'

import button from '@/components/button'

export default function(h) {
  return h(
    button,
    {
      props: {
        icon: icons.file_download,
        mode: buttonModes.flat,
        color: colors.primary,
        disabled: !this.value.documentId,
        tooltip: this.getTranslate(`${services.invoices}.tooltips.download`)
      },
      on: {
        click: () => {
          this.download()
        }
      }
    }
  )
}
