export const SENDING_STATES = {
  succeed: {
    name: 'succeed',
    color: 'success',
    icon: 'done'
  },
  failed: {
    name: 'failed',
    color: 'error',
    icon: 'clear'
  },
  pending: {
    name: 'pending',
    color: 'info',
    icon: 'update'
  },
  processing: {
    name: 'processing',
    color: 'info',
    icon: 'autorenew'
  },
  queued: {
    name: 'queued',
    color: 'info',
    icon: 'list'
  },
  paused: {
    name: 'paused',
    color: 'info',
    icon: 'pause_circle_outline'
  },
  sent: {
    name: 'sent',
    color: 'info',
    icon: 'trending_flat'
  },
  processed: {
    name: 'processed',
    color: 'info',
    icon: 'cached'
  },
  delivered: {
    name: 'delivered',
    color: 'success',
    icon: 'done'
  },
  seen: {
    name: 'seen',
    color: 'success',
    icon: 'done_all'
  }
}
