import { colors, eventsHistoryColors, eventsHistoryIcons, icons, permissionPresets, services } from '@/utils'

import sticker from '@/components/misc/sticker'
import buttonEdit from '@/components/services/eventsHistory/item/buttons/edit'
import date from '@/components/templates/service/item/date'
import owner from '@/components/templates/service/item/owner'

function renderService(h) {
  return h(
    sticker,
    {
      props: {
        value: this.getTranslate(this.service.title),
        label: this.getTranslate(`${services.eventsHistory}.types.${this.value.type}`),
        icon: eventsHistoryIcons[this.value.type],
        iconTooltip: this.getTranslate(`${services.eventsHistory}.types.${this.value.type}`),
        color: eventsHistoryColors[this.value.type],
        to: {
          name: this.service.name,
          params: this.toParamsForServiceSticker,
          query: { currentTab: this.service.currentTab }
        }
      }
    }
  )
}
function renderUser(h) {
  if (this.viewport.size.width > 500) {
    return h(
      owner,
      {
        props: {
          value: this.value,
          pathToData: 'User',
          label: this.getTranslate(`${services.eventsHistory}.labels.author`)
        }
      }
    )
  }
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: {
        [`${services.eventsHistory}-item__info`]: true,
        [`${services.eventsHistory}-item__info--admin`]: this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)
      }
    },
    [
      renderService.call(this, h),
      renderUser.call(this, h)
    ]
  )
}

function renderEntityId(h) {
  if (this.viewport.size.width > 1280) {
    if (this.service.entityId) {
      return h(
        sticker,
        {
          props: {
            value: { entityId: this.service.entityId },
            label: this.getTranslate(`${services.eventsHistory}.labels.id`),
            icon: icons.numbers,
            color: colors.grey,
            pathToTitle: 'entityId',
            borderStyle: this.computedTo ? 'solid' : 'dashed',
            to: this.computedTo,
            width: 'fit-content'
          }
        }
      )
    }
  }
}

function renderButtons(h) {
  return h(
    'div',
    {
      class: {
        [`${services.eventsHistory}-item__buttons`]: true,
        'hover-child': this.viewport.breakpoint.mdUp
      }
    },
    [
      h(
        buttonEdit,
        {
          props: {
            id: this.value.id
          }
        }
      )
    ]
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-item': true,
        [`${services.eventsHistory}-item`]: true,
        [`${services.eventsHistory}-item--id`]: !!this.service.entityId,
        'hover-parent hover-parent--opacity': this.viewport.breakpoint.mdUp
      }
    },
    [
      renderInfo.call(this, h),
      renderEntityId.call(this, h),
      renderButtons.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
