import { types } from './sendings'

export default {
  title: 'Usuarios',

  titles: {
    create: 'Crear nuevo usuario',
    add: {
      funds: 'Agregar fondos'
    },
    token: 'Clave API',
    presets: 'Preajustes'
  },

  user: 'Usuario',
  users: 'Usuarios',
  currentTariff: 'Tarifa actual',

  contents: {
    routingGroups: {
      confirm: {
        remove: 'Confirme la eliminación del grupo de enrutamiento solo de la configuración del usuario actual.'
      }
    }
  },

  labels: {
    owner: 'Propietario',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    reseller: 'Revendedor',
    manager: 'Gerente',
    referralCode: 'Código de referencia del usuario',
    target: 'sin nombres de remitentes | un nombre de remitente | {count} nombres de remitentes',

    no: {
      manager: 'Sin gerente'
    },

    data: {
      firstName: 'Nombre',
      lastName: 'Apellido',
      email: 'Correo electrónico',
      phone: 'Teléfono',
      partnership: {
        hasContract: 'Tiene contrato'
      },
      meta: {
        source: 'Fuente',
        usedReferralCode: 'Código de referencia utilizado'
      }
    },

    settings: {
      sendings: {
        $title: 'Envíos',
        allowDuplicates: {
          title: 'Permitir duplicados',
          false: 'No',
          true: 'Sí'
        },
        sendernamesCheck: 'Restringir nombres de remitentes para tipos',
        strictTemplates: Object.assign({ title: 'Plantilla estricta para los tipos de envíos especificados' }, types),
        anySendername: Object.assign({ title: 'Cualquier nombre de remitente' }, types),
        smpp: { async: 'SMPP async' },
        allow: 'Permitir'
      },
      routingGroup: {
        $title: 'Grupo de enrutamiento',
        sms: 'Grupo de enrutamiento SMS',
        viber: 'Grupo de enrutamiento Viber',
        vk: 'Grupo de enrutamiento VKontakte',
        whatsapp: 'Grupo de enrutamiento WhatsApp',
        push: 'Grupo de enrutamiento Push',
        voice: 'Grupo de enrutamiento Voice',
        whatsappchat: 'Grupo de enrutamiento WhatsApp chat',
        viberchat: 'Grupo de enrutamiento Viber chat',
        vkchat: 'Grupo de enrutamiento VKontakte chat',
        telegramchat: 'Grupo de enrutamiento Telegram chat',
        facebookchat: 'Grupo de enrutamiento Facebook chat',
        flashcall: 'Grupo de enrutamiento Flash call'
      },
      billing: {
        $title: 'Operaciones de facturación',
        overdraft: { title: 'Sobregiro' }
      },
      websocket: {
        $title: 'WEB Socket',
        channels: {
          title: 'Canales Websocket',
          dbUpdates: 'Actualización de base de datos',
          everything: 'Todo',
          health: 'Salud de la plataforma',
          sendingStatusUpdates: 'Actualización del estado de los envíos'
        }
      },
      smpp: {
        $title: 'SMPP',
        rudeMode: {
          title: 'Modo rudo',
          false: 'No',
          true: 'Sí'
        }
      },
      reseller: {
        $title: 'Revendedor',
        auth: {
          registration: {
            defaults: {
              title: 'Valores predeterminados al registrarse',
              tariffs: { title: 'Tarifa predeterminada' },
              balance: { title: 'Balance predeterminado' },
              routingGroups: { title: 'Grupo de enrutamiento predeterminado' },
              patterns: { title: 'Patrones' },
              sendernames: { title: 'Nombres de remitentes' }
            }
          }
        },
        enabled: {
          title: 'Habilitar configuración de revendedor',
          false: 'Deshabilitado',
          true: 'Habilitado'
        },
        frontend: {
          $title: 'Configuración del sitio',
          title: { title: 'Título del sitio' },
          colors: {
            title: 'Colores',
            primary: { title: 'Color primario' },
            secondary: { title: 'Color secundario' },
            accent: { title: 'Color de acento' },
            info: { title: 'Color informativo' },
            warning: { title: 'Color de advertencia' },
            error: { title: 'Color de error' },
            success: { title: 'Color de éxito' }
          }
        },
        paymentMethods: {
          title: 'Métodos de pago',
          key: { title: 'Métodos de pago' },
          value: {
            title: '',
            key: { title: 'Configuración del método de pago' },
            value: { title: '' }
          },
          simplepay: { title: 'SimplePay' }
        },
        billings: {
          processChildrenOperations: {
            title: 'Procesar operaciones de usuarios secundarios como propias'
          }
        }
      },
      webhooks: {
        $title: 'Webhooks',
        sendings: {
          title: 'Envíos',
          hookParameters: {
            url: { title: 'URL de la solicitud' },
            method: { title: 'Método de la solicitud' },
            headers: { title: 'Encabezados de la solicitud' },
            body: { title: 'Cuerpo de la solicitud' },
            statuses: {
              title: 'Mapeo de estados',
              seen: { title: 'Visto' },
              processing: { title: 'En proceso' },
              paused: { title: 'Pausado' },
              delivered: { title: 'Entregado' },
              failed: { title: 'Fallido' },
              pending: { title: 'Pendiente' },
              sent: { title: 'Enviado' }
            },
            numericStatuses: { title: 'Estados numéricos' },
            maxConnections: { title: 'Máximo número de conexiones' },
            successOn: {
              title: 'Condiciones para el procesamiento exitoso',
              status: { title: 'Verificación de estado (regexp)' },
              body: { title: 'Verificación del cuerpo de la respuesta (regexp)' }
            }
          }
        }
      }
    },

    permissions: {
      name: {
        create: 'Crear',
        finalize: 'Finalizar',
        find: 'Encontrar',
        generate: 'Generar',
        set: 'Establecer',
        get: 'Obtener',
        remove: 'Eliminar',
        update: 'Actualizar',
        modify: 'Modificar',
        seek: 'Buscar',
        download: 'Descargar',
        upload: 'Subir',
        setPermissions: 'Establecer permisos',
        setRoutingGroups: 'Establecer grupos de enrutamiento',
        setSettings: 'Establecer configuraciones',
        setSendernames: 'Establecer nombres de remitentes',
        setPatterns: 'Establecer patrones',
        setTariffs: 'Establecer tarifas',
        showSecrets: 'Mostrar permisos secretos',
        health: 'Rendimiento',
        loginAs: 'Iniciar sesión como',
        request: 'Solicitud',
        controlDocuments: 'Trabajar con documentos',
        edit: 'Editar',
        viewChangelogs: 'Ver lista de cambios',
        contracts: 'Contratos',
        companies: 'Empresas',
        documentTemplates: 'Plantillas de documentos',
        refund: 'Reembolso',
        presetUsersBalance: 'Posibilidad de descargar la lista de usuarios'
      }
    },

    sendernames: {
      strict: 'El nombre debe coincidir con uno de los indicados a continuación',
      names: 'Nombres de remitentes'
    },

    patterns: {
      strict: 'El mensaje debe coincidir con uno de estos patrones',
      names: 'Patrones'
    }
  },

  dialogs: {
    new: 'Nuevo usuario'
  },

  subheaders: {
    routingGroups: 'Grupos de enrutamiento',
    sendings: 'Envíos',
    billing: 'Cuenta de facturación',
    websocket: 'Websocket',
    smpp: 'SMPP',
    partnership: 'Asociación',
    permissions: {
      billings: 'Cuentas de facturación',
      dictionaries: 'Diccionarios',
      providerGroups: 'Grupos de proveedores',
      providers: 'Proveedores',
      routingGroups: 'Grupos de enrutamiento',
      routingRules: 'Reglas de enrutamiento',
      sendings: 'Envíos',
      storage: 'Almacenamiento',
      tariffs: 'Tarifas',
      users: 'Usuarios',
      platform: 'Plataforma',
      reports: 'Informes',
      components: 'Componentes',
      tasks: 'Tareas',
      contacts: 'Contactos',
      payment: 'Pagos',
      settings: 'Configuración del sitio',
      messageProcessors: 'Procesadores de mensajes',
      templates: 'Plantillas',
      patterns: 'Patrones',
      sendernames: 'Nombres de remitentes',
      requisites: 'Requisitos',
      sendingsIncoming: 'Mensajes entrantes',
      changelogs: 'Lista de cambios',
      contracts: 'Contratos',
      companies: 'Empresas',
      documentTemplates: 'Plantillas de documentos',
      operatorGroups: 'Grupos de operadores',
      secret: 'Secretos',
      paymentLogs: 'Historial de pagos',
      recurrentPayments: 'Pagos recurrentes'
    },
    sendernames: 'Nombres de remitentes',
    patterns: 'Patrones'
  },

  permissions: {
    allowAnySendername: 'Permitir cualquier nombre de remitente',
    allowSending: 'Envíos permitidos',
    smppAsync: 'SMPP async',
    options: {
      true: 'true',
      false: 'false',
      me: 'me'
    }
  },

  tabs: {
    general: 'General',
    settings: 'Configuraciones',
    settingsUser: 'Configuraciones',
    permissions: 'Permisos',
    tariffs: 'Tarifas',
    routingGroups: 'Rutas',
    processMessages: 'Procesamiento de mensajes',
    requisites: 'Requisitos',
    whatsapp: 'WhatsApp',
    recurrentPayments: 'Pagos recurrentes',
    referral: 'Recargas de referencia',
    tokens: 'Tokens',
    eventsHistory: 'Historial de eventos',
    eventsHistoryTab: 'Historial de cambios',
    userComments: 'Comentarios',
    userChannels: 'Canales OTP'
  },

  warnings: {
    apiKey:
      'Desafortunadamente, la clave API excede la longitud permitida. Intente cambiar el nombre de usuario y recargar la página o comuníquese con su gerente para obtener ayuda.'
  },

  tooltips: {
    name: 'Nombre: {name}',
    overdraft: 'Sobregiro: {overdraft}',
    reseller: 'Revendedor: {reseller}',
    manager: 'Gerente: {manager}',

    add: {
      funds: 'Agregar fondos'
    },

    generate: {
      document: 'Descargar documento'
    },

    copy: {
      apiKey: 'Copiar clave API',

      referral: {
        code: 'Copiar código de referencia',
        link: 'Copiar enlace de referencia'
      }
    }
  },

  buttons: {
    generate: {
      document: 'Descargar documento'
    },
    account: 'Perfil',
    reseller: 'Revendedor',
    me: 'Usuario',
    false: 'False'
  },

  hints: {
    title: 'Configuración avanzada'
  },

  snackbars: {
    created: 'Usuario creado con éxito.',
    updated: 'Usuario actualizado con éxito.',
    removed: 'Usuario eliminado con éxito.',
    linkCopied: 'Enlace copiado con éxito.',
    refCopied: 'Código de referencia copiado con éxito.',
    copy: {
      apiKey: 'Clave API copiada con éxito.'
    }
  }
}
