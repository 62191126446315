import { escape } from 'lodash'

import { generateServices, services } from '@/utils'

import { userTariffsInputFilter } from '@/components/services/tariffs/utils'

import render from './render'

export default {
  name: 'Template',

  mixins: [
    generateServices({
      name: services.users,

      inputFilter(result) {
        if (!Array.isArray(result.Tariffs)) {
          result.Tariffs = []
        }

        result.Tariffs = result.Tariffs
          .map(item => userTariffsInputFilter.call(this, item))
          .sort((a, b) => a.priority - b.priority)

        return result
      },

      outputFilter(result) {
        return Object.keys(result).reduce((res, key) => {
          if (!key[0].match(/[A-Z]/)) {
            res[key] = result[key]
          }

          return res
        }, {})
      },

      find: false,
      create: false,
      remove: false,
      get: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      },
      update: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      }
    })
  ],

  computed: {
    username() {
      const data = this.restData[services.users].get.data

      return data.username
    },
    name() {
      const data = this.restData[services.users].get.data
      const { firstName, lastName } = data.data || {}

      return [ firstName, lastName ].filter(Boolean).map(escape).join(' ')
    },
    verified() {
      const data = this.restData[services.users].get.data

      return data.$verified
    }
  },

  mounted() {
    this.rest[services.users].get(this.account.id)
  },

  render
}
