export default {
  title: 'Palabra clave',

  one: 'Palabra clave',
  many: 'Palabras clave',

  titles: {
    create: 'Crear palabra clave',
    matches: 'Buscar coincidencias'
  },

  labels: {
    title: 'Título',
    value: 'Valor',
    text: 'Texto',
    coincidences: 'Lista de coincidencias:',
    create: 'Crear palabra clave',
    remove: 'Confirmación de eliminación',
    update: 'Editar palabra clave'
  },

  dialogs: {
    new: 'Nueva palabra clave'
  },

  hints: {
    notFound: 'Palabra clave no encontrada'
  },

  contents: {
    confirm: {
      remove: '¿Confirmar eliminación de {title}?'
    }
  },

  tooltips: {
    isActive: 'Activar o desactivar la palabra clave.',
    favorites: 'Agregar palabra clave a favoritos'
  },

  snackbars: {
    created: 'Palabra clave creada con éxito.',
    updated: 'Palabra clave actualizada con éxito.',
    removed: 'Palabra clave eliminada con éxito.'
  }
}
