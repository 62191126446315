import { buttonModes, colors } from '@/utils'

import button from '@/components/button'
import { className, ruleTags } from '@/components/misc/rules/utils'

function renderComponent(h) {
  return h(
    this.$component,
    {
      props: this.$props,
      on: {
        input: event => {
          this.eventHandler(event)
        }
      }
    }
  )
}
function renderAddButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.add'),
        mode: buttonModes.flat,
        color: colors.primary,
        disabled: !this.pattern
      },
      on: {
        click: () => {
          this.proxy.push(this.pattern)
          this.pattern = undefined
        }
      }
    }
  )
}
function renderSection(h) {
  return h(
    'div',
    {
      class: `${className}-dialog__content-section--${this.tag}`
    },
    [
      renderComponent.call(this, h),
      renderAddButton.call(this, h)
    ]
  )
}

export default function(h) {
  switch (this.tag) {
    case ruleTags.group: return renderSection.call(this, h)
    default: return renderComponent.call(this, h)
  }
}
