import services from './services'

export default {
  titles: {
    export: 'Exportar',
    filter: 'Filtrar',
    rename: 'Renombrar',
    data: 'Datos',
    warning: 'Advertencia',
    copy: 'Copiar',
    title: 'Título',
    loading: 'Cargando...',
    country: 'País',
    conditions: 'Condiciones',
    lastVisited: 'Última visita',
    all: 'Todos',
    notifications: 'Notificaciones',

    confirm: {
      title: 'Confirmar',
      remove: 'Confirmar eliminación',
      confirm: 'Confirmar'
    },

    file: {
      edit: 'Editar archivo'
    },

    online: {
      messages: 'Mensajes'
    },

    text: {
      highlight: 'Resaltar texto'
    }
  },

  subheader: {
    general: 'General',
    admin: 'Administrador'
  },

  labels: {
    id: 'ID',
    title: 'Título',
    filter: 'Filtro',
    search: 'Buscar',
    owner: 'Propietario',
    type: 'Tipo',
    turnOff: 'Apagar',
    turnOn: 'Encender',
    countries: 'Países',
    operators: 'Operadores',
    contactList: 'Lista de contactos',
    format: 'Formato',
    createdAt: 'Creado el',
    updatedAt: 'Actualizado el',
    expiredAt: 'Expirado el',
    limit: 'Límite',
    symbols: 'Símbolos'
  },

  contents: {
    confirm: {
      remove: '¿Estás seguro de que quieres eliminar?',
      confirm: 'Está a punto de realizar una acción irreversible.<br>Será imposible restaurar los datos originales después de esto.<br><strong class="mt-2">¿Está seguro de que desea continuar?<strong>'
    },

    text: {
      highlight: 'El resultado de la comparación lingüística se mostrará aquí. Los caracteres cirílicos y latinos se resaltarán en colores según el esquema especificado.'
    }
  },

  filters: {
    isActive: {
      title: 'Filtro no activo',
      show: 'Mostrar no activos',
      hide: 'Ocultar no activos'
    },
    lastVisited: {
      show: 'Mostrar última visita',
      hide: 'Ocultar última visita'
    },
    filter: {
      show: 'Mostrar filtro',
      hide: 'Ocultar filtro'
    }
  },

  chips: {
    more: 'Y {title} más'
  },

  hints: {
    platinum: 'Cuenta Platinum',
    unsavedData: 'Tienes datos no guardados en esta pestaña. Haz clic en el botón de actualización para guardarlos.',
    notFound: 'No encontrado',
    noDescription: 'Sin descripción',

    grafana: {
      empty: 'Los datos para el gráfico no están disponibles.'
    }
  },

  tooltips: {
    copy: 'Copiar',
    copyLinkForSlack: 'Copiar enlace a esta tarjeta formateado para Slack',
    favorites: 'Agregar a favoritos',
    sensitivity: 'Mostrar u ocultar datos confidenciales',

    getReport: {
      me: 'Los informes para un período de más de 31 días solo están disponibles para descargar en formato CSV.',
      reseller: 'Los informes para un período de más de 93 días solo están disponibles para descargar en formato CSV.'
    }
  },

  views: {
    table: 'Ver como tabla',
    cards: 'Ver como tarjetas',
    panels: 'Ver como paneles'
  },

  sizes: {
    small: 'Pequeño',
    medium: 'Mediano',
    large: 'Grande'
  },

  snackbars: {
    removed: 'Eliminado con éxito',
    tokenCopied: 'Token copiado',
    copied: 'Copiado'
  },

  errors: {
    hex: 'El valor del color debe estar en formato HEX.'
  },

  expand: {
    all: 'Expandir todo'
  },

  collapse: {
    all: 'Colapsar todo'
  },

  tabs: {
    all: 'Todos',
    format: 'Formato',
    source: 'Fuente',
    matches: 'Coincidencias',
    checked: 'Revisado',
    visited: 'Visitado',
    favorites: 'Favoritos',
    grafana: 'Grafana',

    ...services
  },

  has: {
    contract: 'Tiene contrato'
  },

  alphabets: {
    latin: 'Latino',
    cyrillic: 'Cirílico',
    other: 'Otro'
  },

  removed: '< ELIMINADO >'
}
