import { colors, components } from '@/utils'

import svgIcon from '@/components/icon/svg'
import tag from '@/components/tag'

function renderIcon(h) {
  return h(
    svgIcon,
    {
      class: `${components.empty}__icon`,
      props: {
        value: 'search-close',
        size: this.size,
        color: colors.grey
      }
    }
  )
}

function renderTitle(h) {
  if (this.title) {
    return h(
      tag,
      {
        props: {
          label: this.title,
          color: colors.grey
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        [`${components.empty}`]: true,
        [`${components.empty}--padless`]: this.padless
      }
    },
    [
      renderIcon.call(this, h),
      renderTitle.call(this, h)
    ]
  )
}
