import { get } from 'lodash'

import textCopy from '@/components/text/copy'
import textOverflow from '@/components/text/overflow'

import pkg from '../../../../package.json'

export default function(h) {
  return h(
    'div',
    {
      class: 'copyright'
    },
    [
      h(
        textCopy,
        {
          class: 'link link--passive',
          props: { value: `v${pkg.version}` }
        }
      ),
      h(
        textOverflow,
        {
          props: {
            value: [ '©', get(this.globalSettings, 'frontend.title'), new Date().getFullYear() ].join(' ')
          }
        }
      )
    ]
  )
}
