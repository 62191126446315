import { buttonModes, components, isEmpty, popperPlacements, services } from '@/utils'

import filterDate from '@/components/filter/date'
import actions from '@/components/services/sendingsModeration/actions'

function renderDateFilter(h) {
  return h(
    filterDate,
    {
      props: {
        value: this.proxy.range,
        min: new Date(2023, 4, 18).getTime(),
        presets: true,
        preset: this.preset,
        showDates: true
      },
      on: {
        input: event => {
          this.proxy.range = event
          this.$emit('preset', undefined)
        },
        preset: event => {
          this.$emit('preset', event)
        }
      }
    }
  )
}

function renderPattern(h) {
  return h(
    components.menu,
    {
      props: {
        value: this.showMenu,
        rounded: true,
        closeOnContentClick: false,
        placement: popperPlacements['bottom-start'],
        width: '100%',
        maxWidth: 280,
        offsetDistance: -44,
        disabled: !this.proxy.OwnerId
      },
      on: {
        input: event => {
          this.showMenu = event
        }
      }
    },
    [
      h(
        components['text-field'],
        {
          props: {
            value: this.pattern,
            label: this.getTranslate(`${services.sendingsModeration}.labels.pattern`),
            mode: buttonModes.outline,
            dense: true,
            rounded: true,
            details: false,
            clearable: true,
            disabled: !this.proxy.OwnerId
          },
          slot: 'activator',
          on: {
            input: event => {
              if (isEmpty(event)) {
                this.proxy.pattern = event
              }
            }
          }
        }
      ),
      h(
        components.textarea,
        {
          props: {
            value: this.proxy.pattern,
            label: this.getTranslate(`${services.sendingsModeration}.labels.pattern`),
            mode: buttonModes.outline,
            rows: 8,
            grow: true,
            rounded: true,
            details: false,
            clearable: true,
            disabled: !this.proxy.OwnerId
          },
          ref: 'pattern',
          on: {
            input: event => {
              this.proxy.pattern = event
            }
          }
        }
      )
    ]
  )
}

function renderSender(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy.sender,
        label: this.getTranslate(`${services.sendingsModeration}.labels.sender`),
        service: services.sendernames,
        itemTitle: 'name',
        itemValue: 'name',
        mode: buttonModes.outline,
        details: false,
        dense: true,
        rounded: true,
        clearable: true,
        combobox: true,
        disabled: !this.proxy.pattern
      },
      on: {
        input: event => {
          this.proxy.sender = event
        }
      }
    }
  )
}

function renderActions(h) {
  return h(
    actions,
    {
      props: {
        filter: this.proxy,
        massive: true
      },
      on: {
        clear: () => {
          this.proxy.pattern = undefined
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.sendingsModeration}-filter`
    },
    [
      renderDateFilter.call(this, h),
      renderPattern.call(this, h),
      renderSender.call(this, h),
      renderActions.call(this, h)
    ]
  )
}
