import { generateServices, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'Tokens',

  mixins: [
    generateServices({
      name: services.tokens,

      find: {
        defaultPagination: {
          limit: 8
        },
        defaultFilter: {
          OwnerId: undefined
        },
        alwaysCreateFromWebSocket: true,
        alwaysUpdateFromWebSocket: true,
        alwaysRemoveFromWebSocket: true
      },

      get: false,
      update: false,

      remove: {
        redirect: false
      }
    })
  ],

  props: {
    OwnerId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      showCreateDialog: false,
      showAfterCreateDialog: false,

      loading: false,
      token: undefined
    }
  },

  async mounted() {
    this.restData[services.tokens].find.filter.OwnerId = this.OwnerId
    this.restData[services.tokens].create.data.OwnerId = this.OwnerId
    await this.rest[services.tokens].find()
  },

  methods: {
    async create() {
      try {
        this.loading = true
        const response = await this.rest[services.tokens].create(this.restData[services.tokens].create.data)
        if (response) {
          this.showCreateDialog = false
          this.token = response.token
          this.$nextTick(() => this.showAfterCreateDialog = true)
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
