import { colors, icons, services } from '@/utils'

import sticker from '@/components/misc/sticker'
import date from '@/components/templates/service/item/date'
import owner from '@/components/templates/service/item/owner'

function renderTitle(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        label: this.getTranslate(`${services.companies}.labels.title`),
        service: services.companies,
        icon: icons.business_center,
        color: colors.grey
      }
    }
  )
}
function renderVat(h) {
  return h(
    sticker,
    {
      props: {
        value: this.getTranslate(`${services.companies}.vat.${this.value.vat}`),
        label: this.getTranslate(`${services.companies}.labels.vat`)
      }
    }
  )
}
function renderOwner(h) {
  if (this.viewport.breakpoint.xs) {
    return
  }

  return h(
    owner,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderDetails(h) {
  return h(
    'div',
    {
      class: `${services.companies}-panel__details`
    },
    [
      renderTitle.call(this, h),
      renderVat.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderCreatedAt(h) {
  if (this.viewport.breakpoint.xs) {
    return
  }

  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `service-template-item ${services.companies}-panel`
    },
    [
      renderDetails.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
