export default {
  title: 'Reglas',

  one: 'Regla',
  many: 'Reglas',

  titles: {
    create: 'Crear regla de procesamiento de mensajes'
  },

  labels: {
    title: 'Título',
    conditions: 'Condiciones',
    actions: 'Acciones',
    search: 'Buscar',
    text: 'Texto',
    sender: 'Nombre del remitente',
    moderation: 'Moderación',
    type: 'Tipo'
  },

  dialogs: {
    new: 'Nueva regla de procesamiento de mensajes'
  },

  hints: {
    notFound: 'No se encontraron reglas de procesamiento de mensajes',
    texts: 'Se seleccionará aleatoriamente uno de los textos especificados aquí.',
    fallbacks: 'Se respetará el orden de reenvío.'
  },

  contents: {
    confirm: {
      remove: '¿Confirmar eliminación de {title}?'
    }
  },

  tooltips: {
    isActive: 'Activar o desactivar la regla de procesamiento de mensajes.',
    favorites: 'Agregar la regla de procesamiento de mensajes a favoritos',
    active: 'Activo',
    notActive: 'Inactivo'
  },

  subtitles: {
    conditions: 'Condiciones',
    actions: 'Acciones'
  },

  buttons: {
    texts: 'Textos',
    fallbacks: 'Reenvíos'
  },

  snackbars: {
    created: 'Regla de procesamiento de mensajes creada con éxito.',
    updated: 'Regla de procesamiento de mensajes actualizada con éxito.',
    removed: 'Regla de procesamiento de mensajes eliminada con éxito.'
  }
}
