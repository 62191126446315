import { cloneDeep } from 'lodash'

import { attach, isArrayNotEmpty, serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/routingGroups/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      inputFilter(result) {
        if (isArrayNotEmpty(result.RoutingRules)) {
          result._RoutingRules = cloneDeep(result.RoutingRules)
          result.RoutingRules = result.RoutingRules.map(({ id, priority }) => {
            return {
              id,
              priority,
              $attach: true
            }
          }).sort((a, b) => (a.priority || 0) - (b.priority || 0))
        } else {
          result.RoutingRules = []
        }

        return result
      },
      outputFilter(result) {
        if (isArrayNotEmpty(result.RoutingRules)) {
          attach(result, 'RoutingRules')
          result.RoutingRules = result.RoutingRules.map(({ id, $attach }, index) => {
            return {
              id,
              priority: index,
              $attach
            }
          })
        } else {
          result.RoutingRules = undefined
        }

        return result
      },

      find: {
        defaultFilter: {
          type: undefined,
          isActive: true
        }
      },

      width: 500
    })
  ]
}
