import { cloneDeep } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Fallbacks',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    defaultValue: {
      type: Object,
      default: () => ({})
    },

    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },

    flat: Boolean,
    outline: Boolean,
    rounded: Boolean
  },

  methods: {
    add() {
      this.proxy.fallbacks.push(cloneDeep(this.defaultValue))
    },
    remove(index) {
      this.proxy.fallbacks.splice(index, 1)
    },

    scrollToPanel(index) {
      this.$nextTick(() => {
        const panel = this.$refs[`fallback-${index}`]
        if (panel) {
          panel.toggle()
          const fallbackElement = document.getElementById(`fallback-${index}`)
          if (fallbackElement) {
            fallbackElement.scrollIntoView({
              block: 'start',
              behavior: 'smooth'
            })
          }
        }
      })
    },

    removeFallback(index) {
      this.remove(index)
      this.scrollToPanel(index - 1)
    }
  },

  render
}
