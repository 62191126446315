import { permissionPresets, services } from '@/utils'

import { hasPermission } from '@/components/services/invoices/utils'

import render from './render'

export default {
  name: 'Buttons',

  mixins: [ hasPermission ],

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    view: {
      type: String,
      default: 'item',
      validator: value => {
        return ~[ 'item', 'form' ].indexOf(value)
      }
    }
  },

  computed: {
    canEdit() {
      return this.checkPermissions(`advanced.${services.invoices}.update`, permissionPresets.resellerUp)
    },
    canRemove() {
      return this.checkPermissions(`advanced.${services.invoices}.remove`, permissionPresets.resellerUp)
    }
  },

  render
}
