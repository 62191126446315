export default {
  title: 'Configuraciones del usuario',

  titles: {
    sendings: 'Mensajes',
    billings: 'Operaciones financieras',
    referral: 'Programa de referidos',
    websocket: 'Websocket',
    smpp: 'SMPP',
    webhooks: 'Webhooks',
    notifications: 'Notificaciones',
    statuses: 'Mapeo de estados',
    integrations: 'Integraciones'
  },

  subtitles: {
    sendings: {
      duplicates: 'Duplicados',
      rateLimit: 'Límite de tasa de mensajes'
    },

    billings: {
      autoAccrual: 'Recarga automática'
    },

    referral: {
      billings: {
        processedChildOperations: 'Operaciones financieras'
      }
    },

    webhooks: {
      general: 'General',
      successOn: 'Condiciones de éxito'
    },

    integrations: {
      pyrus: {
        credentials: 'Datos de conexión a Pyrus'
      },
      yclients: {
        applicationCredentials: 'Datos de conexión a YClients'
      }
    },

    notifications: {
      events: {
        balance: {
          limit: 'Límite de balance'
        }
      }
    }
  },

  dialogs: {
    titles: {
      webhooks: {
        onStatusChange: 'Al cambiar el estado',
        onIncomingMessage: 'Entrantes'
      }
    }
  },

  labels: {
    sendings: {
      allowDuplicates: 'Permitir duplicados de mensajes',
      rateLimit: {
        maxAmount: 'Cantidad de mensajes',
        timeout: 'Intervalo de tiempo'
      },
      duplicates: {
        hashFields: 'Campos para verificar duplicados',
        timeout: 'Tiempo de bloqueo de duplicados'
      },
      tilda: {
        sendFromForms: 'Permitir envío de mensajes desde formularios Tilda'
      }
    },

    billings: {
      autoAccrual: {
        limit: 'Límite',
        requiredBalance: 'Balance requerido'
      },
      overdraft: 'Sobregiro',
      processChildrenOperations: 'Procesar operaciones financieras de usuarios secundarios como propias',
      billOnErrors: 'Cobrar SMS en caso de error',
      currency: 'Moneda'
    },

    referral: {
      billings: {
        processedChildOperations: {
          source: 'Fuente',
          percent: 'Porcentaje',
          limitMin: 'Límite mínimo',
          limitMax: 'Límite máximo'
        }
      }
    },

    smpp: {
      enabled: 'SMPP habilitado',
      rudeMode: 'Modo rudo SMPP',
      defaultMessageType: 'Tipo de mensaje predeterminado para SMPP',
      ipWhitelist: 'Lista blanca de IPs',
      ipAddresses: 'Direcciones IP',
      confirm: 'Confirmar guardado'
    },

    webhooks: {
      title: 'Envíos',
      url: 'URL de la solicitud',
      method: 'Método de la solicitud',
      headers: 'Encabezados de la solicitud',
      body: 'Cuerpo de la solicitud',
      maxConnections: 'Máximo número de conexiones',
      successOn: {
        status: 'Verificación de estado (regexp)',
        body: 'Verificación del cuerpo de la respuesta (regexp)'
      }
    },

    notifications: {
      extraRecipients: 'Destinatarios adicionales de notificaciones',
      type: 'Método de notificación',
      events: {
        balance: {
          limit: {
            host: 'Host',
            port: 'Puerto',
            from: 'De',
            login: 'Nombre de usuario',
            password: 'Contraseña',
            limit: 'Límite',

            include: {
              overdraft: 'Incluir sobregiro',
              invoice: 'Adjuntar factura por {amount}'
            },

            notInclude: {
              overdraft: 'No incluir sobregiro',
              invoice: 'No adjuntar factura'
            },

            invoice: {
              amount: 'Monto de la factura'
            }
          }
        },
        providers: {
          health: {
            recipients: 'Destinatarios de notificaciones sobre el estado de los proveedores'
          }
        }
      }
    },

    websocket: {
      channels: 'Canales'
    },

    integrations: {
      pyrus: {
        credentials: {
          login: 'Nombre de usuario',
          security_key: 'Clave de seguridad'
        }
      },
      yclients: {
        applicationCredentials: {
          id: 'ID de la aplicación',
          token: 'Token de la aplicación'
        }
      }
    }
  },

  contents: {
    webhooks: {
      onStatusChange: 'Al cambiar el estado',
      onIncomingMessage: 'Entrantes',
      statuses: 'Estados'
    }
  },

  hints: {
    billings: {
      overdraft: {
        negative: '<strong>¡ATENCIÓN!<br>El nuevo valor del sobregiro no permitirá al usuario enviar mensajes.</strong>'
      }
    },

    webhooks: {
      onStatusChange: 'Configuraciones del webhook para el evento de cambio de estado del mensaje',
      onIncomingMessage: 'Configuraciones del webhook para el evento de mensaje entrante'
    },

    referral: {
      billings: {
        processedChildOperations: {
          limits: 'Límites - monto mínimo y máximo de recompensa garantizada en cada operación de referido.'
        }
      }
    },

    notifications: {
      events: {
        balance: {
          limit: {
            balance: 'Notificaremos cuando el balance sea {amount}',
            onLimit: 'Al alcanzar el balance',
            onNullBalance: 'Notificaremos cuando el balance sea cero',
            noLimit: '<límite no especificado>',
            notifyBy: 'notificar por',
            noNotifications: '<no notificar>',
            overdraft: ' + sobregiro del usuario',
            extraRecipients: 'Notificaciones sobre el estado del balance de todos los usuarios del sistema',
            invoice: 'Adjuntar factura por {amount}'
          }
        }
      }
    },

    smpp: {
      ipAddresses: 'Solo direcciones IP válidas, separadas por "coma", "salto de línea".'
    },

    warning: 'Cambiar configuraciones no requiere actualizar al usuario.'
  },

  warnings: {
    smpp: {
      ipAddresses: 'Algunas de las direcciones IP especificadas no pasaron la verificación de validez.<br>Si necesita guardar estas direcciones, asegúrese de que sean correctas, luego ingréselas nuevamente en el campo "Direcciones IP" y haga clic en "Guardar".<br>Si las direcciones siguen siendo incorrectas, esta ventana no se cerrará y la lista a continuación se actualizará con las nuevas direcciones incorrectas.'
    }
  },

  items: {
    websocket: {
      channels: {
        dbUpdates: 'Actualización de la base de datos',
        everything: 'Todo',
        health: 'Salud de la plataforma',
        sendingStatusUpdates: 'Actualización del estado de los envíos',
        providersStatesUpdates: 'Actualización del estado de los proveedores'
      }
    }
  },

  snackbars: {
    updated: 'Configuración actualizada exitosamente.'
  },

  buttons: {
    notifications: {
      events: {
        balance: {
          limit: {
            invoice: {
              attach: 'Adjuntar factura',
              detach: 'Desadjuntar factura'
            }
          }
        }
      }
    }
  }
}
