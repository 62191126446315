export default {
  preset: 'Preajuste',
  presets: 'Preajustes',
  clear: 'Limpiar filtro',

  isActive: {
    true: 'Solo activos',
    false: 'Solo inactivos'
  },

  date: {
    title: 'Fecha',
    label: 'Filtrar por fecha y hora',
    from: 'Fecha desde',
    by: 'Fecha hasta',
    empty: 'Seleccione una fecha',

    titles: {
      createdAt: 'Seleccione la fecha de creación',
      expiredAt: 'Seleccione la fecha de vencimiento',
      startsAt: 'Seleccione la fecha de inicio',
      endsAt: 'Seleccione la fecha de finalización',
      range: 'Seleccione un rango de fechas'
    },

    labels: {
      createdAt: 'Rango de fechas de creación',
      startsAt: 'Rango de fechas de inicio',

      expiredAt: {
        range: {
          true: 'Rango de fechas de vencimiento',
          false: 'Fecha de vencimiento'
        }
      }
    },

    presets: {
      minutes: {
        5: '5 minutos',
        15: '15 minutos',
        30: '30 minutos'
      },
      hours: {
        1: '1 hora',
        3: '3 horas',
        6: '6 horas'
      },
      last: {
        week: 'Semana pasada',
        month: 'Mes pasado'
      },
      current: {
        week: 'Semana actual',
        month: 'Mes actual'
      },
      yesterday: 'Ayer',
      today: 'Hoy'
    }
  },

  time: {
    gt: 'Inicio del rango de tiempo',
    lt: 'Fin del rango de tiempo',
    from: 'Hora desde',
    by: 'Hora hasta',
    labels: 'Etiquetas',
    hints: 'Sugerencias',

    current: {
      time: 'Hora actual',
      date: 'Fecha actual'
    },

    tooltips: {
      current: {
        time: 'Establecer hora actual'
      }
    }
  }
}
