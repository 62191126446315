export const methods = {
  yooMoney: 'YooMoney',
  tinkoff: 'Тинькофф',
  mandarin: 'Мандарин',
  stripe: 'Stripe',
  cards: 'Оплата картой',
  recurrent: 'Автоплатёж',
  invoice: 'Счёт'
}

export default {
  title: 'Платежи',

  titles: {
    individual: 'Физические лица',
    legal: 'Юридические лица',
    threshold: 'По порогу',
    frequency: 'По времени',
    billings: 'Финансовые операции',
    paymentLogs: 'Журнал запросов',

    step: {
      first: 'Способ оплаты',
      individual: 'Способ оплаты',
      legal: 'Выставление счета',
      cards: 'Оплата картой',
      yooMoney: 'YooMoney',
      recurrent: 'Автоплатёж'
    }
  },

  labels: {
    amount: 'Сумма',
    method: 'Метод',
    frequency: '',
    status: 'Статус',
    paymentType: 'Тип платежа',
    sum: 'Сумма',
    owner: 'Владелец',
    acknowledged: 'Я прочитал(а) информацию, понял(а), что без отправки письма мой платеж не будет зачислен, и обязуюсь прикрепить чек к письму.',

    methods: {
      tinkoff: {
        TerminalKey: 'Ключ терминала',
        Password: 'Пароль',
        ekamToken: 'EKAM токен'
      },

      mandarin: {
        merchantId: 'ID продавца',
        secret: 'Секретный ключ',
        ekamToken: 'EKAM токен',
        includeFiscalInformation: 'Включить фискальную информацию'
      },

      stripe: {
        apikey: 'API ключ',
        usd_price_id: 'ID цены для USD',
        eur_price_id: 'ID цены для EUR'
      }
    }
  },

  recurrentTypes: {
    once: 'Единоразово',
    daily: 'Ежедневно',
    weekly: 'Еженедельно',
    monthly: 'Ежемесячно'
  },

  contents: {
    legal: 'Для выставления счета просьба написать на <a href="mailto:support@sigmasms.ru">support@sigmasms.ru</a>.',

    payment: {
      result: {
        success: 'Платёж успешно проведён.<br>Денежные средства скоро будут зачислены на ваш счёт.',
        fail: 'Не удалось завершить платёж.<br>Пожалуйста, обратитесь к менеджеру для решения этой проблемы.'
      }
    }
  },

  hints: {
    delay: 'Баланс зачисляется в течение 5 минут',
    commission: 'Комиссия банка:',
    commissions: 'комиссии',
    yooMoney: 'Также вы можете оплатить с помощью платёжной системы YooMoney, с меньшей комиссией.',
    noOneFound: 'Не найдено ни одного платежа',
    nextPaymentAt: 'Следующий платёж',
    addPayment: 'Добавить платёж',
    offer: 'Я принимаю условия договора оферты.',
    goToPayment: 'Перейти к оплате',
    notFound: 'Не найдено ни одного платежа',
    individual: 'Для пополнения баланса личного кабинета кредитной картой свяжитесь с нами любым удобным способом.',
    owner: 'Владелец',
    due: 'Fee: {due}%',
    sum: 'Комиссия: 3%',
    recurrent: 'Повторяющийся автоматический платёж'
  },

  types: {
    individual: 'Физическое лицо',
    legal: 'Юридическое лицо'
  },

  methods,

  buttons: {
    add: {
      funds: 'Пополнить баланс',
      payment: 'Настроить автоплатеж'
    },
    request: {
      payment: 'Запросить платёж'
    },
    billings: 'История финансовых операций',
    paymentLogs: 'Журнал запросов',
    refund: 'Возврат платежа'
  },

  links: {
    attachInvoice: 'Приложить счет',
    attachInvoiceTo: 'Приложить счет на {title}'
  },

  statuses: {
    init: 'Инициализирован',
    completed: 'Проведён',
    refundRequested: 'Запрошен возврат',
    refunded: 'Возмещён',
    partialRefunded: 'Частично возмещён',
    error: 'Ошибка'
  },

  tabs: {
    payments: 'Платежи',
    recurrentPayments: 'Автоплатежи'
  },

  infos: {
    method: 'В данный момент способы оплаты не настроены. Пожалуйста, обратитесь в службу поддержки.'
  },

  warnings: {
    yooMoney: `
<center>⚠ <strong>ВАЖНАЯ ИНФОРМАЦИЯ!</strong> ⚠</center>
<br>
<center><strong>ЕСЛИ ВЫ НЕ СООБЩИТЕ НАМ О ПЛАТЕЖЕ, ДЕНЬГИ НЕ БУДУТ ЗАЧИСЛЕНЫ!</strong></center>
<br>
Чтобы мы смогли зачислить ваш платеж, вам необходимо <strong>написать нам на почту</strong>:
<div><a href="mailto:a1@sigmasms.ru?subject=Платеж%20через%20YooMoney%20–%20идентификация&body=Здравствуйте.%0A%0AЛогин: {username}%0AСумма: {sum}%0A%0AПрикрепляю чек во вложении.">a1@sigmasms.ru</a></div>
<div><strong>Просто нажмите на ссылку — письмо уже заполнено, вам останется только отправить! Не забудьте прикрепить чек об оплате.</strong></div>
<br>
Если же будете заполнять письмо сами, то в теле письма укажите:
<ul>
<li><strong>Ваш логин</strong> (иначе мы не знаем, кому зачислить деньги)</li>
<li><strong>Сумму платежа</strong> (чтобы найти его в системе)</li>
<li><strong>Прикрепите чек об оплате во вложении</strong> (чтобы подтвердить транзакцию)</li>
</ul>
<p><strong>Без этих данных мы не сможем зачислить платеж, и деньги останутся неидентифицированными!</strong></p>
<strong>Примерная комиссия сервиса — 3%.</strong>
<div>&#x2197; <a href="https://yoomoney.ru/page?id=536332" target="_blank">Подробнее о комиссиях YooMoney</a></div>
`,

    no: {
      methods: 'В данный момент не настроены способы оплаты. Пожалуйста, обратитесь в службу поддержки.'
    }
  }
}
