import Vue from 'vue'

import numeral from 'numeral'

numeral.register('locale', 'ru', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: ' тыс.',
    million: ' млн.',
    billion: ' млрд.',
    trillion: ' трлн.'
  },
  ordinal: function(/* number */) {
    return ''
  },
  currency: {
    symbol: '₽'
  }
})

Vue.$n = numeral
