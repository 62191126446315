import { components } from '@/utils'

function renderCheckbox(h) {
  return h(
    'div',
    {
      class: `${components.checkbox}__background`
    },
    [
      h(
        'svg',
        {
          attrs: {
            xmlns: 'http://www.w3.org/2000/svg',
            viewBox: '0 0 24 24'
          },
          class: `${components.checkbox}__checkmark`
        },
        [
          h(
            'path',
            {
              attrs: { d: 'M1.73 12.91 8.1 19.28 22.79 4.59' },
              class: `${components.checkbox}__checkmark__path`
            }
          )
        ]
      )
    ]
  )
}
function renderHolder(h) {
  return h(
    'div',
    {
      class: `${components.checkbox}__holder`
    },
    [ renderCheckbox.call(this, h) ]
  )
}

function renderLabel(h) {
  if (this.label) {
    return h(
      'label',
      {
        class: `${components.checkbox}__label`
      },
      [ this.label ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      attrs: this.$_attrs,
      class: this.$_class,
      directives: this.$_directives,
      on: this.$_on
    },
    [
      renderHolder.call(this, h),
      renderLabel.call(this, h)
    ]
  )
}
