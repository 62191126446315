import { get } from 'lodash'

import { colors, dateFields, getLocaleDateString, getLocaleTimeString, icons } from '@/utils'

import render from './render'

export default {
  name: 'Date',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    field: {
      type: String,
      default: dateFields.createdAt,
      validator: value => {
        return Object.values(dateFields).includes(value)
      }
    },

    compact: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    $props() {
      const value = get(this.value, this.field)
      const label = this.getTranslate(`commons.labels.${this.field}`)
      const result = {
        value: [ getLocaleDateString(value), getLocaleTimeString(value) ].join(' '),
        label,
        icon: icons.date_range,
        color: colors.grey,
        minWidth: 170
      }

      if (this.compact) {
        result.value = getLocaleTimeString(value)
        result.label = getLocaleDateString(value),
        result.minWidth = 105,
        result.width = 105,
        result.tooltip = [
          label,
          getLocaleTimeString(value, { milliseconds: true }),
          getLocaleDateString(value)
        ].join(' ')
      }

      return result
    }
  },

  render
}
