import { buttonModes, colors, components, icons, inputModes, services } from '@/utils'

import button from '@/components/button'
import selectorOwner from '@/components/selector/owner'

function renderFields(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    this.proxy.map((item, index) => {
      return h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: { 'grid-template-columns': 'repeat(2, 1fr) auto' }
        },
        [
          h(
            selectorOwner,
            {
              props: {
                value: item.key,
                label: this.getTranslate(`${services.providers}.labels.d360.owner`),
                mode: inputModes.outline,
                dense: true
              },
              on: {
                input: event => {
                  item.key = event
                }
              }
            }
          ),
          h(
            components['text-field'],
            {
              props: {
                value: item.value,
                label: this.getTranslate(`${services.providers}.labels.d360.token`),
                mode: inputModes.outline,
                dense: true,
                rounded: true,
                details: false,
                clearable: true
              },
              on: {
                input: event => {
                  item.value = event
                }
              }
            }
          ),
          h(
            button,
            {
              props: {
                icon: icons.remove,
                mode: buttonModes.flat,
                color: colors.error
              },
              on: {
                click: () => {
                  this.removeField(index)
                }
              }
            }
          )
        ]
      )
    })
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        button,
        {
          props: {
            icon: icons.add,
            mode: buttonModes.flat,
            color: colors.primary
          },
          on: {
            click: () => {
              this.addField()
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderFields.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
