import { components, popperPlacements } from '@/utils'

import textOverflow from '@/components/text/overflow'

function renderLink(h) {
  return h(
    textOverflow,
    {
      class: 'link',
      props: { value: this.getTranslate(`misc.locales.${this.locale}`) },
      slot: 'activator'
    }
  )
}
function renderItem(h, locale) {
  return h(
    components['list-item'],
    {
      props: {
        label: this.getTranslate(`misc.locales.${locale}`),
        dense: true,
        rounded: true
      },
      on: {
        click: () => {
          this.setLocale(locale)
        }
      }
    }
  )
}
function renderItems(h) {
  return h(
    components.list,
    {
      props: {
        dense: true,
        rounded: true
      }
    },
    [
      this.locales.map(locale => {
        return renderItem.call(this, h, locale)
      })
    ]
  )
}

export default function(h) {
  if (this.locales.length > 1) {
    return h(
      components.menu,
      {
        props: {
          rounded: true,
          offsetDistance: 8,
          placement: popperPlacements['bottom-end']
        }
      },
      [
        renderLink.call(this, h),
        renderItems.call(this, h)
      ]
    )
  }
}
