import { cloneDeep } from 'lodash'

import { EMPTY_MESSAGERULEGROUPS, EMPTY_MESSAGERULES } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices, globalErrorHandler, globalErrorProcessor, isUUID, services, states } from '@/utils'

import { outputFilter } from '@/components/services/messageRules/utils'

import render from './render'

const defaultPayload = {
  ...EMPTY_MESSAGERULEGROUPS,
  MessageRules: []
}

export default {
  name: 'CreateFull',

  mixins: [
    generateServices([
      {
        name: services.messageRuleGroups,

        find: {
          defaultFilter: {
            title: undefined
          }
        },

        get: false,
        update: false,
        remove: false
      },
      {
        name: services.messageRules,

        get: false,
        find: false,
        update: false,
        remove: false
      }
    ])
  ],

  props: {
    show: Boolean
  },

  data() {
    return {
      showMessageRuleCreateDialog: false,

      messageRulePayload: cloneDeep(EMPTY_MESSAGERULES),
      messageRuleGroupPayload: cloneDeep(defaultPayload),

      messageRuleValidation: false
    }
  },

  computed: {
    loading() {
      return this.restData[services.messageRuleGroups].create.state === states.loading
    },
    disabled() {
      return this.restData[services.messageRuleGroups].find.pagination.total > 0 || this.loading
    },
    messageRulesValidation() {
      return this.messageRuleGroupPayload.MessageRules.length && this.messageRuleGroupPayload.MessageRules.every(rule => !!rule.id)
    },
    validation() {
      return !!this.messageRuleGroupPayload.title && this.messageRulesValidation
    }
  },

  methods: {
    async create() {
      try {
        const MessageRules = await Promise.all(this.messageRuleGroupPayload.MessageRules.map(async (item, index) => {
          const result = {
            id: item.id,
            title: item.title,
            priority: index,
            $attach: true
          }

          return result
        }))

        const { id } = await this.rest[services.messageRuleGroups].create({
          title: this.messageRuleGroupPayload.title,
          MessageRules
        })
        this.$emit('input', id)
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.messageRuleGroupPayload = cloneDeep(defaultPayload)
        this.$emit('show', false)
      }
    },

    async createMessageRule() {
      try {
        const messageRule = this.messageRulePayload
        if (!messageRule.id && messageRule.title) {
          const { id } = await this.rest.messageRules.create(outputFilter(messageRule))
          messageRule.id = id
        }
        if (messageRule.id) {
          if (!this.messageRulesValidation) {
            this.messageRuleGroupPayload.MessageRules = []
          }
          this.messageRuleGroupPayload.MessageRules.push(messageRule)
          this.messageRulePayload = cloneDeep(EMPTY_MESSAGERULES)
          this.showMessageRuleCreateDialog = false
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    },

    async addMessageRuleFromSelect(id) {
      if (isUUID(id)) {
        if (!~this.messageRuleGroupPayload.MessageRules.findIndex(item => item.id === id)) {
          if (~[ states.empty, states.ready ].indexOf(this.restData.routingRules.get.state)) {
            try {
              const messageRule = await this.rest.messageRules.get(id)
              this.messageRuleGroupPayload.MessageRules.push({
                id: messageRule.id,
                title: messageRule.title
              })
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            }
          }
        }
      }
    }
  },

  render
}
