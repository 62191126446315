import Vue from 'vue'

import { cloneDeep, pick } from 'lodash'

import { generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import { cloneProvider, commonProperties, inputFilter, outputFilter } from '@/components/services/inactiveProviders/utils'
import { serviceName } from '@/components/services/providerGroups/utils'

import render from './render'

export default {
  name: 'FixButton',

  mixins: [
    generateServices([
      {
        name: serviceName,

        inputFilter,
        outputFilter,

        update: {
          params: {
            query: {
              $scope: [ 'Providers' ]
            }
          }
        },

        find: false,
        create: false,
        remove: false
      },
      {
        name: 'providers',

        find: false,
        remove: false
      }
    ])
  ],

  props: {
    provider: {
      type: Object,
      default: () => ({}),
      required: true
    },

    providerGroup: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },

  data() {
    return {
      loading: {}
    }
  },

  methods: {
    async fixProvider() {
      try {
        Vue.set(this.loading, this.provider.id, true)

        const commonPayload = pick(cloneDeep(this.provider), commonProperties)
        const newProvider = await cloneProvider(commonPayload)
        if (newProvider) {
          Vue.set(this.loading, newProvider.id, true)

          await this.rest.providers.update(this.provider.id, {
            ...commonPayload,
            isActive: false
          })

          const { id, title, type, Providers, _Providers } = cloneDeep(this.providerGroup)
          const index = Providers.findIndex(item => item.id === this.provider.id)
          Providers.splice(
            index,
            1,
            {
              id: newProvider.id,
              priority: this.provider.priority,
              $attach: true
            }
          )

          await this.rest[serviceName].update(id, {
            title,
            type,
            Providers,
            _Providers // это нужно чтобы не потерять старые данные и чтобы отработала функция attach в outputFilter
          })

          this.$emit('fix', true)

          Vue.set(this.loading, newProvider.id, false)
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        Vue.set(this.loading, this.provider.id, false)
      }
    }
  },

  render
}
