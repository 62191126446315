import { services } from '@/utils'

import squircle from '@/components/icon/squircle'
import tag from '@/components/tag'
import textOverflow from '@/components/text/overflow'

function renderIcon(h) {
  if (this.as === 'text') {
    return h(
      squircle,
      {
        props: {
          iconSVG: this.$iconSVG,
          iconSize: 18,
          color: this.$iconSVG,
          tooltip: this.getTranslate(`${services.sendings}.types.${this.value}`)
        }
      }
    )
  }
}
function renderTitle(h) {
  if (this.as === 'tag') {
    return h(
      tag,
      {
        props: {
          label: this.title,
          tooltip: this.getTranslate(`${services.sendings}.types.${this.$iconSVG}`),
          color: this.$iconSVG,
          as: this.dense ? 'select-dense' : 'select',
          cancelable: this.cancelable,
          cancel: this.cancel
        }
      }
    )
  } else {
    return h(
      textOverflow,
      {
        props: {
          value: this.title
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      ...this.attributes
    },
    [
      renderIcon.call(this, h),
      renderTitle.call(this, h)
    ]
  )
}
