import { get } from 'lodash'

import { buttonModes, colors, components, getLocaleDateString, getLocaleTimeString, inputModes, services, sizes, states } from '@/utils'

import button from '@/components/button'
import buttonRefresh from '@/components/button/refresh'
import pagination from '@/components/pagination'
import preloader from '@/components/preloader'
import tag from '@/components/tag'

function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.userComments}-header`
    },
    [
      h(
        components['text-field'],
        {
          props: {
            value: this.text,
            label: this.getTranslate(`${services.userComments}.labels.text`),
            mode: inputModes.outline,
            details: false,
            grow: true,
            dense: true,
            rounded: true,
            clearable: true
          },
          on: {
            input: event => {
              this.text = event
            },
            keypress: event => {
              if (event.keyCode === 13) {
                if (this.text) {
                  this.create()
                }
              }
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            iconSVG: 'send',
            mode: buttonModes.flat,
            color: colors.primary,
            loading: this.loading,
            disabled: this.disabled
          },
          on: {
            click: () => {
              this.create()
            }
          }
        }
      )
    ]
  )
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'preloader__holder pa-5'
    },
    [ h(preloader) ]
  )
}
function renderItemText(h, item) {
  return h(
    'div',
    {
      class: `${services.userComments}-item__text`
    },
    [ item.text ]
  )
}
function renderItemOwner(h, item) {
  const label = get(item, 'Owner.username')
  if (label) {
    return h(
      tag,
      {
        props: {
          label,
          size: sizes.tiny,
          color: colors.grey
        }
      }
    )
  }
}
function renderItemDate(h, item) {
  return h(
    tag,
    {
      props: {
        label: [ getLocaleDateString(item.createdAt), this.getTranslate('misc.in'), getLocaleTimeString(item.createdAt) ].join(' '),
        size: sizes.tiny,
        color: colors.grey
      }
    }
  )
}
function renderItemFooter(h, item) {
  return h(
    'div',
    {
      class: `${services.userComments}-item__footer`
    },
    [
      renderItemOwner.call(this, h, item),
      renderItemDate.call(this, h, item)
    ]
  )
}
function renderItem(h, item) {
  return h(
    'div',
    {
      class: `${services.userComments}-item`
    },
    [
      renderItemText.call(this, h, item),
      renderItemFooter.call(this, h, item)
    ]
  )
}
function renderItems(h) {
  return h(
    'div',
    {
      class: `${services.userComments}-items`
    },
    [ this.restData[services.userComments].find.data.map(item => renderItem.call(this, h, item)) ]
  )
}
function renderBody(h) {
  if (this.restData[services.userComments].find.pagination.total > 0) {
    return renderItems.call(this, h)
  } else {
    switch (this.restData[services.userComments].find.state) {
      case states.loading: return renderPreloader.call(this, h)
      case states.ready:
      default: return h(components.empty)
    }
  }
}

function renderPagination(h) {
  return h(
    pagination,
    {
      props: {
        selectRows: false,
        edgeArrows: false,
        value: this.restData[services.userComments].find.pagination,
        service: services.userComments
      },
      on: {
        input: event => {
          this.restData[services.userComments].find.pagination = event
        }
      }
    }
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: `${services.userComments}-footer`
    },
    [
      renderPagination.call(this, h),

      h(
        buttonRefresh,
        {
          props: {
            state: this.restData[services.userComments].find.state,
            shortClickMethod: () => this.rest[services.userComments].find({ query: { t: Date.now() } }),
            longClickMethod: () => this.rest[services.userComments].find({ query: { t: Date.now() } }, { noCache: true })
          }
        }
      )
    ]
  )
}

export default function(h) {
  if (this.checkPermissions(`advanced.${services.userComments}.get`)) {
    return h(
      'div',
      {
        class: services.userComments
      },
      [
        renderHeader.call(this, h),
        renderBody.call(this, h),
        renderFooter.call(this, h)
      ]
    )
  }
}
