export default function(h) {
  return h(
    'form',
    {
      attrs: this.$_attrs,
      style: this.$_style,
      on: this.$_on,
      ref: 'form'
    },
    [ this.$slots.default ]
  )
}
