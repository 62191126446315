import render from './render'
import { eventHandlers } from '../../../../utils/mixins'

export default {
  name: 'SettingRow',

  mixins: [ eventHandlers ],

  props: {
    title: {
      type: [ String, Number ],
      default: undefined
    },

    label: {
      type: [ String, Number ],
      default: undefined
    },

    icon: {
      type: String,
      default: undefined
    },

    iconTooltip: {
      type: String,
      default: undefined
    }
  },

  render
}
