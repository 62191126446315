export default {
  labels: {
    accent: 'Acento',
    error: 'Error',
    info: 'Información',
    primary: 'Primario',
    secondary: 'Secundario',
    success: 'Éxito',
    warning: 'Advertencia'
  },

  hints: {
    accent: 'Color de acento',
    error: 'Color de error',
    info: 'Color de información',
    primary: 'Color primario',
    secondary: 'Color secundario',
    success: 'Color de éxito',
    warning: 'Color de advertencia'
  }
}
