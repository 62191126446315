import { get } from 'lodash'

import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { proxy } from '@/utils'

import { invoicesContracts } from '@/components/services/documentTemplates/generate/utils'

import render from './render'

export default {
  name: 'Invoice',

  mixins: [
    proxy({ type: 'object' }),
    invoicesContracts(DOCUMENT_TEMPLATES_TYPES.invoice)
  ],

  props: {
    disabled: Boolean
  },

  computed: {
    validation() {
      if (!this.proxy.requisiteId) {
        return false
      }
      if (!this.proxy.companyId) {
        return false
      }

      const amount = get(this.proxy, 'data.SERVICE_PRICE')
      if (!amount) {
        return false
      }

      return true
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.setAmount()
  },

  beforeDestroy() {
    this.setAmount()
  },

  methods: {
    setAmount(value) {
      this.proxy.data.SERVICE_PRICE = value || 1000
    }
  },

  render
}

