import { colors, icons } from '../constants/enums'

export const moderationColors = {
  pending: colors.info,
  approved: colors.success,
  rejected: colors.error
}

export const moderationIcons = {
  pending: icons.schedule,
  approved: icons.done,
  rejected: icons.clear
}

export const moderationStatusIcons = {
  pending: icons.schedule,
  approved: icons.check_circle_outline,
  rejected: icons.highlight_remove
}
