import Vue from 'vue'

import { buttonModes, components, services, states } from '@/utils'

import button from '@/components/button'
import buttonRefresh from '@/components/button/refresh'
import pagination from '@/components/pagination'
import preloader from '@/components/preloader'
import item from '@/components/services/providers/sendings/item'
import detailsDialog from '@/components/services/sendings/outgoing/details/dialog'

function renderButtonRefresh(h) {
  return h(
    buttonRefresh,
    {
      props: {
        state: this.restData[services.sendings].find.state,
        shortClickMethod: () => this.rest[services.sendings].find(),
        longClickMethod: () => this.rest[services.sendings].find({}, { noCache: true })
      }
    }
  )
}
function renderPagination(h) {
  return h(
    'div',
    {
      class: `${services.providers}-${services.sendings}__pagination`
    },
    [
      h(
        pagination,
        {
          props: {
            value: this.restData[services.sendings].find.pagination,
            chunk: 1,
            edgeArrows: false,
            service: services.sendings
          },
          on: {
            input: event => {
              this.restData[services.sendings].find.pagination = event
            }
          }
        }
      ),

      renderButtonRefresh.call(this, h)
    ]
  )
}
function renderButtonToReport(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.goto.report'),
        mode: buttonModes.flat
      },
      on: {
        click: () => {
          Vue.router.push({
            name: services.sendings,
            query: { providerId: this.providerId }
          })
        }
      }
    }
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.providers}-${services.sendings}__header`
    },
    [
      renderButtonToReport.call(this, h),
      renderPagination.call(this, h)
    ]
  )
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'preloader__holder pa-5'
    },
    [ h(preloader) ]
  )
}
function renderAbsolutePreloader(h) {
  if (this.restData[services.sendings].find.state === states.loading) {
    return h(
      'div',
      {
        class: 'preloader__holder preloader__holder--absolute'
      },
      [ h(preloader) ]
    )
  }
}
function renderItem(h, value) {
  return h(
    item,
    {
      props: { value },
      key: value.id,
      on: {
        source: value => {
          this.source = value
          this.showDialog = true
        }
      }
    }
  )
}
function renderItems(h) {
  return h(
    'div',
    {
      style: { position: 'relative' }
    },
    [
      this.restData[services.sendings].find.data.map(item => {
        return renderItem.call(this, h, item)
      }),

      renderAbsolutePreloader.call(this, h)
    ]
  )
}
function renderBody(h) {
  if (this.restData[services.sendings].find.pagination.total > 0) {
    return renderItems.call(this, h)
  } else {
    switch (this.restData[services.sendings].find.state) {
      case states.loading: return renderPreloader.call(this, h)
      case states.ready:
      default: return h(components.empty)
    }
  }
}

function renderDetailsDialog(h) {
  return h(
    detailsDialog,
    {
      props: {
        value: this.source,
        show: this.showDialog
      },
      on: {
        close: () => {
          this.showDialog = false
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.providers}-${services.sendings}`
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderDetailsDialog.call(this, h)
    ]
  )
}
