import { buttonModes, colors, components, services } from '@/utils'

import button from '@/components/button'
import form from '@/components/services/tokens/form'

function renderBody(h) {
  return h(
    form,
    {
      props: {
        value: this.data,
        OwnerId: this.OwnerId
      },
      on: {
        input: event => {
          this.$emit('data', event)
        },
        validation: event => {
          this.formValidation = event
        }
      }
    }
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'faic fjcfe grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat,
            disabled: this.loading
          },
          on: {
            click: () => {
              this.proxy = false
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.create'),
            color: colors.primary,
            loading: this.loading,
            disabled: this.loading || !this.formValidation
          },
          on: {
            click: () => {
              this.$emit('create', this.payload)
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    components.dialog,
    {
      props: {
        value: this.proxy,
        title: this.getTranslate(`${services.tokens}.titles.create`),
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 400 : undefined
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    },
    [
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
