import { colors, components, moderationColors, services } from '@/utils'

import arrow from '@/components/misc/arrow'
import sendingsCreateForm from '@/components/services/sendingsCreate/form'
import tag from '@/components/tag'

const className = 'replacement'

function renderDescription(h, index) {
  let path = 'replace'
  if (index === 0) {
    path = 'original'
  } else if (index === this.value.length - 1) {
    path = 'final'
  }

  return h(
    'div',
    {
      class: `${className}-stepper__step-description`
    },
    [ this.getTranslate(`replacement.hints.replacement.${path}`) ]
  )
}

function renderFallbacks(h, fallbacks) {
  if (Array.isArray(fallbacks) && fallbacks.length) {
    return h(
      components['expansion-panels'],
      {
        class: 'mt-2',
        props: {
          outline: true,
          rounded: true
        }
      },
      fallbacks.map(fallback => {
        return h(
          components['expansion-panel'],
          {
            scopedSlots: {
              header: ({ expanded }) => {
                return h(
                  'div',
                  { class: 'default-expansion-panel-header' },
                  [
                    this.getTranslate(`${className}.labels.fallback`),
                    h(arrow, { props: { expanded } })
                  ]
                )
              },
              default: () => {
                return h(
                  'div',
                  { class: 'pa-2' },
                  [
                    h(
                      sendingsCreateForm,
                      {
                        props: {
                          value: fallback,
                          isFallback: true,
                          disabled: true,
                          readonly: true
                        }
                      }
                    )
                  ]
                )
              }
            }
          }
        )
      })
    )
  } else {
    return this.getTranslate('replacement.hints.fallbacks')
  }
}

function renderTagByType(h, label, color) {
  return h(
    tag,
    {
      props: {
        label,
        color
      }
    }
  )
}
function renderContentByType(h, value) {
  switch (this.type) {
    case 'type': return renderTagByType.call(this, h, this.getTranslate(`${services.sendings}.types.${value}`), value)
    case 'payload.sender': return renderTagByType.call(this, h, value, colors.epic)
    case 'state.moderation': return renderTagByType.call(this, h, this.getTranslate(`misc.moderation.${value}`), moderationColors[value])
    case 'fallbacks': return renderFallbacks.call(this, h, value)
    default: return h('pre', {}, value)
  }
}
function renderContent(h, item) {
  return h(
    'div',
    {
      class: `${className}-stepper__step-content`
    },
    [ renderContentByType.call(this, h, item) ]
  )
}
function renderItem(h, item, index) {
  if (item) {
    return h(
      'div',
      {
        class: {
          [`${className}-stepper__step`]: true,
          [`${className}-stepper__step--first`]: index === 0,
          [`${className}-stepper__step--last`]: index === this.value.length - 1
        }
      },
      [
        renderDescription.call(this, h, index),
        renderContent.call(this, h, item)
      ]
    )
  }
}
function renderItems(h) {
  if (Array.isArray(this.value) && this.value.length) {
    return h(
      'div',
      {
        class: `${className}-stepper__steps`
      },
      [
        this.value.map((item, index) => {
          return renderItem.call(this, h, item, index)
        })
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${className}-stepper`
    },
    [ renderItems.call(this, h) ]
  )
}
