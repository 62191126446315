import { dateFilter, services } from '@/utils'

import { getChannelTypes } from '@/components/services/otp-settings/utils'

import render from './render'

export default {
  name: 'Filters',

  mixins: [ dateFilter ],

  computed: {
    channelTypes() {
      return getChannelTypes(services['otp-settings/channels']).map(value => {
        return {
          title: this.getTranslate(`${services.sendings}.types.${value}`),
          value
        }
      })
    },

    gridTemplateColumns() {
      switch (true) {
        case this.viewport.breakpoint.smDown: return '1fr'
        case this.viewport.breakpoint.mdDown: return 'repeat(2, 1fr)'
        case this.viewport.breakpoint.lgUp: return 'repeat(2, 260px)'
      }
    }
  },

  render
}
