export default {
  title: 'Registro de eventos de pagos',

  labels: {
    type: 'Tipo',
    id: 'Id'
  },

  tabs: {
    request: 'Solicitud',
    response: 'Respuesta'
  },

  directions: {
    incoming: 'Entrante',
    outgoing: 'Saliente'
  },

  hints: {
    notFound: 'No se encontró ningún registro'
  }
}
