export default {
  title: 'WebSockets',

  labels: {
    connected: 'Conectado a WebSockets',
    disconnected: 'Desconectado de WebSockets'
  },

  tooltips: {
    connection: `Su conexión con la plataforma está <strong>activa a través de WebSockets</strong>. Recibe actualizaciones de estado y saldo <strong>en tiempo real</strong>.
<br>Si el estado es <strong>desconectado</strong>, intente:
<ul class="ma-0 pl-3"><li>Recargar la página.</li><li>Verificar la conexión a Internet.</li></ul>
Si el problema persiste, puede ser un problema técnico. Inténtelo de nuevo más tarde.`
  }
}
