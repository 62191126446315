import { CPaymentsCommissionsMap, paymentsAddCommissionToAmount } from '@sigmasms/commons'

import { proxy, services } from '@/utils'

import render from './render'

export default {
  name: 'PaymentsForm',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    OwnerId: String,

    methods: {
      type: Array,
      default: undefined
    }
  },

  data() {
    return {
      acceptOffer: true,
      showMethodsWarning: false
    }
  },

  computed: {
    validation() {
      let result = true
      if (!this.acceptOffer) {
        result = false
      }
      if (!this.proxy.method) {
        result = false
      }
      if (!this.proxy.amount || this.proxy.amount <= 0) {
        result = false
      }

      return result
    },

    commission() {
      if (this.proxy.method) {
        return CPaymentsCommissionsMap[this.proxy.method]
      }
    },
    commissionSuffix() {
      if (this.commission) {
        return [
          [
            '+',
            this.getCommission(),
            this.getTranslate(`currency.${this.globalSettings.billings.currency}.symbol.unicode`)
          ].join(''),
          this.getTranslate(`${services.payments}.hints.commissions`)
        ].join(' ')
      }
    },
    commissionHint() {
      if (this.commission) {
        return `${this.getTranslate(`${services.payments}.hints.commission`)} ${this.commission}%`
      }
    }
  },

  watch: {
    $route: {
      handler() {
        this.setOwnerId()
      },
      deep: true
    },

    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.setOwnerId()
    this.$emit('validation', this.validation)
  },

  methods: {
    setOwnerId() {
      const owner = this.$route.params.owner
      if (owner && !this.proxy.OwnerId) {
        this.proxy.OwnerId = owner
      }
    },

    getCommission() {
      const amount = this.proxy.amount || 0
      const amountWithCommission = paymentsAddCommissionToAmount(amount, this.commission)

      return (amountWithCommission - amount).toFixed(2)
    }
  },

  render
}
