import { serviceTemplate, services } from '@/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services.providers,

      find: {
        defaultFilter: {
          ServerId: undefined,
          CredentialsId: undefined,
          isActive: true,
          $scope: [ 'Server', 'Credentials' ]
        }
      }
    })
  ]
}
