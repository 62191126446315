import generatorRow from '@/components/generator/row'
import fallback from '@/components/misc/rules/actions/generators/fallbacks/fallback'
import { defaultFallback } from '@/components/misc/rules/utils'

function renderItem(h, item) {
  return h(
    fallback,
    {
      props: {
        value: item
      },
      on: {
        input: event => {
          item = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    generatorRow,
    {
      props: {
        value: this.proxy,
        defaultValue: defaultFallback
      },
      scopedSlots: {
        row: ({ item }) => {
          return renderItem.call(this, h, item)
        }
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
