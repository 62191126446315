import { generateServices, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'Update',

  mixins: [
    generateServices({
      name: services.providers,

      find: false,
      create: false,
      remove: false
    })
  ],

  props: {
    id: String
  },

  data() {
    return {
      showDialog: false
    }
  },

  watch: {
    id() {
      this.get()
    }
  },

  mounted() {
    this.get()
  },

  methods: {
    async get() {
      if (this.checkPermissions(`advanced.${services.providers}.get`)) {
        if (this.id) {
          try {
            await this.rest[services.providers].get(this.id)
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },
    async update() {
      if (this.checkPermissions(`advanced.${services.providers}.update`)) {
        if (this.restData[services.providers].update.isValid) {
          try {
            const result = await this.rest[services.providers].update(this.restData[services.providers].get.data)
            if (result) {
              this.showDialog = false
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    }
  },

  render
}
