import Sortable from 'sortablejs'

const updateDom = event => {
  event.item.remove()
  if (event.oldIndex !== undefined) {
    event.from.insertBefore(event.item, event.from.children[event.oldIndex])
  }
}

/**
 * Draggable class makes elements in a container draggable.
 *
 * @class Draggable
 * @param {HTMLElement} holder - The container with draggable elements.
 * @param {string} handle - The class name of the elements that trigger dragging.
 *
 * @property {HTMLElement} holder - The container with draggable elements.
 * @property {string} handle - The class name of the elements that trigger dragging.
 * @property {number} oldIndex - The old index of the dragged element.
 * @property {number} newIndex - The new index of the dragged element.
 * @property {string} eventType - The last drag event type.
 *
 * @method dragstart - Called when the dragging starts. Sets the eventType.
 * @method dragend - Called when the dragging ends. Sets the oldIndex, newIndex, and eventType, and updates the DOM.
 * @method setOptions - Sets the options for the Sortable instance.
 */
export class Draggable {
  constructor(holder, handle, disabled) {
    this.sortable = null

    this.disabled = disabled
    this.holder = holder
    this.handle = handle

    this.oldIndex = 0
    this.newIndex = 0
    this.eventType = undefined

    this.dragstart = this.dragstart.bind(this)
    this.dragend = this.dragend.bind(this)

    for (const element of this.holder.getElementsByClassName(this.handle)) {
      element.classList.add('cursor--grab')
    }

    if (this.holder) {
      this.setOptions()
    }
  }

  dragstart(event) {
    this.eventType = event.type
  }

  dragend(event) {
    this.oldIndex = event.oldIndex
    this.newIndex = event.newIndex
    updateDom(event)
    this.eventType = event.type

    for (const element of this.holder.children) {
      element.removeAttribute('draggable')
      element.removeAttribute('style')
    }
  }

  setOptions() {
    this.eventType = undefined

    if (this.sortable) {
      this.sortable.destroy()
    }

    this.sortable = new Sortable(this.holder, {
      handle: this.handle ? `.${this.handle}` : undefined,
      disabled: this.disabled,
      animation: 150,
      onStart: this.dragstart,
      onEnd: this.dragend,
      ghostClass: 'draggable-ghost',
      swapThreshold: 0.5,
      scroll: true,
      scrollSpeed: 15,
      scrollSensitivity: 50,
      forceAutoScrollFallback: true,
      bubbleScroll: true,
      forceFallback: false
    })
  }
}

export default { Draggable }
