import { mapGetters } from 'vuex'

import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    isNew: Boolean
  },

  data() {
    return {
      vat: undefined,

      showFileManager: false,
      showFileDialog: false,

      googleFileURL: undefined,
      currentEditFile: undefined,
      dialogType: undefined,
      dialogData: undefined
    }
  },

  computed: {
    ...mapGetters({ file: 'file/file' }),

    validation() {
      return !!this.proxy.title && !!this.proxy.FileId
    }
  },

  watch: {
    file: {
      handler({ data }) {
        this.showFileManager = false
        this.proxy.FileId = data.id
      },
      deep: true
    },

    validation() {
      this.$emit('validation', this.validation)
    },

    'proxy.OwnerId'() {
      this.proxy.RequisiteId = undefined
      this.proxy.CompanyId = undefined
    },

    'proxy.RequisiteId'() {
      this.proxy.CompanyId = undefined
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  beforeDestroy() {
    if (this.isNew) {
      for (const key of Object.keys(this.proxy)) {
        this.proxy[key] = undefined
      }
    }
  },

  render
}
