import { isEqual } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import { components, form } from '@/utils'

import { core, iMask } from './mixins'
import render from './render'

export default {
  name: components.input,

  mixins: [ core(), proxy(), iMask, form ],

  props: {
    component: {
      type: String,
      default: 'input',
      validator: value => {
        return ~[ 'input', 'textarea' ].indexOf(value)
      }
    }
  },

  methods: {
    receiveValue() {
      this._initMasked()
      this.proxy = this._inputFilter(this.value)
    },

    transmitValue() {
      const proxy = this._outputFilter(this.proxy)
      if (!isEqual(proxy, this.value)) {
        this.$emit('input', proxy)
      }
    },

    _processValue(value) {
      if (this.processValue && typeof this.processValue === 'function') {
        value = this.processValue(value)
      }

      switch (typeof value) {
        case 'string': {
          if (this.trim) {
            value = value.trim()
          }
          break
        }
        case 'number': {
          value = value.toString()
          break
        }
      }

      if (this.mask && this.masked) {
        this._updateValue(value)
        value = this.masked.value
      }

      return value
    },

    _inputFilter(value) {
      value = this._processValue(value)
      if (this.$el) {
        const valueChangeEvent = new Event('valuechange', { bubbles: true })
        this.$el.dispatchEvent(valueChangeEvent)
      }

      return value
    },
    _outputFilter(value) {
      if (this.type === 'number') {
        if (typeof value !== 'string' && value !== undefined) {
          value = value.toString()
        }
        if (typeof value === 'string') {
          value = value.replace(',', '.')
          value = parseFloat(value)
        }
        if (isNaN(value)) {
          value = undefined
        }
      } else if (this.type === 'text') {
        if (typeof value === 'number') {
          value = value.toString()
        }
      }

      return value
    },

    _eventHandler(event) {
      this.$emit(event.type, event)
    },

    _inputHandler(event) {
      this.proxy = this._processValue(event.target.value)
    }
  },

  render
}
