export default {
  title: 'Diccionarios',

  states: {
    loading: 'Subido {count}.',
    processing: 'Procesando...',
    import: 'Importando {count} filas.',
    done: 'Importadas {count} filas.'
  },

  dialogs: {
    titles: {
      remove: 'Confirmar eliminación del diccionario'
    },
    create: 'Crear nueva clave'
  },

  new: {
    key: 'Nueva clave'
  },

  titles: {
    'phone:mnp': 'MNP',
    'phone:ranges': 'Prefijos',
    'phone:opgroups': 'Grupos de operadores',
    service: {
      mnp: 'MNP',
      ranges: 'Rangos',
      operatorGroups: 'Grupos de operadores'
    },
    upload: 'Subir',
    import: 'Importar',
    mix: '¿Agregar prefijos del servidor INTIS?',
    createGroup: 'Crear grupo de operadores',
    group: 'Grupo',
    operator: 'Operador',
    number: 'Número',
    mcc: 'MCC',
    mnc: 'MNC'
  },

  labels: {
    key: 'Clave',
    opcode: 'Código de operador',
    group: 'Grupo',
    groupName: 'Nombre del grupo',
    operatorName: 'Nombre del operador',
    operator: 'Operador',
    number: 'Número',
    region: 'Región',
    prefix: 'Prefijo',
    mcc: 'MCC',
    mnc: 'MNC',
    type: 'Tipo de diccionario',
    status: 'Estado',
    OwnerId: 'Propietario',
    total: 'Total',

    ranges: {
      start: 'Inicio del rango',
      end: 'Fin del rango'
    },

    choose: {
      dictionary: 'Elija el diccionario',
      key: 'Ingrese la clave'
    },

    search: {
      keys: 'Buscar en las claves'
    }
  },

  hints: {
    noItems: 'No hay diccionarios para mostrar',
    loading: 'No cierre ni recargue la ventana mientras se procesa la importación.',
    importOr: 'O use el administrador de archivos',

    search: {
      empty: 'No se encontraron datos para este número'
    }
  },

  captions: {
    createdAt: 'Diccionario creado el',
    total: 'Total de entidades'
  },

  buttons: {
    compile: 'Compilar',
    set: 'Activar',
    unset: 'Desactivar',

    add: {
      range: 'Agregar rango'
    }
  },

  contents: {
    confirm: {
      remove: {
        dictionary: '¿Está seguro de que desea eliminar el diccionario {title}?',
        key: '¿Está seguro de que desea eliminar la clave {title}?'
      }
    }
  },

  snackbars: {
    dictionary: {
      created: 'Diccionario creado con éxito.',
      compile: 'Diccionario compilado con éxito.',
      updated: 'Diccionario actualizado con éxito.',
      removed: 'Diccionario eliminado con éxito.'
    },
    key: {
      created: 'Clave en el diccionario creada con éxito.',
      updated: 'Clave en el diccionario actualizada con éxito.',
      removed: 'Clave en el diccionario eliminada con éxito.'
    },
    updated: 'Diccionario actualizado con éxito.'
  },

  statuses: {
    created: 'Creado',
    ready: 'Listo',
    downloading: 'Descargando',
    importing: 'Importando',
    importError: 'Error de importación',
    compiling: 'Compilando',
    compiled: 'Compilado',
    compileError: 'Error de compilación',
    activated: 'Activado'
  },

  tooltips: {
    upload: {
      filemanager: 'Importar desde archivo',
      intis: 'Importar desde el servidor INTIS'
    },
    info: 'Información de estructura CSV'
  },

  filters: {
    type: {
      'phone:mnp': 'MNP',
      'phone:ranges': 'Rangos',
      'phone:opgroups': 'Grupos de operadores'
    },

    items: {
      default: 'Diccionarios del sistema'
    }
  },

  info: {
    columns: {
      Number: 'número de teléfono',
      OwnerId: 'nombre del operador',
      MCC: 'MCC',
      MNC: 'MNC',
      NumberFrom: 'número de teléfono donde comienza el rango',
      NumberTo: 'número de teléfono donde termina el rango',
      RegionCode: 'código de región',
      GROUP: 'nombre del grupo',
      OrgCode: 'nombre del operador',
      TYPE: 'tipo de número de teléfono (ciudad ABC, móvil DEF)'
    },

    hint: 'Los nombres de las columnas distinguen entre mayúsculas y minúsculas'
  }
}
