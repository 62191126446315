import Vue from 'vue'

import { cloneDeep, get } from 'lodash'

import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { DEFAULT_INVOICE_PAYLOAD, getUrlToStorage, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'DocumentTemplatesGenerateButton',

  props: {
    OwnerId: String,
    ResellerId: String
  },

  data() {
    return {
      loading: false,
      showDialog: false,
      formValidation: false,

      type: DOCUMENT_TEMPLATES_TYPES.invoice,
      payload: cloneDeep(DEFAULT_INVOICE_PAYLOAD)
    }
  },

  watch: {
    OwnerId() {
      this.payload.customerId = this.OwnerId
    },

    $route: {
      handler() {
        this.showDialog = false
      },
      deep: true
    }
  },

  mounted() {
    this.payload.customerId = this.OwnerId
  },

  beforeDestroy() {
    this.payload = cloneDeep(DEFAULT_INVOICE_PAYLOAD)
  },

  methods: {
    async generate() {
      try {
        this.loading = true
        switch (this.type) {
          case DOCUMENT_TEMPLATES_TYPES.contract: {
            const { data } = await Vue.$GRequest.find(`${services.documentTemplates}/generate`, {
              query: {
                type: DOCUMENT_TEMPLATES_TYPES.contract,
                companyId: this.payload.companyId,
                requisiteId: this.payload.requisiteId
              }
            })
            if (data) {
              window.location.href = getUrlToStorage([ get(data, 'Owner.id', data.OwnerId), data.id ])
              this.showDialog = false
            }
            break
          }
          case DOCUMENT_TEMPLATES_TYPES.invoice: {
            const { data } = await Vue.$GRequest.create(`/n/${services.invoices}`, this.payload, { query: { scopes: [ 'awaitDocument' ] } })
            if (data) {
              window.location.href = getUrlToStorage([ get(data, 'Owner.id', data.OwnerId), data.documentId ])
              this.showDialog = false
            }
            break
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
