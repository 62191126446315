import { PAYMENTS_LOG_DIRECTION } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, icons, services, states } from '@/utils'

import button from '@/components/button'
import sticker from '@/components/misc/sticker'
import preloader from '@/components/preloader'
import sourceBox from '@/components/source/box'
import date from '@/components/templates/service/item/date'

const tabs = [ 'request', 'response' ]

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'preloader__holder pa-5'
    },
    [ h(preloader) ]
  )
}
function renderAbsolutePreloader(h) {
  if (this.restData[services.paymentLogs].find.state === states.loading) {
    return h(
      'div',
      {
        class: 'preloader__holder preloader__holder--absolute'
      },
      [ h(preloader) ]
    )
  }
}

function renderType(h, item) {
  return h(
    sticker,
    {
      props: {
        value: this.getTranslate(`${services.paymentLogs}.directions.${item.direction}`),
        label: this.getTranslate(`${services.paymentLogs}.labels.type`),
        icon: item.direction === PAYMENTS_LOG_DIRECTION.incoming ? 'arrow_drop_down' : 'arrow_drop_up',
        iconTooltip: this.getTranslate(`${services.paymentLogs}.directions.${item.direction}`),
        color: item.direction === PAYMENTS_LOG_DIRECTION.incoming ? 'success' : 'primary',
        borderStyle: 'dashed'
      }
    }
  )
}
function renderId(h, item) {
  if (this.viewport.size.width > 425) {
    if (item?.id) {
      return h(
        sticker,
        {
          props: {
            value: { entityId: item.id },
            label: this.getTranslate(`${services.paymentLogs}.labels.id`),
            pathToTitle: 'entityId',
            icon: icons.numbers,
            color: colors.grey
          }
        }
      )
    }
  }
}
function renderInfo(h, item) {
  return h(
    'div',
    {
      class: `${services.paymentLogs}-item__info`
    },
    [
      renderType.call(this, h, item),
      renderId.call(this, h, item)
    ]
  )
}
function renderDate(h, item) {
  return h(
    date,
    {
      props: {
        value: item
      }
    }
  )
}
function renderItem(h, item) {
  return h(
    'div',
    {
      class: `${services.paymentLogs}-item service-template-item`,
      on: {
        click: () => {
          this.request = item.request
          this.response = item.response
          this.showDialog = true
        }
      }
    },
    [
      renderInfo.call(this, h, item),
      renderDate.call(this, h, item)
    ]
  )
}
function renderItems(h) {
  return h(
    'div',
    {
      style: { position: 'relative' }
    },
    [
      this.restData[services.paymentLogs].find.data.map(item => {
        return renderItem.call(this, h, item)
      }),

      renderAbsolutePreloader.call(this, h)
    ]
  )
}
function renderBody(h) {
  if (this.restData[services.paymentLogs].find.pagination.total > 0) {
    return renderItems.call(this, h)
  } else {
    switch (this.restData[services.paymentLogs].find.state) {
      case states.loading: return renderPreloader.call(this, h)
      case states.ready:
      default: return h(components.empty)
    }
  }
}

function renderDialogHeader(h) {
  return h(
    components['tabs-header'],
    {
      class: 'pa-2',
      props: {
        value: this.currentTab,
        tabs: tabs.map(key => {
          return {
            key,
            title: this.getTranslate(`${services.paymentLogs}.tabs.${key}`)
          }
        })
      },
      on: {
        input: event => {
          this.currentTab = event
        }
      },
      slot: 'header'
    }
  )
}
function renderDialogBody(h) {
  return h(
    components.tabs,
    {
      class: 'px-2',
      props: { value: this.currentTab }
    },
    [
      tabs.map(key => {
        return h(
          sourceBox,
          {
            props: { value: this[key] },
            key
          }
        )
      })
    ]
  )
}
function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'dialog__footer',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.showDialog = false
            }
          }
        }
      )
    ]
  )
}
function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        value: this.showDialog,
        rounded: this.viewport.breakpoint.mdUp ? true : undefined,
        maxWidth: this.viewport.breakpoint.mdUp ? 1000 : undefined
      },
      on: {
        input: event => {
          if (event === false) {
            this.currentTab = undefined
            this.request = undefined
            this.response = undefined
          }
          this.showDialog = event
        }
      }
    },
    [
      renderDialogHeader.call(this, h),
      renderDialogBody.call(this, h),
      renderDialogFooter.call(this, h)
    ]
  )
}

export default function(h) {
  if (this.checkPermissions(`advanced.${services.paymentLogs}.get`)) {
    return h(
      'div',
      {
        class: services.paymentLogs
      },
      [
        renderBody.call(this, h),
        renderDialog.call(this, h)
      ]
    )
  }
}
