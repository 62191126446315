import { cloneDeep, isEqual } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import { timeToArrayOfNumbers } from '../misc'

const timeOptions = {
  hour12: false,
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
}

const createdAtKeyMapping = {
  legacy: {
    gt: '$gt',
    lt: '$lt'
  },
  nest: {
    gt: 'gte',
    lt: 'lt'
  }
}

const convertDateTime = ({ date, time }, backendGeneration = 'legacy') => {
  const result = {
    [createdAtKeyMapping[backendGeneration].gt]: undefined,
    [createdAtKeyMapping[backendGeneration].lt]: undefined
  }

  const _gt = date[0] ? new Date(date[0]) : undefined
  const _lt = date[1] ? new Date(date[1]) : _gt ? new Date(_gt) : undefined

  if (_gt && _lt && time.$gt && time.$lt) {
    _gt.setHours(...timeToArrayOfNumbers(time.$gt))
    _lt.setHours(...timeToArrayOfNumbers(time.$lt))

    result[createdAtKeyMapping[backendGeneration].gt] = _gt.toISOString()
    result[createdAtKeyMapping[backendGeneration].lt] = _lt.toISOString()
  }

  return result
}
const convertDateToHammerTime = (date, locale) => {
  return date.toLocaleTimeString(locale, timeOptions)
}

export const createdAtFilter = {
  mixins: [ proxy({ type: 'object' }) ],

  props: {
    backendGeneration: {
      type: String,
      default: 'legacy'
    }
  },

  methods: {
    _inputFilter(data) {
      const value = cloneDeep(data)
      const result = {
        date: [],
        time: {}
      }

      const _gt = value[createdAtKeyMapping[this.backendGeneration].gt]
      const _lt = value[createdAtKeyMapping[this.backendGeneration].lt]

      const gt = _gt ? new Date(_gt) : undefined
      const lt = _lt ? new Date(_lt) : undefined

      if (gt) {
        result.date.push(gt.getTime())
        result.time.$gt = convertDateToHammerTime(gt, this.locale)
      }
      if (lt) {
        result.date.push(lt.getTime())
        result.time.$lt = convertDateToHammerTime(lt, this.locale)
      }

      return result
    },

    _outputFilter(data) {
      return convertDateTime(data, this.backendGeneration)
    },

    watchProxyHandler() {
      const value = cloneDeep(this.value)
      const converted = convertDateTime(cloneDeep(this.proxy), this.backendGeneration)

      if (
        !isEqual(
          value[createdAtKeyMapping[this.backendGeneration].gt],
          converted[createdAtKeyMapping[this.backendGeneration].gt]
        ) ||
        !isEqual(
          value[createdAtKeyMapping[this.backendGeneration].lt],
          converted[createdAtKeyMapping[this.backendGeneration].lt]
        )
      ) {
        this.transmitValue()
      }
    }
  }
}

export default { createdAtFilter }
