import { buttonModes, colors, services, sizes } from '@/utils'

import button from '@/components/button'
import { className } from '@/components/misc/rules/utils'
import ruleActions from '@/components/services/tariffs/rules/rule/actions'
import tag from '@/components/tag'

function renderTitle(h) {
  return h(
    tag,
    {
      props: {
        label: this.getTranslate(`${services.tariffs}.${this.isMultiple ? 'otherCases' : 'always'}`),
        size: sizes.huge,
        color: colors.grey
      }
    }
  )
}

function renderActions(h) {
  return h(
    ruleActions,
    {
      props: {
        value: this.proxy,
        source: this.source
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderRemoveButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate(`${className}.buttons.remove.rule`),
        size: sizes.small,
        mode: buttonModes.flat,
        color: colors.error
      },
      on: {
        click: () => {
          this.$emit('remove')
        }
      }
    }
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: `${services.tariffs}-rule__footer`
    },
    [ renderRemoveButton.call(this, h) ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.tariffs}-rule`
    },
    [
      renderTitle.call(this, h),
      renderActions.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
