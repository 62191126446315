export default {
  titles: {
    import: {
      result: 'Resultados de la importación'
    }
  },

  labels: {
    version: 'Versión'
  },

  contents: {
    import: {
      result: 'Registros importados con éxito: {result}',
      error: 'No se pudieron importar los registros de este archivo.<br>Por favor, revise el archivo e intente nuevamente.<strong class="mt-2">Columnas obligatorias: mcc, mnc, precio.</strong>'
    }
  }
}
