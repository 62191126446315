import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, moderationColors, moderationIcons } from '@/utils'

import button from '@/components/button'
import { className } from '@/components/misc/rules/utils'

export default function(h) {
  return h(
    'div',
    {
      class: `${className}-dialog__content-section--row`
    },
    MODERATION_VALUES.map(moderation => {
      return h(
        button,
        {
          props: {
            label: this.getTranslate(`misc.moderation.${moderation}`),
            icon: moderationIcons[moderation],
            mode: this.proxy !== moderation ? buttonModes.flat : buttonModes.filled,
            color: this.proxy !== moderation ? colors.description : moderationColors[moderation]
          },
          on: {
            click: () => {
              this.proxy = moderation
            }
          }
        }
      )
    })
  )
}

