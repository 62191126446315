export default {
  title: 'Шаблоны для модерации',
  pattern: 'Паттерн',

  titles: {
    new: 'Новый шаблон для модерации',
    create: 'Создать новый шаблон для модерации',
    users: 'Пользователи'
  },

  headers: {
    moderation: 'Модерация',
    title: 'Название',
    comment: 'Комментарий'
  },

  labels: {
    title: 'Название',
    name: 'Имя отправителя',
    text: 'Текст',
    type: 'Тип отправления',
    moderation: 'Модерация',
    search: 'Поиск',
    id: 'ID',
    owner: 'Владелец',
    isActive: 'Активировать или деактивировать шаблон для модерации',
    checkPattern: 'Проверить паттерн',
    sendername: 'Имя отправителя',

    meta: {
      comment: 'Комментарий',
      adminComment: 'Комментарий администратора',
      adminCommentPrivate: 'Приватный комментарий администратора',
      resellerComment: 'Комментарий реселлера',
      resellerCommentPrivate: 'Приватный комментарий реселлера'
    }
  },

  statuses: {
    disabled: 'Не запрошено',
    requested: 'Запрошено',
    moderation: 'На модерации',
    approved: 'Одобрено',
    rejected: 'Отклонено'
  },

  contents: {
    confirm: {
      remove: 'Удалить шаблон для модерации?'
    }
  },

  errors: {
    isSms: 'Только латинские буквы или цифры, не более 15 символов',
    pattern: 'Паттерн не прошёл проверку'
  },

  buttons: {
    users: 'Пользователи {count}'
  },

  hints: {
    emptyState: 'Не найдено ни одного шаблона для модерации',
    notFound: 'Шаблоны для модерации не найдены',
    checkPattern: 'Введите проверочный текст для тестирования паттерна модерации'
  },

  tooltips: {
    favorites: 'Сделать шаблон для модерации избранным',
    filter: {
      isActive: 'Показать деактивированные шаблоны для модерации'
    },
    isActive: {
      true: 'Активирован',
      false: 'Деактивирован'
    }
  },

  snackbars: {
    created: 'Шаблон для модерации успешно создан.',
    updated: 'Шаблон для модерации успешно обновлён.',
    removed: 'Шаблон для модерации успешно удалён.'
  }
}
