import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { components, inputModes, services } from '@/utils'

import contractForm from '@/components/services/documentTemplates/generate/form/contract'
import invoicesForm from '@/components/services/invoices/form'
import tag from '@/components/tag'

function renderTypes(h) {
  return h(
    components.select,
    {
      props: {
        value: this.type,
        items: DOCUMENT_TEMPLATES_TYPES.map(item => {
          return {
            title: this.getTranslate(`${services.invoices}.labels.${item}`),
            value: item
          }
        }),
        label: this.getTranslate(`${services.invoices}.labels.type`),
        mode: inputModes['line-label'],
        disabled: this.loading,
        rounded: true,
        details: false,
        required: true
      },
      on: {
        input: event => {
          this.type = event
        }
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            tag,
            {
              props: {
                label: item.title,
                as: 'select'
              }
            }
          )
        }
      }
    }
  )
}

function renderContentByType(h) {
  switch (this.type) {
    case DOCUMENT_TEMPLATES_TYPES.contract: {
      return h(
        contractForm,
        {
          props: {
            value: this.proxy,
            loading: this.loading,
            OwnerId: this.OwnerId,
            ResellerId: this.ResellerId
          },
          on: {
            input: event => {
              this.proxy = event
            },
            validation: event => {
              this.formValidation = event
            }
          }
        }
      )
    }
    case DOCUMENT_TEMPLATES_TYPES.invoice:
    default: {
      return h(
        invoicesForm,
        {
          props: {
            value: this.proxy,
            isNew: true,
            loading: this.loading,
            paddless: true,
            OwnerId: this.OwnerId,
            ResellerId: this.ResellerId
          },
          on: {
            input: event => {
              this.proxy = event
            },
            validation: event => {
              this.formValidation = event
            }
          }
        }
      )
    }
  }
}

export default function(h) {
  return h(
    components.form,
    {
      class: 'grid pt-2 px-2'
    },
    [
      renderTypes.call(this, h),
      renderContentByType.call(this, h)
    ]
  )
}
