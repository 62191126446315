import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { proxy } from '@/utils'

import { invoicesContracts } from '@/components/services/documentTemplates/generate/utils'

import render from './render'

export default {
  name: 'Contract',

  mixins: [
    proxy({ type: 'object' }),
    invoicesContracts(DOCUMENT_TEMPLATES_TYPES.contract)
  ],

  props: {
    loading: Boolean
  },

  computed: {
    validation() {
      if (!this.proxy.requisiteId) {
        return false
      }
      if (!this.proxy.companyId) {
        return false
      }

      return true
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  render
}

