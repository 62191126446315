import { colors, moderationColors } from '@/utils'

import { className, ruleClasses, ruleTags, ruleTypes } from '@/components/misc/rules/utils'

import render from './render'

export default {
  name: 'Item',

  props: {
    value: null,

    tag: String,

    ruleType: {
      type: String,
      default: ruleTypes.conditions,
      validator: value => {
        return ~Object.values(ruleTypes).indexOf(value)
      }
    },

    readonly: Boolean,
    clearable: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    hasValue() {
      return ruleClasses[this.ruleType].validateValue(this.value, this.tag)
    },

    maxTags() {
      switch (this.tag) {
        case ruleTags.type: return 3
        case ruleTags.sender: return 1
        case ruleTags.group: return 1
        case ruleTags.mccmnc: return this.viewport.breakpoint.mdUp ? 3 : this.viewport.breakpoint.sm ? 2 : 1
      }
    },

    $value() {
      switch (this.ruleType) {
        case ruleTypes.conditions: {
          switch (this.tag) {
            case ruleTags.text:
            case ruleTags.code:
            case ruleTags.recipient: return this.value
            case ruleTags.group:
            case ruleTags.sender: return this.value.slice(0, this.maxTags)
            case ruleTags.keywords:
            case ruleTags.contactList: return `${this.getTranslate(`${className}.labels.${this.tag}`)} | ${this.value.length}`
            case ruleTags.type: return this.value.slice(0, this.maxTags).map(type => {
              return {
                label: this.getTranslate(`${className}.labels.${type}`),
                color: colors[type]
              }
            })
          }
        }

        case ruleTypes.actions: {
          switch (this.tag) {
            case ruleTags.text:
            case ruleTags.fallbacks:
            case ruleTags.buttons: return `${this.getTranslate(`${className}.labels.${this.tag}`)} | ${this.value.length}`
            case ruleTags.type: return {
              label: this.getTranslate(`${className}.labels.${this.value}`),
              color: colors[this.value]
            }
            case ruleTags.sender: return this.value.slice(0, this.maxTags)
            case ruleTags.moderation: return {
              label: this.getTranslate(`${className}.labels.${this.value}`),
              color: moderationColors[this.value]
            }
          }
        }
      }
    }
  },

  render
}
