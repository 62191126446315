import { cloneDeep } from 'lodash'

import { components, inputModes, services } from '@/utils'

import rulesActionsDialogGeneratorsButtons from '@/components/misc/rules/actions/generators/buttons'
import rulesActionsDialogGeneratorsFallbacks from '@/components/misc/rules/actions/generators/fallbacks'
import rulesActionsDialogGeneratorsModeration from '@/components/misc/rules/actions/generators/moderation'
import rulesActionsDialogGeneratorsText from '@/components/misc/rules/actions/generators/text'
import sendingType from '@/components/misc/rules/commons/type'
import { className, ruleClasses, ruleTags, ruleTypes } from '@/components/misc/rules/utils'
import groups from '@/components/trees/group'
import listReadonly from '@/components/trees/list/readonly'
import mccmnc from '@/components/trees/mccmnc'
import groupsReadonly from '@/components/trees/mccmnc/readonly'
import senders from '@/components/trees/sender'

import render from './render'

export default {
  name: 'mainComponent',

  props: {
    value: null,

    tag: {
      type: String,
      default: undefined,
      validator: value => {
        return ~Object.values(ruleTags).indexOf(value)
      }
    },

    ruleType: {
      type: String,
      default: ruleTypes.conditions,
      validator: value => {
        return ~Object.values(ruleTypes).indexOf(value)
      }
    },

    readonly: Boolean
  },

  data() {
    return {
      proxy: this.value
    }
  },

  computed: {
    $component() {
      let result = components.select
      const componentMap = {
        [ruleTags.text]: {
          [ruleTypes.actions]: rulesActionsDialogGeneratorsText,
          [ruleTypes.conditions]: components.textarea
        },
        [ruleTags.type]: {
          [ruleTypes.actions]: sendingType({ type: 'string' }),
          [ruleTypes.conditions]: sendingType({ type: 'array' })
        },
        [ruleTags.sender]: this.readonly ? listReadonly : senders,
        [ruleTags.fallbacks]: rulesActionsDialogGeneratorsFallbacks,
        [ruleTags.moderation]: rulesActionsDialogGeneratorsModeration,
        [ruleTags.buttons]: rulesActionsDialogGeneratorsButtons,
        [ruleTags.mccmnc]: this.readonly ? groupsReadonly : mccmnc,
        [ruleTags.group]: this.readonly ? listReadonly : groups,
        [ruleTags.contactList]: components.select,
        [ruleTags.keywords]: components.select
      }

      if (componentMap[this.tag]) {
        result = componentMap[this.tag][this.ruleType] || componentMap[this.tag]
      } else {
        result = components['text-field']
      }

      return result
    },

    $props() {
      const props = {
        value: this.proxy,
        label: this.getTranslate(`${className}.tags.${this.tag}`),
        mode: inputModes['line-label'],
        disabled: this.disabled,
        dense: true,
        details: false,
        rounded: true,
        autofocus: true,
        clearable: true,
        readonly: this.readonly
      }

      if (~[ ruleTags.contactList, ruleTags.keywords ].indexOf(this.tag)) {
        Object.assign(props, {
          service: this.tag === ruleTags.contactList ? services.contactLists : services.keywordLists,
          multiple: true,
          autocomplete: true
        })
      }

      return props
    },

    defaultValue() {
      const type = ruleClasses[this.ruleType].tagsMeta[this.tag].valueValidator.type
      switch (type) {
        case 'object': return {}
        case 'array': return []
        default: undefined
      }
    }
  },

  watch: {
    value: {
      handler(value) {
        this.proxy = value
      },
      deep: true
    },
    proxy: {
      handler(value) {
        this.$emit('input', value)
      },
      deep: true
    },

    tag() {
      this.setProxy()
    }
  },

  mounted() {
    this.setProxy()
  },

  methods: {
    setProxy() {
      this.proxy = this.defaultValue
      if (this.value) {
        this.proxy = this.value
      }
    },

    reset() {
      this.proxy = cloneDeep(this.defaultValue)
    }
  },

  render
}
