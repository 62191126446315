import { TASK_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, icons, services, sizes } from '@/utils'

import button from '@/components/button'
import tag from '@/components/tag'

function renderType(h, type) {
  if (type) {
    return h(
      tag,
      {
        props: {
          label: this.getTranslate(`${services.tasks}.subheaders.${type}.lists`)
        }
      }
    )
  }
}
function renderRecipientByType(h, item) {
  const props = {
    label: item.title,
    size: sizes.small,
    mode: buttonModes.flat,
    color: colors.primary
  }
  const on = {}

  if (item.id) {
    return h(
      'router-link',
      {
        class: 'link',
        props: {
          to: {
            name: `${services.contacts}.single`,
            params: { id: item.id }
          }
        }
      },
      [ h(button, { props }) ]
    )
  } else {
    on.click = () => {
      navigator.clipboard.writeText(item.title || item)
      this.addSnackbar({
        text: this.getTranslate('misc.copied'),
        type: colors.success
      })
    }
  }

  if (item.error) {
    props.label = `${item.title}: ${item.error}`
    props.color = colors.error
  }

  return h(button, {
    props,
    on
  })
}
function renderRecipients(h, type) {
  return h(
    'div',
    {
      class: 'faic fw grid-gap--8'
    },
    [
      this.analytics[type].map(item => {
        return renderRecipientByType.call(this, h, item)
      })
    ]
  )
}
function renderRecipientsByType(h, type) {
  return h(
    'div',
    {
      class: 'grid faic grid-gap--8'
    },
    [
      renderType.call(this, h, type),
      renderRecipients.call(this, h, type)
    ]
  )
}
function renderAnalytics(h) {
  if (Object.keys(this.analytics).length) {
    return h(
      'div',
      {
        class: 'grid px-2'
      },
      [
        Object.keys(this.analytics).map(type => {
          return renderRecipientsByType.call(this, h, type)
        })
      ]
    )
  }
}

function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.clearAnalytics()
              this.showDialog = false
            }
          }
        }
      )
    ]
  )
}
function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`${services.tasks}.titles.analytics`),
        value: this.showDialog,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 400 : undefined
      },
      on: {
        input: event => {
          if (event === false) {
            this.clearAnalytics()
          }
          this.showDialog = event
        }
      }
    },
    [
      renderAnalytics.call(this, h),
      renderDialogFooter.call(this, h)
    ]
  )
}

export default function(h) {
  if (this.value.type === TASK_TYPES['sendings:bulk']) {
    return h(
      components['list-item'],
      {
        props: {
          label: this.getTranslate(`${services.tasks}.labels.contactLists`),
          icon: icons.quick_contacts_dialer,
          dense: true
        },
        on: {
          click: () => {
            this.analysis()
          }
        },
        scopedSlots: {
          dialog: () => {
            return renderDialog.call(this, h)
          }
        }
      }
    )
  }
}
