export default {
  title: 'Documentación',

  categories: {
    platform: 'Plataforma',
    api: 'API'
  },

  file: {
    title: 'Documentación'
  },

  files: {
    instruction: 'Instrucción',
    'HTTP-REST': 'HTTP-REST',
    SMPP: 'SMPP'
  },

  buttons: {
    toc: 'Contenido'
  },

  platform: {
    instruction: {
      title: 'Instrucción'
    }
  },

  api: {
    'HTTP-REST': {
      title: 'HTTP-REST'
    },
    SMPP: {
      title: 'SMPP'
    }
  }
}
