import { getString } from '@sigma-frontend-libs/commons'

import { components } from '@/utils'

import render from './render'
import { eventHandlers } from '../../../utils/mixins'

export default {
  name: 'TextOverflow',

  mixins: [ eventHandlers ],

  props: {
    value: [ String, Number, Boolean ]
  },

  data() {
    return {
      overflow: false
    }
  },

  computed: {
    $_value() {
      return getString(this.value)
    },

    directives() {
      if (this.overflow) {
        if (this.$el.offsetWidth && this.$el.scrollWidth) {
          if (this.$el.offsetWidth < this.$el.scrollWidth) {
            return [
              {
                name: components.tooltip,
                options: { value: this.$_value }
              }
            ]
          }
        }
      }
    }
  },

  mounted() {
    this.checkOverflow()
  },

  updated() {
    this.checkOverflow()
  },

  methods: {
    checkOverflow() {
      if (this.$el) {
        const observer = new ResizeObserver(entries => {
          for (const entry of entries) {
            window.requestAnimationFrame(() => {
              if (entry.target.clientHeight > 0) {
                this.overflow = true
              } else {
                this.overflow = false
              }
            })
          }
        })
        observer.observe(this.$el)
      }
    }
  },

  render
}
