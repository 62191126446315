import { debounce } from 'lodash'

import { generateRegExpFromString } from '@sigma-legacy-libs/essentials/lib/utils/regexp'
import proxy from '@sigma-legacy-libs/g-proxy'

import { components, inputModes, services, states } from '@/utils'

import { className, ruleTags } from '@/components/misc/rules/utils'

import render from './render'

import ConditionsFrontend from '@/utils/classes/Conditions'

export default {
  name: 'additionalComponent',

  mixins: [ proxy() ],

  props: {
    tag: {
      type: String,
      default: undefined,
      validator: value => {
        return ~Object.values(ruleTags).indexOf(value)
      }
    },

    readonly: Boolean
  },

  data() {
    return {
      loading: false,
      pattern: undefined,
      text: undefined,
      phone: undefined,
      state: undefined,
      error: undefined
    }
  },

  computed: {
    $component() {
      switch (this.tag) {
        case ruleTags.text:
        case ruleTags.contactList: return components.textarea
        case ruleTags.group: return components['text-field']
      }
    },

    $props() {
      const result = {
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      }

      const propsByTag = {
        [ruleTags.text]: {
          value: this.text,
          label: this.getTranslate(`${className}.labels.textToCheck`),
          state: this.text ? this.checkPattern() ? states.success : states.error : undefined,
          error: this.error,
          rows: 4,
          grow: true
        },
        [ruleTags.group]: {
          value: this.pattern,
          label: this.getTranslate(`${className}.labels.group.pattern`)
        },
        [ruleTags.contactList]: {
          value: this.phone,
          label: this.getTranslate(`${className}.labels.phone`),
          state: this.state,
          error: this.error,
          loading: this.loading
        }
      }

      return Object.assign(result, propsByTag[this.tag])
    }
  },

  watch: {
    proxy: {
      handler() {
        this.checkPhone()
      },
      deep: true
    },
    phone() {
      this.checkPhone()
    }
  },

  methods: {
    checkPattern() {
      if (this.tag === ruleTags.text) {
        if (this.text) {
          try {
            const pattern = generateRegExpFromString(this.proxy)

            return pattern.test(this.text)
          } catch (error) {
            this.error = this.getTranslate(`${services.routingRules}.errors.regexp`)

            return false
          }
        }

        return true
      }
    },

    checkPhone: debounce(async function() {
      if (this.phone) {
        this.loading = true
        const result = await ConditionsFrontend.check({
          tag: this.tag,
          value: this.value
        },
        { payload: { recipient: ~this.phone.indexOf('+') ? this.phone : '+' + this.phone } })
        if (result) {
          this.state = states.success
          this.error = undefined
        } else {
          this.state = states.error
          this.error = this.getTranslate(`${services.routingRules}.errors.phone`)
        }
        this.loading = false
      } else {
        this.state = undefined
        this.error = undefined
      }
    }, 500, {
      leading: false,
      trailing: true
    }),

    eventHandler(value) {
      switch (this.tag) {
        case ruleTags.text: {
          this.text = value
          break
        }
        case ruleTags.group: {
          this.pattern = value
          break
        }
        case ruleTags.contactList: {
          this.phone = value
          break
        }
      }
    },

    reset() {
      this.text = undefined
      this.pattern = undefined
      this.phone = undefined
      this.state = undefined
      this.error = undefined
    }
  },

  render
}
