import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import { get } from 'lodash'

import { DICTIONARIES, DICTIONARIES_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'
import proxy from '@sigma-legacy-libs/g-proxy'

import { generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import { rowStates, serviceName } from '@/components/services/operatorGroups/utils'

import render from './render'

export default {
  name: 'CountriesTree',

  mixins: [
    proxy({ type: 'object' }),

    generateServices([
      {
        name: serviceName,
        as: 'countries',

        find: {
          defaultFilter: {
            DictionaryId: undefined,
            $search: undefined,
            $scope: [],
            $limit: 300
          }
        },

        get: false,
        update: false,
        create: false,
        remove: false
      },
      {
        name: serviceName,

        find: {
          defaultFilter: {
            DictionaryId: undefined,
            country: undefined,
            $limit: 300
          }
        },

        get: false,
        update: false,
        create: false,
        remove: false
      },
      {
        name: 'dictionaries',

        find: {
          defaultFilter: {
            type: DICTIONARIES['phone:opgroups'],
            status: DICTIONARIES_STATUSES.activated,
            $limit: 1
          }
        },

        get: false,
        update: false,
        create: false,
        remove: false
      }
    ])
  ],

  data() {
    return {
      searchValue: undefined,
      DictionaryId: undefined,

      countriesState: {},
      groupsState: {},

      groups: {},
      operators: {},

      tab: 'all'
    }
  },

  computed: {
    ...mapGetters({ sortable: 'sortable/list' }),

    countries() {
      const sortedCountries = get(this.sortable, `${serviceName}.countries`)
      if (sortedCountries && sortedCountries.length) {
        return this.restData.countries.find.data.sort((a, b) => {
          return sortedCountries.indexOf(a.mcc) - sortedCountries.indexOf(b.mcc)
        })
      }

      return this.restData.countries.find.data
    },

    $groups() {
      let openedCountry
      for (const item of this.countries) {
        for (const mcc of Object.keys(this.countriesState)) {
          if (item.mcc === mcc) {
            openedCountry = item
          }
        }
      }

      let groups = {}
      let storageItems
      if (this.sortable[serviceName]) {
        storageItems = this.sortable[serviceName][`groups-${openedCountry.mcc}`]
      }
      if (storageItems && storageItems.length) {
        groups = Object.assign({}, { [`${openedCountry.country}`]: {} })
        for (const item of storageItems) {
          Object.assign(groups[openedCountry.country], { [`${item}`]: this.groups[openedCountry.country][item] })
        }
      } else {
        Object.assign(groups, { [`${openedCountry.country}`]: this.groups[openedCountry.country] })
      }

      if (Object.keys(groups).length && openedCountry) {
        const groupsByCountry = get(groups, openedCountry.country, {})

        return Object.keys(groupsByCountry).map(group => {
          return Object.assign({}, {
            group,
            country: openedCountry.country,
            mcc: openedCountry.mcc,
            operators: groupsByCountry[group]
          })
        })
      }
    }
  },

  watch: {
    tab() {
      this.search(this.searchValue)
    }
  },

  created() {
    this.getSortable({
      serviceName,
      key: 'countries'
    })
  },

  mounted() {
    this.search()
  },

  methods: {
    ...mapActions({
      getSortable: 'sortable/get',
      setSortable: 'sortable/set'
    }),

    _inputFilter(data) {
      if (!data) {
        return {}
      }

      return data
    },

    async getDictionary() {
      try {
        if (this.DictionaryId) {
          return this.DictionaryId
        } else {
          const dictionaries = await this.rest.dictionaries.find()
          if (Array.isArray(dictionaries) && dictionaries.length) {
            this.DictionaryId = dictionaries[0].id

            return this.DictionaryId
          }

          throw new Error('Dictionary with countries not found')
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    },

    async search(search) {
      try {
        this.searchValue = search
        if (this.tab === 'all') {
          if (this.DictionaryId) {
            this.restData.countries.find.filter = {
              DictionaryId: this.DictionaryId,
              $search: search,
              $scope: [ 'Country' ],
              $limit: 300
            }
          } else {
            if (await this.getDictionary()) {
              this.search(search)
            }
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    },

    async getGroupsByCountry(country, countryMCC) {
      try {
        const state = get(this.countriesState, countryMCC)
        const checked = get(this.proxy, countryMCC, false)
        if (checked !== 'all') {
          switch (state) {
            case undefined:
            case rowStates.closed: {
              Vue.set(this.countriesState, countryMCC, rowStates.loading)
              if (this.DictionaryId) {
                const groupsInCountry = await this.rest[serviceName].find({
                  query: {
                    DictionaryId: this.DictionaryId,
                    country
                  }
                })
                const groups = {}
                for (const { group, operator, mcc, mnc } of groupsInCountry) {
                  if (mcc === countryMCC) {
                    if (!groups[group]) {
                      groups[group] = []
                    }
                    groups[group].push({
                      group,
                      operator,
                      mcc,
                      mnc
                    })
                    groups[group].sort((a, b) => a.mnc - b.mnc || a.operator - b.operator)
                  }
                }
                for (const stateKey in this.countriesState) {
                  Vue.delete(this.countriesState, stateKey)
                }
                Vue.set(this.groups, country, groups)
                Vue.set(this.countriesState, countryMCC, rowStates.opened)

                const mccs = this.proxy[countryMCC]
                if (Array.isArray(mccs) && mccs.length) {
                  for (const mnc of mccs) {
                    for (const group in groups) {
                      if (!this.operators[group]) {
                        this.operators[group] = []
                      }
                      this.operators[group].push(mnc)
                    }
                  }
                }
                this.getSortable({
                  serviceName,
                  key: `groups-${countryMCC}`
                })
              } else {
                await this.getDictionary()
                this.getGroupsByCountry(country)
              }
              break
            }
            case rowStates.opened: {
              Vue.set(this.countriesState, countryMCC, rowStates.closed)
              break
            }
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        Vue.delete(this.groups, country)
      }
    },

    parser(data) {
      for (const { mcc, mnc } of data) {
        const other = '00'
        if (mcc) {
          if (mnc) {
            if (!this.proxy[mcc] || typeof this.proxy[mcc] === 'string' && this.proxy[mcc] === 'all') {
              Vue.set(this.proxy, mcc, [])
              this.proxy[mcc].push(other)
            }
            if (!~this.proxy[mcc].indexOf(mnc)) {
              this.proxy[mcc].push(mnc)
            }
          } else {
            if (this.proxy[mcc]) {
              if (!~this.proxy[mcc].indexOf(other)) {
                this.proxy[mcc].unshift(other)
              }
            } else {
              Vue.set(this.proxy, mcc, 'all')
            }
          }
        }
      }
    },

    operatorClick({ mcc, mnc, group }) {
      if (!Array.isArray(this.proxy[mcc])) {
        Vue.set(this.proxy, mcc, [])
      }

      if (Array.isArray(this.proxy[mcc])) {
        const index = this.proxy[mcc].indexOf(mnc)
        if (~index) {
          this.proxy[mcc].splice(index, 1)
          if (this.proxy[mcc].length === 0) {
            Vue.delete(this.proxy, mcc)
          }
        } else {
          this.proxy[mcc].push(mnc)
        }
      }

      if (!Array.isArray(this.operators[group])) {
        Vue.set(this.operators, group, [])
      }

      if (Array.isArray(this.operators[group])) {
        const index = this.operators[group].indexOf(mnc)
        if (~index) {
          this.operators[group].splice(index, 1)
        } else {
          this.operators[group].push(mnc)
        }

        if (this.operators[group].length === 0) {
          Vue.delete(this.operators, group)
        }
      }
    },

    groupClick({ group, mcc, operators }) {
      const mncs = operators.map(({ mnc }) => mnc)
      if (Array.isArray(this.proxy[mcc])) {
        for (const mnc of mncs) {
          const index = this.proxy[mcc].indexOf(mnc)
          if (~index) {
            this.proxy[mcc].splice(index, 1)
          } else {
            this.proxy[mcc].splice(index, 0, mnc)
          }
        }
        if (this.proxy[mcc].length === 0) {
          Vue.delete(this.proxy, mcc)
        }
      } else {
        Vue.set(this.proxy, mcc, [])
        this.proxy[mcc].push(...mncs)
      }

      if (Array.isArray(this.operators[group])) {
        for (const mnc of mncs) {
          const index = this.operators[group].indexOf(mnc)
          if (~index) {
            this.operators[group].splice(index, 1)
          } else {
            this.operators[group].splice(index, 0, mnc)
          }
        }
        if (this.operators[group].length === 0) {
          Vue.delete(this.operators, group)
        }
      } else {
        Vue.set(this.operators, group, [])
        this.operators[group].push(...mncs)
      }
    },

    setCountriesSortedList(values) {
      const items = []
      for (const value of values) {
        items.push(value.mcc)
      }
      this.setSortable({
        serviceName,
        key: 'countries',
        items
      })
    },

    setGroupsSortedList(values) {
      const items = []
      for (const { group } of values) {
        items.push(group)
      }
      this.setSortable({
        serviceName,
        key: `groups-${values[0].mcc}`,
        items
      })
    }
  },

  render
}
