import Vue from 'vue'
import { mapActions } from 'vuex'

import { permissionPresets, proxy, services } from '@/utils'

import render from './render'

export default {
  name: 'Requisite',

  mixins: [ proxy() ],

  props: {
    items: {
      type: Array,
      default: () => []
    },

    loading: Boolean,
    disabled: Boolean
  },

  methods: {
    ...mapActions({ setShowPaymentDialog: 'payments/setShowPaymentDialog' }),

    redirect() {
      let name = 'account'
      if (this.checkPermissions(`advanced.${services.requisites}.get`, permissionPresets.resellerUp)) {
        name = services.requisites
      }
      Vue.router.push({
        name,
        query: { currentTab: services.requisites }
      })
      this.setShowPaymentDialog(false)
    }
  },

  render
}
