import { cloneDeep, isEqual } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import { className, ruleTypes } from '@/components/misc/rules/utils'

import render from './render'

export default {
  name: 'Dialog',

  mixins: [ proxy() ],

  props: {
    tag: String,
    tagEdit: Boolean,
    readonly: Boolean,

    tagDisabled: {
      type: Array,
      default: () => []
    },

    ruleType: {
      type: String,
      default: ruleTypes.conditions,
      validator: value => {
        return Object.values(ruleTypes).includes(value)
      }
    },

    show: Boolean
  },

  computed: {
    title() {
      if (this.tag) {
        if (!this.tagEdit && !this.readonly) {
          return this.getTranslate(`${className}.tags.${this.tag}`)
        }
      }
    }
  },

  watch: {
    tag() {
      this.watchValueHandler()
    }
  },

  methods: {
    watchProxyHandler() {
      return
    },

    watchValueHandler() {
      const value = cloneDeep(this._inputFilter(this.value))
      if (!isEqual(this.proxy, value)) {
        this.proxy = value
      }
    },

    clickHandler(type) {
      switch (type) {
        case 'reset': {
          this.$refs.mainComponent.reset()
          if (this.$refs.additionalComponent) {
            this.$refs.additionalComponent.reset()
          }
          break
        }
        case 'save': {
          this.$emit('input', this.proxy)
          this.$emit('show', false)
          break
        }
        case 'close': {
          this.$emit('show', false)
        }
      }
    }
  },

  render

}
