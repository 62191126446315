import { cloneDeep, isEqual } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'RequisitesFormAddress',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    address: {
      type: Object,
      default: () => ({})
    },

    matches: Boolean
  },

  data() {
    return {
      addressMatches: false
    }
  },

  computed: {
    compareAddresses() {
      let result = true
      for (const key in this.value) {
        if (this.value[key] !== this.address[key]) {
          result = false
        }
      }

      return result
    }
  },

  watch: {
    addressMatches() {
      if (this.addressMatches) {
        this.setAddress(cloneDeep(this.address))
      } else {
        this.setAddress(undefined)
      }
    },

    address: {
      handler() {
        if (this.matches && this.addressMatches && !isEqual(this.address, this.proxy)) {
          this.proxy = cloneDeep(this.address)
        }
      },
      deep: true
    }
  },

  mounted() {
    if (this.compareAddresses) {
      this.addressMatches = true
    }
  },

  methods: {
    setAddress(address) {
      this.proxy.country = address && address.country || this.proxy.country || undefined
      this.proxy.city = address && address.city || this.proxy.city || undefined
      this.proxy.index = address && address.index || this.proxy.index || undefined
      this.proxy.address = address && address.address || this.proxy.address || undefined
      this.proxy.phone = address && address.phone || this.proxy.phone || undefined
      this.proxy.phoneAdd = address && address.phoneAdd || this.proxy.phoneAdd || undefined
    }
  },

  render
}
