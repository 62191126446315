import { cloneDeep } from 'lodash'

import { EMPTY_PROVIDERGROUPS, EMPTY_ROUTINGRULES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, icons, inputModes, services, sizes } from '@/utils'

import button from '@/components/button'
import squircle from '@/components/icon/squircle'
import draggable from '@/components/misc/draggable'
import providerGroupsForm from '@/components/services/providerGroups/form'
import routingRulesForm from '@/components/services/routingRules/form'
import tag from '@/components/tag'

function renderColTitle(h, index, title) {
  return h(
    'div',
    {
      class: 'faic grid-gap--8 text--grey'
    },
    [
      h(
        'div',
        {
          class: 'index'
        },
        [ index ]
      ),
      h(
        'div',
        {
          class: 'body-1'
        },
        [ title ]
      )
    ]
  )
}

function renderSectionTitle(h) {
  return h(
    'div',
    {
      class: 'flex grid-gap--8'
    },
    [
      h(
        squircle,
        {
          props: {
            icon: icons.subtitles,
            size: sizes.medium,
            color: colors.grey,
            tooltip: this.getTranslate('routes.labels.title')
          }
        }
      ),
      h(
        components['text-field'],
        {
          props: {
            value: this.payload.title,
            label: this.getTranslate('routes.labels.title'),
            disabled: this.colsToShow > 1,
            mode: inputModes['line-label'],
            required: true,
            autofocus: true,
            dense: true,
            details: false,
            rounded: true,
            clearable: true
          },
          on: {
            input: event => {
              this.payload.title = event
            }
          }
        }
      )
    ]
  )
}

function renderRoutingRuleRow(h, item, index) {
  const readonly = !!item.id

  return h(
    'div',
    {
      class: 'faic grid grid-gap--8',
      style: { gridTemplateColumns: 'auto 1fr auto' }
    },
    [
      h(
        squircle,
        {
          class: 'drag_handle cursor--grab',
          props: {
            icon: icons.drag_handle,
            size: sizes.medium,
            color: colors.grey
          }
        }
      ),
      h(
        'div',
        {
          class: 'grid grid-gap--0',
          on: {
            click: () => {
              if (!readonly) {
                this.payloads.routingRules = item
                this.routingRuleIndex = index
                this.colsToShow++
              }
            }
          }
        },
        [
          h(
            'div',
            {
              class: 'body-1'
            },
            [ item.title ]
          ),
          h(
            'div',
            {
              class: {
                small: true,
                'text--grey': readonly,
                'text--warning': !item.id
              }
            },
            [ item.id || this.getTranslate('routingRules.hints.willBeCreated') ]
          )
        ]
      ),
      h(
        button,
        {
          props: {
            icon: icons.remove,
            mode: buttonModes.flat,
            color: colors.error
          },
          on: {
            click: () => {
              this.payload.RoutingRules.splice(index, 1)
            }
          }
        }
      )
    ]
  )
}
function renderSelectedRoutingRules(h) {
  if (Array.isArray(this.payload.RoutingRules) && this.payload.RoutingRules.length) {
    return h(
      draggable,
      {
        class: 'grid grid-gap--8',
        props: {
          value: this.payload.RoutingRules,
          handle: 'drag_handle'
        },
        scopedSlots: {
          item: (item, index) => {
            return renderRoutingRuleRow.call(this, h, item, index)
          }
        },
        on: {
          input: event => {
            this.payload.RoutingRules = event
          }
        }
      }
    )
  }
}

function renderSectionRoutingRules(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': 'auto 1fr' }
    },
    [
      h(
        squircle,
        {
          props: {
            icon: icons.rule,
            size: sizes.medium,
            color: colors.grey,
            tooltip: this.getTranslate('routes.subtitles.routingRules')
          }
        }
      ),
      h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          h(
            tag,
            {
              props: {
                label: this.getTranslate('routes.subtitles.routingRules'),
                size: sizes.huge
              }
            }
          ),
          h(
            components.select,
            {
              props: {
                label: this.getTranslate('routes.labels.routingRules'),
                service: services.routingRules,
                query: { isActive: true },
                itemsDisabled: this.payload.RoutingRules.map(({ id }) => id),
                disabled: this.colsToShow > 1,
                mode: inputModes['line-label'],
                dense: true,
                rounded: true,
                details: false,
                autocomplete: true
              },
              on: {
                input: event => {
                  this.addRoutingRuleFromSelect(event)
                }
              }
            }
          ),
          renderSelectedRoutingRules.call(this, h)
        ]
      )
    ]
  )
}

function renderFirstColContent(h) {
  if (this.colsToShow === 1) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        renderSectionTitle.call(this, h),
        renderSectionRoutingRules.call(this, h)
      ]
    )
  }
}
function renderFirstCol(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      key: 'first-col'
    },
    [
      renderColTitle.call(this, h, 1, this.getTranslate('routes.titles.full.create.route')),
      renderFirstColContent.call(this, h)
    ]
  )
}

function renderSecondColContent(h) {
  if (this.colsToShow === 2) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        h(
          routingRulesForm,
          {
            class: 'pa-3',
            props: {
              value: this.payloads.routingRules,
              disabled: this.colsToShow > 2,
              type: this.type
            },
            on: {
              input: event => {
                this.payloads.routingRules = event
              }
            }
          }
        )
      ]
    )
  }
}
function renderSecondCol(h) {
  if (this.colsToShow > 1) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        key: 'second-col'
      },
      [
        renderColTitle.call(this, h, 2, this.getTranslate('routes.titles.full.create.routingRules')),
        renderSecondColContent.call(this, h)
      ]
    )
  }
}

function renderThirdColContent(h) {
  return h(
    providerGroupsForm,
    {
      props: {
        value: this.payloads.providerGroups,
        type: this.type
      },
      on: {
        input: event => {
          this.payloads.providerGroups = event
        }
      }
    }
  )
}
function renderThirdCol(h) {
  if (this.colsToShow > 2) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        key: 'third-col'
      },
      [
        renderColTitle.call(this, h, 3, this.getTranslate('routes.titles.full.create.providerGroups')),
        renderThirdColContent.call(this, h)
      ]
    )
  }
}

function renderBody(h) {
  return h(
    'transition-group',
    {
      class: 'grid grid-gap--8 pa-2',
      props: { name: 'route-slide' }
    },
    [
      renderFirstCol.call(this, h),
      renderSecondCol.call(this, h),
      renderThirdCol.call(this, h)
    ]
  )
}

function renderFooter(h) {
  switch (this.colsToShow) {
    case 1: {
      return h(
        'div',
        {
          class: 'dialog__footer',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.$emit('showDialog', false)
                }
              }
            }
          ),
          h('div', { class: 'ff' }),
          h(
            button,
            {
              props: {
                icon: icons.add,
                label: this.getTranslate('routes.buttons.create.rule'),
                mode: buttonModes.flat,
                color: colors.secondary,
                disabled: this.colsToShow > 1
              },
              on: {
                click: () => {
                  this.colsToShow += 1
                }
              }
            }
          ),
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.create'),
                color: colors.primary,
                disabled: !this.validation.routingGroups
              },
              on: {
                click: () => {
                  this.create()
                }
              }
            }
          )
        ]
      )
    }
    case 2: {
      return h(
        'div',
        {
          class: 'dialog__footer',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                icon: icons.keyboard_arrow_left,
                label: this.getTranslate('misc.buttons.back'),
                mode: buttonModes.flat,
                disabled: this.colsToShow > 2
              },
              on: {
                click: () => {
                  this.payloads.routingRules = cloneDeep(EMPTY_ROUTINGRULES)
                  this.colsToShow -= 1
                }
              }
            }
          ),
          h('div', { class: 'ff' }),
          h(
            button,
            {
              props: {
                icon: icons.add,
                label: this.getTranslate('routes.buttons.create.group'),
                mode: buttonModes.flat,
                color: colors.secondary,
                disabled: this.colsToShow > 2
              },
              on: {
                click: () => {
                  this.colsToShow += 1
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('routes.buttons.inRoute'),
                color: colors.primary,
                disabled: !this.validation.routingRules || this.colsToShow > 2
              },
              on: {
                click: () => {
                  this.addRoutingRuleFromForm()
                }
              }
            }
          )
        ]
      )
    }
    case 3: {
      return h(
        'div',
        {
          class: 'dialog__footer',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                mode: buttonModes.flat,
                icon: icons.keyboard_arrow_left,
                label: this.getTranslate('misc.buttons.back')
              },
              on: {
                click: () => {
                  this.payloads.providerGroups = cloneDeep(EMPTY_PROVIDERGROUPS)
                  this.colsToShow -= 1
                }
              }
            }
          ),
          h('div', { class: 'ff' }),
          h(
            button,
            {
              props: {
                label: this.getTranslate('routes.buttons.inRule'),
                color: colors.primary,
                disabled: !this.validation.providerGroups
              },
              on: {
                click: () => {
                  this.addProviderGroupToRuleFromForm()
                }
              }
            }
          )
        ]
      )
    }
  }
}

export default function(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate('routes.titles.full.title'),
        value: this.show,
        rounded: this.viewport.breakpoint.mdUp,
        overflow: this.viewport.breakpoint.mdUp,
        minHeight: this.viewport.breakpoint.mdUp ? 600 : undefined,
        width: this.viewport.breakpoint.mdUp ? 400 : undefined
      },
      on: {
        input: event => {
          this.$emit('showDialog', event)
        }
      }
    },
    [
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
