import Vue from 'vue'

import { buttonModes, colors, components, icons, inputModes, isObjectEmpty, popperPlacements, services, sizes } from '@/utils'

import button from '@/components/button'
import draggable from '@/components/misc/draggable'
import messageRuleGroupsCreateDirect from '@/components/services/messageRuleGroups/create/direct'
import messageRuleGroupsCreateFull from '@/components/services/messageRuleGroups/create/full'
import messageRulesActions from '@/components/services/users/messageProcessing/actions'
import textOverflow from '@/components/text/overflow'
import routesConditions from '@/components/trees/routes/conditions'

function renderGroupSelector(h) {
  return h(
    components.select,
    {
      props: {
        value: this.groupSelector,
        label: this.getTranslate(`${services.messageRuleGroups}.labels.group`),
        itemsDisabled: this.proxy.map(({ id }) => id),
        service: services.messageRuleGroups,
        query: { isActive: true },
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        autocomplete: true,
        itemsWithTooltips: true,
        details: false
      },
      on: {
        input: event => {
          this.messageRuleGroupsEventProcessing(event)
        }
      }
    }
  )
}
function renderAddMenu(h) {
  return h(
    components.menu,
    {
      props: {
        rounded: true,
        offsetDistance: 8,
        placement: popperPlacements['bottom-end']
      }
    },
    [
      h(
        button,
        {
          props: {
            mode: buttonModes.flat,
            icon: icons.add,
            color: colors.secondary
          },
          slot: 'activator'
        }
      ),

      h(
        components.list,
        {
          props: {
            dense: true,
            rounded: true
          }
        },
        [ 'full', 'direct' ].map(type => {
          return h(
            components['list-item'],
            {
              props: {
                label: this.getTranslate(`${services.messageRuleGroups}.dialogs.types.${type}`),
                dense: true
              },
              on: {
                click: () => {
                  this.showDialog[type] = true
                }
              }
            }
          )
        })
      ),

      h(
        messageRuleGroupsCreateFull,
        {
          props: {
            show: this.showDialog.full
          },
          on: {
            input: event => {
              this.messageRuleGroupsEventProcessing(event)
            },
            show: event => {
              this.showDialog.full = event
            }
          }
        }
      ),
      h(
        messageRuleGroupsCreateDirect,
        {
          props: {
            value: this.showDialog.direct
          },
          on: {
            id: event => {
              this.messageRuleGroupsEventProcessing(event)
            },
            input: event => {
              this.showDialog.direct = event
            }
          }
        }
      )
    ]
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.messageRuleGroups}-tree__header`
    },
    [
      renderGroupSelector.call(this, h),
      renderAddMenu.call(this, h)
    ]
  )
}

function renderExpand(h) {
  if (this.proxy.length) {
    return h(
      'div',
      {
        class: `${services.messageRuleGroups}-tree__expand`
      },
      [
        h(
          button,
          {
            props: {
              label: this.expanded ? this.getTranslate('commons.collapse.all') : this.getTranslate('commons.expand.all'),
              icon: this.expanded ? icons.expand_less : icons.expand_more,
              mode: buttonModes.flat,
              loading: this.expandLoading
            },
            on: {
              click: () => {
                this.expandAll()
              }
            }
          }
        )
      ]
    )
  }
}

function renderRow(h, options = {}) {
  return h(
    'div',
    {
      class: {
        [`${services.messageRuleGroups}-tree__row`]: true,
        [`${services.messageRuleGroups}-tree__row--${this.getRowState(options.service, options.id)}`]: true
      },
      on: {
        click: event => {
          this.clickRow(event, options)
        }
      }
    },
    [
      h(
        textOverflow,
        {
          class: `${services.messageRuleGroups}-tree__title`,
          props: { value: options.title }
        }
      ),
      h(
        textOverflow,
        {
          class: `${services.messageRuleGroups}-tree__info`,
          props: { value: options.info }
        }
      )
    ]
  )
}

function renderEditButton(h, service, id) {
  return h(
    button,
    {
      class: { 'hover-child': this.viewport.breakpoint.mdUp },
      props: {
        icon: icons.edit,
        mode: buttonModes.flat,
        size: sizes.small
      },
      on: {
        click: () => {
          Vue.router.push(`/admin/messageProcessing/${service}/${id}`)
        }
      }
    }
  )
}
function renderRemoveButton(h, id) {
  return h(
    components.menu,
    {
      props: {
        value: this.showConfirmRemoveMenu[id],
        rounded: true,
        offsetDistance: 8,
        placement: popperPlacements['bottom-end']
      }
    },
    [
      h(
        button,
        {
          props: {
            mode: buttonModes.flat,
            icon: icons.remove,
            color: colors.error,
            size: this.viewport.breakpoint.lgUp ? sizes.small : sizes.medium,
            tooltip: this.getTranslate('misc.buttons.remove')
          },
          key: id,
          on: {
            click: event => {
              event.stopPropagation()
              Vue.set(this.showConfirmRemoveMenu, id, true)
            }
          },
          slot: 'activator'
        }
      ),

      h(
        'div',
        {
          class: 'flex grid-gap--8 pa-2'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                size: sizes.small,
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  Vue.set(this.showConfirmRemoveMenu, id, false)
                }
              }
            }
          ),
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.remove'),
                size: sizes.small,
                mode: buttonModes.flat,
                color: colors.error
              },
              on: {
                click: () => {
                  this.removeMessageRuleGroup(id)
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderConditions(h, conditions) {
  if (Array.isArray(conditions) && conditions.length) {
    return h(routesConditions, { props: { value: conditions } })
  }
}
function renderActions(h, actions) {
  if (!isObjectEmpty(actions)) {
    return h(
      messageRulesActions,
      {
        props: {
          value: Object.keys(actions).map(tag => {
            return {
              tag,
              value: actions[tag]
            }
          })
        }
      }
    )
  }
}
function renderMessageRules(h, parentId) {
  if (Array.isArray(this.MessageRules[parentId]) && this.MessageRules[parentId].length) {
    return this.MessageRules[parentId].map(({ id, title, conditions, actions }) => {
      return h(
        components.card,
        {
          class: `${services.messageRuleGroups}-tree__level ${services.messageRuleGroups}-tree__level--2`,
          props: {
            title: this.getTranslate(`${services.messageRules}.one`),
            outline: true,
            rounded: true
          }
        },
        [
          h(
            'div',
            {
              class: {
                [`${services.messageRuleGroups}-tree__section`]: true,
                'hover-parent hover-parent--opacity': true
              }
            },
            [
              h(
                'div',
                {
                  class: {
                    [`${services.messageRuleGroups}-tree__row`]: true,
                    [`${services.messageRuleGroups}-tree__row--childless`]: true
                  }
                },
                [
                  h(
                    textOverflow,
                    {
                      class: `${services.messageRuleGroups}-tree__title`,
                      props: { value: title }
                    }
                  )
                ]
              ),

              renderEditButton.call(this, h, services.messageRules, id)
            ]
          ),

          h(
            'div',
            {
              class: `${services.messageRuleGroups}-tree__content`
            },
            [
              renderConditions.call(this, h, conditions),
              renderActions.call(this, h, actions)
            ]
          )
        ]
      )
    })
  }
}

function renderContent(h) {
  if (this.proxy.length) {
    return h(
      draggable,
      {
        class: `${services.messageRuleGroups}-tree`,
        props: { value: this.proxy },
        scopedSlots: {
          item: ({ id, title }) => {
            return h(
              components.card,
              {
                class: `${services.messageRuleGroups}-tree__level ${services.messageRuleGroups}-tree__level--1`,
                props: {
                  title: this.getTranslate(`${services.messageRuleGroups}.one`),
                  outline: true,
                  rounded: true
                },
                key: id
              },
              [
                h(
                  'div',
                  {
                    class: {
                      [`${services.messageRuleGroups}-tree__section`]: true,
                      'hover-parent hover-parent--opacity': true,
                      'hover--active': this.showConfirmRemoveMenu[id]
                    }
                  },
                  [
                    renderRow.call(this, h, {
                      id,
                      title,
                      service: services.messageRuleGroups,
                      click: () => this.getEntities(services.messageRuleGroups, 'MessageRules', id),
                      dblclick: () => this.getAllEntities(services.messageRuleGroups, 'MessageRules', id)
                    }),

                    h(
                      'div',
                      {
                        class: {
                          'flex grid-gap--8': true,
                          'hover-child': this.viewport.breakpoint.mdUp
                        }
                      },
                      [
                        renderEditButton.call(this, h, services.messageRuleGroups, id),
                        renderRemoveButton.call(this, h, id)
                      ]
                    )
                  ]
                ),

                h(
                  'div',
                  {
                    class: {
                      [`${services.messageRuleGroups}-tree__box`]: true,
                      [`${services.messageRuleGroups}-tree__box--${this.getRowState(services.messageRuleGroups, id)}`]: true
                    }
                  },
                  [ renderMessageRules.call(this, h, id) ]
                )
              ]
            )
          }
        },
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    components.card,
    {
      class: `${services.messageRuleGroups}-tree__holder`,
      props: {
        outline: true,
        rounded: true
      }
    },
    [
      renderHeader.call(this, h),
      renderExpand.call(this, h),
      renderContent.call(this, h)
    ]
  )
}
