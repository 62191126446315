import { isEqual } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

const defaultTag = 'mccmnc'

export default {
  name: 'Conditions',

  mixins: [ proxy({ type: 'array' }) ],

  props: {
    type: String,
    readonly: Boolean
  },

  data() {
    return {
      showDialog: false,
      tag: undefined,
      index: 0
    }
  },

  mounted() {
    this.transmitValue()
  },

  methods: {
    add() {
      this.proxy.push({
        tag: defaultTag,
        value: undefined
      })
      this.index = this.proxy.length - 1
      this.tag = defaultTag
      this.showDialog = true
    },
    remove(index) {
      this.proxy.splice(index, 1)
    },

    _inputFilter(data) {
      if (data.length === 0) {
        data.push({
          tag: defaultTag,
          value: undefined
        })
      }

      return data
    },

    transmitValue() {
      if (!isEqual(this.proxy, this.value)) {
        this.$emit('input', this.proxy)
      }
    }
  },

  render
}
