export const types = {
  sms: 'SMS',
  smsping: 'SMS ping',
  mms: 'MMS',
  viber: 'Viber',
  voice: 'Voz',
  whatsapp: 'WhatsApp',
  email: 'E-mail',
  vk: 'VKontakte',
  ok: 'Odnoklassniki',
  push: 'Push',
  flashcall: 'Flash call',
  telegram: 'Telegram',
  tgBot: 'Bot de Telegram',
  facebook: 'Facebook',
  instagram: 'Instagram',
  subtract: 'Deducción',
  whatsappchat: 'Chat de WhatsApp',
  viberchat: 'Chat de Viber',
  vkchat: 'Chat de VKontakte',
  telegramchat: 'Chat de Telegram',
  facebookchat: 'Chat de Facebook',
  accrual: 'Acreditación',
  web: 'Web',
  incoming_wapi: 'WAPI',
  mobileid_mts_push: 'Mobile ID MTS Push',
  mobileid_mts_push_sms: 'Mobile ID MTS Push SMS',
  incoming_tg_bot: 'Bot de Telegram',
  hlr: 'HLR',
  apisender: 'WAPI',
  web: 'Web',
  tgBot: 'Bot de Telegram',
  accrual: 'Acreditación',
  subtract: 'Deducción',

  subscription: {
    apisender: 'WAPI'
  }
}

export const labels = {
  title: 'Nombre de la campaña',
  message: 'Mensaje',
  recipient: 'Destinatario',
  type: 'Tipo de mensaje',
  file: 'Archivo',
  sender: 'Remitente',
  sendername: 'Nombre del remitente',
  allowSending: 'Tipos de envíos permitidos',
  text: 'Texto',
  image: 'Enlace a la imagen',
  images: 'Imágenes',
  hash: 'Hash',
  service: 'Servicio',
  provider: 'Proveedor',
  defaultRoute: 'Ruta predeterminada',
  ip: 'IP',
  headers: 'Encabezados',
  body: 'Cuerpo',
  params: 'Parámetros',
  hostname: 'Nombre del host',
  request: 'Solicitud',
  response: 'Respuesta',
  server: 'Servidor',
  method: 'Método',
  uri: 'Enlace',
  statusCode: 'Código de estado',
  preset: 'Preajuste',
  encoding: 'Codificación',
  asOwner: 'En nombre del propietario del mensaje',
  button: {
    subheader: 'Botón',
    url: 'Enlace del botón',
    text: 'Texto del botón',
    action: 'Acción del botón'
  },
  target: 'sin direcciones | una dirección | {count} direcciones',
  targets: {
    include: 'Direcciones para enviar',
    exclude: 'Direcciones para excluir',
    info: 'Seleccione libretas de direcciones o ingrese números individuales (no más de 100 números)'
  },
  schedule: {
    delay: 'Inicio del envío',
    until: 'Extender hasta la fecha'
  },
  $options: {
    onStatus: 'Por estado',
    onTimeout: 'Por tiempo de espera',
    onIncomingPayload: 'Por entrada'
  },
  options: {
    status: 'El mensaje anterior recibió el estado',
    timeout: 'Pasado',
    except: 'Y el estado del mensaje anterior no es igual a',
    onIncomingPayload: {
      text: 'Si el texto del mensaje entrante es igual a'
    }
  },
  template: 'Plantilla',
  id: 'ID',
  chainId: 'ID de cadena',
  groupId: 'ID de grupo',
  owner: 'Propietario',
  Owner: 'Propietario',
  OwnerId: 'Propietario',
  TariffId: 'Tarifa',
  dursec: 'Llamada (total)',
  billsec: 'Llamada (facturación)',
  pattern: 'Plantilla para moderación',
  status: 'Estado',
  time: 'Hora',
  source: 'Fuente',
  amount: 'Cantidad',
  payload: {
    sender: 'Remitente',
    recipient: 'Destinatario',
    text: 'Texto',
    image: 'Imagen',
    file: 'Archivo',
    tts: 'TTS',
    button: {
      url: 'URL del botón',
      text: 'Texto del botón'
    }
  },
  state: {
    detailed: {
      ids: 'IDs detallados'
    },
    status: 'Estado'
  },
  meta: {
    _recipientData: 'Operador',
    _routing: {
      provider: 'Proveedor'
    },
    billing: {
      id: 'ID de facturación',
      amount: 'Cantidad'
    },
    billings: {
      ids: 'ID de facturación'
    },
    stats: {
      segments: 'Segmentos',
      characters: 'Caracteres'
    },
    replacement: {
      payload: {
        sender: 'Reemplazo del remitente',
        text: 'Reemplazo del texto'
      }
    }
  },
  data: {
    price: 'Precio',
    multiplier: 'Multiplicador'
  },

  external: {
    id: 'ID externo'
  }
}

export default {
  title: 'Informe detallado',

  sending: 'Envío',
  sendings: 'Envíos',
  new: 'Crear envío',
  noData: 'No se encontraron mensajes',
  targets: 'Direcciones',
  messages: 'Mensajes',
  preview: 'Vista previa',
  create: 'Crear envío',
  type: 'Tipo de envío',
  uploadImageText: 'Arrastre la imagen aquí<br>o<br>haga clic para seleccionar un archivo',
  getImageKey: 'Obtener código de imagen',
  count: 'sin envíos | un nuevo envío | {count} nuevos envíos',
  insert: 'Insertar',

  labels,

  headers: {
    status: 'Estado',
    owner: 'Propietario',
    provider: 'Proveedor',
    type: 'Tipo',
    recipient: 'Destinatario',
    sender: 'Remitente',
    text: 'Texto',
    amount: 'Costo',
    operator: 'Operador',
    count_sent: 'En&nbsp;proceso',
    count_delivered: 'Entregado',
    percent_delivered: 'Porcentaje de entregados',
    count_seen: 'Visto',
    count_failed: 'Fallido',
    count_total: 'Total',
    opgroup: 'Grupo de operador',
    operatorGroup: 'Grupo de operador',
    mcc: 'mcc',
    mnc: 'mnc',
    sending: 'Envío a',
    fallback: 'Reenvío a',
    tariff: 'Tarifa',
    from: 'De',
    billing_tariff_price: 'Precio',
    billing_sum_all: 'Suma total',
    billing_sum_pending_complete: 'Suma de completados',
    billing_sum_refunded_invalid: 'Suma de reembolsados',
    updatedAt: 'Actualizado en',
    createdAt: 'Creado en'
  },

  titles: {
    preview: 'Vista previa',
    filter: 'Filtro',
    sudoData: 'Datos',
    resend: 'Reenvío',
    incoming: {
      details: 'Mensajes entrantes'
    },
    replacement: {
      original: 'Original',
      rule: 'Por regla',
      provider: 'Por proveedor'
    }
  },

  filter: {
    attributes: 'Atributos',
    id: 'ID',
    groupId: 'ID de grupo',
    chainId: 'ID de cadena',
    title: 'Filtro',
    status: 'Estado',
    type: 'Tipo',
    OwnerId: 'Propietario',
    from: 'De',
    pattern: 'Patrón',
    payload: {
      recipient: 'Destinatario',
      sender: 'Remitente'
    },
    meta: {
      _routing: {
        provider: 'Proveedor'
      },
      user: {
        id: 'Propietario'
      }
    },
    state: {
      status: 'Estado'
    },
    date: {
      from: 'Fecha desde',
      by: 'Fecha hasta'
    },
    time: {
      from: 'Hora desde',
      by: 'Hora hasta'
    },
    clear: 'Limpiar filtro'
  },

  hints: {
    date: {
      from: 'AAAA-MM-DD',
      by: 'AAAA-MM-DD'
    },
    time: {
      from: 'HH:MM',
      by: 'HH:MM'
    },
    button: {
      url: 'Máximo 2048 caracteres, ejemplo: http://www.example.com',
      text: 'Máximo 30 caracteres'
    },
    text: 'Ver el texto completo del mensaje',
    empty: 'Para obtener datos, utilice el filtro.',
    remoteReport: 'Su informe ha sido enviado al correo electrónico que proporcionó al registrarse.',
    sale: 'Venta',
    purchase: 'Compra',
    has: {
      replacement: 'Hay reemplazo'
    },
    resend: 'Por defecto, el mensaje será enviado en nombre del usuario actual.',
    apisender: 'Se encontró una instancia vinculada al nombre del remitente:',
    replacement: {
      original: 'Contenido original del mensaje',
      rule: 'Resultado del reemplazo por reglas',
      provider: 'Contenido enviado al proveedor'
    }
  },

  presets: {
    sendings: {
      details: 'Informe detallado de envíos',
      group001: 'Agrupación por tipo de envío',
      group002: 'Agrupación por grupo de operador y proveedor',
      group003: '¡El informe más detallado del mundo!'
    }
  },

  statistic: {
    title: 'Estadísticas',
    symbols: 'Símbolos: {count}',
    parts: 'Partes: {count}',
    segments: 'Segmentos: {count}'
  },

  statuses: {
    pending: 'Pendiente',
    paused: 'Pausado',
    processing: 'En proceso',
    sent: 'Enviado',
    delivered: 'Entregado',
    seen: 'Visto',
    failed: 'Fallido'
  },

  report: {
    download: 'Descargar archivo',
    get: 'Obtener informe'
  },

  attributes: {
    id: 'ID',
    chainId: 'ID de cadena',
    type: 'Tipo',
    userId: 'Usuario',
    providerId: 'Proveedor',
    operatorCode: 'Código de operador',
    operatorGroup: 'Grupo de operador',
    text: 'Texto',
    sender: 'Remitente',
    recipient: 'Destinatario',
    error: 'Error',
    status: 'Estado'
  },

  status: {
    succeed: 'Exitoso',
    failed: 'No entregado',
    pending: 'Pendiente',
    processing: 'Procesando',
    queued: 'En cola',
    paused: 'Pausado',
    sent: 'Enviado',
    processed: 'Procesado',
    delivered: 'Entregado',
    seen: 'Visto'
  },

  subheaders: {
    scheduling: 'Programación',
    options: 'Opciones de reenvío',
    resend: 'Enviar este mensaje si:'
  },

  confirm: {
    send: {
      title: 'Está a punto de enviar ',
      count: 'cero mensajes | un mensaje | {count} mensajes',
      text: 'Revise los destinatarios, el nombre del remitente, el texto y otra información antes de continuar.',
      loading: 'Estamos preparando su envío. Esto tomará un poco de tiempo.'
    }
  },

  tooltips: {
    preview: 'Vista previa del mensaje',
    count: '{count} mensajes',
    save: 'Guardar como plantilla',
    planning: 'Planificación',
    insert: 'Insertar variable',
    billing_sum_pending_complete: 'Suma de completados y pendientes',
    billing_sum_refunded_invalid: 'Suma de reembolsados y fallidos',
    refresh: 'Actualizar datos de la tabla aplicando el filtro seleccionado.',
    segments: 'Longitud del mensaje | Segmentos',
    billSec: 'Segundos de facturación',
    approve: 'Aprobar',
    reject: 'Rechazar',
    moderation: 'En moderación',

    tasks: {
      filter: {
        owner: 'Ir a la pestaña de salientes y agregar al filtro el propietario de la tarea actual'
      }
    }
  },

  filterMenu: {
    any: 'Cualquier dato',
    string: 'Caracteres rusos sin espacios',
    phone: 'Teléfono',
    email: 'Correo electrónico',
    date: 'Fecha dd.mm.aa'
  },

  catchSymbolsMenu: {
    symbols: 'Letras',
    numbers: 'Números',
    symbolsNumbers: 'Letras o números',
    symbolsNumbersSpaces: 'Letras, números o espacios',
    russianSymbols: 'Letras rusas',
    russianSymbolsNumber: 'Letras rusas o números',
    russianSymbolsNumbersSpaces: 'Letras rusas, números o espacios'
  },

  tabs: {
    format: 'Formateados',
    source: 'Originales',
    create: 'Nuevo mensaje',
    templates: 'Plantillas',
    sendings: 'Envíos',
    outgoing: 'Salientes',
    incoming: 'Entrantes',
    tasks: 'Tareas',
    logs: 'Registros',
    routing: 'Enrutamiento',
    billings: 'Facturas',
    fallbacks: 'Reenvíos',
    replacement: 'Reemplazo',
    sendingsModeration: 'Moderación',
    eventsHistory: 'Historial de eventos'
  },

  types,

  contents: {
    noAllowedSendingTypes: 'Actualmente no puede enviar ningún tipo de mensaje.<br>Por favor, contacte al administrador para configurar su cuenta.',
    noPattern: 'Plantilla no encontrada',
    confirm: {
      resend: 'Confirme el reenvío del mensaje.'
    }
  },

  errors: {
    payload: {
      button: {
        url: '¡El campo url no coincide con el patrón requerido!'
      }
    },
    recipients: {
      include: 'Las direcciones para enviar son obligatorias'
    },
    button: {
      url: 'Límite de caracteres excedido',
      text: 'Límite de caracteres excedido'
    }
  },

  schedule: {
    until: {
      0: 'No extender',
      1: 'Extender por 1 hora',
      2: 'Extender por 2 horas',
      3: 'Extender por 3 horas',
      4: 'Extender por 4 horas',
      5: 'Extender por 5 horas',
      6: 'Extender por 6 horas',
      7: 'Extender por 7 horas',
      8: 'Extender por 8 horas',
      9: 'Extender por 9 horas',
      10: 'Extender por 10 horas',
      11: 'Extender por 11 horas',
      12: 'Extender por 12 horas'
    }
  },

  buttons: {
    refresh: 'Actualizar'
  },

  snackbars: {
    created: 'Envío creado con éxito.',
    updated: 'Envío actualizado con éxito.',
    removed: 'Envío eliminado con éxito.'
  },

  warnings: {
    own: 'Para enviar un SMS de prueba sin moderación, <strong>¡no lo cambie!</strong><br><br>Para poder enviar mensajes con cualquier texto, comuníquese con su gerente o soporte técnico.',
    imlink: 'Estimado Cliente,<br>Para enviar un mensaje de prueba, por favor contacte a su AM <strong><a href="mailto:sale@imlink.io">sale@imlink.io</a></strong> o al Equipo de Soporte <strong><a href="mailto:support@imlink.io">support@imlink.io</a></strong>.'
  }
}
