import { buttonModes, colors, components, inputModes, services, states } from '@/utils'

import button from '@/components/button'
import info from '@/components/info'

function renderProviderField(h) {
  return h(
    components.select,
    {
      props: {
        value: this.provider,
        label: this.getTranslate('routes.labels.direct.provider.choose'),
        service: services.providers,
        query: {
          type: this.type,
          isActive: true
        },
        mode: inputModes['line-label'],
        rounded: true,
        details: false,
        autocomplete: true
      },
      on: {
        input: event => {
          this.provider = event
        }
      }
    }
  )
}

function renderItem(h, { title, id }) {
  return h(
    components['list-item'],
    {
      class: 'cursor--pointer',
      props: {
        dense: true,
        rounded: true,
        label: title
      },
      on: {
        click: () => {
          this.$emit('input', id)
          this.clear()
          this.$emit('showDialog', false)
        }
      }
    }
  )
}

function renderItems(h) {
  return h(
    components.list,
    {
      props: {
        dense: true,
        rounded: true
      }
    },
    [ this.restData.routingGroups.find.data.map(item => renderItem.call(this, h, item)) ]
  )
}

function renderWarning(h) {
  if (!this.responsibility) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        h(
          info,
          {
            props: {
              value: this.getTranslate('routes.hints.direct.choose'),
              color: colors.warning
            }
          }
        ),

        renderItems.call(this, h)
      ]
    )
  }
}

function renderRoutingGroups(h) {
  if (this.restData.routingGroups.find.state === states.ready) {
    if (this.restData.routingGroups.find.pagination.total) {
      return h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          h(
            components.switch,
            {
              props: {
                value: this.responsibility,
                label: this.getTranslate('routes.labels.direct.responsibility'),
                outline: true
              },
              on: {
                input: event => {
                  this.responsibility = event
                }
              }
            }
          ),

          renderWarning.call(this, h)
        ]
      )
    }
  }
}

function renderBody(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pt-2 px-2'
    },
    [
      renderProviderField.call(this, h),
      renderRoutingGroups.call(this, h)
    ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'dialog__footer',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat,
            disabled: this.loading
          },
          on: {
            click: () => {
              this.clear()
              this.$emit('showDialog', false)
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.create'),
            color: colors.primary,
            loading: this.loading,
            disabled: this.disabled
          },
          on: {
            click: () => {
              this.create()
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate('routes.titles.direct.title'),
        value: this.value,
        rounded: this.viewport.breakpoint.mdUp,
        overflow: this.viewport.breakpoint.mdUp,
        maxWidth: this.viewport.breakpoint.mdUp ? 500 : undefined,
        minHeight: this.viewport.breakpoint.mdUp ? 350 : undefined
      },
      on: {
        input: event => {
          this.$emit('showDialog', event)
        }
      }
    },
    [
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
