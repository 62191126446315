import { components, inputModes } from '@/utils'

import generatorRow from '@/components/generator/row'
import { className } from '@/components/misc/rules/utils'

function renderItem(h, item) {
  return h(
    components.textarea,
    {
      props: {
        value: item.text,
        label: this.getTranslate(`${className}.labels.text`),
        mode: inputModes['line-label'],
        defaultValue: '',
        details: false,
        grow: true,
        dense: true,
        rounded: true,
        clearable: true
      },
      on: {
        input: event => {
          item.text = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    generatorRow,
    {
      props: {
        value: this.proxy,
        defaultValue: { text: undefined }
      },
      scopedSlots: {
        row: ({ item }) => {
          return renderItem.call(this, h, item)
        }
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
