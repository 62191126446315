import { getPrice } from '@sigma-frontend-libs/commons'

import { components, inputModes, positiveNumberRegex, services } from '@/utils'

import sticker from '@/components/misc/sticker'

function renderItem(h, item, index) {
  if (this.readonly) {
    return h(
      sticker,
      {
        props: {
          value: getPrice(item),
          label: this.hint[index]
        }
      }
    )
  } else {
    return h(
      components['text-field'],
      {
        props: {
          value: item,
          suffix: this.getTranslate('currency.RUB.symbol.unicode'),
          label: this.hint[index],
          mode: inputModes['outline-label'],
          dense: true,
          rounded: true,
          details: false,
          mask: positiveNumberRegex,
          processValue: value => value?.toString().replace(',', '.')
        },
        on: {
          input: event => {
            this.proxy[index] = event
          }
        }
      }
    )
  }
}
function renderItems(h) {
  return this.proxy.map((item, index) => {
    return renderItem.call(this, h, item, index)
  })
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        [`${services.tariffs}-rule__prices`]: true,
        [`${services.tariffs}-rule__prices--readonly`]: this.readonly
      }
    },
    [ renderItems.call(this, h) ]
  )
}
