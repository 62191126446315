export default {
  title: 'YClients',

  titles: {
    create: 'Crear salón'
  },

  labels: {
    salon: 'ID del salón',
    token: 'Token',
    isActive: 'Activo',
    owner: 'Propietario',
    error: 'Error',
    errorIsEmpty: 'No hay errores'
  },

  tooltips: {
    active: 'Activo',
    notActive: 'No activo',
    filter: {
      isActive: 'Mostrar registros inactivos'
    },
    updateStatus: 'Actualizar estado'
  },

  snackbars: {
    created: 'Salón creado',
    updated: 'Salón actualizado',
    removed: 'Salón eliminado'
  }
}
