export default {
  title: 'Pago rápido',

  labels: {
    amount: 'Cantidad',
    method: 'Método'
  },

  methods: {
    v1: {
      tinkoff: 'Tinkoff'
    }
  },

  hints: {
    commission: 'Comisión del banco: 3%',
    commissions: 'Comisión'
  },

  errors: {
    owner: 'Para el pago, el enlace debe contener el ID del destinatario.'
  }
}
