import { cloneDeep } from 'lodash'

import { PROVIDER_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import { inputFilter, outputFilter } from '@/components/services/inactiveProviders/utils'
import { serviceName } from '@/components/services/providerGroups/utils'

import render from './render'

const limit = 100

export default {
  name: 'Template',

  mixins: [
    generateServices([
      {
        name: serviceName,

        inputFilter,
        outputFilter,

        find: {
          defaultFilter: {
            isActive: true,
            $scope: [ 'Providers' ]
          },
          defaultOrder: { createdAt: 'desc' },
          defaultPagination: { limit }
        },
        get: {
          params: {
            query: {
              $scope: [ 'Providers' ]
            }
          }
        },

        create: false,
        update: false,
        remove: false
      }
    ])
  ],

  data() {
    return {
      providerGroups: [],
      providerGroupsLoading: false,
      providerGroup: {},

      providers: [],
      providersLoading: false,

      search: undefined,
      showUpdateDialog: false,
      showProvidersDialog: false,

      check: {
        update: false,
        showUpdateDialog: false
      }
    }
  },

  computed: {
    computedProviderGroups() {
      if (this.providerGroups.length) {
        const providerGroups = cloneDeep(this.providerGroups)
        providerGroups.sort((a, b) => {
          const aCreatedAt = new Date(a.createdAt)
          const bCreatedAt = new Date(b.createdAt)

          return bCreatedAt - aCreatedAt
        })

        return providerGroups.filter(providerGroup => {
          const hasInactive = providerGroup.Providers.some(provider => provider.state === PROVIDER_STATUS.inactive)
          if (hasInactive) {
            if (this.search) {
              const search = this.search.toLowerCase()
              const regex = new RegExp(search, 'g')
              for (const key of [ 'id', 'title' ]) {
                const value = providerGroup[key]
                if (typeof value === 'string') {
                  if (regex.test(value.toLowerCase())) {
                    return true
                  }
                }
              }

              return false
            }

            return true
          }

          return false
        })
      }

      return []
    },

    computedProviders() {
      const providers = cloneDeep(this.providers)
      providers.sort((a, b) => (a.priority || 0) - (b.priority || 0))

      return providers
    }
  },

  methods: {
    async getProviders(providerGroup) {
      try {
        this.providersLoading = true
        const id = providerGroup && providerGroup.id || this.providerGroup.id
        const response = await this.rest[serviceName].get(id, { query: { t: Date.now() } })
        const clone = cloneDeep(response)
        if (clone) {
          this.providerGroup = clone
          this.providers = clone.Providers
          this.showProvidersDialog = true
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.providersLoading = false
      }
    },

    async getProviderGroups() {
      try {
        this.providerGroupsLoading = true
        this.showUpdateDialog = false
        this.providerGroups = []

        const providerGroups = []
        const firstResponse = await this.rest[serviceName].find()
        if (firstResponse) {
          providerGroups.push(...firstResponse)
          while (this.restData[serviceName].find.pagination.total > providerGroups.length) {
            const secondResponse = await this.rest[serviceName].find({ query: { $offset: providerGroups.length } })
            providerGroups.push(...secondResponse)
          }

          if (providerGroups.length) {
            this.providerGroups.push(...providerGroups)
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.providerGroupsLoading = false
      }
    }
  },

  render
}
