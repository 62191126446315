import { components, services, states } from '@/utils'

import selectorOwner from '@/components/selector/owner'
import company from '@/components/services/documentTemplates/generate/form/common/company'
import requisite from '@/components/services/documentTemplates/generate/form/common/requisite'

function renderOwner(h) {
  if (!this.OwnerId) {
    return h(
      selectorOwner,
      {
        props: {
          value: this.proxy.customerId,
          disabled: this.loading,
          combobox: true,
          required: true
        },
        on: {
          input: event => {
            this.proxy.customerId = event
          }
        }
      }
    )
  }
}

function renderRequisite(h) {
  if (this.customerId) {
    return h(
      requisite,
      {
        props: {
          value: this.proxy.requisiteId,
          items: this.restData[services.requisites].find.data,
          loading: this.restData[services.requisites].find.state === states.loading,
          disabled: this.restData[services.requisites].find.state === states.loading || this.loading
        },
        on: {
          input: event => {
            this.proxy.requisiteId = event
          }
        }
      }
    )
  }
}

function renderCompany(h) {
  if (this.customerId && this.proxy.requisiteId) {
    return h(
      company,
      {
        props: {
          value: this.proxy.companyId,
          items: this.restData[services.companies].find.data,
          loading: this.restData[services.companies].find.state === states.loading,
          disabled: this.restData[services.companies].find.state === states.loading || this.loading
        },
        on: {
          input: event => {
            this.proxy.companyId = event
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    components.form,
    {
      class: 'grid'
    },
    [
      renderOwner.call(this, h),
      renderRequisite.call(this, h),
      renderCompany.call(this, h)
    ]
  )
}
