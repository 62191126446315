import { inputModes, services } from '@/utils'

import rulesTemplate from '@/components/misc/rules/template'
import { ruleTags, ruleTypes } from '@/components/misc/rules/utils'

function renderRules(h) {
  return h(
    rulesTemplate,
    {
      props: {
        value: this.proxy.rules,
        whitelist: [ ruleTags.sender, ruleTags.text, ruleTags.mccmnc ],
        ruleType: ruleTypes.conditions
      },
      on: {
        input: event => {
          this.proxy.rules = event
        }
      }
    }
  )
}

function renderReplacement(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--8 pt-3 px-2 pb-2',
      props: {
        title: this.getTranslate(`${services.providers}.hints.replacement`),
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [
      h(
        'div',
        {
          class: 'grid'
        },
        [
          h(
            'g-select',
            {
              props: {
                value: this.proxy.replacement.payload.sender,
                label: this.getTranslate(`${services.providers}.labels.sendername`),
                service: 'sendernames',
                itemTitle: 'name',
                itemValue: 'name',
                mode: inputModes['line-label'],
                dense: true,
                rounded: true,
                details: false,
                combobox: true,
                clearable: true,
                autocomplete: true
              },
              on: {
                input: event => {
                  this.proxy.replacement.payload.sender = event
                }
              }
            }
          ),

          h(
            'g-textarea',
            {
              props: {
                value: this.proxy.replacement.payload.text,
                label: this.getTranslate(`${services.providers}.labels.text`),
                mode: inputModes['line-label'],
                grow: true,
                rounded: true,
                details: false,
                clearable: true
              },
              on: {
                input: event => {
                  this.proxy.replacement.payload.text = event
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid'
    },
    [
      renderRules.call(this, h),
      renderReplacement.call(this, h)
    ]
  )
}
