export default {
  title: 'WebSockets',

  labels: {
    connected: 'Connected via WebSockets',
    disconnected: 'Disconnected from WebSockets'
  },

  tooltips: {
    connection: `Your connection to the platform is <strong>active via WebSockets</strong>. You receive status and balance updates <strong>in real-time</strong>.
<br>If the status is <strong>disconnected</strong>, try:
<ul class="ma-0 pl-3"><li>Reloading the page.</li><li>Checking your internet connection.</li></ul>
If the problem persists, it may be technical issues. Try again later.`
  }
}
