import generatePassword from 'generate-password'

import proxy from '@sigma-legacy-libs/g-proxy'

import { isValidEmail, isValidPassword, isValidPhone, isValidUserName } from '@/utils'

import render from './render'

export default {
  name: 'RegistrationForm',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      passwordVisibility: true
    }
  },

  computed: {
    usernameValidation() {
      if (this.proxy.username && !isValidUserName(this.proxy.username)) {
        return this.getTranslate('errors.types.invalid', { field: this.getTranslate('registration.labels.username').toLowerCase() })
      }
    },

    passwordValidation() {
      if (this.proxy.password && !isValidPassword(this.proxy.password)) {
        return this.getTranslate('errors.types.invalid', { field: this.getTranslate('registration.labels.password').toLowerCase() })
      }
    },
    emailValidation() {
      if (this.proxy.data.email && !isValidEmail(this.proxy.data.email)) {
        return this.getTranslate('errors.types.email', { field: this.getTranslate('registration.labels.email').toLowerCase() })
      }
    },
    phoneValidation() {
      if (this.proxy.data.phone && !isValidPhone(this.proxy.data.phone)) {
        return this.getTranslate('errors.types.phoneNumber', { field: this.getTranslate('registration.labels.phone').toLowerCase() })
      }
    },

    validation() {
      return !!(
        isValidUserName(this.proxy.username) &&
        isValidPassword(this.proxy.password) &&
        isValidEmail(this.proxy.data.email) &&
        isValidPhone(this.proxy.data.phone)
      )
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  methods: {
    passwordGenerator() {
      this.passwordVisibility = false
      this.proxy.password = generatePassword.generate({
        length: 8,
        numbers: true,
        symbols: false,
        uppercase: true,
        strict: true
      })
    }
  },

  render
}
