import { colors, components, icons, permissionPresets, services, states } from '@/utils'

import button from '@/components/button'
import buttonRefresh from '@/components/button/refresh'
import pagination from '@/components/pagination'
import preloader from '@/components/preloader'
import afterCreateDialog from '@/components/services/tokens/dialog/after/create'
import item from '@/components/services/tokens/item'
import createDialog from '@/components/services/users/tokens/dialog/create'

function renderAfterCreateDialog(h) {
  return h(
    afterCreateDialog,
    {
      props: {
        value: this.showAfterCreateDialog,
        data: { token: this.token }
      },
      on: {
        input: event => {
          this.showAfterCreateDialog = event
        }
      }
    }
  )
}

function renderCreateDialog(h) {
  return h(
    createDialog,
    {
      props: {
        value: this.showCreateDialog,
        OwnerId: this.OwnerId,
        data: this.restData[services.tokens].create.data,
        loading: this.restData[services.tokens].create.state === states.loading
      },
      on: {
        input: event => {
          this.showCreateDialog = event
        },
        data: event => {
          this.restData[services.tokens].create.data = event
        },
        create: () => {
          this.create()
        }
      }
    }
  )
}
function renderCreateButton(h) {
  if (this.checkPermissions(`advanced.${services.tokens}.create`, permissionPresets.meUp)) {
    return h(
      button,
      {
        props: {
          label: this.viewport.breakpoint.xs ? undefined : this.getTranslate(`${services.tokens}.buttons.add`),
          icon: icons.add,
          color: colors.secondary,
          loading: this.restData[services.tokens].find.state === states.loading
        },
        scopedSlots: {
          dialog: () => renderCreateDialog.call(this, h)
        },
        on: {
          click: () => {
            this.showCreateDialog = true
          }
        }
      }
    )
  }
}

function renderRefreshButton(h) {
  return h(
    buttonRefresh,
    {
      props: {
        state: this.restData[services.tokens].find.state,
        shortClickMethod: () => this.rest[services.tokens].find(),
        longClickMethod: () => this.rest[services.tokens].find({}, { noCache: true })
      }
    }
  )
}
function renderButtons(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid grid-gap--8 pa-2',
      style: { gridTemplateColumns: 'repeat(2, auto)' }
    },
    [
      renderCreateButton.call(this, h),
      renderRefreshButton.call(this, h)
    ]
  )
}

function renderDivider(h) {
  if (this.restData[services.tokens].find.pagination.total > 0) {
    return h(components.divider)
  }
}

function renderPagination(h) {
  return h(
    'div',
    {
      class: 'fjcfe pa-2'
    },
    [
      h(
        pagination,
        {
          props: {
            value: this.restData[services.tokens].find.pagination,
            service: services.tokens
          },
          on: {
            input: event => {
              this.restData[services.tokens].find.pagination = event
            }
          }
        }
      )
    ]
  )
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'preloader__holder pa-5'
    },
    [ h(preloader) ]
  )
}
function renderAbsolutePreloader(h) {
  if (this.restData[services.tokens].find.state === states.loading) {
    return h(
      'div',
      {
        class: 'preloader__holder preloader__holder--absolute'
      },
      [ h(preloader) ]
    )
  }
}
function renderItem(h, value) {
  return h(
    item,
    {
      props: {
        value,
        hasOwner: false
      }
    }
  )
}
function renderItems(h) {
  return h(
    'div',
    {
      style: { position: 'relative' }
    },
    [
      this.restData[services.tokens].find.data.map(item => {
        return renderItem.call(this, h, item)
      }),

      renderAbsolutePreloader.call(this, h)
    ]
  )
}
function renderBody(h) {
  if (this.restData[services.tokens].find.pagination.total > 0) {
    return renderItems.call(this, h)
  } else {
    switch (this.restData[services.tokens].find.state) {
      case states.loading: return renderPreloader.call(this, h)
      case states.ready:
      default: return h(components.empty)
    }
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderButtons.call(this, h),
      renderDivider.call(this, h),
      renderBody.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h),
      renderAfterCreateDialog.call(this, h)
    ]
  )
}
