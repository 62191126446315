import Vue from 'vue'

import { get } from 'lodash'

import { Macro } from '@sigma-legacy-libs/cache'

import { colors, permissionPresets, services } from '@/utils'

import render from './render'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})
const cachedGet = Cache.wrapWithCache(async (key, service, id, params) => await Vue.$GRequest.get(service, id, params))

export default {
  name: 'SingleLink',

  props: {
    value: {
      type: [ String, Object ],
      default: () => undefined
    },

    params: {
      type: Object,
      default: () => ({})
    },

    length: {
      type: Number,
      default: 50
    },

    service: {
      type: String,
      default: services.users
    },

    pathToId: {
      type: String,
      default: 'id'
    },
    pathToTitle: {
      type: String,
      default: 'title'
    },

    verified: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: 'link',
      validator: value => !!~[ 'text', 'link', 'button' ].indexOf(value)
    },

    to: {
      type: Object,
      default: undefined
    },

    copy: {
      type: Boolean,
      default: false
    },

    flat: {
      type: Boolean,
      default: true
    },
    rounded: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: true
    },
    large: {
      type: Boolean,
      default: false
    },
    depressed: {
      type: Boolean,
      default: false
    },

    color: {
      type: String,
      default: colors.primary
    }
  },

  data() {
    return {
      data: undefined
    }
  },

  computed: {
    title() {
      let result = get(this.data, this.pathToTitle, this.data.username)
      if (result && typeof result === 'string' && result.length > this.length) {
        result = result.slice(0, this.length) + '...'
      }

      return result
    },

    $type() {
      let result = this.type
      if (!this.checkPermissions(`advanced.${this.service}.get`, permissionPresets.managerUp)) {
        result = 'text'
      }

      return result
    },

    $to() {
      if (this.to) {
        return this.to
      }

      return {
        name: `${this.service}.single`,
        params: { id: get(this.data, this.pathToId) }
      }
    }
  },

  watch: {
    value: {
      handler() {
        this.get()
      },
      deep: true
    }
  },

  mounted() {
    this.get()
  },

  methods: {
    async get() {
      if (typeof this.value === 'string') {
        if (this.checkPermissions(`advanced.${this.service}.get`, permissionPresets.resellerUp)) {
          if (this.value) {
            const { data } = await cachedGet(`${this.service}:${this.value}`, this.service, this.value, this.params)
            if (data) {
              this.data = data
            }
          }
        }
      } else {
        this.data = this.value
      }
    },

    clickHandler() {
      navigator.clipboard.writeText(this.title)
      this.addSnackbar({
        text: this.getTranslate('misc.copied'),
        type: colors.success
      })
    }
  },

  render
}
