export default {
  title: 'Регистрация',

  labels: {
    username: 'Логин',
    password: 'Пароль',
    firstName: 'Имя',
    lastName: 'Фамилия',
    email: 'E-mail',
    phone: 'Телефон',
    usedReferralCode: 'Реферальный код'
  },

  buttons: {
    signin: 'Войти',
    signup: 'Зарегистрироваться'
  },

  tooltips: {
    password: {
      generate: 'Сгенерировать пароль'
    }
  },

  hints: {
    username: 'Минимум 5, максимум 16 символов',
    password: 'Придумайте надёжный пароль',
    firstName: 'Имя',
    lastName: 'Фамилия',
    email: 'Для восстановления пароля и уведомлений о балансе',
    phone: 'Телефон',
    required: 'Поля отмеченные звёздочкой обязательны.'
  },

  errors: {
    username: 'Такой логин уже зарегистрирован',
    email: 'Такой e-mail уже зарегистрирован',
    phone: 'Такой телефон уже зарегистрирован'
  }
}
