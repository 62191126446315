import { inputModes, services } from '@/utils'

import buttonUpDown from '@/components/button/upDown'
import generatorRow from '@/components/generator/row'
import routesFormLink from '@/components/services/routes/form/link'
import routesFormTitle from '@/components/services/routes/form/title'
import routesFormType from '@/components/services/routes/form/type'

function renderTypeField(h) {
  if (!this.type) {
    return h(
      routesFormType,
      {
        props: {
          value: this.proxy,
          serviceName: services.routingGroups
        },
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}

function renderTitleField(h) {
  return h(
    routesFormTitle,
    {
      props: {
        value: this.proxy,
        serviceName: services.routingGroups
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderRoutingRulesFields(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--8 pt-3 px-2 pb-2',
      props: {
        title: this.getTranslate(`${services.routingGroups}.subtitles.${services.routingRules}`),
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [
      h(
        generatorRow,
        {
          props: {
            value: this.proxy.RoutingRules,
            defaultValue: {
              id: undefined,
              priority: 0,
              $attach: true
            },
            sortable: true,
            sortableProperty: 'priority'
          },
          scopedSlots: {
            row: ({ item }) => {
              return h(
                'div',
                {
                  class: 'grid grid-gap--8',
                  style: { 'grid-template-columns': '1fr auto' }
                },
                [
                  h(
                    'g-select',
                    {
                      props: {
                        value: item.id,
                        label: this.getTranslate(`${services.routingGroups}.labels.routingRule`),
                        itemsDisabled: this.proxy.RoutingRules.map(({ id }) => id),
                        query: {
                          type: this.proxy.type,
                          isActive: true
                        },
                        service: 'routingRules',
                        mode: 'outline',
                        hideSelections: true,
                        rounded: true,
                        details: false,
                        clearable: true,
                        dense: true,
                        autocomplete: true
                      },
                      on: {
                        input: event => {
                          item.id = event
                        }
                      }
                    }
                  ),

                  h(
                    routesFormLink,
                    {
                      props: {
                        value: item,
                        service: services.routingRules
                      }
                    }
                  )
                ]
              )
            }
          },
          on: {
            input: event => {
              this.proxy.RoutingRules = event
            }
          }
        }
      )
    ]
  )
}

function renderDescriptionField(h) {
  return h(
    'g-textarea',
    {
      props: {
        value: this.proxy.description,
        label: this.getTranslate(`${services.routingGroups}.labels.description`),
        mode: inputModes['line-label'],
        rounded: true,
        grow: true,
        details: false,
        clearable: true,
        defaultValue: ''
      },
      on: {
        input: event => {
          this.proxy.description = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid',
      style: { 'grid-template-columns': '100%' }
    },
    [
      renderTypeField.call(this, h),
      renderTitleField.call(this, h),
      renderRoutingRulesFields.call(this, h),
      renderDescriptionField.call(this, h),

      h(buttonUpDown)
    ]
  )
}
