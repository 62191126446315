import { types } from './sendings'

export default {
  title: 'Tarifas',

  tariff: 'Tarifa',
  tariffs: 'Tarifas',
  new: 'Nueva tarifa',
  create: 'Crear nueva tarifa',
  activeAt: 'Activo desde',
  cycle: 'Ciclo',
  per: 'por',
  for: 'para',
  inherited: 'Heredado de la tarifa',
  withOperatorGroups: '(con grupo de operador)',
  sendingId: 'ID de envío',
  conditions: 'Condiciones',
  customize: 'Personalizar',
  unconditional: 'Regla incondicional',
  usersOnTariff: 'Usuarios en la tarifa',
  addRule: 'Agregar regla',
  addSetConditions: 'Agregar grupo de condiciones',
  addAnotherSetConditions: 'Agregar otro grupo de condiciones',
  always: 'Siempre',
  otherCases: 'En otros casos',
  choosePath: '<Elija qué verificar>',
  chooseCondition: '<Indique cómo verificar>',
  matchesWith: 'Coincide con',
  matchesWithRegex: 'expresión regular',
  matchesWithMultiple: 'uno de los valores',
  operator: 'ningún operador | un operador | {count} operadores',
  sender: 'ningún nombre de remitente | un nombre de remitente | {count} nombres de remitente',
  rule: 'Regla #{rule}',

  titles: {
    rules: 'Reglas',
    similar: 'Tarifa similar'
  },

  contents: {
    similar: 'Ya existe una tarifa con ese nombre. Si aún desea crear un duplicado de la tarifa, haga clic en "Crear". De lo contrario, haga clic en "Cerrar".'
  },

  sources: {
    ...types,

    apisender: 'Suscripción API sender',
    hlr: 'HLR'
  },

  cycles: {
    month: 'Mes',
    week: 'Semana',
    day: 'Día',
    trigger: 'Disparador'
  },

  labels: {
    tariff: 'Tarifa',
    title: 'Título',
    source: 'Fuente',
    cycle: 'Ciclo',
    cycleStart: 'Inicio del ciclo',
    price: 'Precio',
    comment: 'Comentario',
    owner: 'Propietario',
    actions: 'Acción',
    refundOnFailed: 'Reembolsar en caso de fallo',
    noPayments: 'Puede ser gratuito',
    rules: 'Reglas',
    index: 'Tarifa #{index}',

    activePeriod: {
      start: 'Fecha de inicio',
      end: 'Fecha de finalización'
    },

    conditions: {
      path: 'Ruta',
      comparator: 'Comparador',
      value: 'Valor',
      negate: 'Negar',
      multiple: 'Elija de la lista',
      regex: 'Ingrese una expresión regular'
    }
  },

  tabs: {
    eventsHistory: 'Historial de cambios'
  },

  dialogs: {
    new: 'Nueva tarifa'
  },

  subheaders: {
    rules: 'Reglas',
    conditions: 'Condiciones',
    activePeriod: {
      title: 'Período activo',
      start: 'Inicio del período activo',
      end: 'Fin del período activo'
    },
    cycleStart: 'Inicio del ciclo'
  },

  actions: {
    decrease: 'Descontar',
    increase: 'Aumentar',
    main: {
      decrease: 'Descontar',
      increase: 'Aumentar'
    }
  },

  buttons: {
    add: {
      tariff: 'Agregar tarifa',
      rule: 'Agregar regla',
      condition: 'Agregar condición'
    },
    remove: {
      tariff: 'Eliminar tarifa'
    },
    clone: {
      divided: '/ 100'
    }
  },

  tooltips: {
    custom: 'Personalizado',

    empty: {
      condition: 'La condición no está completamente llena'
    },

    remove: {
      default: {
        rule: {
          always: 'Eliminar regla',
          otherCases: 'Eliminar regla para otros casos'
        }
      }
    }
  },

  snackbars: {
    created: 'Tarifa creada con éxito.',
    updated: 'Tarifa actualizada con éxito.',
    removed: 'Tarifa eliminada con éxito.'
  },

  hints: {
    default: 'Por mensaje',
    perCall: 'Por segmento',
    perSecond: 'Por segundo',
    perPeriod: 'Por período'
  }
}
