export const moderation = {
  pending: 'Pendiente',
  approved: 'Aprobado',
  rejected: 'Rechazado'
}

export default {
  key: 'Clave',
  value: 'Valor',
  all: 'Todos',
  from: 'De',
  by: 'Por',
  at: 'a las',
  in: 'en',
  if: 'Si',
  to: 'A',
  of: 'De',
  and: 'Y',
  or: 'O',
  yet: 'Todavía',
  new: 'Nuevo',
  isActive: 'Activo',
  search: 'Buscar',
  loadMore: 'Cargar más',
  documentation: 'Documentación',
  pay: 'Pagar',
  payment: 'Pago',
  contacts: 'Contactos',
  statistic: 'Estadística',
  refreshSite: 'Actualiza la página para cargar la nueva versión del sitio.',
  createdAt: 'Creado',
  updatedAt: 'Actualizado',
  total: 'Total',
  true: 'Sí',
  false: 'No',
  empty: '',
  verified: 'Verificado',
  additional: 'Adicional',
  owner: 'Propietario',
  symbols: 'Símbolos',
  unavailable: 'Temporalmente no disponible',
  clear: 'Limpiar',
  copied: 'Copiado',
  nothingFound: 'Nada encontrado',
  sentToday: 'Enviado hoy',
  filteredBy: 'Filtrado por {filter}:',
  presets: 'Preajustes',
  chooseTypes: 'Elegir tipos',
  understand: 'Entendido',
  price: 'Precio',
  current: 'Actual',
  previous: 'Anterior',
  sourceData: 'Datos fuente',
  attention: '¡Atención!',
  preview: 'Vista previa',
  token: 'Token',
  undefined: 'No definido',

  titles: {
    confirm: {
      clone: 'Clonación'
    }
  },

  contents: {
    confirm: {
      clone: '¿Está seguro de que desea clonar esta entidad?'
    }
  },

  hints: {
    countriesImportCSV: 'La importación solo admite archivos CSV con punto y coma como delimitador.',
    fromNotFound: 'Componente de formulario no encontrado.',
    componentNotFound: 'Componente no encontrado.',
    from0To24: 'De 0 a 24',

    need: {
      save: 'Es necesario guardar los cambios.'
    }
  },

  theme: {
    title: 'Tema',
    light: 'Tema claro',
    dark: 'Tema oscuro'
  },

  subheaders: {
    general: 'General',
    administrative: 'Sección administrativa'
  },

  no: {
    data: {
      text: 'No hay datos disponibles',
      value: 'Sin datos'
    }
  },

  time: {
    hours: 'hora(s)',
    minutes: 'minuto(s)',
    seconds: 'segundo(s)'
  },

  date: {
    from: 'desde',
    by: 'hasta',
    creation: 'Fecha de creación',
    update: 'Fecha de actualización',
    days: 'día(s)'
  },

  locales: {
    en: 'English',
    ru: 'Русский',
    es: 'Español',
    pt: 'Português',
    fr: 'Français',
    de: 'Deutsch',
    it: 'Italiano'
  },

  platforms: {
    windows: 'Windows',
    macintosh: 'Macintosh',
    linux: 'Linux',
    android: 'Android'
  },

  confirm: {
    remove: 'Confirmar eliminación',
    send: 'Confirmar envío',
    scheduled: 'Confirmar programación'
  },

  pagination: {
    rows: 'Filas',
    perPage: 'Filas por página:',
    of: 'de'
  },

  buttons: {
    cancel: 'Cancelar',
    apply: 'Aplicar',
    remove: 'Eliminar',
    create: 'Crear',
    edit: 'Editar',
    copy: 'Copiar',
    update: 'Actualizar',
    retry: 'Reintentar',
    upload: 'Subir',
    uploadImage: 'Subir imagen',
    continue: 'Continuar',
    add: 'Agregar',
    save: 'Guardar',
    download: 'Descargar',
    done: 'Hecho',
    approve: 'Aprobar',
    reject: 'Rechazar',
    signin: 'Iniciar sesión',
    signup: 'Registrarse',
    login: 'Iniciar sesión',
    registration: 'Registro',
    back: 'Atrás',
    send: 'Enviar',
    ok: 'Aceptar',
    restoring: 'Restaurando',
    customize: 'Personalizar',
    addFunds: 'Agregar fondos',
    close: 'Cerrar',
    show: 'Mostrar',
    scheduled: 'Programar',
    import: 'Importar',
    fallback: 'Reenvío',
    refresh: 'Actualizar',
    export: 'Exportar',
    filter: 'Filtrar',
    look: 'Ver',
    bill: 'Emitir factura',
    passwordEdit: 'Cambiar contraseña',
    match: 'Coincidir',
    statistic: 'Estadística',
    clear: 'Limpiar',
    pay: 'Pagar',
    support: 'Soporte',
    makeRequest: 'Aplicar filtro',
    rename: 'Renombrar',
    choose: 'Elegir',
    source: 'Datos fuente',
    properties: 'Propiedades',
    accept: 'Aceptar',
    report: 'Reportar error',
    yes: 'Sí',
    no: 'No',
    confirm: 'Confirmar',
    understand: 'Entendido',
    rules: 'Condiciones',
    unlock: 'Desbloquear',
    logout: 'Cerrar sesión',
    clone: 'Clonar',
    notifications: 'Notificaciones',
    attach: 'Adjuntar',
    detach: 'Desconectar',
    generate: 'Generar',
    documentation: 'Documentación',
    reset: 'Restablecer',

    goto: {
      files: 'Ir a archivos',
      requestAndFiles: 'Solicitar e ir a archivos',
      report: 'Ir al informe'
    },

    reports: {
      request: 'Solicitar informe',
      get: 'Obtener informe'
    },

    exports: {
      pdf: 'Exportar a PDF'
    }
  },

  moderation,

  moderationStatus: {
    disabled: 'Deshabilitado',
    requested: 'Solicitado',
    moderation: 'En moderación',
    approved: 'Aprobado',
    rejected: 'Rechazado'
  },

  encodings: {
    utf8: 'UTF-8',
    win1251: 'WIN-1251',
    win1252: 'WIN-1252',
    maccyrillic: 'MacCyrillic'
  },

  delimiters: {
    comma: 'Coma',
    semicolon: 'Punto y coma'
  },

  comparators: {
    regexp: 'Expresión regular',
    pattern: 'Patrón',
    lt: 'Menor que',
    lte: 'Menor o igual que',
    gt: 'Mayor que',
    gte: 'Mayor o igual que',
    eq: 'Igual a'
  },

  paths: {
    payload: {
      sender: 'Remitente',
      text: 'Texto'
    },
    meta: {
      stats: {
        segments: 'Segmentos',
        characters: 'Caracteres'
      },
      _recipientData: {
        group: 'Grupo de operadores',
        code: 'Código del operador'
      }
    }
  },

  genders: {
    male: 'Masculino',
    female: 'Femenino'
  },

  sudo: {
    data: {
      title: '¡Datos solo para programadores increíbles!'
    }
  },

  measure: {
    time: {
      s: 'S',
      sec: 'Seg',
      second: 'Segundo',
      seconds: 'Segundos',
      m: 'M',
      min: 'Min',
      minute: 'Minuto',
      minutes: 'Minutos',
      h: 'H',
      hour: 'Hora',
      hours: 'Horas',

      from: 'Desde',
      to: 'Hasta',

      short: {
        ms: 'ms',
        seconds: 's',
        minutes: 'm',
        hours: 'h'
      },

      template: {
        seconds: '0 segundos | Un segundo | {count} segundos',
        minutes: '0 minutos | Un minuto | {count} minutos',
        hours: '0 horas | Una hora | {count} horas',
        days: '0 días | Un día | {count} días'
      }
    },

    date: {
      today: 'Hoy',
      yesterday: 'Ayer',
      tomorrow: 'Mañana'
    }
  },

  links: {
    documentation: 'Documentación',
    platform: 'Plataforma',
    api: 'API',
    instruction: 'Instrucción',
    support: 'Contactos',
    to: {
      recurrentPayments: 'Enlace a pagos recurrentes'
    }
  },

  fileTypes: {
    documents: 'Documentos',
    tables: 'Tablas',
    images: 'Imágenes',
    audio: 'Audio',
    pdf: 'PDF',
    video: 'Video'
  },

  emptyList: 'Nada encontrado',

  entity: {
    info: {
      owner: 'Propietario',
      reseller: 'Revendedor',
      source: 'Fuente',
      createdAt: 'Creado',
      updatedAt: 'Actualizado'
    }
  },

  tooltips: {
    clone: 'Clonar',
    navigationList: {
      sortable: 'Cambiar el orden de los elementos del menú',
      clear: 'Limpiar el orden de los elementos del menú'
    },
    favorites: {
      disabled: 'El número máximo de favoritos disponibles es 4'
    }
  }
}
