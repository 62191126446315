import { mapGetters } from 'vuex'

import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default function(options) {
  return {
    name: 'PaymentsAllowedGlobalMethods',

    mixins: [ proxy({ type: 'array' }) ],

    computed: {
      ...mapGetters({ paymentsMethods: 'payments/methods' })
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
