import { CONTACTLIST_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, components, icons, inputModes, permissionPresets, services, sizes, states } from '@/utils'

import button from '@/components/button'
import buttonFavorite from '@/components/button/favorite'
import buttonRefresh from '@/components/button/refresh'
import fileManager from '@/components/file/manager'
import info from '@/components/info'
import HeadTitle from '@/components/misc/HeadTitle'
import sticker from '@/components/misc/sticker'
import pagination from '@/components/pagination'
import preloader from '@/components/preloader'
import contactsDialogs from '@/components/services/contacts/dialogs'
import contactsFilter from '@/components/services/contacts/filter'
import contactsItem from '@/components/services/contacts/item'
import eventsHistoryButton from '@/components/services/eventsHistory/button'
import tag from '@/components/tag'
import owner from '@/components/templates/service/item/owner'
import textOverflow from '@/components/text/overflow'

function renderDivider(h, trigger) {
  if (trigger) {
    return h('g-divider')
  }

  return h('div')
}
function renderPagination(h, trigger) {
  if (trigger) {
    return h(
      'div',
      {
        class: 'fjcfe grid-gap--8 pa-2'
      },
      [
        h(
          pagination,
          {
            props: {
              value: this.restData[services.contacts].find.pagination,
              service: services.contacts
            },
            on: {
              input: event => {
                this.restData[services.contacts].find.pagination = event
              }
            }
          }
        ),
        h(
          buttonRefresh,
          {
            props: {
              state: this.restData[services.contacts].find.state,
              shortClickMethod: () => this.rest[services.contacts].find(),
              longClickMethod: () => this.rest[services.contacts].find({}, { noCache: true })
            }
          }
        )
      ]
    )
  }
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'preloader__holder pa-5'
    },
    [ h(preloader) ]
  )
}

function renderSearchGeneratorItemTitle(h, item) {
  return h(
    textOverflow,
    {
      props: {
        value: item.title
      }
    }
  )
}
function renderSearchGeneratorItemType(h, item) {
  return h(
    tag,
    {
      props: {
        label: this.getTranslate(`${services.contactLists}.types.${item.type}`),
        size: sizes.tiny,
        color: colors.grey
      }
    }
  )
}
function renderSearchGeneratorItem(h, item) {
  return h(
    'router-link',
    {
      class: {
        'search-generator__item': true,
        'search-generator__item--active': item.id === this.$route.params.id
      },
      props: {
        to: {
          name: `${services.contacts}.single`,
          params: { id: item.id }
        }
      },
      key: item.id
    },
    [
      h(
        'div',
        {
          class: 'search-generator__item-content'
        },
        [
          renderSearchGeneratorItemTitle.call(this, h, item),
          renderSearchGeneratorItemType.call(this, h, item)
        ]
      )
    ]
  )
}
function renderSearchGenerator(h) {
  return h(
    'search-generator',
    {
      scopedSlots: {
        create: () => {
          return h(
            components['list-item'],
            {
              props: {
                color: colors.secondary,
                label: this.getTranslate(`${services.contactLists}.buttons.create`),
                dense: true
              },
              on: {
                click: () => {
                  this.dialogType = `${services.contactLists}.create`
                  this.showDialog = true
                }
              }
            }
          )
        },
        filter: ({ filter }) => {
          return h(
            components.select,
            {
              props: {
                value: filter.type,
                label: this.getTranslate(`${services.contactLists}.labels.type`),
                items: CONTACTLIST_TYPES.map(type => {
                  return {
                    title: this.getTranslate(`${services.contactLists}.types.${type}`),
                    value: type
                  }
                }),
                clearable: true,
                mode: inputModes.outline,
                dense: true,
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  filter.type = event
                }
              }
            }
          )
        },
        default: ({ item }) => renderSearchGeneratorItem.call(this, h, item)
      }
    }
  )
}

function renderEditButton(h, id) {
  return h(
    button,
    {
      props: {
        icon: icons.edit,
        mode: buttonModes.flat
      },
      on: {
        click: () => {
          this.contactListId = id
          this.dialogType = `${services.contactLists}.update`
          this.showDialog = true
        }
      }
    }
  )
}
function renderFavoriteButton(h, id) {
  return h(
    buttonFavorite,
    {
      props: {
        id,
        service: services.contactLists
      }
    }
  )
}
function renderTitle(h, { title, type }) {
  return h(
    sticker,
    {
      props: {
        value: title,
        label: this.getTranslate(`${services.contactLists}.types.${type}`),
        maxWidth: 200,
        width: 'auto',
        copy: true
      }
    }
  )
}
function renderOwner(h, item) {
  return h(
    owner,
    {
      props: {
        value: item
      }
    }
  )
}
function renderInfo(h) {
  const { id } = this.restData[services.contactLists].get.data

  return h(
    'div',
    {
      class: `${services.contacts}-info`
    },
    [
      renderEditButton.call(this, h, id),
      renderFavoriteButton.call(this, h, id),
      renderTitle.call(this, h, this.restData[services.contactLists].get.data),
      renderOwner.call(this, h, this.restData[services.contactLists].get.data)
    ]
  )
}
function renderEventsHistoryButton(h) {
  if (this.checkPermissions(`advanced.${services.contacts}.get`, permissionPresets.true)) {
    return h(
      eventsHistoryButton,
      {
        props: {
          label: this.viewport.breakpoint.xl ? true : false,
          entityId: this.$route.params.id,
          rounded: true
        }
      }
    )
  }
}
function renderRemoveContactListButton(h) {
  return h(
    button,
    {
      props: {
        label: this.viewport.breakpoint.xl ? this.getTranslate(`${services.contactLists}.buttons.remove`) : undefined,
        icon: icons.delete,
        color: colors.error,
        mode: buttonModes.flat
      },
      directives: [
        {
          name: 'g-tooltip',
          options: { value: this.isActive || this.getTranslate(`${services.contactLists}.tooltips.remove`) }
        }
      ],
      on: {
        click: () => {
          this.contactListId = this.restData[services.contactLists].get.data.id
          this.dialogType = `${services.contactLists}.remove`
          this.showDialog = true
        }
      }
    }
  )
}
function renderAddContactButton(h) {
  return h(
    button,
    {
      props: {
        label: this.viewport.breakpoint.xl ? this.getTranslate(`${services.contacts}.buttons.add`) : undefined,
        icon: icons.exposure_plus_1,
        color: colors.primary,
        mode: buttonModes.flat
      },
      directives: [
        {
          name: 'g-tooltip',
          options: { value: this.isActive || this.getTranslate(`${services.contacts}.tooltips.add`) }
        }
      ],
      on: {
        click: () => {
          this.contactListId = this.restData[services.contactLists].get.data.id
          this.dialogType = `${services.contacts}.create`
          this.showDialog = true
        }
      }
    }
  )
}
function renderImportContactsButton(h) {
  return h(
    button,
    {
      props: {
        label: this.viewport.breakpoint.xl ? this.getTranslate('misc.buttons.import') : undefined,
        icon: icons.cloud_upload,
        mode: buttonModes.flat
      },
      directives: [
        {
          name: 'g-tooltip',
          options: { value: this.isActive || this.getTranslate(`${services.contactLists}.tooltips.import`) }
        }
      ],
      on: {
        click: () => {
          this.showFileManager = true
        }
      }
    }
  )
}
function renderFilterButton(h) {
  return h(
    button,
    {
      props: {
        label: this.viewport.breakpoint.xl ? this.getTranslate('misc.buttons.filter') : undefined,
        icon: icons.filter_list,
        mode: buttonModes.flat
      },
      on: {
        click: () => {
          this.showFilter = !this.showFilter
        }
      }
    }
  )
}
function renderActions(h) {
  return h(
    'div',
    {
      class: `${services.contacts}-actions`
    },
    [
      renderEventsHistoryButton.call(this, h),
      renderRemoveContactListButton.call(this, h),
      renderAddContactButton.call(this, h),
      renderImportContactsButton.call(this, h),
      renderFilterButton.call(this, h)
    ]
  )
}
function renderHeader(h) {
  if (this.restData[services.contactLists].get.state === states.ready) {
    return h(
      'div',
      {
        class: `${services.contacts}-header`
      },
      [
        renderInfo.call(this, h),
        renderActions.call(this, h)
      ]
    )
  }
}
function renderFilter(h) {
  if (this.showFilter) {
    return h(
      contactsFilter,
      {
        props: {
          value: this.restData[services.contacts].find.filter
        },
        on: {
          input: event => {
            this.restData[services.contacts].find.filter = event
          }
        }
      }
    )
  }
}
function renderItems(h) {
  const contactsEvents = [ `${services.contacts}.update`, `${services.contacts}.remove` ]
  const on = contactsEvents.reduce((result, event) => {
    result[event] = id => {
      this.contactId = id
      this.dialogType = event
      this.showDialog = true
    }

    return result
  }, {})

  return h(
    'div',
    {
      class: 'fc ff'
    },
    this.restData[services.contacts].find.data.map(item => {
      return h(
        contactsItem,
        {
          props: { value: item },
          on
        }
      )
    })
  )
}
function renderContactsList(h) {
  if (this.restData[services.contacts].find.pagination.total > 0) {
    return renderItems.call(this, h)
  } else {
    switch (this.restData[services.contacts].find.state) {
      case states.loading: return renderPreloader.call(this, h)
      case states.ready:
      default: return h(components.empty)
    }
  }
}
function renderContactsContent(h) {
  return h(
    components.card,
    {
      props: {
        rounded: true,
        outline: true
      }
    },
    [
      renderPagination.call(this, h, true),
      renderDivider.call(this, h, this.restData[services.contacts].find.pagination.total),
      renderContactsList.call(this, h),
      renderDivider.call(this, h, this.restData[services.contacts].find.pagination.total),
      renderPagination.call(this, h, !!this.restData[services.contacts].find.pagination.total)
    ]
  )
}
function renderContent(h) {
  if (this.$route.params.id) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 ff'
      },
      [
        renderHeader.call(this, h),
        renderFilter.call(this, h),
        renderContactsContent.call(this, h)
      ]
    )
  }

  return h('div')
}

function renderContactsDialogs(h) {
  return h(
    contactsDialogs,
    {
      props: {
        id: this.contactId || this.contactListId,
        type: this.dialogType,
        show: this.showDialog,
        ListId: this.restData[services.contactLists].get.data && this.restData[services.contactLists].get.data.id
      },
      on: {
        show: event => {
          if (event === false) {
            this.contactId = undefined
            this.contactListId = undefined
            this.dialogType = undefined
          }
          this.showDialog = event
        }
      }
    }
  )
}
function renderImportDialogHeader(h) {
  return h(
    HeadTitle,
    {
      props: { value: this.getTranslate(`${services.contacts}.dialogs.titles.import`) },
      slot: 'header'
    }
  )
}
function renderImportDialogBody(h) {
  switch (this.importContactsResult.state) {
    case states.loading: {
      return h(
        'div',
        {
          class: 'grid grid-gap--0 pb-3'
        },
        [
          renderPreloader.call(this, h),

          h(
            'div',
            {
              domProps: { innerHTML: this.getTranslate(`${services.contacts}.contents.import.${states.loading}`) }
            }
          )
        ]
      )
    }
    case states.success: {
      return h(
        info,
        {
          style: {
            width: '100%'
          },
          props: {
            icon: icons.done,
            color: colors.success,
            value: this.getTranslate(`${services.contacts}.contents.import.${states.success}`, { count: this.importContactsResult.total })
          }
        }
      )
    }
    case states.error: {
      return h(
        info,
        {
          props: {
            icon: icons.warning,
            color: colors.warning,
            value: this.getTranslate(`${services.contacts}.contents.import.${states.error}`)
          }
        }
      )
    }
  }
}
function renderImportDialogFooter(h) {
  switch (this.importContactsResult.state) {
    case states.success:
    case states.error: {
      return h(
        'div',
        {
          class: 'faic fjcfe pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.showImportContacts = false
                  this.importContactsResult.state = undefined
                  this.importContactsResult.total = 0
                }
              }
            }
          )
        ]
      )
    }
  }
}
function renderImportDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showImportContacts,
        closeOnClick: false,
        closeOnEsc: false,
        maxWidth: 500
      },
      on: {
        input: event => {
          this.showImportContacts = event
        }
      }
    },
    [
      renderImportDialogHeader.call(this, h),
      h(
        'div',
        {
          class: 'fc faic fjcfc px-2'
        },
        [ renderImportDialogBody.call(this, h) ]
      ),

      renderImportDialogFooter.call(this, h)
    ]
  )
}

function renderFileManager(h) {
  return h(
    fileManager,
    {
      props: {
        show: this.showFileManager,
        flat: true,
        readonly: true,
        embedded: true,
        types: [ 'csv', 'xls', 'xlsx' ]
      },
      on: {
        show: event => {
          this.showFileManager = event
        },
        input: event => {
          this.showFileManager = false
          this.showImportContacts = true
          this.importContacts(event.id)
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: services.contacts
    },
    [
      renderSearchGenerator.call(this, h),
      renderContent.call(this, h),
      renderContactsDialogs.call(this, h),
      renderImportDialog.call(this, h),
      renderFileManager.call(this, h)
    ]
  )
}
