import { get } from 'lodash'

import { EVENTSHISTORY_ASSOCIATED_SERVICES, EVENTSHISTORY_SERVICES, EVENTSHISTORY_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { services } from '@/utils'

import render from './render'

export default {
  name: 'Item',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    service() {
      const result = {
        title: `${this.value.service}.title`,
        name: this.value.service,
        single: `${this.value.service}.single`,
        entityId: this.value.data.id,
        createdAt: undefined,
        currentTab: undefined
      }

      switch (this.value.service) {
        case EVENTSHISTORY_SERVICES.recurrentPayments: {
          result.name = services.payments
          result.single = services.payments
          result.currentTab = services.recurrentPayments
          break
        }
        case EVENTSHISTORY_ASSOCIATED_SERVICES.tasks: {
          result.name = services.billings
          result.single = services.billings
          result.currentTab = this.value.service
          result.createdAt = this.getCreatedAt()
          break
        }
        case EVENTSHISTORY_ASSOCIATED_SERVICES.templates: {
          result.name = 'create'
          result.single = 'create'
          result.currentTab = this.value.service
          break
        }
        case EVENTSHISTORY_ASSOCIATED_SERVICES.contactLists: {
          result.name = `${services.contacts}.single`
          result.single = `${services.contacts}.single`
          break
        }
        case EVENTSHISTORY_ASSOCIATED_SERVICES.contactListsContacts: {
          result.title = `${services.contacts}.title`
          result.name = services.contacts
          result.single = `${services.contacts}.single`
          result.entityId = get(this.value, 'data.ListId')
          break
        }
        case EVENTSHISTORY_ASSOCIATED_SERVICES.billingsUsers: {
          result.title = `${services.eventsHistory}.services.billingsUsers`
          result.name = services.billings
          result.single = services.billings
          result.currentTab = services.billings
          result.createdAt = this.getCreatedAt()
          break
        }
        case EVENTSHISTORY_ASSOCIATED_SERVICES.keywordListsKeywords: {
          result.title = `${services.keywords}.title`
          result.name = `${services.keywordLists}.single`
          result.single = `${services.keywordLists}.single`
          result.entityId = get(this.value, 'data.ListId')
          break
        }
        case EVENTSHISTORY_ASSOCIATED_SERVICES.permissionsUsers: {
          result.title = `${services.eventsHistory}.services.permissionsUsers`
          result.name = `${services.users}.single`
          result.single = `${services.users}.single`
          result.currentTab = services.permissions
          break
        }
        case EVENTSHISTORY_ASSOCIATED_SERVICES.settingsUserUsers: {
          const [ settingKey ] = this.value?.data?.new?.field?.split('.') || []
          result.title = `${services.eventsHistory}.services.settingsUserUsers`
          result.name = `${services.users}.single`
          result.single = `${services.users}.single`
          result.currentTab = services.settingsUser
          result.settingKey = settingKey
          result.entityId = this.value?.entityId
          break
        }
        case EVENTSHISTORY_ASSOCIATED_SERVICES.tokensUsers: {
          result.title = `${services.eventsHistory}.services.${services.tokens}`
          result.name = `${services.users}.single`
          result.single = `${services.users}.single`
          result.currentTab = services.tokens
          result.entityId = this.value?.entityId
          break
        }
      }

      return result
    },

    toParamsForServiceSticker() {
      switch (this.value.service) {
        case services.contactLists:
        case EVENTSHISTORY_ASSOCIATED_SERVICES.settingsUserUsers:
        case EVENTSHISTORY_ASSOCIATED_SERVICES.tokensUsers: return { id: this.service.entityId }
        default: return undefined
      }
    },

    computedTo() {
      if (EVENTSHISTORY_TYPES[this.value.type] !== 'removed') {
        return {
          name: this.service.single,
          params: {
            id: this.service.entityId,
            createdAt: this.service.createdAt
          },
          query: {
            currentTab: this.service.currentTab,
            settingKey: this.service.settingKey
          }
        }
      }
    }
  },

  methods: {
    getCreatedAt() {
      const gt = this.value.data.createdAt
      const lt = new Date(gt).setSeconds(new Date(gt).getSeconds() + 1)

      return {
        $gt: gt,
        $lt: new Date(lt).toISOString()
      }
    }
  },

  render
}
