import Vue from 'vue'

import { get } from 'lodash'

import { buttonModes, colors, sizes, states } from '@/utils'

import button from '@/components/button'
import draggable from '@/components/misc/draggable'
import { rowStates, serviceName } from '@/components/services/operatorGroups/utils'
import importCSVButton from '@/components/trees/importCSVButton'

function renderSearch(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.searchValue,
        label: this.getTranslate('commons.labels.search'),
        beforeIcon: 'search',
        afterIcon: 'refresh',
        afterIconCallback: () => {
          this.search()
        },
        loading: this.restData.countries.find.state === states.loading,
        mode: 'outline',
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.search(event)
        }
      }
    }
  )
}

function renderImportButton(h) {
  return h(
    importCSVButton,
    {
      props: {
        value: this.proxy,
        parser: this.parser,
        tooltip: this.getTranslate('misc.hints.countriesImportCSV')
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': '1fr 38px' }
    },
    [
      renderSearch.call(this, h),
      renderImportButton.call(this, h)
    ]
  )
}

function renderPills(h) {
  return h(
    'div',
    {
      class: 'faic grid-gap--8'
    },
    [ 'all', 'checked' ].map(tab => {
      return h(
        button,
        {
          props: {
            mode: buttonModes.flat,
            size: sizes.small,
            color: this.tab === tab ? colors.primary : colors.grey,
            label: this.getTranslate(`${serviceName}.pills.${tab}`)
          },
          on: {
            click: () => {
              this.tab = tab
            }
          }
        }
      )
    })
  )
}

function renderOperator(h, { mcc, mnc, group, operator }) {
  if (operator) {
    let mccHasMnc = false
    if (Array.isArray(this.proxy[mcc])) {
      mccHasMnc = ~this.proxy[mcc].indexOf(mnc)
    }

    return h(
      'div',
      {
        class: 'tree__row',
        on: {
          click: () => {
            this.operatorClick({
              group,
              mcc,
              mnc,
              operator
            })
          }
        }
      },
      [
        h(
          'div',
          {
            class: {
              ['tree__checker']: true,
              ['tree__checker--checked']: mccHasMnc
            }
          }
        ),

        h(
          'div',
          {
            class: 'tree__content'
          },
          [
            h(
              'div',
              {
                class: 'tree__description'
              },
              mnc
            ),

            h(
              'div',
              {
                class: 'tree__title'
              },
              operator
            ),

            h('div', { class: 'ff' }),

            h(
              'div',
              {
                class: 'tree__description tree__description--hover caption'
              },
              this.getTranslate(`${serviceName}.hints.tree.operator`)
            )
          ]
        )
      ]
    )
  }
}
function renderOperators(h, { state, group, country, mcc, operators }) {
  if (state === rowStates.opened) {
    if (country) {
      let filter = () => true
      if (this.tab === 'checked') {
        filter = ({ mnc }) => {
          if (Array.isArray(this.proxy[mcc])) {
            return ~this.proxy[mcc].indexOf(mnc)
          }

          return true
        }
      }
      if (operators.length) {
        return h(
          'div',
          {
            class: 'tree__level tree__level--3'
          },
          operators.filter(filter).map(({ mnc, operator }) => {
            return renderOperator.call(this, h, {
              mcc,
              mnc,
              group,
              operator
            })
          })
        )
      }
    }
  }
}

function renderGroup(h, { group, country, mcc, operators }) {
  const state = get(this.groupsState, `${mcc}:${group}`, rowStates.closed)
  const every = Array.isArray(this.operators[group]) && operators.every(operator => !!~this.operators[group].indexOf(operator.mnc) && this.proxy[operator.mcc] && !!~this.proxy[operator.mcc].indexOf(operator.mnc)) && operators.every(operator => operator.mcc === mcc)
  const some = Array.isArray(this.operators[group]) && operators.some(operator => !!~this.operators[group].indexOf(operator.mnc) && this.proxy[operator.mcc] && !!~this.proxy[operator.mcc].indexOf(operator.mnc)) && operators.every(operator => operator.mcc === mcc)

  return h(
    'div',
    {
      class: 'tree__holder'
    },
    [
      h(
        'div',
        {
          class: 'tree__row'
        },
        [
          h(
            'div',
            {
              class: {
                ['tree__checker']: true,
                ['tree__checker--checked']: every,
                ['tree__checker--partial']: !every && some
              },
              on: {
                click: () => {
                  this.groupClick({
                    group,
                    country,
                    mcc,
                    operators
                  })
                }
              }
            }
          ),

          h(
            'div',
            {
              class: {
                ['tree__content']: true,
                [`tree__content--${state}`]: true
              },
              on: {
                click: () => {
                  if (state === rowStates.closed) {
                    Vue.set(this.groupsState, `${mcc}:${group}`, rowStates.opened)
                  } else {
                    Vue.set(this.groupsState, `${mcc}:${group}`, rowStates.closed)
                  }
                }
              }
            },
            [
              h(
                'div',
                {
                  class: 'tree__title'
                },
                group
              ),

              h('div', { class: 'ff' }),

              h(
                'div',
                {
                  class: 'tree__description tree__description--hover prpx-24 caption'
                },
                this.getTranslate(`${serviceName}.hints.tree.group`)
              )
            ]
          )
        ]
      ),

      renderOperators.call(this, h, {
        state,
        group,
        country,
        mcc,
        operators
      })
    ]
  )
}
function renderGroups(h, { state }) {
  if (state === rowStates.opened) {
    if (this.$groups) {
      return h(
        'div',
        {
          class: 'tree__level tree__level--2'
        },
        [
          h(
            draggable,
            {
              props: {
                value: this.$groups
              },
              scopedSlots: {
                item: group => {
                  return renderGroup.call(this, h, group)
                }
              },
              on: {
                input: event => {
                  this.setGroupsSortedList(event)
                }
              }
            }
          )
        ]
      )
    }
  }
}

function renderCountry(h, { country, mcc }) {
  if (country) {
    const state = get(this.countriesState, mcc, rowStates.closed)
    const checked = get(this.proxy, mcc, false)

    return h(
      'div',
      {
        class: 'tree__holder'
      },
      [
        h(
          'div',
          {
            class: 'tree__row'
          },
          [
            h(
              'div',
              {
                class: {
                  ['tree__checker']: true,
                  ['tree__checker--checked']: checked === 'all',
                  ['tree__checker--partial']: Array.isArray(checked) && checked.length
                },
                on: {
                  click: () => {
                    switch (true) {
                      case Array.isArray(checked):
                      case checked === false: {
                        Vue.set(this.proxy, mcc, 'all')
                        Vue.set(this.countriesState, mcc, rowStates.closed)
                        break
                      }
                      case checked === 'all': {
                        Vue.delete(this.proxy, mcc)
                        for (const group in this.groups[country]) {
                          Vue.delete(this.operators, group)
                        }
                        break
                      }
                    }
                  }
                }
              }
            ),

            h(
              'div',
              {
                class: {
                  ['tree__content']: true,
                  [`tree__content--${state}`]: true,
                  ['tree__content--hide-icon']: checked === 'all'
                },
                on: {
                  click: () => {
                    this.getGroupsByCountry(country, mcc)
                  }
                }
              },
              [
                h(
                  'div',
                  {
                    class: 'tree__description'
                  },
                  mcc
                ),

                h(
                  'div',
                  {
                    class: 'tree__title'
                  },
                  country
                ),

                h('div', { class: 'ff' }),

                h(
                  'div',
                  {
                    class: 'tree__description tree__description--hover prpx-24 caption'
                  },
                  this.getTranslate(`${serviceName}.hints.tree.country`)
                )
              ]
            )
          ]
        ),

        renderGroups.call(this, h, {
          state
        })
      ]
    )
  }
}
function renderCountries(h) {
  switch (this.tab) {
    case 'all': {
      if (Array.isArray(this.countries)) {
        if (this.countries.length) {
          return h(
            'div',
            {
              class: 'tree__level tree__level--1'
            },
            [
              h(
                draggable,
                {
                  props: {
                    value: this.countries
                  },
                  scopedSlots: {
                    item: country => {
                      return renderCountry.call(this, h, country)
                    }
                  },
                  on: {
                    input: event => {
                      this.setCountriesSortedList(event)
                    }
                  }
                }
              )
            ]
          )
        } else {
          return h('g-empty')
        }
      }
      break
    }
    case 'checked': {
      const checkedKeys = Object.keys(this.proxy)
      if (checkedKeys.length) {
        return h(
          'div',
          {
            class: 'tree__level tree__level--1'
          },
          this.countries.reduce((result, country) => {
            if (~checkedKeys.indexOf(country.mcc)) {
              result.push(renderCountry.call(this, h, country))
            }

            return result
          }, [])
        )
      } else {
        return h('g-empty')
      }
    }
  }
}

function renderBody(h) {
  return h(
    'div',
    {
      class: 'tree grid grid-gap--8 grid-cols--1'
    },
    [
      renderPills.call(this, h),
      renderCountries.call(this, h)
    ]
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 grid-cols--1'
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
