export default {
  title: 'HLR',

  labels: {
    search: 'Ingrese el número de teléfono',
    status: 'Estado de la búsqueda'
  },

  hints: {
    empty: 'Aquí aparecerán los datos encontrados para el número'
  }
}
