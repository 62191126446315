import Vue from 'vue'
import { mapGetters } from 'vuex'

import { cloneDeep } from 'lodash'

import { CONTACTS_FILTER, generateServices, globalErrorHandler, globalErrorProcessor, permissionPresets, services, states, tableHeaderParser } from '@/utils'

import SearchGenerator from '@/components/generator/search'
import { contactFields } from '@/components/services/contacts/utils'

import render from './render'

export default {
  name: 'Template',

  mixins: [
    generateServices([
      {
        name: services.contacts,

        outputFilter(result) {
          Object.keys(result).forEach(key => {
            if (result[key] === undefined) {
              result[key] = null
            }
          })

          return result
        },

        find: {
          defaultFilter: Object.assign({}, cloneDeep(CONTACTS_FILTER), {
            $scope: [ 'Owner' ],
            t: undefined
          }),

          alwaysCreateFromWebSocket: true,
          alwaysUpdateFromWebSocket: true,
          alwaysRemoveFromWebSocket: true,

          useCache: false
        },

        get: { useCache: false }
      },
      {
        name: services.contactLists,

        find: { useCache: false },
        get: { useCache: false },

        remove: {
          redirect: services.contacts
        }
      }
    ])
  ],

  components: {
    'search-generator': SearchGenerator({
      name: services.contactLists,

      find: {
        defaultFilter: {
          isActive: true,
          type: undefined,
          $scope: [ 'Owner' ]
        },
        alwaysCreateFromWebSocket: true,
        alwaysUpdateFromWebSocket: true,
        alwaysRemoveFromWebSocket: true,

        useCache: false
      },

      serviceForCheckPermissions: services.contacts,
      filterByIsActive: false,
      avoid304: true
    })
  },

  data() {
    return {
      showDialog: false,
      showFilter: false,
      showFileManager: false,

      exportList: undefined,
      exportType: 'new',

      importContactsResult: {
        state: undefined,
        total: 0
      },

      contactId: undefined,
      contactListId: undefined,
      contactListItem: {},
      contactItem: undefined,
      proxyContactList: undefined,
      canUpdate: false,

      dialogType: undefined,

      show: {
        create: {
          contactLists: false,
          contacts: false
        },
        update: {
          contactLists: false,
          contacts: false
        },
        remove: {
          contactLists: false,
          contacts: false
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      file: 'file/file',
      token: 'authentication/token'
    }),

    headers() {
      const result = []
      for (const key of contactFields) {
        const header = {
          title: `${services.contacts}.labels.${key}`,
          value: key,
          align: 'left',
          class: 'pl-2 pr-2'
        }
        switch (key) {
          case 'custom01':
          case 'custom02':
          case 'custom03': {
            if (this.viewport.size.width <= 1440) {
              continue
            }
          }
          case 'createdAt': {
            header.align = 'right'
            break
          }
          case 'actions': {
            delete header.align
            header.class = 'pl-0 pr-0'
            break
          }
        }
        result.push(header)
      }

      return tableHeaderParser.call(this, result)
    }
  },

  watch: {
    '$route.params.id'() {
      this.restData[services.contacts].find.data = undefined
      this.restData[services.contacts].find.pagination.total = 0
      this.restData[services.contacts].find.state = states.empty

      this.getCurrentContactList()
    },

    file: {
      handler({ data }) {
        this.showImportContacts = true
        this.showFileManager = false
        this.importContacts(data.key)
      },
      deep: true
    }
  },

  mounted() {
    this.getCurrentContactList()
  },

  beforeDestroy() {
    this.restData[services.contacts].find.filter = cloneDeep(CONTACTS_FILTER)
  },

  methods: {
    async getCurrentContactList() {
      const id = this.$route.params.id
      if (id) {
        this.proxyContactList = cloneDeep(await this.rest.contactLists.get(id))
        this.restData[services.contacts].find.filter.ListId = id
        this.restData[services.contacts].find.filter.t = Date.now()
      }
    },

    async importContacts(key) {
      this.importContactsResult.state = states.loading
      try {
        const result = await Vue.$GRequest._request({
          method: 'PUT',
          url: `/${services.contacts}`,
          headers: { Authorization: this.token },
          data: {
            ListId: this.$route.params.id,
            from: { key }
          }
        })
        if (result) {
          this.rest[services.contacts].find()
          const total = result.data.total
          if (total) {
            this.importContactsResult.state = states.success
            this.importContactsResult.total = result.data.total
          } else {
            this.importContactsResult.state = states.error
          }
        }
      } catch (error) {
        this.importContactsResult.state = states.error

        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    },

    async exportContacts(id) {
      try {
        await Vue.$GRequest._request({
          method: 'PUT',
          url: `/${services.contactLists}`,
          headers: { Authorization: this.token },
          data: {
            ListId: id,
            from: { ListId: this.$route.params.id }
          }
        })
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.showExportContacts = false
      }
    },

    async tryUpdating() {
      if (this.restData[services.contacts].update.isValid) {
        try {
          await this.rest.contacts.update(this.contactItem.id, this.contactItem)
          this.contactItem = undefined
          this.show.update.contacts = false
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    },

    async changeData(service, method, data) {
      const valid =
        method === 'remove'
          ? this.checkPermissions(`advanced.${services.contacts}.remove`, permissionPresets.meUp)
          : this.restData[service][method].isValid

      if (method && service && data) {
        if (this.restData[service][method].state !== states.loading && valid) {
          try {
            const result = await this.rest[service][method](data)
            if (result) {
              this.show[method][service] = false
            }

            return result
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    }
  },

  render
}
