export default {
  title: 'Grupos de enrutamiento',

  titles: {
    create: 'Crear grupo de enrutamiento'
  },

  routingGroup: 'Grupo de enrutamiento',
  routingGroups: 'Grupos de enrutamiento',
  one: 'Grupo de enrutamiento',
  many: 'Grupos de enrutamiento',

  labels: {
    title: 'Título',
    type: 'Tipo',
    routingRule: 'Regla de enrutamiento',
    route: 'Ruta',
    search: 'Buscar',
    description: 'Descripción',
    isActive: 'Activo'
  },

  tabs: {
    eventsHistory: 'Historial de cambios'
  },

  dialogs: {
    new: 'Nuevo grupo de enrutamiento'
  },

  buttons: {
    add: {
      route: 'Agregar ruta',
      routingRule: 'Agregar regla de enrutamiento'
    }
  },

  hints: {
    notFound: 'No se encontraron grupos de enrutamiento'
  },

  contents: {
    confirm: {
      remove: '¿Confirmar eliminación de {title}?'
    }
  },

  tooltips: {
    isActive: 'Activar o desactivar el grupo de rutas.',
    favorites: 'Marcar el grupo de enrutamiento como favorito',
    routingRules: 'Lista de reglas de enrutamiento',
    remove: '¿Eliminar el grupo de enrutamiento de la lista de grupos seleccionados?',

    filter: {
      isActive: 'Mostrar solo grupos de enrutamiento activos o inactivos'
    }
  },

  subtitles: {
    routingRules: 'Reglas de enrutamiento'
  },

  snackbars: {
    created: 'Grupo de enrutamiento creado con éxito.',
    updated: 'Grupo de enrutamiento actualizado con éxito.',
    removed: 'Grupo de enrutamiento eliminado con éxito.'
  }
}
