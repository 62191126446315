import { buttonModes, colors, components, popperPlacements } from '@/utils'

import button from '@/components/button'
import toc from '@/components/services/documentation/toc'

const className = 'documentation'

function renderContent(h) {
  if (this.html) {
    return h(
      components.card,
      {
        class: `${className}-content-holder`,
        props: {
          outline: true,
          rounded: true
        }
      },
      [
        h(
          'div',
          {
            class: `${className}-content`,
            domProps: { innerHTML: this.html }
          }
        )
      ]
    )
  }
}

function renderToc(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      toc,
      {
        props: {
          headings: this.headings
        }
      }
    )
  } else {
    return h(
      components.menu,
      {
        class: 'w--100',
        props: {
          rounded: true,
          offsetDistance: 8,
          placement: popperPlacements.bottom
        }
      },
      [
        h(
          button,
          {
            style: {
              maxWidth: '100%',
              width: '100%'
            },
            props: {
              label: this.getTranslate(`${className}.buttons.toc`),
              mode: buttonModes.flat,
              color: colors.primary
            },
            slot: 'activator'
          }
        ),
        h(
          toc,
          {
            class: 'pa-2',
            props: {
              headings: this.headings,
              scrollable: false
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: className
    },
    [
      renderToc.call(this, h),
      renderContent.call(this, h)
    ]
  )
}
