import { buttonModes, colors, components, icons, inputModes, isUUID, services, sizes } from '@/utils'

import button from '@/components/button'
import squircle from '@/components/icon/squircle'
import draggable from '@/components/misc/draggable'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'
import sticker from '@/components/misc/sticker'
import preloader from '@/components/preloader'
import rulesReadonly from '@/components/services/tariffs/rules/readonly'
import tag from '@/components/tag'

function renderSelectTariffField(h) {
  if (!this.readonly) {
    return h(
      components.select,
      {
        props: {
          value: this.proxy,
          label: this.getTranslate(`${services.tariffs}.tariffs`),
          mode: inputModes['line-label'],
          service: services.tariffs,
          picks: [ 'id', 'title', 'source', 'data' ],
          rounded: true,
          multiple: true,
          clearable: true,
          autocomplete: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy = event
          }
        },
        scopedSlots: {
          selection: ({ item }) => {
            const [ source, type ] = item.source && item.source.split('.') || []

            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: type || source,
                  title: item.title
                }
              }
            )
          },
          item: ({ item }) => {
            const [ source, type ] = item.source && item.source.split('.') || []

            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: type || source,
                  title: item.title,
                  as: 'text'
                }
              }
            )
          }
        }
      }
    )
  }
}
function renderHeader(h) {
  if (!this.readonly) {
    return h(
      'div',
      {
        class: `${services.tariffs}-generator__header`
      },
      [ renderSelectTariffField.call(this, h) ]
    )
  }
}

function renderDragHandle(h) {
  if (!this.readonly) {
    return h(
      squircle,
      {
        class: 'drag_handle',
        props: {
          icon: icons.drag_handle,
          size: sizes.medium,
          color: colors.grey
        }
      }
    )
  }
}
function renderIndex(h, index) {
  return h(
    tag,
    {
      props: {
        label: this.getTranslate(`${services.tariffs}.labels.index`, { index: index + 1 }),
        size: sizes.huge,
        color: colors.grey
      }
    }
  )
}
function renderTitle(h, item) {
  const [ source, type ] = item.source && item.source.split('.') || []

  return h(
    sticker,
    {
      props: {
        value: this.readonly ? this.getTranslate(`${services.sendings}.types.${type}`) : item,
        label: this.readonly ? this.getTranslate(`${services.tariffs}.tariff`) : this.getTranslate(`${services.sendings}.types.${type || source}`),
        iconSVG: type || source,
        color: colors[type || source],
        service: this.readonly ? undefined : services.tariffs,
        maxWidth: 'fit-content'
      }
    }
  )
}
function renderRemoveButton(h, index) {
  if (!this.readonly) {
    return h(
      button,
      {
        props: {
          label: this.viewport.size.width > 500 ? this.getTranslate('misc.buttons.remove') : undefined,
          icon: this.viewport.size.width < 500 ? icons.clear : undefined,
          mode: buttonModes.flat
        },
        on: {
          click: () => {
            this.remove(index)
          }
        }
      }
    )
  }
}
function renderItemHeader(h, item, index) {
  return h(
    'div',
    {
      class: {
        [`${services.tariffs}-generator__item-header`]: true,
        [`${services.tariffs}-generator__item-header--readonly`]: this.readonly
      }
    },
    [
      renderDragHandle.call(this, h),
      renderIndex.call(this, h, index),
      renderTitle.call(this, h, item),
      renderRemoveButton.call(this, h, index)
    ]
  )
}
function renderItemBody(h, item) {
  const [ source, type ] = item.source && item.source.split('.') || []

  return h(
    rulesReadonly,
    {
      props: {
        rules: item._defaultRules,
        defaultRule: item._defaultRule,
        source: type || source
      }
    }
  )
}
function renderItemContent(h, item, index) {
  if (isUUID(item)) {
    return h(preloader)
  } else {
    return [
      renderItemHeader.call(this, h, item, index),
      renderItemBody.call(this, h, item)
    ]
  }
}
function renderItem(h, item, index) {
  return h(
    'div',
    {
      class: `${services.tariffs}-generator__item`,
      key: item.id
    },
    [ renderItemContent.call(this, h, item, index) ]
  )
}
function renderItems(h) {
  if (Array.isArray(this.proxy) && this.proxy.length) {
    if (this.readonly) {
      return h(
        'div',
        {
          class: `${services.tariffs}-generator__items`
        },
        [ this.proxy.map((item, index) => renderItem.call(this, h, item, index)) ]
      )
    } else {
      return h(
        draggable,
        {
          class: `${services.tariffs}-generator__items`,
          props: {
            value: this.proxy,
            handle: 'drag_handle'
          },
          on: {
            input: event => {
              this.proxy = event
            }
          },
          scopedSlots: {
            item: (item, index) => renderItem.call(this, h, item, index)
          }
        }
      )
    }
  } else {
    return h(components.empty)
  }
}
function renderBody(h) {
  return h(
    'div',
    {
      class: `${services.tariffs}-generator__body`
    },
    [ renderItems.call(this, h) ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.tariffs}-generator`
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h)
    ]
  )
}
