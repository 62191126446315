import { moderation } from './misc'
import { types } from './sendings'

export default {
  title: 'Reglas',

  titles: {
    conditions: 'Condiciones',
    actions: 'Acciones'
  },

  labels: {
    title: 'Título de la regla',
    type: 'Tipo',
    text: 'Texto',
    sender: 'Remitente',
    routingRule: 'Regla de enrutamiento',
    condition: 'Condición',
    providerGroup: 'Grupo de proveedores',
    percentage: 'Porcentaje',
    search: 'Buscar',
    textToCheck: 'Texto para verificar',
    group: {
      pattern: 'Patrón para el grupo de búsqueda'
    },
    add: {
      condition: 'Agregar condición'
    },
    phone: 'Teléfono',
    keywords: 'Listas de palabras clave',
    contactList: 'Lista de contactos',
    fallbacks: 'Alternativas',
    buttons: 'Botones',

    ...types,
    ...moderation
  },

  tags: {
    text: 'Texto',
    sender: 'Remitente',
    recipient: 'Destinatario',
    code: 'Código de operador',
    group: 'Grupo de operadores',
    mcc: 'MCC',
    mnc: 'MNC',
    mccmnc: 'MCC MNC',
    contactList: 'Lista de contactos',
    type: 'Tipo',
    moderation: 'Moderación',
    keywords: 'Palabras clave',
    fallbacks: 'Alternativas',
    buttons: 'Botones'
  },

  hints: {
    noRule: 'Condición aún no seleccionada. Haga clic en esta línea para seleccionar.'
  },

  buttons: {
    add: {
      rule: 'Agregar regla',
      group: 'Agregar grupo',
      condition: 'Agregar condición'
    },
    remove: {
      rule: 'Eliminar regla',
      group: 'Eliminar grupo'
    }
  }
}
