import { buttonModes, colors, components, inputModes, services } from '@/utils'

import button from '@/components/button'
import info from '@/components/info'
import preloader from '@/components/preloader'
import tag from '@/components/tag'
import textOverflow from '@/components/text/overflow'

function renderPreloader(h) {
  if (this.loading) {
    return h(
      'div',
      {
        class: 'preloader__holder',
        style: { minHeight: '50px' }
      },
      [ h(preloader) ]
    )
  }
}

function renderSelect(h) {
  if (this.items.length) {
    return h(
      components.select,
      {
        props: {
          value: this.proxy,
          label: this.getTranslate(`${services.invoices}.labels.company`),
          items: this.items,
          itemValue: 'id',
          picks: [ 'id', 'title', 'vat' ],
          mode: inputModes['line-label'],
          loading: this.loading,
          disabled: this.loading || this.disabled,
          details: false,
          rounded: true,
          required: true
        },
        on: {
          input: event => {
            this.proxy = event
          }
        },
        scopedSlots: {
          selection: ({ item }) => {
            return h(
              'div',
              {
                class: 'w--100 grid grid-gap--8 faic fjcsb',
                style: { gridTemplateColumns: 'repeat(2, auto)' }
              },
              [
                h(
                  tag,
                  {
                    props: {
                      label: item.title,
                      as: 'select'
                    }
                  }
                ),
                h(
                  tag,
                  {
                    props: {
                      label: this.getTranslate(`${services.companies}.vat.${item.vat}`),
                      color: colors.primary,
                      as: 'select'
                    }
                  }
                )
              ]
            )
          },
          item: ({ item }) => {
            return h(
              'div',
              {
                class: 'w--100 grid grid-gap--8 faic fjcsb',
                style: { gridTemplateColumns: '1fr auto' }
              },
              [
                h(
                  textOverflow,
                  {
                    props: {
                      value: item.title
                    }
                  }
                ),
                h(
                  tag,
                  {
                    props: {
                      label: this.getTranslate(`${services.requisites}.vat.${item.vat}`),
                      color: colors.primary,
                      as: 'select'
                    }
                  }
                )
              ]
            )
          }
        }
      }
    )
  }
}

function renderWarning(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        info,
        {
          props: {
            value: this.getTranslate(`${services.invoices}.warnings.notFound.companies`),
            color: colors.error
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.support'),
            mode: buttonModes.flat,
            color: colors.primary
          },
          on: {
            click: () => {
              this.redirect()
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return renderPreloader.call(this, h) || renderSelect.call(this, h) || renderWarning.call(this, h)
}
