export default {
  title: 'Servidores',

  titles: {
    one: 'Servidor',
    create: 'Crear servidor',
    update: 'Actualizar servidor',
    remove: 'Eliminar servidor'
  },

  labels: {
    title: 'Nombre',
    host: 'Host',
    port: 'Puerto',
    processor: 'Procesador',
    search: 'Buscar'
  },

  contents: {
    confirm: {
      remove: 'Confirme la eliminación del servidor.'
    }
  },

  hints: {
    notFound: 'No se encontraron servidores.',
    other: 'Otro'
  },

  tooltips: {
    favorites: 'Marcar servidor como favorito',
    isActive: 'Servidor activo'
  },

  snackbars: {
    created: 'Servidor creado con éxito',
    updated: 'Servidor actualizado con éxito',
    removed: 'Servidor eliminado con éxito'
  }
}
