export default {
  title: 'Rutas',

  titles: {
    full: {
      title: 'Ruta completa',
      create: {
        route: 'Ruta',
        routingRules: 'Regla de enrutamiento',
        providerGroups: 'Grupo de proveedores'
      }
    },
    direct: {
      title: 'Ruta directa',
      create: 'Crear ruta directa'
    },
    create: 'Crear ruta'
  },

  subtitles: {
    routingRules: 'Reglas de enrutamiento',
    providerGroups: 'Grupos de proveedores'
  },

  labels: {
    direct: {
      responsibility: 'Ignorar rutas sugeridas',
      provider: {
        choose: 'Elija un proveedor'
      }
    },
    title: 'Nombre de la ruta',
    routingRules: 'Elija reglas',
    providerGroups: 'Elija grupos de proveedores'
  },

  hints: {
    direct: {
      choose: 'Se encontró una ruta similar.<br>Elija una de las rutas directas ya creadas.'
    }
  },

  buttons: {
    full: 'Completa',
    direct: 'Directa',
    inRoute: 'En ruta',
    inRule: 'En regla',
    create: {
      rule: 'Regla',
      group: 'Grupo'
    }
  },

  types: {
    full: 'Completa',
    direct: 'Directa'
  }
}
