import { mapActions, mapGetters } from 'vuex'

import { components, displays } from '@/utils'

import render from './render'

export default {
  name: components.snackbar,

  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      observer: undefined,

      show: true
    }
  },

  computed: {
    ...mapGetters({ display: 'viewport/display' }),

    $_class() {
      return {
        [`${components.snackbar}`]: true,
        [`${components.snackbar}--mobile`]: this.display === displays.mobile
      }
    }
  },

  mounted() {
    if (this.display === displays.mobile) {
      this.$nextTick(() => this.initBlockElementsObserver())
    }
  },

  beforeDestroy() {
    this.stopBlockElementsObserver()
  },

  methods: {
    ...mapActions({ clearSnackbars: 'notifications/clearSnackbars' }),

    initBlockElementsObserver() {
      this.observer = new MutationObserver(mutations => {
        for (const mutation of mutations) {
          if (mutation.addedNodes.length) {
            const blockElements = document.querySelectorAll([
              `.${components.dialog}`,
              `.${components.menu}__content--active`
            ].join(', '))
            if (blockElements.length) {
              this.clearSnackbars()
            }
          }
        }
      })

      this.observer.observe(document.body, { childList: true })
    },

    stopBlockElementsObserver() {
      if (this.observer) {
        this.observer.disconnect()
        this.observer = undefined
      }
    }
  },

  render
}
