import { names } from './tts'

export default {
  title: 'Crear envíos',

  titles: {
    message: 'Mensaje',
    preview: 'Vista previa del mensaje'
  },

  voice: {
    default: 'Seleccione una voz',
    generate: 'Generar audio',
    voice: 'Voz:',
    names,
    company: {
      aws: 'Amazon',
      yandex: 'Yandex'
    },
    upload: 'Subir archivo',
    misc: {
      generatedFile: 'Se enviará el archivo de audio generado',
      uploadedFile: 'Se enviará el archivo subido',
      can: 'También puede',
      ownFile: 'subir su propio archivo',
      textFile: 'generar un archivo de audio a partir del texto'
    },
    timer: 'Espere {timerCount} seg para la siguiente solicitud'
  },

  attachMenu: {
    attach: 'Adjuntar',
    choose: 'Seleccionar archivo',
    items: {
      file: 'Archivo',
      image: 'Imagen'
    }
  },

  hints: {
    viberTemplates: 'Variaciones posibles: Texto, Texto + Botón, Texto + Botón + Imagen'
  },

  tooltips: {
    createNewUser: 'Crear un nuevo nombre de remitente',
    insertVariables: 'Insertar variables'
  }
}
