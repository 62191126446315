export default {
  title: 'Registration',

  labels: {
    username: 'Username',
    password: 'Password',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'E-mail',
    phone: 'Phone',
    usedReferralCode: 'Referral code'
  },

  buttons: {
    signup: 'Sign Up',
    signin: 'Sign In'
  },

  tooltips: {
    password: {
      generate: 'Generate password'
    }
  },

  hints: {
    username: 'Minimum 5, maximum 16 characters',
    password: 'Create a secure password',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'For password recovery and balance notifications',
    phone: 'Phone',
    required: 'Fields marked with an asterisk are required.'
  },

  errors: {
    username: 'This username is already registered',
    email: 'This e-mail is already registered',
    phone: 'This phone is already registered'
  }
}
