import { isEqual } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default function(options) {
  return {
    name: 'ReferralBillingsProcessedChildOperations',

    mixins: [ proxy({ type: 'array' }) ],

    props: {
      loading: {
        type: Object,
        default: () => ({})
      }
    },

    computed: {
      disabled() {
        let result = false
        for (const item of this.proxy) {
          if (Object.values(item).some(value => value === undefined)) {
            result = true
          }
        }
        if (result) {
          return true
        } else {
          if (isEqual(this.proxy, this.value)) {
            return true
          }
        }

        return false
      }
    },

    methods: {
      watchProxyHandler() {
        return
      },

      save() {
        if (!this.disabled) {
          this.$emit('input', this.proxy)
        }
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
