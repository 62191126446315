import { CSendingTypesGenerallyAvailable } from '@sigmasms/commons'

import selectorSendingType from '@/components/selector/sendingType'

export default function(h, options) {
  return h(
    selectorSendingType,
    {
      props: {
        value: this.proxy,
        items: CSendingTypesGenerallyAvailable,
        view: 'buttons',
        multiple: options.type === 'array',
        disabled: this.disabled,
        readonly: this.readonly
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
