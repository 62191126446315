import Conditions from '@sigma-legacy-libs/essentials/lib/utils/Conditions'

import { buttonModes, components, isObjectEmpty, sizes } from '@/utils'

import button from '@/components/button'
import { className, ruleTags } from '@/components/misc/rules/utils'
import gTag from '@/components/tag'
import mccmnc from '@/components/trees/mccmnc/readonly'
import mccmncValue from '@/components/trees/routes/mccmncValue'
import servicesValue from '@/components/trees/routes/servicesValue'
import simpleValue from '@/components/trees/routes/simpleValue'
import simpleTree from '@/components/trees/simple'

function renderDialogContent(h) {
  const data = { props: { value: this.dialogData } }
  switch (this.dialogType) {
    case 'simple': return h(simpleTree, data)
    case 'mccmnc': return h(mccmnc, data)
  }
}
function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        value: this.showDialog,
        rounded: true,
        maxWidth: 500
      },
      on: {
        input: event => {
          if (event === false) {
            this.dialogData = undefined
            this.dialogType = undefined
          }
          this.showDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'pa-2'
        },
        [ renderDialogContent.call(this, h) ]
      ),

      h(
        'div',
        {
          class: 'fjcfe pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderType(h, tag) {
  return h(
    'div',
    {
      class: 'routes-conditions__condition-type'
    },
    [ this.getTranslate(`${className}.tags.${tag}`) ]
  )
}

function renderValue(h, tag, value) {
  if (value) {
    if (Array.isArray(value) && value.length <= 0) {
      return
    }
    if (isObjectEmpty(value)) {
      return
    }

    switch (tag) {
      case ruleTags.text:
      case ruleTags.recipient: {
        return h(
          gTag,
          {
            props: {
              label: value,
              size: sizes.tiny
            }
          }
        )
      }

      case ruleTags.type:
      case ruleTags.group:
      case ruleTags.sender: {
        return h(
          simpleValue,
          {
            props: {
              value,
              count: this.viewport.breakpoint.mdUp ? 3 : this.viewport.breakpoint.sm ? 2 : 1
            },
            on: {
              click: () => {
                this.dialogData = value
                this.dialogType = 'simple'
                this.showDialog = true
              }
            }
          }
        )
      }

      case ruleTags.keywords:
      case ruleTags.contactList: {
        return h(
          servicesValue,
          {
            props: {
              value,
              count: 2,
              service: tag === ruleTags.contactList ? ruleTags.contactLists : ruleTags.keywordLists
            },
            on: {
              click: () => {
                this.dialogData = value
                this.dialogType = 'simple'
                this.showDialog = true
              }
            }
          }
        )
      }

      case ruleTags.mccmnc: {
        return h(
          mccmncValue,
          {
            props: {
              value,
              count: 1
            },
            on: {
              click: () => {
                this.dialogData = value
                this.dialogType = 'mccmnc'
                this.showDialog = true
              }
            }
          }
        )
      }
    }
  }
}

function renderCondition(h, tag, value) {
  return h(
    'div',
    {
      class: 'routes-conditions__condition'
    },
    [
      renderType.call(this, h, tag),
      renderValue.call(this, h, tag, value)
    ]
  )
}

function renderConditions(h) {
  const conditions = this.value.reduce((result, { type, path, tag, value }) => {
    const tagMeta = Conditions.tagsMeta[tag || type || path]
    if (tagMeta) {
      result.push(renderCondition.call(this, h, tagMeta.tag, value))
    }

    return result
  }, [])

  return h(
    'div',
    {
      class: 'routes-conditions__items'
    },
    [ conditions ]
  )
}

export default function(h) {
  if (Array.isArray(this.value) && this.value.length) {
    return h(
      components.card,
      {
        class: 'routes-conditions',
        props: {
          title: this.getTranslate(`${className}.titles.conditions`),
          flat: true
        }
      },
      [
        renderConditions.call(this, h),
        renderDialog.call(this, h)
      ]
    )
  }
}
