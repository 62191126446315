import { cloneDeep, defaultsDeep, get } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import { DEFAULT_DOCUMENT_TEMPLATES_INVOICE_PAYLOAD } from '@/utils'

import render from './render'

export default {
  name: 'InvoiceDialog',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    show: Boolean,

    OwnerId: String,
    ResellerId: String
  },

  data() {
    return {
      formValidation: false
    }
  },

  computed: {
    validation() {
      const amount = get(this.proxy, 'data.SERVICE_PRICE', 0)

      return (
        !!this.formValidation &&
        !!this.proxy.requisiteId &&
        !!this.proxy.companyId &&
        !!this.proxy.type &&
        !isNaN(amount) &&
        amount > 0
      )
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  methods: {
    _inputFilter(data) {
      if (!data) {
        return cloneDeep(DEFAULT_DOCUMENT_TEMPLATES_INVOICE_PAYLOAD)
      }

      defaultsDeep(data, cloneDeep(DEFAULT_DOCUMENT_TEMPLATES_INVOICE_PAYLOAD))

      return data
    },

    close() {
      this.$emit('show', {
        event: false,
        validation: this.validation
      })
    },
    inputHandler(data) {
      this.$emit('input', data)
      this.close()
    },
    showHandler(event) {
      this.$emit('show', {
        event,
        validation: this.validation
      })
    }
  },

  render
}
