export default {
  title: 'Registro',

  labels: {
    username: 'Nombre de usuario',
    password: 'Contraseña',
    firstName: 'Nombre',
    lastName: 'Apellido',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    usedReferralCode: 'Código de referencia'
  },

  buttons: {
    signin: 'Iniciar sesión',
    signup: 'Registrarse'
  },

  tooltips: {
    password: {
      generate: 'Generar contraseña'
    }
  },

  hints: {
    username: 'Mínimo 5, máximo 16 caracteres',
    password: 'Cree una contraseña segura',
    firstName: 'Nombre',
    lastName: 'Apellido',
    email: 'Para recuperación de contraseña y notificaciones de saldo',
    phone: 'Teléfono',
    required: 'Los campos marcados con un asterisco son obligatorios.'
  },

  errors: {
    username: 'Este nombre de usuario ya está registrado',
    email: 'Este correo electrónico ya está registrado',
    phone: 'Este teléfono ya está registrado'
  }
}
