import { cloneDeep } from 'lodash'

import { buttonModes, colors, generateUniqueKey, icons, services } from '@/utils'

import button from '@/components/button'
import draggable from '@/components/misc/draggable'
import { className } from '@/components/misc/rules/utils'
import rule from '@/components/services/tariffs/rules/rule'

function renderItem(h, item, index) {
  return h(
    rule, {
      props: {
        value: cloneDeep(item),
        target: this.proxy,
        source: this.source,
        index
      },
      key: generateUniqueKey(),
      on: {
        input: event => {
          this.proxy[index] = event
        }
      }
    }
  )
}
function renderItems(h) {
  if (this.proxy.length) {
    return h(
      draggable,
      {
        class: `${services.tariffs}-rules__items`,
        props: {
          value: this.proxy,
          handle: 'drag_handle'
        },
        on: {
          input: event => {
            this.proxy = event
          }
        },
        scopedSlots: {
          item: (item, index) => {
            return renderItem.call(this, h, item, index)
          }
        }
      }
    )
  }
}

function renderAddRuleButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate(`${className}.buttons.add.rule`),
        icon: icons.add,
        mode: buttonModes.flat,
        color: colors.primary
      },
      on: {
        click: () => {
          this.add()
        }
      }
    }
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: `${services.tariffs}-rules__footer`
    },
    [ renderAddRuleButton.call(this, h) ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.tariffs}-rules`
    },
    [
      renderItems.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
