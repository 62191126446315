import { alphabets } from '@/utils'

import render from './render'

export default {
  name: 'TextHighlight',

  props: {
    value: String
  },

  computed: {
    highlight() {
      if (this.value) {
        const className = 'text-highlight'
        const renderSpan = (text, alphabet) => {
          return `<span class="${className}--${alphabet}">${text}</span>`
        }

        return this.value.replace(
          /([А-Яа-яЁё]+)|([A-Za-z]+)|([^А-Яа-яЁёA-Za-z]+)/g,
          (other, cyrillic, latin, nonAlpha) => {
            if (cyrillic) {
              return renderSpan(cyrillic, alphabets.cyrillic)
            } else if (latin) {
              return renderSpan(latin, alphabets.latin)
            } else if (nonAlpha) {
              return renderSpan(nonAlpha, alphabets.cyrillic)
            } else {
              return other
            }
          }
        )
      }
    }
  },

  render
}
