export default {
  title: 'Tareas',

  schedule: {
    now: 'Iniciar ahora',
    delay: 'Programar para ',
    delayed: 'Programado para '
  },

  labels: {
    id: 'ID',
    title: 'Título',
    cycle: {
      batch: 'Cantidad de mensajes',
      every: 'Cada (segundos)'
    },
    owner: 'Propietario',
    creator: 'Creador',
    type: 'Tipo',
    state: 'Estado',
    data: {
      source: 'Fuente',
      amount: 'Cantidad',
      frequency: 'Frecuencia',
      endsAt: 'Cuándo terminar'
    },
    startsAt: 'Cuándo empezar',
    analytics: 'Analítica',
    contactLists: 'Listas de contactos'
  },

  headers: {
    title: 'Título',
    task: 'Tarea',
    progress: 'Progreso',
    type: 'Tipo',
    state: 'Estado',
    sent: 'Enviado',
    statistic: 'Estadística',
    errored: 'Con error',
    text: 'Texto',
    owner: 'Propietario',
    actions: 'Acciones'
  },

  dialogs: {
    create: 'Crear nueva tarea'
  },

  states: {
    scheduled: 'Programado',
    running: 'En ejecución',
    paused: 'Pausado',
    completed: 'Completado',
    failed: 'Fallido'
  },

  simpleStates: {
    scheduled: 'Activo',
    running: 'Activo',
    paused: 'Pausado',
    completed: 'Completado',
    failed: 'Fallido'
  },

  actions: {
    resume: 'Reanudar',
    start: 'Iniciar',
    schedule: 'Programar',
    paused: 'Pausar'
  },

  misc: {
    receivers: 'Total de destinatarios',
    sent: 'Enviado',
    errored: 'Con error',
    rest: 'Restante por enviar',
    include: {
      lists: 'Listas incluidas',
      recipients: 'Destinatarios incluidos'
    },
    exclude: {
      lists: 'Listas excluidas',
      recipients: 'Destinatarios excluidos'
    },
    total: 'Total'
  },

  titles: {
    analytics: 'Analítica de tareas',
    filter: 'Filtro de tareas'
  },

  types: {
    bulk: 'Envío masivo',
    'sendings:bulk': 'Envíos masivos',
    'payments:recurrent': 'Recarga recurrente',
    'billings:operations': 'Operación financiera'
  },

  subheaders: {
    include: {
      lists: 'Listas incluidas',
      recipients: 'Destinatarios incluidos'
    },
    exclude: {
      lists: 'Listas excluidas',
      recipients: 'Destinatarios excluidos'
    },
    error: {
      lists: 'Con error'
    },
    errors: 'Errores'
  },

  contents: {
    confirm: {
      remove: '¿Está seguro de que desea eliminar la tarea {title}?'
    }
  },

  buttons: {
    today: 'Hoy',
    twoWeeks: 'Programadas',
    analytics: 'Analítica',
    refresh: 'Actualizar'
  },

  confirm: {
    remove: {
      title: 'Confirmar eliminación de tarea',
      text: 'Está a punto de eliminar la tarea {title}.'
    }
  },

  frequencies: {
    once: 'Una vez',
    daily: 'Diariamente',
    weekly: 'Semanalmente',
    monthly: 'Mensualmente',

    title: {
      once: 'Una vez',
      daily: 'Diario',
      weekly: 'Semanal',
      monthly: 'Mensual'
    }
  },

  tooltips: {
    refresh: 'Actualizar datos de la tabla aplicando el filtro seleccionado.'
  },

  snackbars: {
    updated: 'Tarea actualizada con éxito',
    removed: 'Tarea eliminada con éxito',
    created: 'Tarea creada con éxito'
  }
}
