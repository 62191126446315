export default {
  titles: {
    generate: 'Generar texto del mensaje',
    additional: 'Configuraciones adicionales',
    text: 'Texto generado'
  },

  labels: {
    text: 'Texto del mensaje',
    messageTheme: 'Tema del mensaje',
    occasion: 'Situación o motivo',
    smsSegments: 'Número de segmentos SMS',
    otherChars: 'Número de caracteres',
    additionalNotes1: 'Observaciones',
    rephraseText: 'Reformular',
    addEmojis: 'Usar emojis',
    endQuestion: 'Terminar con la siguiente pregunta',
    emotionalTone: 'Tono emocional',
    language: 'Idioma del mensaje'
  },

  emotionalTones: {
    joyful: 'Alegre',
    neutral: 'Neutral',
    businesslike: 'Profesional',
    energetic: 'Enérgico',
    enthusiastic: 'Entusiasta',
    excited: 'Emocionado',
    optimistic: 'Optimista',
    informative: 'Informativo',
    inspiring: 'Inspirador'
  },

  infos: {
    additional: 'Para obtener un resultado más preciso, complete las "Configuraciones adicionales" a continuación.',
    text: 'Aquí se mostrará el texto generado'
  },

  hints: {
    messageTheme: 'Tema clave del mensaje',
    occasion: 'Describa la esencia principal de la situación',
    smsSegments: 'De 1 a 10 segmentos como máximo',
    otherChars: 'Hasta 1000 caracteres como máximo',
    additionalNotes1: 'Observaciones adicionales para el texto',
    rephraseText: 'Texto que necesita ser reformulado'
  },

  tooltips: {
    generate: 'Utilizar el modelo generativo para crear el texto del mensaje'
  },

  warnings: {
    limit: 'Se ha superado el límite de solicitudes. Inténtelo más tarde.'
  },

  buttons: {
    generate: 'Generar',
    additional: 'Configuraciones adicionales'
  },

  stats: {
    characters: 'Caracteres: {value}',
    segments: 'Segmentos: {value}',
    encoding: 'Codificación: {value}'
  }
}
