export default {
  title: 'Reglas de enrutamiento',

  titles: {
    create: 'Crear regla de enrutamiento'
  },

  routingRule: 'Regla de enrutamiento',
  routingRules: 'Reglas de enrutamiento',
  one: 'Regla de enrutamiento',
  many: 'Reglas de enrutamiento',
  condition: 'Condición',
  conditions: 'Condiciones',
  ruleType: 'Tipo de regla',
  percentage: 'Porcentaje',

  labels: {
    title: 'Nombre de la regla',
    type: 'Tipo',
    routingRule: 'Regla',
    condition: 'Condición',
    providerGroup: 'Grupo de proveedores',
    percentage: 'Porcentaje',
    search: 'Buscar',
    textToCheck: 'Texto para verificar',
    phone: 'Teléfono',
    isActive: 'Activo',

    group: {
      pattern: 'Patrón del grupo'
    },

    add: {
      condition: 'Agregar condición'
    }
  },

  tabs: {
    eventsHistory: 'Historial de cambios'
  },

  dialogs: {
    new: 'Nueva regla de enrutamiento'
  },

  rules: {
    payload: {
      text: 'Texto',
      sender: 'Remitente',
      recipient: 'Destinatario'
    },
    meta: {
      _recipientData: {
        code: 'Código del operador',
        group: 'Grupo del operador'
      }
    }
  },

  hints: {
    notFound: 'No se encontraron reglas de enrutamiento',
    wrongType: 'Se especificó un tipo de mensaje no compatible.',
    willBeCreated: 'Será creado',
    noRule: 'Aún no se ha seleccionado una condición. Haga clic en esta línea para seleccionar.',
    noRules: 'No se ha seleccionado ninguna condición. Haga clic en agregar condición.',
    click: 'Haga clic para editar.'
  },

  tooltips: {
    rules: 'Lista de reglas para el enrutamiento de mensajes.',
    conditions: 'Lista de condiciones para el enrutamiento de mensajes.',
    providerGroups: 'Lista de grupos de proveedores.',
    isActive: 'Activar o desactivar la regla.',
    favorites: 'Marcar la regla de enrutamiento como favorita',

    add: {
      condition: 'Agregar condición'
    },

    filter: {
      isActive: 'Mostrar solo reglas de enrutamiento activas o inactivas'
    }
  },

  subtitles: {
    rules: 'Reglas',
    conditions: 'Condiciones',
    providerGroups: 'Grupos de proveedores'
  },

  buttons: {
    add: {
      condition: 'Agregar condición',
      providerGroup: 'Agregar grupo de proveedores'
    },
    routingRule: 'Seleccionar regla'
  },

  snackbars: {
    created: 'Regla de enrutamiento creada con éxito.',
    updated: 'Regla de enrutamiento actualizada con éxito.',
    removed: 'Regla de enrutamiento eliminada con éxito.'
  },

  errors: {
    regexp: 'Error en la expresión regular',
    phone: 'Teléfono no encontrado'
  }
}
