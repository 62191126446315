export default {
  title: 'Proveedores inactivos',

  titles: {
    fix: 'Crear duplicados de proveedores inactivos',
    confirm: {
      update: 'Confirme la actualización',
      cloneProvider: 'Confirme la creación del duplicado'
    }
  },

  labels: {
    update: {
      check: 'He leído y entendido el texto de confirmación y quiero actualizar la lista de proveedores inactivos',
      showUpdateDialog: 'Soy consciente de lo que hago, no muestres más este diálogo. Lo recordaremos solo para este navegador.'
    }
  },

  contents: {
    confirm: {
      update: '¿Está seguro de que desea actualizar la lista de proveedores inactivos?<br><br>La lista actual se restablecerá y se realizarán nuevas solicitudes para formar una nueva lista, lo que puede llevar algún tiempo y afectar negativamente el rendimiento de la plataforma. No realice esta solicitud con más frecuencia que una vez cada 5 minutos.<br><br>Si algo no funciona o cree que no está funcionando correctamente, comuníquese con los desarrolladores, no continúe realizando solicitudes.',
      cloneProvider: 'Se creará un clon del proveedor con todas las propiedades, pero con un nombre diferente. No se realizará ninguna acción con el proveedor actual. Después de la creación, se redirigirá automáticamente a la tarjeta del nuevo proveedor.'
    }
  },

  tooltips: {
    fix: 'Al presionar este botón, se creará un clon del proveedor seleccionado.<br>Se copiarán todas las propiedades del proveedor antiguo, como el nombre, servidor, credenciales, etc.<br>Se agregará un prefijo (1) al nombre si no lo tiene o se incrementará el número del prefijo si ya existe.<br>El nuevo proveedor se creará activado (isActive === true).<br><br>El proveedor original será desactivado (isActive = false) y eliminado del grupo de proveedores.<br>El nuevo proveedor ocupará su lugar en el grupo con la misma prioridad.',
    cloneProvider: 'Se creará un clon del proveedor con todas las propiedades, pero con un nombre diferente. No se realizará ninguna acción con el proveedor actual. Después de la creación, se redirigirá automáticamente a la tarjeta del nuevo proveedor.'
  },

  warnings: {
    general: 'Esta es una página de búsqueda de grupos de proveedores con proveedores inactivos en su interior. ¡La página es experimental!<br>Todas las acciones en esta página realizan consultas complejas y pesadas a la plataforma, por lo tanto, por favor, no presione los botones más rápido que una taza de café con un paseo hasta la máquina de café.<br>Si algo no funciona o cree que no está funcionando correctamente, comuníquese con los desarrolladores y no continúe realizando solicitudes.'
  }
}
