import Vue from 'vue'

import proxy from '@sigma-legacy-libs/g-proxy'

import { generateServices, globalErrorHandler, globalErrorProcessor, permissionPresets, services } from '@/utils'

import { dialogTypes } from '@/components/file/utils'

import render from './render'

export default {
  name: 'FileDialog',

  mixins: [
    proxy({ type: 'object' }),
    generateServices({
      name: services.storage,

      find: false,
      create: false,

      remove: { redirect: false }
    })
  ],

  props: {
    show: Boolean,
    type: String,
    googleFileURL: String
  },

  data() {
    return {
      loading: false,

      showEditExpireMenu: false
    }
  },

  computed: {
    $_props() {
      const result = {
        value: this.show,
        rounded: this.viewport.breakpoint.mdUp,
        closeOnClick: this.type !== dialogTypes.export,
        closeOnEsc: this.type !== dialogTypes.export
      }

      switch (this.type) {
        case dialogTypes.editMeta:
          result.title = this.getTranslate(`${services.storage}.titles.properties`)
          break
        case dialogTypes.remove:
          result.title = this.getTranslate('commons.titles.confirm.remove')
          break
        case dialogTypes.source:
          result.title = this.getTranslate('commons.titles.data')
          break
      }

      switch (this.type) {
        case dialogTypes.editMeta:
        case dialogTypes.export:
        case dialogTypes.remove:{
          result.maxWidth = 500
          break
        }
        case dialogTypes.edit:{
          result.fullscreen = true
          break
        }
        case dialogTypes.source:{
          result.maxWidth = 1000
          break
        }
      }

      return result
    },

    $_on() {
      return {
        input: event => {
          this.hideDialog(event)
        }
      }
    },

    rows() {
      const result = [ 'id', 'hash', 'size', 'type', 'createdAt', 'updatedAt' ]
      if (this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)) {
        result.push('owner')
      }

      if (!this.proxy.locks.length) {
        result.push('willBeDeleted')
      }

      return result
    }
  },

  methods: {
    hideDialog(event = false) {
      if (this.type !== dialogTypes.export) {
        this.loading = false
        this.$emit('show', event)
      }
    },

    async editMetaFile() {
      if (this.proxy.title) {
        try {
          await this.rest[services.storage].update(this.proxy)
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.hideDialog()
        }
      }
    },

    async removeFile() {
      if (this.proxy.id) {
        try {
          await this.rest[services.storage].remove(this.proxy.id)
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.hideDialog()
        }
      }
    },

    async editFile() {
      this.loading = true
      setTimeout(() => {
        Vue.$GRequest
          .remove(`/${services.storage}/${dialogTypes.edit}/`, this.proxy.id)
          .then(() => this.hideDialog())
          .catch(() => this.hideDialog())
      }, 5 * 1000)
    }
  },

  render
}
