import Vue from 'vue'

import { components, inputModes, services } from '@/utils'

import buttonUpDown from '@/components/button/upDown'
import selectorSendingType from '@/components/selector/sendingType'
import TariffsRules from '@/components/services/tariffs/rules'
import TariffsDefaultRule from '@/components/services/tariffs/rules/rule/default'

function renderTypeField(h) {
  return h(
    selectorSendingType,
    {
      props: {
        value: this.proxy.source,
        label: this.getTranslate(`${services.tariffs}.labels.source`),
        items: this.sourceItems,
        format: value => value.replace(/sendings\.|subscription\./g, ''),
        mode: inputModes['line-label'],
        required: true
      },
      on: {
        input: event => {
          this.proxy.source = event
        }
      }
    }
  )
}

function renderTariffRules(h) {
  return h(
    TariffsRules,
    {
      props: {
        value: this.proxy.data.rules,
        source: this.proxy.source ? this.proxy.source.split('.')[1] : undefined,
        isNew: this.isNew,
        hasDefault: !!this.proxy._defaultRule
      },
      on: {
        input: event => {
          this.proxy.data.rules = event
        }
      }
    }
  )
}

function renderDefaultRule(h) {
  if (this.proxy._defaultRule) {
    return h(
      TariffsDefaultRule,
      {
        props: {
          value: this.proxy._defaultRule,
          isMultiple: !!(this.proxy.data.rules.length > 0),
          source: this.proxy.source ? this.proxy.source.split('.')[1] : undefined
        },
        on: {
          input: event => {
            this.proxy._defaultRule = event
          },
          remove: () => {
            Vue.delete(this.proxy, '_defaultRule')
          }
        }
      }
    )
  }
}

function renderRefund(h) {
  if (this.proxy.source && this.proxy.source.split('.')[0] === services.sendings) {
    return h(
      components.switch,
      {
        props: {
          value: this.proxy.data.options.refundOnFailed,
          label: this.getTranslate(`${services.tariffs}.labels.refundOnFailed`)
        },
        on: {
          input: event => {
            this.proxy.data.options.refundOnFailed = event
          }
        }
      }
    )
  }
}
function renderNoPayments(h) {
  if (this.proxy.source && this.proxy.source.split('.')[0] === services.sendings) {
    return h(
      components.switch,
      {
        props: {
          value: this.proxy.data.options.noPayments,
          label: this.getTranslate(`${services.tariffs}.labels.noPayments`)
        },
        on: {
          input: event => {
            this.proxy.data.options.noPayments = event
          }
        }
      }
    )
  }
}
function renderSwitches(h) {
  return h(
    components.card,
    {
      class: `${services.tariffs}-form-switches`,
      props: {
        outline: true,
        rounded: true
      }
    },
    [
      renderRefund.call(this, h),
      renderNoPayments.call(this, h)
    ]
  )
}
function renderCommentField(h) {
  return h(
    components.textarea,
    {
      props: {
        value: this.proxy.data.hidden.comment,
        label: this.getTranslate(`${services.tariffs}.labels.comment`),
        mode: inputModes['line-label'],
        grow: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.data.hidden.comment = event
        }
      }
    }
  )
}
function renderOptionalFields(h) {
  return h(
    'div',
    {
      class: `${services.tariffs}-form-optional`
    },
    [
      renderSwitches.call(this, h),
      renderCommentField.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    components.form,
    {
      class: {
        [`${services.tariffs}-form`]: true,
        [`${services.tariffs}-form--new`]: this.isNew
      }
    },
    [
      renderTypeField.call(this, h),
      renderTariffRules.call(this, h),
      renderDefaultRule.call(this, h),
      renderOptionalFields.call(this, h),

      h(buttonUpDown)
    ]
  )
}
