import { colors, components } from '@/utils'

import squircle from '@/components/icon/squircle'
import preloader from '@/components/preloader'
import textOverflow from '@/components/text/overflow'

function renderPreloader(h) {
  if (this.loading) {
    return h(
      'div',
      {
        class: `${components['list-item']}__preloader`
      },
      [
        h(
          preloader,
          {
            props: {
              size: 18
            }
          }
        )
      ]
    )
  }
}

function renderIconContent(h, props) {
  return h(
    squircle,
    {
      props: {
        iconSize: 18,
        color: colors.grey,
        ...props
      }
    }
  )
}
function renderSlot(h, slot) {
  switch (slot) {
    case 'icon': {
      if (this.icon || this.iconSVG) {
        return renderIconContent.call(this, h, {
          icon: this.icon,
          iconSVG: this.iconSVG,
          color: this.$iconColor
        })
      } else if (typeof this.$scopedSlots.icon === 'function') {
        return this.$scopedSlots.icon()
      }

      return this.$slots.icon
    }
    case 'suffix': {
      if (this.suffix) {
        return renderIconContent.call(this, h, { icon: this.suffix })
      } else if (typeof this.$scopedSlots.suffix === 'function') {
        return this.$scopedSlots.suffix()
      }

      return this.$slots.suffix
    }
  }
}
function renderLabelContent(h) {
  if (this.label) {
    return h(
      textOverflow,
      {
        props: {
          value: this.label
        }
      }
    )
  }

  return this.$slots.default
}
function renderLabel(h) {
  return h(
    'div',
    {
      class: `${components['list-item']}__label`
    },
    [ renderLabelContent.call(this, h) ]
  )
}
function renderHolder(h) {
  return h(
    'div',
    {
      class: `${components['list-item']}__holder`
    },
    [
      renderSlot.call(this, h, 'icon'),
      renderLabel.call(this, h),
      renderSlot.call(this, h, 'suffix')
    ]
  )
}

function renderDialogSlot() {
  if (typeof this.$scopedSlots.dialog === 'function') {
    return this.$scopedSlots.dialog()
  }

  return this.$slots.dialog
}

export default function(h) {
  return h(
    this.to ? 'router-link' : 'div',
    {
      attrs: { role: 'listitem' },
      class: this.$class,
      props: { to: this.to },
      on: this.$eventHandlers
    },
    [
      renderHolder.call(this, h),
      renderPreloader.call(this, h),
      renderDialogSlot.call(this, h)
    ]
  )
}
