import Vue from 'vue'

import proxy from '@sigma-legacy-libs/g-proxy'

import { generateServices, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

const serviceNameViaPoint = services['otp-settings/widgets'].split('/').join('.')

export default {
  name: 'SecondCol',

  mixins: [
    proxy({ type: 'object' }),

    generateServices({
      name: services['otp-settings/channels'],

      backendGeneration: 'nest',

      get: false,
      create: false,
      update: false,
      remove: false
    })
  ],

  data() {
    return {
      loading: false
    }
  },

  computed: {
    filteredChannels() {
      return this.proxy.otpWidgetChannelSettings.filter(({ otpChannelSettingsId, priority }) => {
        if (otpChannelSettingsId && typeof priority === 'number') {
          return {
            otpChannelSettingsId,
            priority
          }
        }
      })
    },

    $disabled() {
      return this.filteredChannels.length === 0
    }
  },

  mounted() {
    this.rest[services['otp-settings/channels']].find()
  },

  methods: {
    async attachChannels() {
      try {
        this.loading = true
        const { data } = await Vue.$GRequest.update(
          `/n/${services['otp-settings/widgets']}`,
          `${this.$route.params.id}/channels/bulk`,
          { otpWidgetChannelSettings: this.filteredChannels }
        )
        if (data) {
          this.addSnackbar({
            text: this.getTranslate(`${serviceNameViaPoint}.snackbars.channels.attached`),
            type: 'success'
          })
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
