import { services } from '@/utils'

import updateStatus from '@/components/services/yclients/item/actions/update-status'
import actions from '@/components/templates/service/item/actions'

function renderUpdateStatus(h) {
  return h(
    updateStatus,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderActions(h) {
  return h(
    actions,
    {
      props: {
        value: this.value,
        service: services.yclients
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        [`${services.yclients}-item__actions`]: true,
        'hover-child': this.viewport.breakpoint.mdUp
      }
    },
    [
      renderUpdateStatus.call(this, h),
      renderActions.call(this, h)
    ]
  )
}
