import { get } from 'lodash'

import { services } from '@/utils'

import render from './render'

const floor = (value, divider) => divider > 0 ? Math.floor(value / divider * 100) : 0

export default {
  name: 'TasksCounts',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    $value() {
      const { total = 0, completed = 0, errored = 0 } = get(this.value, 'meta', {})
      const remaining = total - (completed + errored)

      const tooltips = {
        total: `${this.getTranslate(`${services.tasks}.misc.receivers`)}: ${total}`,
        completed: `${this.getTranslate(`${services.tasks}.misc.sent`)}: ${completed} (${floor(completed, total)}%)`,
        errored: `${this.getTranslate(`${services.tasks}.misc.errored`)}: ${errored} (${floor(errored, total)}%)`,
        remaining: `${this.getTranslate(`${services.tasks}.misc.rest`)}: ${remaining} (${floor(remaining, total)}%)`
      }

      return {
        count: {
          total,
          completed,
          errored,
          remaining
        },
        tooltips
      }
    }
  },

  render
}
