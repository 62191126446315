import { getPrice } from '@sigma-frontend-libs/commons'
import { BILLING_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, components, services } from '@/utils'

import formatTable from '@/components/format/table'
import tag from '@/components/tag'

const colorsByStatus = {
  [BILLING_STATUS.pending]: colors.info,
  [BILLING_STATUS.refunded]: colors.info,
  [BILLING_STATUS.complete]: colors.success,
  [BILLING_STATUS.invalid]: colors.error
}

function renderSource(h, { source }) {
  const [ s, t ] = source.split('.')
  const type = t || s

  if (type) {
    return h(
      tag,
      {
        props: {
          label: this.getTranslate(`${services.billings}.sources.${type}`),
          color: type
        }
      }
    )
  }
}
function renderStatus(h, { status }) {
  if (status) {
    return h(
      tag,
      {
        props: {
          label: this.getTranslate(`${services.billings}.statuses.${status}`),
          color: colorsByStatus[status]
        }
      }
    )
  }
}
function renderPrice(h, { amount }) {
  return h(
    tag,
    {
      props: {
        label: getPrice(amount),
        color: colors.primary
      }
    }
  )
}
function renderHeader(h, item) {
  return h(
    'div',
    {
      class: 'faic text-upper grid-gap--8 px-2'
    },
    [
      renderSource.call(this, h, item),
      renderStatus.call(this, h, item),
      renderPrice.call(this, h, item)
    ]
  )
}

function renderBody(h, value) {
  return h(
    formatTable,
    {
      props: {
        value,
        service: services.billings
      }
    }
  )
}

function renderItem(h, item) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderHeader.call(this, h, item),
      renderBody.call(this, h, item)
    ]
  )
}

export default function(h) {
  if (Array.isArray(this.data) && this.data.length) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [ this.data.map(item => renderItem.call(this, h, item)) ]
    )
  }

  return h(components.empty)
}
