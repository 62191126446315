import Vue from 'vue'

import { isEqual } from 'lodash'

import { EVENTSHISTORY_ASSOCIATED_SERVICES, EVENTSHISTORY_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { globalErrorHandler, globalErrorProcessor, isUUID, projectName, services } from '@/utils'

import { $order, calcChanges } from '@/components/services/eventsHistory/utils'

import render from './render'

export default {
  name: 'Tab',

  props: {
    entityId: {
      type: String,
      required: true,
      validator: isUUID
    }
  },

  data() {
    let limitFromLocalStorage = 25
    try {
      limitFromLocalStorage = JSON.parse(window.localStorage.getItem(`${projectName}:pagination:limit:${services.eventsHistory}`))
    } catch (error) {
    }

    return {
      items: [],

      pagination: {
        limit: limitFromLocalStorage || 10,
        offset: 0,
        total: 0
      },

      loading: false,
      showDialog: false,

      currentTab: 'oldValues',

      compareData: {
        oldValues: {},
        newValues: {}
      },

      compareService: undefined,

      compareId: ''
    }
  },

  computed: {
    types() {
      return Object.keys(this.compareData).sort((a, b) => b.localeCompare(a))
    },

    tabForCreated() {
      return Object.keys(this.compareData).length === 1 && Object.keys(this.compareData).includes('newValues')
    },

    dialogTitle() {
      if (this.tabForCreated) {
        return this.getTranslate(`${services.eventsHistory}.labels.created`)
      }

      return this.getTranslate(`${services.eventsHistory}.title`)
    }
  },

  watch: {
    'pagination.offset': {
      handler() {
        this.getItems()
      },
      deep: true
    }
  },

  mounted() {
    this.getItems()

    Vue.$socket.on(`${services.eventsHistory}.created`, data => {
      if (data.entityId && data.entityId === this.entityId) {
        if (this.pagination.offset === 0) {
          this.items.unshift(data)
          if (this.items.length > this.pagination.limit) {
            this.items.splice(this.items.length - 1, 1)
          }
        }
        this.pagination.total++
      }
    })
  },

  methods: {
    async getItems() {
      try {
        this.loading = true

        const { data } = await Vue.$GRequest.find(services.eventsHistory, {
          query: {
            entityId: this.entityId,
            $offset: this.pagination.offset,
            $limit: this.pagination.limit,
            $order
          }
        })

        if (data) {
          this.items = data.data
          this.pagination.total = data.total
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    },

    async getNextItem(item, offset) {
      try {
        const query = {
          entityId: this.entityId,
          service: EVENTSHISTORY_ASSOCIATED_SERVICES[item.service],
          $offset: offset,
          $order
        }

        const { data } = await Vue.$GRequest.find(services.eventsHistory, { query })

        if (Array.isArray(data.data) && data.data.length) {
          const index = data.data.findIndex(v => v.data.id === item.data.id)
          if (index !== -1) {
            return data.data[index]
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    },

    async compareItems(item, index) {
      let nextItem
      this.compareId = item.data.id
      this.compareService = item.service
      const comparedItem = this.items[index]
      if (comparedItem.type === EVENTSHISTORY_TYPES.created) {
        nextItem = { data: {} }
        this.compareData = calcChanges.call(this, item, nextItem)
        this.currentTab = 'newValues'
        this.showDialog = true
      } else {
        switch (comparedItem.service) {
          case 'permissionsUsers':
          case 'settingsUserUsers': {
            const compareValues = {
              old: {},
              new: {}
            }
            for (const type of Object.keys(compareValues)) {
              compareValues[type] = { data: {} }
              const { value } = comparedItem.data[type]
              for (const field of Object.keys(comparedItem.data[type])) {
                if (field !== 'value') {
                  compareValues[type].data[comparedItem.data[type][field]] = value
                }
              }
            }
            if (isEqual(compareValues.new.data, compareValues.old.data)) {
              this.compareData.oldValues = compareValues.old.data
              this.compareData.newValues = compareValues.new.data
            } else {
              this.compareData = calcChanges.call(this, compareValues.new, compareValues.old)
            }
            this.showDialog = true

            break
          }

          default: {
            if (this.items[index + 1]) {
              nextItem = this.items.find((v, i) => {
                return i > index
                  && v.service === comparedItem.service
                  && comparedItem.type === EVENTSHISTORY_TYPES.updated
                  && v.data.id === comparedItem.data.id
              })
            }
            if (!nextItem) {
              const response = await this.getNextItem(item, index + 1)
              if (response) {
                nextItem = response
              }
            }
            if (nextItem && !isEqual(item.data, nextItem.data)) {
              this.compareData = calcChanges.call(this, item, nextItem)
              this.showDialog = true
            }
            break
          }
        }
      }
    },

    getLabel(value) {
      const label = []
      switch (this.compareService) {
        case 'permissionsUsers': {
          const [ , service, type ] = value.split('.')
          label.push(this.getTranslate(`${services.eventsHistory}.labels.${this.compareService}`))
          label.push([
            this.getTranslate(`${services.permissions}.labels.${type}`),
            this.getTranslate(`${services.permissions}.services.${service}`).toLowerCase()
          ].join(' '))

          break
        }

        default: {
          const firstLowerCase = value[0].toLowerCase() + value.slice(1)
          label.push(this.getTranslate(`${services.eventsHistory}.updatedValues.${firstLowerCase}`))

          break
        }
      }

      return label.join(': ')
    }
  },

  render
}
