export default {
  title: 'Plantillas para moderación',
  pattern: 'Patrón',

  titles: {
    new: 'Nueva plantilla para moderación',
    create: 'Crear nueva plantilla para moderación',
    users: 'Usuarios'
  },

  headers: {
    moderation: 'Moderación',
    title: 'Título',
    comment: 'Comentario'
  },

  labels: {
    title: 'Título',
    name: 'Nombre del remitente',
    text: 'Texto',
    type: 'Tipo de envío',
    moderation: 'Moderación',
    search: 'Buscar',
    id: 'ID',
    owner: 'Propietario',
    isActive: 'Activar o desactivar plantilla para moderación',
    checkPattern: 'Comprobar patrón',
    sendername: 'Nombre del remitente',

    meta: {
      comment: 'Comentario',
      adminComment: 'Comentario del administrador',
      adminCommentPrivate: 'Comentario privado del administrador',
      resellerComment: 'Comentario del revendedor',
      resellerCommentPrivate: 'Comentario privado del revendedor'
    }
  },

  statuses: {
    disabled: 'No solicitado',
    requested: 'Solicitado',
    moderation: 'En moderación',
    approved: 'Aprobado',
    rejected: 'Rechazado'
  },

  contents: {
    confirm: {
      remove: '¿Eliminar plantilla para moderación?'
    }
  },

  errors: {
    isSms: 'Solo letras latinas o números, no más de 15 caracteres',
    pattern: 'El patrón no pasó la verificación'
  },

  buttons: {
    users: 'Usuarios {count}'
  },

  hints: {
    emptyState: 'No se encontró ninguna plantilla para moderación',
    notFound: 'No se encontraron plantillas para moderación',
    checkPattern: 'Ingrese texto de prueba para verificar el patrón de moderación'
  },

  tooltips: {
    favorites: 'Marcar plantilla para moderación como favorita',
    filter: {
      isActive: 'Mostrar plantillas para moderación desactivadas'
    },
    isActive: {
      true: 'Activada',
      false: 'Desactivada'
    }
  },

  snackbars: {
    created: 'Plantilla para moderación creada con éxito.',
    updated: 'Plantilla para moderación actualizada con éxito.',
    removed: 'Plantilla para moderación eliminada con éxito.'
  }
}
