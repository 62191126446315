import { components, services } from '@/utils'

import rulesTemplate from '@/components/misc/rules/template'
import { ruleTypes } from '@/components/misc/rules/utils'
import title from '@/components/services/routes/form/title'

function renderTitleField(h) {
  return h(
    title,
    {
      props: {
        value: this.proxy,
        serviceName: services.messageRules
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderRules(h) {
  return [ ruleTypes.conditions, ruleTypes.actions ].map(ruleType => {
    return h(
      rulesTemplate,
      {
        props: {
          value: this.proxy[ruleType],
          ruleType
        },
        on: {
          input: event => {
            this.proxy[ruleType] = event
          }
        }
      }
    )
  })
}

export default function(h) {
  return h(
    components.form,
    {
      class: 'grid'
    },
    [
      renderTitleField.call(this, h),
      renderRules.call(this, h)
    ]
  )
}
