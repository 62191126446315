import Vue from 'vue'

import { get } from 'lodash'

import { buttonModes, colors, components, services, sizes, states } from '@/utils'

import button from '@/components/button'
import info from '@/components/info'
import form from '@/components/services/login/form'
import tag from '@/components/tag'

function renderTitle(h) {
  return h(
    'div',
    {},
    [ this.getTranslate(`${services.login}.title`) ]
  )
}
function renderPlatformTitle(h) {
  const title = get(this.globalSettings, 'frontend.title')
  if (title) {
    return h(
      'div',
      {
        class: 'text--grey'
      },
      [ title ]
    )
  }
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8 px-3': true,
        title: this.viewport.size.width <= 768,
        headline: this.viewport.size.width > 768
      },
      style: { gridTemplateColumns: '1fr auto' }
    },
    [
      renderTitle.call(this, h),
      renderPlatformTitle.call(this, h)
    ]
  )
}

function renderBody(h) {
  return h(
    components.card,
    {
      props: {
        outline: true,
        rounded: true,
        minWidth: this.viewport.size.width < 768 ? 360 : 400,
        maxWidth: this.viewport.size.width < 768 ? 360 : 400
      }
    },
    [
      h(
        form,
        {
          class: 'pa-3',
          props: { value: this.payload },
          on: {
            input: event => {
              this.payload = event
            },
            submit: () => {
              this.login(this.payload)
            },
            validation: event => {
              this.formValidation = event
            }
          }
        }
      )
    ]
  )
}

function renderWarning(h) {
  if (this.count) {
    return h(
      info,
      {
        style: this.infoStyle,
        props: {
          value: this.getTranslate(`${services.login}.errors.tooManyRequests`, {
            count: this.count,
            limit: this.limit
          }),
          color: colors.error
        }
      }
    )
  } else if (this.remaining !== undefined) {
    return h(
      info,
      {
        style: this.infoStyle,
        props: {
          value: this.getTranslate(`${services.login}.warnings.remainingAttempts`, {
            limit: this.limit,
            remaining: this.remaining
          }),
          color: colors.warning
        }
      }
    )
  }
}

function renderSignUpButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate(`${services.login}.buttons.signup`),
        mode: buttonModes.flat,
        disabled: this.status === states.loading,
        name: 'registration'
      },
      on: {
        click: () => {
          Vue.router.push({ name: 'registration' })
        }
      }
    }
  )
}
function renderSignInButton(h) {
  if (this.count) {
    return h(
      tag,
      {
        props: {
          label: this.count,
          color: colors.grey,
          monospace: true,
          as: 'button',
          size: sizes.huge
        }
      }
    )
  }

  return h(
    button,
    {
      props: {
        label: this.getTranslate(`${services.login}.buttons.signin`),
        mode: buttonModes.filled,
        color: colors.primary,
        loading: this.status === states.loading,
        disabled: this.status === states.loading || !this.formValidation || this.count > 0,
        name: 'login',
        type: 'submit'
      },
      on: {
        click: () => {
          this.login(this.payload)
        }
      }
    }
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8'
    },
    [
      renderSignUpButton.call(this, h),
      renderSignInButton.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderWarning.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
