import { debounce, defaultsDeep } from 'lodash'

import { generateServices, permissionPresets, projectName, states } from '@/utils'

import render from './render'

import visitedFavorites from '@/utils/mixins/visitedFavorites'

export default function(options = {}) {
  options = Object.assign(
    {
      filterByIsActive: true,
      dense: false
    },
    options
  )

  const localStorageKeys = {
    isActive: `${projectName}:generator:search:${options.name}:isActive`
  }

  const find = defaultsDeep(
    {
      defaultFilter: {
        $search: undefined,
        isActive: undefined,
        t: undefined
      },
      appendMode: true,
      abortPrevious: true
    },
    options.find
  )

  return {
    name: 'GeneratorSearch',

    mixins: [
      visitedFavorites({ service: options.name }),
      generateServices({
        name: options.name,

        inputFilter: options.inputFilter,
        outputFilter: options.outputFilter,

        find,

        cacher: options.cacher,

        create: false,
        update: false,
        remove: false
      })
    ],

    props: {
      maxWidth: {
        type: Number,
        default: 800
      }
    },

    data() {
      return {
        isActive: true,

        showFilter: false
      }
    },

    computed: {
      $show() {
        if (this.viewport.breakpoint.mdDown) {
          if (!this.$route.params.id) {
            return true
          } else if (this.$route.params.id) {
            return false
          }
        }

        return true
      },

      showLoadMore() {
        return (
          this.restData[options.name].find.pagination.limit + this.restData[options.name].find.pagination.offset <
          this.restData[options.name].find.pagination.total
        )
      },

      hasFilter() {
        return !!this.$slots.filter || typeof this.$scopedSlots.filter === 'function'
      },
      hasReport() {
        return !!this.$slots.report || typeof this.$scopedSlots.report === 'function'
      },
      hasCreate() {
        return !options.excludeCreate || (this.$slots.create || typeof this.$scopedSlots.create === 'function')
      },

      gridTemplateRows() {
        const result = [ '38px' ]

        if (this.showFilter) {
          result.push('auto')
        }
        result.push('auto')
        result.push('1fr')

        return result.join(' ')
      }
    },

    watch: {
      [`restData.${options.name}.find.filter.$search`]() {
        this.rest[options.name].options.find.abort()
        this.restData[options.name].find.pagination.offset = 0
      },

      'account.id'() {
        this.find()
      }
    },

    created() {
      this.onScroll = debounce(this.onScroll, 500, {
        leading: false,
        trailing: true
      })
    },

    async mounted() {
      await this.getIsActive()
      await this.find()
      this.getVisited()
      this.getFavorites()
    },

    methods: {
      onScroll(event) {
        if (
          this.restData[options.name].find.state === states.ready &&
          event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop <= 160 &&
          this.showLoadMore
        ) {
          this.restData[options.name].find.pagination.offset += this.restData[options.name].find.pagination.limit
        }
      },

      async find() {
        const accept = [ 'advanced', options.serviceForCheckPermissions || options.name, options.methodForCheckPermissions || 'find' ].join('.')
        if (this.checkPermissions(accept, permissionPresets.meUp)) {
          const params = {}
          if (options.avoid304) {
            params.query = { t: Date.now() }
          }
          await this.rest[options.name].find(params)
        }
      },

      async getIsActive() {
        let isActive = true
        try {
          isActive = await JSON.parse(window.localStorage.getItem(localStorageKeys.isActive))
        } catch (error) {
          isActive = true
        } finally {
          if (isActive === null || isActive === undefined) {
            isActive = true
          }
          this.setIsActive(isActive)
        }
      },

      setIsActive(event) {
        if (typeof event !== 'boolean') {
          event = true
        }
        window.localStorage.setItem(localStorageKeys.isActive, event)
        this.isActive = event
        this.toggleFilterByIsActive(event)
      },

      toggleFilterByIsActive(event) {
        if (event === false) {
          this.restData[options.name].find.filter.isActive = undefined
        } else {
          this.restData[options.name].find.filter.isActive = true
        }
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
