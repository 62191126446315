import { upgradeArray } from '@sigma-legacy-libs/essentials/lib/utils'

import { colors, icons, permissionPresets, services } from '@/utils'

export const invoiceStatues = upgradeArray([ 'paid', 'rejected', 'pending' ])

export const statusColors = {
  paid: colors.success,
  pending: colors.primary,
  rejected: colors.error
}

export const statusIcons = {
  paid: icons.done,
  pending: icons.schedule,
  rejected: icons.clear
}

export const hasPermission = {
  computed: {
    hasPermission() {
      if (this.checkPermissions(`advanced.${services.invoices}.update`, permissionPresets.managerUp)) {
        return true
      }
      if (this.checkPermissions(`advanced.${services.invoices}.update`, permissionPresets.resellerUp)) {
        if (this.value.customerId !== this.account.id || !!this.account.settings['billings.processChildrenOperations']) {
          return true
        }
      }

      return false
    }
  }
}
