import { PAYMENTS_METHODS } from '@sigma-legacy-libs/essentials/lib/constants'
import { site } from '@sigma-legacy-libs/essentials/lib/schemas'

import { buttonModes, colors, components, inputModes, sizes } from '@/utils'

import button from '@/components/button'
import squircle from '@/components/icon/squircle'
import svgIcon from '@/components/icon/svg'
import settingRow from '@/components/services/settings/row'

function renderIcon(h) {
  return h(
    squircle,
    {
      props: {
        color: colors.grey,
        size: sizes.medium
      }
    },
    [
      h(
        svgIcon,
        {
          props: {
            value: 'RUB',
            color: colors.grey,
            size: 32
          }
        }
      )
    ]
  )
}

function renderPaymentMethodProp(h, { method, prop }) {
  switch (typeof this.proxy[method][prop]) {
    case 'boolean': {
      return h(
        components.switch,
        {
          props: {
            value: this.proxy[method][prop],
            label: this.getTranslate(`payments.labels.methods.${method}.${prop}`),
            outline: true
          },
          on: {
            input: event => {
              this.proxy[method][prop] = event
            }
          }
        }
      )
    }

    case 'string':
    default: {
      const visibilityProps = {}
      switch (prop) {
        case 'Password':
        case 'ekamToken':
        case 'apikey':
        case 'secret': {
          visibilityProps.afterIcon = this.visibility[method][prop] ? 'visibility' : 'visibility_off'
          visibilityProps.afterIconCallback = () => {
            this.visibility[method][prop] = !this.visibility[method][prop]
          }
          visibilityProps.type = this.visibility[method][prop] ? undefined : 'password'
          break
        }
      }

      return h(
        components['text-field'],
        {
          props: {
            value: this.proxy[method][prop],
            label: this.getTranslate(`payments.labels.methods.${method}.${prop}`),
            name: `${method}.${prop}`,
            autocomplete: 'off',
            mode: inputModes['line-label'],
            defaultValue: '',
            dense: true,
            rounded: true,
            clearable: true,
            details: false,
            ...visibilityProps
          },
          on: {
            input: event => {
              this.proxy[method][prop] = event
            }
          }
        }
      )
    }
  }
}
function renderPaymentMethodProps(h, method) {
  const { SETTINGS_SITE_SCHEMA } = site
  const props = Object.keys(SETTINGS_SITE_SCHEMA['payments.methods'].$default[method])

  return h(
    'div',
    {
      class: 'grid grid-gap--12'
    },
    [
      props.map(prop => renderPaymentMethodProp.call(this, h, {
        method,
        prop
      }))
    ]
  )
}
function renderPaymentMethod(h, method) {
  return h(
    components.card,
    {
      class: 'grid grid-gap--8 pt-3 pb-2 px-2',
      props: {
        title: this.getTranslate(`payments.methods.${method}`),
        outline: true,
        rounded: true
      }
    },
    [ renderPaymentMethodProps.call(this, h, method) ]
  )
}
function renderDialogBody(h) {
  return h(
    components.form,
    {
      class: 'grid grid-gap--24 pt-2 px-2',
      props: { autocomplete: 'off' }
    },
    [ PAYMENTS_METHODS.map(method => renderPaymentMethod.call(this, h, method)) ]
  )
}
function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'dialog__footer',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.showDialog = false
            }
          }
        }
      ),
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.save'),
            color: colors.primary
          },
          on: {
            click: () => {
              this.$emit('input', this.proxy)
              this.showDialog = false
            }
          }
        }
      )
    ]
  )
}
function renderDialog(h, { path, serviceName }) {
  return h(
    components.dialog,
    {
      props: {
        title: this.getTranslate(`${serviceName}.subtitles.${path}`),
        value: this.showDialog,
        rounded: true,
        maxWidth: 410
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      renderDialogBody.call(this, h, { path }),
      renderDialogFooter.call(this, h)
    ]
  )
}

export default function(h, options) {
  return h(
    settingRow,
    {
      props: {
        title: this.getTranslate(`${options.serviceName}.contents.${options.path}`),
        label: this.getTranslate(`${options.serviceName}.hints.${options.path}`)
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      },
      scopedSlots: {
        icon: () => {
          return renderIcon.call(this, h)
        },
        dialog: () => {
          return renderDialog.call(this, h, options)
        }
      }
    }
  )
}
