import { getPrice } from '@sigma-frontend-libs/commons'

import { colors, icons, services } from '@/utils'

import sticker from '@/components/misc/sticker'

// NOTE: валюта this.currency идёт из миксина balance
// там это значение указано в props
export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: getPrice(this.balance, { currency: this.currency }),
        label: this.getTranslate(`${services.invoices}.labels.balance`),
        loading: this.balanceLoading,
        color: colors.grey,
        iconSVG: this.currency,
        button: {
          icon: icons.refresh,
          color: colors.grey,
          tooltip: this.getTranslate(`${services.invoices}.tooltips.balance`),
          loading: this.balanceLoading,
          disabled: this.balanceLoading,
          callback: () => this.getBalance()
        }
      }
    }
  )
}
