import Vue from 'vue'

import { upperFirst } from 'lodash'

import { components, inputModes, moderationColors, moderationIcons, services } from '@/utils'

import squircle from '@/components/icon/squircle'
import selectorSendingType from '@/components/selector/sendingType'
import tag from '@/components/tag'

function renderTypes(h) {
  return h(
    selectorSendingType,
    {
      props: {
        value: this.type,
        permission: `advanced.${services.users}.update`,
        clearable: false
      },
      on: {
        input: event => {
          this.type = event
        }
      }
    }
  )
}

function renderSwitch(h, service) {
  if (this.checks[service]) {
    return h(
      components.switch,
      {
        props: {
          value: this.checks[service][this.type],
          label: this.getTranslate(`users.labels.${service}.strict`),
          outline: true
        },
        key: `switch-${service}-${this.type}`,
        on: {
          input: event => {
            Vue.set(this.checks[service], this.type, event)
            this.updateSetting(service)
          }
        }
      }
    )
  }
}

function renderSelectSelection(h, item, removeByValue, service) {
  return h(
    tag,
    {
      props: {
        label: item[service === services.sendernames ? 'name' : 'title'],
        color: moderationColors[item.moderation],
        tooltip: this.getTranslate(`misc.moderation.${item.moderation}`),
        as: 'select',
        cancelable: true,
        cancel: () => removeByValue(item)
      },
      key: item.id
    }
  )
}
function renderSelectItem(h, item, service) {
  return h(
    'div',
    {
      class: 'faic grid-gap--8'
    },
    [
      h(
        squircle,
        {
          props: {
            icon: moderationIcons[item.moderation],
            color: moderationColors[item.moderation],
            tooltip: this.getTranslate(`misc.moderation.${item.moderation}`)
          }
        }
      ),

      item[service === services.sendernames ? 'name' : 'title']
    ]
  )
}
function renderSelect(h, service) {
  const associationName = upperFirst(service)

  return h(
    components.select,
    {
      props: {
        value: this.proxy[associationName][this.type],
        label: this.getTranslate(`${services.users}.labels.${service}.names`),
        query: {
          $scope: [ 'Owner' ],
          type: this.type
        },
        service,
        itemTitle: service === services.sendernames ? 'name' : 'title',
        picks: [ 'moderation', 'type', 'isActive', 'Owner' ],
        mode: inputModes.outline,
        rounded: true,
        multiple: true,
        autocomplete: true,
        details: false
      },
      key: `select-${service}-${this.type}`,
      scopedSlots: {
        selection: ({ item, removeByValue }) => renderSelectSelection.call(this, h, item, removeByValue, service),
        item: ({ item }) => renderSelectItem.call(this, h, item, service)
      },
      on: {
        input: event => {
          Vue.set(this.proxy[associationName], this.type, event)
        }
      }
    }
  )
}

function renderFields(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--12'
    },
    [
      this.services.map(service => {
        return h(
          components.card,
          {
            class: 'grid grid-gap--8 pa-2',
            props: {
              title: this.getTranslate(`${services.users}.subheaders.${service}`),
              outline: true,
              rounded: true
            }
          },
          [
            renderSwitch.call(this, h, service),
            renderSelect.call(this, h, service)
          ]
        )
      })
    ]
  )
}

export default function(h) {
  return h(
    components.card,
    {
      class: 'grid grid-gap--12 pa-2',
      props: {
        outline: true,
        rounded: true
      }
    },
    [
      renderTypes.call(this, h),
      renderFields.call(this, h)
    ]
  )
}
