import VirtualList from 'vue-virtual-scroll-list'

import { buttonModes, colors, components, icons, inputModes, services, sizes, states } from '@/utils'

import button from '@/components/button'
import refreshButton from '@/components/button/refresh'
import row from '@/components/misc/virtualScroll/row'

function renderSearchField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.searchValue,
        label: this.getTranslate('commons.labels.search'),
        beforeIcon: icons.search,
        loading: this.restData[services.operatorGroups].find.state === states.loading,
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.search(event)
        }
      }
    }
  )
}
function renderRefreshButton(h) {
  return h(
    refreshButton,
    {
      props: {
        state: this.restData[services.operatorGroups].find.state,
        disabled: !this.searchValue,
        shortClickMethod: () => this.search()
      }
    }
  )
}
function renderSearch(h) {
  return h(
    'div',
    {
      class: 'tree-header__search'
    },
    [
      renderSearchField.call(this, h),
      renderRefreshButton.call(this, h)
    ]
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: 'tree-header'
    },
    [ renderSearch.call(this, h) ]
  )
}

function renderPills(h) {
  return h(
    'div',
    {
      class: 'tree-body__pills'
    },
    [ 'all', 'checked' ].map(tab => {
      let total = this.proxy.length
      if (tab === 'all') {
        total = this.restData[services.operatorGroups].find.pagination.total
      }

      return h(
        button,
        {
          props: {
            mode: buttonModes.flat,
            size: sizes.small,
            color: this.tab === tab ? colors.primary : colors.grey,
            label: this.getTranslate(`${services.operatorGroups}.pills.${tab}`) + ` ${total}`
          },
          on: {
            click: () => {
              this.tab = tab
            }
          }
        }
      )
    })
  )
}
function renderGroup(h, group) {
  if (group) {
    return h(
      'div',
      {
        class: 'tree__row',
        on: {
          click: () => {
            const index = this.proxy.indexOf(group)
            if (~index) {
              this.proxy.splice(index, 1)
            } else {
              this.proxy.push(group)
            }
          }
        }
      },
      [
        h(
          'div',
          {
            class: {
              ['tree__checker']: true,
              ['tree__checker--checked']: ~this.proxy.indexOf(group)
            }
          }
        ),

        h(
          'div',
          {
            class: 'tree__content'
          },
          [
            h(
              'div',
              {
                class: 'tree__title'
              },
              group
            )
          ]
        )
      ]
    )
  }
}
function renderCheckedGroups(h) {
  if (this.tab === 'checked') {
    if (Array.isArray(this.checkedGroups)) {
      if (this.checkedGroups.length) {
        return h(
          'div',
          {
            class: 'tree__level tree__level--special'
          },
          this.checkedGroups.sort((a, b) => a - b).map(group => renderGroup.call(this, h, group))
        )
      } else {
        return h(
          'div',
          {
            class: 'tree__empty'
          },
          [ h('g-empty') ]
        )
      }
    }
  }
}
function renderGroups(h) {
  if (this.tab === 'all') {
    if (Array.isArray(this.restData[services.operatorGroups].find.data)) {
      if (this.restData[services.operatorGroups].find.data.length) {
        return h(
          VirtualList,
          {
            class: 'tree__level tree__level--1',
            props: {
              dataKey: 'group',
              dataSources: this.restData[services.operatorGroups].find.data,
              dataComponent: row,
              itemScopedSlots: {
                item: source => renderGroup.call(this, h, source.group)
              }
            },
            on: {
              tobottom: () => {
                const loadMore = this.restData[services.operatorGroups].find.pagination.limit + this.restData[services.operatorGroups].find.pagination.offset < this.restData[services.operatorGroups].find.pagination.total
                if (this.restData[services.operatorGroups].find.state === states.ready && loadMore) {
                  this.restData[services.operatorGroups].find.pagination.offset += this.restData[services.operatorGroups].find.pagination.limit
                }
              }
            }
          }
        )
      } else {
        return h(
          'div',
          {
            class: 'tree__empty'
          },
          [ h('g-empty') ]
        )
      }
    }
  }
}
function renderBody(h) {
  return h(
    'div',
    {
      class: 'tree-body'
    },
    [
      renderPills.call(this, h),
      renderCheckedGroups.call(this, h),
      renderGroups.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'tree'
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h)
    ]
  )
}
