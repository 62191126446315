import Vue from 'vue'

import { projectName } from '@sigma-frontend-libs/commons'
import { Macro } from '@sigma-legacy-libs/cache'

import { globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

const cachedGet = Cache.wrapWithCache(async (key, service, id) => {
  return await Vue.$GRequest?.get(service, id)
})

export default {
  name: 'FormatTable',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    service: {
      type: String,
      default: services.sendings
    }
  },

  data() {
    return {
      loading: false,
      provider: undefined
    }
  },

  computed: {
    items() {
      const result = [
        'id',
        'chainId',
        'meta.billings.ids',
        'state.detailed.ids',
        'groupId',
        'status',
        'state.status',
        'moderation',
        'pattern',
        'type',
        'channel',
        'source',
        'payload.sender',
        'payload.recipient',
        'text',
        'payload.text',
        'payload.tts',
        'payload.image',
        'payload.file',
        'payload.audio',
        'payload.video',
        'payload.button.url',
        'payload.button.text',
        'meta.stats.segments',
        'meta.stats.characters',
        'meta.billing.amount',
        'dursec',
        'billsec',
        'amount',
        'data.price',
        'data.multiplier',
        '$options',
        'meta._recipientData',
        'meta.patternId',
        'isRead',
        'title',
        'notification'
      ]

      if (this.checkPermissions(`advanced.${services.users}.get`)) {
        switch (this.service) {
          case services.sendings: {
            result.push('Owner')
            break
          }
          case services.billings: {
            result.push('OwnerId')
            break
          }
          case services.notifications: {
            result.push('targetUser')
            break
          }
        }
      }

      if (this.checkPermissions(`advanced.${services.tariffs}.get`)) {
        result.push('TariffId')
      }

      if (this.checkPermissions(`advanced.${services.providers}.get`)) {
        result.push('meta._routing.provider')
      }

      result.push('createdAt', 'updatedAt', 'sentAt', 'deliveredAt')

      return result
    }
  },

  async mounted() {
    if (this.checkPermissions(`advanced.${services.providers}.get`)) {
      const id = this.value?.meta?._routing?.provider
      if (id) {
        this.provider = await this.getData(services.providers, id)
      }
    }
  },

  methods: {
    async getData(service, id) {
      if (service && id) {
        try {
          this.loading = true
          const key = [ projectName, service, 'get', id ].filter(Boolean).join(':')
          const response = await cachedGet(key, service, id)
          if (response?.data) {
            return response.data
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
        }
      }
    }
  },

  render
}
