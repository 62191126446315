export default {
  title: 'Listas de contactos',

  contactList: 'Lista de contactos',

  labels: {
    title: 'Nombre de la libreta de direcciones',
    type: 'Tipo',
    exportTo: 'Exportar a'
  },

  titles: {
    export: 'Exportar',
    create: 'Crear una nueva lista de contactos'
  },

  types: {
    regular: 'Regular',
    blacklist: 'Lista negra'
  },

  exportTypes: {
    new: 'Nueva lista de contactos',
    existing: 'Lista de contactos existente',
    file: 'Archivo'
  },

  dialogs: {
    titles: {
      create: 'Crear lista de contactos',
      update: 'Cambiar la lista de contactos',
      remove: 'Eliminar la lista de contactos'
    },
    createAndCopy: 'Crear y copiar la lista de contactos'
  },

  emptyState: {
    title: 'Seleccionar la lista de contactos',
    description: 'Elija la lista de contactos en el lado izquierdo o {0}'
  },

  buttons: {
    create: 'Crear lista de contactos',
    createAndCopy: 'Crear y copiar',
    remove: 'Eliminar'
  },

  tooltips: {
    blacklist: 'Lista negra',
    create: 'Crear una nueva lista de contactos',
    remove: 'Eliminar la lista de contactos',
    import: 'Importar contactos a la lista de contactos actual desde un archivo'
  },

  stepper: {
    headers: {
      1: 'Seleccionar tipo de exportación',
      2: {
        new: 'Crear una nueva lista de contactos y exportar',
        existing: 'Elija la lista de contactos y exportar',
        file: 'Exportar a archivo'
      }
    }
  },

  contents: {
    confirm: {
      remove: '¿Estás seguro de que quieres eliminar la lista de contactos?'
    }
  },

  snackbars: {
    created: 'Lista de contactos creada correctamente.',
    updated: 'Lista de contactos actualizada correctamente.',
    removed: 'Lista de contactos eliminada correctamente.'
  }
}
