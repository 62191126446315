import { datePresets } from '../../../enums'

export const SENDINGS_INCOMING_FILTER = {
  OwnerId: undefined,
  type: undefined,
  from: undefined,
  createdAt: {
    $gt: undefined,
    $lt: undefined
  },
  createdAtPreset: datePresets.minutes['5']
}
