export const hostnames = [
  'localhost',
  'online.sigmasms.ru',
  'online.mirsms.ru',
  'online.freesender.net',
  'online.apisender.com',
  'stage-online.sigmasms.ru'
]

export const imlinkHostnames = [
  'online.imlink.io',
  'stage-online-l4nipwn7.sigmasms.ru'
]
